export function NoNotifications() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="163"
        viewBox="0 0 200 163"
        fill="none"
      >
        <g clipPath="url(#clip0_308_35344)">
          <path
            opacity="0.8"
            d="M161.047 45.5285C160.701 45.3857 160.472 45.0497 160.472 44.6741C160.472 20.0015 140.483 0 115.825 0C98.5398 0 83.5489 9.82887 76.13 24.2055C75.3508 25.7157 73.7417 26.6108 72.0479 26.4836C70.3814 26.358 68.6981 26.2938 66.9993 26.2938C30.1285 26.2938 0.265651 56.3201 0.501016 93.2687C0.735264 130.017 30.5228 159.51 67.2491 159.374C72.7278 159.354 78.0502 158.67 83.1407 157.4C94.2078 154.638 105.865 155.169 116.551 159.162C123.107 161.612 130.197 162.965 137.6 162.999C171.181 163.15 198.668 135.938 198.857 102.336C199.001 76.6944 183.337 54.6961 161.047 45.5285H161.047Z"
            fill="#181C2A"
          />
          <path
            d="M3.36227 79.3375C-0.748793 101.237 44.0937 110.522 44.1539 114.783C44.2142 119.044 21.318 116.793 26.2233 128.211C30.7688 138.789 66.4645 142.054 92.1482 129.197C92.8916 128.825 110.487 96.2511 96.4712 77.82C82.4558 59.3901 79.2583 91.8077 72.9604 91.1391C66.6619 90.4694 79.3554 50.4352 66.3267 42.2883C52.8262 33.8474 49.4786 62.4227 43.1455 58.2192C37.1465 54.2373 32.9903 13.8794 16.8862 19.5472C16.3028 19.752 15.7038 20.0177 15.0881 20.3481L15.0809 20.3514V20.3525C14.7546 20.5272 14.4244 20.7203 14.0892 20.9318C-4.35511 32.5817 32.4995 74.2355 24.4045 76.0878C16.3101 77.9406 5.44653 68.2395 3.36283 79.3375H3.36227Z"
            fill="#545B73"
          />
          <path
            d="M82.7442 137.567C82.7442 137.567 62.8794 102.354 45.4446 83.7463C28.0098 65.1383 14.2872 61.4086 14.2872 61.4086C14.2872 61.4086 22.704 69.5672 30.084 81.5028C37.4639 93.4384 42.4785 106.842 51.7252 117.916C65.7132 134.667 82.7442 137.568 82.7442 137.568V137.567Z"
            fill="#262936"
          />
          <path
            d="M76.0191 138.255C76.0191 138.255 58.6545 115.907 44.6738 104.809C30.693 93.7113 20.8083 92.536 20.8083 92.536C20.8083 92.536 27.4732 97.3226 33.8069 104.831C40.1405 112.339 44.9867 121.107 52.5245 127.828C63.9279 137.994 76.0191 138.255 76.0191 138.255V138.255Z"
            fill="#2C303E"
          />
          <path
            d="M138.869 131.411C143.916 130.165 152.836 126.472 155.597 121.192C158.146 116.318 153.331 110.91 155.057 106.243C156.784 101.576 169.686 97.1496 172.685 91.6375C176.241 85.1013 165.807 80.9257 169.874 76.2479C173.94 71.5701 188.836 65.3347 189.584 60.0709C190.332 54.8071 184.166 55.4813 188.544 45.4543C192.252 36.9637 197.244 25.8122 191.183 21.8348C184.723 17.5962 178.255 26.5773 175.079 33.4428C170.987 42.2867 165.175 33.8005 159.979 38.1987C155.282 42.175 157.637 61.5682 154.174 65.7494C150.71 69.9305 143.066 67.653 138.401 74.7958C133.735 81.9381 142.298 91.715 140.033 96.9721C137.767 102.229 129.81 100.961 126.559 108.427C123.308 115.894 138.87 131.411 138.87 131.411H138.869Z"
            fill="#545B73"
          />
          <path
            d="M129.62 139.13C132.908 140.285 139.267 141.489 142.753 139.542C145.971 137.744 145.254 132.941 147.935 130.957C150.615 128.973 159.476 131.229 163.181 129.239C167.574 126.878 163.252 120.703 167.25 119.573C171.247 118.442 181.891 120.415 184.241 117.737C186.592 115.059 182.886 113.173 189.02 109.156C194.214 105.755 201.106 101.333 199.164 96.875C197.095 92.1247 190.172 94.7867 185.872 97.4716C180.332 100.93 180.185 94.0344 175.656 94.5936C171.562 95.0992 165.769 106.843 162.292 107.917C158.815 108.99 155.362 104.905 150.125 107.198C144.888 109.491 146.105 118.121 142.905 120.237C139.706 122.354 135.708 118.72 131.145 121.715C126.582 124.709 129.62 139.13 129.62 139.13H129.62Z"
            fill="#2C303E"
          />
          <path
            d="M102.839 141.626C136.109 141.626 163.079 114.639 163.079 81.3488C163.079 48.0588 136.109 21.0719 102.839 21.0719C69.5699 21.0719 42.5995 48.0588 42.5995 81.3488C42.5995 114.639 69.5699 141.626 102.839 141.626Z"
            fill="#3A3E51"
          />
          <path
            d="M129.165 99.6604C128.838 99.6604 128.513 99.536 128.264 99.287C127.766 98.7892 127.766 97.9823 128.264 97.4845C137.155 88.5876 137.155 74.1105 128.264 65.2131C127.766 64.7153 127.766 63.9083 128.264 63.4105C128.761 62.9127 129.568 62.9127 130.065 63.4105C139.951 73.3018 139.951 89.3957 130.065 99.287C129.817 99.536 129.491 99.6604 129.165 99.6604Z"
            fill="#545B73"
          />
          <path
            d="M134.152 104.651C133.826 104.651 133.5 104.527 133.252 104.278C132.754 103.78 132.754 102.973 133.252 102.475C144.894 90.826 144.894 71.8715 133.252 60.2222C132.754 59.7244 132.754 58.9174 133.252 58.4196C133.749 57.9218 134.556 57.9218 135.053 58.4196C141.174 64.5439 144.545 72.6874 144.545 81.3488C144.545 90.0101 141.174 98.153 135.053 104.278C134.804 104.527 134.479 104.651 134.152 104.651Z"
            fill="#545B73"
          />
          <path
            d="M139.14 109.642C138.814 109.642 138.488 109.518 138.239 109.269C137.742 108.771 137.742 107.964 138.239 107.466C152.632 93.065 152.632 69.6325 138.239 55.2313C137.742 54.7335 137.742 53.9265 138.239 53.4287C138.737 52.9309 139.543 52.9309 140.041 53.4287C147.494 60.8863 151.599 70.8016 151.599 81.3488C151.599 91.8959 147.495 101.811 140.041 109.269C139.792 109.518 139.466 109.642 139.14 109.642Z"
            fill="#545B73"
          />
          <path
            d="M110.378 100.747C121.085 100.747 129.765 92.0621 129.765 81.3488C129.765 70.6354 121.085 61.9505 110.378 61.9505C99.6715 61.9505 90.992 70.6354 90.992 81.3488C90.992 92.0621 99.6715 100.747 110.378 100.747Z"
            fill="#262936"
          />
          <path
            d="M120.015 41.9574C106.664 51.558 92.8269 59.86 78.6649 66.8326V96.3186C92.813 103.175 106.648 111.325 120.015 120.74C123.872 94.6416 123.872 68.0553 120.015 41.9574Z"
            fill="#2C303E"
          />
          <path
            d="M77.3542 66.8326H58.9518C56.2618 66.8326 54.0811 69.0147 54.0811 71.7063V91.4449C54.0811 94.1365 56.2618 96.3186 58.9518 96.3186H77.3542V66.8326Z"
            fill="#262936"
          />
          <path
            d="M102.839 16.9862C67.3719 16.9862 38.5169 45.8589 38.5169 81.3482C38.5169 116.837 67.3719 145.71 102.839 145.71C138.307 145.71 167.162 116.837 167.162 81.3482C167.162 45.8589 138.307 16.9862 102.839 16.9862ZM102.839 25.9562C116.702 25.9562 129.39 31.0816 139.112 39.5376L61.0539 117.643C52.6036 107.915 47.4808 95.2198 47.4808 81.3488C47.4808 50.8052 72.3146 25.9567 102.839 25.9567L102.839 25.9562ZM102.839 136.741C88.9769 136.741 76.289 131.615 66.5671 123.159L144.625 45.0542C153.075 54.782 158.198 67.4777 158.198 81.3488C158.198 111.892 133.364 136.741 102.84 136.741H102.839Z"
            fill="#262936"
          />
        </g>
        <defs>
          <clipPath id="clip0_308_35344">
            <rect
              width="199"
              height="163"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
