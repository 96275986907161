import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const SecurityWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(5)} ${theme.spacing(7.5)}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.table,
  width: '100%',
  '.title': {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.contrast,
  },
  '.label': {
    marginBottom: theme.spacing(1.5),
    fontWeight: 600,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

export const ChangePasswordBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    minWidth: '460px',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 'unset',
    flexGrow: 1,
  },
  '.change_password_btn': {
    width: '100%',
    padding: `${theme.spacing(2)} 0`,
    p: {
      fontWeight: 700,
    },
  },
  '.box_item': {
    position: 'relative',
    height: 12,
    width: 12,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.text.primary}`,
    marginRight: theme.spacing(1),
    '.MuiSvgIcon-root': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 12,
      width: 12,
      color: theme.palette.primary.main,
    },
    '&.isMatch': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const SetupAuthBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    minWidth: '460px',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 'unset',
    flexGrow: 1,
  },
  '.account_token': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.75, 2.5),
    path: {
      fill: theme.palette.text.primary,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.75, 2),
    },
    '.copy_btn': {
      fontSize: 14,
      '&:hover': {
        color: theme.palette.text.contrast,
        path: {
          fill: theme.palette.text.contrast,
        },
      },
    },
  },
  canvas: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
  '.two_FA_code': {
    color: theme.palette.support.red,
  },
  '.enable_2fa_btn, .disable_2fa_btn': {
    width: '100%',
    padding: `${theme.spacing(2)} 0`,
    p: {
      fontWeight: 700,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 0),
    },
  },
  '.disable_2fa_btn': {
    marginTop: theme.spacing(3),
  },
}));

export const Step = styled(Typography)(({ theme }) => ({
  margin: `${theme.spacing(3)} auto`,
  whiteSpace: 'pre-wrap',
}));
