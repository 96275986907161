import isPropValid from '@emotion/is-prop-valid';
import Scrollbar from 'components/scrollbar/Scrollbar';
import { hideScrollbarX } from 'utils/cssStyles';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const AssetsPortfolioWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '.search_bar_content': {
    div: {
      backgroundColor: '#24234A !important',
      borderRadius: theme.spacing(1.5),
    },
  },

  input: {
    fontSize: '14px !important',
  },
}));

export const CommonAssetsBox = styled(Box)(({ theme }) => ({
  '.common_label': {
    marginBottom: theme.spacing(1.5),
  },
  '.common_assets_crypto': {
    display: 'flex',
    overflow: 'auto',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.5),
    },
    ...hideScrollbarX,
  },
}));

interface CommonAssetItemProps {
  $isActive: boolean;
}

export const CommonAssetItem = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<CommonAssetItemProps>(({ theme, $isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 'max-content',
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    p: {
      textOverflow: 'inherit',
    },
  },
  padding: `${theme.spacing(1)} ${theme.spacing(1.825)}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  fontWeight: $isActive ? 600 : 400,
  backgroundColor: $isActive
    ? theme.palette.background.hovering
    : theme.palette.background.secondary,
  img: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.3),
  },
  '&:hover': {
    backgroundColor: theme.palette.background.hovering,
  },
}));

interface AssetsItemProps {
  isActive: boolean;
}

export const AssetsItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<AssetsItemProps>(({ theme, isActive }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(1.5),
  minHeight: '55px',
  cursor: 'pointer',
  transition: '275ms',
  '&:hover': {
    transition: '275ms',
    backgroundColor: theme.palette.background.main,
  },
  img: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
  },
  svg: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
  },
  '.coin_box': {
    width: '32px',
    height: '32px',
  },
  '.name_box': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    fontSize: '14px !important',
    '.sort_name': {
      color: theme.palette.text.contrast,
      textTransform: 'uppercase',
    },
  },
  '.amount_box': {
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  '.minmax_box': {
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    p: {
      fontSize: 13,
    },
  },
  ...(isActive && {
    backgroundColor: theme.palette.background.main,
  }),
}));

export const ViewInFiatBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1.5),
  width: '100%',
  borderTop: `1px solid ${theme.palette.background.overlay}`,
  backgroundColor: theme.palette.background.main,
  '.MuiSwitch-track': {
    backgroundColor: theme.palette.text.contrast,
  },
  '.MuiFormControlLabel-label': {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.contrast,
  },
}));

export const AssetListScroll = styled(Scrollbar)(({ theme }) => ({
  margin: theme.spacing(1.5, 0),
  flex: 'auto',
}));
