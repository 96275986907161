export enum BET_MODE {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum BET_BUTTON_STATE {
  BET = 'BET',
  BET_NEXT = 'BET_NEXT',
  CANCEL = 'CANCEL',
  WAIT = 'WAIT',
  CASHOUT = 'CASHOUT',
  WIN = 'WIN',
  NONE = 'NONE',
}
