import { Typography } from '@mui/material';

import { BetStateButtonStyle } from './styles';

const BetStateButton = (props) => {
  const { type } = props;
  // use bet control v2
  // const { stateBetButton } = useGlobalContext();
  const stateBetButton = {
    disable: false,
    title: 'Bet',
    subTitle: '',
  };

  return (
    <BetStateButtonStyle
      sx={{
        background:
          'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
        color: (theme) => `${theme.palette.background.main} !important}`,
        borderRadius: (theme) => `${theme.shape.borderRadius}px`,
        ...(props.disabled && {
          opacity: 0.6,
          pointerEvents: 'none',
        }),
        width: '100%',
        px: 0,
        // '&.MuiButton-root:hover': {
        //   /* backgroundColor: stateBetButton.color, */
        //   opacity: 0.8,
        //   color: (theme) => theme.palette.common.black,
        // },
        // '&.MuiButton-root:active': {
        //   /* backgroundColor: stateBetButton.color, */
        //   opacity: 0.6,
        // },
        /* '&.MuiButton-root:disabled': {
          backgroundColor: '#637381',
        }, */
      }}
      {...props}
      type={type}
      disabled={stateBetButton.disable}
    >
      <Typography fontSize={16} fontWeight="bold" textAlign="center">
        {`${stateBetButton.title}`}
      </Typography>
      {stateBetButton.subTitle && (
        <Typography fontSize={12} fontWeight="bold" textAlign="center">
          {`(${stateBetButton.subTitle})`}
        </Typography>
      )}
    </BetStateButtonStyle>
  );
};

export default BetStateButton;
