import isPropValid from '@emotion/is-prop-valid';

import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';

export const WithdrawWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  'p.label, .withdraw_amount': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  '.fee_box': {
    '.fee, .receive': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '.label': {
        color: theme.palette.text.primary,
        fontWeight: 500,
        margin: `${theme.spacing(0.625)} 0`,
      },
      '.amount': {
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
    },
  },
}));

export const WithdrawAddressBox = styled(Box)(({ theme }) => ({
  'p.label': {
    marginTop: theme.spacing(2),
  },
  '.MuiInputBase-root': {
    width: '100%',
    backgroundColor: theme.palette.background.table,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.contrast,
  },
  input: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
  },
  'input::placeholder': {
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  '.address_input': {
    height: 56,
    marginTop: '12px',
    '.MuiInputBase-root': {
      backgroundColor: '#161432',
      borderRadius: theme.shape.borderRadius,
    },
    '.MuiFormHelperText-root': {
      color: theme.palette.support.red,
    },
  },
}));

export const ConfirmedButton = styled(Button)(({ theme }) => ({
  width: '100%',
  minHeight: 48,
  borderRadius: theme.shape.borderRadius,
  marginTop: '12px',
  backgroundImage:
    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  color: '#2E2C5E',
  '&.Mui-disabled': {
    opacity: 0.7,
  },

  span: {
    fontSize: 14,
    fontWeight: 700,
    color: '#2E2C5E',
    lineHeight: 'normal',
  },
}));

export const ConfirmWithdrawDialogBody = styled(Box)(({ theme }) => ({
  '.withdraw_amount': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 1.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
    marginBottom: theme.spacing(3),
    img: {
      height: 24,
      width: 24,
      marginRight: theme.spacing(1),
    },
    div: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  '.flex': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing(1)} 0`,
    p: {
      color: theme.palette.text.primary,
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
}));

export const WithdrawAmountBox = styled(Box)(({ theme }) => ({
  '.withdraw_amount': {
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      color: theme.palette.text.primary,
    },
  },
  '.amount_box': {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#161432',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.75, 2),
    marginBottom: theme.spacing(3),
    '.MuiInputBase-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      backgroundColor: '#161432',
      input: {
        textAlign: 'start',
        padding: 0,
        color: theme.palette.text.contrast,
        [theme.breakpoints.down(400)]: {
          fontSize: 12,
        },
      },
    },
    '.MuiTypography-root': {
      fontWeight: 500,
    },
  },
  '.coin_image': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    width: 'max-content',
    img: {
      height: 24,
      width: 24,
      marginRight: theme.spacing(0.5),
    },
    p: {
      textOverflow: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const AccountNotVerifiedWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  flex: 1,
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  'p, h6': {
    textAlign: 'center',
  },
  '.frame-wrapper': {
    border: 'none',
    marginTop: theme.spacing(4.5),
  },
  '.frame': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  '.img': {
    height: '170px',
    width: '250px',
  },
  '.text-wrapper': {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '26px',
    whiteSpace: 'pre-wrap',
  },
}));

export const AccountNotConditionsWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  flex: 1,
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  'p, h6': {
    textAlign: 'center',
  },
  '.frame-wrapper': {
    border: 'none',
    marginTop: theme.spacing(4.5),
  },
  '.frame': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  '.img': {
    height: '170px',
    width: '250px',
  },
  '.text-wrapper': {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '26px',
    whiteSpace: 'pre-wrap',
  },
}));

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  background: theme.palette.background.main,
  '.MuiLinearProgress-bar': {
    width: '100%',
    background: 'linear-gradient(90deg, #EFC516 2.23%, #EFC516 102.51%)',
    boxShadow: `2px 2px 4px 0px ${alpha(
      theme.palette.common.black,
      0.25,
    )} inset`,
    borderRadius: theme.shape.borderRadius,
  },
}));

interface SelectNetWorkToContinueProps {
  $haveSelectedNetwork: boolean;
}

export const SelectNetWorkToContinue = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<SelectNetWorkToContinueProps>(({ theme, $haveSelectedNetwork }) => ({
  position: 'relative',
  '.select-network-to-action': {
    display: 'none',
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -100%)',
    color: theme.palette.text.contrast,
    fontSize: 24,
    fontWeight: 600,
  },
  ...($haveSelectedNetwork && {
    '& > div': {
      opacity: 0.2,
      pointerEvents: 'none',
    },
    '& .select-network-to-action': {
      display: 'block',
    },
  }),
}));

export const ConfirmedButtonWithdrawPi = styled(Button)(({ theme }) => ({
  width: '100%',
  minHeight: 48,
  borderRadius: theme.shape.borderRadius,
  marginTop: '12px',
  backgroundImage:
    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  color: '#2E2C5E',
  '&.Mui-disabled': {
    opacity: 0.7,
  },
  // position: 'absolute',
  // bottom: 0,
  ':hover': {
    backgroundImage:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.7,
  },
}));

export const WithdrawPiWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    height: '90%',
  },
  '.title': {
    fontSize: '14px',
    color: '#FFF',
    marginTop: '14px',
    marginBottom: '12px',
  },
  '.input_number_wrapper': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '8px',
    marginBottom: '8px',
  },
  '.close-btn': {
    ':hover': {
      opacity: 0.8,
    },
  },
  '.button_option_pi': {
    minWidth: 'fit-content',
    padding: '8px 12px !important',
    background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
    ':hover': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.8,
    },
    '.content': {
      fontSize: '14px',
      fontWeight: 700,
    },
  },
}));
