import isPropValid from '@emotion/is-prop-valid';

import { Box, styled } from '@mui/material';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 60,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3),
  '.title': {
    color: theme.palette.text.contrast,
    textTransform: 'uppercase',
  },
  '.close-btn': {
    padding: 0,
  },
}));

export const Body = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 3, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2.5, 2.5),
    '&&& p': {
      fontSize: 14,
    },
  },
  height: 'calc(100% - 50px)',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  '.MuiTableContainer-root': {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.shape.borderRadius,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    'table::after': {
      content: 'unset',
    },
    minHeight: 'unset',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '85vh',
    },
    padding: 0,
    '.MuiTableRow-head .MuiTableCell-root': {
      backgroundColor: theme.palette.background.secondary,
      backgroundImage: 'unset',
      padding: theme.spacing(1),
      p: {
        color: theme.palette.text.contrast,
        fontWeight: 500,
        fontSize: 12,
        border: 'none',
      },
    },
    '.MuiTableBody-root .MuiTableRow-root': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.background.hovering,
      },
      transition: 'all ease 0.15s',
      '& td:first-of-type': {
        borderRadius: 'unset',
      },
      '& td:last-child': {
        borderRadius: 'unset',
      },
    },
    '.MuiTableBody-root': {
      '.MuiTableRow-root:not(:last-of-type)': {
        '.MuiTableCell-root': {
          borderBottom: `1px solid ${theme.palette.background.table}`,
        },
      },
      '.MuiTableCell-root': {
        border: 'none',
        '.MuiBox-root p': {
          color: 'white',
        },
        p: {
          color: 'white',
          fontSize: 12,
          fontWeight: 400,
        },
        '&.time p': {
          textTransform: 'uppercase',
          color: 'white !important',
        },
        '&.increase p': {
          color: 'white',
        },
        '&.decrease p': {
          color: 'white',
        },
        '& .user_name, & .profit, & .bet_amount, & .jackpot_amount': {
          fontWeight: 600,
        },
        img: {
          '&.currency_image': {
            marginLeft: theme.spacing(1),
          },
          '&.avatar': {
            marginRight: theme.spacing(1),
            borderRadius: '50%',
            '&[alt=hidden]': {
              height: 16,
              width: 20,
            },
          },
          [theme.breakpoints.up('xs')]: {
            height: 16,
            width: 16,
            '&.currency_image': {
              marginLeft: theme.spacing(0.5),
            },
            '&.avatar': {
              marginRight: theme.spacing(0.5),
            },
          },
        },
      },
    },
    '.MuiTableCell-root': {
      border: 'none',
      padding: theme.spacing(1.2, 1),
    },
    '.show_more p, .show_less p': {
      fontSize: 10,
    },
  },
}));

export const TabItem = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})(({ theme }) => ({
  padding: theme.spacing(1.25, 3),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.secondary,
  cursor: 'pointer',
  '&:hover, &.active': {
    backgroundColor: theme.palette.background.hovering,
    path: {
      fill: theme.palette.text.contrast,
    },
    h6: {
      color: theme.palette.text.contrast,
    },
  },
  transition: 'all ease 0.15s',
  svg: {
    marginRight: theme.spacing(1),
  },
  h6: {
    fontSize: 12,
  },
}));
