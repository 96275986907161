import { Box, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import currencyImages from 'assets/images/coins';
import PlayerBox from 'components/PlayerBox';
import { Currency, User } from 'graph';
import { DeepPartial } from 'react-hook-form';

interface IBetInfoProps {
  id: string;
  currency: Currency;
  jackpotCount: number;
  gameName: string;
  user?: DeepPartial<User>;
  jackpotTotalAmount: number;
  handleClick: () => void;
}

interface IRowTableProps {
  row: IBetInfoProps;
  isBetsByGame: boolean;
  betTimeAt: string;
}

const RowJackpotTableWrapper = (props: Partial<IRowTableProps>) => {
  const theme = useTheme();

  const { isBetsByGame, betTimeAt, row } = props;
  return (
    <>
      <TableRow key={row.id} onClick={() => row.handleClick()}>
        {!isBetsByGame && (
          <TableCell
            align="left"
            sx={{ maxWidth: { sm: 100, xs: 20 } }}
            width="15%"
          >
            <Typography ml={1}>{row.gameName}</Typography>
          </TableCell>
        )}

        <TableCell id="jackpot_winner_user" align="left" width="25%">
          <PlayerBox
            align={isBetsByGame ? 'center' : 'left'}
            user={row.user as User}
          />
        </TableCell>

        <TableCell
          className={row.jackpotCount > 0 ? 'increase' : 'decrease'}
          align="right"
          width="35%"
        >
          <Box display="flex" alignItems="center" justifyContent="right">
            <Typography className="jackpot_amount">
              {row.jackpotTotalAmount}
            </Typography>
            <img
              className="currency_image"
              alt={row.currency.shortName}
              src={currencyImages[row.currency.shortName]}
            />
          </Box>
        </TableCell>

        <TableCell
          align="right"
          component="td"
          scope="row"
          width="25%"
          sx={{
            [theme.breakpoints.down('sm')]: {
              width: '15%',
            },
          }}
        >
          <Typography textAlign="right" mr={1}>
            {betTimeAt}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
export { RowJackpotTableWrapper };
