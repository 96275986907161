import React from 'react';
import { RotateSpinner } from 'react-spinners-kit';

import { Stack, SxProps } from '@mui/material';
import Box from '@mui/material/Box';

const LoadingAnimated = () => (
  <Box
    width="100%"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <RotateSpinner />
  </Box>
);

interface LoadingOverlayProps {
  sx?: SxProps;
  loading: boolean;
  children: React.ReactNode;
}

export const WithLoadingOverlay = <
  P extends LoadingOverlayProps = LoadingOverlayProps,
>({
  sx,
  loading,
  children,
}: P) => (
  <Box
    position="relative"
    sx={{
      ...(loading && {
        cursor: 'progress',
        '& > div:nth-of-type(2)': {
          pointerEvents: 'none',
        },
      }),
      ...sx,
    }}
  >
    {loading && (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          position: 'absolute',
          backgroundColor: 'rgba(0 , 0, 0, 0.4)',
        }}
      >
        <LoadingAnimated />
      </Stack>
    )}
    {children}
  </Box>
);

export default LoadingAnimated;
