import { Box, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import currencyImages from 'assets/images/coins';
import PlayerBox from 'components/PlayerBox';
import { Currency, User } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import numeral from 'numeral';
import { truncate } from 'utils/truncateMiddle';

interface IBetInfoProps {
  id: string;
  betAmount: number;
  currency: Currency;
  payout: number;
  gameName: string;
  user?: User;
  handleClick: () => void;
  payoutRate: number;
}

interface IRowTableProps {
  row: IBetInfoProps;
  isBetsByGame: boolean;
  isShowInMiniGameDialog: boolean;
  equivalentUsdRate: string;
}

const RowAllBetTableWrapper = (props: Partial<IRowTableProps>) => {
  const isDesktop = useResponsive('up', 'sm');
  const theme = useTheme();
  const { isShowInMiniGameDialog, isBetsByGame, row, equivalentUsdRate } =
    props;
  const { getCurrencyAmount } = useGetCurrencyAmount();

  return (
    <>
      <TableRow key={row.id} onClick={() => row.handleClick()}>
        <TableCell
          align="left"
          sx={{
            maxWidth: { sm: isBetsByGame ? 150 : 100, xs: 20 },
          }}
          width={isShowInMiniGameDialog ? '25%' : '15%'}
        >
          {isBetsByGame ? (
            <Typography color="white" ml={1}>
              {isShowInMiniGameDialog ? truncate(row.id, 13, 6, 6) : row.id}
            </Typography>
          ) : (
            <Typography ml={1}>{row.gameName}</Typography>
          )}
        </TableCell>

        <TableCell align="left" width="20%">
          <PlayerBox user={row.user} />
        </TableCell>

        {isDesktop && !isShowInMiniGameDialog && (
          <TableCell align="right" width="20%">
            <Box display="flex" alignItems="center" justifyContent="right">
              <Typography color="text.contrast" className="bet_amount">
                {getCurrencyAmount(row.betAmount, {
                  ...row.currency,
                  equivalentUsdRate,
                })}
              </Typography>
              <img
                className="currency_image"
                alt={row.currency.shortName}
                src={currencyImages[row.currency.shortName]}
              />
            </Box>
          </TableCell>
        )}

        <TableCell
          align="center"
          component="td"
          scope="rowData"
          width="20%"
          sx={{
            [theme.breakpoints.down('sm')]: {
              width: '15%',
            },
          }}
        >
          <Typography color="white" className="multiplier">
            {!Number.isNaN(Number(row.payoutRate))
              ? numeral(Number(row.payoutRate)).format('0.00')
              : row.payoutRate}
            x
          </Typography>
        </TableCell>

        <TableCell
          className={row.payoutRate > 0 ? 'increase' : 'decrease'}
          align="right"
          sx={{ width: { sm: '30%', xs: '20%' } }}
        >
          <Box display="flex" alignItems="center" justifyContent="right" mr={1}>
            <Typography className="profit" color={row.payout ? '#53BA0C' : ''}>
              {row.payout
                ? getCurrencyAmount(row.payout, {
                    ...row.currency,
                    equivalentUsdRate,
                  })
                : getCurrencyAmount(row.betAmount, {
                    ...row.currency,
                    equivalentUsdRate,
                  })}
            </Typography>
            <img
              className="currency_image"
              alt={row.currency.shortName}
              src={currencyImages[row.currency.shortName]}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
export { RowAllBetTableWrapper };
