import { Box, styled } from '@mui/material';

export const UnlockCodgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(1.5, 1.5),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(2.2),
    padding: theme.spacing(1.5),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
    padding: theme.spacing(1, 1.5),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down(400)]: {
    gap: theme.spacing(1.75),
  },

  '.unlock-header': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    h4: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.text.contrast,
      lineHeight: 'normal',
      textAlign: 'center',
      flexGrow: 1,
      marginLeft: theme.spacing(1.5),
      [theme.breakpoints.down('lg')]: {
        fontSize: 22,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.down(450)]: {
        fontSize: 18,
      },
    },
  },

  '.unlock-description': {
    fontSize: 15,
    fontWeight: 400,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.primary,
    lineHeight: 'normal',
    whiteSpace: 'wrap',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 12,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  '.unlock-label': {
    width: '100%',
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 14,
    },
  },

  '.unlock-list': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.down(450)]: {
      gap: theme.spacing(2),
      padding: theme.spacing(0, 1.5),
    },
    [theme.breakpoints.down(400)]: {
      gap: theme.spacing(1.75),
      padding: theme.spacing(0, 1),
    },

    '.unlock-item': {
      width: '45%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      backgroundPosition: 'center center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(2),
      padding: theme.spacing(5, 3, 4),
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(1.5),
        padding: theme.spacing(4, 3, 4),
      },
      [theme.breakpoints.down('sm')]: {
        width: '46%',
        gap: theme.spacing(1.25),
        padding: theme.spacing(4, 2, 4),
      },
      [theme.breakpoints.down(450)]: {
        gap: theme.spacing(1),
        padding: theme.spacing(3.25, 1.75, 3),
      },
      [theme.breakpoints.down(400)]: {
        width: '47%',
        gap: theme.spacing(1),
        padding: theme.spacing(3, 1.5, 2.75),
      },

      h6: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.text.contrast,
        lineHeight: 'normal',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
        },
        [theme.breakpoints.down(450)]: {
          fontSize: 12,
        },
      },

      h5: {
        fontSize: 24,
        fontWeight: 700,
        color: theme.palette.text.contrast,
        lineHeight: 'normal',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
        [theme.breakpoints.down(450)]: {
          fontSize: 18,
        },
      },
    },

    '.unlock-item-money': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.75),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(0.5),
      },

      div: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        span: {
          fontSize: 16,
          color: theme.palette.text.contrast,
          lineHeight: 'normal',
          [theme.breakpoints.down('sm')]: {
            fontSize: 14,
          },
          [theme.breakpoints.down(450)]: {
            fontSize: 12,
          },
        },
        'span:first-of-type': {
          fontWeight: 500,
        },
        'span:last-child': {
          fontWeight: 600,
        },
      },
    },

    button: {
      width: '100%',
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.background.main,
      lineHeight: 'normal',
      background:
        'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
      padding: theme.spacing(1.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down(450)]: {
        fontSize: 12,
      },
    },
  },
}));
