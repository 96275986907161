import { SvgIcon } from '@mui/material';

export function IconCopy(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0347 20.208C16.8282 20.6622 16.4953 21.0473 16.0757 21.3173C15.6562 21.5873 15.1679 21.7308 14.669 21.7307H6.69514C6.35402 21.7307 6.01624 21.6636 5.70107 21.533C5.38591 21.4025 5.09955 21.2112 4.85834 20.97C4.61713 20.7288 4.42581 20.4424 4.29529 20.1272C4.16477 19.8121 4.09761 19.4743 4.09766 19.1332V7.50072C4.09737 7.0018 4.24082 6.51335 4.51085 6.0938C4.78087 5.67426 5.16604 5.34139 5.62029 5.13501V18.1372C5.62019 18.4092 5.67369 18.6785 5.77772 18.9299C5.88175 19.1812 6.03428 19.4096 6.22658 19.6019C6.41889 19.7943 6.64721 19.9469 6.8985 20.051C7.14979 20.1551 7.41914 20.2087 7.69114 20.2087L17.0347 20.208ZM13.4391 7.3605V2.26941H9.32863C8.98739 2.26941 8.6495 2.33665 8.33427 2.4673C8.01904 2.59795 7.73264 2.78944 7.49144 3.03082C7.25025 3.27221 7.05899 3.55876 6.92859 3.87409C6.79819 4.18943 6.73122 4.52737 6.73149 4.86861V17.304C6.73176 17.7797 6.92091 18.2358 7.25735 18.572C7.59379 18.9083 8.04999 19.0972 8.52566 19.0972H17.3028C17.6439 19.0972 17.9817 19.03 18.2968 18.8995C18.612 18.7689 18.8983 18.5776 19.1395 18.3364C19.3807 18.0952 19.572 17.8089 19.7026 17.4937C19.8331 17.1786 19.9003 16.8408 19.9003 16.4997V8.92735H15.0046C14.5893 8.92699 14.1911 8.76175 13.8976 8.46795C13.604 8.17414 13.4391 7.77581 13.4391 7.3605ZM14.3385 2.26941L19.9006 8.0493H15.1442C14.9305 8.0493 14.7255 7.96441 14.5744 7.81331C14.4233 7.66221 14.3385 7.45727 14.3385 7.24358V2.26941Z"
        fill="white"
      />
    </SvgIcon>
  );
}
