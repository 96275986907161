import LoadingAnimated from 'components/LoadingAnimated/LoadingAnimated';

import { Box } from '@mui/material';

export const GameLoadingAnim = () => (
  <Box
    sx={{
      height: '90vh',
    }}
  >
    <LoadingAnimated />
    {/* <Lottie animationData={loadingDiceVfx} /> */}
  </Box>
);
