import TabItem, { TabList } from 'components/Tab';
import useLocales from 'locales/useLocales';
import { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import UploadImage from '../UploadImage';

const tabs = ['id_card', 'passport'];

export default function GovernmentIdUploading() {
  const { translate } = useLocales();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <Box>
      <Typography
        variant="subtitle1"
        fontWeight={400}
        whiteSpace="pre-wrap"
        mt={{ sm: 1.5, xs: 3 }}
      >
        {translate('verify_government_id_description')}
      </Typography>
      <Stack mt={3.5} spacing={3.5} alignItems="flex-start">
        <TabList>
          {tabs.map((key) => (
            <TabItem
              key={key}
              isActive={key === selectedTab}
              onClick={() => setSelectedTab(key)}
              label={translate(key)}
            />
          ))}
        </TabList>

        {selectedTab === tabs[0] && (
          <Stack
            width="100%"
            direction={{ sm: 'row', xs: 'column' }}
            rowGap={3}
            columnGap={3}
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <UploadImage
              name="idCard.frontSide"
              label={translate('front_side')}
            />
            <UploadImage
              label={translate('back_side')}
              name="idCard.backSide"
            />
          </Stack>
        )}
        {selectedTab === tabs[1] && (
          <Box width="100%" maxWidth={430}>
            <UploadImage name="passport" label={translate('passport')} />
          </Box>
        )}
      </Stack>
    </Box>
  );
}
