/* eslint-disable @typescript-eslint/no-use-before-define */
import { useReactiveVar } from '@apollo/client';
import { useAuthContext } from 'auth/useAuthContext';
import FormProvider from 'components/hook-form';
import { useSettingsContext } from 'components/settings';
import CrashPlayers from 'components/_Games/_GameCrash/CrashPlayers';
import { useUserWalletsQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import numeral from 'numeral';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { storageCurrencyVar, viewInFiatVar } from 'store';
import { gamesLocalStateOperations } from 'store/operations';
import { toN } from 'utils/number-help';

import { Box, Divider, Stack } from '@mui/material';

import BetAmount from '../components/betAmount';
import BetStateButton from '../components/betStateButton';
import CashoutAt from '../components/cashoutAt';
import NumberOfBet from '../components/numberOfBet';
import OnLose from '../components/onLose';
import OnWin from '../components/onWin';
import StopOnLose from '../components/stopOnLose';
import StopOnWin from '../components/stopOnWin';
import Tabs from '../components/tabs';
import { BetControlProps } from '../components/types';
import WinAmount from '../components/winAmount';
import { BetControlBody, BetModeButton } from '../style';

// ----------------------------------------------------------------

const BetControl = (props: Partial<BetControlProps>) => {
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'md');
  const {
    enabledAuto,
    setEnableAuto,
    betMode,
    setBetMode,
    controlGames,
    betResult,
    onSubmit,
    setValueChange,
    timingAuto,
    setOnChangeValue,
    dataGamebank,
    profit,
    resetProfit,
    stopAutoBet,
    isProcessWithoutTime,
    gameState,
    isUsingAuto,
    isUsingManual,
    usingButtonManual,
  } = props;

  const { themeMode } = useSettingsContext();
  const intervalId = useRef(null);
  const { isAuthenticated, openLoginModal } = useAuthContext();
  // move to bet control v2
  // const {  updateStateButton } =
  //   useGlobalContext();
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);

  const { showOverGameBankPopover, showInsufficientBalancePopOver } =
    gamesLocalStateOperations;
  const [firstBetAmount, setFirstBetAmount] = useState<number>();
  const [selectedChildAutoTab, setSelectedChildAutoTab] = useState<string>(
    (controlGames.autobet.tabs?.defaultValue as string) || 'controls',
  );

  const { data: userWalletsData } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });

  const getWalletBalance = userWalletsData?.me?.userWallets.filter(
    (value) => value.currencyId === storageCurrency?.id,
  );
  const form = useForm({
    defaultValues: {
      betAmount: controlGames.manual.betAmount?.defaultValue,
      numberOfBet: controlGames.autobet.numberOfBet?.defaultValue,
      cashoutAt:
        betMode === 1
          ? controlGames.manual.cashoutAt?.defaultValue
          : controlGames.autobet.cashoutAt?.defaultValue,
      onWin: true,
      stopOnWin: controlGames.autobet.stopOnWin?.defaultValue,
      onLose: true,
      stopOnLose: controlGames.autobet.stopOnLose?.defaultValue,
    },
    // resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  useEffect(() => {
    form.reset({
      betAmount:
        viewInFiat.status && storageCurrency.equivalentUsdRate !== 0
          ? formatUSD(
              Number(controlGames.autobet.betAmount?.defaultValue) *
                storageCurrency.equivalentUsdRate,
            )
          : formatAmount(
              Number(controlGames.autobet.betAmount?.defaultValue).toFixed(7),
            ),
      numberOfBet: controlGames.autobet.numberOfBet?.defaultValue,
      cashoutAt:
        betMode === 1
          ? controlGames.manual.cashoutAt?.defaultValue
          : controlGames.autobet.cashoutAt?.defaultValue,
      onWin: true,
      stopOnWin:
        viewInFiat.status && storageCurrency.equivalentUsdRate !== 0
          ? (
              Number(controlGames.autobet.stopOnWin?.defaultValue) *
              storageCurrency.equivalentUsdRate
            ).toFixed(3)
          : Number(controlGames.autobet.stopOnWin?.defaultValue).toFixed(7),

      onLose: true,
      stopOnLose:
        viewInFiat.status && storageCurrency.equivalentUsdRate !== 0
          ? (
              Number(controlGames.autobet.stopOnLose?.defaultValue) *
              storageCurrency.equivalentUsdRate
            ).toFixed(3)
          : Number(controlGames.autobet.stopOnLose?.defaultValue).toFixed(7),
    });
  }, [props.betMode, storageCurrency.name]);

  const onChangeValue = useWatch({ control: form.control });

  const initialValue = 0;
  const computeProfitCount = (profit: Array<number>) =>
    profit.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      initialValue,
    );
  const profitCount = useMemo(() => computeProfitCount(profit), [profit]);

  useEffect(() => {
    if (storageCurrency.equivalentUsdRate !== 0 && viewInFiat.status) {
      form.reset({
        betAmount: formatUSD(
          Number(controlGames.autobet.betAmount?.defaultValue) *
            storageCurrency.equivalentUsdRate,
        ),
        onWin: true,
        stopOnWin: (
          Number(controlGames.autobet.stopOnWin?.defaultValue) *
          storageCurrency.equivalentUsdRate
        ).toFixed(3),
        onLose: true,
        stopOnLose: (
          Number(controlGames.autobet.stopOnLose?.defaultValue) *
          storageCurrency.equivalentUsdRate
        ).toFixed(3),
      });
    } else {
      form.reset({
        betAmount: formatAmount(
          Number(controlGames.autobet.betAmount?.defaultValue).toFixed(7),
        ),
        onWin: true,
        stopOnWin: Number(controlGames.autobet.stopOnWin?.defaultValue).toFixed(
          7,
        ),
        onLose: true,
        stopOnLose: Number(
          controlGames.autobet.stopOnLose?.defaultValue,
        ).toFixed(7),
      });
    }
  }, [viewInFiat.status]);

  useEffect(
    () => () => {
      clearTimeout(intervalId.current);
      return form.reset();
    },
    [],
  );

  const handleManualButton = () => {
    setBetMode(1);
  };

  const handleAutoButton = () => {
    setBetMode(2);
  };

  useEffect(() => {
    setValueChange(form.getValues());
  }, [onChangeValue]);

  useEffect(() => {
    if (setOnChangeValue) {
      setOnChangeValue(form);
    }
  }, []);

  useEffect(() => {
    if (betMode === 2 && enabledAuto && betResult?.bet) {
      /** WIN */
      if (betResult?.bet?.payoutRate > 0) {
        if (form.getValues('onWin') === true) {
          form.setValue('betAmount', formatAmount(firstBetAmount));
        } else {
          form.setValue(
            'betAmount',
            formatAmount(
              toN(form.getValues('betAmount')) +
                toN(form.getValues('betAmount')) *
                  (+form.getValues('onWin') / 100),
            ),
          );
        }
      }

      /** LOSE */
      if (betResult?.bet?.payoutRate === 0) {
        if (form.getValues('onLose') === true) {
          form.setValue('betAmount', formatAmount(firstBetAmount));
        } else {
          form.setValue(
            'betAmount',
            formatAmount(
              toN(form.getValues('betAmount')) +
                toN(form.getValues('betAmount')) *
                  (+form.getValues('onLose') / 100),
            ),
          );
        }
      }

      // Check điều kiển dừng profit thắng thua
      const isSOS =
        (toN(form.getValues('stopOnWin')) > 0 &&
          (profitCount >= toN(form.getValues('stopOnWin')) ||
            (viewInFiat.status &&
              storageCurrency.equivalentUsdRate !== 0 &&
              profitCount >=
                toN(form.getValues('stopOnWin')) /
                  storageCurrency.equivalentUsdRate))) ||
        (toN(form.getValues('stopOnLose')) > 0 &&
          (profitCount <= -toN(form.getValues('stopOnLose')) ||
            (viewInFiat.status &&
              storageCurrency.equivalentUsdRate !== 0 &&
              profitCount <=
                -toN(form.getValues('stopOnLose')) /
                  storageCurrency.equivalentUsdRate)));

      // Check điều kiện dừng hết lượt
      const toZero =
        form.getValues('numberOfBet') === -1
          ? false
          : form.getValues('numberOfBet') === 0;

      if (isSOS || toZero) {
        clearTimeout(intervalId.current);
        resetProfit();
        setEnableAuto(false);
        // updateStateButton({
        //   title: translate('set_auto_bet'),
        //   subTitle: '',
        //   disable: false,
        //   color: '#53BA0C',
        // });
      } else {
        intervalId.current = setTimeout(
          () => onSubmitData(form.getValues(), false),
          timingAuto,
        );
      }
    }
  }, [betResult?.bet]);

  useEffect(() => {
    if (getWalletBalance) {
      if (
        (viewInFiat.status &&
          storageCurrency.equivalentUsdRate !== 0 &&
          (getWalletBalance[0]?.amount <
            toN(form.getValues('betAmount')) /
              storageCurrency.equivalentUsdRate ||
            getWalletBalance[0]?.amount <
              dataGamebank?.game?.gameFund?.betRooms[0]?.betRoomSetting
                ?.minimumBetAmount ||
            0)) ||
        (!viewInFiat.status &&
          (getWalletBalance[0]?.amount < +toN(form.getValues('betAmount')) ||
            getWalletBalance[0]?.amount <
              dataGamebank?.game?.gameFund?.betRooms[0]?.betRoomSetting
                ?.minimumBetAmount ||
            0))
      ) {
        setEnableAuto(false);
        // updateStateButton({
        //   title: translate('set_auto_bet'),
        //   subTitle: '',
        //   disable: false,
        //   color: '#53BA0C',
        // });
        resetProfit();
        // handleCheckOverBalance(true);
        return form.setValue('numberOfBet', 0);
      }
    }
  }, [userWalletsData]);

  // useEffect(() => {
  //   if (enabledAuto) {
  //     clearTimeout(intervalId.current);
  //     onSubmitData(form.getValues());
  //   }
  // }, [window.innerHeight, window.innerWidth]);

  const onStop = () => {
    setEnableAuto(false);
    clearTimeout(intervalId.current);
    resetProfit();
    if (betMode === 2) {
      // updateStateButton({
      //   title: translate('set_auto_bet'),
      //   subTitle: '',
      //   disable: false,
      //   color: '#53BA0C',
      // });
    }
    if (stopAutoBet) stopAutoBet();
  };

  const onSubmitData = (data, status?) => {
    /** Check gamebank */
    if (!dataGamebank?.game?.gameFund) {
      showOverGameBankPopover(true);
      return;
    }

    /** Check login */
    if (!isAuthenticated) {
      openLoginModal();
      return;
    }

    /** Check balance */
    if (
      (!viewInFiat.status &&
        getWalletBalance[0]?.amount < +toN(form.getValues('betAmount'))) ||
      (viewInFiat.status &&
        storageCurrency.equivalentUsdRate !== 0 &&
        getWalletBalance[0]?.amount <
          +toN(form.getValues('betAmount')) /
            storageCurrency.equivalentUsdRate) ||
      !getWalletBalance[0]
    ) {
      showInsufficientBalancePopOver(true);
      setEnableAuto(false);
      clearTimeout(intervalId.current);
      resetProfit();
      if (stopAutoBet) stopAutoBet();
      return;
    }

    if (betResult === 'NONE') {
      // Ném ra ngoài xử lý
      onSubmit({ ...data, betAmount: toN(data.betAmount) });
      return;
    }

    if (!enabledAuto && betMode === 2) return;

    if (betMode === 2) {
      if (status !== false) {
        setFirstBetAmount(+toN(form.getValues('betAmount')));
      }

      data.betAmount = toN(form.getValues('betAmount'));
      data.numberOfBet = form.getValues('numberOfBet');
      form.setValue('numberOfBet', +form.getValues('numberOfBet') - 1);

      if (typeof stopAutoBet !== 'undefined') {
        clearTimeout(intervalId.current);
        onSubmit(data);
        return;
      }
    }

    onSubmit(data);
  };

  const handleShowCashoutAt = () => {
    if (controlGames.manual.cashoutAt && betMode === 1) {
      return (
        <Box
          sx={{
            marginBottom: '12px',
            order: controlGames.manual.cashoutAt.index,
          }}
        >
          <CashoutAt {...controlGames.manual.cashoutAt} />
        </Box>
      );
    }
    if (controlGames.autobet.cashoutAt && betMode === 2) {
      return (
        <Box
          sx={{
            marginBottom: '12px',
            order: controlGames.autobet.cashoutAt.index,
          }}
        >
          <CashoutAt {...controlGames.autobet.cashoutAt} />
        </Box>
      );
    }
  };

  const handleShowBetAmount = () => {
    if (controlGames.manual.betAmount && betMode === 1) {
      return (
        <Box
          sx={{
            marginBottom: '12px',
            order: controlGames.manual.betAmount.index,
          }}
        >
          <BetAmount {...controlGames.manual.betAmount} />
        </Box>
      );
    }
    if (controlGames.autobet.betAmount && betMode === 2) {
      return (
        <Box
          sx={{
            marginBottom: '12px',
            order: controlGames.autobet.betAmount.index,
          }}
        >
          <BetAmount {...controlGames.autobet.betAmount} />
        </Box>
      );
    }
  };

  const handleShowNumberOfBet = () => {
    if (controlGames.autobet.numberOfBet) {
      return (
        <Box
          sx={{
            marginBottom: '12px',
            order: controlGames.autobet.numberOfBet.index,
          }}
        >
          <NumberOfBet {...controlGames.autobet.numberOfBet} />
        </Box>
      );
    }
  };

  const handleShowOnWin = () => {
    if (controlGames.autobet.onWin) {
      return (
        <Box
          id="bet-control-auto-show-on-win"
          sx={{ marginBottom: '12px', order: controlGames.autobet.onWin.index }}
        >
          <OnWin {...controlGames.autobet.onWin} />
        </Box>
      );
    }
  };

  const handleShowStopOnWin = () => {
    if (controlGames.autobet.stopOnWin) {
      return (
        <Box
          sx={{
            marginBottom: '12px',
            order: controlGames.autobet.stopOnWin.index,
          }}
        >
          <StopOnWin {...controlGames.autobet.stopOnWin} />
        </Box>
      );
    }
  };

  const handleShowOnLose = () => {
    if (controlGames.autobet.onLose) {
      return (
        <Box
          sx={{
            marginBottom: '12px',
            order: controlGames.autobet.onLose.index,
          }}
        >
          <OnLose {...controlGames.autobet.onLose} />
        </Box>
      );
    }
  };

  const handleShowStopOnLose = () => {
    if (controlGames.autobet.stopOnLose) {
      return (
        <Box
          sx={{
            marginBottom: '12px',
            order: controlGames.autobet.stopOnLose.index,
          }}
        >
          <StopOnLose {...controlGames.autobet.stopOnLose} />
        </Box>
      );
    }
  };

  const handleShowWinAmount = () => {
    if (controlGames.manual.winAmount) {
      return <WinAmount {...controlGames.manual.winAmount} />;
    }
  };

  const handleShowPlayers = () => {
    if (controlGames.manual.players || controlGames.autobet.players) {
      return (
        <CrashPlayers sx={{ order: 10 }} roomState={gameState} players={[]} />
      );
    }
  };

  const handleShowTabs = () => {
    if (controlGames.autobet.tabs) {
      return (
        <Tabs
          selectedTab={selectedChildAutoTab}
          onChangeTab={(tab) => setSelectedChildAutoTab(tab)}
          tabs={controlGames.autobet.tabs.optionTabs}
        />
      );
    }
  };

  return (
    <BetControlBody
      sx={{
        ...(!isDesktop && { width: '100%', minWidth: 320, p: 1.5, order: 2 }),
        ...(isDesktop && { width: '100%', p: 1.5 }),
      }}
    >
      <FormProvider
        methods={form}
        onSubmit={form.handleSubmit(onSubmitData)}
        width="initial"
      >
        <Stack display="flex">
          <Box sx={{ order: !isDesktop ? 0 : '' }}>
            <Stack
              direction="row"
              justifyContent="space-around"
              spacing={0.4}
              borderRadius="12px"
              sx={{
                marginBottom: '12px',
                backgroundColor: isUsingManual === true && 'background.main',
              }}
            >
              {isUsingManual === true && (
                <BetModeButton
                  disabled={enabledAuto}
                  variant="soft"
                  themeMode={themeMode}
                  fullWidth
                  onClick={handleManualButton}
                  $click={betMode === 1}
                >
                  {translate('manual')}
                </BetModeButton>
              )}

              {isUsingAuto === true && (
                <BetModeButton
                  disabled={enabledAuto}
                  variant="soft"
                  themeMode={themeMode}
                  fullWidth
                  onClick={handleAutoButton}
                  $click={betMode === 2}
                >
                  {translate('auto')}
                </BetModeButton>
              )}
            </Stack>
          </Box>

          {betMode === 1 && (
            <Stack>
              {handleShowCashoutAt()}
              {handleShowBetAmount()}
              {handleShowWinAmount()}
              {!isDesktop && handleShowPlayers()}
            </Stack>
          )}

          {betMode === 1 && (
            <Box sx={{ order: !isDesktop ? -1 : '', pb: 2 }}>
              {isDesktop && <Divider sx={{ borderStyle: 'dashed' }} />}
              <BetStateButton
                type="submit"
                state={{ state: enabledAuto ? 1 : 2, result: '' }}
                disabled={!usingButtonManual ? usingButtonManual : enabledAuto}
              />
              {isDesktop && handleShowPlayers()}
            </Box>
          )}

          {betMode === 2 && (
            <>
              {handleShowTabs()}
              {selectedChildAutoTab === 'controls' && (
                <Stack display="flex">
                  {handleShowCashoutAt()}
                  {handleShowBetAmount()}
                  {handleShowWinAmount()}
                  {handleShowNumberOfBet()}
                  {handleShowOnWin()}
                  {handleShowStopOnWin()}
                  {handleShowOnLose()}
                  {handleShowStopOnLose()}
                </Stack>
              )}
              {selectedChildAutoTab === 'leaderboard' && (
                <Stack display="flex">{handleShowPlayers()}</Stack>
              )}
            </>
          )}

          {betMode === 2 && (
            <Box sx={{ order: !isDesktop ? -1 : '', pb: isDesktop ? 0 : 2 }}>
              {isDesktop && <Divider sx={{ borderStyle: 'dashed' }} />}
              <BetStateButton
                type="submit"
                state={{ state: enabledAuto ? 11 : 10, result: '' }}
                onClick={() => {
                  if (betResult === 'NONE')
                    return; /** Ném ra ngoài xử lý (gamecrash) */
                  if (enabledAuto) {
                    onStop();
                    // form.setValue('betAmount', formatAmount(firstBetAmount));
                    if (isProcessWithoutTime === true) return;
                    return form.setValue('numberOfBet', 0);
                  }
                  setEnableAuto(true);
                  setFirstBetAmount(+toN(form.getValues('betAmount')));
                }}
              />
            </Box>
          )}
        </Stack>
      </FormProvider>
    </BetControlBody>
  );
};
export default BetControl;

BetControl.defaultProps = {
  isUsingAuto: true,
  isUsingManual: true,
};

export const formatAmount = (amount) => numeral(amount).format('0.[00000000]');
export const formatUSD = (amount) => numeral(amount).format('0.[000]');
