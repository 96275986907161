import Iconify from 'components/iconify';
import { CurrencyInput } from 'components/_Games/CurrencyField/styles';
import { Controller, useFormContext } from 'react-hook-form';

import { Stack } from '@mui/material';

import { useLocales } from 'locales';
import { NumberOffHost } from 'overrides/components/text-host/TextHost';
import {
  DividerControlGame,
  MultButton,
  TitleControlGame,
  TitlePayout,
} from './styles';
import { FieldsBetProps } from './types';

const NumberOfBet = (props: Partial<FieldsBetProps>) => {
  const { disabled, name, infiniteValue, multiTenValue, multiHundredValue } =
    props;
  const { control } = useFormContext();
  const { translate } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Stack direction="column" sx={{ pt: 1 }}>
          <TitlePayout>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
            >
              <TitleControlGame>{translate(NumberOffHost())}</TitleControlGame>
            </Stack>
          </TitlePayout>

          <Stack
            spacing={1}
            direction="row"
            sx={{
              backgroundColor: 'background.hovering',
              borderRadius: (theme) => `${theme.shape.borderRadius}px`,
              padding: 0.3,
            }}
          >
            <CurrencyInput
              fullWidth
              disabled={disabled}
              size="small"
              autoComplete="off"
              onFocus={(event) => {
                event.target.select();
              }}
              value={+value <= -1 ? '∞' : value}
              {...props}
              onChange={(e) => {
                onChange(e.target.value.replace(/\D/, ''));
              }}
            />
            {infiniteValue && (
              <>
                <MultButton
                  onClick={() => {
                    onChange(-1);
                  }}
                  disabled={disabled}
                >
                  <Iconify icon="ion:infinite-outline" width={20} />
                </MultButton>
                <DividerControlGame />
              </>
            )}
            {multiTenValue && (
              <>
                <MultButton
                  onClick={() => {
                    onChange(10);
                  }}
                  disabled={disabled}
                >
                  10
                </MultButton>
                <DividerControlGame />
              </>
            )}
            {multiHundredValue && (
              <MultButton
                onClick={() => {
                  onChange(100);
                }}
                disabled={disabled}
              >
                100
              </MultButton>
            )}
          </Stack>
        </Stack>
      )}
    />
  );
};
export default NumberOfBet;
