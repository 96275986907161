import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '.MuiButton-root': {
    flex: 1,
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.contrast,
    '&:hover': {
      backgroundColor: theme.palette.background.hovering,
    },
    padding: theme.spacing(1.5, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
  },
}));

export const StyledDropZone = styled('div')(({ theme }) => ({
  width: '100%',
  alignSelf: 'center',
  display: 'flex',
  aspectRatio: '343/177',
  objectFit: 'cover',
  outline: 'none',
  cursor: 'pointer',
  position: 'relative',
  padding: theme.spacing(5),
  margin: '0 !important',
  borderRadius: 12,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.main,
  border: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  '&:hover': {
    opacity: 1,
  },
}));
