import * as colors from './colors';
import palette from './palette';
import shadows from './shadows';

const v2 = {
  colors,
  palette,
  shadows,
};

export default v2;
