import React from 'react';

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material/';

import Dialog from '../../../../components/Dialog';
import useLocales from '../../../../locales/useLocales';

// ----------------------------------------------------------------------

export const VerifyPhoneNumberDialog = ({ isOpen, handleClose }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const [telCode, setTelCode] = React.useState('');

  const handleConfirm = () => {};

  const isConfirmButtonDisabled =
    !telCode || Number.isInteger(telCode) || telCode.length !== 6;

  return (
    <Dialog
      title=""
      isOpen={isOpen}
      handleClose={handleClose}
      hasCloseButton
      dialogBackgroundColor={theme.palette.background.table}
      dialogHeaderChild={
        <Typography
          variant="h6"
          color="white"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {translate('verify_phone_number')}
        </Typography>
      }
    >
      <Box>
        <Box>
          <Typography textAlign="center" color="white" whiteSpace="pre-wrap">
            {translate('pls_enter_code_send_to_tel')}
          </Typography>
        </Box>
        <Box mt={4} mb={3}>
          <TextField
            fullWidth
            style={{}}
            type="number"
            value={telCode}
            onChange={(event) => {
              setTelCode(event.target.value);
            }}
            variant="outlined"
            sx={{
              '.MuiOutlinedInput-root': {
                backgroundColor: theme.palette.background.secondary,
                border: 'none',
              },
            }}
          />
          <Grid container mt={3} display="flex">
            <Grid item xs={12}>
              <Typography>{translate('resend_code')}</Typography>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Box>
        <Box mt={3} display="flex" flexDirection="column" alignItems="center">
          <Button
            style={{ width: '100%', padding: '16px 0' }}
            variant={isConfirmButtonDisabled ? 'disable' : 'contained'}
            color="secondary"
            disabled={isConfirmButtonDisabled}
            onClick={() => handleConfirm()}
          >
            <Typography variant="h6">{translate('confirm')}</Typography>
          </Button>
          <Button
            sx={{
              '&:hover p': {
                color: 'inherit',
              },
              '&& p': {
                textDecoration: 'underline',
              },
              marginTop: theme.spacing(2.5),
              width: 'fit-content',
            }}
          >
            <Typography>{translate('did_not_receive_code')}</Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default VerifyPhoneNumberDialog;
