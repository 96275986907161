// routes
import { PATH_DASHBOARD } from 'routes/paths';

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 48,
  H_MAIN_DESKTOP: 64,
  H_DASHBOARD_DESKTOP: 64,
  H_DASHBOARD_DESKTOP_OFFSET: 64 - 22,
};

export const NAV = {
  W_BASE: 240,
  W_LARGE: 320,
  W_DASHBOARD: 240,
  W_DASHBOARD_MINI: 65,
  W_MOBILE: '100%',
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 48,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 24,
  NAV_ITEM_MINI: 24,
};
