import { useReactiveVar } from '@apollo/client';
import { IconDollar } from 'assets/icons';
import { ReactComponent as InfoCircle } from 'assets/icons/info-circle-white.svg';
import coinImages from 'assets/images/coins';
import { CurrencyInput } from 'components/_Games/CurrencyField/styles';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { storageCurrencyVar, viewInFiatVar } from 'store';
import { TEXT_GREY_COLOR } from 'theme/colors';
import { toN } from 'utils/number-help';

import {
  Fade,
  InputAdornment,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';

import { BetAmountHost, MaxBet } from 'overrides/components/text-host/TextHost';
import { formatAmount, formatUSD } from '../betControl';
import {
  MultButton,
  SubTitleControlGame,
  TitleControlGame,
  TitlePayout,
} from './styles';
import { FieldsBetProps } from './types';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    px: 15,
    py: 20,
    minWidth: 300,
  },
});

const BetAmount = (props: Partial<FieldsBetProps>) => {
  const { translate } = useLocales();

  const {
    disabled,
    subtitle,
    name,
    multiplicationValue,
    divisionValue,
    minBet,
    maxBet,
    maxProfit,
    disableInput,
    onChangeValue,
  } = props;
  const { control } = useFormContext();
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Stack direction="column">
          <TitlePayout>
            {/* title */}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
            >
              <TitleControlGame>{translate(BetAmountHost())}</TitleControlGame>
              <CustomWidthTooltip
                title={
                  <Stack direction="column" justifyContent="space-between">
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" alignItems="center" spacing={0}>
                        {/* <MonetizationOnSharpIcon sx={{ color: 'white' }} /> */}
                        <Typography sx={{ pl: 1 }}>
                          {translate('max_profit')}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={0}>
                        <Typography color="#53BA0C" mr={1}>
                          {getCurrencyAmount(maxProfit || 0, storageCurrency)}
                        </Typography>
                        <img
                          src={coinImages[storageCurrency?.shortName]}
                          alt="coin"
                          width={24}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      pt={1}
                    >
                      <Stack direction="row" alignItems="center" spacing={0}>
                        {/* <MonetizationOnSharpIcon sx={{ color: 'white' }} /> */}
                        <Typography sx={{ pl: 1 }}>
                          {/* {translate('max_profit')} */}
                          {translate(MaxBet())}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={0}>
                        <Typography color="#53BA0C" mr={1}>
                          {getCurrencyAmount(maxBet || 0, storageCurrency)}
                        </Typography>
                        <img
                          src={coinImages[storageCurrency?.shortName]}
                          alt="coin"
                          width={24}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                }
                placement="top"
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                onClose={handleTooltipClose}
                open={open}
              >
                <InfoCircle
                  width="16px"
                  height="16px"
                  style={{
                    padding: '0 0 0 0',
                    marginLeft: 8,
                    color: TEXT_GREY_COLOR,
                  }}
                  onClick={handleTooltipOpen}
                />
              </CustomWidthTooltip>
            </Stack>
            {/* subtitle */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}
            >
              <SubTitleControlGame>{subtitle}</SubTitleControlGame>
            </Stack>
          </TitlePayout>

          <Stack
            spacing={1}
            direction="row"
            sx={{
              borderRadius: (theme) => `${theme.shape.borderRadius}px`,
              border: 'none',
            }}
          >
            <CurrencyInput
              fullWidth
              disabled={disabled}
              size="small"
              autoComplete="off"
              onFocus={(event) => {
                event.target.select();
              }}
              // type="number"
              // value={formattedAmount}
              value={value}
              // onChange={onChange}
              onChange={(event) => {
                if (!Number.isNaN(Number(event.target.value))) {
                  onChange(event.target.value.trim());
                  // setFormattedAmount(event.target.value.trim());
                }
              }}
              InputProps={{
                sx: {
                  borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    {viewInFiat.status === true &&
                    storageCurrency?.equivalentUsdRate !== 0 ? (
                      <IconDollar />
                    ) : (
                      <img
                        src={coinImages[storageCurrency?.shortName]}
                        alt="coin"
                        width={24}
                      />
                    )}
                  </InputAdornment>
                ),
                disabled: disableInput,
                // onChange:(e) => onChangeValue(e),
              }}
              onBlur={() => {
                if (
                  viewInFiat.status === true &&
                  storageCurrency?.equivalentUsdRate !== 0
                ) {
                  if (
                    toN(value) <
                    Number(minBet * (storageCurrency.equivalentUsdRate || 1))
                  ) {
                    onChange(
                      formatUSD(
                        Number(
                          minBet * (storageCurrency.equivalentUsdRate || 1),
                        ),
                      ),
                    );
                    return;
                  }
                  if (
                    toN(value) >
                    Number(maxBet * (storageCurrency.equivalentUsdRate || 1))
                  ) {
                    onChange(
                      formatUSD(
                        Number(
                          maxBet * (storageCurrency.equivalentUsdRate || 1),
                        ),
                      ),
                    );
                    return;
                  }
                  onChange(formatUSD(toN(value)));
                } else {
                  if (toN(value) < minBet) {
                    onChange(formatAmount(minBet.toFixed(7)));
                    return;
                  }
                  if (toN(value) > maxBet) {
                    onChange(formatAmount(maxBet.toFixed(7)));
                    return;
                  }
                  onChange(formatAmount(toN(value)));
                }
              }}
              {...props}
            />

            {multiplicationValue && (
              <MultButton
                onClick={() => {
                  if (
                    viewInFiat.status === true &&
                    toN(value) * 2 >
                      maxBet * (storageCurrency.equivalentUsdRate || 1)
                  ) {
                    return onChange(
                      Number(
                        maxBet * (storageCurrency.equivalentUsdRate || 1),
                      ).toFixed(7),
                    );
                  }

                  if (toN(value) * 2 > maxBet) return;
                  onChange(formatAmount(Number(toN(value) * 2).toFixed(7)));
                  if (onChangeValue) onChangeValue(toN(value) * 2);
                }}
                disabled={disabled}
              >
                X2
              </MultButton>
            )}
            {divisionValue && (
              <MultButton
                onClick={() => {
                  if (
                    viewInFiat.status === true &&
                    toN(value) / 2 <
                      Number(minBet * (storageCurrency.equivalentUsdRate || 1))
                  ) {
                    return onChange(
                      Number(
                        minBet * (storageCurrency.equivalentUsdRate || 1),
                      ).toFixed(7),
                    );
                  }
                  if (toN(value) / 2 < minBet) return;
                  onChange(formatAmount(Number(toN(value) / 2).toFixed(7)));
                  if (onChangeValue) onChangeValue(toN(value) / 2);
                }}
                disabled={disabled}
              >
                /2
              </MultButton>
            )}
          </Stack>
        </Stack>
      )}
    />
  );
};
export default BetAmount;
