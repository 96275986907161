import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { motion } from 'framer-motion';
import { useUserInfoQuery, useUserProfileQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { UserInfoBox } from 'layouts/dashboard/header/AuthenticatedUser/styles';
import UserPopover from 'layouts/dashboard/header/UserPopover';
import { memo, useEffect, useState } from 'react';

import { Avatar, Box } from '@mui/material';

const HeaderUserInfo = () => {
  const isMobile = useResponsive('down', 'sm');
  const [isShowUserPopover, setIsShowUserPopover] = useState(false);
  const { data: userInfoData } = useUserInfoQuery();
  const { data: userProfileData } = useUserProfileQuery();

  useEffect(() => {
    if (userInfoData && userProfileData) {
      window.Intercom('update', {
        name: userInfoData?.me?.user?.nickname, // Full name
        email: userProfileData?.me?.profile?.email, // Email address
        user_id: userInfoData?.me?.user?.id,
        vip_level: userInfoData?.me?.user?.vipLevel,
      });
    }
  }, [userInfoData, userProfileData]);

  return (
    <UserInfoBox>
      <Box component={motion.div} className="user-avatar-box">
        <Avatar
          onClick={() => {
            setIsShowUserPopover((prev) => !prev);
          }}
          className="user-avatar"
          alt={userInfoData?.me?.user.nickname}
          src={userInfoData?.me?.user?.avatar?.square || '/assets/ava1.jpg'}
        />
        {!isMobile && (
          <ArrowDown
            width="24px"
            height="24px"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setIsShowUserPopover((prev) => !prev);
            }}
          />
        )}
        <UserPopover
          open={isShowUserPopover}
          handleClose={() => setIsShowUserPopover(false)}
        />
      </Box>
    </UserInfoBox>
  );
};

export default memo(HeaderUserInfo);
