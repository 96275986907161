import { NoNotifications } from 'assets/icons';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { ReactComponent as TickCircle } from 'assets/icons/tick-circle.svg';
import { useSettingsContext } from 'components/settings';
import {
  usePersonalNotificationsLazyQuery,
  useReadAllPersonalNotificationsMutation,
  useReadAllSystemNotificationsMutation,
  useSystemNotificationsLazyQuery,
  useUnreadNotificationQuery,
} from 'graph';
import useResponsive from 'hooks/useResponsive';
import { HeaderButtonIcon } from 'layouts/dashboard/header/styles';
import { useLocales } from 'locales';
import { useEffect, useState } from 'react';

import { Badge, Box, Drawer, IconButton, Typography } from '@mui/material';

import ItemPlatform from './ItemPlatform';
import { ButtonAttachment, NoNotication, NotificationButton } from './styles';

// =================================================================================================

export interface NotificationsProps {
  drawer: boolean;
  setDrawer: (boolean) => void;
  isShow?: boolean;
}
const Notifications = (props: NotificationsProps) => {
  const { drawer, setDrawer, isShow } = props;
  const isMobile = useResponsive('down', 'sm');
  const isDesktop = useResponsive('up', 'md');
  const [isPlatform, setIsPlatform] = useState(true);
  const [listNotificationSystem, setListNotificationSystem] = useState([]);
  const [listNotificationPersonal, setListNotificationPersonal] = useState([]);
  // const [newestNotification, setNewestNotification] = useState(null);
  const [numberOfPersonal, setNumberOfPersonal] = useState(0);
  const [numberOfSystem, setNumberOfSystem] = useState(0);
  const paramPlatform = {
    page: 0,
    pageSize: 100,
  };
  const paramPersonal = {
    page: 0,
    pageSize: 100,
  };
  const { themeMode } = useSettingsContext();
  const { translate } = useLocales();

  const handleClose = () => {
    setDrawer(false);
  };

  const [
    getListNotification,
    { loading: loadingSystem, refetch: refetchSystem },
  ] = useSystemNotificationsLazyQuery({
    variables: {
      page: paramPlatform.page,
      pageSize: paramPlatform.pageSize,
    },
  });

  const [
    getListNotiPersonal,
    { loading: loadingPersonal, refetch: refetchPersonal },
  ] = usePersonalNotificationsLazyQuery({
    variables: {
      page: paramPersonal.page,
      pageSize: paramPersonal.pageSize,
    },
  });

  const { data: unreadNumberNotification } = useUnreadNotificationQuery();

  const [readAllPlatform] = useReadAllSystemNotificationsMutation();

  const [readAllPersonal] = useReadAllPersonalNotificationsMutation();
  useEffect(() => {
    getListNotification({
      variables: {
        page: paramPlatform.page,
        pageSize: paramPlatform.pageSize,
      },
    }).then((value) => {
      setListNotificationSystem(value.data.systemNotifications.data);
    });
    getListNotiPersonal({
      variables: {
        page: paramPlatform.page,
        pageSize: paramPlatform.pageSize,
      },
    }).then((value) => {
      setListNotificationPersonal(value.data.personalNotifications.data);
    });
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      refetchSystem({
        page: paramPlatform.page,
        pageSize: paramPlatform.pageSize,
      }).then((value) => {
        const results = value.data.systemNotifications.data.filter(
          ({ id: id1 }) =>
            !listNotificationSystem.some(({ id: id2 }) => +id2 === +id1),
        );
        if (results.length > 0 && listNotificationSystem.length > 1) {
          // setNewestNotification(results[0]);
          setListNotificationSystem(value.data.systemNotifications.data);
          setNumberOfSystem(+numberOfSystem + 1);
        }
      });

      refetchPersonal({
        page: paramPersonal.page,
        pageSize: paramPersonal.pageSize,
      }).then((value) => {
        const results = value.data.personalNotifications.data.filter(
          ({ id: id1 }) =>
            !listNotificationPersonal.some(({ id: id2 }) => +id2 === +id1),
        );
        if (results.length > 0 && listNotificationPersonal.length > 1) {
          // setNewestNotification(results[0]);
          setListNotificationPersonal(value.data.personalNotifications.data);
          setNumberOfPersonal(+numberOfPersonal + 1);
        }
      });
    }, 40000);
    return () => {
      clearInterval(timer);
    };
  }, [listNotificationSystem]);

  const noItemNotification = () => (
    <NoNotication className="no-notication">
      <NoNotifications />

      <Typography component="span">
        {translate('no_notifications_at_the_moment')}
      </Typography>
    </NoNotication>
  );

  const readAllNotifiaction = () => {
    if (isPlatform) {
      return readAllPlatform({
        variables: {
          lastId: listNotificationSystem[0].id,
        },
      }).then((value) => {
        setNumberOfSystem(value.data.readAllSystemNotifications.content.system);
      });
    }
    return readAllPersonal().then((value) => {
      setNumberOfPersonal(
        value.data.readAllPersonalNotifications.content.personal,
      );
    });
  };

  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // useEffect(() => {
  //   if (newestNotification) {
  //     setAnchorEl(BoxRef);
  //   }
  // }, [newestNotification]);
  // const BoxRef: any = useRef();
  useEffect(() => {
    setNumberOfSystem(unreadNumberNotification?.unreadNotification.system || 0);
    setNumberOfPersonal(
      unreadNumberNotification?.unreadNotification.personal || 0,
    );
  }, [loadingPersonal, loadingSystem]);
  const showNumberOfNoti = (number: number) => {
    if (number === 0) return '';
    if (number > 99) return `99+`;
    return number;
  };

  return (
    <div>
      {isShow && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <HeaderButtonIcon
            onClick={() => setDrawer(!drawer)}
            sx={{
              mt: isMobile && '-20%',
              padding: 0,
              marginLeft: isMobile ? 0 : '1rem',
            }}
          >
            {numberOfPersonal > 0 || numberOfSystem > 0 ? (
              <Badge
                badgeContent={+numberOfPersonal + +numberOfSystem}
                sx={{
                  span: {
                    backgroundColor: '#53BA0C',
                    color: '#FFF',
                    borderRadius: '6px',
                  },
                }}
              >
                <Bell width="24px" height="24px" />
              </Badge>
            ) : (
              <Bell width="24px" height="24px" />
            )}
          </HeaderButtonIcon>
        </Box>
      )}
      <Drawer
        anchor="right"
        open={drawer}
        onClose={() => setDrawer(!drawer)}
        variant="persistent"
        sx={{
          '& .MuiDrawer-paper': {
            width: isMobile ? '100%' : 360,
            border: 'none',
            boxShadow: (theme) => theme.shadows[12],
            height: isMobile ? '100%' : 'calc(100% - 64px)',
            top: isMobile ? '0' : '64px',
          },
        }}
      >
        <Box
          className="notification-header"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'inherit',
            position: 'inherit',
            height: 'auto',
            backgroundColor: (theme) => theme.palette.background.secondary,
            zIndex: 1,
          }}
        >
          <Box
            className="notification-title"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              pt: 1.5,
              px: 1.5,
              height: 'auto !important',
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Bell width="24px" height="24px" />
              <Typography
                sx={{
                  fontSize: '16px',
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {translate('notification')}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleClose} sx={{ pr: 0 }}>
                <CloseCircle width="24px" height="24px" />
              </IconButton>
            </Box>
          </Box>

          <Box
            className="notification-tabs"
            sx={{
              order: !isDesktop ? 0 : '',
              height: 'auto !important',
              background: (theme) => theme.palette.background.main,
            }}
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            my={1.5}
            mx={1.5}
            borderRadius="12px"
          >
            <NotificationButton
              variant="soft"
              themeMode={themeMode}
              fullWidth
              onClick={() => setIsPlatform(true)}
              $click={isPlatform}
            >
              {translate('platform')}
              {numberOfSystem > 0 && (
                <Box
                  sx={{
                    ml: 1,
                    borderRadius: '6px',
                    background: '#53BA0C',
                    px: '6px',
                    color: '#fff',
                    fontSize: '12px',
                    alignItems: 'center',
                  }}
                >
                  {showNumberOfNoti(numberOfSystem)}
                </Box>
              )}
            </NotificationButton>
            <NotificationButton
              variant="soft"
              themeMode={themeMode}
              fullWidth
              onClick={() => setIsPlatform(false)}
              $click={!isPlatform}
            >
              {translate('personal')}
              {numberOfPersonal > 0 && (
                <Box
                  sx={{
                    ml: 1,
                    borderRadius: '6px',
                    background: '#53BA0C',
                    px: '6px',
                    color: '#fff',
                    fontSize: '12px',
                    alignItems: 'center',
                  }}
                >
                  {showNumberOfNoti(numberOfPersonal)}
                </Box>
              )}
            </NotificationButton>
          </Box>
        </Box>
        {/* {showListNotification()} */}
        <Box display="flex" flexDirection="column" mt={16.5}>
          {isPlatform &&
            (listNotificationSystem.length > 0 ? (
              <>
                {listNotificationSystem?.map((value) => (
                  <Box
                    sx={{ height: 'auto !important' }}
                    px={1.5}
                    pt={1}
                    key={value.id}
                  >
                    <ItemPlatform
                      image={value.thumbnail?.large}
                      title={value.title}
                      time={value.publishedAt}
                      seen
                      description={JSON.stringify(value.content)}
                      buttonTitle={value.actionText}
                      functionAddOn={() => window.open(`${value.actionUrl}`)}
                    />
                  </Box>
                ))}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="flex-end"
                  py={isMobile ? 2 : 2}
                  style={{
                    width: 'inherit',
                    position: 'sticky',
                    height: 'inherit',
                    zIndex: 1,
                  }}
                  bottom={0}
                >
                  <ButtonAttachment
                    endIcon={<TickCircle width="24px" height="24px" />}
                    onClick={readAllNotifiaction}
                    sx={{
                      fontSize: '14px !important',
                      fontWeight: '400 !important',
                    }}
                  >
                    {translate('mark_all_as_read')}
                  </ButtonAttachment>
                </Box>
              </>
            ) : (
              noItemNotification()
            ))}
          {!isPlatform &&
            (listNotificationPersonal.length > 0 ? (
              <>
                {listNotificationPersonal?.map((value) => (
                  <Box
                    sx={{ height: 'auto !important' }}
                    px={1.5}
                    pt={1}
                    key={value.id}
                  >
                    <ItemPlatform
                      image={value.thumbnail?.large}
                      title={value.title}
                      time={value.publishedAt}
                      seen
                      description={JSON.stringify(value.content)}
                      buttonTitle={value.actionText}
                      functionAddOn={() => window.open(`${value.actionUrl}`)}
                    />
                  </Box>
                ))}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="flex-end"
                  sx={{
                    background: (theme) => theme.palette.background.secondary,
                    width: 'inherit',
                    position: 'sticky',
                    height: 'inherit',
                    zIndex: 1,
                    mt: 1.5,
                  }}
                  bottom={0}
                >
                  <ButtonAttachment
                    endIcon={<TickCircle width="22px" height="22px" />}
                    onClick={readAllNotifiaction}
                    sx={{
                      fontSize: '14px !important',
                      fontWeight: '400 !important',
                    }}
                  >
                    {translate('mark_all_as_read')}
                  </ButtonAttachment>
                </Box>
              </>
            ) : (
              noItemNotification()
            ))}
        </Box>
      </Drawer>
    </div>
  );
};

export default Notifications;
