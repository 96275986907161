import { ReactiveVar } from '@apollo/client';
import { FIAT } from 'constants/index';
import { ViewInFiat, ViewInFiatReactiveVar } from 'graph';
import lodash from 'lodash';

export default (viewFiatVar: ReactiveVar<ViewInFiatReactiveVar>) =>
  (
    viewInFiat: ViewInFiat,
    options?: { onCompleted: (viewInFiat: ViewInFiat) => void },
  ) => {
    const currentValue = viewFiatVar();
    const updatedValue = lodash.merge(currentValue, viewInFiat);

    viewFiatVar({ viewInFiat: updatedValue });
    localStorage.setItem(FIAT, JSON.stringify(viewInFiat.status));
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: FIAT,
        newValue: JSON.stringify(viewInFiat.status),
        storageArea: localStorage,
      }),
    );

    /* -------------------------------------------------------------------------- */
    if (options?.onCompleted) {
      setTimeout(() => {
        options.onCompleted(updatedValue);
      }, 0);
    }
  };
