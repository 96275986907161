import { WagerContestTableContainer } from 'components/_Events/_WagerContest/WagerContestTable/styles';

import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.secondary,
  '.MuiIconButton-root': {
    padding: 0,
    backgroundColor: theme.palette.background.hovering,
    borderRadius: '2px',
    height: 24,
    width: 24,
    '&:hover': {
      backgroundColor: theme.palette.background.hovering,
      opacity: 0.8,
      path: {
        fill: theme.palette.text.contrast,
      },
    },
  },
}));

export const PredictorTableContainer = styled(WagerContestTableContainer)(
  ({ theme }) => ({
    '&& table': {
      minWidth: 'unset',
    },
    maxHeight: '100%',
    '.MuiTableHead-root .MuiTableCell-head': {
      backgroundColor: theme.palette.background.secondary,
    },
    '.MuiTableCell-root': {
      '&:not(:first-of-type), &:not(:last-of-type)': {
        padding: theme.spacing(1.5),
      },
      '&.rank img': {
        minWidth: 24,
        [theme.breakpoints.down('sm')]: {
          width: 20,
        },
      },
      [theme.breakpoints.down('sm')]: {
        '&:not(:first-of-type), &:not(:last-of-type)': {
          padding: theme.spacing(1),
        },
        '&.wagers': {
          width: '20%',
          textAlign: 'right',
          maxWidth: 'unset',
          minWidth: 'fit-content',
        },
        '&.prize': {
          '&.prize': {
            width: '25%',
            textAlign: 'right',
          },
        },
      },
      [theme.breakpoints.up('sm')]: {
        '&.rank': {
          textAlign: 'left',
          minWidth: 20,
          maxWidth: '10%',
        },
        '&.player': {
          width: '20%',
          textAlign: 'center',
        },
        '&.cashout': {
          width: '15%',
          textAlign: 'center',
        },
        '&.result': {
          width: '20%',
          textAlign: 'center',
        },
        '&.wagers': {
          width: '20%',
          textAlign: 'right',
        },
        '&.prize': {
          width: '20%',
          textAlign: 'right',
        },
      },
      p: {
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
          '&.cashout, &.user_name': {
            fontWeight: 500,
            textOverflow: 'unset',
            overflow: 'unset',
          },
        },
      },
    },
  }),
);
