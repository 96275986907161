import { SvgIcon } from '@mui/material';

export function IconUnlock(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M6.12484 6.66602C6.12484 4.24977 8.08359 2.29102 10.4998 2.29102C12.5376 2.29102 14.2513 3.6847 14.737 5.5718C14.8231 5.90609 15.1638 6.10733 15.4981 6.02129C15.8324 5.93525 16.0336 5.59451 15.9476 5.26023C15.3232 2.83426 13.1217 1.04102 10.4998 1.04102C7.39324 1.04102 4.87484 3.55941 4.87484 6.66602V8.37819C3.94588 8.44757 3.34093 8.62272 2.89874 9.06492C2.1665 9.79715 2.1665 10.9757 2.1665 13.3327C2.1665 15.6897 2.1665 16.8682 2.89874 17.6005C3.63097 18.3327 4.80948 18.3327 7.1665 18.3327H13.8332C16.1902 18.3327 17.3687 18.3327 18.1009 17.6005C18.8332 16.8682 18.8332 15.6897 18.8332 13.3327C18.8332 10.9757 18.8332 9.79715 18.1009 9.06492C17.3687 8.33268 16.1902 8.33268 13.8332 8.33268H7.1665C6.79024 8.33268 6.44401 8.33268 6.12484 8.33566V6.66602Z"
        fill={props.color ? props.color : '#9998d4'}
      />
    </SvgIcon>
  );
}
