import { ReactiveVar } from '@apollo/client';
import { ShowOverGameBankPopoverReactiveVar } from 'graph';

export default (
    overGameBankVar: ReactiveVar<ShowOverGameBankPopoverReactiveVar>,
  ) =>
  (
    isShowOverGameBankPopOver: boolean,
    options?: { onCompleted: (isShowOverGameBankPopOver: boolean) => void },
  ) => {
    overGameBankVar({ isShowOverGameBankPopOver });

    /* -------------------------------------------------------------------------- */
    if (options?.onCompleted) {
      setTimeout(() => {
        options.onCompleted(isShowOverGameBankPopOver);
      }, 0);
    }
  };
