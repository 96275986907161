import useResponsive from 'hooks/useResponsive';
import { memo } from 'react';
import { hideScrollbarX } from 'utils/cssStyles';

import { Box, Stack, Typography } from '@mui/material';

interface Props {
  payOutHistories: any[];
}

const makeColor = (payout) => {
  if (payout.payout < 2) return '#FF5C00';
  if (payout.payout < 10) return '#53BA0C';
  return '#18BDF9';
};

function RoundHistories({ payOutHistories }: Props) {
  const isDesktop = useResponsive('up', 'sm');

  return (
    <Stack
      direction="row"
      justifyContent="end"
      spacing={2}
      sx={{
        overflowX: 'scroll',
        maxWidth: isDesktop ? 340 : 263,
        ...hideScrollbarX,
      }}
    >
      {payOutHistories &&
        payOutHistories.map((value: any, index: any) => (
          <Box
            {...(index === payOutHistories.length - 1 && {
              sx: { backgroundColor: '#413C7C', px: 0.5, borderRadius: 1 },
            })}
          >
            <Typography
              fontWeight="600"
              fontSize={14}
              color={makeColor(value)}
              {...(value.idx && { sx: { cursor: 'pointer' } })}
            >
              {value?.payout ? `${value?.payout?.toFixed(2)}x` : ''}
            </Typography>
          </Box>
        ))}
    </Stack>
  );
}

export default memo(RoundHistories);
