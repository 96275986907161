import { Box, styled } from '@mui/material';

export const LanguageBox = styled(Box)(({ theme }) => ({
  width: 165,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.secondary,
  gap: theme.spacing(1.25),
  padding: theme.spacing(1.25),

  '.language-item': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    cursor: 'pointer',
    padding: theme.spacing(1.25),
    '&:hover': {
      background: theme.palette.background.main,
    },
    '&:hover p': {
      color: '#FFFFFF',
    },
    '&.selected': {
      background: theme.palette.background.main,
    },
    '&.selected p': {
      color: '#FFFFFF',
    },
  },

  '.language-item img': {
    width: 20,
    height: 20,
  },

  '.language-name': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#858997',
  },
}));
