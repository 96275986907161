import { SvgIcon } from '@mui/material';

export function IconSound(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 16.75C17.84 16.75 17.69 16.7 17.55 16.6C17.22 16.35 17.15 15.88 17.4 15.55C18.97 13.46 18.97 10.54 17.4 8.45003C17.15 8.12003 17.22 7.65003 17.55 7.40003C17.88 7.15003 18.35 7.22003 18.6 7.55003C20.56 10.17 20.56 13.83 18.6 16.45C18.45 16.65 18.23 16.75 18 16.75Z"
        fill="white"
      />
      <path
        d="M19.8301 19.25C19.6701 19.25 19.5201 19.2 19.3801 19.1C19.0501 18.85 18.9801 18.38 19.2301 18.05C21.9001 14.49 21.9001 9.51003 19.2301 5.95003C18.9801 5.62003 19.0501 5.15003 19.3801 4.90003C19.7101 4.65003 20.1801 4.72003 20.4301 5.05003C23.5001 9.14003 23.5001 14.86 20.4301 18.95C20.2901 19.15 20.0601 19.25 19.8301 19.25Z"
        fill="white"
      />
      <path
        d="M14.02 3.77997C12.9 3.15997 11.47 3.31997 10.01 4.22997L7.09 6.05997C6.89 6.17997 6.66 6.24997 6.43 6.24997H5.5H5C2.58 6.24997 1.25 7.57997 1.25 9.99997V14C1.25 16.42 2.58 17.75 5 17.75H5.5H6.43C6.66 17.75 6.89 17.82 7.09 17.94L10.01 19.77C10.89 20.32 11.75 20.59 12.55 20.59C13.07 20.59 13.57 20.47 14.02 20.22C15.13 19.6 15.75 18.31 15.75 16.59V7.40997C15.75 5.68997 15.13 4.39997 14.02 3.77997Z"
        fill="white"
      />
    </SvgIcon>
  );
}
