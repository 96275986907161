import { ButtonProps } from '@mui/material';

import { ThemedButton } from './style';

export interface IButtonProps extends ButtonProps {}

const Button = (props: IButtonProps) => {
  const { children, ...rest } = props;
  return <ThemedButton {...rest}>{children}</ThemedButton>;
};

export default Button;
