import { NavSectionHorizontal as V2 } from 'components/nav-section';
import { memo } from 'react';

import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

function NavHorizontal({ onOpenNav }) {
  return (
    <Box sx={{ zIndex: 99 }}>
      <V2 onOpenNav={onOpenNav} />
    </Box>
  );
}

export default memo(NavHorizontal);

// ----------------------------------------------------------------------
