import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as BigWinChat } from 'assets/icons/bigwin-chat.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { ReactComponent as ForwardIcon } from 'assets/icons/forward.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/messages.svg';
import { useAuthContext } from 'auth/useAuthContext';
import {
  useChatSubscription,
  useSentChatMutation,
  useUserChatsLazyQuery,
  useUserInfoQuery,
  useUserProfileQuery,
} from 'graph';
import useResponsive from 'hooks/useResponsive';
import { HeaderButtonIcon } from 'layouts/dashboard/header/styles';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';

import ChangeLanguageDialog from '../ChangeLanguageDialog';
import { BoxBigWin } from './BoxBigwin';
import ItemMessageBase from './ItemMessageBase';
import MentionAutocomplete from './MentionAutocomplete';
import { ChatBody, ChatHeader, LanguageSelectBox } from './styles';

// =================================================================================================

export interface ChatsProps {
  drawer: boolean;
  setDrawer: (boolean) => void;
  isShow: boolean;
}
const Chats = (props: ChatsProps) => {
  const { drawer, setDrawer, isShow } = props;
  const isMobile = useResponsive('down', 'sm');
  const [listChats, setlistChats] = useState([]);
  const { currentLang, translate } = useLocales();
  const { isAuthenticated, openLoginModal } = useAuthContext();
  const [message, setMessage] = useState('');
  const drawerContentRef = useRef(null);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [countNewMessage, setCountNewMessage] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLang);
  const [disableSend, setDisableSend] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isWindownChatOpen, setIsWindownChatOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isBigWinnerOpen, setIsBigWinnerOpen] = useState<boolean>(false);
  const EndRef: any = useRef(null);

  const { data: userProfileData } = useUserProfileQuery({
    fetchPolicy: 'cache-only',
  });
  const { data: userInfoData } = useUserInfoQuery({
    fetchPolicy: 'cache-only',
  });

  const handleScrollToBottoms = () => {
    const endrefContent = EndRef.current;
    if (endrefContent) {
      endrefContent.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
      setCountNewMessage([]);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      handleScrollToBottoms();
    }, 1000);
  }, []);

  const handleClickLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLanguage = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let api_base = '';
    let app_id = '';
    if (process.env.REACT_APP_SITE === 'ohplay') {
      api_base = 'https://pnu26oby.intercom-messenger.com';
      app_id = 'pnu26oby';
    } else {
      api_base = 'https://mj6nuzwa.intercom-messenger.com';
      app_id = 'mj6nuzwa';
    }

    window.Intercom('boot', {
      api_base,
      app_id,
      name: userInfoData?.me?.user?.nickname, // Full name
      email: userProfileData?.me?.profile?.email, // Email address
      user_id: userInfoData?.me?.user?.id,
      vip_level: userInfoData?.me?.user?.vipLevel,
      source_website: window.location.host,
    });
  }, []);

  useEffect(() => {
    if (drawer) {
      window.Intercom('update', {
        horizontal_padding: 400,
        vertical_padding: 60,
      });
    } else {
      window.Intercom('update', {
        horizontal_padding: 20,
        vertical_padding: 60,
      });
    }
  }, [drawer]);

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    handleCloseLanguage();
  };

  const handleClose = () => {
    setDrawer(false);
  };

  const [, { refetch: refetchChat }] = useUserChatsLazyQuery({
    variables: {
      channel: selectedLanguage.label,
    },
  });

  const [sendMessage] = useSentChatMutation();

  const { data: newMessage } = useChatSubscription({
    variables: {
      channel: selectedLanguage.label,
    },
  });

  useEffect(() => {
    refetchChat().then(async (value) => {
      const chatLst = await Promise.all(
        value.data.chats.map(
          async (r) => ({
            id: r?.id,
            message: r?.message,
            sender: {
              id: r?.sender?.userId,
              nickname: r?.sender?.nickname,
              vipLevel: r?.sender?.vipLevel,
              avatar: {
                square: r?.sender?.avatar?.square,
              },
              commands: false,
            },
          }),
          // }
        ),
      );

      setlistChats(chatLst.reverse());
      // console.log('call', chatLst);
    });
  }, [selectedLanguage.label]);

  useEffect(() => {
    const processNewMessage = async () => {
      const getUserBlock = JSON.parse(localStorage.getItem('ignoreUser'));

      if (
        newMessage?.chat &&
        newMessage?.chat?.sender?.nickname !== userInfoData?.me?.user?.nickname
      ) {
        if (getUserBlock?.length > 0) {
          const checklist = getUserBlock.filter(
            (e) => e === newMessage?.chat?.sender?.userId,
          );
          if (checklist.length > 0) {
            return;
          }
        }

        let newM;
        try {
          // const detectCommand = await detectCommandChat(
          //   newMessage?.chat?.message,
          // );
          newM = {
            id: newMessage?.chat?.id,
            message: newMessage?.chat?.message,
            sender: {
              id: newMessage?.chat?.sender?.userId,
              nickname: newMessage?.chat?.sender?.nickname,
              avatar: {
                square: newMessage?.chat?.sender?.avatar?.square,
              },
              vipLevel: newMessage?.chat?.sender?.vipLevel,
              // commands: detectCommand.commands,
            },
          };
        } catch (error) {
          newM = {
            id: newMessage?.chat?.id,
            message: newMessage?.chat?.message,
            sender: {
              id: newMessage?.chat?.sender?.userId,
              nickname: newMessage?.chat?.sender?.nickname,
              avatar: {
                square: newMessage?.chat?.sender?.avatar?.square,
              },
              vipLevel: newMessage?.chat?.sender?.vipLevel,
            },
          };
        }
        setTimeout(() => {
          if (!showScrollToBottom) {
            handleScrollToBottoms();
          }
        }, 100);
        setCountNewMessage((lists) => [...lists, newM]);
        setlistChats((lists) => [...lists, newM]);
      }
    };

    processNewMessage();
  }, [newMessage?.chat]);

  const getListUser = () => {
    const users = [];
    listChats.forEach((r) => {
      const find = users.find((u) => u.nickname === r.sender.nickname);
      if (!find) users.push(r.sender);
    });
    const filterUser = users?.filter(
      (r) => r.nickname !== userInfoData?.me?.user?.nickname,
    );
    return filterUser;
  };

  const handleSendMessage = async () => {
    if (disableSend) {
      enqueueSnackbar(translate('please_slow_down'), { variant: 'error' });
      return;
    }

    if (message.length > 0) {
      if (!isAuthenticated) {
        openLoginModal();
        return;
      }

      const linkRegex = /(http[s]?:\/\/[^\s]+)/gi;
      const containsLink = linkRegex.test(message);
      const allowedDomain = window.location.host;

      if (containsLink) {
        const containsAllowedDomain = new RegExp(
          `http[s]?://${allowedDomain}`,
          'gi',
        ).test(message);
        if (!containsAllowedDomain) {
          return enqueueSnackbar(
            translate('links_must_include_your_website_domain'),
            {
              variant: 'error',
            },
          );
        }
      }
      const value = await sendMessage({
        variables: {
          channel: selectedLanguage.label,
          message,
        },
      });

      const newM = {
        id: value?.data?.chat?.id,
        message: value?.data?.chat?.message,
        sender: {
          id: value?.data?.chat?.sender?.userId,
          nickname: value?.data?.chat?.sender?.nickname,
          avatar: {
            square: value?.data?.chat?.sender?.avatar?.square,
          },
          vipLevel: value?.data?.chat?.sender?.vipLevel,
        },
      };
      setlistChats((lists) => [...lists, newM]);
      // console.log('call 2', newM);

      setMessage('');
      setTimeout(() => {
        handleScrollToBottoms();
      }, 100);
      // handleDrawerScroll();
    }
    setDisableSend(true);
    setTimeout(() => {
      setDisableSend(false);
    }, 3000);
  };

  const handleDrawerScroll = () => {
    const drawerContent = drawerContentRef.current;
    const isScrolledToBottom =
      Math.ceil(drawerContent.scrollTop + drawerContent.clientHeight) ===
      Math.ceil(drawerContent.scrollHeight);

    if (isScrolledToBottom) {
      setShowScrollToBottom(false);
      setCountNewMessage([]);
    } else {
      setShowScrollToBottom(true);
    }
  };

  const handleOpenNewWindownChat = () => {
    const windowFeatures = `width=350,height=${document.documentElement.scrollHeight}`;

    const newWindow = window.open(
      `http://${window.location.host}/chat`,
      '_blank',
      windowFeatures,
    );
    setIsWindownChatOpen(true);
    newWindow.moveTo(0, 0);
  };

  const handleOpenBigWinnerChat = () => {
    setIsBigWinnerOpen(!isBigWinnerOpen);
  };
  return (
    <div>
      {isShow && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <HeaderButtonIcon
            onClick={() => setDrawer(!drawer)}
            sx={{
              mt: isMobile && '-20%',
              padding: 0,
              marginLeft: isMobile ? 0 : '1rem',
            }}
          >
            <ChatIcon />
          </HeaderButtonIcon>
        </Box>
      )}

      {drawer && (
        <Drawer
          anchor="right"
          open={drawer}
          id="chat-drawer"
          onClose={() => setDrawer(!drawer)}
          variant="persistent"
          PaperProps={{ id: 'chat-drawer-paper' }}
          sx={{
            '& .MuiDrawer-paper': {
              zIndex: 100,
              width: isMobile ? '100%' : 360,
              border: 'none',
              boxShadow: (theme) => theme.shadows[12],
              top: !isMobile && '64px',
            },
          }}
        >
          <ChatHeader id="chat-header">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                p: 1.5,
                height: 'auto !important',
              }}
            >
              <Box
                id="chat-change-language-box"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <LanguageSelectBox onClick={handleClickLanguage}>
                  <img
                    width="20px"
                    height="20px"
                    src={selectedLanguage.icon}
                    alt={selectedLanguage.label}
                  />

                  <Typography
                    variant="subtitle2"
                    fontSize="14px"
                    fontWeight={700}
                  >
                    {selectedLanguage.label}
                  </Typography>

                  <ArrowDown className="arrow-icon" />
                </LanguageSelectBox>
                <ChangeLanguageDialog
                  anchorEl={anchorEl}
                  onClose={handleCloseLanguage}
                  onSelect={handleSelectLanguage}
                />
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <IconButton onClick={handleOpenBigWinnerChat}>
                  <BigWinChat width="20px" height="20px" />
                </IconButton>
                {!isMobile && (
                  <IconButton
                    onClick={handleOpenNewWindownChat}
                    sx={{ padding: '8px' }}
                  >
                    <ForwardIcon width="20px" height="20px" />
                  </IconButton>
                )}
                <IconButton onClick={handleClose} sx={{ padding: '8px' }}>
                  <CloseCircle width="20px" height="20px" />
                </IconButton>
              </Box>
            </Box>
          </ChatHeader>

          {isWindownChatOpen ? (
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={2}
              zIndex={1}
            >
              <Typography fontSize="16px" fontWeight={600}>
                {translate('chat_is_hidden')}
              </Typography>
              <Button
                style={{
                  color: '#2E2C5E',
                  background:
                    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
                }}
                variant="contained"
                onClick={() => {
                  setMessage('');
                  setIsWindownChatOpen(false);
                  setTimeout(() => {
                    handleScrollToBottoms();
                  }, 100);
                }}
              >
                {translate('show_chat')}
              </Button>
            </Box>
          ) : (
            <>
              {' '}
              {/* <Box
                    sx={{ height: 'auto !important', width: '100%' }}
                    px={1}
                    py={0.5}
                  >

                   </Box> */}
              <ChatBody
                id="chat-body"
                onScroll={handleDrawerScroll}
                ref={drawerContentRef}
              >
                {listChats?.map((value) => (
                  <ItemMessageBase
                    key={value.id}
                    message={value}
                    myInfo={userInfoData?.me?.user}
                    userRecent={getListUser()}
                  />
                ))}
                <Box sx={{ height: 'auto !important' }} py={7} />
                <div id="end-chat" ref={EndRef} />
              </ChatBody>
              {showScrollToBottom && (
                <Box
                  sx={{
                    height: 'auto !important',
                    background: '#262936',
                    borderRadius: '30px',
                  }}
                  position="fixed"
                  bottom="120px"
                  right="20px"
                >
                  <Badge
                    badgeContent={countNewMessage.length}
                    color="secondary"
                    overlap="circular"
                    sx={{
                      span: {
                        height: '24px',
                        width: '24px',
                        borderRadius: '24px',
                        fontSize: '8px',
                        fontWeight: 600,
                      },
                    }}
                  >
                    <IconButton onClick={handleScrollToBottoms}>
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </Badge>
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: 'inherit',
                  position: 'inherit',
                  height: 'auto',
                  // backgroundColor: '#323646',
                  zIndex: 1,
                  borderTopLeftRadius: 12,
                  borderTopRightRadius: 12,
                }}
                sx={{
                  width: '100%',
                  boxShadow: (theme) => theme.shadows[12],
                }}
                bottom={0}
              >
                {/* {isBigWinnerOpen && ( */}
                <BoxBigWin
                  isOpen={isBigWinnerOpen}
                  handleClose={() => setIsBigWinnerOpen(false)}
                />
                {/* )} */}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    background: '#24234a',
                    zIndex: 1,
                  }}
                >
                  <MentionAutocomplete
                    userRecent={getListUser()}
                    message={message}
                    setMessage={(e: string) => setMessage(e)}
                    sendMessage={handleSendMessage}
                  />
                </Box>
              </Box>
            </>
          )}
        </Drawer>
      )}
    </div>
  );
};

export default Chats;
