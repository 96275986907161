// ----------------------------------------------------------------------

export default function Switch() {
  // const isLight = theme.palette.mode === 'light';

  const rootStyle = () => ({});

  return {
    MuiSwitch: {
      styleOverrides: {
        root: () => rootStyle(),
      },
    },
  };
}
