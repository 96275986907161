import V2BetDetail from 'components/_Games/BetDetail';
import {
  BetSummary,
  useRecentBetsQuery,
  useRecentBetSubscription,
} from 'graph';
import useResponsive from 'hooks/useResponsive';
import React, { memo, useEffect, useState } from 'react';

import { TableBody } from '@mui/material';

import BetTable from '../BetTable';
import { HeaderAllBetWrapper } from './HeaderAllBet';
import { RowAllBetTableWrapper } from './RowsAllBet';

/* -------------------------------------------------------------------------- */

interface Props {
  isBetsByGame?: boolean;
  isShowInMiniGameDialog?: boolean;
  gameId?: string;
}
interface TableRowProps {
  row: Partial<BetSummary>;
}
const AllBetTable = ({
  isBetsByGame,
  isShowInMiniGameDialog,
  gameId,
}: Props) => {
  const isDesktop = useResponsive('up', 'sm');
  const defaultShowedRows = !isDesktop && isShowInMiniGameDialog ? 15 : 10;
  const [showedRows, setShowedRows] = useState(defaultShowedRows);
  const [selectedBet, setSelectedBet] = useState<Partial<BetSummary>>(null);
  const [rows, setRows] = useState<Partial<BetSummary>[]>([]);

  const { data: recentBetsData } = useRecentBetsQuery({
    variables: {
      gameId,
    },
  });
  const { data: allBetSubsData } = useRecentBetSubscription({
    variables: {
      gameId,
    },
  });

  const hasMore =
    rows.length > defaultShowedRows && showedRows <= defaultShowedRows;

  useEffect(() => {
    if (allBetSubsData) {
      setRows((rows) => {
        const allBets = [...rows];
        const newLength = allBets.unshift(
          allBetSubsData.recentBet as BetSummary,
        );

        if (newLength > 50) allBets.pop();
        return allBets;
      });
    }
  }, [allBetSubsData]);

  useEffect(() => {
    if (recentBetsData?.recentBets && rows.length === 0) {
      setRows((prev) => {
        const rows = [...prev];

        const allBets = rows.concat(recentBetsData?.recentBets as BetSummary[]);

        return allBets;
      });
    }
  }, [recentBetsData]);

  React.useEffect(() => {
    setShowedRows(defaultShowedRows);
  }, [isDesktop]);

  const TableRowComponent = memo(({ row }: TableRowProps) => {
    const { equivalentUsdRate, ...rowData } = row;
    return (
      <RowAllBetTableWrapper
        key={rowData.id}
        isBetsByGame={isBetsByGame}
        isShowInMiniGameDialog={isShowInMiniGameDialog}
        equivalentUsdRate={equivalentUsdRate}
        row={{
          id: row.id,
          betAmount: row.betAmount,
          payoutRate: row.payoutRate,
          currency: row.currency,
          payout: row.payoutAmount,
          gameName: row.game.name,
          user: row.user,
          handleClick: () => setSelectedBet(row),
        }}
      />
    );
  });

  return (
    <>
      <BetTable
        rows={rows}
        hasMore={hasMore}
        setShowedRows={setShowedRows}
        defaultShowedRows={defaultShowedRows}
      >
        <>
          <HeaderAllBetWrapper />

          <TableBody>
            {rows.slice(0, showedRows).map((row) => (
              <TableRowComponent key={row.id} row={row} />
            ))}
          </TableBody>
        </>
      </BetTable>
      {selectedBet !== null && (
        <V2BetDetail
          open={selectedBet !== null}
          onClose={() => {
            setSelectedBet(null);
          }}
          betId={selectedBet.id}
        />
      )}
    </>
  );
};

export { AllBetTable };
