import { DialogAnimate } from 'components/animate';
import {
  CloseBox,
  DialogBody,
  DialogHeader,
  DialogWrapper,
} from 'components/Dialog/styles';
import useResponsive from 'hooks/useResponsive';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, IconButton, Stack, Typography, useTheme } from '@mui/material';

import useLocales from '../../../../locales/useLocales';

// =================================================================================================

export const VerifyEmailDialog = ({ isOpen, handleClose, handleConfirm }) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');
  const { translate } = useLocales();

  return (
    <DialogAnimate
      open={isOpen}
      onClose={handleClose}
      sx={{
        width: 633,
        [theme.breakpoints.down('sm')]: {
          width: '80%',
        },
      }}
    >
      <DialogWrapper>
        <DialogHeader hasCloseBtn pt={2} px={3}>
          <Typography className="dialog-title" variant="h6">
            {translate('verify_email')}
          </Typography>
          <CloseBox key="close-button" onClick={() => handleClose()}>
            <IconButton className="close-btn">
              <CloseRoundedIcon />
            </IconButton>
          </CloseBox>
        </DialogHeader>

        <DialogBody>
          <Stack justifyContent="space-around" height="100%" gap={2}>
            <Typography textAlign="center" color="white" whiteSpace="pre-wrap">
              {translate('send_information_to_email')}
            </Typography>
            <Stack direction="row" spacing={4}>
              {isDesktop && (
                <Button
                  size="small"
                  fullWidth
                  variant="disable"
                  onClick={() => handleClose()}
                >
                  <Typography variant="button" color="white">
                    {translate('cancel')}
                  </Typography>
                </Button>
              )}
              <Button
                size="small"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => handleConfirm()}
              >
                <Typography variant="button">{translate('confirm')}</Typography>
              </Button>
            </Stack>
          </Stack>
        </DialogBody>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default VerifyEmailDialog;
