import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.secondary,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
  width: '100%',
  '.title': {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.contrast,
  },
  '.active-devices-table': {
    backgroundColor: theme.palette.background.main,
  },
  '.MuiTableRow-head .MuiTableCell-root': {
    fontWeight: 600,
    fontSize: 14,
    border: 'none',
    backgroundColor: '#2F2B60',
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '&:last-child': {
      borderBottomRightRadius: '12px',
      borderTopRightRadius: '12px',
    },
  },
  '.MuiTableRow-root .MuiTableCell-root': {
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '&:last-child': {
      borderBottomRightRadius: '12px',
      borderTopRightRadius: '12px',
    },
  },
  '.MuiTableCell-root': {
    backgroundColor: theme.palette.background.secondary,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
}));
