// @mui
import { useEffect } from 'react';
// config
import { NAV } from 'config/config-global';
// hooks
import useResponsive from 'hooks/useResponsive';
// components

import { Box, BoxProps } from '@mui/material';

import Footer from './footer';
import { MainBox } from './styles';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  open: boolean;
}

export default function Main({ children, sx, ...other }: Props) {
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      /* iOS hides Safari address bar */
      window.addEventListener('load', () => {
        setTimeout(() => {
          window.scrollTo({ top: 0, left: 1, behavior: 'smooth' });
        }, 1000);
      });
    }
  }, []);
  return (
    <MainBox
      component="main"
      id="main-element"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1,
        ...(isMobile && {
          paddingBottom: '30px',
        }),
        pt: 2,
        ...sx,
      }}
      {...other}
    >
      <Box
        id="main-content-box"
        sx={{
          flexGrow: 1,
          pt: isMobile ? 0 : 2,
          px: 0,
          pb: 6,
          minWidth: 1200,
          maxWidth: 1200,
          margin: '0 auto',
          '@media (max-width: 1200px)': {
            minWidth: '95%',
            maxWidth: '95%',
          },
          ...(isDesktop && {
            pt: 2,
          }),
          ...(isMobile && {
            paddingBottom: `${NAV.H_DASHBOARD_ITEM_HORIZONTAL}px`,
          }),
        }}
      >
        {children}
      </Box>
      <Footer />
    </MainBox>
  );
}
