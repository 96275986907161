import { IconDiscord, IconTelegram, IconTwitterX } from 'assets/icons';
import { SOCIAL_COMMUNITY_LINK } from 'constants/index';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import { FooterContactWrapper } from './style';

// ----------------------------------------------------------------

const FooterContact = () => {
  return (
    <FooterContactWrapper>
      <Box component="li">
        <Link to={SOCIAL_COMMUNITY_LINK.TELEGRAM_COCO} target="blank">
          <IconTelegram />
        </Link>
      </Box>

      <Box component="li">
        <Link to={SOCIAL_COMMUNITY_LINK.DISCORD_COCO} target="blank">
          <IconDiscord />
        </Link>
      </Box>

      <Box component="li">
        <Link to={SOCIAL_COMMUNITY_LINK.TWITTER_COCO} target="blank">
          <IconTwitterX />
        </Link>
      </Box>
    </FooterContactWrapper>
  );
};

export { FooterContact };
