import { SvgIcon } from '@mui/material';

export function IconFairness(props: any) {
  return (
    <SvgIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group">
        <path
          id="Vector"
          d="M8.21702 11.5392C8.21702 11.2151 7.95424 10.9523 7.6301 10.9523H7.4251L5.41499 6.41479C5.02914 6.51626 4.64044 6.60516 4.24951 6.68204L6.14125 10.9523H2.07584L3.94196 6.73987C3.48767 6.82142 3.03065 6.88665 2.57167 6.93489L0.791949 10.9523H0.586955C0.262814 10.9523 2.62137e-05 11.2151 2.62137e-05 11.5392C-0.00866033 13.5563 2.1431 14.9102 4.10853 14.8651C6.07356 14.9107 8.2263 13.5558 8.21702 11.5392Z"
          fill="#858997"
        />
        <path
          id="Vector_2"
          d="M19.3686 10.9523H19.1636L17.3839 6.93489C16.9249 6.88665 16.4679 6.82142 16.0136 6.73987L17.8798 10.9523H13.8144L15.7061 6.68204C15.3152 6.60519 14.9264 6.51626 14.5406 6.41479L12.5304 10.9523H12.3255C12.0013 10.9523 11.7385 11.2151 11.7385 11.5392C11.7385 12.456 12.1853 13.3083 12.9965 13.9393C14.5095 15.1652 17.1845 15.1652 18.6975 13.9393C19.5087 13.3083 19.9555 12.456 19.9555 11.5392C19.9556 11.2151 19.6928 10.9523 19.3686 10.9523Z"
          fill="#858997"
        />
        <path
          id="Vector_3"
          d="M0.587462 5.86545C2.19823 5.86545 3.80195 5.64684 5.3541 5.21576C8.37898 4.37548 11.5767 4.37548 14.6016 5.21576C16.1537 5.64688 17.7574 5.86545 19.3681 5.86545C20.0339 5.86545 20.1853 4.93232 19.5537 4.72177H19.5537C18.0303 4.21396 16.5461 3.5611 15.1424 2.78122L14.2151 2.2661C13.0935 1.64297 11.8422 1.27489 10.5647 1.18822V0.583216C10.5358 -0.194699 9.41945 -0.194112 9.39084 0.583216V1.18822C8.11333 1.27489 6.86208 1.64297 5.74046 2.2661L4.81311 2.78122C3.40941 3.5611 1.92527 4.21396 0.401836 4.72177H0.401797C-0.229738 4.93232 -0.0783103 5.86545 0.587462 5.86545Z"
          fill="#858997"
        />
        <path
          id="Vector_4"
          d="M12.3254 17.6826H7.62993C6.65746 17.6826 5.86914 18.4709 5.86914 19.4434C5.86914 19.7675 6.13193 20.0303 6.45607 20.0303H13.4992C13.8234 20.0303 14.0861 19.7675 14.0861 19.4434C14.0861 18.4709 13.2978 17.6826 12.3254 17.6826Z"
          fill="#858997"
        />
        <path
          id="Vector_5"
          d="M9.39062 16.5086H10.5645V5.77044C10.1736 5.75588 9.78148 5.75588 9.39062 5.77044V16.5086Z"
          fill="#858997"
        />
      </g>
    </SvgIcon>
  );
}
