import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { ThemedButton } from 'common/Button/style';
import { DialogAnimate } from 'components/animate';
import FormProvider from 'components/hook-form/FormProvider';
import { format, startOfDay } from 'date-fns';
import { useUpdateIdentificationMutation } from 'graph';
import { useLocales } from 'locales';
import { enqueueSnackbar } from 'notistack';
import GeneralInfoUploading from 'pages/ProfileSettings/components/Verify/GeneralInfoUploading/GeneralInfoUploading';
import {
  GeneralInfoDialogBody,
  GeneralInfoDialogHeader,
} from 'pages/ProfileSettings/components/Verify/GeneralInfoUploading/styles';
import { generalInfoSchema } from 'pages/ProfileSettings/components/Verify/schema';
import { GeneralInfoForm } from 'pages/ProfileSettings/components/Verify/types';
import { memo } from 'react';
import { useForm } from 'react-hook-form';

import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

interface IGeneralInfoUploadingDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

const GeneralInfoUploadingDialog = ({
  open,
  onClose,
}: IGeneralInfoUploadingDialogProps) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const [updateIdentification] = useUpdateIdentificationMutation({
    ignoreResults: true,
  });

  const generalInfoForm = useForm<GeneralInfoForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      gender: '',
      dateOfBirth: '',
      address: '',
      city: '',
      postalCode: '',
      countryCode: '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(generalInfoSchema),
    shouldFocusError: true,
  });

  const handleUpdateGeneralInfo = async (data: GeneralInfoForm) => {
    const dateOfBirth = format(
      startOfDay(new Date(data.dateOfBirth)),
      'MM/dd/yyyy',
    );

    const response = await updateIdentification({
      variables: {
        input: {
          ...data,
          dateOfBirth,
        },
      },
    });

    if (response?.data?.updateIdentification.success) {
      enqueueSnackbar(translate('update_identification_success'), {
        variant: 'success',
      });
      onClose();
      return;
    }
    if (
      response?.data?.updateIdentification.error.code ===
      'KYC_COMPLETED_OR_REVIEWING'
    ) {
      enqueueSnackbar(response?.data?.updateIdentification?.error.message, {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar(response.data?.updateIdentification.error?.message, {
      variant: 'error',
    });
  };

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        width: 480,
        overflow: 'hidden',
        maxWidth: 'unset',
        padding: (theme) => theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          py: theme.spacing(3),
          maxHeight: 'unset',
          minHeight: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
    >
      <GeneralInfoDialogHeader>
        <Typography variant="h5" className="dialog-title">
          {translate('kyc_required')}
        </Typography>
        <IconButton className="close-btn" onClick={() => onClose()}>
          <CloseCircle width="24px" height="24px" />
        </IconButton>
      </GeneralInfoDialogHeader>
      <GeneralInfoDialogBody>
        <Typography variant="subtitle2" className="general-info-subtitle">
          {translate('kyc_step1_dialog_subtitle')}
        </Typography>
        <FormProvider
          methods={generalInfoForm}
          onSubmit={generalInfoForm.handleSubmit(handleUpdateGeneralInfo)}
          height="initial"
        >
          <Stack>
            <GeneralInfoUploading form={generalInfoForm} />
            <Box flex={1}>
              <ThemedButton
                className="update-general-info-btn"
                fullWidth
                type="submit"
              >
                {translate('confirm')}
              </ThemedButton>
            </Box>
          </Stack>
        </FormProvider>
      </GeneralInfoDialogBody>
    </DialogAnimate>
  );
};

export default memo(GeneralInfoUploadingDialog);
