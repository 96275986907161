import { User, UserInfoDocument, UserInfoQuery } from 'graph';
import { client } from 'graph/apollo';
import { cache } from 'graph/cache';
import { DeepPartial } from 'react-hook-form';

export const updateCacheUserInfo = (updatedUserInfo: DeepPartial<User>) => {
  const currentUserInfo: UserInfoQuery = client.readQuery({
    query: UserInfoDocument,
  });

  if (currentUserInfo && updatedUserInfo) {
    client.cache.modify({
      id: cache.identify(currentUserInfo?.me?.user),
      fields: {
        nickname(curNickname) {
          return updatedUserInfo.nickname || curNickname;
        },
        vipLevel(curVipLevel) {
          return updatedUserInfo?.vipLevel || curVipLevel;
        },
      } as any,
    });
  }
};
