import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { ReactComponent as HandStarsIcon } from 'assets/icons/hand-stars-bold.svg';
import { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';
import { ReactComponent as MagicStickIcon } from 'assets/icons/magic-stick-bold.svg';
import { ReactComponent as RefreshCircleIcon } from 'assets/icons/refresh-circle-bold.svg';
import { ReactComponent as Widget5Icon } from 'assets/icons/widget5-bold.svg';
import { useAuthContext } from 'auth/useAuthContext';
import DialogAnimate from 'components/animate/DialogAnimate';
import {
  DialogBody,
  DialogHeader,
  DialogWrapper,
} from 'components/Dialog/styles';
import {
  useVipClaimMonthBonusMutation,
  useVipClaimWeekBonusMutation,
} from 'graph';
import { useLocales } from 'locales';
import VipClubOverviewTab from 'pages/VipClub/components/VipClubDialogV2/components/overview-tab';
import {
  VipClubMonthlyBonusTab,
  VipClubWeeklyBonusTab,
} from 'pages/VipClub/components/VipClubDialogV2/components/periodic-bonus-tab/PeriodicBonusTab';
import VipClubRakebackTab from 'pages/VipClub/components/VipClubDialogV2/components/rakeback-tab/RakebackTab';
import VipClubTabs from 'pages/VipClub/components/VipClubDialogV2/components/vip-club-dialog-tabs';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';

import {
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

// =================================================================

interface Props {
  open: boolean;
  handleClose: VoidFunction;
  handleOpenWalletDialog: VoidFunction;
}

type TabValue = 'overview' | 'rakeback' | 'weekly' | 'monthly';

const VipClubDialog = ({
  open,
  handleClose,
  handleOpenWalletDialog,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { openLoginModal } = useAuthContext();
  const { isAuthenticated } = useAuthContext();
  const [selectedTab, setSelectedTab] = useState<TabValue>('overview');

  const [claimWeeklyBonusMutation] = useVipClaimWeekBonusMutation({
    ignoreResults: true,
  });
  const [claimMonthlyBonusMutation] = useVipClaimMonthBonusMutation({
    ignoreResults: true,
  });

  const tabs: TabsType<TabValue> = [
    { label: translate('overview'), value: 'overview', icon: <Widget5Icon /> },
    {
      label: translate('rakeback'),
      value: 'rakeback',
      icon: <RefreshCircleIcon />,
    },
    { label: translate('weekly'), value: 'weekly', icon: <HandStarsIcon /> },
    { label: translate('monthly'), value: 'monthly', icon: <MagicStickIcon /> },
  ];

  const handleClickLogin = () => {
    handleClose();
    openLoginModal();
  };

  /* return is claim success or not */
  const claimWeeklyBonus = async () => {
    try {
      const response = await claimWeeklyBonusMutation();

      return response?.data?.vipClaimWeekBonus?.success;
    } catch (_) {
      return false;
    }
  };

  /* return is claim success or not */
  const claimMonthlyBonus = async () => {
    try {
      const response = await claimMonthlyBonusMutation();

      return response?.data?.vipClaimMonthBonus?.success;
    } catch (_) {
      return false;
    }
  };

  return (
    <DialogAnimate
      open={open}
      onClose={handleClose}
      sx={{
        width: 670,
        height: 750,
        borderRadius: '12px !important',
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'unset',
          maxWidth: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
    >
      <DialogWrapper
        className="vipclub-popup"
        component={Stack}
        sx={{ flexGrow: 1 }}
      >
        <DialogHeader
          className="vipclub-header"
          hasCloseBtn={false}
          sx={{
            padding: '20px',
          }}
        >
          <IconButton
            className="close-btn"
            sx={{
              padding: 0,
              visibility: 'hidden',
            }}
          >
            <CloseCircle width="24px" height="24px" />
          </IconButton>
          <Typography
            variant="h5"
            color="#fff"
            width="100%"
            textAlign="center"
            textTransform="uppercase"
          >
            {translate('host_vip_club')}
          </Typography>
          <IconButton
            className="close-btn"
            sx={{
              padding: 0,
            }}
            onClick={() => handleClose()}
          >
            <CloseCircle width="24px" height="24px" />
          </IconButton>
        </DialogHeader>

        <DialogBody
          component={Stack}
          sx={{ pt: 1.5, px: 0, flexGrow: 1, justifyContent: 'space-between' }}
        >
          <Stack flexGrow={1} overflow="auto">
            {isAuthenticated && (
              <VipClubTabs<TabValue>
                tabs={tabs}
                selectedTab={selectedTab}
                onChangeTab={(tab) => setSelectedTab(tab)}
              />
            )}

            <Box pt={2} flexGrow={1} position="relative">
              {selectedTab === 'overview' && (
                <VipClubOverviewTab handleClickLogin={handleClickLogin} />
              )}
              {selectedTab === 'rakeback' && (
                <VipClubRakebackTab
                  handleCloseVipDialog={handleClose}
                  handleOpenWalletDialog={handleOpenWalletDialog}
                />
              )}

              {selectedTab === 'weekly' && (
                <VipClubWeeklyBonusTab
                  onClickClaimBonus={claimWeeklyBonus}
                  handleCloseVipDialog={handleClose}
                />
              )}

              {selectedTab === 'monthly' && (
                <VipClubMonthlyBonusTab
                  onClickClaimBonus={claimMonthlyBonus}
                  handleCloseVipDialog={handleClose}
                />
              )}
            </Box>
          </Stack>
          <Typography
            component={Link}
            color="text.primary"
            underline="hover"
            textAlign="center"
            sx={{
              cursor: 'pointer',
              alignSelf: 'center',
              display: 'inline-flex',
            }}
            gap={1}
            mt={1}
            onClick={() => {
              navigate(PATH_DASHBOARD.vipClub.root);
              handleClose();
            }}
          >
            <InfoCircle width="20px" height="20px" />
            {translate('learn_vip')}
          </Typography>
        </DialogBody>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default VipClubDialog;
