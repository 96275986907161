import { MotionProps } from 'framer-motion';

import { Box, BoxProps, styled } from '@mui/material';

export const Wrapper = styled(Box)<BoxProps & MotionProps>(({ theme }) => ({
  zIndex: 9999,
  position: 'absolute',
  top: 'calc(100% + 12px)',
  [theme.breakpoints.down('sm')]: {
    top: 'calc(100% + 6px)',
  },
  right: -5,
  padding: theme.spacing(1),
  minWidth: 244,
  width: '100%',
  height: 'auto !important',
  borderRadius: '0 0 12px 12px',
  background: theme.palette.background.secondary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItem: 'center',
  '.group, .item': {
    width: '100%',
    borderRadius: '12px',
    backgroundColor: theme.palette.background.secondary,
  },
  '.group_item': {
    '&:first-of-type': {
      borderRadius: '12px',
    },
    '&:last-of-type': {
      borderRadius: '12px',
    },
  },
  '.item, .group_item': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '10px 8px',
    borderRadius: '12px',
    cursor: 'pointer',
    'path, g': {
      fill: theme.palette.text.primary,
    },
    h6: {
      color: theme.palette.text.primary,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.main,
      'path, g, circle': {
        fill: theme.palette.text.contrast,
      },
      h6: {
        color: theme.palette.text.contrast,
      },
    },
  },
  '.logout_box': {
    display: 'flex',
    alignItem: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1.25, 1),
    'path, g, circle': {
      fill: theme.palette.text.primary,
    },
    h6: {
      color: theme.palette.text.primary,
    },
    '&:hover': {
      path: {
        fill: theme.palette.text.contrast,
      },
      h6: {
        color: theme.palette.text.contrast,
      },
    },
  },
}));
