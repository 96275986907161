import { ReactComponent as Switch } from 'assets/icons/switch.svg';
import LoadingAnimated from 'components/LoadingAnimated';
import AssetsPortfolioDialog from 'components/WalletDialog/components/AssetsPortfolioDialog';
import {
  Currency,
  SwapPair,
  useSwapCurrencyMutation,
  useSwapPairsQuery,
  useUserWalletsQuery,
} from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import React, { memo, useMemo, useRef, useState } from 'react';
import { updateCacheUserWallet } from 'store/operations/update-cache-opts/updateCacheUserWallet';

import { CircularProgress, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { SelectCoinBox } from '../../styles';
import SelectCurrency from '../SelectCurrency';
import WalletDialogHeader from '../WalletDialogHeader';
import { ConfirmSwapButton, SwapWrapper, SwitchButton } from './styles';

// ----------------------------------------------------------------

const defaultCountdownTimeSeconds = 15;

interface CircleCountDownProgressProps {
  onCountDownEnd: VoidFunction;
}

const CircleCountDownProgress = memo(
  ({ onCountDownEnd }: CircleCountDownProgressProps) => {
    const [timer, setTimer] = useState(defaultCountdownTimeSeconds);
    const timerIntervalId = useRef(null);

    const countdown = () => {
      timerIntervalId.current = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) return prev - 0.2;

          onCountDownEnd();
          return defaultCountdownTimeSeconds;
        });
      }, 200);
    };

    React.useEffect(() => {
      countdown();
      return () => {
        clearInterval(timerIntervalId.current);
      };
    }, []);

    return (
      <CircularProgress
        variant="determinate"
        color="secondary"
        thickness={4}
        style={{
          width: 20,
        }}
        value={0 + (1 - timer / defaultCountdownTimeSeconds) * 100}
      />
    );
  },
);

interface Props {
  handleCloseWallet: VoidFunction;
}

type SwappableCurrency = Currency & {
  swappableTo: Array<Currency & SwapPair>;
};

interface SwapData {
  from: {
    amount: string | number;
    currency: Currency;
  };
  to: {
    amount: string | number;
    currency: Currency & Partial<{ minimumAmount: number }>;
    rate: number;
  };
}

const Swap = ({ handleCloseWallet }: Props) => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useResponsive('down', 'sm');
  const isLargeDesktop = useResponsive('up', 'lg');
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const { data: userWallets } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });

  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(null);
  const [isAssetPortfolioOpen, setIsAssetPortfolioOpen] = useState(false);
  const [swapData, setSwapData] = useState<SwapData>({
    from: {
      currency: null,
      amount: null,
    },
    to: {
      currency: null,
      amount: null,
      rate: 0,
    },
  });

  const {
    data: swapPairsData,
    refetch,
    loading,
  } = useSwapPairsQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [swap] = useSwapCurrencyMutation();

  const swappableFromCurrencies: SwappableCurrency[] = useMemo(() => {
    const result = swapPairsData?.swapPairs?.reduce(
      (prevVal, { from, to, ...swapPair }) => {
        const currentSwappableCurrency = prevVal[from.id];

        const isAlreadyIn = Boolean(currentSwappableCurrency);

        if (isAlreadyIn) {
          return {
            ...prevVal,
            [from.id]: {
              ...currentSwappableCurrency,
              swappableTo: [
                ...currentSwappableCurrency.swappableTo,
                {
                  ...swapPair,
                  ...to,
                },
              ],
            },
          };
        }

        if (!isAlreadyIn) {
          return {
            ...prevVal,
            [from.id]: {
              ...from,
              swappableTo: [{ ...swapPair, ...to }],
            },
          };
        }

        return prevVal;
      },
      {},
    );

    return Object.values(result || {});
  }, [swapPairsData]);

  const swapsCurrencies = useMemo(() => {
    if (
      !swapData?.from?.currency?.id ||
      !swapData?.to?.currency?.id ||
      !selectedCurrency?.id
    )
      return [];

    if (swapData?.from?.currency.id === selectedCurrency?.id) {
      return swappableFromCurrencies?.filter(
        (item) => item.id !== swapData?.to.currency?.id,
      );
    }

    if (swapData?.to?.currency.id === selectedCurrency?.id) {
      return swappableFromCurrencies?.filter(
        (item) => item.id !== swapData?.from.currency?.id,
      );
    }

    return [];
  }, [
    swappableFromCurrencies,
    swapData?.from?.currency,
    selectedCurrency,
    swapData?.to.currency,
  ]);

  const handleSelectCurrency = (currency: SwappableCurrency) => {
    if (selectedCurrency?.id === swapData?.from.currency?.id) {
      setSwapData((prev) => ({
        from: {
          ...prev.from,
          currency,
        },
        to: {
          ...prev.to,
          currency: currency.swappableTo[0],
        },
      }));

      return;
    }

    const fromCurrency = swappableFromCurrencies.find((cur) =>
      cur.swappableTo.some((cur) => cur.id === currency.id),
    );

    const toCurrency = fromCurrency.swappableTo.find(
      (cur) => cur.id === currency.id,
    );

    setSwapData((prev) => ({
      ...prev,
      to: {
        ...prev.to,
        currency: toCurrency,
      },
      from: {
        ...prev.from,
        currency: fromCurrency || swappableFromCurrencies[0],
      },
    }));
  };

  const handleSwitch = () => {
    const isNotAllowedSwitch = !swappableFromCurrencies
      .find((currency) => currency.id === swapData.to.currency?.id)
      ?.swappableTo?.find(
        (currency) => currency.id === swapData.from.currency?.id,
      );

    if (isNotAllowedSwitch) return;

    const toCurrency = swappableFromCurrencies
      .find((currency) => currency.id === swapData.to.currency.id)
      ?.swappableTo.find(
        (currency) => currency.id === swapData.from.currency.id,
      );

    setSwapData(({ to: { rate, currency, amount }, from }) => ({
      from: {
        ...from,
        currency,
      },
      to: {
        amount,
        currency: toCurrency,
        rate: 1 / rate,
      },
    }));
  };

  const isInsufficientBalance = React.useMemo(() => {
    const fromBalanceAmount = userWallets?.me?.userWallets?.find(
      (item) => item.currencyId === swapData?.from?.currency?.id,
    )?.amount;

    return fromBalanceAmount < swapData?.from?.amount;
  }, [swapData?.from, userWallets]);

  const handleSubmitSwap = async () => {
    if (isInsufficientBalance) {
      enqueueSnackbar(translate('insufficient_balance'), { variant: 'error' });
      return;
    }

    if (!swapData?.from?.amount) {
      enqueueSnackbar(translate('please_enter_swap_amount'), {
        variant: 'error',
      });
      return;
    }

    if (
      Number(swapData?.from?.amount) < swapData?.to?.currency?.minimumAmount
    ) {
      enqueueSnackbar(translate('swap_amount_need_to_larger_than_min'), {
        variant: 'error',
      });
      return;
    }

    try {
      const response = await swap({
        variables: {
          fromId: swapData?.from?.currency?.id,
          toId: swapData?.to.currency?.id,
          amount: Number(swapData?.from?.amount),
        },
      });

      if (response?.data?.swap?.error?.message) {
        enqueueSnackbar(translate(response.data.swap.error.code), {
          variant: 'error',
        });
        return;
      }

      if (response?.data?.swap?.success) {
        enqueueSnackbar(translate('swap_successfully'), { variant: 'success' });
        updateCacheUserWallet({
          amount: response.data.swap.content.amount * -1,
          currencyId: response?.data?.swap?.content?.from.id,
        });
        updateCacheUserWallet({
          amount:
            response.data.swap.content.amount * response.data.swap.content.rate,
          currencyId: response?.data?.swap?.content?.to.id,
        });
        handleCloseWallet();
      }
    } catch (_) {
      enqueueSnackbar(translate('swap_failed'), { variant: 'error' });
    }
  };

  const swapFee = useMemo(() => {
    if (!swapData?.from?.amount || !swapData?.to?.currency) return 0;

    let swapFee = 0;

    swappableFromCurrencies.forEach((swapFrom) => {
      const swapToCurrency = swapFrom.swappableTo.find(
        (item) => item.id === swapData?.to?.currency.id,
      );

      if (!swapToCurrency) return;

      swapFee =
        swapToCurrency.feeAmount +
          Number(swapData.from.amount) * swapToCurrency.feeRate || 0;
    });

    return swapFee || 0;
  }, [swapData]);

  const receiveAmount = useMemo(() => {
    const swapFeeInEndCurrency = swapFee * (swapData.to.rate || 1);

    const receiveAmount = Number(swapData?.to?.amount) - swapFeeInEndCurrency;

    return receiveAmount > 0 ? receiveAmount : 0;
  }, [swapFee, swapData?.to]);

  React.useEffect(() => {
    const initialMinimumAmount =
      swappableFromCurrencies[0]?.swappableTo[0]?.minimumAmount || 0;

    if (!swapData.from.currency)
      setSwapData({
        from: {
          amount: initialMinimumAmount || null,
          currency: swappableFromCurrencies[0],
        },
        to: {
          amount: initialMinimumAmount
            ? initialMinimumAmount *
              (swappableFromCurrencies[0]?.swappableTo[0]?.rate || 0)
            : null,
          currency: swappableFromCurrencies[0]?.swappableTo[0],
          rate: swappableFromCurrencies[0]?.swappableTo[0].rate || 0,
        },
      });
    else {
      const newRate = swappableFromCurrencies
        .find((currency) => currency.id === swapData.from.currency?.id)
        ?.swappableTo?.find(
          (currency) => currency.id === swapData.to.currency.id,
        )?.rate;

      setSwapData((prev) => ({
        ...prev,
        to: {
          ...prev.to,
          rate: newRate || 0,
        },
      }));
    }
  }, [
    swappableFromCurrencies,
    swapData?.from?.currency,
    swapData?.to?.currency,
  ]);

  React.useEffect(() => {
    if (!swapData?.from?.amount) return;

    setSwapData((prev) => ({
      ...prev,
      to: {
        ...prev.to,
        amount: Number(swapData.from.amount) * swapData.to.rate,
      },
    }));
  }, [swapData?.from.amount, swapData?.to?.rate]);

  return (
    <SwapWrapper>
      {isLargeDesktop && (
        <WalletDialogHeader
          handleClose={handleCloseWallet}
          title="swap"
          hideCloseIcon={isMobile}
        />
      )}
      {swapPairsData?.swapPairs.length > 0 ? (
        <Stack
          // justifyContent="space-between"
          flex={1}
          sx={{ mt: 6 }}
          {...(loading && {
            sx: {
              opacity: 0.6,
            },
          })}
        >
          <Box>
            <SelectCoinBox>
              <SelectCurrency
                amount={swapData.from.amount}
                selectedCurrency={swapData.from.currency}
                setAmount={(amount) => {
                  const am =
                    amount &&
                    Number(amount) < swapData.to?.currency?.minimumAmount
                      ? swapData.to?.currency?.minimumAmount
                      : amount;
                  setSwapData((prev) => ({
                    from: {
                      ...prev.from,
                      amount: amount ? am : null,
                    },
                    to: {
                      ...prev.to,
                      amount: am * swapData.to.rate,
                    },
                  }));
                }}
                setIsAssetPortfolioOpen={() => {
                  setSelectedCurrency(swapData.from.currency);
                  setIsAssetPortfolioOpen(true);
                }}
                onBlur={(amount: any) => {
                  const am =
                    Number(amount) < swapData.to?.currency?.minimumAmount
                      ? swapData.to?.currency?.minimumAmount
                      : amount;
                  setSwapData((prev) => ({
                    from: {
                      ...prev.from,
                      amount: am,
                    },
                    to: {
                      ...prev.to,
                      amount: am * swapData.to.rate,
                    },
                  }));
                }}
              />
            </SelectCoinBox>
            <Box display="flex" justifyContent="center" alignItems="center">
              <SwitchButton onClick={handleSwitch}>
                <Switch width="32px" height="32px" />
              </SwitchButton>
            </Box>
            <SelectCoinBox sx={{ marginTop: '12px' }}>
              <SelectCurrency
                amount={swapData.to.amount}
                selectedCurrency={swapData.to.currency}
                disabledInputAmount
                setIsAssetPortfolioOpen={() => {
                  setSelectedCurrency(swapData.to.currency);
                  setIsAssetPortfolioOpen(true);
                }}
              />
            </SelectCoinBox>
            <Box className="exchange_box">
              <div className="exchange_rate">
                {swapPairsData?.swapPairs.length > 0 && (
                  <CircleCountDownProgress
                    onCountDownEnd={() => {
                      refetch();
                    }}
                  />
                )}
                <Typography className="amount" textTransform="uppercase">
                  {`1 ${swapData?.from?.currency?.shortName} `}&sim;
                  {` ${getCurrencyAmount(
                    Number(swapData.to.rate),
                    swapData?.to.currency,
                    { onlyCurrency: true },
                  )} ${swapData?.to?.currency?.shortName}`}
                </Typography>
              </div>
              <div className="route">
                <Typography className="label">
                  {translate('swap_fee')}
                </Typography>
                <Typography className="amount" textTransform="uppercase">
                  {`${getCurrencyAmount(swapFee, swapData.from.currency, {
                    onlyCurrency: true,
                  })} ${swapData?.from?.currency?.shortName}`}
                </Typography>
              </div>
              <div className="route">
                <Typography className="label">{`${translate(
                  'receive_amount',
                )}`}</Typography>
                <Typography className="amount">{`${getCurrencyAmount(
                  receiveAmount,
                  swapData.to.currency,
                  { onlyCurrency: true },
                )} ${swapData?.to?.currency?.shortName.toUpperCase()}`}</Typography>
              </div>
            </Box>
          </Box>
          <ConfirmSwapButton
            size="large"
            variant="contained"
            sx={{ minHeight: 48, mb: { sm: 0, md: 2 } }}
            onClick={() => handleSubmitSwap()}
          >
            {translate('swap')}
          </ConfirmSwapButton>
        </Stack>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          {!loading && (
            <Typography whiteSpace="pre-wrap">
              {translate('no_found_any_currency_can_swap')}
            </Typography>
          )}
        </Box>
      )}
      {selectedCurrency && (
        <AssetsPortfolioDialog
          isOpen={isAssetPortfolioOpen}
          currencies={swapsCurrencies}
          selectedCurrency={selectedCurrency}
          handleSelectCurrency={handleSelectCurrency}
          handleClose={() => {
            // handleCloseWallet();
            setIsAssetPortfolioOpen(false);
          }}
          handleBack={() => {
            setIsAssetPortfolioOpen(false);
          }}
        />
      )}
      {loading && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          sx={{
            opacity: 0.6,
          }}
        >
          <LoadingAnimated />
        </Box>
      )}
    </SwapWrapper>
  );
};

export default Swap;
