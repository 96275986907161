import { ReactiveVar } from '@apollo/client';
import { IS_HIDE_SMALL } from 'constants/localStorage';
import { HideSmallWalletReactiveVar } from 'graph';

export default (
    hideSmallWalletAmountVar: ReactiveVar<HideSmallWalletReactiveVar>,
  ) =>
  (
    hide: boolean,
    options?: {
      onCompleted: (hideSmallCurrency: boolean) => void;
    },
  ) => {
    hideSmallWalletAmountVar({
      hideSmallWalletAmount: hide,
    });

    localStorage.setItem(IS_HIDE_SMALL, JSON.stringify(hide));

    /* -------------------------------------------------------------------------- */
    if (options?.onCompleted) {
      setTimeout(() => {
        options.onCompleted(hide);
      }, 0);
    }
  };
