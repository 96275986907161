import { Profile, UserProfileDocument, UserProfileQuery } from 'graph';
import { cache } from 'graph/cache';
import { DeepPartial } from 'react-hook-form';

export const updateCacheUserProfile = (
  updatedProfile: DeepPartial<Profile>,
) => {
  const currentUserProfile: UserProfileQuery = cache.readQuery({
    query: UserProfileDocument,
  });

  if (currentUserProfile) {
    cache.modify({
      id: cache.identify(currentUserProfile),
      fields: {
        ...currentUserProfile,
        updatedProfile,
      } as any,
    });
  }
};
