import { useReactiveVar } from '@apollo/client';
import TranFiLogo from 'assets/icons/tranfi-logo.png';
import axios from 'axios';
import {
  Currency,
  TransfiFiat,
  useFiatsQuery,
  useTransfiRequestBuyCryptoMutation,
} from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import useIPRegistry from 'locales/useIPRegistry';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { currenciesVar } from 'store';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { SelectCoinBox } from '../../styles';
import AssetsPortfolioDialog from '../AssetsPortfolioDialog';
import SelectCurrency from '../SelectCurrency';
import WalletDialogHeader from '../WalletDialogHeader';
import { BuyWrapper, ThemedTranFiButton } from './styles';

/* -------------------------------------------------------------------------- */

// const FiatObJ = fiatData.data.transfiSupportedFiats;
const ApiKey = '8620cfbe32-4f7f3e26c4-s8q8ar';

const FiatsOK = [
  'USD',
  'PHP',
  'THB',
  'IDR',
  'JPY',
  'MXN',
  'ARS',
  'EUR',
  'BRL',
  'GBP',
  'VND',
];

type ForexConvert = {
  base: string;
  amount: number;
  result: {
    USD: number;
    rate: number;
  };
};

const forex: (
  from: string,
  to: string,
  amount: number,
) => Promise<ForexConvert> = async (
  from: string,
  to: string,
  amount: number,
) => {
  const response = await axios.get(
    `https://api.fastforex.io/convert?from=${from}&to=${to}&amount=${amount}&api_key=${ApiKey}`,
  );
  return response.data;
};

interface BuyData {
  fiat: {
    amount: string | number;
    fiat: TransfiFiat & Partial<{ minimumAmount: number }>;
  };
  currency: {
    amount: string | number;
    currency: Currency & Partial<{ minimumAmount: number }>;
    rate: number;
  };
}

interface BuyCryptoProps {
  handleCloseWalletDialog: VoidFunction;
  handleClose: () => void;
}

const BuyCrypto = ({ handleCloseWalletDialog }: BuyCryptoProps) => {
  const locationInfo = useIPRegistry();
  const { currencies } = useReactiveVar(currenciesVar);
  const [isAssetPortfolioOpen, setIsAssetPortfolioOpen] = useState(false);
  const [isAssetFiatOpen, setIsAssetFiatOpen] = useState(false);
  const [needUpdateAmount, setNeedUpdateAmount] = useState(false);
  const { data: fiats } = useFiatsQuery();
  const [openTranfiLoading, setOpenTranfiLoading] = useState(false);

  const fiatsSupport: any[] = React.useMemo(() => {
    if (!fiats?.transfiSupportedFiats) return;
    const results = _.groupBy(
      fiats.transfiSupportedFiats.filter((fiat) => {
        const lowercaseFiat = fiat?.symbol.toUpperCase();
        return FiatsOK.includes(lowercaseFiat);
      }),
      (r) => r.symbol,
    );
    const outs = [];
    _.forEach(results, (r) => {
      outs.push({
        ...r[0],
        ...(r[0].symbol === 'USD' && {
          country: 'United States',
        }),
      });
    });
    return outs.reverse();
  }, [fiats]);

  const [BuyTranFi] = useTransfiRequestBuyCryptoMutation();

  const [buyData, setBuyData] = useState<BuyData>({
    fiat: {
      fiat: null,
      amount: null,
    },
    currency: {
      currency: currencies.find((r) => r.id === '5'),
      amount: null,
      rate: 0,
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');

  const BuyCurrencies = useMemo(
    () =>
      currencies.filter(
        (currency) =>
          currency.id &&
          currency.id !== '1' &&
          currency.id !== '2' &&
          currency.id !== '7' &&
          currency.id !== '13',
      ),
    [currencies],
  );

  useEffect(() => {
    if (fiatsSupport) {
      const fiatSP =
        fiatsSupport?.find((f) => f.symbol === locationInfo?.currency?.code) ||
        fiatsSupport?.find((f) => f.symbol === 'USD');

      forex(fiatSP?.symbol, 'USD', fiatSP?.minAmount).then((convertToUSD) => {
        setNeedUpdateAmount(true);
        setBuyData((prev) => ({
          ...prev,
          fiat: {
            fiat: fiatSP,
            amount: fiatSP?.minAmount,
          },
          currency: {
            ...prev.currency,
            amount:
              convertToUSD.result.USD /
              prev.currency.currency.equivalentUsdRate,
            rate: convertToUSD.result.rate,
          },
        }));
      });
    }
  }, [fiatsSupport]);

  const handleSelect = async (asset: any) => {
    const isFiat = (asset as TransfiFiat).symbol !== undefined;
    if (isFiat) {
      setNeedUpdateAmount(true);
      const convertToUSD = await forex(asset.symbol, 'USD', asset.minAmount);
      setBuyData((prev) => ({
        ...prev,
        fiat: {
          fiat: asset,
          amount: asset.minAmount,
        },
        currency: {
          ...prev.currency,
          amount:
            convertToUSD.result.USD / prev.currency.currency.equivalentUsdRate,
          rate: convertToUSD.result.rate,
        },
      }));
    } else {
      setBuyData((prev) => ({
        ...prev,
        currency: {
          ...prev.currency,
          currency: asset,
          amount:
            (Number(buyData.fiat.amount) * prev.currency.rate) /
            (asset as Currency).equivalentUsdRate,
        },
      }));
    }
  };

  const handleSubmitSwap = () => {
    setOpenTranfiLoading(true);

    BuyTranFi({
      variables: {
        currencyId: Number(buyData.currency.currency.id),
        fiat: buyData.fiat.fiat.symbol,
        amount: Number(buyData.fiat.amount),
        returnUrl: `http://${window.location.host}?buy_crypto=${buyData?.currency?.currency?.shortName}|${buyData.fiat.fiat.symbol}`,
      },
    })
      .then((value) => {
        if (value.errors) {
          enqueueSnackbar(translate('something_is_wrong'), {
            variant: 'error',
          });
          setOpenTranfiLoading(false);
        } else if (value?.data?.transfiRequestBuyCrypto?.error?.message) {
          enqueueSnackbar(
            value?.data?.transfiRequestBuyCrypto?.error?.message,
            { variant: 'error' },
          );
          setOpenTranfiLoading(false);
        } else {
          window.location.href = value?.data?.transfiRequestBuyCrypto?.content;
        }
      })
      .catch(() => {
        enqueueSnackbar(translate('something_is_wrong'), { variant: 'error' });
        setOpenTranfiLoading(false);
      });
  };

  return (
    <BuyWrapper>
      <Box justifyContent="space-between" className="top_box">
        {!isMobile && !isTablet && (
          <WalletDialogHeader
            handleClose={handleCloseWalletDialog}
            title="buy Crypto"
            hideCloseIcon={isMobile}
          />
        )}
        <Box>
          <Typography className="label">{`${translate(
            'buy_fiat',
          )}`}</Typography>
          <SelectCoinBox>
            <SelectCurrency
              amount={buyData.fiat.amount}
              selectedCurrency={buyData.fiat.fiat}
              setAmount={(amount) => {
                setNeedUpdateAmount(false);
                let am =
                  Number(amount) > buyData.fiat.fiat.maxAmount
                    ? buyData.fiat.fiat.maxAmount
                    : amount;
                am =
                  Number(am) < buyData.fiat.fiat.minAmount
                    ? buyData.fiat.fiat.minAmount
                    : amount;
                setBuyData((prev) => ({
                  ...prev,
                  fiat: {
                    ...prev.fiat,
                    amount: am,
                  },
                  currency: {
                    ...prev.currency,
                    amount:
                      (Number(am) * prev.currency.rate) /
                      prev.currency.currency.equivalentUsdRate,
                  },
                }));
              }}
              setIsAssetPortfolioOpen={() => {
                setIsAssetFiatOpen(true);
              }}
              hiddenExchangeFiat
              hiddenBalance
              needUpdateAmount={needUpdateAmount}
            />
          </SelectCoinBox>
        </Box>

        <Box>
          <Typography className="label">{`${translate(
            'buy_crypto',
          )}`}</Typography>
          <SelectCoinBox>
            <SelectCurrency
              disabledInputAmount
              amount={buyData.currency.amount}
              selectedCurrency={buyData.currency.currency}
              setAmount={() => {}}
              setIsAssetPortfolioOpen={() => {
                setIsAssetPortfolioOpen(true);
              }}
            />
          </SelectCoinBox>
        </Box>

        <Box className="notice">
          <Typography variant="subtitle1">
            {translate('disclaimer')}:
          </Typography>
          <Typography>{translate('the_above_third_party_HOST')}</Typography>
        </Box>
      </Box>

      <ThemedTranFiButton
        disabled={openTranfiLoading}
        size="large"
        variant="contained"
        fullWidth
        sx={{
          mt: 5,
          mb: { sm: 0, md: 2 },
        }}
        endIcon={
          !openTranfiLoading ? (
            <img src={TranFiLogo} alt="tranfilogo" style={{ height: 36 }} />
          ) : (
            <img
              src="assets/icon-circle-loading.svg"
              alt="tranfilogo"
              style={{ height: 36 }}
            />
          )
        }
        onClick={handleSubmitSwap}
      >
        {openTranfiLoading ? '' : translate('buy_with_transFI')}
      </ThemedTranFiButton>

      {buyData.fiat.fiat && (
        <AssetsPortfolioDialog
          title="Select Fiat"
          isOpen={isAssetFiatOpen}
          currencies={fiatsSupport}
          selectedCurrency={buyData.fiat.fiat}
          handleSelectCurrency={handleSelect}
          handleClose={() => {
            setIsAssetFiatOpen(false);
          }}
          handleBack={() => {
            setIsAssetFiatOpen(false);
          }}
          hiddenBalance
          hiddenFooter
        />
      )}

      {buyData.currency.currency && (
        <AssetsPortfolioDialog
          isOpen={isAssetPortfolioOpen}
          currencies={BuyCurrencies}
          selectedCurrency={buyData.currency.currency}
          handleSelectCurrency={handleSelect}
          handleClose={() => {
            setIsAssetPortfolioOpen(false);
          }}
          handleBack={() => {
            setIsAssetPortfolioOpen(false);
          }}
          // hiddenBalance
          // hiddenFooter
        />
      )}
    </BuyWrapper>
  );
};

export default BuyCrypto;
