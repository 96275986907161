import Winner from 'components/_Events/_CrashEvent/Winner';
import {
  CrashPredictorEvent,
  FunCrashPredictorEvent,
  FunCrashPredictorResult,
  useFunCrashPredictorLazyQuery,
} from 'graph';
import lodashHelper from 'lodash';
import { memo, SetStateAction, useEffect, useMemo } from 'react';

interface Props {
  setData: React.Dispatch<
    SetStateAction<
      Pick<
        CrashPredictorEvent | FunCrashPredictorEvent,
        'end' | 'start' | 'crashPredictorResults'
      >
    >
  >;
  selectedDate: Array<Date | number>;
  gameId: string;
}

function FunContest({ gameId, setData, selectedDate }: Props) {
  const [getCrashPredictor, { data: funCrashPredictorData, loading }] =
    useFunCrashPredictorLazyQuery({
      variables: {
        date: selectedDate[0],
        gameId,
      },
    });

  const sortedCrashPredictorList = useMemo(
    () =>
      lodashHelper.orderBy(
        funCrashPredictorData?.funCrashPredictor?.crashPredictorResults || [],
        ['rank'],
        'asc',
      ),
    [funCrashPredictorData],
  );

  useEffect(() => {
    getCrashPredictor({
      variables: {
        date: selectedDate[0],
        gameId,
      },
      fetchPolicy: 'network-only',
    });
  }, [selectedDate[0]]);

  useEffect(() => {
    if (funCrashPredictorData !== undefined) {
      setData({
        end: funCrashPredictorData?.funCrashPredictor?.end,
        start: funCrashPredictorData?.funCrashPredictor?.start,
        crashPredictorResults: funCrashPredictorData?.funCrashPredictor
          ?.crashPredictorResults as FunCrashPredictorResult[],
      });
    }
  }, [funCrashPredictorData]);

  return (
    <Winner
      data={sortedCrashPredictorList as FunCrashPredictorResult[]}
      myPredictor={funCrashPredictorData?.funCrashPredictor?.myPredictor}
      loading={loading}
      isFun
    />
  );
}

export default memo(FunContest);
