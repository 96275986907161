import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import useResponsive from 'hooks/useResponsive';
import useLocales from 'locales/useLocales';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Collapse, Divider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { newIcon } from 'assets/images';
import { NavListProps } from '../types';
import { NavigationItemWrapper } from './styles';

// =================================================================

interface Props {
  navItem: NavListProps;
  handleCloseNav: VoidFunction;
}

const NavigationItem = ({ navItem, handleCloseNav }: Props) => {
  const { translate } = useLocales();
  const [isCollapse, setIsCollapse] = useState(false);

  const theme = useTheme();

  const isMobile = useResponsive('down', 'sm');
  const { pathname } = useLocation();

  const checkIsActive = (path: string) =>
    pathname !== '/' && path.includes(pathname.replace('/', ''));

  return (
    <NavigationItemWrapper
      className={`nav-item-wrapper ${navItem.type} ${navItem.key}`}
    >
      {navItem.type === 'item' && (
        <Box
          className={`item__flex item--single ${
            pathname === navItem?.path ? 'active' : ''
          }`}
          {...(navItem?.path?.includes('http') && {
            target: '_blank',
          })}
          {...(navItem?.path &&
            !navItem?.link && {
              component: Link,
            })}
          {...(navItem?.path &&
            !navItem?.link && {
              to: navItem?.path,
            })}
          {...(navItem?.link && {
            onClick: () => {
              window.open(navItem?.link, '_blank');
            },
          })}
          {...(!navItem?.link &&
            !navItem?.path && {
              onClick: () => {
                if (navItem?.click) navItem?.click();
                if (isMobile) {
                  handleCloseNav();
                }
              },
            })}
        >
          <Box className="icon__box">{navItem.icon}</Box>
          <Typography variant="subtitle2">{translate(navItem.key)}</Typography>
        </Box>
      )}

      {navItem.type === 'ohplay' && process.env.REACT_APP_SITE === 'ohplay' && (
        <Box
          className={`nav-ohplay item--single ${
            pathname === navItem?.path ? 'active' : ''
          }`}
          {...(navItem?.path?.includes('http') && {
            target: '_blank',
          })}
          {...(navItem?.path &&
            !navItem?.link && {
              component: Link,
              to: navItem?.path,
            })}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {navItem.icon}
            <Typography variant="subtitle2">
              {translate(navItem.key)}
            </Typography>
          </Box>

          <img src={newIcon} />
        </Box>
      )}

      {navItem.type === 'grouped' &&
        navItem.items.map((item) => (
          <Box
            className={`item__flex ${item.key} ${
              checkIsActive(item.path) ? 'active' : ''
            }`}
            key={item.key}
            component={Link}
            to={item?.path}
            onClick={() => {
              if (isMobile) {
                handleCloseNav();
              }
            }}
          >
            <Box className="icon__box">{item.icon}</Box>
            <Typography variant="subtitle2">{translate(item.key)}</Typography>
          </Box>
        ))}

      {navItem.type === 'collapse' && (
        <>
          <Box
            className={`item__flex item--main ${navItem.type} ${navItem.key} ${
              checkIsActive(navItem.path) ? 'active' : ''
            }`}
            onClick={() => {
              setIsCollapse((prev) => !prev);
            }}
            sx={{
              backgroundColor: isCollapse && theme.palette.background.secondary,
              backgroundImage: !isCollapse
                ? 'linear-gradient(180deg, #313064 0%, #43416C 100%)'
                : 'none',
              borderRadius: `${theme.shape.borderRadius}px`,
              color: 'text.primary',
            }}
          >
            <Box className="icon__box">{navItem.icon}</Box>
            <Typography variant="subtitle2">
              {translate(navItem.key)}
            </Typography>
            <ArrowDown
              className={`collapse_icon ${isCollapse ? 'collapsed' : ''}`}
              width="30px"
              height="30px"
            />
          </Box>
          <Collapse in={!isCollapse} timeout="auto" unmountOnExit>
            {navItem.items.map((item) => (
              <Box
                sx={{
                  justifyContent: 'space-between',
                }}
                className={`item__flex ${item.key} ${
                  checkIsActive(item.path) ? 'active' : ''
                }`}
                key={item.key}
                to={item?.path}
                component={Link}
                {...(item?.path &&
                  !item?.link && {
                    component: Link,
                  })}
                {...(item?.path &&
                  !item?.link && {
                    to: item?.path,
                  })}
                {...(item?.link && {
                  onClick: () => {
                    window.open(item?.link, '_blank');
                  },
                })}
                {...(!item?.link &&
                  !item?.path && {
                    onClick: () => {
                      if (item?.click) item?.click();
                      if (isMobile) {
                        handleCloseNav();
                      }
                    },
                  })}
              >
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Box className="icon__box">{item.icon}</Box>

                  <Typography variant="subtitle2">
                    {translate(item.key)}
                  </Typography>
                </Box>

                {item.key === 'Crash' && (
                  <Typography
                    component="span"
                    sx={{
                      fontSize: 13,
                      fontWeight: 600,
                      color: theme.palette.text.contrast,
                      lineHeight: 'normal',
                      background: '#FF7A00',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: theme.spacing(1),
                      padding: theme.spacing(0, 1),
                    }}
                  >
                    Event
                  </Typography>
                )}
              </Box>
            ))}
          </Collapse>

          <Divider
            sx={{
              margin: '10px 0',
              borderColor: '#2F2B60',
            }}
          />
        </>
      )}
    </NavigationItemWrapper>
  );
};

export default NavigationItem;
// grouped_item;
