import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { hiddenUser, USD } from 'assets/images';
import { Top1, Top2, Top3 } from 'assets/images/Chat';

import { Avatar, Box, IconButton, Slide } from '@mui/material';

import { useTodayTopBiggestWinQuery } from 'graph';
import { useLocales } from 'locales';
import { localStringNumber } from 'utils/number-help';
import {
  BigWinnerHeader,
  BoxBigWinWrapper,
  HeaderBigWinner,
  ItemBigwin,
  ListUserBigWin,
  NameUserWinner,
  UserBigwin,
  ValueBigWinner,
} from './styles';

interface IBoxBigWinProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface RankBiggestBet {
  rank: number;
}
const GetRankBiggest = (props: RankBiggestBet) => {
  switch (props.rank) {
    case 1:
      return <img src={Top1} width={21} height={24} />;
    case 2:
      return <img src={Top2} width={21} height={24} />;
    case 3:
      return <img src={Top3} width={21} height={24} />;
    default:
      break;
  }
};

export const BoxBigWin = (props: IBoxBigWinProps) => {
  const { isOpen, handleClose } = props;
  const { translate } = useLocales();

  const { data: topUserBiggest } = useTodayTopBiggestWinQuery();

  return (
    <Slide in={isOpen} direction="up" mountOnEnter unmountOnExit>
      <BoxBigWinWrapper>
        <BigWinnerHeader>
          <HeaderBigWinner>
            {translate('today_biggest_winners')}
          </HeaderBigWinner>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 0,
            }}
          >
            <CloseCircle width="24px" height="24px" />
          </IconButton>
        </BigWinnerHeader>
        <ListUserBigWin>
          {topUserBiggest?.todayTopBiggestWin.map((e) => (
            <UserBigwin key={e.rank}>
              <ItemBigwin>
                <GetRankBiggest rank={e.rank} />
                <Avatar
                  sizes="small"
                  sx={{ height: '20px', width: '20px' }}
                  src={e.user ? e.user?.avatar?.square : hiddenUser}
                />
                <NameUserWinner>
                  {e.user ? e.user?.nickname : translate('hidden')}
                </NameUserWinner>
              </ItemBigwin>
              <ItemBigwin>
                <ValueBigWinner>
                  {localStringNumber(e.totalWinAmountUsd)}
                </ValueBigWinner>
                <Box component="img" src={USD} width="24px" height="24px" />
              </ItemBigwin>
            </UserBigwin>
          ))}
        </ListUserBigWin>
      </BoxBigWinWrapper>
    </Slide>
  );
};
