import { styled, TableContainer } from '@mui/material';

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  overflowY: 'auto',
  table: {
    borderSpacing: '0px 0px',
    'tr:nth-child(even)': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'rgba(22, 20, 50, 0.50)',
    },
    // sticky row
    'tr.sticky-row': {
      position: 'sticky',
      backgroundColor: 'rgba(22, 20, 50)',
      '&.sticky-row-bottom': {
        bottom: 0,
      },
    },
    'tr.my-rank': {
      '.MuiTableCell-body, &.MuiTableCell-body p': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
    },
  },
  'tr th, .MuiTableCell-root': {
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '&:last-of-type': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  '.MuiTableCell-root': {
    '.Mui-active': {
      color: theme.palette.text.contrast,
    },
    padding: theme.spacing(1.25, 2),
    fontWeight: 500,
    color: theme.palette.text.contrast,
    backgroundColor: 'transparent',
    backgroundImage: 'unset',
    '&.MuiTableCell-head, &.MuiTableCell-head p': {
      whiteSpace: 'nowrap',
      backgroundColor: '#2F2B60',
      fontWeight: 600,
      fontSize: 14,
      color: theme.palette.text.primary,
      lineHeight: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.25, 1.5),
    },
    'img, img.currency-image': {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      width: 20,
      height: 20,
      aspectRatio: '1/1',
    },
    '&.MuiTableCell-body': {
      '&.primary-green, & p.primary-green': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
