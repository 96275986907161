import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const AvatarBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: '24px',
  '.avatar': {
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: 110,
      height: 110,
    },
    [theme.breakpoints.down('lg')]: {
      width: 80,
      height: 80,
    },
  },
  '.MuiSvgIcon-root': {
    cursor: 'pointer',
  },
  '.upload-btn': {
    right: '50%',
    top: '98%',
  },
  '.copy_btn': {
    cursor: 'pointer',
    padding: 'unset',
    path: {
      fill: theme.palette.text.primary,
    },
    'svg:hover': {
      path: {
        fill: theme.palette.text.contrast,
      },
    },
  },
}));

export const GeneralContentGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  borderRadius: theme.shape.borderRadius,
  marginTop: 0,
  padding: theme.spacing(3),
  '.label': {
    marginBottom: theme.spacing(1.5),
    fontWeight: 600,
    textTransform: 'capitalize',
    '&.id_field': {
      textTransform: 'uppercase',
    },
  },
  '& .MuiGrid-container': {
    maxWidth: 'unset',
    flexGrow: 1,
  },
  '.button_grid': {
    flexShrink: 4,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
}));

export const InputIdBox = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 400,
  },
  '.id_number': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 2.5),
  },
  '.copy_btn': {
    cursor: 'pointer',
    padding: 'unset',
    path: {
      fill: theme.palette.text.primary,
    },
    'svg:hover': {
      path: {
        fill: theme.palette.text.contrast,
      },
    },
  },
}));

export const NicknameBox = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 400,
  },
  '.nickname': {
    '.MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export const HideProfileRatioBox = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 400,
  },
  display: 'flex',
  alignItems: 'center',
  '.MuiSwitch-track': {
    backgroundColor: theme.palette.text.contrast,
  },
}));

export const VerifyEmailBox = styled(Grid)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.up('lg')]: {
    minWidth: 400,
  },

  '.email': {
    '.MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export const VerifyPhoneNumberBox = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 400,
  },
  '.verify_phone_btn': {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  '& .MuiOutlinedInput-root': {
    input: {
      color: theme.palette.common.white,
    },
    color: theme.palette.error.main,
    paddingRight: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
    border: 'none',
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
}));
