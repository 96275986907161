import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const TransactionBodyWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '.tab_list': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  '.view_history': {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    h6: {
      width: 'fit-content',
      cursor: 'pointer',
    },
  },
  '.select_filter': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRight: `2px solid ${theme.palette.background.secondary}`,
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.up(700)]: {
      maxHeight: 530,
    },
    '&.coin': {
      padding: theme.spacing(1.5),
      backgroundColor: theme.palette.background.secondary,
    },
    '.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.main,
      color: theme.palette.text.primary,
    },
    '& .MuiTypography-root': {
      lineHeight: 'normal',
      display: 'flex',
      alignUtems: 'center',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
        border: 'none',
        color: theme.palette.text.primary,
      },
      '.MuiButtonBase-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    '.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
    '.MuiSvgIcon-root': {
      color: theme.palette.text.contrast,
    },
  },
  '.bill_content': {
    flex: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '.select_form': {
        minWidth: 150,
      },
      '.MuiSelect-select': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
  },
}));

export const TransactionTableBox = styled(Box)(({ theme }) => ({
  width: '100%',
  flex: 'auto',
  height: 550,
  paddingBottom: 15,
  [theme.breakpoints.down('lg')]: {
    paddingBottom: 50,
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: 30,
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 200,
  },
  '.MuiTable-root': {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  '.MuiTableHead-root': {
    '& th:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '& th:last-child': {
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
  '& .MuiTableHead-root tr th': {
    backgroundColor: theme.palette.background.secondary,
  },
  '.MuiTableContainer-root': {
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.table,
    padding: '12px',
    height: '100%',
  },
  '.MuiTableCell-head': {
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  '.MuiPaper-elevation': {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.main,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  'img.coin_img': {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(0.5),
  },
  [theme.breakpoints.down(500)]: {
    maxWidth: 120,
    'p, span': {
      fontSize: 10,
    },
    '&& .coin_img': {
      width: 12,
      height: 12,
    },
  },
  [theme.breakpoints.down(400)]: {
    maxWidth: 100,
    padding: theme.spacing(0.5),
  },
  [theme.breakpoints.down(330)]: {
    maxWidth: 90,
  },
  '&.status_success': {
    color: theme.palette.primary.main,
  },
  '&.status_failed': {
    color: theme.palette.error.main,
  },
  '&.status_pending': {
    color: theme.palette.support.orange,
  },
  '&.MuiTableCell-root': {
    borderBottom: 'none',
    backgroundImage: 'none',
  },
  '.view_detail': {
    '&:hover path': {
      fill: theme.palette.text.contrast,
    },
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({}));

export const DetailTransactionWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} 0`,
  '.row_item': {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '.status_success': {
      color: theme.palette.primary.main,
    },
    '.status_pending': {
      color: theme.palette.support.orange,
    },
  },
}));

export const PaginationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 24,
  '.pagination_number': {
    display: 'flex',
    span: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  '&&& .pagination_btn': {
    width: 24,
    borderRadius: 2,
    backgroundColor: theme.palette.background.secondary,
    margin: `0 ${theme.spacing(2)}`,
    '.MuiSvgIcon-root': {
      color: theme.palette.text.primary,
    },
    '&:hover .MuiSvgIcon-root': {
      color: theme.palette.text.contrast,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.hovering,
    },
  },
}));

export const SelectGroupBox = styled(Box)(({ theme }) => ({
  width: '100%',
  '.group_item': {
    height: 56,
    padding: theme.spacing(2.2, 5),
    cursor: 'pointer',
    minWidth: 162,
    p: {
      textTransform: 'capitalize',
      fontWeight: 500,
    },
    '&.active': {
      color: theme.palette.text.contrast,
      background: theme.palette.background.secondaryBgImage,
      borderRadius: theme.spacing(1.5),
    },
  },
}));

export const Tabs = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.palette.background.main,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1.5),
  '.wallet-tab-item': {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 'unset',
    width: 'unset',
    padding: theme.spacing(1, 3),
  },
}));

export const StyledTableHeaderSticker = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1.5),
  borderTopLeftRadius: theme.spacing(1.5),
  borderTopRightRadius: theme.spacing(1.5),
  backgroundColor: '#11141E',
}));
