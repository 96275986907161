import { TabsStyled } from 'common/Tabs';

import { styled } from '@mui/material';

export const MiniDiceTopProfitTabsStyled = styled(TabsStyled)(() => ({
  backgroundColor: 'transparent',
  '&& .tab-item': {
    textTransform: 'uppercase',
    '&.top-profit': {
      background: 'linear-gradient(180deg, #FF7A00 -32.32%, #642A00 57%)',
    },
    '&.lucky-win': {
      visibility: 'hidden',
    },
  },
}));
