export const cardsConfig = [
  [
    '2z',
    '3z',
    '4z',
    '5z',
    '6z',
    '7z',
    '8z',
    '9z',
    '10z',
    'jz',
    'qz',
    'kz',
    'az',
  ],
  [
    '2c',
    '3c',
    '4c',
    '5c',
    '6c',
    '7c',
    '8c',
    '9c',
    '10c',
    'jc',
    'qc',
    'kc',
    'ac',
  ],
  [
    '2b',
    '3b',
    '4b',
    '5b',
    '6b',
    '7b',
    '8b',
    '9b',
    '10b',
    'jb',
    'qb',
    'kb',
    'ab',
  ],
  [
    '2t',
    '3t',
    '4t',
    '5t',
    '6t',
    '7t',
    '8t',
    '9t',
    '10t',
    'jt',
    'qt',
    'kt',
    'at',
  ],
];

export const cardsfn = () => {
  const cardsObj: any = {};
  cardsConfig[0].map((n) => {
    cardsObj[n] = `/assets/minipoker/cards/${n}.svg`;
    return null;
  });
  cardsConfig[1].map((n) => {
    cardsObj[n] = `/assets/minipoker/cards/${n}.svg`;
    return null;
  });
  cardsConfig[2].map((n) => {
    cardsObj[n] = `/assets/minipoker/cards/${n}.svg`;
    return null;
  });
  cardsConfig[3].map((n) => {
    cardsObj[n] = `/assets/minipoker/cards/${n}.svg`;
    return null;
  });
  return cardsObj;
};

export const coinfn = {
  bnb: 'bnb.png',
  btc: 'btc.png',
  doge: 'doge.png',
  eth: 'eth.png',
  cod: 'cod.png',
  tcod: 'cod.png',
  matic: 'matic.png',
  tbnb: 'tbnb.png',
  tbtc: 'tbtc.png',
  tdoge: 'tdoge.png',
  teth: 'teth.png',
  tmatic: 'tmatic.png',
  tusdt: 'tusdt.png',
  tfun: 'tfun.png',
  usdt: 'usdt.png',
  fun: 'fun.png',
  lunc: 'lunc.png',
  shib: 'shib.png',
};

export const coinsfn = [
  coinfn.bnb,
  coinfn.btc,
  coinfn.doge,
  coinfn.eth,
  coinfn.cod,
  coinfn.tcod,
  coinfn.matic,
  coinfn.tbnb,
  coinfn.tbtc,
  coinfn.tdoge,
  coinfn.teth,
  coinfn.tmatic,
  coinfn.tusdt,
  coinfn.tfun,
  coinfn.usdt,
  coinfn.fun,
  coinfn.lunc,
];

export const vfxfn = {
  particleCoins: 'effects/coins/coin.json',
};

export const cardT = (rank: number, suit: string) => {
  const suitKey = ['♦', '♥', '♠', '♣'].findIndex((r) => r === suit);
  if (suitKey === -1) return '';
  return `${cardsConfig[suitKey][rank - 2]}`;
};

export const cardfn = (rank: number, suit: string) => {
  const suitKey = ['♦', '♥', '♠', '♣'].findIndex((r) => r === suit);
  if (suitKey === -1) return '';
  return `/assets/minipoker/cards/${cardsConfig[suitKey][rank - 2]}.png`;
};

export const uiT = (name: any) => `uis.${name}`;
export const coinT = (name: any) => `coins.${name}`;
export const vfsT = (name: any) => `vfxs.${name}`;
