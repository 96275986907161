/* eslint-disable react/no-array-index-key */
import { IconInfo, IconQuestion } from 'assets/icons';
import Iconify from 'components/iconify';
import { useLocales } from 'locales';
import Markdown from 'markdown/Markdown';
import { Key, useEffect, useState } from 'react';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import GameInfo from '../_GameMiniCoco/GameInfo';
import Rule from '../_GameMiniCoco/Rule';
import { cardfn } from './Assets';
import { payTable, textTable } from './Config';
import { BootstrapDialog } from './styles';

// -----------------------------------------------------------

// '♦', '♥', '♠', '♣';
const CardRowRender = ({ cards, title, textPayout, ...props }) => {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        my: 0.5,
        pb: 1,
        px: 1,
        borderRadius: 1,
        backgroundColor: 'background.table',
      }}
      {...props}
    >
      <Stack direction="column">
        <Typography variant="body2">{translate(title)}</Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Stack direction="row" spacing={0.5}>
            {cards.map((r: string, i: Key) => (
              <img key={i} src={r} alt="card" width={26} height="auto" />
            ))}
          </Stack>
          <Typography variant="body2">{translate(textPayout)}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

const Paytable = (props: any) => {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = useState(1);
  const { translate, currentLang } = useLocales();
  const [miniPokerRule, setminiPokerRule] = useState('');
  // console.log(props.slug);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  useEffect(() => {
    try {
      import(`markdown/miniPoker/${currentLang.value}.md`)
        .then((response) => fetch(response.default))
        .then((response) => response.text())
        .then((text) => setminiPokerRule(text))
        .catch(() => {
          setminiPokerRule(translate('coming_soon2'));
        });
    } catch (error) {
      setminiPokerRule(translate('coming_soon2'));
    }
  }, [currentLang]);

  return (
    <BootstrapDialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      <Box className="header">
        <Typography>GAME INFO</Typography>
        <IconButton sx={{ p: 0.5 }} onClick={handleClose}>
          <Iconify
            icon="solar:close-square-linear"
            width={28}
            color="text.primary"
          />
        </IconButton>
      </Box>

      <DialogActions className="game-info-btn">
        <Button
          className={`button ${tab === 1 ? 'selected' : 'default'}`}
          onClick={() => {
            setTab(1);
          }}
        >
          <IconInfo />
          <Typography>{translate('pay_table')}</Typography>
        </Button>

        <Button
          className={`button ${tab === 2 ? 'selected' : 'default'}`}
          onClick={() => {
            setTab(2);
          }}
        >
          <IconQuestion />
          <Typography>{translate('game_rule')}</Typography>
        </Button>
      </DialogActions>

      {props.slug === 'minipoker' && (
        <DialogContent className="game-info-content">
          {tab === 1 &&
            payTable.map((c, i) => (
              <CardRowRender
                className="paytable-item"
                key={`c${i}`}
                cards={c}
                title={translate(textTable[i][1])}
                textPayout={textTable[i][0]}
              />
            ))}
          {tab === 2 && (
            <Markdown className="rule-text">{miniPokerRule}</Markdown>
          )}
        </DialogContent>
      )}

      {props.slug === 'minicrypto' && (
        <DialogContent className="game-info-content">
          {tab === 1 && <GameInfo />}
          {tab === 2 && <Rule />}
        </DialogContent>
      )}
    </BootstrapDialog>
  );
};

export const ResultDetail = ({ betDetail, ...props }) => {
  const cc: any[] = [];
  betDetail?.betData?.result?.cards?.map(
    (c: { rank: number; suit: string }) => {
      cc.push(cardfn(c.rank, c.suit));
      return null;
    },
  );
  return <CardRowRender cards={cc} title="" textPayout="" {...props} />;
};

export default Paytable;
