import { SvgIcon } from '@mui/material';

export function IconCasino(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9697 20.5953H7.07164C6.4249 20.5953 5.89062 19.9942 5.89062 19.2666V4.16117C5.89062 3.43357 6.4249 2.83252 7.07164 2.83252H16.9697C17.6165 2.83252 18.1507 3.43357 18.1507 4.16117V19.2666C18.1507 19.9942 17.6165 20.5953 16.9697 20.5953ZM12.0207 14.4898C11.9504 14.4898 11.8801 14.4582 11.8238 14.3949C11.388 13.9046 10.9381 13.5408 10.5585 13.2086C9.743 12.5285 9.04001 11.9274 9.04001 10.7728C9.04001 9.76046 9.77112 8.93797 10.6709 8.93797C11.3318 8.93797 11.7114 9.2385 12.0207 9.74465C12.33 9.2385 12.7237 8.93797 13.3704 8.93797C14.2702 8.93797 15.0013 9.76046 15.0013 10.7728C15.0013 11.9274 14.2984 12.5285 13.4829 13.2086C13.1033 13.5408 12.6534 13.9046 12.2175 14.3949C12.1613 14.4582 12.091 14.4898 12.0207 14.4898ZM8.26672 6.83428C8.18237 6.83428 8.11207 6.80264 8.06989 6.73937C7.87305 6.53375 7.69027 6.37558 7.5075 6.21741C7.14194 5.90106 6.77639 5.60053 6.77639 4.99948C6.77639 4.44588 7.17006 4.00299 7.66215 4.00299C7.92929 4.00299 8.12613 4.11371 8.26672 4.27189C8.40732 4.11371 8.5901 4.00299 8.87129 4.00299C9.34932 4.00299 9.743 4.44588 9.743 4.99948C9.743 5.60053 9.3915 5.90106 9.01189 6.21741C8.84317 6.35976 8.64634 6.53375 8.46356 6.73937C8.40732 6.80264 8.33702 6.83428 8.26672 6.83428ZM15.6059 19.662C15.5356 19.662 15.4653 19.6304 15.4091 19.5671C15.2263 19.3615 15.0295 19.2033 14.8607 19.061C14.4811 18.7446 14.1296 18.4283 14.1296 17.8272C14.1296 17.2736 14.5233 16.8308 15.0154 16.8308C15.2825 16.8308 15.4794 16.9415 15.6059 17.0997C15.7465 16.9415 15.9433 16.8308 16.2105 16.8308C16.7026 16.8308 17.0962 17.2736 17.0962 17.8272C17.0962 18.4283 16.7448 18.7446 16.3651 19.061C16.1964 19.2033 15.9996 19.3615 15.8168 19.5671C15.7606 19.6304 15.6903 19.662 15.6059 19.662Z"
        fill="#9998D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.32865 17.7632H1.18102C0.534271 17.7632 0 17.1622 0 16.4346V1.32914C0 0.601543 0.534271 0.000488281 1.18102 0.000488281H11.0791C11.7258 0.000488281 12.2601 0.601543 12.2601 1.32914V2.19908H7.07206C6.11599 2.19908 5.32865 3.08485 5.32865 4.16042V6.18502C5.17399 6.13757 4.99121 6.09012 4.78032 6.09012C3.88049 6.09012 3.14939 6.91262 3.14939 7.92492C3.14939 9.09539 3.85237 9.69645 4.65378 10.3766C4.87874 10.5506 5.10369 10.7404 5.32865 10.9618V17.7632ZM2.3761 4.00225C2.29174 4.00225 2.22144 3.97061 2.1652 3.90734C1.98243 3.70172 1.78559 3.52773 1.61687 3.38537C1.23726 3.06903 0.885765 2.7685 0.885765 2.15163C0.885765 1.61385 1.27944 1.17096 1.77153 1.17096C2.03866 1.17096 2.2355 1.28168 2.36204 1.42404C2.50264 1.28168 2.69947 1.17096 2.96661 1.17096C3.4587 1.17096 3.85237 1.61385 3.85237 2.15163C3.85237 2.7685 3.50088 3.06903 3.12127 3.38537C2.95255 3.52773 2.75571 3.70172 2.57294 3.90734C2.5167 3.97061 2.4464 4.00225 2.3761 4.00225Z"
        fill="#9998D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8193 23.9634H12.9213C12.2745 23.9634 11.7402 23.3624 11.7402 22.6348V21.227H16.9705C17.9265 21.227 18.7139 20.3413 18.7139 19.2657V17.1146C18.9248 16.9248 19.1357 16.735 19.3325 16.5768C20.148 15.8966 20.851 15.2956 20.851 14.1251C20.851 13.1286 20.1198 12.3061 19.22 12.3061C19.0232 12.3061 18.8545 12.3219 18.7139 12.3694V6.20068H22.8193C23.4661 6.20068 24.0003 6.80174 24.0003 7.52933V22.6348C24.0003 23.3624 23.4661 23.9634 22.8193 23.9634ZM21.4555 23.0302C21.3852 23.0302 21.3149 22.9986 21.2587 22.9353C21.0759 22.7297 20.8791 22.5715 20.7104 22.4292C20.3307 22.097 19.9793 21.7965 19.9793 21.1954C19.9793 20.6418 20.3729 20.1989 20.851 20.1989C21.1322 20.1989 21.3149 20.3096 21.4555 20.4678C21.5961 20.3096 21.793 20.1989 22.0601 20.1989C22.5522 20.1989 22.9459 20.6418 22.9459 21.1954C22.9459 21.7965 22.5803 22.097 22.2148 22.4292C22.032 22.5715 21.8492 22.7297 21.6524 22.9353C21.6102 22.9986 21.5399 23.0302 21.4555 23.0302Z"
        fill="#9998D4"
      />
    </SvgIcon>
  );
}
