import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { ReactComponent as TouchFeatureDisabled } from 'assets/icons/trio-dice-touch-disabled.svg';
import { ReactComponent as TouchFeatureEnabled } from 'assets/icons/trio-dice-touch-enabled.svg';
import { ThemedButton } from 'common/Button/style';
import { DialogHeaderStyled } from 'common/Dialog';
import { DialogAnimate } from 'components/animate';
import { DialogWrapper } from 'components/Dialog/styles';
import { useLocales } from 'locales';
import { memo } from 'react';

import { IconButton, Stack, Typography, useTheme } from '@mui/material';

interface IMiniDiceTouchFeatureDetailDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

const MiniDiceTouchFeatureDetailDialog = ({
  open,
  onClose,
}: IMiniDiceTouchFeatureDetailDialogProps) => {
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        width: 580,
        maxWidth: 'unset',
        borderRadius: '12px !important',
        padding: (theme) => theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'unset',
          minHeight: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: (theme) => theme.shape.borderRadius,
        },
      }}
    >
      <DialogHeaderStyled
        sx={{
          padding: 0,
          height: 'unset',
        }}
      >
        <Typography variant="h5" color="text.contrast">
          {translate('what_is_touch_feature')}
        </Typography>
        <IconButton className="close-btn" onClick={() => onClose()}>
          <CloseCircle width="24px" height="24px" />
        </IconButton>
      </DialogHeaderStyled>
      <DialogWrapper
        className="three-dice-touch-feature"
        sx={{ p: { xs: 0 }, borderRadius: 0 }}
      >
        <Stack mt={{ xs: 2, sm: 'inherit' }} rowGap={3}>
          <div>
            <Typography whiteSpace="pre-wrap">
              {translate('touch_feature_description_1')}
              <Typography
                mx={0.25}
                fontWeight={700}
                color="#FFE500"
                display="inline-flex"
                component="span"
              >
                {translate('touch')}
              </Typography>
              {translate('feature')}.
            </Typography>
            <Typography whiteSpace="pre-wrap">
              {translate('touch_feature_description_2')}:
            </Typography>
          </div>

          <Stack columnGap={3} rowGap={2} direction="row" flexWrap="wrap">
            <Stack rowGap={1} flex={1} alignItems="center" minWidth={200}>
              <Typography variant="subtitle1" color="text.contrast">
                {translate('touch_enabled_title')}:
              </Typography>
              <TouchFeatureEnabled />
              <Typography whiteSpace="pre-wrap" textAlign="center">
                {translate('touch_enabled_description')}
              </Typography>
            </Stack>
            <Stack rowGap={1} flex={1} alignItems="center" minWidth={200}>
              <Typography variant="subtitle1" color="text.contrast">
                {translate('touch_disabled_title')}:
              </Typography>
              <TouchFeatureDisabled />
              <Typography whiteSpace="pre-wrap" textAlign="center">
                {translate('touch_disabled_description')}
              </Typography>
            </Stack>
          </Stack>
          <ThemedButton sx={{ minHeight: 40 }} onClick={() => onClose()}>
            {translate('got_it')}
          </ThemedButton>
        </Stack>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default memo(MiniDiceTouchFeatureDetailDialog);
