import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useLocales } from 'locales';
import { memo } from 'react';

interface IHeaderJackpotWrapperProps {
  isBetsByGame: boolean;
}

const HeaderJackpotWrapper = memo(
  (props: Partial<IHeaderJackpotWrapperProps>) => {
    const { isBetsByGame } = props;
    const { translate } = useLocales();

    return (
      <TableHead>
        <TableRow>
          {!isBetsByGame && (
            <TableCell align="left" width="15%">
              <Typography color="text.primary" ml={1}>
                {translate('game')}
              </Typography>
            </TableCell>
          )}
          <TableCell align={isBetsByGame ? 'center' : 'left'} width="25%">
            <Typography color="text.primary">{translate('player')}</Typography>
          </TableCell>
          <TableCell align="right" width="35%">
            <Typography color="text.primary">{translate('jackpot')}</Typography>
          </TableCell>
          <TableCell align="right" width="25%">
            <Typography color="text.primary" mr={1}>
              {translate('time')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  },
);

export { HeaderJackpotWrapper };
