// @mui

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

/* -------------------------------------------------------------------------- */

export const DialogWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  height: '100%',
  overflowY: 'auto',

  // Bet detail

  '.popup-bet-detail-content': {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1.5),
    },
  },

  '.bet-detail-id': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1),
    },

    span: {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.text.contrast,
      lineHeight: 'normal',
    },

    svg: {
      width: 20,
      height: 20,
    },
  },

  '.bet-detail-info': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1),

    span: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.text.primary,
      lineHeight: 'normal',
      margin: theme.spacing(0, 0.5),
    },
  },

  // Game bank

  '.gamebank-header': {
    padding: theme.spacing(3, 3, 0),
    margin: 0,
  },
}));

type DialogHeaderProps = {
  hasCloseBtn?: boolean | undefined;
  hasBackBtn?: boolean | undefined;
  backgroundHeader?: string;
};

export const DialogHeader = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'hasCloseBtn' &&
    prop !== 'hasBackBtn' &&
    prop !== 'backgroundHeader',
})<DialogHeaderProps>(({ hasBackBtn, backgroundHeader, theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2.5),
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1.5),
  },

  ...(backgroundHeader && {
    paddingBottom: theme.spacing(3),
  }),
  backgroundColor: backgroundHeader,
  '& > div:not(:last-of-type)': {
    [theme.breakpoints.down('sm')]: {
      top: '50%',
      transform: hasBackBtn && 'translateY(-50%)',
      '.MuiSvgIcon-root': {
        width: 16,
        height: 16,
      },
    },
  },

  h5: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    [theme.breakpoints.down('lg')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
}));

export const CloseBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: '1/1',
  objectFit: 'cover',
  height: 20,
  button: {
    padding: 0,
  },
  svg: {
    color: theme.palette.text.primary,
    transition: 'all ease-in-out 0.3s',
  },
  '&:hover svg': {
    color: theme.palette.text.contrast,
    transform: 'rotate(180deg)',
  },
  '&&': {
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(1.5),
    },
    '.MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
}));

export const BackBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-45%)',
  left: theme.spacing(2.775),
  [theme.breakpoints.down('sm')]: {
    left: theme.spacing(1.5),
  },
  svg: { color: theme.palette.text.contrast },
}));

export const DialogBody = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  minHeight: 'inherit',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

export const DiaLogFooter = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
}));
