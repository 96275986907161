import { SvgIcon } from '@mui/material';

export function IconCloseMiniGame(props: any) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="15.625"
        y="2.625"
        width="1.8"
        height="18"
        rx="0.9"
        transform="rotate(45 15.625 2.625)"
        fill="#9A99D5"
      />
      <rect
        x="4.375"
        y="2.625"
        width="18"
        height="1.8"
        rx="0.9"
        transform="rotate(45 4.375 2.625)"
        fill="#9A99D5"
      />
    </SvgIcon>
  );
}
