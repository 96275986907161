import { SvgIcon } from '@mui/material';

export function IconTelegram(props: any) {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_34_1362)">
        <path
          d="M20.0013 40.0026C31.0477 40.0026 40.0026 31.0477 40.0026 20.0013C40.0026 8.9549 31.0477 0 20.0013 0C8.9549 0 0 8.9549 0 20.0013C0 31.0477 8.9549 40.0026 20.0013 40.0026Z"
          fill="#2F2B60"
        />
        <g clipPath="url(#clip1_34_1362)">
          <g filter="url(#filter0_d_34_1362)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.00024 19.4C14.8002 17 18.6002 15.4 20.6002 14.6C26.0002 12.4 27.2002 12 27.9002 12C28.1002 12 28.4002 12 28.7002 12.2C28.9002 12.4 29.0002 12.6 29.0002 12.7C29.0002 12.8 29.1002 13.2 29.0002 13.5C28.7002 16.5 27.4002 23.8 26.8002 27.2C26.5002 28.6 26.0002 28.9 25.4002 29C24.2002 29.1 23.4002 28.4 22.3002 27.7C20.6002 26.6 19.9002 26.3 18.2002 25.2C16.2002 24 17.3002 23.3 18.4002 22.2C18.7002 21.9 23.8002 17.4 23.9002 17C23.9002 16.9 23.7002 16.4 23.6002 16.3C23.5002 16.2 23.3002 16.2 23.2002 16.3C23.0002 16.3 20.2002 18.1 14.8002 21.6C14.0002 22.1 13.3002 22.4 12.7002 22.4C12.0002 22.4 10.6002 22 9.60024 21.7C8.40024 21.3 7.90024 21.1 8.00024 20.5C8.10024 20.1 8.40024 19.8 9.00024 19.4Z"
              fill="#63D8FE"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_34_1362"
          x="-2.01221"
          y="2"
          width="41.0571"
          height="37.0098"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_34_1362"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_34_1362"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_34_1362">
          <rect width="40" height="40" fill="white" />
        </clipPath>
        <clipPath id="clip1_34_1362">
          <rect
            width="21"
            height="17"
            fill="white"
            transform="translate(8 12)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
