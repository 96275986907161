import { ReactiveVar } from '@apollo/client';
import { CURRENCY } from 'constants/localStorage';
import { CurrenciesQuery, CurrenciesReactiveVar, Currency } from 'graph';
import { currencyLocalStateOperations } from 'store/operations/currency-opts';

export default (currenciesVar: ReactiveVar<CurrenciesReactiveVar>) =>
  (
    currencies: CurrenciesQuery['currencies'],
    options?: {
      onCompleted: (currencies: CurrenciesQuery['currencies']) => void;
    },
  ) => {
    const { currencies: currentCurrencies } = currenciesVar();

    if (!currentCurrencies.length) {
      currenciesVar({ currencies });

      const storageCurrency: Currency = JSON.parse(
        localStorage.getItem(CURRENCY),
      );
      const { setStorageCurrency } = currencyLocalStateOperations;

      if (!storageCurrency?.id) {
        // set default currency is fun
        const funCurrency = currencies.find(
          (currency) => currency.shortName === 'fun',
        );
        setStorageCurrency(funCurrency);
      }

      // update storage currency with new rate
      if (storageCurrency?.id) {
        const currencyWithUpdatedRate = currencies.find(
          (currency) => currency.id === storageCurrency.id,
        );
        setStorageCurrency(currencyWithUpdatedRate);
      }

      /* -------------------------------------------------------------------------- */

      if (options?.onCompleted) {
        setTimeout(() => {
          options.onCompleted(currencies);
        }, 0);
      }
    }
  };
