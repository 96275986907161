import { m } from 'framer-motion';

type Props = {
  children: React.ReactNode;
};

export default function MotionLazyContainer({ children }: Props) {
  return (
    // <LazyMotion strict features={loadFeatures}>
    <m.div style={{ height: '100%' }}> {children} </m.div>
    // </LazyMotion>
  );
}
