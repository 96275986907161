import { TableContainerStyled } from 'common/Table/styles';
import useResponsive from 'hooks/useResponsive';
import { memo, useEffect, useId, useRef } from 'react';

import { Table, TableContainerProps } from '@mui/material';

interface IStickyHeaderTableProps extends TableContainerProps {
  children: React.ReactNode;
  maxRows?: number;
  isMaxHeightByParent?: boolean;
}

const StickyHeaderTable = ({
  children,
  maxRows,
  isMaxHeightByParent,
  ...props
}: IStickyHeaderTableProps) => {
  const tableId = useId();
  const tableContainerId = useId();
  const isMobile = useResponsive('down', 'sm');
  const containerRef = useRef<HTMLDivElement>(null);

  const changeTableContainerMaxHeight = (height: number) => {
    if (containerRef.current) {
      containerRef.current.style.maxHeight = `${height}px`;
    }
  };

  // Desktop && !isMaxHeightByParent: calculate heigh of table base set table container max height
  useEffect(() => {
    const rowBody = document.querySelector(`#${CSS.escape(tableId)} tbody tr`);
    const rowHead = document.querySelector(`#${CSS.escape(tableId)} thead`);
    const tableContainerParent = document.querySelector(
      `#${CSS.escape(tableContainerId)}`,
    ).parentElement;

    const resizeObserver = new ResizeObserver(() => {
      const paddingBottom = window
        .getComputedStyle(tableContainerParent)
        .paddingBottom.replace('px', '');
      if (
        tableContainerParent.clientHeight - Number(paddingBottom) <
        maxRows * rowBody.clientHeight + rowHead.clientHeight
      ) {
        changeTableContainerMaxHeight(
          tableContainerParent.clientHeight - Number(paddingBottom),
        );
      } else {
        changeTableContainerMaxHeight(
          maxRows * rowBody.clientHeight + rowHead.clientHeight,
        );
      }
    });
    if (!tableContainerParent) return;

    if (rowBody && tableContainerParent && !isMobile && !isMaxHeightByParent) {
      resizeObserver.observe(tableContainerParent);
    }

    return () => {
      resizeObserver.unobserve(tableContainerParent);
    };
  }, [isMobile, maxRows]);

  // Mobile/isMaxHeightByParent === true: set max height of table container by parent height
  useEffect(() => {
    const tableContainerParent = document.querySelector(
      `#${CSS.escape(tableContainerId)}`,
    ).parentElement;
    const resizeObserver = new ResizeObserver(() => {
      const paddingBottom = window
        .getComputedStyle(tableContainerParent)
        .paddingBottom.replace('px', '');

      changeTableContainerMaxHeight(
        tableContainerParent.clientHeight - Number(paddingBottom),
      );
    });
    if (!tableContainerParent) return;

    if (tableContainerParent && (isMobile || isMaxHeightByParent)) {
      resizeObserver.observe(tableContainerParent);
    }

    return () => {
      resizeObserver.unobserve(tableContainerParent);
    };
  }, [isMobile]);

  return (
    <TableContainerStyled {...props} id={tableContainerId} ref={containerRef}>
      <Table id={tableId} stickyHeader sx={{ overflowY: 'scroll' }}>
        {children}
      </Table>
    </TableContainerStyled>
  );
};

StickyHeaderTable.defaultProps = {
  maxRows: 10,
  isMaxHeightByParent: false,
};

export default memo(StickyHeaderTable);
