import { SvgIcon } from '@mui/material';

export function IconCoco(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_8266_43811)">
        <path
          d="M17.4298 11.8872C17.4298 12.3352 17.3682 12.7701 17.2613 13.1854C16.7171 15.3274 14.8542 16.9331 12.5961 17.077C12.4957 17.0835 12.3952 17.0933 12.2948 17.0933C12.2883 17.0933 12.2786 17.0933 12.2721 17.0933C12.1782 17.0933 12.0842 17.0835 11.9935 17.0802C9.27538 16.9331 7.11771 14.6668 7.11771 11.8904C7.08531 9.68958 8.32613 8.07083 10.2181 6.42265C10.3056 6.34743 10.3834 6.27222 10.4644 6.20027L10.4579 6.19373C10.4579 6.19373 11.5173 5.27481 11.9935 4.06483C11.9935 4.06156 11.9968 4.05502 12 4.05175C12.2203 3.48274 12.3078 2.85159 12.1004 2.21717C12.0713 2.12887 12.0356 2.04058 11.9935 1.95228C11.8769 1.70702 11.7149 1.45848 11.4881 1.21976C9.95572 -0.340127 7.52268 0.631122 6.65767 1.05298C6.4568 1.15108 6.34017 1.21976 6.34017 1.21976V1.21322C2.56587 3.25055 0 7.26636 0 11.8872C0 15.54 1.60043 18.8134 4.13391 21.0339C7.5324 21.5473 10.0821 21.41 11.9935 20.8802C12.0972 20.8508 12.1976 20.8213 12.2948 20.7919C12.3985 20.7592 12.4989 20.7265 12.5961 20.6938C15.5184 19.6833 16.7624 17.6852 17.2613 15.8474C17.8251 13.7642 17.4298 11.8904 17.4298 11.8904V11.8872Z"
          fill="#9998D4"
        />
        <path
          d="M17.9646 1.38652C17.8382 1.30804 17.6892 1.22301 17.5175 1.13799C17.4851 1.12164 17.4559 1.10201 17.4235 1.08566C17.3101 1.03007 17.1644 0.964666 17.0024 0.895992C17.0024 0.895992 17.0024 0.895992 16.9991 0.895992H16.9927C16.8825 0.850209 16.7626 0.804426 16.6363 0.761913C14.6147 -0.0163944 12.4991 -0.0785281 10.8598 0.0555501C11.2518 0.228871 11.605 0.467595 11.916 0.784805C12.1816 1.06931 12.389 1.37017 12.538 1.68738C12.5898 1.79857 12.6352 1.90975 12.6708 2.01767C12.9041 2.72076 12.8685 3.47945 12.5607 4.26757C12.5542 4.28065 12.5477 4.29373 12.5412 4.31008C12.8037 4.81042 13.3188 5.54622 14.3231 6.41936C14.3976 6.48476 14.4689 6.55017 14.5434 6.61557C14.5434 6.61557 17.4948 9.07149 18.0261 11.8054C18.0391 11.8806 18.0682 12.0441 18.0974 12.2763C18.0974 12.2763 18.0974 12.2796 18.0974 12.2828C18.0974 12.2828 18.0974 12.2828 18.0974 12.2861C18.1849 13.0252 18.2594 14.4575 17.8415 16.0076C17.1546 18.5453 15.4084 20.3668 12.7907 21.2693C12.687 21.3053 12.5769 21.3413 12.47 21.374C12.3663 21.4067 12.2594 21.4394 12.1492 21.4688C10.9603 21.7991 9.58984 21.9659 8.07364 21.9659C7.14384 21.9659 6.146 21.9038 5.08984 21.7795C7.04341 23.1726 9.42138 24 11.997 24C18.6255 24 23.997 18.578 23.997 11.8871C23.997 7.39061 21.5672 3.47291 17.9646 1.38325V1.38652Z"
          fill="#9998D4"
        />
      </g>
      <defs>
        <clipPath id="clip0_8266_43811">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
