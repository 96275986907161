import { SvgIcon } from '@mui/material';

export function IconTrendMiniGame(props: any) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.2439C3 4.45234 4.45234 3 6.2439 3C8.03546 3 9.4878 4.45234 9.4878 6.2439C9.4878 8.03546 8.03546 9.4878 6.2439 9.4878C4.45234 9.4878 3 8.03546 3 6.2439Z"
        fill="#9A99D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5122 13.7561C10.5122 11.9645 11.9645 10.5122 13.7561 10.5122C15.5477 10.5122 17 11.9645 17 13.7561C17 15.5477 15.5477 17 13.7561 17C11.9645 17 10.5122 15.5477 10.5122 13.7561Z"
        fill="#9A99D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 6.2439C17 4.45234 15.5477 3 13.7561 3C11.9645 3 10.5122 4.45234 10.5122 6.2439C10.5122 8.03546 11.9645 9.4878 13.7561 9.4878C15.5477 9.4878 17 8.03546 17 6.2439Z"
        fill="#9A99D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4878 13.7561C9.4878 11.9645 8.03546 10.5122 6.2439 10.5122C4.45234 10.5122 3 11.9645 3 13.7561C3 15.5477 4.45234 17 6.2439 17C8.03546 17 9.4878 15.5477 9.4878 13.7561Z"
        fill="#9A99D5"
      />
    </SvgIcon>
  );
}
