import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { Currency, ViewInFiatReactiveVar } from 'graph';
import { viewInFiatVar } from 'store';
import { localStringNumber, toFixedWithoutRounding } from 'utils/number-help';

export type FormatCurrencyOps = {
  onlyUsd?: boolean;
  onlyCurrency?: boolean;
};

type GetCurrencyAmountFn = (
  cryptoAmount: number,
  currency?: Currency,
  opts?: FormatCurrencyOps,
) => string;

const getCurrencyAmount =
  (viewInFiatVar: ReactiveVar<ViewInFiatReactiveVar>): GetCurrencyAmountFn =>
  (cryptoAmount, currency, opts = { onlyCurrency: false, onlyUsd: false }) => {
    const { onlyUsd, onlyCurrency } = opts;
    const isFunCurrency = currency?.shortName === 'fun';
    const { viewInFiat } = viewInFiatVar();

    if (
      cryptoAmount === null ||
      cryptoAmount === undefined ||
      Number.isNaN(cryptoAmount)
    )
      return;

    if (
      (!currency?.decimalDigits && !currency?.equivalentUsdRate) ||
      isFunCurrency ||
      currency?.equivalentUsdRate === 0
    ) {
      return viewInFiat.status
        ? localStringNumber(cryptoAmount)
        : toFixedWithoutRounding(cryptoAmount, 2);
    }

    if (onlyCurrency) {
      return toFixedWithoutRounding(cryptoAmount, currency.decimalDigits);
    }

    if (onlyUsd) {
      return `$${localStringNumber(cryptoAmount * currency.equivalentUsdRate)}`;
    }

    if (!viewInFiat.status) {
      return toFixedWithoutRounding(cryptoAmount, currency.decimalDigits);
    }

    if (viewInFiat.status) {
      return `$${localStringNumber(cryptoAmount * currency.equivalentUsdRate)}`;
    }

    return toFixedWithoutRounding(cryptoAmount, currency.decimalDigits);
  };

export default function useGetCurrencyAmount() {
  useReactiveVar(viewInFiatVar);

  return {
    getCurrencyAmount: getCurrencyAmount(viewInFiatVar),
  };
}
