import coinImages from 'assets/images/coins';
import FiatImages from 'assets/images/fiats';
import { Currency, TransfiFiat, useUserWalletsQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useLocales from 'locales/useLocales';
import { FunctionComponent, SVGProps, useEffect, useState } from 'react';
import { toN } from 'utils/number-help';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import { SelectCurrencyWrapper } from './styles';

// --------------------------------------------------------

interface SelectCurrencyProps {
  setIsAssetPortfolioOpen: (state: any) => void;
  selectedCurrency: Currency | TransfiFiat;
  amount: number | string;
  setAmount?: (amount: any) => void;
  showInputAmount?: boolean;
  disabledInputAmount?: boolean;
  hiddenExchangeFiat?: boolean;
  hiddenBalance?: boolean;
  needUpdateAmount?: boolean;
  onBlur?: (amount: number | string) => void;
}

const SelectCurrency = ({
  setIsAssetPortfolioOpen,
  selectedCurrency,
  amount,
  showInputAmount,
  setAmount,
  onBlur,
  disabledInputAmount,
  hiddenExchangeFiat = false,
  hiddenBalance = false,
  needUpdateAmount = false,
}: SelectCurrencyProps) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const [formattedAmount, setFormattedAmount] = useState(amount);
  const { data: userWalletsData } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });

  const IconFiat = () => {
    const IC: FunctionComponent<SVGProps<SVGSVGElement>> =
      FiatImages[
        `Ic${(selectedCurrency as TransfiFiat)?.symbol.toUpperCase()}`
      ];
    return IC && <IC />;
  };

  const balance: number =
    userWalletsData?.me?.userWallets?.find(
      (userWallet) =>
        userWallet.currencyId === (selectedCurrency as Currency)?.id,
    )?.amount || 0;

  const defaultAmount =
    (selectedCurrency as Currency)?.id !== undefined
      ? getCurrencyAmount(toN(amount), selectedCurrency as Currency, {
          onlyCurrency: true,
        })
      : 0;

  useEffect(() => {
    if (disabledInputAmount || needUpdateAmount) {
      const formattedAmount = getCurrencyAmount(
        toN(amount),
        selectedCurrency as any,
        {
          onlyCurrency: true,
        },
      );
      setFormattedAmount(formattedAmount);
    }
  }, [amount]);

  return (
    <SelectCurrencyWrapper>
      <Box
        className="selected-coin"
        onClick={() => setIsAssetPortfolioOpen(true)}
      >
        {(selectedCurrency as Currency)?.shortName && (
          <>
            <img
              src={coinImages[(selectedCurrency as Currency)?.shortName]}
              alt="bnb coin"
            />
            <Typography textTransform="uppercase">
              {(selectedCurrency as Currency)?.shortName}
            </Typography>
          </>
        )}
        {(selectedCurrency as TransfiFiat)?.symbol && (
          <>
            <IconFiat />
            <Typography textTransform="uppercase">
              {(selectedCurrency as TransfiFiat)?.symbol}
            </Typography>
          </>
        )}

        <KeyboardArrowDownRoundedIcon />
      </Box>
      <Box className="balance_box">
        {!hiddenBalance && (
          <Typography
            onClick={() => {
              if (showInputAmount && !disabledInputAmount) {
                setAmount(
                  getCurrencyAmount(balance, selectedCurrency as any, {
                    onlyCurrency: true,
                  }),
                );
                setFormattedAmount(
                  getCurrencyAmount(balance, selectedCurrency as any, {
                    onlyCurrency: true,
                  }),
                );
              }
            }}
            className="balance_amount"
            fontSize="14px"
            {...(showInputAmount &&
              !disabledInputAmount && {
                sx: {
                  cursor: 'pointer',
                },
              })}
          >
            <>
              {translate('balance')}
              :&nbsp;
              <span className="balance-value">
                {getCurrencyAmount(balance, selectedCurrency as any, {
                  onlyCurrency: true,
                })}
              </span>
            </>
          </Typography>
        )}
        {showInputAmount && (
          <InputBase
            value={formattedAmount || defaultAmount}
            disabled={disabledInputAmount}
            onFocus={(event) => {
              event.target.select();
            }}
            placeholder="0.00000000"
            onChange={(event) => {
              if (!Number.isNaN(toN(event.target.value))) {
                setAmount(event.target.value);
                setFormattedAmount(event.target.value);
              }
            }}
            onBlur={() => {
              if (amount) {
                const formattedAmount = getCurrencyAmount(
                  toN(amount),
                  selectedCurrency as Currency,
                  { onlyCurrency: true },
                );
                setFormattedAmount(formattedAmount);
              }
              onBlur(amount);
            }}
            sx={{
              '& input': {
                fontSize: '18px !important',
                fontWeight: 600,
                lineHeight: '26px',
              },
            }}
          />
        )}
        {!hiddenExchangeFiat && (
          <Typography fontSize="12px">
            ~
            {showInputAmount
              ? getCurrencyAmount(
                  Number(amount),
                  selectedCurrency as Currency,
                  {
                    onlyUsd: true,
                  },
                )
              : getCurrencyAmount(
                  Number(balance),
                  selectedCurrency as Currency,
                  {
                    onlyUsd: true,
                  },
                )}
          </Typography>
        )}
      </Box>
    </SelectCurrencyWrapper>
  );
};

SelectCurrency.defaultProps = {
  showInputAmount: true,
  disabledInputAmount: false,
  setAmount: () => {},
  onBlur: () => {},
};

export default SelectCurrency;
