const E = 50;

export const fxChart = (x: number) => 1 + 0.0007 * x ** 4;
export const fxAxis = (x: number) => E / (E - x);

export type RoomState = {
  x: number;
  fx: number;
  round_id: string;
  server_time: number;
  end_time_count_down: number;
  status: 'WAITING_START' | 'STARTED' | 'STOPED';
};

export type PlayerType = {
  user_id?: string | number;
  display_name: string;
  avata_url?: string;
  bet_amount: number;
  win_payout: number;
  win_amount?: number;
  currency_id?: string | number;
  status: 'BET' | 'BET_NEXT' | 'WAIT' | 'WIN' | 'LOSE' | 'PROCESS';
};

export type JoinOptionType = {
  access_token: string;
  currency_id: string;
  slug: string;
  game_id: string;
  bet_room_id: string;
};

export type BetType = {
  bet_amount: number;
  cashout_at: number;
};

export type CashoutType = {
  currency_id: string;
  user_id: string;
  display_name: string;
  avata_url: string;
  bet_amount: number;
  win_payout: number;
  win_amount: number;
  x: number;
};

export type ChangeCurrencyType = {
  currency_id: string;
  bet_room_id: string;
};

export type BetErrorType = {
  error_code: CRASH_BET_RESULT;
  message: string;
};

export type ReturnType = {
  ping: number;
  process: boolean;
  connectStatus: 'DISCONNECT' | 'CONNECTED' | 'JOINED' | 'INIT' | 'PAUSE';
  leaveCode: number;
  roomState: RoomState;
  players: PlayerType[];
  crashAt: number | 'gogo';
  playerCashOut: CashoutType[];
  betError: BetErrorType;
  join: (option: JoinOptionType) => void;
  // out: () => void;
  bet: (betData: BetType) => void;
  cashout: () => void;
  changeCurrency: (changeData: ChangeCurrencyType) => void;
};

export enum CRASH {
  BET = 'BET',
  CANCEL_BET = 'CANCEL_BET',
  CRASH = 'CRASH',
  CASH_OUT = 'CASH_OUT',
  CHANGE_CURRENCY = 'CHANGE_CURRENCY',
  PING = 'PING',
  PONG = 'PONG',
  ERROR = 'ERROR',
  PAUSED = 'PAUSED',
  WAITING = 'WAITING',
}

export enum CRASH_BET_RESULT {
  SUCCESS = 1,
  WAITING = 2,
  UNDER_MIN_BET = 4,
  INVALID_BET_PARAMS = 5,
  GAME_NOT_AVAILABLE = 6,
  INSUFFICIENT_AMOUNT = 7,
  INVALID_BET_STATE = 8,
  GAMEBANK_NOT_AVAILABLE = 9,
  OVER_MAX_BET = 10,
}
