import { differenceInMinutes, format } from 'date-fns';
import {
  ActiveDevice,
  useActiveDevicesQuery,
  useRemoveActiveDeviceMutation,
} from 'graph';
import useLocales from 'locales/useLocales';
import { useSnackbar } from 'notistack';
import { UAParser } from 'ua-parser-js';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Wrapper } from './styles_v2';

// -----------------------------------------------------------------

const V2ActiveDevices = () => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const { data: myActiveDevices, refetch: refetchActiveDevice } =
    useActiveDevicesQuery();
  const [removeDeviceSession] = useRemoveActiveDeviceMutation();

  const handleRemoveSession = async (activeDevice: ActiveDevice) => {
    if (activeDevice.current) return;

    try {
      const response = await removeDeviceSession({
        variables: {
          deviceId: activeDevice.id,
        },
      });

      if (response?.data.removeActiveDevice?.success) {
        enqueueSnackbar(translate('remove_session_success'), {
          variant: 'success',
        });
        refetchActiveDevice();
      }
    } catch (_) {
      enqueueSnackbar(translate('remove_session_failed'), { variant: 'error' });
    }
  };

  const sortedActiveDevice = [
    ...(myActiveDevices?.me?.activeDevices || []),
  ].sort((a, b) => Number(b.current) - Number(a.current));

  return (
    <Wrapper>
      <Typography variant="h6" className="title">
        {translate('active_devices')}
      </Typography>
      <TableContainer
        className="active-devices-table"
        component={Paper}
        sx={{
          padding: '12px',
          borderRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Table
          aria-label="simple table"
          sx={{ borderSpacing: '0 8px', borderCollapse: 'unset' }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" width="30%">
                <Typography>{translate('device')}</Typography>
              </TableCell>
              <TableCell align="center" width="30%">
                <Typography>{translate('ip_address')}</Typography>
              </TableCell>
              <TableCell align="center" width="20%">
                <Typography>{translate('last_used')}</Typography>
              </TableCell>
              <TableCell align="center" width="20%">
                <Typography>{translate('action')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(sortedActiveDevice || []).map((activeDevice) => {
              const deviceInfo = new UAParser(activeDevice.device).getResult();

              const deviceName = `${deviceInfo?.os.name || ''} (${
                deviceInfo?.browser?.name || ''
              })`;
              const isLastSeenIn3Minutes =
                differenceInMinutes(
                  new Date(),
                  new Date(activeDevice.lastSeenAt),
                ) < 3;

              const lastSeenTime = (() => {
                if (activeDevice.current || isLastSeenIn3Minutes)
                  return translate('online');
                if (!activeDevice.lastSeenAt) return '--';
                return format(new Date(activeDevice.lastSeenAt), 'dd/MM/yyyy');
              })();

              return (
                <TableRow
                  key={activeDevice.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell width="30%">
                    <Typography color="text.contrast">{deviceName}</Typography>
                  </TableCell>
                  <TableCell align="center" color="text.contrast" width="30%">
                    <Typography>
                      {activeDevice.lastSeenIpAddress || '--'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="20%">
                    <Typography
                      {...((activeDevice.current || isLastSeenIn3Minutes) && {
                        color: 'primary',
                      })}
                    >
                      {lastSeenTime}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="20%">
                    <Typography
                      color="text.contrast"
                      {...(!activeDevice.current && {
                        color: 'error.main',
                        style: { cursor: 'pointer' },
                      })}
                      onClick={() => handleRemoveSession(activeDevice)}
                    >
                      {activeDevice.current
                        ? translate('in_use')
                        : translate('remove_device')}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default V2ActiveDevices;
