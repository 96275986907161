import { useReactiveVar } from '@apollo/client';
import { IconBill, IconDeposit, IconTip, IconWithdraw } from 'assets/icons';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import currencyImages from 'assets/images/coins';
import {
  Currency,
  InternalTransfersQuery,
  InternalTransferStatus,
  TransactionGroup,
  useDepositTransactionsQuery,
  useInternalTransfersLazyQuery,
  useTransactionsLazyQuery,
  useWalletChangedSubscription,
  useWithdrawalTransactionsLazyQuery,
} from 'graph';
import { useLocales } from 'locales';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { currenciesVar } from 'store';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DialogAnimate } from '../animate';
import { varSlide } from '../animate/variants/slide';
import {
  DialogBody,
  DiaLogFooter,
  DialogHeader,
  DialogWrapper,
} from '../Dialog/styles';
import TabItem from '../WalletDialog/WalletTabItem';
import BillTransactionsTable from './BillTransactionsTable';
import { DepositNWithdrawTransactionTable } from './DepositNWithdrawTransactionTable';
import { SelectGroupBox, Tabs, TransactionBodyWrapper } from './styles';
import TipsTransactionsTable from './TipsTransactionsTable';

/* -------------------------------------------------------------------------- */

export enum TransactionTabKey {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  BILL = 'bill',
  TIPS = 'tips',
}

export const transactionGroupList = [
  TransactionGroup.Game,
  TransactionGroup.Affiliate,
  TransactionGroup.Bonus,
  TransactionGroup.Invest,
  TransactionGroup.Swap,
];

const transactionTabs = [
  { key: TransactionTabKey.DEPOSIT, icon: <IconDeposit /> },
  { key: TransactionTabKey.WITHDRAW, icon: <IconWithdraw /> },
  { key: TransactionTabKey.BILL, icon: <IconBill /> },
  { key: TransactionTabKey.TIPS, icon: <IconTip /> },
];

const defaultPageSize = 30;
const defaultPageNo = 1;

interface TransactionsProps {
  isOpen: boolean;
  handleClose: () => void;
  handleBack?: () => void;
  directExact?: string;
}

const TransactionsDialog = ({
  isOpen,
  handleBack,
  handleClose,
  directExact,
}: TransactionsProps) => {
  const { translate } = useLocales();
  const { currencies } = useReactiveVar(currenciesVar);
  const [filterCurrency, setFilterCurrency] = useState<Currency | null>(null);
  const [pageNo, setPageNo] = useState(defaultPageNo);
  const [selectTransactionsTab, setSelectTransactionsTab] = useState(
    transactionTabs[0],
  );
  const [rows, setRows] = useState([]);
  const { data: walletChange } = useWalletChangedSubscription();
  const [selectedGroup, setSelectedGroup] = useState<TransactionGroup | null>(
    null,
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data: depositTransactionsData,
    refetch: refetchDepositTransactions,
    loading: loadingDepositTransaction,
  } = useDepositTransactionsQuery({
    variables: {
      page: pageNo,
      pageSize: defaultPageSize,
      currencyId: filterCurrency?.id,
    },
    fetchPolicy: 'network-only',
  });
  const [
    getWithdrawalTransactions,
    {
      data: withdrawalTransactions,
      loading: loadingWithdrawTransactions,
      refetch: refetchWithdraw,
    },
  ] = useWithdrawalTransactionsLazyQuery({
    variables: {
      page: pageNo,
      pageSize: defaultPageSize,
      currencyId: filterCurrency?.id,
    },
    fetchPolicy: 'network-only',
  });
  const [
    getTransactions,
    {
      data: transactions,
      loading: loadingTransactions,
      refetch: refetchTransaction,
    },
  ] = useTransactionsLazyQuery({
    variables: {
      page: pageNo,
      pageSize: defaultPageSize,
      currencyId: filterCurrency?.id,
      group: selectedGroup || null,
    },
    fetchPolicy: 'network-only',
  });

  const [
    getInternalTranfer,
    {
      data: internalTranfer,
      loading: loadingInternalTranfer,
      refetch: refetchInternalTranfer,
    },
  ] = useInternalTransfersLazyQuery({
    variables: {
      page: pageNo,
      pageSize: defaultPageSize,
      currencyId: filterCurrency?.id,
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (directExact === TransactionGroup.InternalTransfer) {
      setSelectTransactionsTab(transactionTabs[3]);
      setSelectedGroup(TransactionGroup.InternalTransfer);
    } else if (directExact === TransactionGroup.Bonus) {
      setSelectTransactionsTab(transactionTabs[1]);
      // setSelectedGroup(TransactionGroup.InternalTransfer);
    }
  }, [directExact]);

  const filterCompletedTipsTrans = (
    tipsTransaction: InternalTransfersQuery['internalTransfers']['data'],
  ) =>
    tipsTransaction.filter(
      (tipTransaction) =>
        tipTransaction.status === InternalTransferStatus.Completed,
    );

  const handleGetTransactions = (tab, page = defaultPageNo) => {
    if (tab.key === TransactionTabKey.WITHDRAW) {
      getWithdrawalTransactions({
        variables: {
          page,
          pageSize: defaultPageSize,
          currencyId: filterCurrency?.id,
        },
      }).then((response) => {
        if (response?.data?.withdrawalTransactions) {
          setRows(response.data.withdrawalTransactions.data);
        }
      });
    }
    if (tab.key === TransactionTabKey.BILL) {
      getTransactions({
        variables: {
          page,
          pageSize: defaultPageSize,
          currencyId: filterCurrency?.id,
          group: selectedGroup || null,
        },
      }).then((response) => {
        if (response?.data?.transactions) {
          setRows(response.data.transactions.data);
        } else {
          setRows(null);
        }
      });
    }
    if (tab.key === TransactionTabKey.TIPS) {
      getInternalTranfer({
        variables: {
          page,
          pageSize: defaultPageSize,
          currencyId: filterCurrency?.id,
        },
      }).then((response) => {
        if (response?.data?.internalTransfers) {
          const completedTipTransactions = filterCompletedTipsTrans(
            response?.data?.internalTransfers?.data || [],
          );
          setRows(completedTipTransactions);
        } else {
          setRows(null);
        }
      });
    }
  };

  const handleSelectTab = (tab) => {
    if (tab === selectTransactionsTab) return;

    setRows([]);
    setPageNo(defaultPageNo);
    handleGetTransactions(tab);
    setSelectTransactionsTab(tab);
  };

  const handleSelectFilterCurrency = (selectedCurrency) => {
    setFilterCurrency(selectedCurrency);
  };

  const handleSelectFilterGroup = (selectedGroup) => {
    setSelectedGroup(selectedGroup);
  };

  const handleLoadMoreDepositTransactions = (event) => {
    const listboxNode = event.currentTarget;
    const scrollPosition = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - scrollPosition < 1 &&
      depositTransactionsData?.depositTransactions.hasMore
    ) {
      refetchDepositTransactions({
        page: pageNo + 1,
        pageSize: defaultPageSize,
        currencyId: filterCurrency?.id,
      }).then((response) => {
        if (response?.data?.depositTransactions?.data) {
          setRows((prev) => [
            ...prev,
            ...response.data.depositTransactions.data,
          ]);
        }
      });
      setPageNo((prev) => prev + 1);
    }
  };

  const handleLoadMoreWithdrawTransactions = (event) => {
    const listboxNode = event.currentTarget;
    const scrollPosition = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - scrollPosition < 1 &&
      withdrawalTransactions?.withdrawalTransactions.hasMore
    ) {
      getWithdrawalTransactions({
        variables: {
          page: pageNo + 1,
          pageSize: defaultPageSize,
          currencyId: filterCurrency?.id,
        },
      }).then((response) => {
        if (response?.data?.withdrawalTransactions) {
          setRows((prev) => [
            ...prev,
            ...response.data.withdrawalTransactions.data,
          ]);
        }
      });

      setPageNo((prev) => prev + 1);
    }
  };

  const handleLoadMoreTransactions = (event) => {
    const listboxNode = event.currentTarget;
    const scrollPosition = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - scrollPosition < 1 &&
      transactions?.transactions?.hasMore
    ) {
      getTransactions({
        variables: {
          page: pageNo + 1,
          pageSize: defaultPageSize,
          currencyId: filterCurrency?.id,
          group: selectedGroup || null,
        },
      }).then((response) => {
        if (response?.data?.transactions?.data) {
          setRows((prev) => [...prev, ...response.data.transactions.data]);
        }
      });

      setPageNo((prev) => prev + 1);
    }
  };

  const handleLoadMoreTranferTransactions = (event) => {
    const listboxNode = event.currentTarget;
    const scrollPosition = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - scrollPosition < 1 &&
      internalTranfer?.internalTransfers.hasMore
    ) {
      getInternalTranfer({
        variables: {
          page: pageNo + 1,
          pageSize: defaultPageSize,
          currencyId: filterCurrency?.id,
        },
      }).then((response) => {
        if (response?.data?.internalTransfers) {
          const completedTipTransactions = filterCompletedTipsTrans(
            response?.data?.internalTransfers?.data || [],
          );
          setRows((prev) => [...prev, ...completedTipTransactions]);
        }
      });

      setPageNo((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (walletChange) {
      setPageNo(defaultPageNo);
      refetchDepositTransactions({ page: defaultPageNo });
      refetchTransaction({ page: defaultPageNo });
      refetchWithdraw({ page: pageNo });
      refetchInternalTranfer({ page: defaultPageNo });
    }
  }, [walletChange]);

  useEffect(() => {
    handleGetTransactions(selectTransactionsTab);
  }, [filterCurrency, selectedGroup]);

  useEffect(() => {
    if (
      selectTransactionsTab.key === TransactionTabKey.DEPOSIT &&
      depositTransactionsData?.depositTransactions
    ) {
      setRows(depositTransactionsData.depositTransactions.data);
    }
  }, [depositTransactionsData, selectTransactionsTab]);

  return (
    <DialogAnimate
      open={isOpen}
      onClose={handleClose}
      sx={{
        backgroundColor: 'unset',
        overflow: 'hidden',
        width: 875,
        height: 800,
        maxWidth: 'unset',
        [theme.breakpoints.down(700)]: {
          maxHeight: 'unset',
          maxWidth: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
      variants={{
        ...varSlide({
          distance: 160,
        }).inRight,
      }}
    >
      <DialogWrapper
        className="transaction-content"
        sx={{ overflowY: 'hidden' }}
      >
        <DialogHeader
          className="transaction-header"
          hasCloseBtn
          hasBackBtn={Boolean(handleBack)}
          sx={{
            padding: '16px 12px 0',
            [theme.breakpoints.down(450)]: {
              marginBottom: 0,
            },
          }}
        >
          <Typography
            className="dialog-title"
            variant="h6"
            sx={{ fontSize: '20px', lineHeight: { xs: 1.8, sm: 'inherit' } }}
          >
            {translate('transactions')}
          </Typography>
          <Box onClick={handleClose}>
            <IconButton>
              <CloseCircle width="24px" height="24px" />
            </IconButton>
          </Box>
        </DialogHeader>
        <DialogBody sx={{ overflowY: 'unset', padding: '0 12px 12px' }}>
          <TransactionBodyWrapper>
            <Box className="tab_list">
              <Tabs>
                {transactionTabs.map((tab) => (
                  <TabItem
                    key={tab.key}
                    tab={tab}
                    handleSelectTab={() => handleSelectTab(tab)}
                    isActive={selectTransactionsTab.key === tab.key}
                    sx={{ minWidth: { sm: 175 } }}
                  />
                ))}
              </Tabs>
            </Box>

            {selectTransactionsTab.key === TransactionTabKey.DEPOSIT && (
              <DepositNWithdrawTransactionTable
                rows={rows}
                handleLoadMore={handleLoadMoreDepositTransactions}
                loading={loadingDepositTransaction}
                selectedTab={selectTransactionsTab}
              />
            )}
            {selectTransactionsTab.key === TransactionTabKey.WITHDRAW && (
              <DepositNWithdrawTransactionTable
                rows={rows}
                handleLoadMore={handleLoadMoreWithdrawTransactions}
                loading={loadingWithdrawTransactions}
                selectedTab={selectTransactionsTab}
              />
            )}
            {selectTransactionsTab.key === TransactionTabKey.TIPS && (
              <TipsTransactionsTable
                rows={rows}
                handleLoadMore={handleLoadMoreTranferTransactions}
                loading={loadingInternalTranfer}
              />
            )}
            {selectTransactionsTab.key === TransactionTabKey.BILL && (
              <Box
                className="bill_content"
                display="flex"
                alignItems="flex-start"
              >
                <Box
                  className="select_filter"
                  sx={{
                    '&&': { width: '20%' },

                    backgroundColor: { sm: 'background.table' },
                    mb: { xs: 2, sm: 0 },
                  }}
                >
                  {isMobile ? (
                    <FormControl className="select_form" size="small">
                      <Select
                        value={selectedGroup || ''}
                        displayEmpty
                        onChange={({ target }) => {
                          if (
                            typeof target.value === 'string' &&
                            target.value
                          ) {
                            handleSelectFilterGroup(target.value);
                          } else handleSelectFilterGroup(null);
                        }}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        renderValue={(value) => {
                          if (!value)
                            return (
                              <Typography>
                                {translate('all_feature')}
                              </Typography>
                            );
                          return (
                            <Box>
                              <Typography>
                                {lodash.capitalize(value)}
                              </Typography>
                            </Box>
                          );
                        }}
                        MenuProps={{
                          sx: {
                            '.MuiMenu-list': {
                              backgroundColor: theme.palette.background.main,
                              padding: 0,
                              color: theme.palette.text.contrast,
                            },
                            '&&& .Mui-selected': {
                              backgroundColor:
                                theme.palette.background.hovering,
                            },
                            '&& .MuiMenuItem-root': {
                              paddingTop: theme.spacing(1),
                              paddingBottom: theme.spacing(1),
                            },
                          },
                        }}
                      >
                        <MenuItem value={null}>{translate('all')}</MenuItem>
                        {transactionGroupList.map((group) => (
                          <MenuItem value={group} key={group}>
                            <Typography>{lodash.capitalize(group)}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <SelectGroupBox>
                      <Box
                        className={`group_item ${!selectedGroup && 'active'}`}
                        onClick={() => setSelectedGroup(null)}
                      >
                        <Typography>{translate('all')}</Typography>
                      </Box>
                      {transactionGroupList.map((group) => (
                        <Box
                          key={group}
                          className={`group_item ${
                            selectedGroup === group && 'active'
                          }`}
                          onClick={() => setSelectedGroup(group)}
                        >
                          <Typography>{translate(group)}</Typography>
                        </Box>
                      ))}
                    </SelectGroupBox>
                  )}
                </Box>
                <BillTransactionsTable
                  rows={rows}
                  handleLoadMore={handleLoadMoreTransactions}
                  loading={loadingTransactions}
                />
              </Box>
            )}
            <DiaLogFooter>
              <Box className="select_filter coin">
                <FormControl sx={{ minWidth: 130 }} size="small">
                  <Select
                    value={filterCurrency || ''}
                    displayEmpty
                    onChange={({ target }) => {
                      if (typeof target.value === 'string' && target.value) {
                        handleSelectFilterCurrency(JSON.parse(target.value));
                      } else handleSelectFilterCurrency('');
                    }}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    renderValue={(value) => {
                      if (!value)
                        return (
                          <Typography>{translate('all_currency')}</Typography>
                        );
                      return (
                        <Box display="flex">
                          <img
                            style={{
                              width: '24px',
                              height: '24px',
                              marginRight: '8px',
                            }}
                            className="coin_img"
                            src={currencyImages[value.shortName]}
                            alt={value.name}
                          />
                          <Typography
                            textTransform="uppercase"
                            lineHeight="normal"
                            display="flex"
                            alignItems="center"
                          >
                            {value.shortName}
                          </Typography>
                        </Box>
                      );
                    }}
                    MenuProps={{
                      transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      sx: {
                        '.MuiMenu-list': {
                          backgroundColor: theme.palette.background.main,
                          padding: 0,
                          color: theme.palette.text.contrast,
                          maxHeight: 300,
                          overflow: 'auto',
                        },
                        '&&& .Mui-selected': {
                          backgroundColor: theme.palette.background.hovering,
                        },
                        '&& .MuiMenuItem-root': {
                          paddingTop: theme.spacing(1),
                          paddingBottom: theme.spacing(1),
                        },
                      },
                    }}
                  >
                    <MenuItem value="">{translate('all_currency')}</MenuItem>
                    {currencies.map((currency) => (
                      <MenuItem
                        value={JSON.stringify(currency)}
                        key={currency.id}
                      >
                        <img
                          style={{
                            width: '24px',
                            height: '24px',
                            marginRight: '5px',
                          }}
                          className="coin_img"
                          src={currencyImages[currency.shortName]}
                          alt={currency.shortName}
                        />
                        <Typography textTransform="uppercase">
                          {currency.shortName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </DiaLogFooter>
          </TransactionBodyWrapper>
        </DialogBody>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default TransactionsDialog;
