import {
  bit,
  bnb,
  eth,
  shiba,
  usdt,
  wild,
} from 'assets/images/minicoco/PayTable';
import { useLocales } from 'locales';

import { Box, Typography } from '@mui/material';

import { PayTableWrapper } from './styles';

// ------------------------------------------------

const GameInfo = () => {
  const { translate } = useLocales();

  return (
    <PayTableWrapper>
      <Box className="currency">
        <Box>
          <img src={wild} alt="wild" />
          <img src={wild} alt="wild" />
          <img src={wild} alt="wild" />
        </Box>
        <Typography>{translate('jackpot')}</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={bit} alt="bit" />
          <img src={bit} alt="bit" />
          <img src={bit} alt="bit" />
        </Box>
        <Typography>x80</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={eth} alt="eth" />
          <img src={eth} alt="eth" />
          <img src={eth} alt="eth" />
        </Box>
        <Typography>x32</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={usdt} alt="usdt" />
          <img src={usdt} alt="usdt" />
          <img src={usdt} alt="usdt" />
        </Box>
        <Typography>x16</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={bnb} alt="bnb" />
          <img src={bnb} alt="bnb" />
          <img src={bnb} alt="bnb" />
        </Box>
        <Typography>x10</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={shiba} alt="shiba" />
          <img src={shiba} alt="shiba" />
          <img src={shiba} alt="shiba" />
        </Box>
        <Typography>x6</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={bit} alt="bit" />
          <img src={bit} alt="bit" />
        </Box>
        <Typography>x4</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={eth} alt="eth" />
          <img src={eth} alt="eth" />
        </Box>
        <Typography>x2.5</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={usdt} alt="usdt" />
          <img src={usdt} alt="usdt" />
        </Box>
        <Typography>x1</Typography>
      </Box>

      <Box className="currency">
        <Box>
          <img src={bnb} alt="bnb" />
          <img src={bnb} alt="bnb" />
        </Box>
        <Typography>x0.5</Typography>
      </Box>
    </PayTableWrapper>
  );
};

export default GameInfo;
