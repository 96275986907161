import { useReactiveVar } from '@apollo/client';
import { ReactComponent as BuyCryptoIcon } from 'assets/icons/BuyCryptoIcon.svg';
import { ReactComponent as CodgIcon } from 'assets/icons/CODG.svg';
import { ReactComponent as DepositIcon } from 'assets/icons/deposit.svg';
import { ReactComponent as IconCrypto } from 'assets/icons/IconCrypto.svg';
import { ReactComponent as IconPiWallet } from 'assets/icons/IconPiWallet.svg';
import { ReactComponent as SendTipIcon } from 'assets/icons/send-tip.svg';
import { ReactComponent as SwapIcon } from 'assets/icons/swap.svg';
import { ReactComponent as WithdrawIcon } from 'assets/icons/withdraw.svg';
import DialogAnimate from 'components/animate/DialogAnimate';
import { DialogBody, DialogWrapper } from 'components/Dialog/styles';
import TransactionsDialog from 'components/TransactionsDialog';
import { WALLET_TAB_KEY } from 'constants/index';
import {
  Currency,
  IdentificationStatus,
  User,
  useUserIdentificationQuery,
} from 'graph';
import useResponsive from 'hooks/useResponsive';
import React, { useMemo, useState } from 'react';
import { storageCurrencyVar } from 'store';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import BuyCrypto from './components/BuyCrypto';
import CODG from './components/CODG';
import Deposit from './components/Deposit';
import Swap from './components/Swap';
import Tip from './components/Tip';
import WalletDialogHeader from './components/WalletDialogHeader';
import Withdraw from './components/Withdraw';
import { WalletBody, WalletNavigation } from './styles';
import WalletTabItem from './WalletTabItem';

/* -------------------------------------------------------------------------- */

interface WalletDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  tabIdDefault?: number;
  user?: User;
}

export const SUB_TAB = [
  {
    id: 1,
    label: 'Crypto',
    icon: <IconCrypto />,
  },
  {
    id: 2,
    label: 'Pi',
    icon: <IconPiWallet />,
  },
];

const WalletDialog = ({
  isOpen,
  handleClose,
  tabIdDefault,
  user,
}: WalletDialogProps) => {
  const theme = useTheme();
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const { data: userIdentificationData, loading: loadingUserIndetification } =
    useUserIdentificationQuery();
  const isMobile = useResponsive('down', 'sm');
  const isMiddle = useResponsive('between', 'sm', 'lg');

  const walletTabs = [
    {
      key: WALLET_TAB_KEY.DEPOSIT,
      label: 'deposit',
      icon: <DepositIcon width="24px" height="24px" />,
      subTab: SUB_TAB,
    },
    {
      key: WALLET_TAB_KEY.BUY_CRYPTO,
      label: 'buy Crypto',
      icon: <BuyCryptoIcon width="24px" height="24px" />,
    },
    {
      key: WALLET_TAB_KEY.WITHDRAW,
      label: 'withdraw',
      icon: <WithdrawIcon width="24px" height="24px" />,
      subTab: SUB_TAB,
    },
    {
      key: WALLET_TAB_KEY.SWAP,
      label: 'swap',
      icon: <SwapIcon width="24px" height="24px" />,
    },
    {
      key: WALLET_TAB_KEY.TIP,
      label: 'tip',
      icon: <SendTipIcon width="24px" height="24px" />,
    },

    {
      key: WALLET_TAB_KEY.CODG,
      label: 'COD Gift',
      icon: <CodgIcon width="24px" height="24px" />,
      iconColor: true,
    },
  ];

  const [selectedWalletTab, setSelectedWalletTab] = useState(
    walletTabs[tabIdDefault],
  );
  const [selectedSubTab, setSelectedSubTab] = useState(walletTabs[0].subTab[0]);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(
    storageCurrency,
  );
  const [isWalletDialogHidden, setIsWalletDialogHidden] = useState(false);
  const [isTransactionsDialogOpen, setIsTransactionDialogOpen] =
    useState(false);

  const isAccountAccepted = useMemo(() => {
    if (!userIdentificationData) return false;
    return (
      !userIdentificationData?.userIdentification?.identification &&
      userIdentificationData?.userIdentification?.status ===
        IdentificationStatus.Accepted
    );
  }, [userIdentificationData?.userIdentification]);

  const isAccountNotVerified = useMemo(() => {
    if (!userIdentificationData) return false;
    return (
      (!userIdentificationData?.userIdentification?.identification &&
        userIdentificationData?.userIdentification?.status ===
          IdentificationStatus.New) ||
      userIdentificationData?.userIdentification?.status ===
        IdentificationStatus.Rejected
    );
  }, [userIdentificationData?.userIdentification]);

  const handleSelectSubTab = (selectedSubTab) => {
    setSelectedSubTab(selectedSubTab);
  };
  const handleSelectTab = (selectedTab: any) => {
    setSelectedWalletTab(selectedTab);
  };

  const handleCloseTransactionDialog = () => {
    handleClose();
    setIsTransactionDialogOpen(false);
  };

  const handleClickBackTransactionDialog = () => {
    setIsTransactionDialogOpen(false);
  };

  const handleSelectCurrency = (
    currency: React.SetStateAction<Currency | null>,
  ) => {
    setSelectedCurrency(currency);
  };

  return (
    <>
      <DialogAnimate
        open={isOpen}
        onClose={handleClose}
        id="wallet-dialog"
        sx={{
          width: 680,
          minHeight: '450px',
          maxWidth: 'unset',
          display: isWalletDialogHidden && 'none',
          borderRadius: '12px !important',
          [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset',
            minHeight: 'unset',
            '&&&': { margin: 0 },
            width: '100%',
            height: '100%',
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: `${theme.shape.borderRadius}px`,
          },
        }}
      >
        {(isMobile || isMiddle) && (
          <Box
            className="wallet-header"
            padding={{ xs: '12px 12px 0 12px', md: '12px 12px 0 12px' }}
          >
            <WalletDialogHeader
              handleClose={handleClose}
              title={selectedWalletTab.key}
            />
          </Box>
        )}
        <DialogWrapper sx={{ height: { xs: 'inherit', md: '600px' } }}>
          <DialogBody
            sx={{
              display: 'flex',
              overflowY: 'unset',
              padding: 0,
              flexDirection: { sm: 'column', lg: 'row' },
              height: '100%',
            }}
          >
            <WalletNavigation>
              {walletTabs.map((walletTab) => (
                <WalletTabItem
                  key={walletTab.key}
                  tab={walletTab}
                  isActive={walletTab.key === selectedWalletTab.key}
                  handleSelectTab={() => handleSelectTab(walletTab)}
                  selectedSubTab={selectedSubTab}
                  handleSelectSubTab={(subtab) => handleSelectSubTab(subtab)}
                />
              ))}
            </WalletNavigation>
            <WalletBody
              style={{ overflowY: 'scroll', height: 'inherit', width: '100%' }}
            >
              {selectedWalletTab.key === WALLET_TAB_KEY.DEPOSIT && (
                <Deposit
                  handleClose={handleClose}
                  handleCloseWalletDialog={handleClose}
                  selectedCurrency={selectedCurrency}
                  handleSelectCurrency={handleSelectCurrency}
                  selectedSubTab={selectedSubTab}
                  subTab={selectedWalletTab.subTab}
                  handleSelectSubTab={(subtab) => handleSelectSubTab(subtab)}
                  handleChangeWalletTab={(tabId) =>
                    setSelectedWalletTab(walletTabs[tabId])
                  }
                />
              )}
              {selectedWalletTab.key === WALLET_TAB_KEY.WITHDRAW && (
                <Withdraw
                  setIsWalletDialogHidden={setIsWalletDialogHidden}
                  handleCloseWallet={handleClose}
                  selectedCurrency={selectedCurrency}
                  handleSelectCurrency={handleSelectCurrency}
                  isAccountNotVerified={isAccountNotVerified}
                  selectedSubTab={selectedSubTab}
                  subTab={selectedWalletTab.subTab}
                  handleSelectSubTab={(subtab) => handleSelectSubTab(subtab)}
                />
              )}
              {selectedWalletTab.key === WALLET_TAB_KEY.SWAP && (
                <Swap handleCloseWallet={handleClose} />
              )}
              {selectedWalletTab.key === WALLET_TAB_KEY.TIP && (
                <Tip
                  handleCloseWalletDialog={handleClose}
                  isAccountAccepted={isAccountAccepted}
                  user={user}
                  loadingAccountAccepted={loadingUserIndetification}
                />
              )}
              {selectedWalletTab.key === WALLET_TAB_KEY.BUY_CRYPTO && (
                <BuyCrypto
                  handleClose={handleClose}
                  handleCloseWalletDialog={handleClose}
                />
              )}
              {selectedWalletTab.key === WALLET_TAB_KEY.CODG && (
                <CODG handleCloseWalletDialog={handleClose} />
              )}

              {/* <WalletFooter
                sx={{
                  ...(((selectedWalletTab.key === WALLET_TAB_KEY.WITHDRAW &&
                    isAccountNotVerified) ||
                    (selectedWalletTab.key === WALLET_TAB_KEY.TIP &&
                      !isAccountAccepted)) && {
                    px: 5,
                    py: 3.5,
                  }),
                }}
                display="flex"
              >
                {selectedWalletTab.key === WALLET_TAB_KEY.WITHDRAW &&
                  isAccountNotVerified && (
                    <ConfirmedButton
                      onClick={handleNavigateToVerify}
                      variant="contained"
                      color="secondary"
                    >
                      <Typography variant="button">
                        {translate('verify_account')}
                      </Typography>
                    </ConfirmedButton>
                  )}
                {selectedWalletTab.key === WALLET_TAB_KEY.TIP &&
                  !isAccountAccepted && (
                    <ConfirmedButton
                      onClick={handleNavigateToVerify}
                      variant="contained"
                      color="secondary"
                    >
                      <Typography variant="button">
                        {translate('verify_account')}
                      </Typography>
                    </ConfirmedButton>
                  )}
              </WalletFooter> */}
            </WalletBody>
          </DialogBody>
        </DialogWrapper>
      </DialogAnimate>
      {isTransactionsDialogOpen && (
        <TransactionsDialog
          isOpen={isTransactionsDialogOpen}
          handleClose={handleCloseTransactionDialog}
          handleBack={handleClickBackTransactionDialog}
        />
      )}
    </>
  );
};
WalletDialog.defaultProps = {
  tabIdDefault: 0,
};

export default React.memo(WalletDialog);
