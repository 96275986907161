import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { DialogHeaderStyled } from 'common/Dialog';
import { useLocales } from 'locales';

import { IconButton, Typography } from '@mui/material';
import { BetHistories } from 'overrides/components/text-host/TextHost';

interface IBetHistoriesDialogHeaderProps {
  onClose: VoidFunction;
}

export const BetHistoriesDialogHeader = ({
  onClose,
}: IBetHistoriesDialogHeaderProps) => {
  const { translate } = useLocales();

  return (
    <DialogHeaderStyled>
      <Typography className="dialog-title" variant="h6" color="text.contrast">
        {translate(BetHistories())}
      </Typography>
      <IconButton className="close-btn" onClick={() => onClose()}>
        <CloseCircle width="24px" height="24px" />
      </IconButton>
    </DialogHeaderStyled>
  );
};
