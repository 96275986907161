import { SvgIcon } from '@mui/material';

export function IconUpMiniGame(props: any) {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="32"
        height="32"
        rx="8"
        fill="url(#paint0_linear_439_11235)"
      />
      <path
        d="M15.8252 13.3333C15.6821 13.3333 15.5391 13.3894 15.4301 13.5012L11.9987 17.0213C11.7804 17.2452 11.7804 17.6083 11.9987 17.8321C12.2169 18.056 12.5707 18.056 12.789 17.8321L15.8252 14.7173L18.8613 17.832C19.0796 18.0558 19.4334 18.0558 19.6516 17.832C19.87 17.6082 19.87 17.2451 19.6516 17.0212L16.2202 13.5011C16.1111 13.3892 15.9681 13.3333 15.8252 13.3333Z"
        fill="#9998D4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_439_11235"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#313064" />
          <stop offset="1" stopColor="#43416C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
