import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const SelectCurrencyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  backgroundColor: theme.palette.background.main,
  borderRadius: theme.shape.borderRadius,
  img: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
  },
  svg: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
  },
  '.selected-coin': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
    cursor: 'pointer',
    p: {
      marginRight: theme.spacing(2.5),
      [theme.breakpoints.down(400)]: {
        marginRight: 0,
      },
    },
    'p, svg': {
      color: theme.palette.text.primary,
    },
  },
  '.balance_box': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '.MuiInputBase-root': {
      display: 'flex',
      justifyContent: 'flex-end',
      input: {
        textAlign: 'end',
        padding: 0,
        color: theme.palette.text.contrast,
      },
      '&.disabled': {
        color: theme.palette.text.contrast,
        webkitTextFillColor: theme.palette.text.contrast,
      },
    },

    p: {
      color: theme.palette.text.primary,
    },

    '.balance_amount': {
      color: theme.palette.text.primary,
      fontSize: '12px',
      span: {
        color: theme.palette.primary.main,
      },

      '.balance-value': {
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 'normal',
      },
    },
  },
}));
