import { SvgIcon } from '@mui/material';

export function IconStatus(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.9898 22.0496L9.98603 22.0495L9.98369 22.0486L9.93958 22.0396C6.29002 21.2964 3.32498 18.6091 2.2182 15.0442C2.17599 14.9003 2.25748 14.7652 2.37524 14.728C2.51979 14.6856 2.65541 14.768 2.69191 14.8866L2.69191 14.8866L2.69229 14.8879C3.74624 18.2816 6.56068 20.8331 10.0403 21.5396L10.0403 21.5396L10.044 21.5403C10.175 21.5659 10.262 21.693 10.2314 21.8324L10.2313 21.8323L10.229 21.8441C10.2052 21.9665 10.0932 22.0496 9.9898 22.0496Z"
        fill="#858997"
        stroke="#858997"
      />
      <path
        d="M2.30277 10.9995L2.30259 11.0015C2.28835 11.1473 2.1664 11.2386 2.03933 11.2235L2.03934 11.2234L2.02877 11.2224C1.89783 11.2096 1.79467 11.0882 1.80778 10.9493C2.34235 5.70559 6.72679 1.75 12.0002 1.75C17.2843 1.75 21.668 5.70621 22.1926 10.9491C22.2059 11.088 22.1027 11.2096 21.9717 11.2224L21.9168 11.2277C21.8021 11.212 21.7093 11.1174 21.6975 11.0082C21.191 6.01302 17.0158 2.25 12.0002 2.25C6.97412 2.25 2.80873 6.01375 2.30277 10.9995Z"
        fill="#858997"
        stroke="#858997"
      />
      <path
        d="M13.7709 21.8544L13.7708 21.8541C13.7425 21.7087 13.8335 21.5851 13.9581 21.5601L13.9581 21.5601L13.9602 21.5597C17.4178 20.8534 20.2322 18.3237 21.2969 14.9503L21.2969 14.9504L21.299 14.9435C21.3378 14.8143 21.479 14.7432 21.602 14.7842L21.6092 14.7866L21.6164 14.7888C21.7339 14.824 21.815 14.9606 21.7727 15.1012C20.6508 18.6347 17.7124 21.2873 14.0992 22.0419C14.0739 22.0434 14.0527 22.0456 14.0395 22.0469C14.038 22.0471 14.0366 22.0472 14.0353 22.0473C14.016 22.0493 14.0094 22.0497 14.0083 22.0498C13.8898 22.0491 13.7935 21.9705 13.7709 21.8544Z"
        fill="#292D32"
        stroke="#858997"
      />
    </SvgIcon>
  );
}
