import { SvgIcon } from '@mui/material';

export function IconClock(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.1705 12.2571C22.0127 12.0879 21.8094 11.9679 21.585 11.9116C21.3606 11.8553 21.1247 11.8651 20.9058 11.9397C20.6868 12.0144 20.4941 12.1508 20.3509 12.3324C20.2076 12.5141 20.1199 12.7333 20.0984 12.9636C18.9708 22.603 5.24578 22.3797 4.37446 12.7366C4.23467 10.6865 4.90381 8.66332 6.23838 7.10085C7.57295 5.53838 9.46666 4.56111 11.5134 4.37862C11.8257 4.35035 12.1143 4.20009 12.3166 3.96044C12.5189 3.72078 12.6186 3.41103 12.594 3.09837C12.5695 2.78571 12.4226 2.49534 12.1854 2.29022C11.9481 2.0851 11.6396 1.98177 11.3267 2.00264C1.63605 2.67992 -1.62591 16.3501 6.97377 21.1752C10.2687 22.8629 13.9955 22.991 17.2026 21.2265C18.676 20.4156 19.9274 19.2549 20.8467 17.8466C21.766 16.4382 22.325 14.8255 22.4744 13.1503C22.488 12.9885 22.468 12.8257 22.4157 12.6719C22.3634 12.5182 22.2799 12.377 22.1705 12.2571Z"
        fill="white"
      />
      <path
        d="M5.82016 12.2497C5.82202 13.9483 6.49761 15.5768 7.6987 16.7779C8.8998 17.979 10.5283 18.6546 12.2269 18.6565C20.7277 18.305 20.724 6.19445 12.2269 5.84302C10.5283 5.84488 8.89979 6.52047 7.6987 7.72156C6.49761 8.92265 5.82202 10.5511 5.82016 12.2497ZM12.593 7.85656V11.2173C12.7461 11.2728 12.8851 11.3612 13.0003 11.4764C13.1154 11.5915 13.2038 11.7305 13.2593 11.8836H15.5694C15.6665 11.8836 15.7596 11.9222 15.8282 11.9909C15.8969 12.0595 15.9355 12.1526 15.9355 12.2497C15.9355 12.3468 15.8969 12.44 15.8282 12.5086C15.7596 12.5773 15.6665 12.6158 15.5694 12.6158H13.2593C13.1977 12.7906 13.0928 12.947 12.9544 13.0703C12.8161 13.1936 12.6487 13.2797 12.468 13.3208C12.2872 13.3619 12.099 13.3564 11.921 13.305C11.7429 13.2536 11.5808 13.1579 11.4497 13.0269C11.3187 12.8958 11.223 12.7337 11.1716 12.5556C11.1202 12.3776 11.1148 12.1894 11.1558 12.0087C11.1969 11.8279 11.2831 11.6606 11.4064 11.5222C11.5296 11.3838 11.686 11.2789 11.8608 11.2173V7.85656C11.8629 7.76084 11.9023 7.66974 11.9708 7.60278C12.0392 7.53581 12.1311 7.49831 12.2269 7.49831C12.3226 7.49831 12.4146 7.53581 12.483 7.60277C12.5514 7.66974 12.5909 7.76084 12.593 7.85656Z"
        fill="white"
      />
      <path
        d="M12.2269 12.6157C12.3228 12.614 12.4143 12.5747 12.4816 12.5062C12.5488 12.4378 12.5865 12.3456 12.5865 12.2496C12.5865 12.1537 12.5488 12.0615 12.4816 11.9931C12.4143 11.9246 12.3228 11.8853 12.2269 11.8835C12.1309 11.8853 12.0395 11.9246 11.9722 11.9931C11.905 12.0615 11.8673 12.1537 11.8673 12.2496C11.8673 12.3456 11.905 12.4378 11.9722 12.5062C12.0395 12.5747 12.1309 12.614 12.2269 12.6157Z"
        fill="white"
      />
    </SvgIcon>
  );
}
