import { ComingSoonIllustration } from 'assets/illustrations';

import { Stack } from '@mui/material';

export default function PageComingSoon() {
  return (
    <Stack
      py={12}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      rowGap={5}
    >
      <ComingSoonIllustration />
    </Stack>
  );
}
