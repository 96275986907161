import { SvgIcon } from '@mui/material';

export function IconGameMiniCrypto(props: any) {
  return (
    <SvgIcon
      fill="#FFFFFF"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 50 45.2"
      style={{ enableBackground: 'new 0 0 50 45.2' }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          className="st0"
          d="M25.6,14.9l-1.4,5.8c1.6,0.4,6.6,2.1,7.5-1.2C32.4,16,27.2,15.3,25.6,14.9z"
        />
        <path
          className="st0"
          d="M23.4,23.5l-1.6,6.3c2,0.5,8,2.4,8.9-1.2h0C31.6,25,25.4,24,23.4,23.5z"
        />
        <path
          className="st0"
          d="M25.4,0.2C13,0.2,2.9,10.3,2.9,22.7c0,12.4,10.1,22.5,22.5,22.5s22.5-10.1,22.5-22.5
		C47.9,10.3,37.9,0.2,25.4,0.2z M37.3,19.5L37.3,19.5c-0.4,2.6-1.8,3.8-3.7,4.2c2.6,1.3,3.9,3.4,2.6,7c-1.6,4.4-5.2,4.8-10.2,3.9
		l-1.2,4.8L22,38.6l1.2-4.7c-0.7-0.2-1.5-0.4-2.3-0.6l-1.2,4.7l-2.9-0.7l1.2-4.8c-0.7-0.2-1.4-0.4-2.1-0.5l-3.8-0.9l1.4-3.3
		c0,0,2.1,0.6,2.1,0.5c0.8,0.2,1.2-0.3,1.3-0.7l1.9-7.6l1.3-5.4c0-0.6-0.2-1.4-1.3-1.7c0,0-2.1-0.5-2.1-0.5l0.8-3.1l4,1l0,0
		c0.6,0.1,1.2,0.3,1.8,0.4l1.2-4.7l2.9,0.7l-1.2,4.6c0.8,0.2,1.6,0.4,2.3,0.5l1.1-4.6l2.9,0.7l-1.2,4.7C35.2,14.1,37.8,16,37.3,19.5
		z"
        />
      </g>
    </SvgIcon>
  );
}
