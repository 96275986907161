import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from 'auth/CocoAuthContext';
import { MotionLazyContainer } from 'components/animate';
import NetworkStatus from 'components/network-status';
import ScrollToTop from 'components/scroll-to-top';
import { SettingsProvider } from 'components/settings';
import SnackbarProvider from 'components/snackbar';
import { REFERRAL_CODE } from 'constants/';
import { client } from 'graph/apollo';
import ThemeLocalization from 'locales';
import { useLayoutEffect } from 'react';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BrowserRouter } from 'react-router-dom';
import Router from 'routes';
import 'simplebar-react/dist/simplebar.min.css';
import ThemeProvider from 'theme/ThemeProvider';

import { ErrorBoundary } from 'components/ErrorBoundary';
import './locales/i18n';
// ----------------------------------------------------------------------

export default function App() {
  ReactGA.initialize('G-6SBJY5JWNR');
  useLayoutEffect(() => {
    const { href, search } = window.location;
    if (search.includes('ref')) {
      const newRefCode = search.split('ref=').pop();
      const currentUrl = href.replace(search, '');
      localStorage.setItem(REFERRAL_CODE, newRefCode);
      window.history.pushState(null, 'FeaturePoints Login', currentUrl);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeLocalization>
                  <SnackbarProvider>
                    <AuthProvider>
                      <ErrorBoundary>
                        <NetworkStatus />
                        <Router />
                      </ErrorBoundary>
                    </AuthProvider>
                  </SnackbarProvider>
                </ThemeLocalization>
              </ThemeProvider>
            </MotionLazyContainer>
          </BrowserRouter>
        </SettingsProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
}
