import { DialogHeaderStyled } from 'common/Dialog';

import { styled } from '@mui/material';

export const MiniDiceTopProfitDialogHeaderStyled = styled(DialogHeaderStyled)(
  () => ({
    'button.select-date-btn': {
      padding: 0,
      fontSize: 14,
      fontWeight: 600,
      display: 'contents',
    },
  }),
);
