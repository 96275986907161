import { SvgIcon } from '@mui/material';

export function IconUnlockUser(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <circle
          cx="12"
          cy="8"
          r="4"
          stroke="green"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          fill="green"
          fillRule="evenodd"
          d="M13.327 15.076C12.889 15.026 12.445 15 12 15c-1.92 0-3.806.474-5.369 1.373c-1.562.9-2.75 2.197-3.3 3.738a1 1 0 0 0 1.883.672c.362-1.01 1.183-1.967 2.415-2.676c1.014-.584 2.235-.957 3.529-1.07a3.005 3.005 0 0 1 2.169-1.961"
          clipRule="evenodd"
        />
        <path
          stroke="green"
          strokeLinecap="round"
          strokeWidth="2.5"
          d="M18 14v8m4-4h-8"
        />
      </g>
    </SvgIcon>
  );
}
