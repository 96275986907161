import { SvgIcon } from '@mui/material';

export function LogoCocoMB(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      {...props}
    >
      <path
        d="M26.1424 17.8276C26.1424 22.1291 22.6979 25.6165 18.442 25.6312C18.4323 25.6312 18.4177 25.6312 18.408 25.6312C14.1376 25.6312 10.6736 22.134 10.6736 17.8227C10.6251 14.5217 12.4858 12.0939 15.323 9.62182C15.4542 9.50901 15.5708 9.3962 15.6923 9.2883L15.6825 9.27849C15.6825 9.27849 17.2712 7.90023 17.9854 6.08054C17.9854 6.07563 17.9902 6.06582 17.9951 6.06092C18.3254 5.20748 18.4566 4.26085 18.1457 3.30931C17.9805 2.80411 17.689 2.30382 17.2323 1.81334C14.9344 -0.526263 11.2858 0.93047 9.98863 1.56319C9.68741 1.71034 9.51252 1.81334 9.51252 1.81334V1.80353C3.84776 4.86414 0 10.8873 0 17.8227C0 23.3014 2.39999 28.2111 6.19917 31.5415C11.5724 32.3508 15.5271 32.081 18.4372 31.1785C28.2995 28.1228 26.1376 17.8227 26.1376 17.8227L26.1424 17.8276Z"
        fill="#EFC516"
      />
      <path
        d="M26.949 2.07331C26.7595 1.9556 26.536 1.82807 26.2785 1.70055C26.23 1.67602 26.1862 1.64659 26.1377 1.62207C25.9676 1.53869 25.749 1.44059 25.5061 1.33759C25.5061 1.33759 25.5061 1.33759 25.5012 1.33759H25.4915C25.3263 1.26892 25.1466 1.20025 24.9571 1.13649C18.7676 -1.23254 11.9563 0.837296 11.9563 0.837296C13.5887 0.395862 15.7069 0.273241 17.2373 1.82807C17.694 2.31365 17.9855 2.81885 18.1506 3.32404C18.3255 3.84886 18.3547 4.36877 18.2964 4.87887C18.3255 5.08978 18.3693 5.30069 18.4324 5.50179C18.4519 5.58026 18.4859 5.70779 18.5539 5.88927C18.5539 5.89417 18.5587 5.90398 18.5636 5.90889C18.8308 6.59556 19.5547 7.94439 21.498 9.63165C24.3401 12.1037 26.196 14.5365 26.1474 17.8325C26.1474 17.8325 28.2996 28.1179 18.447 31.1786C15.5369 32.081 11.5871 32.3606 6.20898 31.5513C9.36687 34.3225 13.4915 36 18.0049 36C27.9449 36 36 27.8678 36 17.8325C36 11.0884 32.3563 5.2124 26.9538 2.07822L26.949 2.07331Z"
        fill="#8527FF"
      />
    </SvgIcon>
  );
}
