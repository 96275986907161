import { Box, Button, ButtonProps } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

interface ContinueButtonProps extends ButtonProps {
  $click?: boolean;
  themeMode: string;
}

export const NotificationButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== '$click' && prop !== 'themeMode',
})<ContinueButtonProps>(({ $click, themeMode, theme }) => ({
  fontSize: '14px !important',
  color: $click && theme.palette.primary.contrastText,
  background: $click
    ? 'linear-gradient(180deg, #313064 0%, #43416C 100%)'
    : theme.palette.background.main,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  ...(themeMode === 'light' && { color: theme.palette.grey[700] }),
}));

export const ButtonAttachment = styled(Button)(() => ({
  '&:hover': {
    textDecoration: 'underline',
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const BoxDescription = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'height'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  fontSize: '14px',
  display: '',
  ...(open && {
    display: '-webkit-box !important',
    transition: theme.transitions.create(['margin', 'height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginTop: 1,
  }),
}));

export const NoNotication = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(5),

  svg: {
    width: 200,
    height: 163,
  },

  span: {
    width: '100%',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: 'normal',
    textAlign: 'center',
  },
}));
