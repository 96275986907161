// @mui
import { enUS, esES, jaJP, koKR, ptPT, trTR, zhTW } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'ENG',
    fullLabel: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'KOR',
    fullLabel: 'Korean',
    value: 'kr',
    systemValue: koKR,
    icon: '/assets/icons/flags/ic_flag_kr.svg',
  },
  {
    label: 'TWN',
    fullLabel: 'Taiwanese',
    value: 'tw',
    systemValue: zhTW,
    icon: '/assets/icons/flags/ic_flag_tw.svg',
  },
  {
    label: 'SPA',
    fullLabel: 'Spanish',
    value: 'es',
    systemValue: esES,
    icon: '/assets/icons/flags/ic_flag_es.svg',
  },
  {
    label: 'POR',
    fullLabel: 'Portuguese',
    value: 'pt',
    systemValue: ptPT,
    icon: '/assets/icons/flags/ic_flag_por.svg',
  },
  {
    label: 'TUR',
    fullLabel: 'Turkish',
    value: 'tr',
    systemValue: trTR,
    icon: '/assets/icons/flags/ic_flag_tur.svg',
  },
  {
    label: 'JPN',
    fullLabel: 'Japanese',
    value: 'ja',
    systemValue: jaJP,
    icon: '/assets/icons/flags/ic_flag_japan.svg',
  },
];

export const defaultLang = allLangs[0]; // English
