import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import currencyImages from 'assets/images/coins';
import FormProvider, { RHFTextField } from 'components/hook-form';
import {
  Currency,
  TransferSetting,
  useInternalTransferSettingsQuery,
  User,
  useUserInfoQuery,
  useUserWalletsQuery,
} from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { currenciesVar, storageCurrencyVar } from 'store';
import * as Yup from 'yup';

import InfoIcon from '@mui/icons-material/Info';
import {
  Autocomplete,
  Avatar,
  Box,
  InputBase,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { hiddenUser } from 'assets/images';
import LoadingAnimated from 'components/LoadingAnimated';
import { QuickSelectWithdrawItem, QuickWithdrawAmountBox } from '../../styles';
import AssetsPortfolioDialog from '../AssetsPortfolioDialog';
import SelectCurrency from '../SelectCurrency';
import WalletDialogHeader from '../WalletDialogHeader';
import AccountNotVerified from '../Withdraw/AccountNotVerified';
import {
  AmountBox,
  ChangeButton,
  ContinueButton,
  SelectCoinBox,
  TipWrapper,
} from './styles';
import { TipInformation } from './TipInformation';

// ----------------------------------------------------------------

export interface TipProps {
  handleCloseWalletDialog: VoidFunction;
  isAccountAccepted: boolean;
  loadingAccountAccepted: boolean;
  user?: User;
}

const quickSelectOption = [
  {
    label: 'max2',
    value: 100,
  },
];

export function Tip(props: TipProps) {
  const {
    handleCloseWalletDialog,
    isAccountAccepted,
    user,
    loadingAccountAccepted,
  } = props;
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(
    storageCurrency,
  );

  const isDesktop = useResponsive('up', 'md');
  const isMobile = useResponsive('down', 'sm');
  const isLargeDesktop = useResponsive('up', 'lg');
  const { translate } = useLocales();
  const [tipAmount, setTipAmount] = useState(null);
  const [isAssetPortfolioOpen, setIsAssetPortfolioOpen] = useState(false);
  const { currencies } = useReactiveVar(currenciesVar);
  const [receiver, setReceiver] = useState(null);
  const [isTipInformationOpen, setIsTipInformationOpen] = useState(false);
  const [dataTipping, setDataTipping] = useState({
    message: '',
    receiver: '',
    tipAmount: 0,
    avatar: '',
    label: '',
  });
  const allValues = [];
  const [transactionFee, setTransactionFee] = useState<TransferSetting>();

  const { data: userWalletsData } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });
  const { data: userInfoData } = useUserInfoQuery({
    fetchPolicy: 'cache-only',
  });

  const amountCurrency: number =
    userWalletsData?.me?.userWallets.find(
      (userWallet) => userWallet.currencyId === selectedCurrency?.id,
    )?.amount || 0;

  const tipCurrencies = useMemo(
    () =>
      currencies
        .filter((currency) => currency.id && currency.id !== '2')
        .map((currency: any) => ({
          ...currency,
          image: currencyImages[currency?.shortName],
        })),
    [currencies],
  );

  const schemaInvest = Yup.object().shape({
    message: Yup.string().required(translate('message_is_required')),
    receiver: Yup.string()
      .matches(/^[0-9]+$/, translate('must_be_only_digits'))
      .min(14, translate('min_length_ID_at_least_14_number'))
      .max(20, translate('max_length_ID_not_more_than_20_number'))
      .required(translate('receiver_is_required')),
    tipAmount: Yup.number()
      .typeError(translate('tip_amount_must_specify_a_number'))
      .min(
        transactionFee?.transferMinimumAmount,
        `${translate('tip_amount_must_specify_a_number')} ${
          transactionFee?.transferMinimumAmount
        }`,
      )
      .required(translate('tip_amount_is_required')),
  });

  const form = useForm({
    defaultValues: {
      message: `${userInfoData?.me?.user?.nickname} tip`,
      receiver: '',
      tipAmount: 0,
    },
    resolver: yupResolver(schemaInvest),
    mode: 'onBlur',
  });

  const onSubmitData = (data) => {
    setIsTipInformationOpen(true);
    if (user) {
      const dataReceiver = { ...data, ...receiver };
      setDataTipping(dataReceiver);
      return;
    }
    setDataTipping(data);
  };

  const { data: dataTransactionsFee } = useInternalTransferSettingsQuery();

  useLayoutEffect(() => {
    if (dataTransactionsFee) {
      const currencyFee = dataTransactionsFee.internalTransferSettings.filter(
        (value) => value.currency.id === selectedCurrency.id,
      );
      setTransactionFee(currencyFee[0]);
    }
  }, [selectedCurrency, dataTransactionsFee]);

  useEffect(() => {
    if (user) {
      setReceiver({
        label: user.nickname,
        year: user.id,
        group: '',
        avatar: user.avatar,
      });
      form.setValue('receiver', user.id);
    }
  }, []);
  useEffect(() => {
    form.setValue('tipAmount', tipAmount);
  }, [tipAmount]);

  useEffect(() => {
    const currenciesWithoutFun = currencies.filter((c) => c.id !== '2');
    if (selectedCurrency.id === '2') {
      setSelectedCurrency(currenciesWithoutFun[2]);
    }
  }, [selectedCurrency.id]);

  const maxBalance: number =
    userWalletsData?.me?.userWallets.find(
      (userWallet) => userWallet.currencyId === selectedCurrency?.id,
    )?.amount || 0;

  const handleQuickSelectAmount = (e) => {
    if (e === 100) setTipAmount(maxBalance);
  };

  const VerifiedAccount = () => (
    <AccountNotVerified
      subTitle={translate(
        'you_should_verify_account_first_before_tipping_your_friends',
      )}
      handleClose={handleCloseWalletDialog}
    />
  );
  if (loadingAccountAccepted) {
    return <LoadingAnimated />;
  }
  return (
    <TipWrapper>
      {isLargeDesktop && (
        <WalletDialogHeader
          handleClose={handleCloseWalletDialog}
          title="Tip"
          hideCloseIcon={isMobile}
        />
      )}
      {!isAccountAccepted ? (
        <VerifiedAccount />
      ) : (
        <Box>
          <Typography display="flex" justifyContent="flex-start" mb={1}>
            {translate('tip_with_any_currency_in_HOST')}
          </Typography>
          <FormProvider
            methods={form}
            onSubmit={form.handleSubmit(onSubmitData)}
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              sx={{ height: '100%' }}
              justifyContent="space-evenly"
            >
              <Box>
                {!receiver ? (
                  <>
                    <Stack mb={1}>
                      <Typography
                        mb={1}
                        variant="subtitle2"
                        display="flex"
                        alignItems="center"
                        gap="10px"
                        // fontSize="16px"
                        // color="white"
                      >
                        {translate('receiver_id')}
                        <Tooltip
                          title={
                            <Box
                              p={1}
                              sx={{
                                maxWidth: 160,
                              }}
                            >
                              <Stack>
                                <Typography color="white" fontSize="12px">
                                  {translate('how_to_see_other_ID')}
                                </Typography>
                                <Typography whiteSpace="normal" fontSize="12px">
                                  {translate(
                                    'get_your_friends_ID_from_their_profiles',
                                  )}
                                </Typography>
                              </Stack>
                            </Box>
                          }
                          placement="bottom"
                        >
                          <InfoIcon sx={{ ml: 0.5 }} />
                        </Tooltip>
                      </Typography>
                    </Stack>

                    <Autocomplete
                      options={allValues}
                      freeSolo
                      fullWidth
                      onChange={(event, value) => {
                        setReceiver(value);
                        form.setValue('receiver', value);
                      }}
                      sx={{
                        backgroundColor: '#161432 !important',
                        borderRadius: '12px !important',
                      }}
                      renderInput={(params) => (
                        <RHFTextField
                          {...params}
                          name="receiver"
                          variant="outlined"
                          placeholder={translate('enter_user_id')}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              backgroundColor: '#161432 !important',
                              borderRadius: '12px !important',
                              '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                padding: '12px 10px !important',
                              },
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 0,
                            paddingLeft: 2,
                          }}
                        >
                          <Box display="flex" alignItems="center" ml={0}>
                            <Avatar
                              alt="Remy Sharp"
                              src="https://i.pravatar.cc/300"
                              sx={{ width: 36, height: 36 }}
                            />
                            <Box px={1}>
                              <Typography
                                fontSize={isDesktop ? '16px' : '14px'}
                                color="white"
                                fontWeight="700"
                              >
                                {option.label}
                              </Typography>
                              <Typography
                                fontSize={isDesktop ? '14px' : '12px'}
                              >
                                {option.year}
                              </Typography>
                            </Box>
                          </Box>
                        </li>
                      )}
                    />
                  </>
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundColor: '#11141E',
                    }}
                    px={2}
                    py={2}
                  >
                    <Box display="flex" alignItems="center">
                      {receiver ? (
                        <Avatar
                          src={receiver.avatar?.square}
                          sx={{ height: 36, width: 36 }}
                        />
                      ) : (
                        <Avatar
                          src={hiddenUser}
                          sx={{ height: 36, width: 36 }}
                        />
                      )}

                      <Box px={2}>
                        <Typography
                          fontSize={isDesktop ? '16px' : '14px'}
                          color="white"
                          fontWeight="700"
                        >
                          {receiver.label}
                        </Typography>
                        <Typography fontSize={isDesktop ? '14px' : '12px'}>
                          {receiver.year}
                        </Typography>
                      </Box>
                    </Box>
                    <Box alignSelf="start">
                      <ChangeButton
                        variant="contained"
                        onClick={() => setReceiver(null)}
                      >
                        <Typography fontSize="14px" fontWeight="500">
                          {translate('change')}
                        </Typography>
                      </ChangeButton>
                    </Box>
                  </Box>
                )}
                {form.formState.errors && (
                  <Typography mt={2} color="red">
                    {form.formState.errors?.receiver?.message}
                  </Typography>
                )}

                <SelectCoinBox>
                  <SelectCurrency
                    selectedCurrency={selectedCurrency}
                    amount={tipAmount}
                    setAmount={(tipAmountAM) => {
                      setTipAmount(tipAmountAM);
                    }}
                    showInputAmount={false}
                    setIsAssetPortfolioOpen={setIsAssetPortfolioOpen}
                  />
                </SelectCoinBox>
                <AmountBox className="amount_box">
                  <InputBase
                    placeholder={Number(0).toFixed(
                      selectedCurrency.decimalDigits,
                    )}
                    onChange={(event) => {
                      if (!Number.isNaN(Number(event.target.value))) {
                        setTipAmount(event.target.value.trim());
                      }
                    }}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    onBlur={() => {
                      if (tipAmount) {
                        if (Number(tipAmount) > amountCurrency) {
                          setTipAmount(amountCurrency);
                        }
                      }
                    }}
                  />
                  <Box display="flex">
                    <div className="coin_image">
                      <img
                        src={currencyImages[selectedCurrency?.shortName]}
                        alt="coin"
                      />
                      <Typography
                        textTransform="uppercase"
                        minWidth="fit-content"
                      >
                        {selectedCurrency?.shortName}
                      </Typography>
                    </div>
                    <QuickWithdrawAmountBox>
                      {quickSelectOption.map((item) => (
                        <QuickSelectWithdrawItem
                          key={item.value}
                          onClick={() => handleQuickSelectAmount(item.value)}
                          $isActive={!!item.value}
                        >
                          <Typography>{translate(item.label)}</Typography>
                        </QuickSelectWithdrawItem>
                      ))}
                    </QuickWithdrawAmountBox>
                  </Box>
                </AmountBox>
                {form.formState.errors && (
                  <Typography mt={2} color="red">
                    {form.formState.errors?.tipAmount?.message}
                  </Typography>
                )}
                {transactionFee?.transferFeeRate > 0 && (
                  <Box mt={1} display="flex" justifyContent="space-between">
                    <Typography>Transfer Fee Rate</Typography>
                    <Typography textTransform="uppercase">
                      {transactionFee.transferFeeRate * 100} %
                    </Typography>
                  </Box>
                )}
                {transactionFee?.transferFeeAmount > 0 && (
                  <Box mt={1} display="flex" justifyContent="space-between">
                    <Typography>Transfer Fee Amount</Typography>
                    <Typography textTransform="uppercase">
                      {transactionFee?.transferFeeAmount}{' '}
                      {selectedCurrency?.shortName}
                    </Typography>
                  </Box>
                )}
                <Box
                  mt={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">
                    {translate('message')}
                  </Typography>
                  <Typography>{form.watch('message').length}/150</Typography>
                </Box>
                <RHFTextField
                  multiline
                  fullWidth
                  name="message"
                  //   error
                  placeholder={translate(
                    'give_your_friends_some_messages_here',
                  )}
                  inputProps={{
                    sx: {
                      background: '#11141E',
                    },
                    maxLength: 150,
                  }}
                  FormHelperTextProps={{
                    style: {
                      color: '#FF5630',
                      fontSize: isDesktop ? '14px' : '12px',
                    },
                  }}
                  sx={{
                    mt: 1,
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#11141E !important',
                    },
                  }}
                />
                {form.formState.errors && (
                  <Typography mt={2} color="red">
                    {form.formState.errors?.message?.message}
                  </Typography>
                )}
              </Box>
              <Box>
                <ContinueButton
                  variant="contained"
                  color="secondary"
                  disabled={!form.formState.errors}
                  type="submit"
                >
                  {translate('tip')}
                </ContinueButton>
              </Box>
            </Box>
          </FormProvider>
        </Box>
      )}
      {isTipInformationOpen && (
        <TipInformation
          // isOpen={}
          selectedCurrency={selectedCurrency}
          handleClose={() => {
            setIsTipInformationOpen(false);
          }}
          handleBack={() => setIsTipInformationOpen(false)}
          dataTipping={dataTipping}
          transactionsFee={transactionFee}
          handleCloseWalletDialog={handleCloseWalletDialog}
        />
      )}

      {isAssetPortfolioOpen && (
        <AssetsPortfolioDialog
          isOpen={isAssetPortfolioOpen}
          currencies={tipCurrencies}
          selectedCurrency={selectedCurrency}
          handleSelectCurrency={setSelectedCurrency}
          handleClose={() => {
            // handleCloseWalletDialog();
            setIsAssetPortfolioOpen(false);
          }}
          handleBack={() => setIsAssetPortfolioOpen(false)}
        />
      )}
    </TipWrapper>
  );
}
