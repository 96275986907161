import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { backgroundBigwin } from 'assets/images/Chat';
import currencyImages from 'assets/images/coins';
import UserProfileDialog from 'components/UserProfileDialog';
import BetDetail from 'components/_Games/BetDetail';
import { Currency, useBetLazyQuery, UserInfoQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import numeral from 'numeral';
import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ChatWrapper } from './styles';

// -----------------------------------------------------------------------

type ItemMessage = {
  id: string;
  message: string;
  sender: {
    id: string;
    nickname: string;
    avatar: {
      uri: string;
      square: string;
    };
    vipLevel: number;
    commands: any;
  };
};

export interface ItemMessageProps {
  message: ItemMessage;
  myInfo: UserInfoQuery['me']['user'];
  userRecent: Array<any>;
}
//  [atlas, earth, kunrluv, 3 acc ben slimy]
export const idMods = [
  '105839029178331136',
  '105864611274508288',
  '105869140732758016',
  '132050371930810368',
  '133125259009517568',
  '132897150825981952',
  '157531801558345728',
];

const ItemMessageBase = (props: Partial<ItemMessageProps>) => {
  const { sender, message } = props.message;
  const theme = useTheme();
  const { userRecent } = props;
  const { myInfo } = props;
  const [isUserProfileDialogOpen, setIsUserProfileDialogOpen] = useState(false);
  const [idUserChat, setIdUserChat] = useState(null);
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const [selectedBetId, setSelectedBetId] = useState(null);
  const [messageCommands, setMessageCommands] = useState(null);
  const [getDetailBet] = useBetLazyQuery({});

  const detectCommandChat = async (message: string) => {
    let commands: any = false;
    let messageFilter = '';

    switch (true) {
      case message.includes('share:'):
        if (message.indexOf('share:') !== -1) {
          const result = message.slice(message.indexOf('share:'));

          if (result.match(/\d+/g).toString().length !== 18) {
            break;
          }

          try {
            const { data } = await getDetailBet({
              variables: {
                betId: result.match(/\d+/g).toString(),
              },
            });

            const match = message.match(/(.*)\bshare:(\d+)\b(.*)/);
            commands = {
              id: data.bet.id,
              square: data.bet.game.gameBase.thumbnail.square,
              name: data.bet.game.name,
              betAmount: data?.bet?.betAmount,
              currency: data?.bet?.currency,
              equivalentUsdRate: data?.bet?.equivalentUsdRate,
              multiplier: +numeral(data?.bet?.payoutRate).format('0.00'),
              payoutAmount: data.bet.payoutAmount,
              textBeforeShare: match ? match[1] : '',
              textAfterShare: match ? match[3] : '',
            };

            messageFilter = message.replace(
              `share:${result.match(/\d+/g).toString()}`,
              '',
            );
          } catch (error) {
            // Handle the error, log it, or throw a new error if necessary
            messageFilter = message;
          }
        }
        break;

      default:
        messageFilter = message;
        commands = false;
        break;
    }

    return { messageFilter, commands };
  };

  useEffect(() => {
    const processMessage = async () => {
      try {
        const { messageFilter, commands } = await detectCommandChat(message);
        let result;
        if (props.message.sender.id === myInfo?.id) {
          result = {
            color: '#FFAB00',
            display: 'Me',
            message: messageFilter,
            commands,
          };
        } else if (idMods.includes(props.message.sender.id)) {
          result = {
            color: '#FFE500',
            subDisplay: 'SUPPORT',
            display: `${props.message.sender.nickname}`,
            message: messageFilter,
            commands,
          };
        } else {
          result = {
            color: '',
            display: `${props.message.sender.nickname}`,
            message: messageFilter,
            commands,
          };
        }
        setMessageCommands(result);
      } catch {
        /* empty */
      }
    };

    processMessage();
  }, [props.message]);

  const HighlightedText = ({ text }) => {
    const regex = /(@\w+)/g;
    const parts = text.split(regex);

    return (
      <Typography component="span" className="chat-text">
        {parts.map((part: string) =>
          regex.test(part) ? (
            <Typography
              component="span"
              // key={`highlighted-${index}`}
              borderRadius={4}
              px={1}
              color={
                part.replace('@', '') === myInfo?.nickname
                  ? '#00B8D9'
                  : '#858997'
              }
              sx={{
                background: 'rgba(145, 158, 171, 0.08)',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                if (
                  userRecent.filter((e) => e.nickname === part.replace('@', ''))
                    .length > 0
                ) {
                  setIsUserProfileDialogOpen(true);
                  setIdUserChat(
                    userRecent.filter(
                      (e) => e.nickname === part.replace('@', ''),
                    )[0],
                  );
                }
              }}
            >
              {part}
            </Typography>
          ) : (
            <Box
              component="span"
              // key={`highlighted-${index}`}
              // color={idMods.includes(sender.id) ? '#FFAB00' : 'white'}
              color="white"
            >
              {part}
            </Box>
          ),
        )}
      </Typography>
    );
  };

  function formatNumberFun(number) {
    if (number >= 1000000) {
      const millionValue = (number / 1000000).toFixed(2);
      return `${parseFloat(millionValue).toLocaleString()}M`;
    }
    if (number >= 100000) {
      const thousandValue = (number / 1000).toFixed(2);
      return `${parseFloat(thousandValue).toLocaleString()}k`;
    }
    return `${parseFloat(number).toLocaleString()}`;
  }

  function formatNumberAbbreviated(number) {
    // if (number.length > 5) {

    const abbreviations = ['', 'k', 'M', 'B', 'T'];
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
    const normalizedTier = Math.min(tier, abbreviations.length - 1);
    const suffix = abbreviations[normalizedTier];
    const formattedNumber = (number / 10 ** (normalizedTier * 3)).toFixed(2);
    return `$${formattedNumber || number}${suffix || ''}`;
    // }
    // return `$${number}`;
  }
  const handleShowCurrency = (param) => {
    if (param.length > 8) {
      const numberValue = parseFloat(param.replace(/[^\d.]/g, ''));
      return formatNumberAbbreviated(numberValue);
    }
    return param;
  };
  const handleShowByCurrency = () => {
    if (messageCommands.commands.payoutAmount) {
      return handleShowCurrency(
        getCurrencyAmount(
          messageCommands.commands.payoutAmount,
          {
            ...messageCommands.commands.currency,
            equivalentUsdRate: messageCommands.commands.equivalentUsdRate,
          } as Currency,
          { onlyUsd: true },
        ),
      );
    }
    return handleShowCurrency(
      getCurrencyAmount(
        messageCommands.commands.betAmount,
        {
          ...messageCommands.commands.currency,
          equivalentUsdRate: messageCommands.commands.equivalentUsdRate,
        } as Currency,
        { onlyUsd: true },
      ),
    );
  };

  return (
    <ChatWrapper>
      {messageCommands && (
        <Box className="chat-content">
          {/* {sender.avatar.square && (
                <Box
                  component="img"
                  className="chat-avatar"
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    verticalAlign: 'middle',
                  }}
                  mx={0.5}
                  sx={{
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                  alt={messageCommands.display}
                  src={sender.avatar.square}
                  display="inline-flex"
                  onClick={() => {
                    setIsUserProfileDialogOpen(true);
                    setIdUserChat(null);
                  }}
                />
              )} */}

          <Box className="chat-user-info">
            <Typography
              component="span"
              className="chat-username"
              onClick={() => {
                setIsUserProfileDialogOpen(true);
                setIdUserChat(null);
              }}
              color={messageCommands.color}
            >
              {messageCommands.display}:
            </Typography>

            {messageCommands.subDisplay && (
              <Typography
                component="span"
                className="chat-support"
                onClick={() => {
                  setIsUserProfileDialogOpen(true);
                  setIdUserChat(null);
                }}
              >
                {messageCommands.subDisplay}
              </Typography>
            )}

            {/* chat user */}
            {!messageCommands.commands && (
              <HighlightedText text={messageCommands.message} />
            )}

            {/* Chat Support */}
            {messageCommands.commands.textBeforeShare && (
              <HighlightedText
                text={messageCommands.commands.textBeforeShare}
              />
            )}
          </Box>

          {/* Share Big win */}

          {messageCommands.commands && (
            <Box
              className="chat-bigwin"
              sx={{
                backgroundImage: `url(${backgroundBigwin})`,
              }}
            >
              <img
                className="chat-bigwin-img"
                src={messageCommands.commands.square}
                alt={messageCommands.commands.name}
              />

              {/* <Typography fontSize="20px" fontWeight={700} color="white">
                    {messageCommands.commands.name}
                  </Typography> */}

              <Box
                className="chat-bigwin-data"
                onClick={() => setSelectedBetId(messageCommands.commands.id)}
              >
                <Box className="chat-bigwin-number">
                  {messageCommands.commands.currency.equivalentUsdRate ===
                    0 && (
                    <img
                      alt={messageCommands.commands.currency.shortName}
                      src={
                        currencyImages[
                          messageCommands.commands.currency.shortName
                        ]
                      }
                    />
                  )}

                  <Typography component="span">
                    {messageCommands.commands.currency.equivalentUsdRate === 0
                      ? formatNumberFun(messageCommands.commands.betAmount)
                      : handleShowCurrency(
                          getCurrencyAmount(
                            messageCommands.commands.betAmount || 0,
                            {
                              ...messageCommands.commands.currency,
                              equivalentUsdRate:
                                messageCommands.commands.equivalentUsdRate,
                            } as Currency,
                            { onlyUsd: true },
                          ),
                        )}
                  </Typography>

                  <Typography
                    component="span"
                    sx={{
                      background: '#7660FE',
                      fontWeight: 700,
                      borderRadius: theme.spacing(1.5),
                      padding: theme.spacing(0, 0.5),
                    }}
                  >
                    x{messageCommands.commands.multiplier}
                  </Typography>

                  <Typography component="span">=</Typography>

                  {messageCommands.commands.multiplier > 0 ? (
                    <Typography component="span" className="chat-bigwin-payout">
                      {' '}
                      {messageCommands.commands.currency.equivalentUsdRate === 0
                        ? handleShowByCurrency().replace('$', '')
                        : handleShowByCurrency()}
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      className="chat-bigwin-payout"
                      color="#FF5C00 !important"
                    >
                      0
                    </Typography>
                  )}
                </Box>

                <ArrowDown />
              </Box>
            </Box>
          )}

          {messageCommands.commands.textAfterShare && (
            <HighlightedText text={messageCommands.commands.textAfterShare} />
          )}
        </Box>
      )}
      {sender && (
        <UserProfileDialog
          open={isUserProfileDialogOpen}
          onClose={() => setIsUserProfileDialogOpen(false)}
          user={idUserChat !== null ? idUserChat : sender}
        />
      )}
      {selectedBetId && (
        <BetDetail
          open={Boolean(selectedBetId)}
          onClose={() => setSelectedBetId(null)}
          betId={selectedBetId}
        />
      )}
    </ChatWrapper>
  );
};

export default ItemMessageBase;
