import { ReactComponent as MiniDiceGold } from 'assets/icons/gold.svg';
import { DialogAnimate } from 'components/animate';
import { DialogWrapper } from 'components/Dialog/styles';
import MiniDiceTopProfitDialogHeader from 'components/_Games/_GamesMiniDice/top-profit/components/top-profit-dialog-header';
import MiniDiceTopProfitTable from 'components/_Games/_GamesMiniDice/top-profit/components/top-profit-table';
import { MiniDiceTopProfitTabsStyled } from 'components/_Games/_GamesMiniDice/top-profit/styles';
import { useLocales } from 'locales';
import { memo, useState } from 'react';

import { Tab, useTheme } from '@mui/material';

interface ITopProfitDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

type TabValue = 'top-profit' | 'lucky-win';

const TopProfitDialog = ({ open, onClose }: ITopProfitDialogProps) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState<TabValue>('top-profit');
  const tabs: TabsType<TabValue> = [
    {
      label: translate('top_profit'),
      value: 'top-profit',
    },
    {
      label: translate('lucky_win'),
      value: 'lucky-win',
    },
  ];

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        width: 480,
        height: 590,
        maxWidth: 'unset',
        borderRadius: '12px !important',
        padding: (theme) => theme.spacing(2, 1.5),
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'unset',
          minHeight: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: (theme) => theme.shape.borderRadius,
        },
      }}
    >
      <MiniDiceTopProfitDialogHeader
        selectedDate={selectedDate}
        onChangeDate={(date: Date) => setSelectedDate(date)}
        onClose={() => onClose()}
      />
      <MiniDiceTopProfitTabsStyled
        scrollButtons={false}
        disabled
        value={selectedTab}
        onChange={(_e, selectedTab) => setSelectedTab(selectedTab)}
      >
        {tabs.map((tab) => (
          <Tab
            className={`tab-item ${tab.value}`}
            icon={<MiniDiceGold />}
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </MiniDiceTopProfitTabsStyled>
      <DialogWrapper sx={{ p: { xs: 0 }, overflowY: 'hidden' }}>
        <MiniDiceTopProfitTable selectedDate={selectedDate} />
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default memo(TopProfitDialog);
