export const BLOG_PATHS = {
  BLOG_HOME: '/blog',
  CATEGORY_HOW_TO_GUIDES: '/blog/category/how-to-guides',
  CATEGORY_PROMOTIONS: '/blog/category/promotions',
  CATEGORY_GAMES: '/blog/category/games',
  CATEGORY_CRYPTO: '/blog/category/crypto',
  CATEGORY_COCO_NEWS: '/blog/category/coco-news',
  CATEGORY_FAQ: '/blog/category/faq',
  CATEGORY_DETAILS: '/blog/category/details',
};
