import { ErrorMessage } from '@hookform/error-message';
import { countries } from 'assets/data/countries';
import {
  RHFAutocomplete,
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from 'components/hook-form';
import ValidationMessage from 'components/ValidationMessage';
import { GENDER } from 'constants/index';
import { useLocales } from 'locales';

import { Box, MenuItem, Stack, Typography } from '@mui/material';

import { GeneralInfoUploadingWrapper } from './styles';

// =================================================================

export default function GeneralInfoUploading({ form }) {
  const { translate } = useLocales();

  const {
    formState: { errors },
  } = form;

  const genderOptions = [
    {
      label: 'male',
      value: GENDER.MALE,
    },
    {
      label: 'female',
      value: GENDER.FEMALE,
    },
    {
      label: 'unspecified',
      value: GENDER.UNSPECIFIED,
    },
  ];

  const countryOptions = countries
    .map((country) => ({
      label: country.label,
      value: country.code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <GeneralInfoUploadingWrapper className="general-info-wrapper">
      <Stack gap={{ sm: 3, xs: 5 }}>
        <Stack direction="row" gap={{ md: 3, xs: 1.5 }}>
          <Box>
            <Typography className="label" gutterBottom>
              {translate('first_name')}
              <Typography component="span" color="support.red">
                &nbsp;*
              </Typography>
            </Typography>
            <RHFTextField
              className="rhf-textfield"
              placeholder={translate('enter_your_first_name')}
              name="firstName"
            />
            <ErrorMessage
              name="firstName"
              errors={errors}
              render={({ message }) => (
                <ValidationMessage mt={1} ml={0} message={message} />
              )}
            />
          </Box>
          <Box>
            <Typography className="label" gutterBottom>
              {translate('last_name')}
              <Typography component="span" color="support.red">
                &nbsp;*
              </Typography>
            </Typography>
            <RHFTextField
              className="rhf-textfield"
              name="lastName"
              placeholder={translate('enter_your_last_name')}
            />
            <ErrorMessage
              name="lastName"
              errors={errors}
              render={({ message }) => (
                <ValidationMessage mt={1} ml={0} message={message} />
              )}
            />
          </Box>
        </Stack>
        <Stack direction="row" gap={{ md: 3, xs: 1.5 }}>
          <Box>
            <Typography className="label" gutterBottom>
              {translate('gender')}
              <Typography component="span" color="support.red">
                &nbsp;*
              </Typography>
            </Typography>
            <RHFSelect name="gender" placeholder={translate('select_gender')}>
              {genderOptions.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  {translate(option.label)}
                </MenuItem>
              ))}
            </RHFSelect>
            <ErrorMessage
              name="gender"
              errors={errors}
              render={({ message }) => (
                <ValidationMessage mt={1} ml={0} message={message} />
              )}
            />
          </Box>
          <Box>
            <Typography className="label" gutterBottom>
              {translate('date_of_birth')}
              <Typography component="span" color="support.red">
                &nbsp;*
              </Typography>
            </Typography>
            <RHFDatePicker name="dateOfBirth" disableFuture />
            <ErrorMessage
              name="dateOfBirth"
              errors={errors}
              render={({ message }) => (
                <ValidationMessage mt={1} ml={0} message={message} />
              )}
            />
          </Box>
        </Stack>
        <Box>
          <Typography className="label" gutterBottom>
            {translate('address')}
            <Typography component="span" color="support.red">
              &nbsp;*
            </Typography>
          </Typography>
          <RHFTextField className="rhf-textfield" name="address" />
          <ErrorMessage
            name="address"
            errors={errors}
            render={({ message }) => (
              <ValidationMessage mt={1} ml={0} message={message} />
            )}
          />
        </Box>
        <Stack direction="row" gap={{ md: 3, xs: 1.5 }}>
          <Box>
            <Typography className="label" gutterBottom>
              {translate('city')}
              <Typography component="span" color="support.red">
                &nbsp;*
              </Typography>
            </Typography>
            <RHFTextField className="rhf-textfield" name="city" />
            <ErrorMessage
              name="city"
              errors={errors}
              render={({ message }) => (
                <ValidationMessage mt={1} ml={0} message={message} />
              )}
            />
          </Box>
          <Box>
            <Typography className="label" gutterBottom>
              {translate('postal_code')}
              <Typography component="span" color="support.red">
                &nbsp;*
              </Typography>
            </Typography>
            <RHFTextField className="rhf-textfield" name="postalCode" />
            <ErrorMessage
              name="postalCode"
              errors={errors}
              render={({ message }) => (
                <ValidationMessage mt={1} ml={0} message={message} />
              )}
            />
          </Box>
        </Stack>
        <Box>
          <Typography className="label" gutterBottom>
            {translate('country')}
            <Typography component="span" color="support.red">
              &nbsp;*
            </Typography>
          </Typography>
          <RHFAutocomplete options={countryOptions} name="countryCode" />
          <ErrorMessage
            name="countryCode"
            errors={errors}
            render={({ message }) => (
              <ValidationMessage mt={1} ml={0} message={message} />
            )}
          />
        </Box>
      </Stack>
    </GeneralInfoUploadingWrapper>
  );
}
