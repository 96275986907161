import { ACCESS_TOKEN, CURRENCY } from 'constants/localStorage';
import { Currency } from 'graph';

import axios from '../utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  if (!base64Url) return null;
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  if (!decoded) return false;

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  const currentTime = Date.now();
  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;
  setTimeout(() => {
    localStorage.removeItem(ACCESS_TOKEN);
    window.location.href = '/';
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const payLoad = jwtDecode(accessToken);
    if (payLoad) tokenExpired(payLoad.exp);
  } else {
    localStorage.removeItem(ACCESS_TOKEN);
    delete axios.defaults.headers.common.Authorization;
  }
};

export const setStorageCurrency = (currency: string | Currency) => {
  if (!currency) {
    return;
  }

  if (typeof currency === 'string') {
    localStorage.setItem(CURRENCY, currency);
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: CURRENCY,
        newValue: currency,
        storageArea: localStorage,
      }),
    );
    return;
  }

  localStorage.setItem(CURRENCY, JSON.stringify(currency));
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: CURRENCY,
      newValue: JSON.stringify(currency),
      storageArea: localStorage,
    }),
  );
};

export const getLocalStorage = (key: string) => {
  if (key) {
    return localStorage.getItem(key);
  }
  return null;
};

export const clearLocalStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem('my_address');
};
