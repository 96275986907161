import { Button, IconButton, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const SwapWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  position: 'relative',
  'p.label': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  '.exchange_box': {
    marginTop: theme.spacing(1),
    '.exchange_rate, .route': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      'p.label': {
        marginTop: theme.spacing(1.5),
        marginBottom: 0,
        color: theme.palette.text.primary,
      },
    },
    '.exchange_rate': {
      justifyContent: 'flex-end',
    },
  },
  '.MuiInputBase-root': {
    backgroundColor: 'transparent',
  },
}));

export const ConfirmSwapButton = styled(Button)(({ theme }) => ({
  width: '100%',
  minHeight: 48,
  borderRadius: theme.shape.borderRadius,
  marginTop: '12px',
  backgroundImage:
    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  color: '#2E2C5E',
  '&.Mui-disabled': {
    opacity: 0.7,
  },
  // position: 'absolute',
  // bottom: 0,
  ':hover': {
    backgroundImage:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.7,
  },
}));

export const SwitchButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: 0,
  backgroundColor: theme.palette.background.secondary,
  marginTop: theme.spacing(1),
  svg: {
    path: {
      fill: theme.palette.secondary.light,
    },
  },
}));
