import { SvgIcon } from '@mui/material';

export function IconSuccess(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.66601 11.1118C3.25181 11.113 2.84639 11.2314 2.49667 11.4533C2.14695 11.6753 1.86722 11.9917 1.68984 12.366C1.51246 12.7403 1.44468 13.1572 1.49434 13.5684C1.544 13.9796 1.70907 14.3684 1.97046 14.6897L7.54261 21.5156C7.74129 21.7623 7.99596 21.958 8.28547 22.0865C8.57498 22.215 8.89097 22.2726 9.2072 22.2545C9.88354 22.2182 10.4942 21.8564 10.8835 21.2615L22.4582 2.62031C22.4601 2.61722 22.4621 2.61413 22.4642 2.61108C22.5728 2.44433 22.5375 2.11387 22.3134 1.90627C22.2518 1.84926 22.1792 1.80546 22.1 1.77757C22.0209 1.74967 21.9369 1.73828 21.8532 1.74409C21.7694 1.7499 21.6878 1.77278 21.6133 1.81133C21.5387 1.84988 21.4729 1.90329 21.4197 1.96825C21.4156 1.97336 21.4113 1.97839 21.4069 1.98334L9.73358 15.1724C9.68917 15.2226 9.63522 15.2635 9.57487 15.2927C9.51453 15.3218 9.44899 15.3387 9.38207 15.3423C9.31514 15.346 9.24817 15.3362 9.18503 15.3138C9.12189 15.2913 9.06385 15.2565 9.01428 15.2114L5.14012 11.6859C4.73776 11.317 4.21186 11.1122 3.66601 11.1118Z"
        fill="#53BA0C"
      />
    </SvgIcon>
  );
}
