import { useReactiveVar } from '@apollo/client';
import { ReactComponent as RankFirst } from 'assets/icons/rank-first.svg';
import { ReactComponent as RankSecond } from 'assets/icons/rank-second.svg';
import { ReactComponent as RankThird } from 'assets/icons/rank-third.svg';
import StickyHeaderTable from 'common/Table/StickyHeaderTable';
import LoadingAnimated from 'components/LoadingAnimated';
import PlayerBox from 'components/PlayerBox';
import { useThreeDiceTopDailyQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { memo } from 'react';
import { storageCurrencyVar } from 'store';

import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

interface IMiniDiceTopProfitTableProps {
  selectedDate: Date;
}

const MiniDiceTopProfitTable = ({
  selectedDate,
}: IMiniDiceTopProfitTableProps) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const { data: threeDiceTopDailyData, loading } = useThreeDiceTopDailyQuery({
    variables: {
      date: selectedDate,
      currencyId: storageCurrency?.id,
    },
    fetchPolicy: 'network-only',
  });

  const getRankEl = (rank: number) => {
    if (rank === 1) {
      return <RankFirst />;
    }
    if (rank === 2) {
      return <RankSecond />;
    }
    if (rank === 3) return <RankThird />;
    return rank;
  };

  if (loading)
    return (
      <Box
        position="absolute"
        width="100%"
        height="100%"
        sx={{ top: 0, left: '50%', transform: 'translate(-50%, -5%)' }}
      >
        <LoadingAnimated />
      </Box>
    );

  return (
    <StickyHeaderTable maxRows={10}>
      <TableHead>
        <TableRow>
          <TableCell className="top-rank" align="center">
            {translate('top')}
          </TableCell>
          <TableCell className="player" align="center">
            {translate('player')}
          </TableCell>
          <TableCell className="profit" align="center">
            {translate('profit')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {threeDiceTopDailyData?.threeDiceTopDaily.map((row, index) => (
          <TableRow key={row?.user?.id}>
            <TableCell className="top-rank" align="center">
              <Stack justifyContent="center" alignItems="center">
                {getRankEl(index + 1)}
              </Stack>
            </TableCell>
            <TableCell className="player" align="center">
              <PlayerBox user={row.user as any} />
            </TableCell>
            <TableCell className="profit" align="left">
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography>
                  {`$${getCurrencyAmount(row.winValueToBaseCurrency, null, {
                    onlyUsd: true,
                  })}`}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        ))}
        {threeDiceTopDailyData?.threeDiceTopDaily?.length === 0 && (
          <TableRow>
            <TableCell colSpan={3}>
              <Typography textAlign="center" color="text.primary">
                {translate('empty_data')}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </StickyHeaderTable>
  );
};

export default memo(MiniDiceTopProfitTable);
