import React from 'react';

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

const PopupBonus: React.FC<Partial<Props>> = () => <div />;

export { PopupBonus };
