import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const NavHorizontalWrapper = styled(Box)(({ theme }) => ({
  zIndex: 999,
  display: 'flex',
  width: '100%',
  padding: '0.75rem 1rem',
  justifyContent: 'space-between',
  position: 'fixed',
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: theme.palette.background.secondary,
}));
