import { ReactComponent as Arrow } from 'assets/icons/arrow-down.svg';
import { useLocales } from 'locales';
import { useState } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, CardMedia, Divider, Typography } from '@mui/material';

import { BoxDescription, ButtonAttachment } from './styles';

// ------------------------------------------------------

export interface ItemPlatformProps {
  image: string;
  title: string;
  time: string;
  seen: boolean;
  description: string;
  buttonTitle: string;
  functionAddOn: () => void;
}

const ItemPlatform = (props: Partial<ItemPlatformProps>) => {
  const { image, title, time, description, buttonTitle, functionAddOn } = props;
  const [showAll, setShowAll] = useState(false);
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        background: '#2F2B60',
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      borderRadius={2}
      p={1.5}
      width="100%"
    >
      {image && (
        <Box>
          <CardMedia
            component="img"
            alt="green iguana"
            height="170"
            image={image}
            sx={{ borderRadius: 2 }}
          />
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        maxWidth="100%"
        justifyContent="space-between"
      >
        <Typography
          color="white"
          fontSize="16px"
          fontWeight="500"
          textOverflow="initial"
          overflow="initial"
          whiteSpace="initial"
        >
          {translate(title)}
        </Typography>
        {/* {seen && (
          <Chip
            sx={{ height: '15px !important', width: 15, alignSelf: 'center' }}
            color="primary"
          />
        )} */}
      </Box>

      <Box sx={{ height: 'auto !important', mb: 1 }}>
        <Typography
          fontSize="12px"
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          {new Date(time).toLocaleString('en-US')}
        </Typography>
      </Box>

      <Box sx={{ height: 'auto !important' }}>
        <BoxDescription
          open={!showAll}
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}
          lineHeight="inherit"
          fontSize="12px"
          whiteSpace="pre-line"
        >
          {JSON.parse(description)}
        </BoxDescription>
      </Box>

      {description.length > 80 && (
        <Box display="flex" justifyContent="end">
          <Button
            endIcon={
              !showAll ? (
                <Arrow
                  width="24px"
                  height="24px"
                  style={{ transform: 'rotate(-90deg)', transition: '275ms' }}
                />
              ) : (
                <Arrow
                  width="24px"
                  height="24px"
                  style={{ transform: 'rotate(0deg)', transition: '275ms' }}
                />
              )
            }
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontSize: '12px !important',
              fontWeight: 400,
            }}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? translate('hide') : translate('show_all')}
          </Button>
        </Box>
      )}

      {buttonTitle && (
        <>
          <Divider />
          <Box display="flex" px={1}>
            <ButtonAttachment
              endIcon={<KeyboardArrowRightIcon />}
              sx={{ color: '#53BA0C' }}
              onClick={() => functionAddOn()}
            >
              <Typography fontSize="14px" fontWeight="500">
                {translate(buttonTitle)}
              </Typography>
            </ButtonAttachment>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ItemPlatform;
