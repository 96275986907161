import { TabsStyled } from 'common/Tabs';
import useResponsive from 'hooks/useResponsive';

import { Tab } from '@mui/material';

interface IVipClubDialogTabsProps<TabValue> {
  selectedTab: TabValue;
  onChangeTab: (tab: TabValue) => void;
  tabs: TabsType<TabValue>;
}

export const VipClubDialogTabs = <TabValue extends string>({
  tabs,
  selectedTab,
  onChangeTab,
}: IVipClubDialogTabsProps<TabValue>) => {
  const isDesktop = useResponsive('up', 'sm');

  return (
    <TabsStyled
      sx={{
        alignSelf: 'center',
        maxWidth: { sm: 'calc(100% - 48px)', xs: 'calc(100% - 16px)' },
        '&&& button': {
          px: 2.5,
        },
      }}
      scrollButtons={false}
      value={selectedTab}
      onChange={(_e, selectedTab) => onChangeTab(selectedTab)}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={(isDesktop || selectedTab === tab.value) && tab.label}
          value={tab.value}
          icon={tab.icon}
        />
      ))}
    </TabsStyled>
  );
};
