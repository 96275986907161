import Chart from 'components/chart';

import { styled } from '@mui/material';

export const StyledMiniDiceTrendsChart = styled(Chart)(() => ({
  minHeight: 'unset !important',
  '.apexcharts-gridline': {
    strokeWidth: 1,
    stroke: '#4E4D7C',
  },
  '.apexcharts-data-labels': {
    rect: {
      width: 16,
      height: 15,
      transform: 'translateX(-0.5px)',
    },
    text: {
      transform: 'translate(-1.5px, 0px)',
    },
  },
}));
