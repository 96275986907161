export function IconDollar({ w = 24, h = 24 }) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#0052B4" />
      <path d="M12.5359 5H11.2656V19.6999H12.5359V5Z" fill="white" />
      <path
        d="M11.9372 18.8294H11.6145C8.82816 18.6603 8.09573 16.5757 8.09573 15.5001H9.36596C9.36596 15.6999 9.45816 17.4311 11.6913 17.5489C12.1705 17.6171 12.6592 17.5585 13.1086 17.3787C13.558 17.1989 13.9524 16.9044 14.2523 16.5245C14.4298 16.2621 14.531 15.9556 14.5446 15.639C14.5582 15.3225 14.4836 15.0084 14.3291 14.7318C13.9981 14.2158 13.5122 13.8178 12.9411 13.5948L12.813 13.523L11.6145 12.9135L10.3955 12.2784L10.2674 12.2118C9.46129 11.8688 8.78633 11.2764 8.34158 10.5216C8.09201 10.0486 7.97544 9.51683 8.00431 8.98285C8.03317 8.44886 8.20638 7.93272 8.50548 7.48942C8.92313 6.92 9.48337 6.47062 10.1299 6.1865C10.7763 5.90237 11.4862 5.79352 12.1881 5.87089C14.9796 6.03991 15.7069 8.11941 15.7069 9.20014H14.4367C14.4367 9.01063 14.3496 7.27942 12.1113 7.15137C11.628 7.08157 11.1349 7.14047 10.6817 7.32212C10.2285 7.50377 9.83115 7.80175 9.52987 8.186C9.35826 8.44502 9.26044 8.74589 9.2469 9.05631C9.23337 9.36672 9.30463 9.67496 9.45304 9.94794C9.7851 10.4611 10.2683 10.8584 10.836 11.085L10.9691 11.1567L12.1933 11.7867L13.4072 12.4218L13.5301 12.4884C14.3397 12.828 15.0173 13.4211 15.4611 14.1787C15.7129 14.6502 15.832 15.1813 15.8059 15.7153C15.7797 16.2492 15.6092 16.7661 15.3125 17.2108C14.929 17.7401 14.4194 18.1653 13.83 18.448C13.2406 18.7306 12.59 18.8617 11.9372 18.8294Z"
        fill="white"
      />
    </svg>
  );
}
