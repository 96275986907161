import { SvgIcon } from '@mui/material';

export function IconCoinQuanlity(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2566_44588)">
        <path
          d="M4.7041 17.7604C4.7041 16.2333 4.7041 14.7062 4.7041 13.1791C4.7041 12.5387 5.19671 11.9722 5.81248 11.7998C6.94548 11.4796 8.05386 11.5535 9.11297 12.0954C9.43317 12.2678 9.75336 12.4156 10.0982 12.5141C10.3691 12.588 10.6401 12.6372 10.911 12.6372C11.379 12.6619 11.847 12.6372 12.3149 12.6372C12.6598 12.6372 12.8568 12.8343 12.8322 13.1791C12.8075 13.8441 12.4627 14.3368 11.8716 14.4599C11.7731 14.4845 11.6499 14.4845 11.5514 14.4845C10.6647 14.4845 9.75336 14.4845 8.86666 14.4845C8.74351 14.4845 8.59573 14.5338 8.47257 14.6077C8.30016 14.7062 8.27553 14.9033 8.32479 15.0757C8.37405 15.2727 8.52184 15.3712 8.71888 15.3959C8.84203 15.3959 8.98982 15.3959 9.11297 15.3959C9.90115 15.3959 10.714 15.3959 11.5021 15.3959C11.9209 15.3959 12.241 15.2481 12.5366 14.9771C13.177 14.3121 13.842 13.6717 14.4824 13.0313C15.3445 12.1939 16.7238 12.1939 17.5859 13.0067C17.8568 13.253 17.8568 13.5239 17.6105 13.7949C16.5021 14.9033 15.3938 16.0116 14.2854 17.12C13.9652 17.4402 13.5711 17.6619 13.1031 17.7358C13.0785 17.7358 13.0539 17.7604 13.0292 17.7604C10.4923 18.45 7.95533 18.6225 4.7041 17.7604Z"
          fill={props.color ? props.color : '#9998d4'}
        />
        <path
          d="M2.56135 17.7594C2.29042 17.6363 2.19189 17.4393 2.19189 17.1437C2.19189 15.986 2.19189 14.853 2.19189 13.6954C2.19189 13.3013 2.36431 13.1289 2.7584 13.1289C3.07859 13.1289 3.39879 13.1289 3.74362 13.1289C3.74362 14.6806 3.74362 16.2324 3.74362 17.7594C3.34953 17.7594 2.95544 17.7594 2.56135 17.7594Z"
          fill={props.color ? props.color : '#9998d4'}
        />
        <path
          d="M11.3297 1.67578C8.64497 1.67578 6.42822 3.89253 6.42822 6.60189C6.42822 9.31125 8.62034 11.528 11.3297 11.528C14.0391 11.528 16.2312 9.31125 16.2312 6.60189C16.2065 3.8679 14.0144 1.67578 11.3297 1.67578ZM11.8962 8.99105C11.8469 9.26199 11.6006 9.45903 11.3297 9.45903C11.0588 9.45903 10.8125 9.26199 10.7632 8.99105C10.3937 8.8679 10.0489 8.64622 9.85187 8.32603C9.67945 8.05509 9.75335 7.71026 10.0243 7.53785C10.2952 7.36544 10.64 7.43933 10.8125 7.71026C10.8864 7.83342 11.0834 7.95657 11.3297 7.95657C11.7484 7.95657 11.8962 7.68563 11.8962 7.56248C11.8962 7.43933 11.7484 7.16839 11.3297 7.16839C10.4923 7.16839 9.60556 6.57726 9.60556 5.6413C9.60556 4.90238 10.1228 4.38514 10.7632 4.1881C10.8125 3.91716 11.0588 3.72012 11.3297 3.72012C11.6006 3.72012 11.8469 3.91716 11.8962 4.1881C12.1671 4.26199 12.4381 4.4344 12.6351 4.63145C12.8568 4.85312 12.8568 5.22258 12.6351 5.44425C12.4134 5.66593 12.044 5.66593 11.8223 5.44425C11.7238 5.34573 11.5514 5.27184 11.3543 5.27184C10.9356 5.27184 10.7878 5.54278 10.7878 5.66593C10.7878 5.78908 10.9356 6.06002 11.3543 6.06002C12.1918 6.06002 13.0785 6.65115 13.0785 7.58711C13.0538 8.27677 12.512 8.79401 11.8962 8.99105Z"
          fill={props.color ? props.color : '#9998d4'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2566_44588">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
