import { useReactiveVar } from '@apollo/client';
import { DialogAnimate } from 'components/animate';
import { DialogBody, DialogWrapper } from 'components/Dialog/styles';
import LoadingAnimated from 'components/LoadingAnimated';
import BetRoundDetailDialogHeader from 'components/_Games/_GamesMiniDice/bet-round-detail/components/bet-round-detail-dialog-header/BetRoundDetailDialogHeader';
import OverAndUnderTabs from 'components/_Games/_GamesMiniDice/bet-round-detail/components/over-and-under-tabs';
import OverTable from 'components/_Games/_GamesMiniDice/bet-round-detail/components/over-table';
import UnderTable from 'components/_Games/_GamesMiniDice/bet-round-detail/components/under-table';
import {
  useThreeDiceCurrentRoundQuery,
  useThreeDiceRoundDetailQuery,
} from 'graph';
import { memo, useEffect, useMemo, useState } from 'react';
import { storageCurrencyVar } from 'store';

import { Box, useTheme } from '@mui/material';

interface IBetRoundDetailProps {
  open: boolean;
  onClose: () => void;
}

const BetRoundDetail = ({ open, onClose }: IBetRoundDetailProps) => {
  const theme = useTheme();
  const defaultRoundId = window.history.state.roundId || null;
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const [selectedTab, setSelectedTab] = useState<'over' | 'under'>('over');
  const [roundId, setRoundId] = useState(defaultRoundId);
  const { data: roundDetailData, loading } = useThreeDiceRoundDetailQuery({
    variables: {
      roundId,
      currencyId: storageCurrency.id,
    },
    fetchPolicy: 'network-only',
  });
  const { data: threeDiceCurrentRoundData } = useThreeDiceCurrentRoundQuery({
    variables: {
      currencyId: storageCurrency?.id,
    },
    fetchPolicy: 'network-only',
  });

  const onChangeRoundId = (roundId) => {
    setRoundId(String(roundId));
  };

  const winSide: 'under' | 'over' = useMemo(() => {
    if (roundDetailData?.threeDiceRoundDetails?.winSpotId === 1) return 'under';
    if (roundDetailData?.threeDiceRoundDetails?.winSpotId === 2) return 'over';
  }, [roundDetailData]);

  const betMode = useMemo(() => {
    if (roundDetailData?.threeDiceRoundDetails?.betModes) {
      return roundDetailData?.threeDiceRoundDetails?.betModes[0];
    }
    return null;
  }, [roundDetailData]);

  useEffect(() => {
    if (!threeDiceCurrentRoundData?.threeDiceCurrentGame?.currentRound?.id)
      return;

    if (
      roundId >
      threeDiceCurrentRoundData?.threeDiceCurrentGame?.currentRound?.id
    ) {
      setRoundId((prev) => String(Number(prev) - 1));
    }
  }, [threeDiceCurrentRoundData, roundId]);

  useEffect(
    () => () => {
      window.history.replaceState({}, undefined);
    },
    [],
  );

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        width: 480,
        height: 630,
        maxWidth: 'unset',
        borderRadius: '12px !important',
        padding: (theme) => theme.spacing(2, 1.5),
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'unset',
          minHeight: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: (theme) => theme.shape.borderRadius,
        },
      }}
    >
      <BetRoundDetailDialogHeader
        roundId={Number(roundId)}
        onClose={() => onClose()}
        disableNext={
          threeDiceCurrentRoundData?.threeDiceCurrentGame?.currentRound?.id ===
          roundId
        }
        onChangeRoundId={onChangeRoundId}
      />
      <OverAndUnderTabs
        winSide={winSide}
        betMode={betMode}
        onChangeTab={(tab) => setSelectedTab(tab)}
        selectedTab={selectedTab}
      />
      <DialogWrapper mt={1.5} sx={{ p: { xs: 0 } }}>
        <DialogBody
          sx={{
            display: 'flex',
            overflowY: 'unset',
            padding: { xs: 0 },
            height: '100%',
            '.MuiTableCell-root': {
              [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1.25, 0.5),
                minWidth: 'fit-content',
              },
            },
          }}
        >
          {loading ? (
            <Box
              position="absolute"
              width="100%"
              height="100%"
              sx={{ top: 0, left: '50%', transform: 'translate(-50%, -5%)' }}
            >
              <LoadingAnimated />
            </Box>
          ) : (
            <>
              {selectedTab === 'over' && (
                <OverTable winSide={winSide} overData={betMode?.overList} />
              )}

              {selectedTab === 'under' && (
                <UnderTable underData={betMode?.underList} winSide={winSide} />
              )}
            </>
          )}
        </DialogBody>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default memo(BetRoundDetail);
