import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// -----------------------------------------------------------------

export const BannerLoginWrapper = styled(Box)(({ theme }) => ({
  width: 460,
  backgroundSize: '100% 100%',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: theme.spacing(9),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(5),
  },

  h4: {
    fontSize: 40,
    fontWeight: 900,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: 36,
    },
  },

  span: {
    fontSize: 22,
    fontWeight: 700,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    whiteSpace: 'wrap',
    textAlign: 'center',
    span: {
      color: '#FFE500',
      fontWeight: 900,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
}));
