import { accountNotVerifiedV2 } from 'assets/images';
import { useLocales } from 'locales';
import { useNavigate } from 'react-router';
import { profilePaths } from 'routes/routesPage';

import { Box, Stack, Typography } from '@mui/material';

import { AccountNotVerifiedWrapper, ConfirmedButton } from './styles';

// ----------------------------------------------------------------

export default function AccountNotVerified({ subTitle, handleClose }) {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const handleNavigateToVerify = () => {
    handleClose();
    navigate(`/${profilePaths.page}/${profilePaths.childTabs.verify}`);
  };
  return (
    <AccountNotVerifiedWrapper className="box" mb={{ sm: 0 }}>
      <Box className="frame-wrapper">
        <Box className="frame">
          <img className="img" alt="Frame" src={accountNotVerifiedV2} />
          <Stack spacing={2} justifyContent="center" width="100%">
            <Typography color="text.contrast" className="text-wrapper">
              {translate('account_not_verified')}
            </Typography>
            <Typography variant="subtitle2" className="p" whiteSpace="pre-wrap">
              {subTitle}
            </Typography>
          </Stack>
        </Box>
      </Box>
      <ConfirmedButton
        onClick={handleNavigateToVerify}
        variant="contained"
        color="secondary"
      >
        <Typography variant="button">{translate('verify_account')}</Typography>
      </ConfirmedButton>
    </AccountNotVerifiedWrapper>
  );
}
