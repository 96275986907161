import { MutationResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemedButton } from 'common/Button/style';
import FormProvider, { RHFTextField } from 'components/hook-form';
import ValidationMessage from 'components/ValidationMessage';
import { LoginMutation } from 'graph';
import { useLocales } from 'locales';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { HELP_CENTER_PATHS } from 'routes/routesPage/helpCenter';
import * as Yup from 'yup';

import { Typography } from '@mui/material';

import { EmailBox, PasswordBox } from '../styles';
import { SignInWrapper } from './styles';

export interface SignInForm {
  email: string;
  password: string;
}

interface ISignInProps {
  openSignUp: () => void;
  openForgotPassword: () => void;
  handleCloseAuthDialog: () => void;
  onSubmitSignIn: (data: SignInForm) => void;
  loginMutationResult: MutationResult<LoginMutation>;
}

const V2SignIn = ({
  onSubmitSignIn,
  openForgotPassword,
  handleCloseAuthDialog,
  loginMutationResult: { data, error: apolloError },
}: ISignInProps) => {
  const { translate } = useLocales();

  const navigate = useNavigate();

  const schema = Yup.object().shape({
    email: Yup.string().required().trim().lowercase(),
    password: Yup.string().required().trim(),
  });

  const form = useForm<SignInForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const handleViewTerms = () => {
    navigate(HELP_CENTER_PATHS.TERMS_SERVICE);
    handleCloseAuthDialog();
  };

  return (
    <SignInWrapper>
      <FormProvider methods={form} onSubmit={form.handleSubmit(onSubmitSignIn)}>
        <EmailBox>
          <RHFTextField name="email" placeholder="Enter your email" fullWidth />
        </EmailBox>

        <PasswordBox>
          <RHFTextField
            fullWidth
            name="password"
            placeholder={translate('enter_password_here')}
            isPasswordInput
          />
        </PasswordBox>

        <Typography
          className="primary_green"
          variant="subtitle2"
          onClick={() => openForgotPassword()}
          sx={{
            fontSize: '14px !important',
            fontWeight: '400 !important',
            color: '#63D8FE',
            lineHeight: 'normal',
          }}
        >
          {translate('forgot_your_password')}
        </Typography>

        {data?.login?.error?.message && (
          <ValidationMessage
            mt={{ md: 1, sm: 1, xs: 2 }}
            mb={{ md: -1, sm: -1 }}
            message={data.login.error?.message}
            ml={0}
          />
        )}

        {apolloError && (
          <ValidationMessage
            mt={{ md: 1, sm: 1, xs: 2 }}
            mb={{ md: -1, sm: -1 }}
            message={apolloError.message}
            ml={0}
          />
        )}

        <ThemedButton
          className="confirm_button"
          type="submit"
          fullWidth
          variant={!form.formState.isValid ? 'disable' : 'contained'}
          disabled={!form.formState.isValid}
          color="secondary"
          style={{
            fontSize: 16,
            fontWeight: 700,
            color: '#2E2C5E',
            lineHeight: 'normal',
          }}
        >
          {translate('sign_in')}
        </ThemedButton>

        <Typography sx={{ fontSize: 12, mb: 1.5 }} whiteSpace="pre-wrap">
          {translate('confirm_accessing_site')}
          <span
            onClick={() => handleViewTerms()}
            className="primary_green underline ml4"
          >
            {translate('terms_of_service')}
          </span>
        </Typography>
      </FormProvider>
    </SignInWrapper>
  );
};

export default V2SignIn;
