// @mui
import axios from 'axios';
import { IP_REGISTRY } from 'constants/config';
import { useCallback, useEffect } from 'react';

import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

import { IPRegistry } from './type';
import useLocales from './useLocales';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

const API_KEY = 'zi6tpxyjh1ahjlf0';

const GetIPRegistry: () => Promise<IPRegistry> = async () => {
  try {
    const response = await axios.get(
      `https://api.ipregistry.co/?key=${API_KEY}&fields=currency,location`,
    );
    return response.data;
  } catch (error) {
    /* empty */
  }
  return null;
};

export default function ThemeLocalization({ children }: Props) {
  const outerTheme = useTheme();

  const { currentLang } = useLocales();

  const run = useCallback(async () => {
    let dataRegistry = null;
    try {
      dataRegistry = JSON.parse(localStorage.getItem(IP_REGISTRY));
    } catch (error) {
      /* empty */
    }

    if (!dataRegistry) {
      const data = await GetIPRegistry();
      data && localStorage.setItem(IP_REGISTRY, JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    run();
  }, [run]);

  const theme = createTheme(outerTheme, currentLang.systemValue);

  return <ThemeProvider theme={theme}> {children} </ThemeProvider>;
}
