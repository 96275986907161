import {
  Box,
  Button,
  ButtonBase,
  ButtonBaseProps,
  styled,
} from '@mui/material';

interface NumberButtonProps extends ButtonBaseProps {
  selected: boolean;
}

export const NumberButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<NumberButtonProps>(({ selected, theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: selected
    ? theme.palette.background.hovering
    : theme.palette.background.secondary,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.background.disable,
    border: 0,
  },
  minWidth: theme.spacing(7),
  fontWeight: 'bold',
  boxShadow: theme.shadows[4],
}));

export const StartAutoButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    border: 0,
  },
}));

// PayTable

export const PayTableWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,

  '.currency': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#262936',
    padding: 12,
    div: {
      display: 'flex',
      gap: 8,
      img: {
        width: 44,
        heigh: 44,
      },
    },
    p: {
      color: theme.palette.text.contrast,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
}));

// Rule

export const RuleWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,

  '.p-12': {
    width: '100%',
    background: '#262936',
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
  },

  '.header': {
    h6: {
      fontSize: 13,
      fontWeight: 500,
      color: theme.palette.text.contrast,
    },
    p: {
      fontSize: 13,
      color: '#858997',
      whiteSpace: 'wrap',
      margin: '12px 0 24px',
    },
    div: {
      display: 'flex',
      justifyContent: 'space-around',
      img: {
        width: 62,
        height: 62,
      },
    },
  },

  '.jackpot': {
    gap: 8,
    h6: {
      fontSize: 13,
      fontWeight: 700,
      color: theme.palette.text.contrast,
      textAlign: 'center',
    },
    div: {
      display: 'flex',
      justifyContent: 'center',
      gap: 10,
      img: {
        width: 64,
        height: 64,
      },
    },
    p: {
      fontSize: 13,
      textAlign: 'center',
    },
  },

  '.wild': {
    gap: 24,
    'div:first-of-type': {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      img: {
        width: 64,
        height: 64,
      },
      p: {
        fontSize: 13,
      },
    },

    'div:nth-child(2), div:last-child': {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      img: {
        width: 112,
        height: 45,
      },
      p: {
        fontSize: 13,
        color: theme.palette.text.contrast,
      },
    },
  },
}));
