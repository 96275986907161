import Iconify from 'components/iconify';
import { motion, useDragControls } from 'framer-motion';
import { useLocales } from 'locales';
import { useLayoutEffect, useRef, useState } from 'react';

import { Box, IconButton, Stack, SxProps, Typography } from '@mui/material';

import { StatisticBox } from './styles';

const width = 420;
const heightCount = 6;
const widthCount = 16;
const offset = 2;
const radius = (width - (widthCount - 1) * offset) / widthCount;
const height = (heightCount - 1) * offset + heightCount * radius;

const tableA = new Array(widthCount * heightCount).fill(1);

type RoundResult = {
  round_id?: string;
  payout: number;
};

interface IStaticTableContentProps {
  results: RoundResult[];
  getCircleColor?: (item: number) => string;
  getCircleType?: (item: number) => 1 | 2;
}

export const StaticTableContent = ({
  results,
  getCircleColor = null,
  getCircleType = null,
}: IStaticTableContentProps) => {
  const [postion, setPostion] = useState<{ x: number; y: number }[]>([
    { x: 0, y: 0 },
  ]);
  const payoutType = (payout: number) => {
    if (getCircleType && getCircleType(payout)) return getCircleType(payout);
    return payout < 2 ? 1 : 2;
  };

  const i2xy = (index: number) => (radius + offset) * index + radius / 2;

  const makeAlertColor = (payout: number) => {
    if (getCircleColor && getCircleColor(payout)) return getCircleColor(payout);

    if (payout < 2) return '#FF5C00';
    if (payout < 10) return '#53BA0C';
    return '#18BDF9';
  };

  useLayoutEffect(() => {
    if (results.length > 0) {
      let consecutive = 1;
      let ddrawState: 'DOWN' | 'RIGHT_UP' | 'LEFT_UP' | 'RIGHT' = 'DOWN';

      const pos: { x: number; y: number }[] = [{ x: 0, y: 0 }];

      results.forEach((result, i) => {
        if (i < results.length - 1) {
          const posLast = pos[pos.length - 1];
          if (
            payoutType(result?.payout) === payoutType(results[i + 1]?.payout)
          ) {
            let ys = 0;
            for (let ii = posLast.y + 1; ii <= 6; ii++) {
              ys = ii;
              const limit = pos.find((r) => r.x === posLast.x && r.y === ii);
              if (limit) {
                break;
              }
            }
            if (consecutive >= ys) {
              ddrawState = 'RIGHT';
              pos.push({
                x: posLast.x + 1,
                y: posLast.y,
              });
            } else {
              ddrawState = 'DOWN';
              pos.push({
                x: posLast.x,
                y: posLast.y + 1,
              });
            }
            consecutive += 1;
          } else {
            if (
              ddrawState === 'DOWN' ||
              ddrawState === 'RIGHT_UP' ||
              ddrawState === 'LEFT_UP'
            ) {
              ddrawState = 'RIGHT_UP';
              consecutive = 1;
              pos.push({
                x: posLast.x + 1,
                y: 0,
              });
            }

            if (ddrawState === 'RIGHT') {
              ddrawState = 'LEFT_UP';
              consecutive = 1;
              const maxX = Math.max(
                ...pos.filter((p) => p.y === 0).map((r) => r.x),
              );
              pos.push({
                x: maxX + 1,
                y: 0,
              });
            }
          }
        }
      });

      setPostion(pos);
    }
  }, [results]);

  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      {tableA.map((_, i) => {
        const xx = (i % widthCount) * (radius + offset);
        const yy = Math.floor(i / widthCount) * (radius + offset);
        return (
          <rect
            key={_}
            x={xx}
            y={yy}
            height={radius}
            width={radius}
            fill="#181C2A"
          />
        );
      })}

      {postion.map((p, i) => (
        <circle
          key={p.x}
          cx={i2xy(p.x)}
          cy={i2xy(p.y)}
          r={radius / 2 - 3}
          fill={makeAlertColor(results[i]?.payout)}
        />
      ))}
    </svg>
  );
};

interface StatisticTableProps {
  results: RoundResult[];
  onClose: () => void;
  sx?: SxProps;
}

const StatisticTable = ({ results, onClose, sx }: StatisticTableProps) => {
  const controls = useDragControls();
  const { translate } = useLocales();
  const [showHelp, setShowHelp] = useState(false);

  const bodyElRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const bodyEl = document.getElementsByTagName('body');

    if (bodyEl) {
      bodyElRef.current = bodyEl[0] as any;
    }
  }, []);

  return (
    <motion.div
      drag
      {...(bodyElRef.current && {
        dragConstraints: bodyElRef,
      })}
      onPointerDown={(event) => {
        controls.start(event);
      }}
      dragControls={controls}
      style={{
        zIndex: 999,
        position: 'absolute',
        top: `0px`,
        left: `0px`,
      }}
    >
      <StatisticBox>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1, ...sx }}
        >
          <Stack direction="row" spacing={0.7}>
            <Iconify icon="icon-park-solid:trend" width={22} />
            <Typography variant="subtitle1">{translate('trends')}</Typography>
          </Stack>
          <IconButton
            sx={{ p: 0.5 }}
            onTouchEnd={() => {
              if (onClose) onClose();
            }}
            onClick={() => {
              if (onClose) onClose();
            }}
          >
            <Iconify
              icon="solar:close-square-linear"
              width={22}
              color="text.primary"
            />
          </IconButton>
        </Stack>
        <StaticTableContent results={results} />
        <Stack
          direction="row"
          alignItems="center"
          sx={{ pt: 0.5 }}
          spacing={0.5}
        >
          <Iconify icon="fluent:info-28-filled" width={18} />
          <Typography variant="caption" color="text.secondary">
            {translate('understand_trend_chart')}
          </Typography>
          <IconButton
            sx={{ p: 0.5 }}
            onClick={() => {
              setShowHelp((s) => !s);
            }}
          >
            <Iconify
              icon={
                showHelp ? 'mingcute:arrow-up-fill' : 'mingcute:arrow-down-fill'
              }
              width={18}
              color="#53BA0C"
            />
          </IconButton>
        </Stack>
        {showHelp && (
          <Box sx={{ py: 2 }}>
            <img src="/assets/Trend.svg" alt="Trend" width={420} />
          </Box>
        )}
      </StatisticBox>
    </motion.div>
  );
};

StatisticTable.defaultProps = {
  sx: {},
};

export default StatisticTable;
