import { useLocales } from 'locales';
import Markdown from 'markdown/Markdown';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

let cacheRules = '';

const MiniDiceRules = () => {
  const { translate, currentLang } = useLocales();
  const [ruleText, setRuleText] = useState(cacheRules);

  useEffect(() => {
    if (!ruleText) {
      try {
        import(`markdown/threedice/rules/${currentLang.value}.md`)
          .then((response) => fetch(response.default))
          .then((response) => response.text())
          .then((text) => {
            cacheRules = text;
            setRuleText(text);
          })
          .catch(() => {
            setRuleText(translate('coming_soon2'));
          });
      } catch {
        setRuleText(translate('coming_soon2'));
      }
    }
  }, [currentLang]);

  return (
    <Box id="mini-dice-rules" sx={{ overflowY: 'auto' }}>
      <Markdown>{ruleText}</Markdown>
    </Box>
  );
};

export default MiniDiceRules;
