import { Button, ButtonBase, ButtonBaseProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

interface NumberButtonProps extends ButtonBaseProps {
  selected: boolean;
}

export const NumberButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<NumberButtonProps>(({ selected, theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: selected
    ? theme.palette.background.hovering
    : theme.palette.background.secondary,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.background.disable,
    border: 0,
  },
  minWidth: theme.spacing(7),
  fontWeight: 'bold',
  boxShadow: theme.shadows[4],
}));

export const StartAutoButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    border: 0,
  },
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 10000,
  padding: theme.spacing(2.5),

  '.css-112i60i-MuiPaper-root-MuiDialog-paper': {
    background: theme.palette.background.table,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100vh',
      maxHeight: '100vh',
      margin: 0,
    },
  },

  '.header': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2.5, 3, 0),
    p: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.contrast,
      lineHeight: 'normal',
      textTransForm: 'uppercase',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.25, 3, 0),
    },
  },

  '.game-info-btn': {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '14px 24px !important',
    },
  },

  '.button': {
    width: '49%',
    ':hover': {
      background: theme.palette.background.hovering,
    },
    'svg:hover, p:hover': {
      background: 'none',
    },
    svg: {
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        width: 15,
        height: 15,
      },
    },
    p: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },

  'button.default': {
    background: theme.palette.background.secondary,
  },

  '.button.selected': {
    background: theme.palette.background.hovering,
    color: theme.palette.text.contrast,
    path: {
      fill: 'white',
    },
  },

  '.game-info-content': {
    minHeight: 708,
    padding: '0 24px 24px !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'fit-content',
    },
  },

  '.paytable-item': {
    background: theme.palette.background.secondary,
    padding: theme.spacing(0, 1, 1),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      p: {
        fontSize: 16,
        lineHeight: 'normal',
      },
    },
  },

  '.rule-text': {
    background: theme.palette.background.secondary,
    padding: theme.spacing(1.25),
    h2: {
      lineHeight: 'normal',
      marginBottom: theme.spacing(1.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    p: {
      lineHeight: 'normal',
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    ol: {
      margin: 0,
    },
  },
}));
