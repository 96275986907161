import { format } from 'date-fns';
import {
  DepositStatus,
  DepositTransaction,
  WithdrawalStatus,
  WithdrawalTransaction,
} from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import { truncate } from 'utils/truncateMiddle';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Dialog from '../Dialog';
import { DetailTransactionWrapper } from './styles';
import { TransactionTabKey } from './TransactionsDialog';

// -----------------------------------------------------------

interface DetailTransactionDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  transaction: DepositTransaction | WithdrawalTransaction;
  selectedTab: any;
}
const DetailTransactionDialog = ({
  isOpen,
  transaction,
  handleClose,
  selectedTab,
}: DetailTransactionDialogProps) => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const getStatusLabel = (status) => {
    switch (status) {
      case DepositStatus.Completed || WithdrawalStatus.Completed:
        return translate('success');
      case DepositStatus.New && selectedTab.key === TransactionTabKey.DEPOSIT:
        return translate('processing');
      case WithdrawalStatus.Credit:
        return translate('audit');
      case WithdrawalStatus.Insufficient:
        return translate('insufficient');

      // TODO: update when confirm from MR THANH
      case WithdrawalStatus.New &&
        selectedTab.key === TransactionTabKey.WITHDRAW:
        return translate('new');
      case WithdrawalStatus.Processing:
        return translate('processing');
      default:
        return '';
    }
  };

  const viewDetailTransaction = () => {
    if (
      transaction.currency.shortName === 'tmatic' ||
      transaction.currency.shortName === 'matic'
    ) {
      window.open(
        `https://mumbai.polygonscan.com/tx/${transaction.tx}`,
        '_blank',
      );
    } else
      enqueueSnackbar(translate('currency_do_not_supported'), {
        variant: 'error',
      });
  };

  return (
    <Dialog
      title={translate('detail')}
      isOpen={isOpen}
      handleClose={handleClose}
      hasCloseButton
      style={{ width: 500 }}
    >
      <DetailTransactionWrapper>
        <Box className="row_item">
          <Typography flex="1">{translate('status')}</Typography>
          <Typography
            flex="3"
            className={
              transaction?.status === WithdrawalStatus.Completed
                ? 'status_success'
                : 'status_pending'
            }
          >
            {getStatusLabel(translate(transaction?.status))}
          </Typography>
        </Box>
        {transaction?.tx && (
          <Box className="row_item">
            <Typography flex="1">Txid</Typography>
            <Box display="flex" alignItems="center" flex="3">
              <Typography color="white">
                {truncate(transaction?.tx, 10, 6, 6)}
              </Typography>
              <Typography
                ml={2}
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                }}
                onClick={() => viewDetailTransaction()}
              >
                {translate('view')}
              </Typography>
            </Box>
          </Box>
        )}
        <Box className="row_item">
          <Typography flex="1">{translate('order_id')}</Typography>
          <Typography flex="3" color="white">
            {transaction?.id}
          </Typography>
        </Box>
        <Box className="row_item">
          <Typography flex="1">{translate('currency')}</Typography>
          <Typography flex="3" color="white">
            {transaction?.currency?.shortName.toUpperCase()}
          </Typography>
        </Box>
        <Box className="row_item">
          <Typography flex="1">{translate('quantity')}</Typography>
          <Typography flex="3" color="white">
            {getCurrencyAmount(transaction.amount, {
              ...transaction.currency,
              equivalentUsdRate: transaction.equivalentUsdRate,
            })}
          </Typography>
        </Box>
        <Box className="row_item">
          <Typography flex="1">{translate('time')}</Typography>
          <Typography flex="3" color="white">
            {format(
              new Date(transaction?.createdAt || 0),
              'MM/dd/yyyy h:mm:ss aa',
            )}
          </Typography>
        </Box>
      </DetailTransactionWrapper>
    </Dialog>
  );
};

export default DetailTransactionDialog;
