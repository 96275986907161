import { kycStep3Placeholder_v2 } from 'assets/images';
import useLocales from 'locales/useLocales';

import { Box, Stack, Typography, useTheme } from '@mui/material';

import UploadImage from '../UploadImage';

// -----------------------------------------------------------------------

export default function PortraitUploading() {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="subtitle1"
        fontWeight={400}
        whiteSpace="pre-wrap"
        mt={{ sm: 1.5, xs: 3 }}
      >
        {translate('verify_portrait_description')}
      </Typography>

      <Stack
        className="step-verify"
        width="100%"
        direction={{ sm: 'row', xs: 'column' }}
        sx={{
          margin: theme.spacing(3, 0),
          flexWrap: 'wrap',
          '.label': {
            textAlign: 'left',
          },
        }}
      >
        <UploadImage name="portrait" label={translate('your_photo')} />

        <Box
          height="100%"
          justifyContent="center"
          sx={{
            display: 'flex',
            flex: 1,
            alignSelf: { sm: 'flex-end', xs: 'center' },
            mb: 3,
            textAlign: {
              xs: 'center',
              md: 'left',
            },
            img: {
              maxWidth: 273,
              width: { sm: 273, xs: '100%' },
              minWidth: 273,
              minHeight: 190,
              aspectRatio: '273/180',
            },
          }}
        >
          <img src={kycStep3Placeholder_v2} alt="placeholder" />
        </Box>
      </Stack>
    </Box>
  );
}
