import isPropValid from '@emotion/is-prop-valid';
import { MotionProps } from 'framer-motion';

import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const V2_HeaderWrapper = styled(Box)<BoxProps & MotionProps>(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'inherit',
    boxShadow: theme.shadows[5],
    zIndex: 9,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1, 2),

    '& div': {
      display: 'flex',
      // alignItems: 'center',
    },

    '.MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
    },

    '.message_box': {
      marginLeft: theme.spacing(2),
    },

    '.message_box:hover, .noti_btn:hover': {
      path: {
        fill: theme.palette.text.contrast,
      },
    },

    '.random_dice': {
      display: 'inline-flex',
      textDecoration: 'none',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(1),
      },
      '&:hover': {
        path: {
          fill: theme.palette.text.contrast,
        },
        p: {
          color: theme.palette.text.contrast,
        },
      },
    },

    '.mobile_logo': {
      width: 36,
      display: 'flex',
      cursor: 'pointer',
    },
  }),
);

interface StyledContainerProps extends BoxProps {
  isNavCollapse: boolean;
}

export const V1StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<StyledContainerProps>(({ theme, isNavCollapse }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    minHeight: 48,
  },
  [theme.breakpoints.between('xl', 1920)]: {
    padding: isNavCollapse ? theme.spacing(0, 1) : theme.spacing(0, 10, 0, 36),
  },
  [theme.breakpoints.up(1920)]: {
    padding: isNavCollapse ? theme.spacing(0, 1) : theme.spacing(0, 19, 0, 36),
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    padding: isNavCollapse ? theme.spacing(0, 1) : theme.spacing(0, 5, 0, 36),
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: theme.spacing(0, 2, 0, 1),
  },
}));

export const V2StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<StyledContainerProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    minHeight: 48,
  },
}));

interface AuthenticationBoxProps extends BoxProps {}

export const AuthenticationBox = styled(Box)<AuthenticationBoxProps>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down(450)]: {
      gap: theme.spacing(1.5),
    },

    button: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 'normal',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1, 2),
      [theme.breakpoints.down(450)]: {
        fontSize: 14,
      },

      ':last-child': {
        position: 'relative',

        '.lottie-sign-up': {
          width: 38,
          height: 38,
          position: 'absolute',
          right: '-20px',
        },
      },
    },

    'button:first-of-type': {
      color: theme.palette.text.primary,
      background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
    },

    'button:last-child': {
      color: '#2E2C5E',
      background:
        'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    },

    '.noti_btn': {
      marginLeft: theme.spacing(2),
    },
  }),
);

export const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',

  '.logo': {
    cursor: 'pointer',
    display: 'flex',
  },
  [theme.breakpoints.down('sm')]: {
    height: 36,
  },
}));

interface MenuIconButtonProps {
  isCollapsed: boolean;
}

export const MenuIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<MenuIconButtonProps>(({ theme, isCollapsed }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  ...(isCollapsed && { backgroundColor: theme.palette.background.hovering }),
  '&:hover': {
    color: theme.palette.text.contrast,
    backgroundColor: theme.palette.background.hovering,
  },
  backgroundColor: theme.palette.background.overlay,
  transition: 'all ease 0.15s',
}));

export const HeaderButtonIcon = styled(IconButton)(({ theme }) => ({
  backgroundImage: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: 'none',
  },
  borderRadius: theme.shape.borderRadius,
  width: '40px',
  height: '40px',
  [theme.breakpoints.down('md')]: {
    width: 35,
    height: 35,
  },
  '& svg': {
    transition: '275ms',
  },
  '&:hover': {
    '& #dice-header': {
      transform: 'rotate(360deg)',
      transition: '275ms',
    },
  },
}));

// LanguageFiat

export const LanguageFiat = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
  gap: '6px',
  transition: 'flexDirection ease 0.15s',
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    padding: theme.spacing(0, 1.5),
    margin: 0,
  },

  '.arrow-icon': {
    width: 20,
    height: 20,
  },

  '.language_box, .fiat_box': {
    backgroundImage: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5),
    },

    '&:hover': {
      color: theme.palette.text.contrast,
      path: {
        fill: theme.palette.text.contrast,
      },
    },

    img: {
      width: 24,
      height: 24,
    },

    span: {
      fontSize: 14,
    },
  },
}));
