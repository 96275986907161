import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PopupBuyCryptoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',

  '.popup': {
    position: 'fixed',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.66)',
    // backdropFilter: 'blur(6px)',
    // WebkitBackdropFilter: 'blur(6px)',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  '.popup-header': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    p: {
      color: theme.palette.text.contrast,
      fontSize: 20,
    },
    '.MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  '.popup-content': {
    width: '460px',
    [theme.breakpoints.down(450)]: {
      width: '366px',
    },
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    padding: theme.spacing(2),
    p: {
      textAlign: 'center',
      lineHeight: 'normal',
      whiteSpace: 'wrap',
      // fontSize: 17,
    },
    borderRadius: theme.spacing(2),
  },
  '.popup-btn': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
    button: {
      width: '210px',
      [theme.breakpoints.down(450)]: {
        width: '160px',
      },
    },
  },
  '.hidden': {
    display: 'none',
  },
}));
