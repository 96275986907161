import { SvgIcon } from '@mui/material';

export function IconProviders() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_8344_11321)">
        <path
          d="M4.96875 13.2188H0.75C0.361687 13.2188 0.046875 13.5336 0.046875 13.9219V23.2969C0.046875 23.6852 0.361687 24 0.75 24H4.96875C5.35706 24 5.67188 23.6852 5.67188 23.2969V13.9219C5.67188 13.5336 5.35706 13.2188 4.96875 13.2188Z"
          fill="#9998D4"
        />
        <path
          d="M22.8652 14.108C22.1583 13.7848 21.3251 13.935 20.7755 14.4846C20.1149 15.1453 19.2006 16.0596 18.3945 16.8658C18.387 18.6046 16.9705 20.0169 15.23 20.0169H11.625C11.2367 20.0169 10.9219 19.7021 10.9219 19.3138C10.9219 18.9255 11.2367 18.6107 11.625 18.6107H15.2301C16.2013 18.6107 16.9886 17.8234 16.9886 16.8521C16.9886 15.8807 16.2011 15.0932 15.2296 15.0934L12.7285 15.0937C10.9859 14.093 8.91619 13.9497 7.07812 14.6654V22.3641C8.72227 22.7466 10.4075 22.9406 12.0932 22.9406C13.7137 22.9406 15.3387 22.7609 16.9232 22.4065C17.9203 22.1831 18.8296 21.6837 19.5531 20.9624L23.406 17.1184C24.3255 16.2011 24.0465 14.6479 22.8652 14.108Z"
          fill="#9998D4"
        />
        <path
          d="M8.18369 10.9674L8.76269 10.6331C9.11379 10.8993 9.49089 11.1187 9.89097 11.2893V11.9531C9.89097 12.3414 10.2058 12.6562 10.5941 12.6562H13.4066C13.7949 12.6562 14.1097 12.3414 14.1097 11.9531V11.2893C14.5098 11.1187 14.8869 10.8993 15.238 10.6331L15.817 10.9674C16.1533 11.1615 16.5833 11.0463 16.7775 10.71L18.1837 8.27433C18.3779 7.93805 18.2627 7.50802 17.9264 7.31386L17.3498 6.98095C17.3772 6.762 17.391 6.54375 17.391 6.32812C17.391 6.1125 17.3772 5.89425 17.3499 5.6753L17.9265 5.34239C18.2628 5.14823 18.378 4.7182 18.1838 4.38192L16.7776 1.9462C16.5834 1.60992 16.1534 1.49466 15.8171 1.68886L15.2381 2.02312C14.8871 1.75697 14.5098 1.53755 14.1098 1.36687V0.703125C14.1098 0.314812 13.795 0 13.4066 0H10.5941C10.2058 0 9.89102 0.314812 9.89102 0.703125V1.36687C9.49094 1.53755 9.11374 1.75697 8.76274 2.02312L8.18374 1.68881C7.84746 1.49466 7.41743 1.60988 7.22327 1.94616L5.81702 4.38188C5.62286 4.71816 5.73808 5.14819 6.07436 5.34234L6.65093 5.67525C6.62355 5.89425 6.60977 6.1125 6.60977 6.32812C6.60977 6.54375 6.62355 6.762 6.65088 6.98095L6.07432 7.31386C5.73803 7.50802 5.62282 7.93805 5.81697 8.27433L7.22322 10.71C7.41738 11.0464 7.84741 11.1616 8.18369 10.9674ZM12.0004 4.21875C13.1635 4.21875 14.1098 5.16502 14.1098 6.32812C14.1098 7.49123 13.1635 8.4375 12.0004 8.4375C10.8373 8.4375 9.89102 7.49123 9.89102 6.32812C9.89102 5.16502 10.8373 4.21875 12.0004 4.21875Z"
          fill="#9998D4"
        />
      </g>
      <defs>
        <clipPath id="clip0_8344_11321">
          <rect width="24" height="24" fill="#9998D4" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
