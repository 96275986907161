import { useReactiveVar } from '@apollo/client';
import coins from 'assets/images/coins';
import StickyHeaderTable from 'common/Table/StickyHeaderTable';
import BetDetail from 'components/_Games/BetDetail';
import { ThreeDiceHistoriesQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { memo, useState } from 'react';
import { currenciesVar } from 'store';
import { truncate } from 'utils/truncateMiddle';

import {
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  BetAmountHost,
  BetIDHost,
  BetPlace,
} from 'overrides/components/text-host/TextHost';

interface MiniDiceMyBetProps {
  myHistories: ThreeDiceHistoriesQuery['threeDiceCurrentGame']['userHistories'];
}

const MiniDiceMyBet = ({ myHistories = [] }: MiniDiceMyBetProps) => {
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const { currencies } = useReactiveVar(currenciesVar);
  const [selectedBetId, setSelectedBetId] = useState(null);

  const getCurrencyById = (id) =>
    currencies.find((currency) => currency.id === id);

  return (
    <StickyHeaderTable className="mini-dice-my-bet">
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Stack>
              <div>{translate('round_id')}</div>
              <div> {translate(BetIDHost())}</div>
            </Stack>
          </TableCell>
          <TableCell align="left">
            <Stack>
              <div>{translate(BetPlace())}</div>
              <div> {translate('result')}</div>
            </Stack>
          </TableCell>
          <TableCell className="bet-amount" align="left">
            <Stack>
              <div>{translate(BetAmountHost())}</div>
              <div> {translate('refund')}</div>
            </Stack>
          </TableCell>
          <TableCell className="profit" align="right">
            <Stack justifyContent="center">{translate('profit')}</Stack>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {myHistories.map((row) => (
          <TableRow key={row.roundId}>
            <TableCell align="left">
              <Stack>
                <Typography
                  className="round-id"
                  onClick={() => {
                    window.ccg_threedice.onOpenRoundDetailDialog(row.roundId);
                  }}
                >
                  #{row.roundId}
                </Typography>
                <Typography
                  onClick={() => setSelectedBetId(row.id)}
                  className="primary-text bet-id"
                >
                  {isMobile ? truncate(String(row.id), 8, 3, 3) : row.id}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell align="left">
              <Stack>
                <div>{row.isOver ? translate('over') : translate('under')}</div>
                <div className="primary-text">{`${
                  row.gameResults?.winSpotId === 1
                    ? translate('under')
                    : translate('Over')
                }-${row.gameResults?.diceSum}`}</div>
              </Stack>
            </TableCell>
            <TableCell className="bet-amount" align="left">
              <Stack direction="row" alignItems="center">
                <img
                  className="currency-image"
                  alt={getCurrencyById(row.currencyId)?.shortName}
                  src={coins[getCurrencyById(row.currencyId)?.shortName]}
                />
                <Stack>
                  <div>
                    {getCurrencyAmount(row.betValue, {
                      ...getCurrencyById(row.currencyId),
                      equivalentUsdRate: row.rateToBaseCurrency,
                    })}
                  </div>
                  <div className="primary-text">
                    {getCurrencyAmount(row.refundValue, {
                      ...getCurrencyById(row.currencyId),
                      equivalentUsdRate: row.rateToBaseCurrency,
                    })}
                  </div>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell className="profit" align="left">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <img
                  className="currency-image"
                  alt={getCurrencyById(row.currencyId)?.shortName}
                  src={coins[getCurrencyById(row.currencyId)?.shortName]}
                />
                <Typography
                  minWidth={50}
                  className={`${row.winValue > 0 ? 'primary-green' : ''}`}
                >
                  {row.winValue
                    ? getCurrencyAmount(row.winValue, {
                        ...getCurrencyById(row.currencyId),
                        equivalentUsdRate: row.rateToBaseCurrency,
                      })
                    : '0.00'}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
        {myHistories.length === 0 && (
          <TableRow>
            <TableCell colSpan={4}>
              <Typography textAlign="center" color="text.primary">
                {translate('empty_data')}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {selectedBetId && (
        <BetDetail
          betId={selectedBetId}
          open={Boolean(selectedBetId)}
          onClose={() => setSelectedBetId(null)}
        />
      )}
    </StickyHeaderTable>
  );
};

export default memo(MiniDiceMyBet);
