import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconQuestionMessage } from 'assets/icons';
import coinImages from 'assets/images/coins';
import { GiftCodeSuccess } from 'assets/images/giftcode';
import { useAuthContext } from 'auth/useAuthContext';
import { DialogAnimate } from 'components/animate';
import { CloseBox, DialogBody, DialogHeader } from 'components/Dialog/styles';
import FormProvider, { RHFTextField } from 'components/hook-form';
import ValidationMessage from 'components/ValidationMessage';
import { m } from 'framer-motion';
import { Currency, useRedeemCodeMutation, useUserWalletsQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StageSpinner } from 'react-spinners-kit';
import { currencyLocalStateOperations } from 'store/operations';
import { BACKGROUND_MAIN_COLOR, PRIMARY_PURPLE_COLOR } from 'theme/colors';
import * as Yup from 'yup';

import { CloseRounded } from '@mui/icons-material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';

import {
  GiftCodeHost,
  HowToGetGiftCode,
  HowToYouGiftCode,
  UseYourGiftToBet,
} from 'overrides/components/text-host/TextHost';
import { StyledButtonClose, StyledButtonUseBonus } from './style';

// -------------------------------------------------------------------------

interface IGiftCodeDialogProps {
  onClose: VoidFunction;
}

export function GiftCodeDialog({ onClose }: IGiftCodeDialogProps) {
  const theme = useTheme();
  const { isAuthenticated, openLoginModal } = useAuthContext();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useResponsive('up', 'md');
  const [openExplainGiftCode, setOpenExplainGiftcode] = useState(false);
  const { refetch: refetchWallets } = useUserWalletsQuery();
  const { setStorageCurrency } = currencyLocalStateOperations;
  const [isOpenSuccess, setIsOpenSuccess] = useState<boolean>(false);

  const objDetail = [
    {
      index: 1,
      title: 'what_is_gift_code',
      content: (
        <Box>
          <p>
            {translate('gift_code_info')}{' '}
            <Typography color="#00B8D9" display="inline" fontSize="inherit">
              {translate(GiftCodeHost())}
            </Typography>
          </p>
          <p>{translate('gift_code_cod')}</p>
        </Box>
      ),
    },
    {
      index: 2,
      title: HowToGetGiftCode(),
      content: (
        <Box>{translate('there_are_various_ways_to_get_HOST_gift_codes')}</Box>
      ),
    },
    {
      index: 3,
      title: HowToYouGiftCode(),
      content: <Box>{translate('simply_enter_the_gift_code')}</Box>,
    },
  ];

  const schema = Yup.object().shape({
    giftcode: Yup.string().required(translate('giftcode_required')).trim(),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const handleCloseDialog = () => {
    onClose();
    form.reset();
    setIsOpenSuccess(false);
  };

  const handleUseBonus = (currency: Currency) => {
    handleCloseDialog();
    setStorageCurrency(currency);
  };

  const [giftCodeMuation, { data: dataGiftCode, loading: loadingGiftCode }] =
    useRedeemCodeMutation();
  const onSubmitData = async () => {
    if (!isAuthenticated) {
      return openLoginModal();
    }
    const result = await giftCodeMuation({
      variables: {
        code: form.getValues('giftcode'),
      },
    });
    if (result.data?.redeemCode.success === true) {
      form.reset();
      refetchWallets();
      setIsOpenSuccess(true);
    } else if (result.data?.redeemCode?.error) {
      enqueueSnackbar(result.data?.redeemCode?.error.message as string, {
        variant: 'error',
      });
    }
  };

  const HandleSuccessGiftCode = () => (
    <Box display="flex" flexDirection="column" gap={1.5} alignItems="center">
      <Box component="img" src={GiftCodeSuccess} width={180} height={160} />
      <Typography fontSize={16} fontWeight={700} color="#FFF">
        {translate('gift_received_successfully')}
      </Typography>
      <Typography>
        {translate('congratulations_you_have_just_received')}
      </Typography>
      {dataGiftCode?.redeemCode.success === true && (
        <>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Box
              component="img"
              src={
                coinImages[dataGiftCode.redeemCode.content.currency.shortName]
              }
              width="20px"
              height="20px"
            />
            <Typography
              fontSize={20}
              fontWeight={700}
              color="#FFF"
              textTransform="uppercase"
            >
              {dataGiftCode.redeemCode.content.amount}{' '}
              {dataGiftCode.redeemCode.content.currency.shortName}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={2} width="100%">
            <StyledButtonClose
              fullWidth
              variant="outlined"
              onClick={() => handleCloseDialog()}
            >
              Close
            </StyledButtonClose>
            <StyledButtonUseBonus
              fullWidth
              variant="contained"
              onClick={() =>
                handleUseBonus(dataGiftCode.redeemCode.content.currency)
              }
            >
              Use Bonus
            </StyledButtonUseBonus>
          </Box>
        </>
      )}
    </Box>
  );

  const HandleLoadingGiftCode = () => (
    <Box
      component={m.div}
      animate={{
        scale: [1, 0.9, 0.9, 1, 1],
        opacity: [1, 0.48, 0.48, 1, 1],
      }}
      transition={{
        duration: 3,
        ease: 'easeInOut',
        repeatDelay: 1,
        repeat: Infinity,
      }}
    >
      <StageSpinner color={theme.palette.secondary.main} />
    </Box>
  );

  const InputFieldClaim = () => (
    <FormProvider methods={form} onSubmit={form.handleSubmit(onSubmitData)}>
      <Typography
        fontSize={isDesktop ? '20px' : '12px'}
        fontWeight="700"
        color="#FFF"
        mb={1}
      >
        {translate('claim_your_free_reward')}
      </Typography>
      <Typography
        fontSize={isDesktop ? '16px' : '12px'}
        fontWeight="500"
        mb={1}
        whiteSpace="normal"
      >
        {translate(UseYourGiftToBet())}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="end" gap={2}>
        <Box width="65%">
          <RHFTextField
            name="giftcode"
            fullWidth
            placeholder={translate('enter_your_giftcode')}
            sx={{
              '& input::placeholder': {
                [theme.breakpoints.down(700)]: {
                  fontSize: isDesktop ? '20px' : '12px !important',
                  display: 'contents',
                },
              },
            }}
            InputProps={{
              sx: {
                height: isDesktop ? '48px' : '36px',
                backgroundColor: '#262936 !important',
              },
            }}
          />
        </Box>

        <Box minWidth="35%" maxHeight="100%">
          <Button
            variant="contained"
            sx={{
              backgroundColor: PRIMARY_PURPLE_COLOR,
              width: '100%',
              maxHeight: 'unset !important',
              height: isDesktop ? '48px' : '36px',
              ':hover': {
                backgroundColor: loadingGiftCode
                  ? `${theme.palette.background.disable}`
                  : `${PRIMARY_PURPLE_COLOR} !important `,
                opacity: 0.8,
              },
            }}
            type="submit"
            disabled={loadingGiftCode}
          >
            <Typography
              fontSize={isDesktop ? '20px' : '12px !important'}
              fontWeight={600}
              display="contents"
            >
              {loadingGiftCode ? (
                <HandleLoadingGiftCode />
              ) : (
                translate('claim_bonus')
              )}
            </Typography>
          </Button>
        </Box>
      </Box>

      <ErrorMessage
        name="giftcode"
        errors={form?.formState?.errors}
        render={({ message }) => (
          <ValidationMessage mt={1} ml={0} message={message} />
        )}
      />

      <Button
        startIcon={<IconQuestionMessage />}
        sx={{
          color: '#53BA0C',
          [theme.breakpoints.down(700)]: {
            fontSize: isDesktop ? '20px' : '12px !important',
          },
          fontWeight: '500 !important',
          mt: 2,
          ':hover': {
            textDecoration: 'underline',
            textDecorationColor: '#53BA0C',
          },
        }}
        variant="text"
        onClick={() => setOpenExplainGiftcode(true)}
      >
        {translate('what_is_giftcode')}
      </Button>
    </FormProvider>
  );

  const WhatIsGiftCode = () => (
    <DialogAnimate
      open={openExplainGiftCode}
      onClose={() => setOpenExplainGiftcode(false)}
      sx={{
        background: BACKGROUND_MAIN_COLOR,
        overflow: 'hidden',
        width: 633,
      }}
    >
      <DialogHeader hasBackBtn hasCloseBtn>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          px={theme.spacing(1)}
          pt={theme.spacing(3)}
        >
          <IconButton onClick={() => setOpenExplainGiftcode(false)}>
            <ArrowBackIosNewRoundedIcon fontSize="small" />
          </IconButton>
          <Typography
            className="dialog-title"
            variant="h6"
            sx={{ lineHeight: { xs: 1.8, sm: 'inherit' } }}
          >
            {translate('what_is_HOST_giftcode')}
          </Typography>
        </Box>
      </DialogHeader>

      <DialogBody>
        {objDetail.map((value) => (
          <Box key={value.index}>
            <Typography
              color="#FFF"
              fontWeight="700"
              fontSize={isDesktop ? '16px' : '14px'}
            >
              {translate(value.title)}
            </Typography>
            <Typography
              fontSize={isDesktop ? '14px' : '12px'}
              fontWeight="400"
              whiteSpace="normal"
              mt={1}
              sx={{
                '&:first-letter': {
                  textTransform: 'unset',
                },
              }}
            >
              {value.content}
            </Typography>
          </Box>
        ))}
        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: '#813FFA',
            ':hover': {
              backgroundColor: '#813FFA',
              opacity: 0.8,
            },
          }}
          onClick={() => setOpenExplainGiftcode(false)}
        >
          {translate('got_it')}
        </Button>
      </DialogBody>
    </DialogAnimate>
  );

  return (
    <DialogAnimate
      open={true}
      onClose={() => {
        handleCloseDialog();
      }}
      sx={{
        background: BACKGROUND_MAIN_COLOR,
        overflow: 'hidden',
        width: isOpenSuccess ? 430 : 633,
      }}
    >
      <DialogHeader
        hasCloseBtn
        className="popup-gift-code-header"
        sx={{
          px: theme.spacing(3),
          pt: theme.spacing(3),
        }}
      >
        <Typography
          className="dialog-title"
          variant="h6"
          sx={{ lineHeight: { xs: 1.8, sm: 'inherit' } }}
        >
          {translate('gift_code')}
        </Typography>
        <CloseBox
          key="close-btn-transaction"
          onClick={() => {
            handleCloseDialog();
          }}
        >
          <IconButton className="close-btn">
            <CloseRounded fontSize="small" />
          </IconButton>
        </CloseBox>
      </DialogHeader>

      <DialogBody className="popup-gift-code-body">
        {isOpenSuccess ? (
          <HandleSuccessGiftCode />
        ) : (
          <>
            {InputFieldClaim()}
            {WhatIsGiftCode()}
          </>
        )}
      </DialogBody>
    </DialogAnimate>
  );
}
