import { NavSectionMini as V2 } from 'components/nav-section';
import { NAV } from 'config/config-global';
import { HouseGame, useGamesQuery } from 'graph';
import { useEffect, useState } from 'react';
import { hideScrollbarX } from 'utils/cssStyles';

import { Box, Stack } from '@mui/material';

import { getFlatConfigNavWithGames } from './config-navigation';

// ----------------------------------------------------------------------

export default function NavMini({ isNavOpen, handleToggleNav }) {
  const { data: gamesData } = useGamesQuery({
    variables: {
      page: 1,
      pageSize: 100,
    },
  });
  const [navConfig, setNavConfig] = useState(null);

  useEffect(() => {
    if (gamesData?.games?.data) {
      setNavConfig(
        getFlatConfigNavWithGames(gamesData?.games?.data as HouseGame[]),
      );
    }
  }, [gamesData?.games?.data]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: NAV.W_DASHBOARD_MINI,
      }}
    >
      <Stack
        sx={{
          pt: 1.5,
          height: 'calc(100vh - 64px)',
          position: 'fixed',
          top: '64px',
          bgcolor: 'background.secondary',
          boxShadow:
            '0px 7px 0px -4px rgba(0, 0, 0, 0.2), 0px 12px 0px 2px rgba(0, 0, 0, 0.14), 0px 5px 0px 4px rgba(0, 0, 0, 0.12);',
          zIndex: 99,
          ...hideScrollbarX,
        }}
      >
        <V2
          data={navConfig}
          isNavOpen={isNavOpen}
          handleToggleNav={handleToggleNav}
        />
      </Stack>
    </Box>
  );
}
