import { vipLevelMask } from 'assets/images/vip_clubs';

import { Box, styled } from '@mui/material';

export const LevelsSliderWrapper = styled(Box)(({ theme }) => ({
  '.swiper': {
    position: 'static',
  },
  '.swiper-pagination': {
    bottom: '0px !important',
    [theme.breakpoints.down('sm')]: {
      bottom: '-12px !important',
    },

    '.swiper-pagination-bullet': {
      width: '18px',
      height: '6px',
      borderRadius: '4px',
    },
    '.swiper-pagination-bullet-active': {
      background: 'white',
    },
  },
}));

export const VipLevelCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  border: '1px solid rgba(85, 85, 147, 0.5)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 1.75),
  background: `url(${vipLevelMask})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '200%',
  backgroundPosition: '-65px 0px',
  height: '100%',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    minWidth: '100px',
    minHeight: '100px',
    background:
      'linear-gradient(180deg, #555593 0%, #313064 21.16%, rgba(49, 48, 100, 0.00) 73.14%)',
    borderRadius: theme.spacing(1.5, 1.5, 0, 0),
    zIndex: -1,
    top: 0,
  },

  '&& .vip-rank-title': {
    fontSize: 16,
  },

  '.vip-level-card-image': {
    width: '90px',
    height: '90px',
  },

  '&.active-rank': {
    border: '1px solid #FFE500',
  },
}));

export const LevelLabel = styled(Box)(({ theme }) => ({
  background: '#2F2B60',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0, 1.5),
  fontSize: '14px',
  fontWeight: 700,
}));
