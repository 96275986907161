import { useReactiveVar } from '@apollo/client';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import DialogAnimate from 'components/animate/DialogAnimate';
import SearchBar from 'components/AutocompleteField';
import {
  DialogBody,
  DiaLogFooter,
  DialogHeader,
  DialogWrapper,
} from 'components/Dialog/styles';
import { Currency, TransfiFiat, useUserWalletsQuery } from 'graph';
import { useLocales } from 'locales';
import lodash from 'lodash';
import React from 'react';
import { hideSmallWalletAmountVar, viewInFiatVar } from 'store';
import { currencyLocalStateOperations } from 'store/operations';

import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AssetItem from './AssetItem';
import {
  AssetListScroll,
  AssetsPortfolioWrapper,
  ViewInFiatBox,
} from './styles';

// ------------------------------------------------------------

interface AssetsPortfolioDialogProps {
  title?: string;
  isOpen: boolean;
  handleClose: () => void;
  handleBack: () => void;
  currencies: Currency[] | TransfiFiat[];
  selectedCurrency: Currency | TransfiFiat;
  handleSelectCurrency: (currency: any) => void;
  hiddenFooter?: boolean;
  hiddenBalance?: boolean;
}

const AssetsPortfolioDialog = ({
  title,
  isOpen,
  handleClose,
  handleBack,
  currencies,
  selectedCurrency,
  handleSelectCurrency,
  hiddenFooter = false,
  hiddenBalance = false,
}: AssetsPortfolioDialogProps) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const [searchString, setSearchString] = React.useState('');
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { hideSmallWalletAmount } = useReactiveVar(hideSmallWalletAmountVar);
  const { setViewInFiat, setHideSmallWalletAmount } =
    currencyLocalStateOperations;

  const { data: userWallets } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });

  const onSearchCurrencyChange = lodash.debounce((searchStr) => {
    setSearchString(searchStr);
  }, 300);

  const filteredCurrencies = React.useMemo(() => {
    const walletCurrencies = userWallets?.me?.userWallets
      ?.filter((userWallet) => userWallet.amount > 0)
      .map((userWallet) =>
        (currencies as any[]).find(
          (currency: Currency) => currency?.id === userWallet.currencyId,
        ),
      );
    const currenciesClone = hideSmallWalletAmount
      ? walletCurrencies
      : currencies;

    return (currenciesClone || []).filter((currency: any) => {
      if (!currency) return false;

      const lowercaseName =
        currency?.name?.toLowerCase() || currency?.symbol?.toLowerCase();

      const lowercaseShortname =
        currency?.shortName?.toLowerCase() || currency?.symbol?.toLowerCase();

      const trimLowercaseSearchString = searchString.trim().toLowerCase();

      return (
        lowercaseName.includes(trimLowercaseSearchString) ||
        lowercaseShortname.includes(trimLowercaseSearchString)
      );
    });
  }, [
    userWallets?.me?.userWallets,
    hideSmallWalletAmount,
    currencies,
    searchString,
  ]);

  // const handleSelectAssetTab = (asset: any) => {
  //   if (asset === ASSETS_TABS.CRYPTO) {
  //     setSelectedAsset(asset);
  //   }
  // };

  return (
    <DialogAnimate
      open={isOpen}
      onClose={handleClose}
      sx={{
        width: 400,
        justifyContent: 'center',
        '&': {
          maxHeight: 850,
        },
        [theme.breakpoints.down(600)]: {
          maxHeight: 'unset',
          maxWidth: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
        '& #animated-paper': {
          background: 'rgba(0,0,0, 0.2)',
        },
      }}
    >
      <DialogWrapper
        sx={{ background: (theme) => theme.palette.background.main }}
      >
        <DialogHeader
          hasBackBtn
          hasCloseBtn={false}
          sx={{
            padding: 1.5,
          }}
        >
          <Box onClick={handleBack} sx={{ visibility: 'hidden' }}>
            <IconButton>
              <CloseCircle width="24px" height="24px" />
            </IconButton>
          </Box>
          <Typography className="dialog-title" variant="h6">
            {title || translate('assets_portfolio')}
          </Typography>
          <Box onClick={handleBack}>
            <IconButton>
              <CloseCircle width="24px" height="24px" />
            </IconButton>
          </Box>
        </DialogHeader>

        <DialogBody sx={{ padding: '0 12px' }}>
          <AssetsPortfolioWrapper>
            <Box className="search_bar">
              <SearchBar
                className="search_bar_content"
                placeholder={`${translate('search_by_name')}`}
                $shouldHaveSearchBar
                options={[]}
                onSelect={() => {}}
                $onSearchBarInputChange={onSearchCurrencyChange}
                $isContrastBackground
                sx={{
                  background: theme.palette.background.secondary,
                }}
              />
            </Box>

            <AssetListScroll>
              {filteredCurrencies.map((currency: any) => (
                <AssetItem
                  key={currency.name}
                  isActive={
                    (selectedCurrency as Currency)?.id === currency.id ||
                    (selectedCurrency as TransfiFiat)?.symbol ===
                      currency.symbol
                  }
                  currency={currency}
                  handleSelectCurrency={() => {
                    handleSelectCurrency(currency);
                    handleBack();
                  }}
                  hiddenBalance={hiddenBalance}
                />
              ))}

              {!filteredCurrencies.length && (
                <Box display="flex" justifyContent="center">
                  <Typography>{`${translate('no_found_currency')}`}</Typography>
                </Box>
              )}
            </AssetListScroll>
          </AssetsPortfolioWrapper>
        </DialogBody>

        {!hiddenFooter && (
          <DiaLogFooter>
            <ViewInFiatBox>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewInFiat.status}
                    onChange={(_, checked) => {
                      setViewInFiat({
                        status: checked,
                      });
                    }}
                  />
                }
                label={`${translate('view_in_fiat')}`}
              />
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hideSmallWalletAmount}
                      onChange={(_, checked) => {
                        setHideSmallWalletAmount(checked);
                      }}
                    />
                  }
                  label={translate('hide_small')}
                />
              </Box>
            </ViewInFiatBox>
          </DiaLogFooter>
        )}
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default AssetsPortfolioDialog;
