export default function IconButton() {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 12,
          '&&:hover': {
            backgroundColor: 'transparent',
          },
          '&&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'inherit',
          },
        },
      },
    },
  };
}
