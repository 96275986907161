// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY

// ----------------------------------------------------------------------

const kr = {
  demo: {
    title: `Korean`,
  },
  blog: `블로그`,
  chat: `채팅`,
  general: `일반적인`,
  only_admin_can_see_this_item: `관리자만 이 항목을 볼 수 있습니다`,
  crypto: '전자화폐',
  // Home page
  dark: '어두운',
  light: '빛',
  Fairness: '공평',
  online: '온라인',
  welcome_bonus: '환영 보너스',
  join_now: '지금 가입하세요',
  // BillTransactionsTable
  type: '유형',
  time: '시간',
  amount: '양',
  sign_in: '로그인',
  login: '로그인',
  sign_up: '가입하기',
  password: '비밀번호',
  reset_password: '암호를 재설정',
  enter_password_here: '여기에 비밀번호를 입력하세요',
  forgot_your_password: '비밀번호를 잊어 버렸습니까',
  confirm_accessing_site:
    '사이트에 접속함으로써 나는 18세이며 다음 사항을 읽었음을 확인합니다',
  terms_of_service: '서비스 약관',
  dont_have_account: '계정이 없나요?',
  or_log_in_with: '또는 다음으로 로그인하세요.',
  validate_invalid_email_address: '유효한 이메일 주소를 입력하세요',
  request_reset_email: '요청이 전송되었습니다. 이메일을 확인하세요.',
  send_recover_link_to_email:
    '복구 비밀번호 링크가 포함된 이메일이 귀하의 이메일로 전송됩니다.',
  recover_password: '비밀번호 복구',
  new_password: '새 비밀번호',
  enter_new_password: '새 비밀번호를 입력하십시오',
  include_lower_uppercase: '소문자와 대문자 포함',
  at_least_1_number: '숫자 1개 이상',
  minimum_6_char: '최소 6자',
  agree_terms_service: '나는 동의하고 이해합니다.',
  terms_conditions: '이용 약관.',
  confirm_password: '새 암호를 확인합니다',
  enter_repeat_password: '비밀번호를 반복 입력하세요',
  already_have_account: '이미 계정이 있습니다',
  'password not match': '비밀번호가 일치하지 않습니다',
  existing_password_not_match: '기존 비밀번호가 일치하지 않습니다.',
  new_password_saved: '새 비밀번호가 저장되었습니다',
  profile_settings: '프로필 설정',
  coming_soon2: '곧 2',
  under_development: '해당 기능은 개발 중입니다.',
  page_not_found: '죄송합니다. 페이지를 찾을 수 없습니다.',
  check_your_spelling:
    '죄송합니다. 원하시는 페이지를 찾을 수 없습니다. 아마도 URL을 잘못 입력하셨나요? 맞춤법을 꼭 확인하세요.',
  go_to_home: '집에 가다',
  active_devices: '활성 장치',
  device: '장치',
  ip_address: 'IP 주소',
  last_used: '마지막으로 사용한',
  action: '행동',
  // General
  validate_nickname_length: '닉네임은 4~13자 사이여야 합니다.',
  validate_nickname_message:
    '닉네임은 4~13자 사이여야 하며 부호가 없고 공백, 기호를 포함할 수 없습니다.',
  copied: '복사됨',
  update_avatar_success: '아바타 업데이트 성공',
  verify_email_was_send:
    '이메일이 전송되었는지 확인하세요. 이메일을 확인하세요.',
  something_wrong: '문제가 발생했습니다. 다시 시도해 주세요',
  new_nick_name_saved: '새 닉네임이 저장되었습니다',
  new_email_saved: '새 이메일이 저장되었습니다',
  new_phone_number_save: '새 전화번호 저장',
  invalid_phone_number: '유효하지 않은 전화 번호',
  update_nickname_failed:
    '닉네임을 업데이트하지 못했습니다. 다시 시도해 주세요.',
  update_avatar_failed: '아바타 업데이트 실패',
  nickname: '별명',
  maximumFileSize: '최대 파일 크기는 4MB입니다.',
  your_profile: '당신의 프로필',
  is_hidden: '숨겨져 있었다',
  is_public_now: '지금은 공개야',
  hide_profile_failed: '프로필을 숨기지 못했습니다. 다시 시도해 주세요.',
  enter_nickname: '여기에 닉네임을 입력하세요',
  confirm: '확인하다',
  nickname_existing: '닉네임이 이미 존재합니다.',
  not_use_symbol:
    '특수 기호를 사용하지 마십시오. 그렇지 않으면 귀하의 계정이 지원되지 않을 수 있습니다.',
  hide_profile_title: '프로필에서 게임 데이터 숨기기',
  hide_profile_content:
    '숨겨지더라도 아바타와 사용자 이름은 항상 채팅방에 표시됩니다.',
  hide_profile: '프로필 숨기기',
  verify: '확인하다',
  verified: '검증됨',
  enter_email: '여기에 이메일을 입력하세요',
  registered_mail_cannot_changed:
    'Ohplay.club의 보안정책에 따라 등록된 이메일은 변경할 수 없습니다.',
  send_information_to_email: '등록하신 이메일 주소로 정보를 보내드립니다.',
  cancel: '취소',
  verify_email: '이메일 확인',
  verify_phone_number: '전화번호 확인',
  resend_code: '코드 재전송',
  did_not_receive_code: '코드를 받지 못하셨나요?',
  // Security
  security: '보안',
  change_password: '비밀번호 변경',
  current_password: '현재 비밀번호',
  enter_current_pw: '현재 비밀번호를 입력하세요',
  password_not_match: '비밀번호가 일치하지 않습니다',
  relogin_after_change_pw: '비밀번호 변경 후 재로그인이 필요합니다.',
  setup_google_auth: 'Google OTP 설정',
  download_google_auth: 'iPhone 또는 Android용 Google OTP 앱을 다운로드하세요.',
  save_account_token: '다음 계정 토큰(키)을 안전한 곳에 저장하세요.',
  account_token: '계정 토큰(키)',
  copy: '복사',
  need_account_token:
    '휴대폰을 분실한 경우 계정에 액세스하려면 위의 계정 토큰(키)이 필요합니다.',
  scan_qr_title: 'qr 제목 스캔',
  enter_token_below: '아래 토큰을 입력하세요',
  verify_code_incorrect: '코드가 잘못된지 확인하세요',
  two_FA_code: '2FA 코드',
  appear_on_google_auth: 'Google OTP 앱에 표시됩니다.',
  enable_2fa: '2FA 활성화',
  enter_2fa_code_to_disable:
    '2단계 인증을 비활성화하려면 2FA 코드를 입력하세요.',
  disable_2fa: '2FA 비활성화',
  // Verify
  this_field_is_required: '이 필드는 필수입니다',
  invalid_date_format: '잘못된 날짜 형식',
  at_least_18_years_old: '최소 18세 이상이어야 합니다.',
  step1_update_identification_success: '1단계: 신분증 업데이트 성공',
  step23_update_identification_photo_success:
    '2, 3단계: 신분증 사진 업데이트 성공',
  all_information_submitted: '모든 정보가 제출되었습니다.',
  thank_you_for_cooperation:
    '도와 주셔서 감사합니다. 검토가 완료되면 알려드리겠습니다.',
  verification: '확인',
  done: '완료',
  step: '단계',
  back: '뒤쪽에',
  next: '다음',
  account_verified: '귀하의 계정이 확인되었습니다',
  verify_setup: '설정 확인',
  verify_setup_description1:
    '더 나은 서비스를 제공하기 위해 원본 식별 문서를 제공해 주시기 바랍니다. 이렇게 하면 계정 복구 시 계정이 보호됩니다. 또한 귀하가 받은 선물이나 실제 보상이 올바른 위치로 전송되는지 확인하는 데 도움이 됩니다.',
  verify_setup_description2:
    '허용되는 신분증에는 정부 사진, 승인된 신분증, 운전면허증 및 여권이 포함됩니다. 이 과정이 완료되면 이메일을 통해 연락드리겠습니다.',
  // Verefy => GeneralInfoUploading
  male: '남성',
  female: '여성',
  unspecified: '불특정',
  first_name: '이름',
  enter_your_first_name: '이름을 입력하세요',
  last_name: '성',
  enter_your_last_name: '성을 입력하세요',
  gender: '성별',
  select_gender: '성별을 선택하세요',
  date_of_birth: '생일',
  address: '주소',
  city: '도시',
  postal_code: '우편 번호',
  country: '국가',
  // Verefy => GovernmentIdUploading
  verify_government_id_description:
    '정부가 발행한 유효한 신분증이나 운전면허증을 업로드하세요. 이름, 생년월일, 사진 및 서류 만료일이 포함되어야 합니다.',
  front_side: '정면',
  id_card: '신분증',
  back_side: '후면',
  passport: '여권',
  // Verefy => PortraitUploading
  verify_portrait_description:
    '본인의 사진을 업로드해주세요. 사진에는 여권이나 신분증, "Ohplay.club", 사용자 이름, 날짜가 적힌 종이가 있어야 합니다. 귀하의 얼굴과 귀하가 보유하고 있는 정보가 깨끗한지 확인하십시오.',
  your_photo: '당신의 사진',
  game: '게임',
  // Footer
  game_hub: '게임 허브',
  casino_home: '카지노 홈',
  promotions: '홍보',
  vip_clubs: 'VIP 클럽',
  promotion: '홍보',
  vip_club: 'VIP 클럽',
  coco_vip_club: 'Ohplay VIP 클럽',
  to_coco_vip_club: '코코 VIP클럽으로',
  welcome_vip_club_text:
    '가장 오래 플레이하고 가장 많이 베팅한 플레이어는 VIP 클럽에 독점 초대를 받게 됩니다. 우리는 회원들에게 프리미엄 경험과 보너스를 제공합니다.',
  coming_soon: '곧 온다',
  refer_friend: '친구 추천',
  support_legal: '법률 지원',
  responsible_gambling: '책임감 있는 도박',
  res_gambling: '책임 있는 도박',
  about_us: '우리에 대해',
  news: '소식',
  business_contact: '비즈니스 연락처',
  work_with_us: '우리와 함께 일하세요',
  verify_representative: '대표자 확인',
  follow_us_on: '우리를 따라 오세요',
  go_to_top: '맨 위로 이동',
  wallet: '지갑',
  transactions: '업무',
  logout: '로그 아웃',
  search_your_game: '게임 검색',
  random: '무작위의',
  need_help: '도움이 필요하다',
  // WalletDialog
  deposit: '보증금',
  withdraw: '철회하다',
  swap: '교환',
  verify_account: '계정 확인',
  to_secure_assets: '자산을 안전하게 보호하려면',
  assets_portfolio: '자산 포트폴리오',
  search_by_name: '이름으로 검색',
  common_assets: '공동자산',
  currency_not_available: '사용할 수 없는 통화',
  view_in_fiat: '법정화폐로 보기',
  hide_small: '작게 숨기다',
  // Deposit
  overview: '개요',
  history: '역사',
  sound: '소리',
  deposit_currency: '예금 통화',
  choose_network: '네트워크 선택',
  deposit_address: '입금 주소',
  notice: '알아채다',
  notice_content:
    '이 입금 주소로 /currency/ 보내세요. 스마트 계약 주소는 지원되지 않습니다(문의).',
  // SelectCurrency
  balance: '균형',
  // Swap
  from: '~에서',
  to: '에게',
  swap_fee: '스왑 수수료',
  insufficient_ballance: '잔액 부족',
  // AccountNotVerified
  account_not_verified: '계정이 확인되지 않았습니다.',
  drawing_your_balance: '잔액을 인출하기 전에 먼저 계좌를 확인해야 합니다.',
  // ConfirmWithdrawDialog
  balance_in_wallet: '지갑의 잔액',
  withdraw_fee: '인출 수수료',
  receive_amount: '받는 금액',
  // Withdraw
  choose_asset: '자산 선택',
  withdraw_address: '출금 주소',
  deposit_address_placeholder: '특정 통화에 따라 신중하게 입력하세요.',
  withdraw_amount: '인출금액',
  fee: '요금',
  // UploadAvatar
  update_photo: '사진 업데이트',
  upload_photo: '사진 업로드',
  // Upload
  drop_or_select_file: '파일 삭제 또는 선택',
  drop_files_here_or_click: '여기에 파일을 놓거나 클릭하세요.',
  browse: '검색',
  thorough_your_machine: '당신의 기계를 철저하게',
  upload_files: '파일 업로드하다',
  remove_all: '모두 제거',
  empty_data: '빈 데이터',
  // TransactionsDialog
  success: '성공',
  processing: '처리',
  audit: '심사',
  insufficient: '불충분하다',
  new: '새로운',
  status: '상태',
  detail: '세부 사항',
  view: '보다',
  order_id: '주문 아이디',
  currency: '통화',
  quantity: '수량',
  all_feature: '모든 기능',
  all: '모두',
  all_currency: '모든 통화',
  Settings: '설정',
  // SearchNotFound
  not_found: '찾을 수 ',
  no_results_found_for: '에 대한 결과가 없습',
  checking_for_typos: '오타가 있는지 확인하거나 완전한 단어를 사용해 보세',
  please_enter_keywords: '키워드를 입력해주',
  please_switch_to_another_asset_to_continue_playing:
    '계속 플레이하려면 다른 자산으로 전환하세요.',
  // LiveStats
  live_stats: '실시간 통계',
  total_bet_amount: '총 베팅 금액',
  total_profit: '총 보상',
  // Game Bank
  gamebank: '게임뱅크',
  more_information: '추가 정보',
  // Overview
  invest: '투자하다',
  Withdraw: '철회하다',
  // Game Mines
  gems: '보석',
  // Game MiniPoker
  auto_select: '자동 선택',
  number_of_spin: '스핀 수',
  advanced_auto: '고급 자동',
  stop_if_anyone_win_jackpot: '누구든지 잭팟에 당첨되면 중지하세요.',
  stop_decrease_by: '그만 감소',
  stop_increase_by: '증가를 중지',
  stop_exceeds_by: '정지가 다음을 초과함',
  // Bet Control
  cashout_at: '현금 인출',
  cashout: '현금 인출',
  win_amount: '승리 금액',
  // Bet Detail
  bet_id: '내기 ID',
  by: '~에 의해: ',
  hidden: '숨겨진',
  on: '~에',
  bet_amount: '플레이 금액',
  multiplier: '승수',
  payout: '지불금',
  play_now: '지금 플레이',
  loading: '로드 중...',
  bet: '놀다',
  waiting: '대기 중...',
  bet_next_round: '다음 라운드에 베팅',
  // ContinueAutoControl
  increase_by: '通過增加',
  reset: '重置',
  manual: '手動的',
  auto: '汽車',
  max_profit: '최대 이익',
  max_bet: '최대 베팅',
  on_loss: '損失時',
  on_win: '勝利時',
  stop_on_lose: '止損',
  stop_on_win: '贏了就停止',
  trends: '趨勢',
  all_bet: '모든 헌신',
  my_bet: '나의 연극',
  my_bets: '나의 약속',
  high_bets: '높은 헌신',
  winner: '우승자',
  jackpot_winner: '대박 우승자',
  big_winner: '큰 승자',
  play_real: '진짜 플레이',
  play_with_fun: '재미있게 놀다',
  // RoleBasedGuard
  permission_denied: '허가가 거부되었습니다',
  you_not_permission_page: '이 페이지에 액세스할 수 있는 권한이 없습니다.',
  min: '분',
  max: '최대',
  max2: '최대',
  set_auto_bet: '자동 베팅 설정',
  cancel_auto_bet: '자동 베팅 취소',
  number_of_bet: '플레이 횟수',
  check_information_carefully: '투자정보를 꼼꼼히 확인하신 후 확인해주세요.',
  invest_now: '지금 투자하세요',
  host: '주인',
  you_must_specify_a_number: '숫자를 지정해야 합니다',
  must_be_a_positive_value: '양수 값이어야 합니다.',
  withdraw_success: '성공을 철회하다',
  withdraw_failed: '철회 실패',
  your_total_invest: '당신의 총 투자',
  your_current_shares: '현재 공유',
  your_balance: '당신의 잔액',
  your_profit: '당신의 이익',
  game_bank: '게임 뱅크',
  game_bank_max: '게임 뱅크 맥스',
  your_invest: '당신의 투자',
  your_share: '당신의 몫n',
  host_share: '호스트 공유',
  invest_success: '투자 성공',
  invest_failed: '투자 실패',
  all_bets: '모든 헌신',
  high_rollers: '하이 롤러',
  jackpot_winners: '대박 우승자',
  jackpot_winner2: '대박',
  player: '플레이어',
  investor: '투자자',
  share: '공유하다',
  game_bank_overview: '게임뱅크 개요',
  game_bank_history: '게임 뱅크 내역',
  game_bank_list: '게임 뱅크 목록',
  game_bank_withdraw: '게임뱅크 출금',
  error_message_server_error: '서버 오류입니다. 나중에 다시 시도해 주세요!',
  login_fail: '로그인에 실패했습니다. 다시 시도해 주세요.',
  successful_balance_change: '성공적인 잔액 변경',
  logout_failed: '로그아웃에 실패했습니다. 다시 시도해 주세요!',
  not_bad: '나쁘지 않다',
  nice: '멋진',
  awesome: '엄청난',
  wicked: '사악한',
  godlike: '신과 같은',
  to_the_moon: '달에',
  you_win: '네가 이겼다',
  bum: '부랑자',
  some_thing_went_wrong: '뭔가 잘못됐어',
  maximum_number: '선택한 최대 셀 수는 다음과 같습니다.',
  start_auto_spin: '자동 회전 시작',
  not_enough_balance: '잔고가 부족해',
  increase: '증가하다',
  decrease: '감소하다',
  win: '이기다',
  lose: '잃다',
  can_not_get_game_bank: '게임 뱅크를 얻을 수 없습니다',
  total: '총',
  insufficient_balance: '잔액 불충분',
  insufficient_gamebank: '이 자산은 일시적으로 지원되지 않습니다.',
  switch: '스위치',
  exit_fullscreen: '退出全屏',
  fullscreen: '全屏',
  settings: '설정',
  currency_do_not_supported: '통화는 지원되지 않습니다',
  no_found_any_currency_can_swap:
    '어떤 통화도 교환할 수 있는 것을 발견하지 못했습니다',
  withdraw_request_was_sent: '출금 요청이 전송되었으며 확인 대기 중입니다.',
  available_amount_insufficient: '사용 가능 금액이 부족함',
  page_404: '404 페이지를 찾을 수 없음 | 파이게임',
  remove_session_success: '세션 성공 제거',
  remove_session_failed: '세션 제거 실패',
  in_use: '사용',
  remove_device: '장치를 제거',
  error_code: {
    EMAIL_IN_USED: '해당 이메일은 다른 사용자가 사용했습니다.',
  },
  live_support: '실시간 지원',
  sponsorships: '후원',
  forum: '법정',
  missions: '임무',
  tournaments: '토너먼트',
  my_favorites: '내가 좋아하는 것들',
  available: '사용 가능',
  exchange_rate: '환율',
  route: '노선',
  bill: '청구서',
  view_history_in_etherscan: 'Pi.game blockexplorer에서 거래 내역 보기',
  phone_number: '전화 번호',
  change: '변화',
  phone_second_login: '두 번째 로그인 방법으로 전화번호를 추가하세요.',
  enter_phone_number_: '여기에 전화번호를 입력하세요...',
  welcome_aboard: '탑승을 환영합니다',
  first_deposit_bonus: '첫 입금 보너스',
  rewards: '보상',
  deposit_now: '지금 입금하세요',
  just_for_you: '너만을 위해서',
  top_games: '최고의 게임',
  recently_played: '최근에 재생 된',
  recommended: '추천',
  favourites: '즐겨찾기',
  chat_room: '대화방',
  error_message: {
    server_error: '서버 오류입니다. 몇 분 후에 다시 시도해 주세요.',
  },
  help_center: '지원 센터',
  faq: '자주하는 질문',
  privacy_policy: '개인 정보 정책',
  aml: '자금 세탁 방지',
  provably_fair: '확실히 공정하다',
  registration_login: '등록 및 로그인',
  coin_accuracy_limit: '동전 정확도 제한',
  support: '지원하다',
  join_our_community: '우리 커뮤니티에 가입하세요',
  game_info: '게임 정보',
  description: '서술',
  provably_fairs: '아마도 박람회',
  reviews: '리뷰',
  latest_bet_race: '최신 내기 경주',
  about_this_game: '이 게임에 대해서',
  share_this_game: '이 게임을 공유해 주세요',
  share_on_fb: '페이스북에 공유',
  share_on_whatsapp: 'WhatsApp에 공유',
  share_on_twitter: '트위터에 공유',
  share_on_discord: '불화의 공유',
  share_on_linkedin: 'linedin에서 공유',
  share_on_skype: '스카이프에 공유',
  release: '풀어 주다',
  return_to_player: '플레이어로 돌아가기',
  provider: '공급자',
  max_win: '최대 승리',
  mobile: '이동하는',
  stakes_range: '스테이크 범위',
  location: '위치',
  activities: '활동',
  jackpot: '공동 자금',
  take_a_photo: '사진을 찍다',
  upload_picture: '사진 업로드',
  information_page: '정보 페이지',
  references: '참고자료',
  confirm_password_must_match: '비밀번호 확인은 일치해야 합니다',
  no_support: '지원 없음',
  invalid_wallet_address: '잘못된 지갑 주소',
  target_multiplier: '목표 승수',
  unexpected_error:
    '예상치 못한 오류가 발생했습니다. 나중에 다시 시도 해주십시오',
  change_password_success: '비밀번호 변경 성공. 다시 로그인해주세요',
  upload_image_failed: '이미지 업로드 실패',
  file_size_limit_10MB: '파일 크기 제한 10MB',
  roll_over: '롤오버',
  win_chance: '승률',
  profit_on_next_tile: '다음 타일에서 이익을 얻으세요',
  pick_a_tile_randomly: '무작위로 타일을 선택',
  pay_table: '지불금',
  game_rule: '게임 규칙',
  number_of_bets: '놀이 횟수',
  on_lose: '패배하다',
  stop_on_profit: '이익을 멈추다',
  bet_failed: '플레이 실패',
  stop_auto_bet: '자동 재생 중지',
  start_auto_bet: '자동 베팅 시작',
  show_more: '더보기',
  show_less: '숨다',
  profit_amount: '이익 금액',
  currency_not_support: '통화는 지원되지 않습니다',
  login_to_play: '플레이하려면 로그인하세요',
  yes: '예',
  how_to_play: '게임 방법',
  help: '돕다',
  start_bet_round_failed: '플레이 라운드 시작 실패',
  cashout_failed: '현금 인출 실패',
  auto_bet_round: '커밋 성공',
  bet_success: '내기 성공',
  play_now2: '지금 플레이',
  game_not_available_now: '지금은 이용할 수 없는 게임',
  invalid_bet_params: '잘못된 재생 매개변수',
  under_min_bet: '최소 베팅 미만',
  invalid_bet_state: '잘못된 플레이 상태',
  insufficient_amount: '금액이 부족하다',
  win_rate: '승률',
  welcome_to_Coco_game: 'Ohplay.club에 오신 것을 환영합니다',
  welcome_to_only: '에 오신 것을 환영합니다',
  welcome_only: '환영',
  to_join_club: 'VIP 클럽에 가입하려면',
  sign_in_or_sign_up: '로그인 가입',
  invalid_email_format: '잘못된 이메일 형식',
  resend_after: '이후에 다시 보내기',
  second: '두번째',
  only_available_at_large_resolutions:
    '1600px(xl) 이상의 큰 해상도에서만 사용 가능',
  fairness: '공평',
  available_invest_amount: '투자 가능 금액',
  gamebank_chart: '게임뱅크 차트',
  next_round: '다음 라운드',
  understand_trend_chart: '추세 차트 이해',
  round_has_been_bet:
    '이번 라운드는 베팅되었습니다. 다음 라운드부터 취소하세요.',
  crash_server_disconnect: '충돌 서버 연결 끊김...',
  coco_info:
    '이 웹사이트는 Curaçao 법률에 따라 등록 및 설립된 회사인 Orisun N.V.(등록 번호 163631, 등록 주소 Zuikertuintjeweg Z/N(Zuikertuin Tower), Curaçao)가 소유하고 운영합니다.',
  leverage: '영향력',
  rank: '계급',
  prize: '상',
  wagers: '재생',
  crash_result: '충돌 결과',
  reset_after: '이후 재설정',
  rule: '규칙',
  club_member: '클럽 회원',
  become_a_coco: '코코가 되어 보세요',
  vip_club_subtitle1:
    '가장 오래 플레이하고 가장 열정적인 플레이어는 독점 초대를 받게 됩니다.',
  vip_club_subtitle2: '이는 회원들에게 프리미엄 경험과 보너스를 제공합니다.',
  your_vip_progress: '귀하의 VIP 진행 상황',
  your_vip_progress_tooltip: '먼저 로그인한 후 VIP 진행 상황을 확인하세요.',
  total_wager: '총 약정',
  your_vip_progress_description: '모든 약정은 현재 환율로 USD로 변환됩니다.',
  how_does_it_work: '어떻게 작동하나요?',
  how_vip_club_work: 'VIP클럽은 어떻게 운영되나요?',
  the_rewards: '보상',
  frequently_asked_questions: '자주 묻는 질문',
  vip_frequent_questions_subtitle:
    '질문이 있는 경우 지원팀이나 VIP 관리자에게 문의하시기 바랍니다.',
  to_see_your_vip_progress: 'VIP 진행 상황을 확인하려면',
  original_wager_rate: '원본 게임: 1 XP = $1 내기 * House Edge * 100 / 2',
  provider_wager_rate: '공급자 게임: 1 XP = $1 베팅',
  all_wagers_converted_to_usd: '모든 베팅은 현재 환율로 USD로 변환됩니다.',
  vip_club_benefit: 'VIP클럽 혜택',
  vip_club_details: 'VIP 클럽 세부 정보',
  benefit_table: '혜택표',
  wager_contest: '베팅 콘테스트',
  game_of_the_day: '오늘의 게임',
  play_to_earn: '베팅을 위해 플레이하세요',
  time_remaining: '남은 시간',
  wagered: '베팅됨',
  wager_contest_information: '챌린지 정보',
  daily_wagering_contest_rules: '일일 플레이 콘테스트 규칙',
  the_daily_race:
    '일일 레이스 콘테스트는 매일 UTC 00:00부터 23:59 UTC까지 진행됩니다',
  daily_contest_prize_pool: '일일 콘테스트 상금 풀',
  straight_flush_with_jack: '잭과 스트레이트 플러시',
  straight_flush: '스트레이트 플러시',
  four_of_a_kind: '포 오브 어 카인드',
  full_house: '풀 하우스',
  flush: '플러시',
  straight: '똑바로',
  three_of_a_kind: '세 가지 종류',
  tow_pair: '두 쌍',
  pair_of_jacks_or_better: '잭 쌍 이상',
  you_won: '당신이 이겼어요',
  show_all: '모두 보이기',
  notification: '공고',
  platform: '플랫폼',
  personal: '개인의',
  no_notifications_at_the_moment:
    '지금은 알림이 없습니다. 나중에 다시 확인해 주세요.',
  What_up: '잘 지내?',
  mode: '방법',
  contrast: '차이',
  direction: '방향',
  layout: '공들여 나열한 것',
  stretch: '뻗기',
  presets: '사전 설정',
  status_success: '상태 성공',
  status_pending: '보류 상태',
  swap_successfully: '교환 성공!',
  swap_failed: '교체에 실패했습니다. 나중에 시도해 주세요.',
  coco_member: 'Pi.game 회원이 되세요',
  play_your_favorite_games: '좋아하는 게임을 즐겨보세요',
  instantly_sign_up:
    '즉시 가입하고 게임이나 스포츠 북에서 플레이를 시작하세요.',
  unlock_further_benefits:
    '플레이를 즐기고, 새로운 레벨에 도달하고, 추가 혜택을 누리세요',
  rank_claim_bonus: '순위를 높이고 보너스를 청구하세요',
  go_up_to_the_next_rank:
    '다음 순위로 올라가세요! 각 레벨에는 보너스와 더 많은 기회가 있습니다!',
  ranking_system_title: '코코 VIP 랭킹 시스템',
  ranking_system_text:
    '플랫폼 순위 시스템에 참여하여 기회와 인정의 세계를 열어보세요! 지금 우리와 함께 프로필을 높이고, 게임을 플레이하고, 뛰어난 참가자가 되는 혜택을 누려보세요. 인정받을 기회를 놓치지 마세요. 지금 성공 스토리에 참여해 보세요!',
  you_start_your_gaming: '레벨 1(초보자)에서 게임 경로를 시작합니다.',
  the_more_you_play:
    '더 많이 플레이할수록 순위가 높아집니다. Ohplay.club에서 플레이하는 동안 베팅한 금액에 따라 올라갑니다. 모든 베팅은 현재 환율로 USD로 변환됩니다.',
  mark_all_as_read: '모두 읽음으로 표시',
  eligible_VIP_member: 'VIP 회원이 될 자격이 있나요?',
  how_much_has_coco_given_out_in_bonuses: 'Pi.game은 보너스로 얼마를 주었나요?',
  why_is_Coco_VIP_program_the_best:
    'Pi.game 게임의 VIP 프로그램이 왜 최고인가요?',
  what_does_affect_the_weekly_monthly:
    '주간/월간 보너스에 영향을 미치는 것은 무엇입니까?',
  information_to_become_a_VIP_member:
    'VIP 회원이 되려면 추가 정보를 제공해야 합니까?',
  coco_VIP_telegram_channel: 'Pi.game 텔레그램 채널은 어디에서 찾을 수 있나요?',
  one_min: '1 분',
  five_min: '5 분',
  one_hour: '1 시간',
  for_hours: '4 시간',
  one_day: '1 일',
  investment: '투자',
  my_investment: '나의 투자',
  what_upp: '잘 지내???',
  hide: '숨다',
  //
  vip_level_comparison: 'VIP 레벨 비교',
  bronze: '청동',
  silver: '은',
  gold: '금',
  platinum: '백금',
  diamond: '다이아몬드',
  level_up_bonus: '레벨업 보너스',
  surprise_box: '서프라이즈 박스',
  free_spin_wheel_enable: '무료 스핀 휠 활성화',
  surprise_box_upgraded: '서프라이즈 박스 업그레이드',
  weekly_bonus: '주간 보너스',
  chat_enable: '채팅 활성화',
  upload_avatar_enable: '아바타 업로드 활성화',
  vip_support_enable: 'VIP 지원 활성화',
  surprise_box_upgraded_2: '서프라이즈 박스 업그레이드 (최대 180만 USDT)',
  where_coco_vip_telegram_channel:
    'Pi.game VIP 텔레그램 채널은 어디에서 찾을 수 있나요?',
  me: '나',
  stake_and_earn: '플레이하고 적립하세요',

  // 21/09
  result: '결과',
  crush_of_crash: '크러시 오브 크래시',
  // Banner new
  earn_real_money: '실제 돈을 버세요',
  the_daily_game: '일일 게임',
  earn_everyday_with_original_games: '오리지널 게임으로 매일 수익을 올리세요',
  daily_race: '일일 레이스',
  wager: '놀다',
  and: '그리고',
  everyday: '매일',
  crush: '으깨다',
  the_crash: '충돌',
  show_off_your_prediction_skills: '당신의 예측 능력을 보여주세요',
  deposit_now2: '지금 입금하세요',
  on_1st_deposit: '1차 입금시',
  // Just for you
  free_play: '무료 플레이',
  with_fun_coin: 'FUN 코인으로',
  show_me: '보여줘',
  low_risk_high_rewards: '낮은 위험, 높은 보상',
  prize_100000: '상금 $100,000',
  win_1000x_jackpot: '1000x 잭팟 승리',
  // Notification
  balance_change: '밸런스 변경',
  your_casino: '당신의 게임 허브',
  // Referral
  spread_the_word_and: '세계를 전파하고',
  earn_reward: '보상 받기',
  campaign_name: '캠페인 이름',
  start_your_referrals: '추천을 시작하세요',
  id_number: 'ID 번호',
  registered: '등기',
  total_deposits: '총 예금',
  last_deposit: '마지막 입금',
  vip_point: 'VIP 포인트',
  commission: '수수료',
  referral: '추천',
  register_date: '등록 날짜',
  display_name: '이름 표시하기',
  agents: '자치령 대표',
  transaction_date: '거래 날짜',
  transaction_type: '거래 유형',
  export: '내보내다',
  campaigns: '캠페인',
  create_a_new_campaign: '새 캠페인 만들기',
  claim_income: '소득 청구',
  per_players: '플레이어당',
  per_agents: '에이전트당',
  per_day: '하루당',
  per_week: '한 주에',
  per_month: '달마다',
  per_country: '국가별',
  date: '날짜',
  total_income: '총 보상',
  total_user_register: '전체 사용자 등록',
  total_user_bet: '총 유저 플레이',
  total_transaction: '총 거래',
  refer_description:
    '친구에게 Ohplay.club 제휴 프로그램을 추천하고 링크를 통해 등록하고 플레이하는 모든 사용자에 대해 결과에 관계없이 커미션을 받으세요.',
  download_banner: '배너 다운로드',
  search: '찾다',
  clicks: '클릭수',
  referrals: '추천',
  deposit_users: '예금 사용자',
  total_commission: '총 커미션',
  commission_rate: '수수료율',
  features: '특징',
  link: '링크',
  code: '암호',
  today: '오늘',
  this_week: '이번 주',
  last_week: '지난주',
  overall: '전반적인',
  commission_available: '커미션 가능',
  claim: '주장하다',
  log_in: '로그인',
  to_see_your_rank: '당신의 순위를 보려면',
  start_in: '에서 시작 ...',
  reconnect: '다시 연결',
  controls: '통제 수단',
  leaderboard: '리더보드',
  bet_next: '다음 재생',
  betting: '놀다',
  // Tip
  message_is_required: '메시지가 필요합니다',
  tip_with_any_currency_in_CoCo: 'Ohplay 모든 통화에 대한 팁',
  receiver_id: '수신자 ID',
  enter_user_id: '사용자 ID를 입력하세요',
  transaction_fee: '거래 수수료',
  message: '메시지',
  give_your_friends_some_messages_here:
    '여기에서 친구들에게 메시지를 보내보세요...',
  continue: '계속하다',
  successfully_tipped: '성공적으로 팁을 받았습니다.',
  you_are_tipping_to: '당신은 팁을 주고 있다',
  your_tip_request_has_been_confirmed: '귀하의 팁 요청이 확인되었습니다.',
  you_can_track_the_progress_on_the_wallet_transaction_history:
    '월렛 거래 내역에서 진행 상황을 확인할 수 있습니다.',
  view_history: '기록 보기',
  tip_failed: '팁 실패',
  your_tip_request_was_unsuccessful_due_to_an_abnormality:
    '이상으로 인해 팁 요청이 실패했습니다.',
  please_try_again_later: '나중에 다시 시도 해주십시오.',
  ok_i_got_it: '알겠습니다. 알겠습니다',
  tip_information: '팁 정보',
  password_is_required: '비밀번호가 필요합니다',
  tip_verification: '팁 확인',
  authentication_secure: '인증 보안',
  to_process_tipping_please: '팁을 처리하려면',
  first_to_secure_your_assets: '자산을 안전하게 보호하는 것이 먼저',
  step_verification: '22단계 인증',
  enter_your_login_password: '로그인 비밀번호를 입력하세요',
  enter_the_2fa_code_appear_on_your_google_authenticator_app:
    'Google OTP 앱에 표시되는 2FA 코드를 입력하세요.',
  authorize: '승인하다',
  // 30/09
  sign_up_in_and_earn: '가입 / 로그인 및 적립',
  learn_more_about_our_affiliate_program:
    '제휴 프로그램에 대해 자세히 알아보세요',
  referral_link: '관련 링크',
  create: '만들다',
  referral_users: '추천 사용자',
  registered_date: '등록일',
  send_tip: '팁 보내기',
  delete: '삭제',
  accept: '수용하다',
  close: '닫다',
  member_downline: '회원 다운라인',
  new_player: '새로운 플레이어',
  claim_commission_failed: '청구 수수료 실패',
  claim_commission_successfully: '커미션 청구 성공',
  create_campaign_successfully: '캠페인이 성공적으로 생성되었습니다.',
  create_campaign_failed: '캠페인 생성 실패',
  any_date: '모든 날짜',
  // Mini CoCo
  stop_if_single_win_exceeds_by: '단일 승리가 다음을 초과하면 중지',
  // Host
  show_filter: '필터 표시',
  filter: '필터',
  default_campaign: '기본 캠페인',
  select_all: '모두 선택l',
  role: '역할',
  hello_world: '안녕하세요 세상',
  // GameBank => Overview
  gb_capacity: 'GB 용량',
  game_bank_capacity: '게임뱅크 용량',
  the_maximum_amount_that_you_can_invest: '투자할 수 있는 최대 금액입니다.',
  // Crash
  finish_bet: '베팅 완료',
  finishing_bet: '마무리 베팅',
  // Referral
  delete_invitation: '초대 삭제',
  you_had_been_invited_to_become_an_agent_by:
    '귀하는 다음의 대리인이 되도록 초대받았습니다.',
  confirming_to_delete_this_invitation:
    '이 초대 삭제를 확인하면 이 작업은 취소되지 않습니다. 아직도 결정하고 싶나요?',
  successfully_accepted_invitation: '귀하는 성공적으로 초대를 수락했습니다.',
  the_house: '집 ',
  invited_you_to_become_an_agent: '당신을 에이전트로 초대했습니다',
  campaign_name_1: '캠페인 이름 1',
  available_commission: '사용 가능한 커미션',
  there_are_no_commission: '수수료가 없습니다',
  please: '제발',
  login2: '로그인',
  to_see_your_rewards: '보상을 확인하려면',
  you_have_successfully_accepted_the_invitation:
    '초대를 성공적으로 수락했습니다',
  //
  downline_report: '다운라인 보고서',
  referral_faq_subscription:
    '제휴 프로그램에 대한 자세한 내용은 다음으로 문의하십시오 ',
  internet_connection_restored: '인터넷 연결이 복원되었습니다.',
  lost_connect_to_network: '네트워크 연결이 끊어졌습니다.',
  // 10/10
  user_profile: '유저 프로필',
  leave_a_tip: '팁을 남기다',
  your_commission_rate: '귀하의 수수료율',
  affiliate_terms_conditions: '제휴 이용 약관',
  REFERRAL_CODE_OR_NAME_EXISTS: '캠페인 이름 또는 추천 코드가 이미 존재합니다.',
  swap_unavailable:
    '스왑 쌍은 현재 지원되지 않습니다. 나중에 다시 시도해 주세요.',
  please_enter_swap_amount: '스왑금액을 입력해주세요',
  become_our_agent_Contact_business:
    '우리 대리인이 되시겠습니까? 업체에 문의하세요:',
  earn_commission_rewards:
    '친구들이 우리 게임에 베팅할 때마다 커미션 보상을 받으세요',
  turn_your_friends_and_followers_into_weekly_commissions:
    'Ohplay 의 제휴 프로그램을 통해 친구와 팔로어를 주간 커미션으로 전환하세요. 간단하고 수익성이 높으며 완전히 투명합니다.',
  // FAQ
  why_should_i_be_a_Coco_affiliate: '왜 Ohplay 제휴사가 되어야 합니까?',
  adding_our_link_and_or_banner_to_your_website:
    '트래픽이 어느 정도 있는 웹사이트나 소셜 채널이 있다면 그 잠재력을 극대화해 보는 것은 어떨까요? 귀하의 웹사이트에 당사의 링크 및/또는 배너를 추가하면 즉시 추가 수입을 얻을 수 있는 새롭고 효과적인 방법을 만들 수 있습니다.',
  how_do_I_get_paid_for_being_an_affiliate:
    '제휴사가 되면 어떻게 급여를 받을 수 있나요?',
  the_banner_or_link_you_create_contains_your_personal_tracking_code:
    '귀하가 생성한 배너 또는 링크에는 개인 추적 코드가 포함되어 있습니다. 플레이어가 Ohplay 를 방문하면 이 코드는 컴퓨터의 쿠키에 저장됩니다. 사용자가 가입하면 귀하가 추천한 사용자로 식별됩니다. 이 플레이어는 귀하와 영구적으로 연결되며 귀하는 평생 동안 그들의 활동을 통해 커미션을 계속 생성합니다.',
  how_a_new_player_linked_to_me:
    '새로운 플레이어가 어떻게 나에게 연결되었나요?',
  how_exactly_is_my_affiliate_commission_calculated:
    '내 제휴 커미션은 정확히 어떻게 계산되나요?',
  the_commission_for_each_bet: `각 베팅에 대한 커미션은 아래 공식에 따라 계산됩니다. 커미션 = 내기 * 게임의 하우스 에지 * 커미션 비율 여기서:
      - 총 베팅 금액: 제휴사가 추천한 모든 사용자가 생성한 총 게임 베팅 금액입니다.
      - 하우스 에지: 초기 베팅에 대한 평균 손실 비율입니다. 각 게임의 하우스 에지는 1에서 RTP(Return to Player)를 뺀 값으로 정의됩니다.
      - 커미션 비율: 비율은 사용자의 제휴 역할에 따라 다르며 제휴 사이트에 표시됩니다.`,
  how_to_join_the_affiliate_program:
    '제휴 프로그램에 참여하는 방법은 무엇입니까?',
  if_you_already_have_a_Coco_account: `이미 Ohplay 계정이 있다면 다음 단계를 따르세요.
      1. 제휴 페이지를 방문하세요.
      2. 고유한 추천 코드를 사용하여 새 캠페인을 생성하세요.
      3. 귀하의 웹사이트, 소셜 미디어 또는 트래픽이 많은 플랫폼에서 추천 링크를 공유하세요.
      4. 추천한 사용자로부터 주간 커미션을 받으세요.`,
  how_can_i_keep_track_of_my_performance: '내 성과를 어떻게 추적할 수 있나요?',
  once_your_affiliate_link_is_generated:
    '제휴 링크가 생성되면 제휴 대시보드에 로그인할 수 있습니다. 여기에서 귀하는 모든 통계를 볼 수 있으며 다양한 요소를 기반으로 보고서의 정보를 필터링할 수 있습니다.',
  at_the_start_of_each_new_week:
    '새로운 주가 시작될 때 커미션이 계산되어 COD 토큰 형태로 청구할 수 있습니다. 수익금을 계정 지갑으로 쉽게 인출할 수 있습니다.',
  i_have_big_audience_how_i_can_get_special_deals:
    '잠재고객이 많습니다. 특별 할인을 받으려면 어떻게 해야 하나요?',
  if_you_have_a_website_with_substantial_traffic:
    '트래픽이 많은 웹사이트나 청중이 많은 소셜 미디어 계정이 있는 경우, business@Ohplay.club으로 연락하여 독점 거래를 문의하실 수 있습니다.',
  // Crash
  maximum_is_9900: `최대값은 "9900"입니다.`,
  minium_is_101: `최소값은 "1.01"입니다.`,
  insufficient_currency: '돈이 충분치 않다',
  this_game_does_not_support_this_currency:
    '이 게임은 이 통화를 지원하지 않습니다. 계속 플레이하려면 다른 자산으로 전환하세요.',
  unknown: '알려지지 않은',
  you_should_verify_account_first_before_tipping_your_friends:
    '먼저 계정을 확인해야 합니다 \n 친구에게 팁을 주기 전에',
  must_be_only_digits: '숫자만 있어야 합니다.',
  min_length_ID_at_least_14_number: 'ID의 최소 길이는 최소 14자리 숫자입니다.',
  receiver_is_required: '수신기 필요',
  tip_amount_must_specify_a_number: '팁 금액은 숫자를 지정해야 합니다.',
  tip_amount_must_greater_than_0: '팁 금액은 0보다 커야 합니다.',
  tip_amount_is_required: '팁 금액은 필수입니다',
  how_to_see_other_ID: '다른 ID는 어떻게 확인하나요?',
  get_your_friends_ID_from_their_profiles: '프로필에서 친구 ID를 받으세요.',
  tips_amount: '팁 금액',
  total_spending_amount: '총 지출 금액',
  withdrawing_your_balance: '잔액을 인출하기 전에 계좌를 확인해야 합니다.',
  pls_enter_code_send_to_tel: '휴대폰으로 전송된 코드를 입력해주세요',
  please_enter_withdraw_address: '출금주소를 입력해주세요',
  your_profile_had_been_verified: '귀하의 프로필이 확인되었습니다',
  SWAP_UNAVAILABLE: '잔액 불충분',
  // Maintain
  this_page_is_under_maintenance: '죄송합니다! 이 페이지는 유지보수 중입니다.',
  please_give_us_some_minutes_to_fix_the_issue:
    '문제를 해결하는 데 몇 분만 기다려 주세요.',
  // 404
  we_cant_find_that_page: `앗! 해당 페이지를 찾을 수 없습니다.`,
  you_can_still_enjoy_the_best_casino_experience_at_Coco: `하지만 걱정하지 마세요. Ohplay.club에서 여전히 최고의 카지노 경험을 즐기실 수 있습니다.`,
  //
  region_prohibited: '금지 지역',
  coco_game_is_not_available_in_your_region:
    '죄송합니다. Ohplay.club은 해당 지역에서 사용할 수 없습니다.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country:
    '게임 라이센스로 인해 귀하의 국가에서는 플레이어를 받아들일 수 없습니다. 추가 지원이 필요한 경우 support@Ohplay.club으로 문의하세요.',
  congrats: '축하해요',
  won_the_most: '가장 많이 이겼어요!',
  coco_took_it_all: '코코가 다 가져갔어!',
  page: '페이지',
  refresh: '새로고침',
  // Popup
  register_to_get: '등록하여 얻으세요',
  player_to_earn_the: '플레이어는',
  $10_prize: '$10 상금',
  sign_up_now: '지금 등록하세요',
  // Popup 2
  you_receive: '당신은 받습니다',
  weekly: '주간',
  see_detail: '자세히 보기',
  swap_amount_need_to_larger_than_min: '스왑 금액이 최소 금액보다 커야 함',
  crypto_play: '크립토플레이',
  fun_play: '재미있는 놀이',
  // Popup 3
  play_with: '플레이',
  earn: '벌다',
  real: '레알',
  dollars: '달러',
  big_wins: '큰 승리',
  what_is_gift_code: '기프트 코드란 무엇인가요?',
  gift_code_info:
    '기프트 코드는 문자와 숫자로 구성된 특수 코드와 같습니다. 이 코드를 사용하면 다음에서 특별한 보상을 받을 수 있습니다.',
  gift_code_cod:
    '각 선물 코드에는 고유한 가치와 기간이 있습니다. 모든 보상은 COD 토큰 형태로 지급됩니다.',
  there_are_various_ways_to_get_Coco_gift_codes:
    '독점 소셜 미디어 이벤트, 커뮤니티 활동, 보너스, 특별 퀘스트 참여 등 Ohplay 기프트 코드를 얻을 수 있는 다양한 방법이 있습니다.',
  how_to_get_Coco_gift_code: '코코 기프트 코드는 어떻게 받나요?',
  how_to_use_Coco_gift_code: '코코 기프트코드는 어떻게 사용하나요?',
  simply_enter_the_gift_code:
    '선물 코드를 입력하면 보상이 자동으로 잔액에 추가됩니다.',
  giftcode_required: '기프트 코드가 필요합니다',
  bonus_code_claimed_successfully: '보너스 코드가 성공적으로 신청되었습니다',
  claim_your_free_reward: '무료 보상을 받으세요',
  enter_your_giftcode: '기프트 코드를 입력하세요',
  claim_bonus: '보너스 청구',
  what_is_giftcode: '기프트코드란 무엇인가요?',
  what_is_COCO_giftcode: 'Ohplay 기프트코드란 무엇인가요?',
  got_it: '알았어요',
  won: '이겼다',

  // 8/12
  use_your_COD_gift_to_bet_and_win_at_Cocogame:
    'Coco 선물을 사용하여 Coco.game 에서 베팅하고 승리하세요',
  gift_code: '선물 코드',
  // 15/12
  mini_Coco_is_Allways_3x3_Slot_machine_game:
    '미니코코는 27줄의 3x3 슬롯머신 게임입니다.',
  rule_content: `규칙: 기호는 첫 번째 열부터 시작하여 왼쪽에서 오른쪽으로 연속적으로 시작됩니다. 지불금은 한 줄의 가장 높은 값만 계산합니다.`,
  jackpot2: '공동 자금',
  explode_if_you_collect_3_WILD: 'WILD를 3개 모으면 폭발합니다.',
  wild_can_subsitute_for_all_symbols: 'Wild는 모든 기호를 대체할 수 있습니다.',

  // 3/1
  series: '시리즈',
  multiple: '다수의',
  result_will_be_display_here: '결과가 여기에 표시됩니다',
  bet_head: '베팅 헤드',
  bet_tail: '베팅 테일',
  instant: '즉각적인',
  multiply: '곱하다',
  coin_flip_instant_mode_desc:
    '간단히 앞면 또는 뒷면에 베팅하거나 자동으로 베팅을 설정하세요.',
  auto_bet_setup: '자동 베팅 설정',
  you_win_title: '네가 이겼다',
  select_network_to_continue: '계속하려면 네트워크를 선택하세요.',
  ensure_withdraw_address:
    '올바른 주소를 입력했는지 확인하세요. 그렇지 않으면 귀하의 자금이 손실되어 복구할 수 없습니다.',
  click_bet_to_choose_coin_side: '코인 사이드를 선택하려면 베팅을 클릭하세요.',

  copied_share_in_chat: '복사되었습니다. 채팅에 공유하세요!',
  copied_share_online: '복사하여 온라인으로 공유하세요!',
  game_id: '게임 ID',
  server_seed_hash: '서버 시드(해시)',
  client_seed: '클라이언트 시드',
  nonce: '목하',
  about_COD: '대금 상환에 대하여',
  coco_dollar_info:
    'COD(Coco Dollar)는 COCO.GAME에서 출시한 특별 통화입니다. COD를 사용하면 게임에 참여하거나 다른 플레이어에게 팁을 줄 수 있습니다.',
  can_COD_be_exchanged_to_any_other_currency:
    'COD를 다른 통화로 교환할 수 있나요??',
  you_can_swap_cod:
    'Coco Swap을 사용하면 언제든지 현재 환율을 기준으로 COD를 다른 통화로 교환할 수 있습니다.',
  get_daily_bonus_up_to_5000_COD: '일일 보너스 최대 5,000 COD 받기',
  by_playing_games_tips_coin_dropping_and_rain_bonus:
    '게임, 팁, 동전 던지기 및 비 보너스를 통해.',
  see_how: '방법 보기',
  how_to_unlock_COD: 'COD 잠금 해제 방법',
  locked_COD_is_earned_through_specific_bonuses_like_deposit_bonuses:
    '잠긴 COD는 입금 보너스와 같은 특정 보너스를 통해 획득됩니다.',
  unlocking_COD_is_simple:
    'COD 잠금 해제는 간단합니다. 이는 레이크백과 유사하며 베팅에 비례하여 잠금이 해제됩니다.',

  // Chat
  please_slow_down: '속도를 줄여주세요.',
  links_must_include_your_website_domain:
    '링크에는 웹사이트 도메인이 포함되어야 합니다.',
  chat_is_hidden: '채팅이 숨겨져 있습니다.',
  show_chat: '채팅 표시',
  pinned_message: '고정된 메시지',

  // 12/01
  deposit_bonus: '입금 보너스',
  rakeback: '레이크백',
  spin: '회전',
  get_extra_300_bonus_on_minimum_of_10_USDT_deposit:
    '최소 10.00 USDT 입금 시 추가 300% 보너스를 받으세요',
  min_deposit: '최소 입금액:',
  max_claim: '최대 청구:',
  withdraw_successful: '출금 성공',
  your_withdrawal_request_has_been_confirmed:
    '귀하의 출금 요청이 확인되었습니다. 월렛 거래 내역에서 진행 상황을 확인할 수 있습니다.',
  pi_balance_in_wallet: '지갑 속 Pi 잔액',
  your_Pi_address: '귀하의 Pi 주소',
  fill_in_carefully_according_to_the_specific_currency:
    '특정 통화에 따라 신중하게 입력하세요.',
  you_will_receive_Pi_to_your_address_within_1_working_day:
    '영업일 기준 1일 이내에 귀하의 주소로 Pi를 받게 됩니다.',
  please_make_sure_you_enter_the_correct_Pi_address_otherwise_your_funds_will_not_be_reversed:
    '올바른 Pi 주소를 입력했는지 확인하세요. 그렇지 않으면 귀하의 자금이 취소되지 않습니다.',
  current_capacity: '현재 용량',
  what_is_CODG_capacity: 'CODG 용량이란 무엇입니까?',
  rng_certificated_by: 'RNG 인증',

  // 08/03
  buy_crypto: '암호화폐 구매',
  buy_fiat: '화폐 법정 화폐를 선택하세요',
  refund: '환불하다',
  bet_histories: '베팅 내역',
  round_histories: '라운드 기록',
  round_id: '라운드 ID',
  bet_place: '베팅 장소',
  profit: '이익',
  top: '맨 위',
  top_profit: '최고 이익',
  leader_board: '리더보드',
  how_to_join: '가입방법',
  see_more: '더보기',
  top_winning_streak: '최고 연승 기록',
  top_losing_streak: '최다 연패',
  place: '장소',
  streak: '줄',
  total_prized: '총상금',
  top_reward: '최고 보상d',
  top_10_winners: '상위 10명 수상자',
  monthly_bonus: '월간 보너스',
  available_rakeback: '사용 가능한 레이크백',
  no_rakeback_yet: '아직 레이크백이 없습니다',
  claim_my_rakeback: '내 환급 받기',
  wager_required: '베팅 필요',
  receive_every: '수신 간격',
  claim_bonus_successfully: '보너스를 성공적으로 청구하세요',
  claim_bonus_failed: '보너스 청구에 실패했습니다. 다시 시도해 주세요!',
  no_bonus_available: '사용 가능한 보너스 없음',
  play_to_earn_bonus: '게임을 플레이하고 베팅을 시작하여 보너스를 받으세요',
  play_and_wager: '플레이 & 베팅',
  claim_all: '모두 청구',
  duration: '지속',
  your_total_bonus: '총 보너스',
  roll_under: '롤 언더',
  game_result_will_be_displayed_here: '게임 결과가 여기에 표시됩니다.',
  level_up_bonus_upgrade: '레벨업 보너스 업그레이드',
  _24h_live_support: '24시간 실시간 지원',
  bonus_growth: '보너스 성장',
  xp_required: 'XP 필요',

  account_not_conditions: '인출하기 위해 더 많은 베팅',
  play_increase_wager:
    '보너스 금액을 받은 후 인출하려면 베팅 금액이 $120,000에 도달해야 합니다.',
  platinum_1_to_6: '플래티넘 I - VI',
  diamond_1_to_6: '다이아몬드 I~VI',

  // CODG
  total_received_CODG: '총 수령 CODG',
  unlocked_amount: '잠금 해제된 금액',
  how_to_unlock_CODG: 'CODG를 잠금 해제하는 방법은 무엇입니까?',
  swap_and_play: '교환하고 플레이하세요',
  unlocked_CODG:
    '잠금 해제된 CODG를 다른 통화로 교환하여 플레이하고 수익을 얻을 수 있습니다.',
  available_to_swap: '교환 가능',
  there_are_no_redeemable_currencies: '상환 가능한 통화가 없습니다.',
  first_deposit: '첫 입금',
  _300_bonus: '300% 보너스',
  get_up_to: '최대',
  second_deposit: '두 번째 입금',
  _200_bonus: '200% 보너스',
  third_deposit: '세 번째 입금',
  _150_bonus: '150% 보너스',
  fourth_deposit: '네 번째 예금',
  _100_bonus: '100% 보너스',
  deposit_promotion: '예금 프로모션',
  unlock_codg_description:
    '탁월한 4단계 입금 매치 보너스를 제공하여 독점 토큰인 CODG에서 추가 보상을 얻을 수 있는 최고의 기회를 제공합니다. 이 이점을 활용하고 자금을 확대하며 우리 플랫폼과 함께 신나는 여정을 시작하세요.',
  // Buy Crypto
  our_excited_bonus: '당신을 위한 신나는 보너스 여행',
  something_is_wrong: '뭔가 잘못되었다.',
  disclaimer: '부인 성명',
  the_above_third_party:
    '위의 제3자 서비스를 사용하여 Coco에서 플레이하는 데 사용할 수 있는 암호화폐를 구매할 수 있습니다. 해당 플랫폼에 등록함으로써 귀하는 해당 서비스 약관에 동의하게 되며 당사와 독립적으로 실행되는 KYC 프로세스를 통과해야 합니다.',
  buy_with_transFI: 'TransFI로 구매',
  order_processing: '주문 처리',
  your: '당신의',
  buying_order_is_currently:
    '현재 구매 주문을 처리 중입니다. 몇 분만 기다려 주십시오.',
  // Deposit
  transfer_pi: '파이 전송',
  transfer_Pi_to_the_address_above_which_is_our_agent_address:
    'Pi를 당사 에이전트 주소인 위의 주소로 전송하세요.',
  meet_our_live_support: '실시간 지원을 만나보세요',
  click_the_i_transferred_Pi:
    '라이브 지원을 받으려면 "I Transferred Pi" 버튼을 클릭하세요.',
  confirm_transaction: '거래 확인',
  send_your_transaction_and_screenshot_to_the_chat_box_as_confirmation:
    '확인을 위해 거래 및 스크린샷을 채팅 상자에 보냅니다.',
  transfer_Pi_to_this_address: 'Pi를 이 주소로 전송하세요',
  how_to_deposit_Pi: 'Pi를 입금하는 방법은 무엇입니까?',
  i_transferred_Pi: 'Pi를 전송했습니다',
  earn_an_extra_300_bonus_with_a_minimum_deposit_of_1000_USD:
    '최소 입금액 10.00 USD로 추가 300% 보너스를 받으세요.',
  // Tip
  max_length_ID_not_more_than_20_number: '최대 길이 ID는 20개 이하입니다.',
  receiver: '수화기',
  transaction_fee_rate: '거래 수수료율',
  transaction_fee_amount: '거래 수수료 금액',
  you_need_to_wager: '내기를 해야 해',
  // Withdraw
  more_to_withdraw_due_to_the_bonus_money_policies:
    '보너스 머니 정책으로 인해 더 많은 인출 가능',
  pi_address_is_required: 'Pi 주소가 필요합니다',
  pi_Amount_must_specify_a_number: '파이 금액은 숫자를 지정해야 합니다.',
  pi_amount_must_greater_than_10_Pi: '파이 금액은 10파이보다 커야 합니다.',
  pi_amount_must_less_than_10000_Pi: 'Pi 금액은 10.000 Pi 미만이어야 합니다.',
  pi_amount_is_required: '파이 금액이 필요합니다',
  withdraw_successfully_We_will_contact_you_later:
    '성공적으로 출금하세요! 나중에 연락드리겠습니다.',
  hey_there: '안녕하세요',
  please_process_my_Pi_coin_withdrawal_as_follows:
    'Pi 코인 출금을 다음과 같이 처리해 주세요',
  fees: '수수료',
  input_your_Pi_address: 'Pi 주소를 입력하세요',
  input_number_of_Pi: 'Pi의 입력 수',
  lucky_win: '행운의 승리',
  can_not_get_bet_detail: '베팅 세부정보를 얻을 수 없습니다!',

  // Popup currency
  cannot_be_used_in_this_game:
    '이 게임에서는 Real Play 모드로 사용할 수 없습니다. 다른 애셋으로 전환하세요.',
  the_selected_currency_will_be_displayed_in: '선택한 통화가 표시됩니다.',
  and_if_you_change_currency_while_playing:
    '플레이하는 동안 통화를 변경하면 게임이 새로 고쳐지고 다시 시작됩니다.',

  // CODG
  about_CODG: 'CODG 소개',
  CODG_is_the_reward_token_of_Cocogame:
    'CODG는 Coco.game의 보상 토큰입니다. 플랫폼에 더 많이 참여할수록 더 많은 CODG를 획득하여 자산을 풍부하게 만들 수 있습니다.',
  can_CODG_be_swapped_for_other_currencies:
    'CODG를 다른 통화로 교환할 수 있나요?',
  you_can_swap_your_unlocked_CODG:
    '잠금 해제된 CODG를 현재 환율에 따라 다른 통화로 교환할 수 있습니다. 그러나 잠긴 CODG는 교체할 수 없습니다. 플랫폼 내의 다양한 게임에서만 사용할 수 있습니다.',
  // GameBank => Overview
  the_maximum_share_of_all_users: '모든 사용자의 최대 공유',
  your_maximum_share: '귀하의 최대 공유',
  total_available_investing_amount: '총 투자 가능 금액',
  your_available_investing_amount: '귀하의 투자 가능 금액',
  // Game bank => InvestInformation
  what_is_investing_in_coco_gamebank: '코코게임뱅크 투자란?',
  anyone_can_add_money_to_the_gamebank:
    '누구나 Gamebank에 돈을 추가하고 베팅의 하우스 측에 참여할 수 있습니다. 귀하의 수입은 총 Gamebank 이익에 귀하의 몫을 곱하여 결정됩니다.',
  why_should_i_invest: '왜 투자해야 하는가??',
  profitable: '유리한',
  do_you_believe_in_the_bad_luck_of_other_gamblers:
    '다른 도박꾼의 불운을 믿습니까? 그렇다면 집에 들어가는 것은 소극적인 소득을 얻을 수 있는 좋은 방법입니다.',
  real_yield: '실질수익률',
  you_will_receive_profit_in_the_same_token_that_you_invest:
    '귀하는 투자한 것과 동일한 토큰으로 수익을 얻게 됩니다.',
  analysis_tool: '분석 도구',
  access_gamebank_value:
    'Gamebank 가치, 주식, 내역 및 추세에 액세스하여 최선의 결정을 내리세요.',
  understand_the_risk_involved: '관련된 위험을 이해하십시오.',
  keep_in_mind_that_the_gamebank_value_may_temporarily_decrease_due_to_gamblers_wins:
    '도박꾼의 승리로 인해 게임뱅크의 가치가 일시적으로 하락할 수 있어 즉각적인 수익을 얻지 못할 수도 있다는 점을 명심하세요. 신중하게 투자하세요.',
  invest_information: '투자정보',
  details: '세부',
  // Gift code
  gift_received_successfully: '선물 코드 신청됨',
  congratulations_you_have_just_received: '축하해요! 방금 받은 내용:',
  cod_successfully: '대금 상환이 성공적으로 완료되었습니다.',
  // Transaction
  receiver_nickname: '수신자 닉네임',
  sender_nickname: '보내는 사람 닉네임',
  tip_successful: '팁 성공',
  // JFU
  bet_win: '베팅&윈',
  challenge: '도전',
  cash_it_out: '현금화하세요',
  tournament: '토너먼트',
  november_exclusive: '11월 독점',
  new_game: '새로운 게임',
  you_need_to_reach_the_rank_to_unlock_this_feature:
    '당신은 도달해야합니다{{val}}이 기능을 잠금 해제하려면 순위를 매기세요. 당신의 순위를 확인해보세요',
  play_with_balance_in: '균형을 가지고 놀아보세요',

  // Host
  ohplay_vip_club: '오플레이 VIP 클럽',
  pi_vip_club: '파이 VIP 클럽',
  to_pi_vip_club: '파이 VIP 클럽으로',
  to_ohplay_vip_club: '오플레이 VIP클럽으로',
  welcome_to_Ohplay: 'Ohplay.club에 오신 것을 환영합니다',
  welcome_to_Pi: 'Pi.game에 오신 것을 환영합니다e',
  become_a_pi: '파이가 되어 보세요',
  become_a_ohplay: '오플레이가 되어보세요',
  pi_member: 'Pi 회원이 되세요',
  ohplay_member: '오플레이 회원이 되세요',
  why_is_Pi_VIP_program_the_best: 'Pi VIP 프로그램이 왜 최고인가요?',
  why_is_Ohplay_VIP_program_the_best: '오플레이 VIP 프로그램이 왜 좋은가요?',
  how_much_has_pi_given_out_in_bonuses: 'Pi는 보너스로 얼마를 주었나요?',
  how_much_has_ohplay_given_out_in_bonuses:
    'Ohplay는 보너스로 얼마를 제공했습니까?',
  pi_VIP_telegram_channel: 'Pi VIP 텔레그램 채널은 어디에서 찾을 수 있나요?',
  ohplay_VIP_telegram_channel:
    'Ohplay VIP 텔레그램 채널은 어디서 찾을 수 있나요?',
  tip_with_any_currency_in_Pi: 'Pi의 모든 통화에 대한 팁',
  tip_with_any_currency_in_Ohplay: 'Ohplay의 어떤 통화로든 팁을 주세요',
  why_should_i_be_a_Pi_affiliate: '왜 Pi 제휴사가 되어야 합니까?',
  why_should_i_be_a_Ohplay_affiliate: '왜 Ohplay Affiliate가 되어야 하나요?',
  if_you_already_have_a_Pi_account: `이미 Pi 계정이 있다면 다음 단계를 따르세요.
       1. 제휴 페이지를 방문하세요.
       2. 고유한 추천 코드를 사용하여 새 캠페인을 생성하십시오.
       3. 웹사이트, 소셜 미디어 또는 트래픽이 많은 플랫폼에 추천 링크를 공유하세요.
       4. 추천한 사용자로부터 주간 커미션을 받으세요.`,
  if_you_already_have_a_Ohplay_account: `이미 Ohplay 계정이 있는 경우 다음 단계를 따르세요.
       1. 제휴 페이지를 방문하세요.
       2. 고유한 추천 코드를 사용하여 새 캠페인을 생성하십시오.
       3. 웹사이트, 소셜 미디어 또는 트래픽이 많은 플랫폼에 추천 링크를 공유하세요.
       4. 추천한 사용자로부터 주간 커미션을 받으세요.`,
  you_can_still_enjoy_the_best_casino_experience_at_Pi: `하지만 걱정하지 마세요. Pi.game에서 최고의 카지노 경험을 즐기실 수 있습니다.`,
  you_can_still_enjoy_the_best_casino_experience_at_Ohplay: `하지만 걱정하지 마세요. Ohplay.club에서 최고의 카지노 경험을 즐기실 수 있습니다.`,
  pi_game_is_not_available_in_your_region:
    '죄송합니다. 해당 지역에서는 Pi.game을 사용할 수 없습니다.',
  ohplay_game_is_not_available_in_your_region:
    '죄송합니다. 해당 지역에서는 Ohplay.club을 이용할 수 없습니다.',
  pi_took_it_all: '파이가 다 가져갔어!',
  ohplay_took_it_all: '오플레이가 다 가져갔어!',
  there_are_various_ways_to_get_pi_gift_codes:
    '독점 소셜 미디어 이벤트, 커뮤니티 활동, 보너스, 특별 퀘스트 참여 등 Pi 선물 코드를 얻을 수 있는 다양한 방법이 있습니다.',
  there_are_various_ways_to_get_ohplay_gift_codes:
    '독점 소셜 미디어 이벤트, 커뮤니티 활동, 보너스, 특별 퀘스트 참여 등 Ohplay 기프트 코드를 얻을 수 있는 다양한 방법이 있습니다.',
  how_to_get_Pi_gift_code: 'Coco.game 선물 코드를 받는 방법은 무엇인가요?',
  how_to_get_Ohplay_gift_code: 'Coco.game 선물 코드를 받는 방법은 무엇인가요?',
  gift_code_pi: 'Pi.game',
  gift_code_ohplay: 'Ohplay.club',
  how_to_use_pi_gift_code: 'Pi.game 선물 코드를 사용하는 방법은 무엇입니까?',
  how_to_use_ohplay_gift_code: 'Ohplay.club 기프트 코드는 어떻게 사용하나요?',
  what_is_pi_giftcode: 'Pi 기프트 코드란 무엇인가요?',
  what_is_ohplay_giftcode: '오플레이 기프트코드란 무엇인가요?',
  use_your_COD_gift_to_bet_and_win_at_pi:
    'Pi 선물을 사용하여 Pi.game에서 플레이하고 승리하세요',
  use_your_COD_gift_to_bet_and_win_at_ohplay:
    'Ohplay 선물을 사용하여 Ohplay.club에서 베팅하고 승리하세요',
  the_above_third_party_pi:
    '위의 제3자 서비스를 사용하여 Pi에서 플레이하는 데 사용할 수 있는 암호화폐를 구매할 수 있습니다. 해당 플랫폼에 등록함으로써 귀하는 해당 서비스 약관에 동의하게 되며 당사와 독립적으로 실행되는 KYC 프로세스를 통과해야 합니다.',
  the_above_third_party_ohplay:
    '위의 제3자 서비스를 사용하여 Ohplay에서 플레이하는 데 사용할 수 있는 암호화폐를 구매할 수 있습니다. 해당 플랫폼에 등록함으로써 귀하는 해당 서비스 약관에 동의하게 되며 당사와 독립적으로 실행되는 KYC 프로세스를 통과해야 합니다.',
  CODG_is_the_reward_token_of_Pigame:
    'CODG는 Pi.game의 보상 토큰입니다. 플랫폼에 더 많이 참여할수록 더 많은 CODG를 획득하여 자산을 풍부하게 만들 수 있습니다.',
  CODG_is_the_reward_token_of_Ohplay:
    'CODG는 Ohplay.club의 보상 토큰입니다. 플랫폼에 더 많이 참여할수록 더 많은 CODG를 획득하여 자산을 풍부하게 만들 수 있습니다.',
  what_is_investing_in_pi_gamebank: 'Pi 게임뱅크 투자란?',
  what_is_investing_in_ohplay_gamebank: '오플레이 게임뱅크 투자란?',
  turn_your_friends_and_followers_into_weekly_commissions_pi:
    'Pi의 제휴 프로그램을 통해 친구와 팔로어를 주간 커미션으로 전환하세요. 간단하고 수익성이 높으며 완전히 투명합니다.',
  turn_your_friends_and_followers_into_weekly_commissions_ohplay:
    'Ohplay의 제휴 프로그램을 통해 친구와 팔로어를 주간 커미션으로 전환하세요. 간단하고 수익성이 높으며 완전히 투명합니다.',
  if_you_have_a_website_with_substantial_traffic_pi:
    '트래픽이 많은 웹사이트나 청중이 많은 소셜 미디어 계정이 있는 경우, 독점 거래를 위해 business@Pi.game으로 연락하실 수 있습니다.',
  if_you_have_a_website_with_substantial_traffic_ohplay:
    '트래픽이 많은 웹사이트나 청중이 많은 소셜 미디어 계정이 있는 경우, business@Ohplay.club으로 연락하여 독점 거래를 문의하실 수 있습니다.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_pi:
    '게임 라이센스로 인해 귀하의 국가에서는 플레이어를 받아들일 수 없습니다. 추가 지원이 필요한 경우 support@Pi.game을 통해 문의하세요.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_ohplay:
    '게임 라이센스로 인해 귀하의 국가에서는 플레이어를 받아들일 수 없습니다. 추가 지원이 필요한 경우 support@Ohplay.club으로 문의하세요.',
  all_plays: '모든 pla',
  my_plays: '나의 플레이',
  high_play: '하이 플레이',
  play_id: '플레이 ID',
  play_amount: '플레이 금액',
  set_auto_play: '자동 베팅 설정',
  number_of_play: '플레이 횟수',
  stop_auto_play: '자동 재생 중지',
  start_play_round_failed: '플레이 라운드 시작 실패',
  wager_contest_pi: 'Pi.game 콘테스트',
  max_play: '최대 플레이',
  total_play_amount: '총 플레이 금액',
  under_min_play: '최소 플레이 미만',
  invalid_play_params: '잘못된 재생 매개변수',
  over_max_play: '오버맥스 플레이',
  invalid_play_state: '잘못된 플레이 상태',
  play_next: '다음 플레이',
  playing: '놀이',
  play_head: '플레이 헤드',
  play_tail: '테일 플레이',
  auto_play_setup: '자동 재생 설정',
  click_play_to_choose_coin_side: '코인 면을 선택하려면 플레이를 클릭하세요.',
  play_histories: '플레이 기록',
  play_place: '플레이 플레이스',
  can_not_get_play_detail: '플레이 세부정보를 가져올 수 없습니다!',
  play_win: '플레이&윈',

  // CODG 05/04
  locked_CODG_is_earned:
    '잠긴 CODG는 입금 보너스와 같은 특정 보너스나 프로모션을 통해 획득됩니다.',
  unlocking_CODG_is_simple: `CODG 잠금 해제는 간단합니다. 이는 rakeback과 유사하며 다음과 같이 베팅에 비례하여 잠금 해제됩니다.`,
  unlocking_formula:
    'Unlock Amount = Wager Amount * House edge as a decimal * X ',
  conversion_formula: `예를 들어, $100를 베팅하면 0.2개의 잠긴 CODG가 잠금 해제됩니다.`,
  note_unlock_codg:
    '참고: CODG를 잠금 해제하려면 최소한 10,000 XP가 필요한 브론즈 등급을 달성해야 합니다.',
  how_to_claim_COD: 'COD 청구 방법',
  as_you_place_wagers:
    '베팅을 하면 Piggy에 잠금 해제된 CODG가 누적되며, 이는 COD로 변환됩니다.',
  check_to_claim: '청구하려면 확인하세요.',
  about_CODG_token: 'CODG 토큰 정보',
  play_our_games_to_earn_CODG_for_free:
    '게임을 플레이하고 무료로 CODG를 획득하세요!',
  how_to_unlock: '잠금 해제 방법',
  how_to_claim: '청구 방법',
  today_biggest_winners: '오늘의 최대 승자',
  and_get_up_to: '그리고 일어나',
  to_get: '얻기 위해',
  claim_now: '지금 청구하세요',
  learn_more: '더보기',

  // Host 10/04
  welcome_to_satoshity: 'satoshity.io에 오신 것을 환영합니다.',
  welcome_to_betgecko: 'betgecko.io에 오신 것을 환영합니다',
  welcome_to_bitwin: 'bitwin.club에 오신 것을 환영합니다',
  host_vip_club: `${process.env.REACT_APP_SITE} VVIP 클럽`,
  to_host_vip_club: `에게 ${process.env.REACT_APP_SITE} VIP 클럽`,
  learn_vip: `자세히 알아보기 ${process.env.REACT_APP_SITE} VIP 시스템`,
  become_a_host: `되기 ${process.env.REACT_APP_SITE}`,
  host_member: `되기 ${process.env.REACT_APP_SITE} 회원`,
  how_much_has_host_given_out_in_bonuses: `얼마입니까? ${process.env.REACT_APP_SITE} 보너스로 주나요?`,
  why_is_HOST_VIP_program_the_best: `왜? ${process.env.REACT_APP_SITE} VIP 프로그램이 최고인가요?`,
  HOST_VIP_telegram_channel: `어디서 찾을 수 있나요? ${process.env.REACT_APP_SITE} VIP 텔레그램 채널?`,
  tip_with_any_currency_in_HOST: `어떤 통화로든 팁을 주세요. ${process.env.REACT_APP_SITE}`,
  turn_your_friends_and_followers_into_weekly_commissions_HOST: `친구와 팔로어를 주간 커미션으로 전환하세요 ${process.env.REACT_APP_SITE}'s 제휴 프로그램. 간단하고 수익성이 높으며 완전히 투명합니다.`,
  why_should_i_be_a_HOST_affiliate: `나는 왜 되어야 하는가? ${process.env.REACT_APP_SITE} 제휴하다?`,
  if_you_already_have_a_HOST_account: `이미 가지고 있는 경우 ${process.env.REACT_APP_SITE} 계정을 사용하려면 다음 단계를 따르세요.
      1. 제휴 페이지를 방문하세요.
      2. 고유한 추천 코드를 사용하여 새 캠페인을 생성하십시오.
      3. 웹사이트, 소셜 미디어 또는 트래픽이 많은 플랫폼에 추천 링크를 공유하세요.
      4. 추천한 사용자로부터 주간 커미션을 받으세요.`,
  HOST_took_it_all: `${process.env.REACT_APP_SITE} 다 가져갔어!`,
  there_are_various_ways_to_get_HOST_gift_codes: `얻는 방법은 다양해요 ${process.env.REACT_APP_SITE} 독점 소셜 미디어 이벤트, 커뮤니티 활동, 보너스 또는 특별 퀘스트 참여와 같은 선물 코드.`,
  what_is_HOST_giftcode: `'무엇인가요 ${process.env.REACT_APP_SITE} 선물 코드?`,
  mini_HOST_is_Allways_3x3_Slot_machine_game: `미니 ${process.env.REACT_APP_SITE} 27줄의 3x3 슬롯머신 게임입니다.`,
  the_above_third_party_HOST: `위의 제3자 서비스를 사용하여 플레이에 사용할 수 있는 암호화폐를 구매할 수 있습니다.on ${process.env.REACT_APP_SITE}. 해당 플랫폼에 등록함으로써 귀하는 해당 서비스 약관에 동의하게 되며 당사와 독립적으로 실행되는 KYC 프로세스를 통과해야 합니다.`,
  what_is_investing_in_HOST_gamebank: `투자란 무엇인가 ${process.env.REACT_APP_SITE} 게임 뱅크`,

  you_can_still_enjoy_the_best_casino_experience_at_satoshity: `하지만 걱정하지 마세요. satoshity.io에서 여전히 최고의 카지노 경험을 즐길 수 있습니다.`,
  you_can_still_enjoy_the_best_casino_experience_at_betgecko: `D하지만 걱정하지 마십시오. betgecko.io에서 여전히 최고의 카지노 경험을 즐길 수 있습니다.`,
  you_can_still_enjoy_the_best_casino_experience_at_bitwin: `하지만 걱정하지 마세요. bitwin.club에서 여전히 최고의 카지노 경험을 즐길 수 있습니다.`,
  satoshity_game_is_not_available_in_your_region:
    '죄송합니다. 해당 지역에서는 satoshity.io를 사용할 수 없습니다.',
  betgecko_game_is_not_available_in_your_region:
    '죄송합니다. 귀하의 지역에서는 betgecko.io를 사용할 수 없습니다.',
  bitwin_game_is_not_available_in_your_region:
    '죄송합니다. 해당 지역에서는 bitwin.club을 사용할 수 없습니다..',
  how_to_get_satoshity_gift_code:
    'satoshity.io 선물 코드를 얻는 방법은 무엇입니까??',
  how_to_get_betgecko_gift_code:
    'betgecko.io 선물 코드를 받는 방법은 무엇입니까?',
  how_to_get_bitwin_gift_code:
    'bitwin.club 선물 코드를 받는 방법은 무엇입니까?',
  how_to_use_satoshity_gift_code:
    'satoshity.io 선물 코드를 사용하는 방법은 무엇입니까?',
  how_to_use_betgecko_gift_code:
    'betgecko.io 선물 코드를 사용하는 방법은 무엇입니까?',
  how_to_use_bitwin_gift_code:
    'bitwin.club 선물 코드를 사용하는 방법은 무엇입니까?',
  use_your_COD_gift_to_bet_and_win_at_satoshity:
    'Satoshity 선물을 사용하여 satoshity.io에서 베팅하고 승리하세요',
  use_your_COD_gift_to_bet_and_win_at_betgecko:
    'Betgecko 선물을 사용하여 betgecko.io에서 베팅하고 승리하세요',
  use_your_COD_gift_to_bet_and_win_at_bitwin:
    'Bitwin 선물을 사용하여 bitwin.club에서 베팅하고 승리하세요',
  CODG_is_the_reward_token_of_satoshity:
    'CODG는 satoshity.io의 보상 토큰입니다. 플랫폼에 더 많이 참여할수록 더 많은 CODG를 획득하여 자산을 풍부하게 만들 수 있습니다.',
  CODG_is_the_reward_token_of_bitwin:
    'CODG는 bitwin.club의 보상 토큰입니다. 플랫폼에 더 많이 참여할수록 더 많은 CODG를 획득하여 자산을 풍부하게 만들 수 있습니다.',
  CODG_is_the_reward_token_of_betgecko:
    'CODG는 betgecko.io의 보상 토큰입니다. 플랫폼에 더 많이 참여할수록 더 많은 CODG를 획득하여 자산을 풍부하게 만들 수 있습니다.',
  if_you_have_a_website_with_substantial_traffic_satoshity:
    '트래픽이 많은 웹사이트나 청중이 많은 소셜 미디어 계정이 있는 경우 business@satoshity.io로 연락하여 독점 거래를 문의할 수 있습니다..',
  if_you_have_a_website_with_substantial_traffic_betgecko:
    '트래픽이 많은 웹사이트나 청중이 많은 소셜 미디어 계정이 있는 경우, business@betgecko.io로 연락하여 독점 거래를 문의하실 수 있습니다.',
  if_you_have_a_website_with_substantial_traffic_bitwin:
    '트래픽이 많은 웹사이트나 청중이 많은 소셜 미디어 계정이 있는 경우, 독점 거래를 위해 business@bitwin.club으로 연락하실 수 있습니다.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_betgecko:
    '게임 라이센스로 인해 귀하의 국가에서는 플레이어를 받아들일 수 없습니다. 추가 지원이 필요한 경우 support@Betgecko.io를 통해 문의하세요.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_satoshity:
    '게임 라이센스로 인해 귀하의 국가에서는 플레이어를 받아들일 수 없습니다. 추가 지원이 필요한 경우 support@Satoshity.io를 통해 문의하세요.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_bitwin:
    '게임 라이센스로 인해 귀하의 국가에서는 플레이어를 받아들일 수 없습니다. 추가 지원이 필요한 경우 support@Bitwin.club을 통해 문의하세요.',
  claim_successful: '소유권 주장 성공',

  refer_description_coco:
    '친구에게 coco.game 제휴 프로그램을 추천하고 링크를 통해 등록하고 플레이하는 모든 사용자에 대해 결과에 관계없이 커미션을 받으세요.',
  refer_description_pi:
    '친구에게 pi.game 제휴 프로그램을 추천하고 링크를 통해 등록하고 플레이하는 모든 사용자에 대해 결과에 관계없이 커미션을 받으세요.',
  refer_description_ohplay:
    '친구에게 ohplay.club 제휴 프로그램을 추천하고 링크를 통해 등록하고 플레이하는 모든 사용자에 대해 결과에 관계없이 커미션을 받으세요.',
  refer_description_satoshity:
    '친구에게 satoshity.io 제휴 프로그램을 추천하고 링크를 통해 등록하고 플레이하는 모든 사용자에 대해 결과에 관계없이 커미션을 받으세요.',
  refer_description_betgecko:
    '친구에게 betgecko.io 제휴 프로그램을 추천하고 링크를 통해 등록하고 플레이하는 모든 사용자에 대해 결과에 관계없이 커미션을 받으세요.',
  refer_description_bitwin:
    '친구에게 bitwin.club 제휴 프로그램을 추천하고 링크를 통해 등록하고 플레이하는 모든 사용자에 대해 결과에 관계없이 커미션을 받으세요.',
  your_email_verified_coco:
    '귀하의 이메일이 확인되었습니다. coco.game을 탐험해 봅시다.',
  your_email_verified_pi:
    '귀하의 이메일이 확인되었습니다. pi.game을 탐험해 봅시다.',
  your_email_verified_ohplay:
    '귀하의 이메일이 확인되었습니다. ohplay.club 탐험해 봅시다.',
  your_email_verified_satoshity:
    '귀하의 이메일이 확인되었습니다. satoshity.io 탐험해 봅시다.',
  your_email_verified_betgecko:
    '귀하의 이메일이 확인되었습니다. betgecko.io 탐험해 봅시다.',
  your_email_verified_bitwin:
    '귀하의 이메일이 확인되었습니다. bitwin.club 탐험해 봅시다.',
  GAME: '게임',
  AFFILIATE: '제휴하다',
  ACCOUNT: '계정',
  BONUS: '보너스',
  INVEST: '투자하다',
  SWAP: '교환',
  deposit_reward: '입금 보상',
  roll_dice_trio: '롤 주사위 트리오',
  hit_1000: '$1000 히트',
  full_jackpot: '풀 잭팟',
  play_game2: '게임하다',
  up_to: '까지',
  for: '~을 위한',
  free_5_usdt: '무료 $5 USDT',
  non_deposited: '비입금',
  hands: '소유',
  total_bet: '총 베팅',
  deal: '거래',
  bet_and_win: '베팅 & 승리',
  daily_wager_tournament: '일일 베팅 토너먼트',
  prize_pool: '상금 풀',
  race_now: '지금 경주하세요',
  fuel_your_experience: '경험에 활력을 불어넣으세요',
};

export default kr;
