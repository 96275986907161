import { ReactComponent as RankFirst } from 'assets/icons/rank-first.svg';
import { ReactComponent as RankSecond } from 'assets/icons/rank-second.svg';
import { ReactComponent as RankThird } from 'assets/icons/rank-third.svg';
import PlayerBox from 'components/PlayerBox';
import { ThreeDiceLeaderBoardQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { memo } from 'react';

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableContainerStyled } from 'common/Table/styles';

interface IMiniDiceTopWinStreakTableProps {
  rows: ThreeDiceLeaderBoardQuery['threeDiceCurrentGame']['streakLeaderBoards'];
  myRank: ArrayElement<
    ThreeDiceLeaderBoardQuery['threeDiceCurrentGame']['streakLeaderBoards']
  > & {
    place: number;
  };
}

const MiniDiceTopWinStreakTable = ({
  rows,
  myRank,
}: IMiniDiceTopWinStreakTableProps) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const getRankEl = (rank: number) => {
    if (rank === 1) {
      return <RankFirst />;
    }
    if (rank === 2) {
      return <RankSecond />;
    }
    if (rank === 3) return <RankThird />;
    return rank;
  };

  return (
    <TableContainerStyled>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="top-rank" align="left">
              {translate('place')}
            </TableCell>
            <TableCell className="streak" align="center">
              {translate('streak')}
            </TableCell>
            <TableCell className="wagered" align="center">
              {translate('wagered')}
            </TableCell>
            <TableCell className="prize" align="center">
              {translate('prize')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography textAlign="center" color="text.primary">
                  {translate('empty_data')}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {rows.map((row, index) => {
            const rank = index + 1;
            return (
              <TableRow key={row.user?.id}>
                <TableCell className="top-rank" align="left">
                  <Stack
                    {...(rank > 3 && {
                      ml: rank >= 10 ? 0.25 : 1,
                    })}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {getRankEl(rank)}
                    <PlayerBox
                      ml={rank <= 3 ? 0.5 : 1.25}
                      user={row.user as any}
                    />
                  </Stack>
                </TableCell>
                <TableCell className="streak" align="center">
                  {row.count || '-'}
                </TableCell>
                <TableCell className="wagered" align="center">
                  {`$${getCurrencyAmount(row.wager, null, {
                    onlyUsd: true,
                  })}`}
                </TableCell>
                <TableCell className="prize" align="center">
                  {`$${getCurrencyAmount(row.winValue, null, {
                    onlyUsd: true,
                  })}`}
                </TableCell>
              </TableRow>
            );
          })}
          {myRank && (
            <TableRow className="my-rank">
              <TableCell className="top-rank" align="left">
                <Stack
                  {...(myRank.place > 3 && {
                    ml: 1,
                  })}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {myRank.place ? getRankEl(myRank.place) : '>10'}
                  <PlayerBox
                    ml={myRank.place <= 3 ? 0.5 : 1.25}
                    user={myRank.user as any}
                  />
                </Stack>
              </TableCell>
              <TableCell className="streak" align="center">
                {myRank.count || '-'}
              </TableCell>
              <TableCell className="wagered" align="center">
                {`$${getCurrencyAmount(myRank.wager, null, {
                  onlyUsd: true,
                })}`}
              </TableCell>
              <TableCell className="prize" align="center">
                {`$${getCurrencyAmount(myRank.winValue, null, {
                  onlyUsd: true,
                })}`}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
};

export default memo(MiniDiceTopWinStreakTable);
