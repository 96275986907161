import currencyImages from 'assets/images/coins';
import { useWalletChangedSubscription } from 'graph';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

export default function useHandleWalletChange() {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { data: walletChange } = useWalletChangedSubscription();

  useEffect(() => {
    if (walletChange) {
      const message = (
        <>
          {`${translate(
            'successful_balance_change',
          )}: ${walletChange.userWalletChanged?.amount.toFixed(
            walletChange?.userWalletChanged?.currency?.decimalDigits,
          )}`}
          <img
            src={
              currencyImages[
                walletChange?.userWalletChanged?.currency?.shortName
              ]
            }
            className="currency_image"
            alt={walletChange?.userWalletChanged?.currency?.shortName}
          />
        </>
      );

      enqueueSnackbar(message, {
        variant: 'success',
        key: walletChange?.userWalletChanged?.amount,
        preventDuplicate: true,
      });
    }
  }, [walletChange?.userWalletChanged?.amount]);

  return null;
}
