import {
  currenciesVar,
  hideSmallWalletAmountVar,
  storageCurrencyVar,
  viewInFiatVar,
} from 'store';
import setCurrencies from 'store/operations/currency-opts/setCurrencies';
import setHideSmallWalletAmount from 'store/operations/currency-opts/setHideSmallWalletAmount';
import setStorageCurrency from 'store/operations/currency-opts/setStorageCurrency';
import setViewInFiat from 'store/operations/currency-opts/setViewInFiat';

export const currencyLocalStateOperations = {
  setViewInFiat: setViewInFiat(viewInFiatVar),
  setStorageCurrency: setStorageCurrency(storageCurrencyVar),
  setHideSmallWalletAmount: setHideSmallWalletAmount(hideSmallWalletAmountVar),
  setCurrencies: setCurrencies(currenciesVar),
};
