import { AnimatePresence, motion } from 'framer-motion';

import { Box, Dialog, DialogProps, Paper, PaperProps } from '@mui/material';

import { varFade } from './variants';

export interface Props extends DialogProps {
  variants?: Record<string, unknown>;
  onClose?: () => void;
}
interface PaperComponent extends PaperProps {
  onBackdropClick: () => void;
}
const AnimatedPaper = ({ variants, onBackdropClick, sx, ...props }) => (
  <Box
    component={motion.div}
    {...(variants ||
      varFade({
        distance: 120,
        durationIn: 0.32,
        durationOut: 0.24,
        easeIn: 'easeInOut',
      }).inUp)}
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box
      onClick={(e) => {
        e.stopPropagation();
        onBackdropClick?.();
      }}
      sx={{ width: '100%', height: '100%', position: 'fixed' }}
    />
    <Paper {...props} sx={sx} />
  </Box>
);

export default function DialogAnimate({
  open,
  onClose,
  children,
  sx,
  PaperProps,
  ...other
}: Props) {
  return (
    <AnimatePresence>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        PaperComponent={AnimatedPaper}
        PaperProps={
          {
            ...PaperProps,
            onBackdropClick: onClose,
            sx,
          } as PaperComponent
        }
        {...other}
      >
        {children}
      </Dialog>
    </AnimatePresence>
  );
}
