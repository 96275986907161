// form
import { ReactComponent as CalendarBoldIcon } from 'assets/icons/calendar-bold.svg';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IconButton, InputAdornment } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// @mui
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

type Props = DatePickerProps<any> & {
  name: string;
  label?: string;
  helperText?: React.ReactNode;
};

export default function RHFDatePicker({
  name,
  label,
  helperText,
  ...other
}: Props) {
  const { control } = useFormContext();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...field}
            value={field.value || null}
            format="dd/MM/yyyy"
            closeOnSelect
            label={label}
            readOnly={false}
            open={openDatePicker}
            onClose={() => setOpenDatePicker(false)}
            slots={{
              openPickerButton: (props) => (
                <IconButton {...props} onClick={() => setOpenDatePicker(true)}>
                  <CalendarBoldIcon />
                </IconButton>
              ),
            }}
            slotProps={{
              openPickerIcon: <CalendarBoldIcon />,
              textField: {
                fullWidth: true,
                error: !!error,
                helperText: !!helperText,
                inputRef: ref,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => setOpenDatePicker(true)}
                      >
                        <CalendarBoldIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
              desktopPaper: {
                sx: {
                  '&&': {
                    '.Mui-selected, .Mui-selected:hover, .Mui-selected:focus': {
                      backgroundColor: (theme) => theme.palette.secondary.main,
                    },
                  },
                },
              },
              mobilePaper: {
                sx: {
                  '&&': {
                    '.Mui-selected, .Mui-selected:hover, .Mui-selected:focus': {
                      backgroundColor: (theme) => theme.palette.secondary.main,
                    },
                  },
                },
              },
            }}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}
