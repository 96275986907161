import { Tabs } from 'components/_Games/gameControl/components/styles';
import { useLocales } from 'locales';

import { Box, StackProps, Typography } from '@mui/material';

interface Props extends StackProps {
  selectedTab: string;
  tabs: string[];
  onChangeTab: (tab: string) => void;
}

const Tab = ({ selectedTab, tabs, onChangeTab, ...props }: Props) => {
  const { translate } = useLocales();

  return (
    <Tabs {...props}>
      {tabs.map((tab) => (
        <Box
          className={`tab ${selectedTab === tab ? 'active' : ''}`}
          key={tab}
          onClick={() => onChangeTab(tab)}
        >
          <Typography>{translate(tab)}</Typography>
        </Box>
      ))}
    </Tabs>
  );
};
export default Tab;
