import { SvgIcon } from '@mui/material';

export function IconTip() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M12.6647 4.52387L12.6662 4.53151L12.6679 4.53911C12.7175 4.7538 12.7418 4.99475 12.7418 5.28001V8.71335C12.7418 9.64471 12.4782 10.2277 12.0949 10.5836C11.7049 10.9458 11.1118 11.1467 10.3085 11.1467H4.44847C4.17723 11.1467 3.92836 11.1225 3.70489 11.0722L3.6896 11.0688L3.67413 11.0663C3.5477 11.0461 3.41994 11.0096 3.29467 10.9642C2.88871 10.8122 2.58103 10.5694 2.36897 10.2258C2.15316 9.87604 2.01514 9.38684 2.01514 8.71335V5.28001C2.01514 4.34865 2.27873 3.76567 2.66203 3.40974C3.05204 3.04759 3.64512 2.84668 4.44847 2.84668H10.3018C10.9829 2.84668 11.5148 2.99161 11.8967 3.25553C12.2678 3.51193 12.5434 3.91196 12.6647 4.52387ZM3.5618 9.00001C4.11128 9.00001 4.5618 8.54949 4.5618 8.00001V6.00001C4.5618 5.45054 4.11128 5.00001 3.5618 5.00001C3.01233 5.00001 2.5618 5.45054 2.5618 6.00001V8.00001C2.5618 8.54949 3.01233 9.00001 3.5618 9.00001ZM5.11514 7.00001C5.11514 8.24949 6.12566 9.26001 7.37514 9.26001C8.62461 9.26001 9.63514 8.24949 9.63514 7.00001C9.63514 5.75054 8.62461 4.74001 7.37514 4.74001C6.12566 4.74001 5.11514 5.75054 5.11514 7.00001ZM11.1818 9.00001C11.7313 9.00001 12.1818 8.54949 12.1818 8.00001V6.00001C12.1818 5.45054 11.7313 5.00001 11.1818 5.00001C10.6323 5.00001 10.1818 5.45054 10.1818 6.00001V8.00001C10.1818 8.54949 10.6323 9.00001 11.1818 9.00001Z"
        fill="smokewhite"
        stroke="smokewhite"
      />
      <path
        d="M14.7348 8.71998V7.03217C14.7393 7.11188 14.7415 7.19446 14.7415 7.27998V10.7133C14.7415 11.6447 14.4779 12.2296 14.0934 12.5872C13.7025 12.951 13.1077 13.1533 12.3015 13.1533H6.44818C6.36502 13.1533 6.28415 13.1511 6.20555 13.1466H10.3015C11.6339 13.1466 12.7565 12.7486 13.5465 11.9596C14.3366 11.1705 14.7348 10.0494 14.7348 8.71998Z"
        fill="smokewhite"
        stroke="smokewhite"
      />
    </SvgIcon>
  );
}
