import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import StickyHeaderTable from 'common/Table/StickyHeaderTable';
import { ThreeDiceHistoriesQuery } from 'graph';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { truncate } from 'utils/truncateMiddle';

import {
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

interface MiniDiceRoundHistoriesProps {
  roundHistories: ThreeDiceHistoriesQuery['threeDiceCurrentGame']['foreCasts'];
}

const MiniDiceRoundHistories = ({
  roundHistories = [],
}: MiniDiceRoundHistoriesProps) => {
  const { translate } = useLocales();
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'sm');

  return (
    <StickyHeaderTable className="mini-dice-round-histories">
      <TableHead>
        <TableRow>
          <TableCell className="round-id" align="left">
            {translate('round_id')}
          </TableCell>
          <TableCell className="total-dice-result" align="center">
            {translate('result')}
          </TableCell>
          <TableCell className="hash" align="right">
            {translate('hash')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {roundHistories?.map((row) => (
          <TableRow key={row.id}>
            <TableCell
              className="round-id"
              align="left"
              onClick={() => {
                window.ccg_threedice.onOpenRoundDetailDialog(row.id);
              }}
            >
              #{row.id || ''}
            </TableCell>
            <TableCell className="total-dice-result" align="center">
              <Box display="flex" justifyContent="center">
                <Typography className="total-num">
                  {row.diceSum || 3}
                </Typography>
              </Box>
            </TableCell>
            <TableCell className="hash" align="right">
              <Tooltip
                followCursor
                title={row.rngResult?.rng}
                enterTouchDelay={0}
                slotProps={{
                  tooltip: {
                    sx: {
                      maxWidth: { sm: '100%', xs: 300 },
                      textAlign: 'center',
                    },
                  },
                }}
              >
                <Typography
                  color="text.contrast"
                  component="span"
                  sx={{
                    '&:first-letter': {
                      textTransform: 'none',
                    },
                  }}
                >
                  {isMobile
                    ? truncate(row.rngResult?.rng, 14, 6, 6)
                    : truncate(row.rngResult?.rng, 50, 20, 20)}
                </Typography>
              </Tooltip>
              <IconButton
                onClick={() => {
                  copy(row.rngResult?.rng);
                  enqueueSnackbar(translate('copied'));
                }}
                sx={{ p: 0, ml: 1 }}
              >
                <CopyIcon width="16px" height="16px" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        {roundHistories.length === 0 && (
          <TableRow>
            <TableCell colSpan={3}>
              <Typography textAlign="center" color="text.primary">
                {translate('empty_data')}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </StickyHeaderTable>
  );
};

export default memo(MiniDiceRoundHistories);
