import { SvgIcon } from '@mui/material';

export function IconBookOpen(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.875 3.73279L11.875 6.28084C11.8748 6.37352 11.8746 6.50566 11.8924 6.61909C11.914 6.75777 11.9854 7.02549 12.2626 7.18826C12.5296 7.34502 12.7907 7.28681 12.9148 7.24822C13.023 7.21459 13.1417 7.15807 13.2301 7.11592L14.1667 6.67105L15.1032 7.11592C15.1916 7.15806 15.3103 7.21459 15.4185 7.24822C15.5426 7.28681 15.8037 7.34502 16.0707 7.18826C16.3479 7.0255 16.4193 6.75777 16.4409 6.61909C16.4587 6.50567 16.4585 6.37354 16.4583 6.28086L16.4583 2.52592C16.5523 2.5215 16.6446 2.51777 16.7348 2.51463C17.628 2.48351 18.3332 3.21713 18.3332 4.11083V13.4528C18.3332 14.3786 17.5781 15.1277 16.6541 15.1855C15.8463 15.236 14.8971 15.3348 14.1665 15.5276C13.2653 15.7654 12.1891 16.2802 11.3562 16.7242C11.1236 16.8482 10.8774 16.938 10.625 16.9936V4.31139C10.8923 4.24592 11.1518 4.14268 11.3947 4.00213C11.5483 3.91325 11.7093 3.8226 11.875 3.73279ZM16.4397 10.6816C16.5234 11.0165 16.3198 11.3558 15.9849 11.4395L12.6516 12.2728C12.3167 12.3566 11.9774 12.153 11.8936 11.8181C11.8099 11.4832 12.0135 11.1439 12.3484 11.0602L15.6817 10.2268C16.0166 10.1431 16.3559 10.3467 16.4397 10.6816Z"
        fill={props.color ? props.color : '#9998d4'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37499 4.34481C9.09645 4.29191 8.8243 4.19805 8.56838 4.06272C7.7466 3.62818 6.70751 3.13676 5.83317 2.90604C5.09441 2.7111 4.13215 2.61225 3.31851 2.56224C2.40509 2.50611 1.6665 3.24953 1.6665 4.16467V13.4528C1.6665 14.3786 2.42154 15.1277 3.34555 15.1855C4.1534 15.236 5.10258 15.3348 5.83317 15.5276C6.73438 15.7654 7.81062 16.2802 8.64352 16.7242C8.87614 16.8482 9.12248 16.938 9.37499 16.9937V4.34481ZM3.56031 7.34825C3.64403 7.01338 3.98336 6.80978 4.31824 6.8935L7.65157 7.72683C7.98644 7.81055 8.19004 8.14988 8.10632 8.48476C8.02261 8.81963 7.68327 9.02323 7.3484 8.93951L4.01507 8.10618C3.6802 8.02246 3.47659 7.68312 3.56031 7.34825ZM4.31824 10.2268C3.98336 10.1431 3.64403 10.3467 3.56031 10.6816C3.47659 11.0165 3.6802 11.3558 4.01507 11.4395L7.3484 12.2728C7.68327 12.3566 8.02261 12.153 8.10632 11.8181C8.19004 11.4832 7.98644 11.1439 7.65157 11.0602L4.31824 10.2268Z"
        fill={props.color ? props.color : '#9998d4'}
      />
      <path
        d="M15.2083 2.62568C14.6916 2.68676 14.1837 2.77616 13.7498 2.90604C13.5486 2.9663 13.3385 3.04268 13.125 3.13017V3.29152V5.782L13.7494 5.48538L13.7568 5.48169C13.7976 5.46081 13.9697 5.37281 14.1667 5.37281C14.2063 5.37281 14.2449 5.37637 14.2816 5.38223C14.4275 5.40549 14.5439 5.46501 14.5766 5.48169L14.5839 5.48538L15.2083 5.782V3.03871V2.62568Z"
        fill={props.color ? props.color : '#9998d4'}
      />
    </SvgIcon>
  );
}

<svg />;
