import { InMemoryCache } from '@apollo/client';
import {
  hideSmallWalletAmountVar,
  liveStatsVar,
  storageCurrencyVar,
  viewInFiatVar,
} from 'store';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Photo: {
      fields: {
        id: {
          read(_, { readField }) {
            const uri = readField('uri');
            return uri;
          },
        },
      },
      merge(current, incoming, { mergeObjects }) {
        return mergeObjects(current, incoming);
      },
    },
    UserIdentification: {
      keyFields: ['userId'],
    },
    Query: {
      fields: {
        me: {
          merge(current, incoming, { mergeObjects }) {
            return mergeObjects(current, incoming);
          },
        },
        liveStats: {
          read() {
            return liveStatsVar();
          },
        },
        viewInFiat: {
          read() {
            return viewInFiatVar();
          },
        },
        storageCurrency: {
          read() {
            return storageCurrencyVar();
          },
        },
        hideSmallWalletAmount: {
          read() {
            return hideSmallWalletAmountVar();
          },
        },
      },
    },
  },
});
