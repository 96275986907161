import { useVerifyEmailMutation } from 'graph';
import { useSnackbar } from 'notistack';
import VerifyEmailSuccessDialog from 'pages/Authentication/VerifyEmail/VerifyEmailSuccessDialog';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GameHubPage } from 'routes/elements';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isVerifiedDialogOpen, setIsVerifiedDialogOpen] = useState(false);
  const [verifyEmailMutation] = useVerifyEmailMutation();

  const handleClose = () => {
    setIsVerifiedDialogOpen(false);
    navigate('/');
  };

  const verifyEmail = async () => {
    const token = searchParams.get('token');

    if (!token) {
      enqueueSnackbar('The link is invalid or has expired, please try again', {
        variant: 'error',
      });
      return;
    }

    const response = await verifyEmailMutation({
      variables: {
        token,
      },
    });

    if (response?.data?.verifyEmail?.error) {
      enqueueSnackbar(response.data.verifyEmail.error.message, {
        variant: 'error',
      });
      navigate('/');
      return;
    }

    if (response?.data?.verifyEmail?.success) {
      setIsVerifiedDialogOpen(true);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <>
      <GameHubPage />
      <VerifyEmailSuccessDialog
        open={isVerifiedDialogOpen}
        onClose={() => handleClose()}
        onConfirm={() => {
          handleClose();
        }}
      />
    </>
  );
}
