import { useLoyalUserQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import numeral from 'numeral';
import React from 'react';
import { getVipRankByVipPoint } from 'utils/getVipRank';

import { Box, Stack, Typography } from '@mui/material';

import {
  ProgressInfo,
  StyledLinearProgress,
  VipBoxWrapper,
  VipProgressBox,
  VipProgressWrapper,
  VipRankLinearTitle,
} from './styles_v2';

const maxVipLevel = 74;

export const VipBox = ({ vipPoint }: { vipPoint: number }) => {
  const rank = React.useMemo(() => getVipRankByVipPoint(vipPoint), [vipPoint]);

  return (
    <VipBoxWrapper id="vip-leval-medal">
      <img src={rank.rankImg} alt="reward" />
      <VipRankLinearTitle className={`vip-rank-title ${rank.value}`}>
        {rank.title}
      </VipRankLinearTitle>
    </VipBoxWrapper>
  );
};

export default function VipProgress() {
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const { data: loyalUserData } = useLoyalUserQuery({
    fetchPolicy: 'network-only',
  });

  const vipLevelProgress = React.useMemo(() => {
    const loyalUser = loyalUserData?.me?.vipUser;
    if (loyalUser?.vipLevel >= maxVipLevel) return 100;
    if (loyalUserData?.me?.vipUser) {
      if (loyalUser.vipPoint < loyalUser.currentLevelPoint) {
        return numeral(
          (loyalUser.vipPoint / loyalUser.currentLevelPoint) * 100,
        ).format('0.00', Math.floor);
      }
      return numeral(
        ((loyalUser.vipPoint - loyalUser.currentLevelPoint) /
          (loyalUser.nextLevelPoint - loyalUser.currentLevelPoint)) *
          100,
      ).format('0.00', Math.floor);
    }
    return 0;
  }, [loyalUserData]);

  return (
    <VipProgressWrapper id="vip-progress-box">
      <ProgressInfo>
        <Typography className="vip-progress-text">
          {translate('your_vip_progress')}
        </Typography>
        {isMobile && (
          <VipBox vipPoint={loyalUserData?.me?.vipUser.vipPoint || 0} />
        )}

        <Stack width={{ sm: 'fit-content' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography fontSize={{ sm: 14, xs: 12 }} fontWeight={500} mb={1}>
              {translate('total_wager')}: &nbsp;
              <Typography component="span" color="text.contrast">
                $
                {numeral(loyalUserData?.me?.vipUser?.betAmountUsd).format(
                  '0,0.00',
                )}
              </Typography>
            </Typography>
          </Box>
          <VipProgressBox>
            <Box>
              <StyledLinearProgress
                variant="determinate"
                value={Number(vipLevelProgress)}
              />
              <div className="exp">
                <Typography color="text.contrast">
                  {loyalUserData?.me?.vipUser.vipLevel < maxVipLevel
                    ? loyalUserData?.me?.vipUser &&
                      `${numeral(loyalUserData?.me?.vipUser?.vipPoint).format(
                        '0,0.00',
                      )}/${numeral(
                        loyalUserData?.me?.vipUser?.nextLevelPoint,
                      ).format('0,0.00')} XP`
                    : numeral(loyalUserData?.me?.vipUser?.vipPoint).format(
                        '0,0.00 XP',
                      )}
                </Typography>
              </div>
              {!isMobile && (
                <VipBox vipPoint={loyalUserData?.me?.vipUser.vipPoint || 0} />
              )}
            </Box>
          </VipProgressBox>
        </Stack>
        <Box maxWidth={{ sm: '70%' }}>
          <Typography mt={1.5} whiteSpace="pre-wrap">
            {translate('original_wager_rate')}
          </Typography>
          <Typography>{translate('provider_wager_rate')}</Typography>
          <Typography whiteSpace="break-spaces">
            {translate('all_wagers_converted_to_usd')}
          </Typography>
        </Box>
      </ProgressInfo>
    </VipProgressWrapper>
  );
}
