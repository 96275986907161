import { IconDollar } from 'assets/icons';
import { ReactComponent as MiniDiceOverSvg } from 'assets/icons/mini-dice-over.svg';
import { ReactComponent as MiniDiceUnderSvg } from 'assets/icons/mini-dice-under.svg';
import { ReactComponent as MiniDiceWinFlag } from 'assets/icons/mini-dice-win.svg';
import { ReactComponent as UserCoupleSvg } from 'assets/icons/user-couple.svg';
import { OverAndUnderTabsWrapper } from 'components/_Games/_GamesMiniDice/bet-round-detail/components/over-and-under-tabs/styles';
import { BetMode } from 'graph';
import { memo } from 'react';
import { DeepPartial } from 'react-hook-form';
import { toFixedWithoutRounding } from 'utils/number-help';

import { Box, Stack, Typography } from '@mui/material';

interface IOverAndUnderTabsProps {
  winSide: 'over' | 'under';
  selectedTab: 'over' | 'under';
  betMode: DeepPartial<BetMode>;
  onChangeTab: (tab: 'over' | 'under') => void;
}

const OverAndUnderTabs = ({
  winSide,
  betMode,
  onChangeTab,
  selectedTab,
}: IOverAndUnderTabsProps) => (
  <OverAndUnderTabsWrapper columnGap={1.5} direction="row">
    <Stack
      onClick={() => {
        onChangeTab('over');
      }}
      className={`tab tab-over ${selectedTab === 'over' ? 'active' : ''}`}
    >
      {winSide === 'over' && (
        <Box className="win-flag">
          <MiniDiceWinFlag />
        </Box>
      )}
      <MiniDiceOverSvg />
      <Stack width="100%" direction="row" justifyContent="space-between">
        <Box className="over user-count">
          <UserCoupleSvg className="user-couple" />
          {betMode?.overList?.totalAccount || 0}
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          className="over-total-bet-amount"
        >
          <div className="currency-img">
            <IconDollar />
          </div>
          <Typography>
            {betMode?.overList?.totalBet
              ? toFixedWithoutRounding(betMode?.overList?.totalBet, 2)
              : '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
    <Stack
      onClick={() => {
        onChangeTab('under');
      }}
      className={`tab tab-under ${selectedTab === 'under' ? 'active' : ''}`}
    >
      {winSide === 'under' && (
        <Box className="win-flag">
          <MiniDiceWinFlag />
        </Box>
      )}
      <MiniDiceUnderSvg />
      <Stack width="100%" direction="row" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          className="under-total-bet-amount"
        >
          <div className="currency-img">
            <IconDollar />
          </div>
          <Typography>
            {betMode?.underList?.totalBet
              ? toFixedWithoutRounding(betMode?.underList?.totalBet, 2)
              : '-'}
          </Typography>
        </Stack>
        <Box className="under user-count">
          <UserCoupleSvg className="user-couple" />
          {betMode?.underList?.totalAccount || 0}
        </Box>
      </Stack>
    </Stack>
  </OverAndUnderTabsWrapper>
);

export default memo(OverAndUnderTabs);
