import currencyImages from 'assets/images/coins';
import LoadingAnimated from 'components/LoadingAnimated';
import { format } from 'date-fns';
import { TransactionInfo } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { uniqueId } from 'lodash';

import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  StyledTableCell,
  StyledTableHeaderSticker,
  StyledTableRow,
  TransactionTableBox,
} from './styles';

// ------------------------------------------------------------------------

interface PropsType {
  rows: TransactionInfo[];
  handleLoadMore: (event) => void;
  loading: boolean;
}

export const BillTransactionsTable = ({
  rows,
  handleLoadMore,
  loading,
}: PropsType) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();

  return (
    <TransactionTableBox>
      <StyledTableHeaderSticker>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" width="30%">
                {translate('type')}/{translate('time')}
              </StyledTableCell>
              <StyledTableCell align="right" width="35%">
                {translate('amount')}
              </StyledTableCell>
              <StyledTableCell align="right" width="35%">
                {translate('balance')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </StyledTableHeaderSticker>
      <TableContainer
        sx={{
          borderTopLeftRadius: '0px !important',
          borderTopRightRadius: '0px !important',
        }}
        onScroll={handleLoadMore}
      >
        <Table
          sx={{ height: '100%' }}
          aria-label="customized table"
          stickyHeader
        >
          <TableBody>
            {rows &&
              rows.map(({ equivalentUsdRate, ...row }) => (
                <StyledTableRow key={uniqueId()}>
                  <StyledTableCell component="th" scope="row" width="30%">
                    <Box>
                      <Typography
                        sx={{ color: 'text.contrast', maxWidth: 200 }}
                      >
                        {row.label}
                      </Typography>
                      <div>
                        <span>
                          {format(
                            new Date(row.createdAt),
                            'MM/dd/yyyy h:mm:ss aa',
                          )}
                        </span>
                      </div>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    sx={{ color: 'text.contrast' }}
                    width="35%"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Typography
                        color={row.amount > 0 ? 'primary.main' : 'error.main'}
                        fontWeight={500}
                      >
                        {getCurrencyAmount(Math.abs(row.amount), {
                          ...row.currency,
                          equivalentUsdRate,
                        })}
                      </Typography>
                      <img
                        className="coin_img"
                        src={currencyImages[row.currency.shortName]}
                        alt={row.currency.name}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    sx={{ color: 'text.contrast' }}
                    width="35%"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Typography color="text.contrast">
                        {getCurrencyAmount(row.remainAmount, {
                          ...row.currency,
                          equivalentUsdRate,
                        })}
                      </Typography>
                      <img
                        className="coin_img"
                        src={currencyImages[row.currency.shortName]}
                        alt={row.currency.name}
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {loading && (
              <TableRow>
                <StyledTableCell colSpan={4}>
                  <LoadingAnimated />
                </StyledTableCell>
              </TableRow>
            )}
            {!rows?.length && !loading && (
              <TableRow>
                <StyledTableCell colSpan={4} align="center">
                  {translate('empty_data')}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TransactionTableBox>
  );
};

export default BillTransactionsTable;
