import isPropValid from '@emotion/is-prop-valid';
import {
  SnackbarContent,
  SnackbarContentProps,
  VariantType as SnackbarVariant,
} from 'notistack';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSnackbar = styled(SnackbarContent, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<SnackbarContentProps>(({ theme }) => ({
  '.MuiSnackbarContent-root': {
    backgroundColor: theme.palette.background.table,
  },
  svg: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(2),
  },
  'img.currency_image': {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(0.5),
  },
  '& > div': {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.table,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 50,
    minWidth: 350,
    boxShadow: theme.shadows[24],
  },
  '.MuiCircularProgress-root': {
    marginLeft: theme.spacing(3),
    transition: 'none',
    '.MuiCircularProgress-svg': {
      margin: 'auto',
    },
  },
}));

interface StyledMessageProps {
  $variant: SnackbarVariant;
}

export const StyledMessage = styled(Typography, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<StyledMessageProps>(({ theme, $variant }) => ({
  color: theme.palette.text.primary,
  ...($variant === 'success' && {
    color: theme.palette.primary.main,
  }),
  ...($variant === 'error' && {
    color: theme.palette.support.red,
  }),
  display: 'flex',
  alignItems: 'center',
}));
