import { SvgIcon } from '@mui/material';

export function IconInfo(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_279_731)">
        <path
          d="M6.75699 5.89868C6.70931 6.10302 6.64801 6.51171 6.51859 6.88633C5.93281 8.5347 5.3266 10.1694 4.74082 11.811C4.56372 12.3082 4.40025 12.8191 4.27764 13.3367C4.18909 13.7114 4.33894 13.8476 4.69995 13.7318C4.93835 13.6569 5.19037 13.5275 5.36747 13.3504C5.7489 12.9689 6.09629 12.5398 6.45048 12.1311C6.51859 12.0562 6.55265 11.9336 6.63439 11.8927C6.78424 11.8042 6.98858 11.6748 7.118 11.7156C7.36321 11.7905 7.28147 12.0426 7.19292 12.206C7.00902 12.5398 6.8183 12.8872 6.57309 13.1801C5.52413 14.4198 4.35257 15.496 2.71783 15.891C2.28871 15.9932 1.81872 16.0204 1.37598 15.9864C0.756141 15.9387 0.381514 15.5232 0.286154 14.9034C0.156737 14.1677 0.340645 13.4798 0.599479 12.8055C1.41685 10.7143 2.24103 8.62324 3.0584 6.53214C3.09927 6.42997 3.16738 6.30736 3.15376 6.21201C3.10608 5.98042 3.07202 5.70796 2.92217 5.5513C2.84724 5.46956 2.47943 5.53768 2.35001 5.65347C1.78466 6.15751 1.26019 6.70243 0.722084 7.24053C0.626725 7.33589 0.558611 7.48574 0.449628 7.53342C0.320211 7.59472 0.156737 7.5811 0.00688644 7.60153C0.0136979 7.4653 -0.033982 7.30183 0.0273207 7.20647C0.306588 6.78416 0.599479 6.36186 0.919615 5.96679C1.8051 4.84972 2.94942 4.14134 4.34575 3.84164C4.82936 3.73946 5.29935 3.82801 5.74209 4.03235C6.45729 4.35249 6.77062 4.92465 6.75699 5.89868Z"
          fill="#858997"
        />
        <path
          d="M5.87149 2.78587C5.08818 2.78587 4.46834 2.15922 4.47515 1.38272C4.47515 0.619838 5.1018 0 5.86468 0C6.63437 0 7.26783 0.633461 7.26102 1.40996C7.25421 2.17965 6.63437 2.78587 5.87149 2.78587Z"
          fill="#858997"
        />
      </g>
      <defs>
        <clipPath id="clip0_279_731">
          <rect width="7.27459" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
