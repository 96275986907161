import { bonus_300 } from 'assets/images/bonus';

import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box, { BoxProps as MuiBoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

// ---------------------------------------------------

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
interface ThemeBoxProps extends MuiBoxProps {}

export const AuthenticatedUserWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  height: '40px !important',
  alignItems: 'center',

  '.wrapper': {
    alignItems: 'center',
    backgroundImage: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
    borderRadius: '12px',
    padding: theme.spacing(0.5, 1.5),
    marginRight: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5, 1),
    },

    p: {
      fontWeight: 500,
    },
    // },
  },

  '.balance-wallet': {
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1.5),
    },

    '.nav-wallet-btn': {
      display: 'flex',
      gap: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(1),
      },

      button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.background.main,
        lineHeight: 'normal',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.5, 2.5),
        '&:hover': {
          filter: 'brightness(1.2)',
        },
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
        },
        [theme.breakpoints.down('md')]: {
          minWidth: 36,
          height: 36,
          padding: theme.spacing(0.75),
        },
      },

      '.nav-withdraw': {
        background: theme.palette.text.primary,
      },
    },
  },

  color: theme.palette.text.contrast,
  '.menu_icon_btn': {
    padding: 0,
    marginLeft: theme.spacing(1.5),
  },

  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const UserInfoBox = styled(Box)<ThemeBoxProps>(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(1),
  },

  '.user-avatar-box': {
    position: 'relative',
    alignItems: 'center',
    '.user-avatar': {
      width: 30,
      height: 30,
      cursor: 'pointer',
      borderRadius: '10px',
    },
  },
}));

export const BalanceBox = styled(Box)<ThemeBoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  position: 'relative',
  alignItems: 'center',
  '& div': {
    height: 'fit-content',
  },

  '.current-currency': {
    display: 'flex',
    alignItems: 'center',

    img: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(0.75),
      [theme.breakpoints.down('sm')]: {
        width: 16,
        height: 16,
      },
    },

    '.MuiSvgIcon-root': {
      width: 20,
      height: 20,
      [theme.breakpoints.down('sm')]: {
        width: 16,
        height: 16,
      },
    },
  },

  span: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}));

export const MyWalletBox = styled(Box)(({ theme }) => ({
  position: 'relative',

  '.nav-bonus': {
    background: `url(${bonus_300})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: '-6px',
    right: '-11px',
    zIndex: 1,
    padding: theme.spacing(0, 1, 1),
    [theme.breakpoints.down('md')]: {
      top: '-3px',
      right: '-7px',
      padding: theme.spacing(0, 0.5, 0.5),
    },

    span: {
      fontSize: 14,
      fontWeight: 800,
      color: theme.palette.text.contrast,
      lineHeight: 'normal',
      animation: 'flash 0.8s infinite',
      [theme.breakpoints.down('md')]: {
        fontSize: 9,
      },

      '@keyframes flash': {
        '0%': {
          color: 'white',
        },
        '50%': {
          color: '#FFD600',
        },
        '100%': {
          color: 'white',
        },
      },
    },
  },

  button: {
    span: {
      margin: 0,
    },
  },
}));
