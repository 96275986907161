import { hiddenUser } from 'assets/images';
import UserProfileDialog from 'components/UserProfileDialog';
import { User } from 'graph';
import { useLocales } from 'locales';
import { useState } from 'react';

import { Box, BoxProps, styled, Typography } from '@mui/material';

// ------------------------------------------------------

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .user_name': {
    fontWeight: 600,
    '&:first-letter': {
      textTransform: 'none',
    },
  },
  img: {
    height: 20,
    width: 20,
    '&.avatar': {
      marginRight: theme.spacing(1),
      borderRadius: '50%',
      '&[alt=hidden]': {
        height: 16,
        width: 20,
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 16,
      width: 16,
      '&.avatar': {
        marginRight: theme.spacing(0.5),
      },
    },
  },
}));

interface Props extends BoxProps {
  user:
    | DeepPartial<User>
    | {
        id: string;
        nickname: string;
        avatar: {
          uri: string;
          square: string;
        };
      }
    | null;
  onOpenUserDialog?: VoidFunction;
  align?: 'center' | 'right' | 'left';
  useTo?: 'normal' | 'chat';
  isHideAvatar?: boolean;
}

const PlayerBox = ({
  user,
  onOpenUserDialog,
  align,
  isHideAvatar = false,
  useTo = 'normal',
  ...props
}: Props) => {
  const { translate } = useLocales();
  const [isUserProfileDialogOpen, setIsUserProfileDialogOpen] = useState(false);

  const justifyContentValue = (() => {
    switch (align) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      case 'left':
        return 'flex-start';
      default:
        return 'center';
    }
  })();

  if (!user) {
    return (
      <Wrapper
        {...props}
        sx={{
          ...props.sx,
          '&&': {
            justifyContent: justifyContentValue,
          },
        }}
      >
        {!isHideAvatar && (
          <img className="avatar" alt="hidden" src={hiddenUser} />
        )}
        <Typography className="user_name" color="text.primary">
          {translate('hidden')}
        </Typography>
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper
        onClick={(e) => {
          e.stopPropagation();
          setIsUserProfileDialogOpen(true);
          onOpenUserDialog();
        }}
        {...props}
        sx={{
          ...props.sx,
          '&&': {
            cursor: 'pointer',
            justifyContent: justifyContentValue,
          },
        }}
      >
        {!isHideAvatar && (
          <img
            className="avatar"
            alt={user.nickname}
            src={user?.avatar?.square || '/assets/ava1.jpg'}
          />
        )}
        <Typography
          color={props?.color || (useTo === 'normal' ? '#FFE500' : 'text.main')}
        >
          {user?.nickname}
        </Typography>
      </Wrapper>
      <UserProfileDialog
        open={isUserProfileDialogOpen}
        onClose={() => setIsUserProfileDialogOpen(false)}
        user={user}
      />
    </>
  );
};

PlayerBox.defaultProps = {
  onOpenUserDialog: () => {},
  align: 'center',
};

export default PlayerBox;
