import { Box, TableCell, TableRow, Typography } from '@mui/material';
import currencyImages from 'assets/images/coins';
import { Currency } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import numeral from 'numeral';
import { memo } from 'react';
import { truncate } from 'utils/truncateMiddle';

interface IBetInfoProps {
  id: string;
  betAmount: number;
  currency: Currency;
  payoutRate: number;
  payout: number;
  handleClick: () => void;
}

interface IRowTableProps {
  row: IBetInfoProps;
  showInMiniGame: boolean;
  equivalentUsdRate: string;
}

const RowMyBetTableWrapper = memo((props: Partial<IRowTableProps>) => {
  const { showInMiniGame, equivalentUsdRate, row } = props;
  const { getCurrencyAmount } = useGetCurrencyAmount();

  return (
    <>
      <TableRow
        key={row.id}
        onClick={() => {
          row.handleClick();
        }}
      >
        <TableCell
          align="left"
          sx={{ maxWidth: showInMiniGame ? 'fit-content' : '100px' }}
          width="25%"
        >
          <Typography color="white" ml={1}>
            {showInMiniGame ? truncate(row.id, 14, 6, 6) : row.id}
          </Typography>
        </TableCell>

        <TableCell align="center" width="30%">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography color="text.contrast" className="bet_amount">
              {getCurrencyAmount(row.betAmount, {
                ...row.currency,
                equivalentUsdRate,
              })}
            </Typography>
            <img
              className="currency_image"
              alt={row.currency.shortName}
              src={currencyImages[row.currency.shortName]}
            />
          </Box>
        </TableCell>

        <TableCell align="center" component="th" scope="row" width="15%">
          <Typography color="text.contrast">
            {!Number.isNaN(Number(row.payoutRate))
              ? numeral(Number(row.payoutRate)).format('0.00')
              : row.payoutRate}
            x
          </Typography>
        </TableCell>

        <TableCell
          className={row.payout > 0 ? 'increase' : 'decrease'}
          align="right"
          width="30%"
        >
          <Box display="flex" alignItems="center" justifyContent="right" mr={1}>
            <Typography color={row.payout ? '#53BA0C' : ''}>
              {row.payout
                ? getCurrencyAmount(row.payout, {
                    ...row.currency,
                    equivalentUsdRate,
                  } as Currency)
                : getCurrencyAmount(row.betAmount, {
                    ...row.currency,
                    equivalentUsdRate,
                  })}
            </Typography>
            <img
              className="currency_image"
              alt={row.currency.shortName}
              src={currencyImages[row.currency.shortName]}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
});
export { RowMyBetTableWrapper };
