/* eslint-disable react/jsx-no-constructed-context-values */
import PopupBuyCrypto from 'components/WalletDialog/components/BuyCrypto/PopupBuyCrypto/PopupBuyCrypto';
import BetDetail from 'components/_Games/BetDetail';
import { GoogleClientId } from 'constants/config';
import { ACCESS_TOKEN, REFERRAL_CODE } from 'constants/localStorage';
import { gapi } from 'gapi-script';
import { useLoginGoogleMutation, useLogoutMutation } from 'graph';
import { client } from 'graph/apollo';
import useHandleWalletChange from 'hooks/useHanldeWalletChanged';
import useResponsive from 'hooks/useResponsive';
import useLocales from 'locales/useLocales';
import { useSnackbar } from 'notistack';
import { PopupBonus } from 'overrides/components/popup-bonus-dialog';
import V2AuthenticationDialog from 'pages/Authentication/Authentication_v2';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { GoogleLoginResponse, useGoogleLogin } from 'react-google-login';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sourceSignup } from 'utils/sourceSignup';

import { AuthScreen } from '../pages/Authentication/Authentication';
import { isValidToken, setSession } from './utils';
// ----------------------------------------------------------------

export interface AuthContextState {
  isInitialized: boolean;
  isAuthenticated: boolean;
}

export interface AuthContextValue extends AuthContextState {
  method: string;
  openLoginModal: (page?: AuthScreen) => void;
  loginWithGoogle: VoidFunction;
  loginWithFacebook: VoidFunction;
  loginWithTwitter: VoidFunction;
  logout: VoidFunction;
}
// --
const initialState: AuthContextState = {
  isInitialized: false,
  isAuthenticated: false,
};

const reducer = (state: AuthContextState, action: any) => {
  if (action.type === 'INITIAL') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
    };
  }
  if (action.type === 'LOGIN_WEB3') {
    return {
      ...state,
      isAuthenticated: false,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<AuthContextValue | null>(null);

// ----------------------------------------------------------------------

interface PropsType {
  children: React.ReactElement;
}
export function AuthProvider({ children }: PropsType) {
  useHandleWalletChange();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState<boolean>(false);
  const [isSignUpBonusOpen, setIsSignUpBonusOpen] = useState(false);
  const [isBetDetail, setIsBetDetail] = useState(false);
  const [loginGoogle, { loading: loginGoogleLoading }] =
    useLoginGoogleMutation();
  const [logoutMutation] = useLogoutMutation({ ignoreResults: true });

  const accessToken =
    typeof window !== 'undefined' ? localStorage.getItem(ACCESS_TOKEN) : '';

  const initialize = useCallback(async () => {
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
        },
      });
    }
  }, [accessToken]);

  // useEffect(() => {
  //   if (meError) {
  //     setSession(null);
  //     // navigate('/');
  //     client.clearStore();
  //   }
  // }, [meError]);

  useEffect(() => {
    initialize();

    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: GoogleClientId, scrope: '' });
    });
  }, [initialize]);

  const [authPage, setAuthPage] = React.useState<AuthScreen>(
    AuthScreen.SIGN_IN,
  );

  const openLoginModal = (authPage?: AuthScreen) => {
    if (authPage) {
      setAuthPage(authPage);
    } else {
      setAuthPage(AuthScreen.SIGN_IN);
    }
    setIsAuthDialogOpen(true);
  };

  const closeLoginModal = () => {
    setIsAuthDialogOpen(false);
  };

  const { search } = window.location;
  const [searchParams] = useSearchParams();

  const params = searchParams.get('buy_crypto')?.split('|');

  useEffect(() => {
    if (sourceSignup.includes(search)) {
      setAuthPage(AuthScreen.SIGN_UP);
      setIsAuthDialogOpen(true);
    }
    if (search.includes('betDetail')) {
      if (search.match(/\d+/) !== null) {
        setIsBetDetail(true);
      }
    }
  }, [search]);

  useEffect(() => {
    let api_base = '';
    let app_id = '';
    if (process.env.REACT_APP_SITE === 'pi') {
      api_base = 'https://pnu26oby.intercom-messenger.com';
      app_id = 'pnu26oby';
    } else {
      api_base = 'https://mj6nuzwa.intercom-messenger.com';
      app_id = 'mj6nuzwa';
    }

    window.Intercom('boot', {
      api_base,
      app_id,
      source_website: window.location.host,
    });
    if (isMobile === true) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  }, [state?.isAuthenticated]);

  // login google
  const { signIn } = useGoogleLogin({
    onSuccess: async (data: GoogleLoginResponse) => {
      const result = await loginGoogle({
        variables: {
          token: data?.tokenId,
          referralCode: localStorage.getItem(REFERRAL_CODE) || null,
        },
      });
      if (result?.data?.loginGoogle.success) {
        closeLoginModal();
        setSession(result.data.loginGoogle.content!.accessToken);
        window.dispatchEvent(
          new StorageEvent('storage', {
            key: ACCESS_TOKEN,
            newValue: result.data.loginGoogle.content!.accessToken,
            storageArea: localStorage,
          }),
        );
        return;
      }
      enqueueSnackbar(translate('error_message_server_error'), {
        variant: 'error',
      });
    },
    onFailure: () => {
      enqueueSnackbar(translate('login_fail'), { variant: 'error' });
    },
    clientId: GoogleClientId,
  });

  const loginWithGoogle = () => {
    signIn();
  };

  const loginWithFacebook = () => {};

  const loginWithTwitter = () => {};

  const logout = async () => {
    try {
      await logoutMutation();
    } catch (error) {
      /* empty */
    }

    dispatch({ type: 'LOGOUT' });
    setSession(null);
    client.resetStore();
    navigate('/');
  };

  useEffect(() => {
    let firstTimeout;

    if (!state.isAuthenticated) {
      if (searchParams.get('access_token')) {
        setSession(searchParams.get('access_token'));
        searchParams.delete('access_token');
        navigate('/');
      }

      firstTimeout = setTimeout(() => {
        setIsSignUpBonusOpen(true);
      }, 2000);
    } else {
      setIsSignUpBonusOpen(false);
    }

    return () => {
      if (firstTimeout) {
        clearTimeout(firstTimeout);
      }
    };
  }, [state.isAuthenticated]);

  const contextValue = useMemo(
    () => ({
      ...state,
      method: 'jwt',
      openLoginModal,
      loginWithGoogle,
      loginWithFacebook,
      loginWithTwitter,
      logout,
    }),
    [state.isAuthenticated, isAuthDialogOpen],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {isAuthDialogOpen && (
        <V2AuthenticationDialog
          open={isAuthDialogOpen}
          loading={loginGoogleLoading}
          handleClose={() => {
            setIsAuthDialogOpen(false);
            if (sourceSignup.includes(search)) {
              if (localStorage.getItem('sourceSignup') === 'false') return;
              localStorage.setItem('sourceSignup', 'true');
            }
          }}
          page={authPage}
          handleOpenBonusDialog={() => setIsSignUpBonusOpen(true)}
        />
      )}

      {isBetDetail && (
        <BetDetail
          open={isBetDetail}
          onClose={() => {
            setIsBetDetail(false);
            const updatedSearch = search.replace(
              `?betDetail=${search.match(/\d+/).pop()}`,
              '',
            );
            const newUrl = `${window.location.pathname}${updatedSearch}${window.location.hash}`;
            window.history.replaceState({}, document.title, newUrl);
          }}
          betId={search.match(/\d+/).pop()}
        />
      )}

      {params && params.length === 2 && (
        <PopupBuyCrypto crypto={params[0].toUpperCase()} />
      )}

      {window.location.href !== 'https://ohplay.club/maintenance' &&
        window.location.href !== 'https://ohplay.club/unavailable-region' &&
        window.location.href !== 'https://pi.game/maintenance' &&
        window.location.href !== 'https://pi.game/unavailable-region' &&
        isSignUpBonusOpen && (
          <PopupBonus
            open={isSignUpBonusOpen}
            onClose={() => setIsSignUpBonusOpen(false)}
          />
        )}

      {children}
    </AuthContext.Provider>
  );
}
