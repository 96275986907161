import { useReactiveVar } from '@apollo/client';
import { ReactComponent as RankingIcon } from 'assets/icons/ranking.svg';
import StickyHeaderTable from 'common/Table/StickyHeaderTable';
import LoadingAnimated from 'components/LoadingAnimated';
import { EventHistoryWrapper } from 'components/_Games/_GamesMiniDice/mini-dice-event/styles';
import { format } from 'date-fns';
import { User, useThreeDiceEventHistoriesQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { memo } from 'react';
import { storageCurrencyVar } from 'store';

import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PlayerBox from 'components/PlayerBox';

interface IMiniDiceEventHistoryProps {
  onViewDetailEvent: (event) => void;
}

const MiniDiceEventHistory = ({
  onViewDetailEvent,
}: IMiniDiceEventHistoryProps) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const { data: threeDiceEventHistoriesData, loading } =
    useThreeDiceEventHistoriesQuery({
      variables: { currencyId: storageCurrency.id },
    });

  if (loading)
    return (
      <Box
        position="absolute"
        width="100%"
        height="100%"
        sx={{ top: 0, left: '50%', transform: 'translate(-50%, -5%)' }}
      >
        <LoadingAnimated />
      </Box>
    );

  return (
    <EventHistoryWrapper>
      <StickyHeaderTable maxRows={10}>
        <TableHead>
          <TableRow>
            <TableCell className="time" align="left">
              {translate('time')}
            </TableCell>
            <TableCell className="dice-result" align="center">
              {translate('result')}
            </TableCell>
            <TableCell className="total-prized" align="center">
              {translate('total_prized')}
            </TableCell>
            <TableCell className="top-reward" align="center">
              {translate('top_reward')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {threeDiceEventHistoriesData?.threeDiceCurrentGame?.jackpotWins?.map(
            (row) => (
              <TableRow onClick={() => onViewDetailEvent(row)} key={row.id}>
                <TableCell className="time" align="left">
                  <Stack>
                    <Typography>
                      {format(new Date(row.createdTime), 'dd/MM/yyyy-HH:mm')}
                    </Typography>
                    <Typography>#{row.roundId}</Typography>
                  </Stack>
                </TableCell>
                <TableCell className="dice-result" align="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    columnGap={0.25}
                  >
                    <Box className="dice-num dice-first">{row.result3D[0]}</Box>
                    <Box className="dice-num dice-second">
                      {row.result3D[1]}
                    </Box>
                    <Box className="dice-num dice-third">{row.result3D[2]}</Box>
                  </Stack>
                </TableCell>
                <TableCell className="total-prized" align="center">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography minWidth={50}>
                      {`$${getCurrencyAmount(row.jackpotPay, null, {
                        onlyUsd: true,
                      })}`}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell className="top-reward" align="center">
                  <Stack direction="row" alignItems="center" columnGap={0.5}>
                    <Stack flex={1}>
                      {row.jackpotWinList[0] && (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          className="user-name first"
                        >
                          <PlayerBox
                            isHideAvatar
                            color="#FFE500"
                            user={row.jackpotWinList[0]?.user as User}
                          />
                          <Typography>
                            {`$${getCurrencyAmount(
                              row.jackpotWinList[0]?.winValue,
                              null,
                              {
                                onlyUsd: true,
                              },
                            )}`}
                          </Typography>
                        </Stack>
                      )}
                      {row.jackpotWinList[1] && (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          className="user-name second"
                        >
                          <PlayerBox
                            isHideAvatar
                            color="#63D8FE"
                            user={row.jackpotWinList[1]?.user as User}
                          />
                          <Typography>
                            {`$${getCurrencyAmount(
                              row.jackpotWinList[1].winValue,
                              null,
                              {
                                onlyUsd: true,
                              },
                            )}`}
                          </Typography>
                        </Stack>
                      )}
                      {row.jackpotWinList[2] && (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          className="user-name third"
                        >
                          <PlayerBox
                            isHideAvatar
                            color="#53BA0C"
                            user={row.jackpotWinList[2]?.user as User}
                          />
                          <Typography>
                            {`$${getCurrencyAmount(
                              row.jackpotWinList[2]?.winValue,
                              null,
                              {
                                onlyUsd: true,
                              },
                            )}`}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Box className="ranking-icon">
                      <RankingIcon />
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
            ),
          )}
          {threeDiceEventHistoriesData?.threeDiceCurrentGame?.jackpotWins
            ?.length === 0 &&
            !loading && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography textAlign="center" color="text.primary">
                    {translate('empty_data')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </StickyHeaderTable>
    </EventHistoryWrapper>
  );
};

export default memo(MiniDiceEventHistory);
