import V2ActiveDevices from 'pages/ProfileSettings/components/ActiveDevices/ActiveDevices_v2';
import V2General from 'pages/ProfileSettings/components/General/General_v2';
import V2Security from 'pages/ProfileSettings/components/Security/Security_v2';
import V2Verify from 'pages/ProfileSettings/components/Verify/Verify_v2';
import { Navigate } from 'react-router-dom';

import { ProfileSettings } from '../elements';
import { profilePaths } from '../paths';

export const profileSettingsRoutes = () => ({
  path: profilePaths.page,
  element: <ProfileSettings />,
  children: [
    {
      element: <Navigate to={profilePaths.childTabs.general} replace />,
      index: true,
    },
    {
      path: profilePaths.childTabs.general,
      element: <V2General />,
    },
    {
      path: profilePaths.childTabs.security,
      element: <V2Security />,
    },
    {
      path: profilePaths.childTabs.verify,
      element: <V2Verify />,
    },
    {
      path: profilePaths.childTabs.activeDevices,
      element: <V2ActiveDevices />,
    },
  ],
});
export { profilePaths };
