import { Button, styled } from '@mui/material';
import { SxProps } from '@mui/system';

export const languageStyle: SxProps = {
  width: '245px',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '10px 10px 0',
  backgroundColor: '#323646',

  '.language-item': {
    width: 'calc((100% - 10px) / 2)',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '10px',
    marginBottom: '10px',
    background: '#323646',
    '&:hover': {
      background: '#262936',
    },
    '&:hover p': {
      color: '#FFFFFF',
    },
    '&.selected': {
      background: '#262936',
    },
    '&.selected p': {
      color: '#FFFFFF',
    },
  },

  '.language-item img': {
    width: '20px',
    height: '20px',
  },

  '.language-name': {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#858997',
  },
};

export const StyledButtonUseBonus = styled(Button)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  color: theme.palette.background.main,
  ':hover': {
    filter: 'brightness(150%)',
  },
}));

export const StyledButtonClose = styled(Button)(() => ({
  borderColor: '#FFE500',
  color: '#FFE500',
  ':hover': {
    filter: 'brightness(150%)',
    borderColor: '#FFE500',
  },
}));
