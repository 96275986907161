import { useReactiveVar } from '@apollo/client';
import coinImages from 'assets/images/coins';
import Select from 'common/Select';
import LoadingAnimated from 'components/LoadingAnimated/LoadingAnimated';
import {
  BlockchainNetwork,
  Currency,
  useCreateCryptoWithdrawalMutation,
  useCryptoWithdrawalInfoLazyQuery,
  useUserWalletsQuery,
} from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { currenciesVar } from 'store';

import { FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import {
  QuickSelectWithdrawItem,
  QuickWithdrawAmountBox,
  SelectCoinBox,
} from '../../styles';
import AssetsPortfolioDialog from '../AssetsPortfolioDialog';
import SelectCurrency from '../SelectCurrency';
import ConfirmWithdrawDialog from './ConfirmWithdrawDialog';
import {
  ConfirmedButton,
  SelectNetWorkToContinue,
  WithdrawAddressBox,
  WithdrawAmountBox,
} from './styles';

// ---------------------------------------------------------

export interface WithdrawCryptoProps {
  selectedCurrency: Currency;
  isAccountNotVerified: boolean;
  handleCloseWallet: VoidFunction;
  onAccountNotVerified: VoidFunction;
  setIsWalletDialogHidden: (hidden: boolean) => void;
  handleSelectCurrency: (currency: Currency | null) => void;
}

const quickSelectOption = [
  {
    label: 'max2',
    value: 100,
  },
];

export function WithdrawCrypto({
  selectedCurrency,
  handleSelectCurrency,
  isAccountNotVerified,
  onAccountNotVerified,
  setIsWalletDialogHidden,
}: WithdrawCryptoProps) {
  const [withdrawAddress, setWithdrawAddress] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [quickSelectWithdrawAmount, setQuickSelectWithdrawAmount] =
    useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState<
    BlockchainNetwork | false
  >(false);
  const [isAssetPortfolioOpen, setIsAssetPortfolioOpen] = useState(false);
  const [isConfirmWithdrawOpen, setIsConfirmWithdrawOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const { currencies } = useReactiveVar(currenciesVar);
  const { data: userWalletsData } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });

  const withdrawCurrencies = React.useMemo(
    () =>
      currencies.filter(
        (currency) =>
          currency.blockchainNetworks && currency.blockchainNetworks.length > 0,
      ),
    [currencies],
  );

  const [getCryptoWithdrawInfo, { data, loading: loadingCryptoWithdrawInfo }] =
    useCryptoWithdrawalInfoLazyQuery();
  const [createCryptoWithdrawal] = useCreateCryptoWithdrawalMutation();

  const handleChooseNetwork = (e: any) => {
    setSelectedNetwork(e.target.value);
  };

  const available: number = (() => {
    const amount = userWalletsData?.me?.userWallets?.find(
      (wallet: { currencyId: any }) =>
        wallet.currencyId === selectedCurrency?.id,
    )?.amount;

    return Number(
      getCurrencyAmount(amount, selectedCurrency, { onlyCurrency: true }),
    );
  })();

  const handleQuickSelectWithdrawAmount = (amount: any) => {
    setQuickSelectWithdrawAmount(amount);
    const maximumWithdrawAmount = available;

    if (!available) {
      setWithdrawAmount(
        getCurrencyAmount(0, selectedCurrency, { onlyCurrency: true }),
      );
      return;
    }

    if (amount === 0 || Number.isNaN(withdrawAmount)) {
      setWithdrawAmount(
        getCurrencyAmount(
          data?.cryptoWithdrawalInfo?.minimumAmount,
          selectedCurrency,
          { onlyCurrency: true },
        ) || null,
      );
      return;
    }
    if (amount === 100) {
      if (available) {
        setWithdrawAmount(
          getCurrencyAmount(available, selectedCurrency, {
            onlyCurrency: true,
          }),
        );
      }
      return;
    }
    setWithdrawAmount(
      getCurrencyAmount(
        (maximumWithdrawAmount * amount) / 100,
        selectedCurrency,
        { onlyCurrency: true },
      ),
    );
  };

  const handleSubmitWithdraw = async () => {
    try {
      const response = await createCryptoWithdrawal({
        variables: {
          currencyId: selectedCurrency.id,
          network: selectedNetwork || BlockchainNetwork.None,
          address: withdrawAddress || '',
          amount: Number(withdrawAmount),
        },
      });
      if (response?.data?.createCryptoWithdrawal?.error) {
        enqueueSnackbar(
          response?.data?.createCryptoWithdrawal?.error?.message,
          { variant: 'error' },
        );
        return;
      }

      if (response?.data?.createCryptoWithdrawal?.success) {
        enqueueSnackbar(translate('withdraw_request_was_sent'), {
          variant: 'success',
        });
        setIsWalletDialogHidden(true);
        setIsConfirmWithdrawOpen(false);
      }
    } catch (_) {
      enqueueSnackbar(translate('withdraw_failed'), { variant: 'error' });
    }
  };

  const handleShowConfirm = () => {
    if (!withdrawAddress) {
      enqueueSnackbar(translate('please_enter_withdraw_address'), {
        variant: 'error',
      });
      return;
    }
    if (!available || withdrawAmount > available) {
      enqueueSnackbar(translate('available_amount_insufficient'), {
        variant: 'error',
      });
      return;
    }

    if (withdrawAmount < data?.cryptoWithdrawalInfo?.minimumAmount) {
      enqueueSnackbar(
        `Min withdraw is ${
          data.cryptoWithdrawalInfo?.minimumAmount
        } ${selectedCurrency?.shortName.toUpperCase()}`,
        { variant: 'error' },
      );
      return;
    }

    if (isAccountNotVerified) {
      onAccountNotVerified();
      return;
    }

    setIsConfirmWithdrawOpen(true);
  };

  const receiveAmount = React.useMemo(() => {
    if (Number(withdrawAmount) === 0 || !withdrawAmount) return 0;
    if (data?.cryptoWithdrawalInfo?.feeAmount) {
      const receiveAmount =
        Number(withdrawAmount) - data.cryptoWithdrawalInfo.feeAmount;
      return receiveAmount > 0 ? receiveAmount : 0;
    }
    return Number(withdrawAmount);
  }, [withdrawAmount]);

  React.useEffect(() => {
    if (selectedCurrency?.id && selectedNetwork) {
      getCryptoWithdrawInfo({
        variables: {
          currencyId: selectedCurrency.id,
          network: selectedNetwork || BlockchainNetwork.None,
        },
      });
    }
  }, [selectedCurrency, selectedNetwork]);

  React.useEffect(() => {
    setWithdrawAmount(
      getCurrencyAmount(
        available > data?.cryptoWithdrawalInfo?.minimumAmount
          ? data?.cryptoWithdrawalInfo?.minimumAmount
          : 0,
        selectedCurrency,
        { onlyCurrency: true },
      ),
    );
  }, [selectedCurrency, data]);
  return (
    <Box
      {...(loadingCryptoWithdrawInfo && {
        sx: {
          opacity: 0.4,
        },
      })}
    >
      <SelectCoinBox>
        <SelectCurrency
          selectedCurrency={selectedCurrency}
          amount={withdrawAmount}
          setAmount={(withdrawAM) => {
            setWithdrawAmount(withdrawAM);
          }}
          showInputAmount={false}
          setIsAssetPortfolioOpen={setIsAssetPortfolioOpen}
        />
      </SelectCoinBox>
      {selectedCurrency?.blockchainNetworks && (
        <Select
          options={selectedCurrency.blockchainNetworks.map((i) => ({
            label: i,
            value: i,
          }))}
          onChange={(e) => {
            handleChooseNetwork(e);
          }}
          placeholder="Network"
          defaultValue={selectedCurrency.blockchainNetworks[0] || ''}
          value={selectedNetwork}
          sx={{ marginTop: '12px' }}
        />
      )}
      <SelectNetWorkToContinue
        $haveSelectedNetwork={
          selectedCurrency?.blockchainNetworks && !selectedNetwork
        }
      >
        <div>
          <WithdrawAddressBox>
            <Box className="withdraw">
              <div className="address_input">
                <InputBase
                  placeholder={`${translate('deposit_address_placeholder')}`}
                  value={withdrawAddress}
                  onChange={(event: any) => {
                    setWithdrawAddress(event.target.value.trim());
                  }}
                />
              </div>
            </Box>
          </WithdrawAddressBox>
          <WithdrawAmountBox>
            <div className="withdraw_amount">
              <Typography className="withdraw_amount_label">
                {`${translate('withdraw_amount')}`}
              </Typography>
              {data?.cryptoWithdrawalInfo?.minimumAmount && (
                <Typography>
                  {`${translate('min')}: ${getCurrencyAmount(
                    data?.cryptoWithdrawalInfo?.minimumAmount,
                    selectedCurrency,
                    { onlyCurrency: true },
                  )}`}
                </Typography>
              )}
            </div>
            <Box className="amount_box">
              <InputBase
                value={withdrawAmount}
                placeholder={Number(0).toFixed(selectedCurrency.decimalDigits)}
                onChange={(event) => {
                  const amount = event.target.value;
                  if (Number.isNaN(Number(amount))) return;
                  if (!amount) {
                    setWithdrawAmount(null);
                    return;
                  }
                  setWithdrawAmount(amount);
                }}
                onBlur={(event) => {
                  const amount = Number(event.target.value);
                  if (!available) {
                    setWithdrawAmount(
                      getCurrencyAmount(0, selectedCurrency, {
                        onlyCurrency: true,
                      }),
                    );
                    return;
                  }

                  if (!Number.isNaN(amount)) {
                    if (
                      amount > available ||
                      available < data?.cryptoWithdrawalInfo?.minimumAmount
                    ) {
                      setWithdrawAmount(
                        getCurrencyAmount(available, selectedCurrency, {
                          onlyCurrency: true,
                        }),
                      );
                    } else if (
                      amount < data?.cryptoWithdrawalInfo?.minimumAmount
                    ) {
                      setWithdrawAmount(
                        data?.cryptoWithdrawalInfo?.minimumAmount
                          ? getCurrencyAmount(
                              data?.cryptoWithdrawalInfo?.minimumAmount,
                              selectedCurrency,
                              { onlyCurrency: true },
                            )
                          : null,
                      );
                    } else {
                      setWithdrawAmount(
                        getCurrencyAmount(amount, selectedCurrency, {
                          onlyCurrency: true,
                        }),
                      );
                    }
                  }
                }}
              />
              <Box display="flex">
                <div className="coin_image">
                  <img
                    src={coinImages[selectedCurrency?.shortName]}
                    alt="coin"
                  />
                  <Typography textTransform="uppercase" minWidth="fit-content">
                    {selectedCurrency?.shortName}
                  </Typography>
                </div>
                <QuickWithdrawAmountBox>
                  {quickSelectOption.map((item) => (
                    <QuickSelectWithdrawItem
                      $isActive={quickSelectWithdrawAmount === item.value}
                      key={item.value}
                      onClick={() =>
                        handleQuickSelectWithdrawAmount(item.value)
                      }
                    >
                      <Typography>{translate(item.label)}</Typography>
                    </QuickSelectWithdrawItem>
                  ))}
                </QuickWithdrawAmountBox>
              </Box>
            </Box>
          </WithdrawAmountBox>
          <Box className="fee_box">
            {data?.cryptoWithdrawalInfo?.feeAmount && (
              <div className="fee">
                <Typography className="label">{`${translate(
                  'fee',
                )}`}</Typography>
                <Typography className="amount">
                  {`${getCurrencyAmount(
                    data?.cryptoWithdrawalInfo?.feeAmount,
                    selectedCurrency,
                    { onlyCurrency: true },
                  )} ${selectedCurrency?.shortName.toUpperCase()}`}
                </Typography>
              </div>
            )}
            <div className="receive">
              <Typography className="label">{`${translate(
                'receive_amount',
              )}`}</Typography>
              <Typography className="amount">{`${getCurrencyAmount(
                receiveAmount,
                selectedCurrency,
                { onlyCurrency: true },
              )} ${selectedCurrency?.shortName.toUpperCase()}`}</Typography>
            </div>
            <Box mt={1}>
              <FormHelperText
                sx={{
                  color: 'support.orange',
                }}
              >
                {translate('ensure_withdraw_address')}
              </FormHelperText>
            </Box>
          </Box>

          <ConfirmedButton
            variant="contained"
            color="secondary"
            onClick={handleShowConfirm}
          >
            <Typography component="span">{`${translate(
              'withdraw',
            )}`}</Typography>
          </ConfirmedButton>
        </div>
        <Typography className="select-network-to-action">
          {translate('select_network_to_continue')}
        </Typography>
      </SelectNetWorkToContinue>
      {isConfirmWithdrawOpen && (
        <ConfirmWithdrawDialog
          isOpen={isConfirmWithdrawOpen}
          selectedCurrency={selectedCurrency}
          withdrawInfo={data}
          receiveAmount={receiveAmount}
          available={available}
          handleClose={() => {
            setIsConfirmWithdrawOpen(false);
          }}
          handleBack={() => {
            setIsConfirmWithdrawOpen(false);
            setIsWalletDialogHidden(false);
          }}
          handleConfirm={() => {
            handleSubmitWithdraw();
          }}
        />
      )}
      {isAssetPortfolioOpen && (
        <AssetsPortfolioDialog
          isOpen={isAssetPortfolioOpen}
          currencies={withdrawCurrencies}
          selectedCurrency={selectedCurrency}
          handleSelectCurrency={handleSelectCurrency}
          handleClose={() => {
            setIsAssetPortfolioOpen(false);
          }}
          handleBack={() => {
            setIsAssetPortfolioOpen(false);
            setSelectedNetwork(false);
          }}
        />
      )}
      {loadingCryptoWithdrawInfo && (
        <Box
          position="absolute"
          sx={{
            opacity: 0.6,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <LoadingAnimated />
        </Box>
      )}
    </Box>
  );
}
