import { DialogHeaderStyled } from 'common/Dialog';

import { styled } from '@mui/material';

export const BetRoundDetailDialogHeaderStyled = styled(DialogHeaderStyled)(
  ({ theme }) => ({
    '.round-id': {
      fontWeight: 600,
      marginTop: theme.spacing(0.25),
    },
  }),
);
