import { SvgIcon } from '@mui/material';

export function IconGameFooterHelp(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10 0C4.47301 0 0 4.4725 0 10C0 15.5269 4.4725 20 10 20C15.527 20 20 15.5275 20 10C20 4.47301 15.5275 0 10 0ZM9.7041 14.6804C9.13809 14.6804 8.70371 14.2066 8.70371 13.6668C8.70371 13.114 9.15125 12.6533 9.7041 12.6533C10.257 12.6533 10.7176 13.114 10.7176 13.6669C10.7176 14.2065 10.2701 14.6804 9.7041 14.6804ZM11.2046 9.56C10.4807 10.126 10.4675 10.5209 10.4675 11.2054C10.4675 11.4555 10.3359 11.7451 9.6909 11.7451C9.15117 11.7451 8.96695 11.5476 8.96695 10.8632C8.96695 9.73113 9.46715 9.19145 9.84887 8.86238C10.2832 8.49379 11.0204 8.08578 11.0204 7.375C11.0204 6.76945 10.4939 6.47988 9.8357 6.47988C8.49309 6.47988 8.7827 7.49348 8.07184 7.49348C7.71645 7.49348 7.28207 7.25648 7.28207 6.74316C7.28207 6.03238 8.09816 4.9793 9.87519 4.9793C11.56 4.9793 12.6789 5.91391 12.6789 7.15121C12.6789 8.38852 11.56 9.28359 11.2046 9.56Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
