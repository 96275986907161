import * as Colyseus from 'colyseus.js';
import { PING_SECRET } from 'constants/config';
import { useUserInfoQuery } from 'graph';
import { useLayoutEffect, useRef, useState } from 'react';

import {
  BetErrorType,
  BetType,
  CashoutType,
  ChangeCurrencyType,
  CRASH,
  JoinOptionType,
  PlayerType,
  ReturnType,
  RoomState,
} from './CrashServerType';

// ----------------------------------------------------------------------

export function useCrashSever(endPoint?: string): ReturnType {
  const timerIntervalId = useRef(null);
  const timerReconnect = useRef(null);

  const [connectStatus, setConnectStatus] = useState<
    'DISCONNECT' | 'CONNECTED' | 'JOINED' | 'INIT' | 'PAUSE'
  >('INIT');
  const [process, setProcess] = useState(false);
  const [ping, setPing] = useState(0);
  const [, setPingStart] = useState(0);
  const [leaveCode, setLeaveCode] = useState(0);
  const [joinOption, setJoinOption] = useState<JoinOptionType>(null);
  const [client, setClient] = useState<Colyseus.Client>(null);
  const [crashRoom, setCrashRoom] = useState<Colyseus.Room<any>>(null);
  const [players, setPlayers] = useState<PlayerType[]>([]);
  const [roomState, setRoomState] = useState<RoomState>(null);
  const [crashAt, setCrashAt] = useState<number | 'gogo'>('gogo');
  const [betError, setBetError] = useState<BetErrorType>(null);
  const [playerCashOut, setPlayerCashOut] = useState<CashoutType[]>([]);

  const { data: userInfo } = useUserInfoQuery({ fetchPolicy: 'cache-only' });

  useLayoutEffect(() => {
    timerIntervalId.current = setInterval(() => {
      if (crashRoom) {
        setPingStart(Date.now());
        crashRoom.connection.isOpen && crashRoom.send(CRASH.PING, PING_SECRET);
      }
    }, 3000);
  }, [crashRoom]);

  useLayoutEffect(() => {
    const run = async () => {
      const _ = new Colyseus.Client(endPoint);
      if (_) {
        setClient(_);
        setConnectStatus('CONNECTED');
      }
    };
    run().catch();
    return () => {
      setConnectStatus('INIT');
      clearInterval(timerIntervalId.current);
      timerIntervalId.current = null;

      clearInterval(timerReconnect?.current);
      timerReconnect.current = null;

      crashRoom?.leave(true);
    };
  }, []);
  const join = async (option: JoinOptionType) => {
    const _: Colyseus.Room<any> = await client?.joinOrCreate(
      'CrashRoom',
      option,
    );
    if (_?.id) {
      setConnectStatus('JOINED');
      setCrashRoom(_);
      setRoomState({
        x: _.state.x,
        fx: _.state.fx,
        round_id: _.state.round_id,
        status: _.state.status,
        server_time: _.state.server_time,
        end_time_count_down: _.state.end_time_count_down,
      });
      setJoinOption(option);

      clearInterval(timerReconnect?.current);
      timerReconnect.current = null;
    }
  };

  useLayoutEffect(() => {
    crashRoom?.onMessage(CRASH.PONG, (data) => {
      if (data === PING_SECRET) {
        setPingStart((p) => {
          setPing(Date.now() - p);
          return Date.now();
        });
      }
    });

    crashRoom?.onLeave(async (code) => {
      let reconnectSuccess = true;

      if (code > 1001 && code < 4000) {
        try {
          const _: Colyseus.Room<any> = await client.reconnect(
            crashRoom?.reconnectionToken,
          );
          setCrashRoom(_);
          setRoomState({
            x: _.state.x,
            fx: _.state.fx,
            round_id: _.state.round_id,
            status: _.state.status,
            server_time: _.state.server_time,
            end_time_count_down: _.state.end_time_count_down,
          });
        } catch (e) {
          setConnectStatus('DISCONNECT');
          setLeaveCode(code);

          setPlayers((state) => {
            const copy = [...state];
            const chk = copy.findIndex(
              (r) => r.user_id === userInfo?.me?.user?.id,
            );
            if (chk !== -1) copy.splice(chk, 1);
            return copy;
          });

          setPlayerCashOut((state) => {
            const copy = [...state];
            const chk = copy.findIndex(
              (r) => r.user_id === userInfo?.me?.user?.id,
            );
            if (chk !== -1) copy.splice(chk, 1);
            return copy;
          });
          reconnectSuccess = false;
        }
      } else {
        setConnectStatus('DISCONNECT');
        setLeaveCode(code);
        setPlayers((state) => {
          const copy = [...state];
          const chk = copy.findIndex(
            (r) => r.user_id === userInfo?.me?.user?.id,
          );
          if (chk !== -1) copy.splice(chk, 1);
          return copy;
        });
        setPlayerCashOut((state) => {
          const copy = [...state];
          const chk = copy.findIndex(
            (r) => r.user_id === userInfo?.me?.user?.id,
          );
          if (chk !== -1) copy.splice(chk, 1);
          return copy;
        });
        reconnectSuccess = false;
      }

      if (!reconnectSuccess && joinOption) {
        timerReconnect.current = setInterval(() => {
          join(joinOption);
        }, 3000);
      }

      // 0 - 999		Yes	No	Unused
      // 1000	CLOSE_NORMAL	No	No	Successful operation / regular socket shutdown
      // 1001	CLOSE_GOING_AWAY	No	No	Client is leaving (browser tab closing)
      // 1002	CLOSE_PROTOCOL_ERROR	Yes	No	Endpoint received a malformed frame
      // 1003	CLOSE_UNSUPPORTED	Yes	No	Endpoint received an unsupported frame (e.g. binary-only endpoint received text frame)
      // 1004	Yes	No	Reserved
      // 1005	CLOSED_NO_STATUS	Yes	No	Expected close status, received none
      // 1006	CLOSE_ABNORMAL	Yes	No	No close code frame has been receieved
      // 1007	Unsupported payload	Yes	No	Endpoint received inconsistent message (e.g. malformed UTF-8)
      // 1008	Policy violation	No	No	Generic code used for situations other than 1003 and 1009
      // 1009	CLOSE_TOO_LARGE	No	No	Endpoint won't process large frame
      // 1010	Mandatory extension	No	No	Client wanted an extension which server did not negotiate
      // 1011	Server error	No	No	Internal server error while operating
      // 1012	Service restart	No	No	Server/service is restarting
      // 1013	Try again later	No	No	Temporary server condition forced blocking client's request
      // 1014	Bad gateway	No	No	Server acting as gateway received an invalid response
      // 1015	TLS handshake fail	Yes	No	Transport Layer Security handshake failure
      // 1016 - 1999		Yes	No	Reserved for future use by the WebSocket standard.
      // 2000 - 2999		Yes	Yes	Reserved for use by WebSocket extensions
      // 3000 - 3999		No	Yes	Available for use by libraries and frameworks. May not be used by applications. Available for registration at the IANA via first-come, first-serve.
      // 4000 - 4999		No	Yes	Available for applications
    });

    crashRoom?.onMessage(CRASH.WAITING, (data) => {
      setProcess(data);
    });

    crashRoom?.onMessage(CRASH.CRASH, (data) => {
      const { result } = data;
      setCrashAt(result);
    });

    crashRoom?.onMessage(CRASH.ERROR, (data) => {
      setBetError(data);
    });

    crashRoom?.onMessage(CRASH.PAUSED, () => {
      setConnectStatus('PAUSE');
    });

    crashRoom?.onMessage(CRASH.CASH_OUT, (data) => {
      setPlayerCashOut((state) => [...state, data]);
    });

    crashRoom?.state?.listen('round_id', (value: string) => {
      setRoomState((state) => ({ ...state, round_id: value }));
    });

    crashRoom?.state?.listen('server_time', (value: number) => {
      setRoomState((state) => ({ ...state, server_time: value }));
    });

    crashRoom?.state?.listen('end_time_count_down', (value: number) => {
      setRoomState((state) => ({ ...state, end_time_count_down: value }));
    });

    crashRoom?.state?.listen('fx', (value: number) => {
      setRoomState((state) => ({ ...state, fx: value }));
    });

    crashRoom?.state?.listen('x', (value: number) => {
      setRoomState((state) => ({ ...state, x: value }));
    });

    crashRoom?.state?.listen(
      'status',
      (value: 'WAITING_START' | 'STARTED' | 'STOPED') => {
        if (value === 'WAITING_START') {
          setCrashAt('gogo');
          setPlayerCashOut([]);
        }
        setRoomState((state) => ({ ...state, status: value }));
      },
    );

    crashRoom?.state?.players?.onAdd((value: any) => {
      setPlayers((state) => {
        const copy = [...state];
        const chk = copy.findIndex((r) => r.user_id === value?.user_id);
        if (chk === -1) {
          copy.push(value!);
        }
        return copy;
      });
    });

    crashRoom?.state?.players?.onRemove((value: any) => {
      setPlayers((state) => {
        const copy = [...state];
        const chk = copy.findIndex((r) => r.user_id === value?.user_id);
        if (chk !== -1) copy.splice(chk, 1);
        return copy;
      });
    });

    crashRoom?.state?.players?.onChange((value: any) => {
      setPlayers((state) => {
        const copy = [...state];
        const chk = copy.findIndex((r) => r.user_id === value?.user_id);
        if (chk !== -1) copy[chk] = value;
        return copy;
      });
    });
  }, [crashRoom]);

  const bet = (betData: BetType) => {
    try {
      setBetError(null);
      crashRoom?.send(CRASH.BET, betData);
    } catch {
      /* empty */
    }
  };

  const cashout = () => {
    try {
      setBetError(null);
      crashRoom?.send(CRASH.CASH_OUT, {});
    } catch {
      /* empty */
    }
  };

  const changeCurrency = (changeData: ChangeCurrencyType) => {
    try {
      setBetError(null);
      crashRoom?.send(CRASH.CHANGE_CURRENCY, changeData);
    } catch {
      /* empty */
    }
  };

  return {
    ping,
    process,
    leaveCode,
    connectStatus,
    roomState,
    players,
    crashAt,
    playerCashOut,
    betError,
    join,
    bet,
    cashout,
    changeCurrency,
  };
}
