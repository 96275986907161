import { GameLoadingAnim } from 'components/LoadingAnimated/GameLoadingAnim';
import { ElementType, lazy, Suspense } from 'react';

// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

export const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const GameLoadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<GameLoadingAnim />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const GameDetail = GameLoadable(lazy(() => import('../pages/Games')));

export const CrashPage = Loadable(lazy(() => import('../pages/Games/Crash')));

export const GameHubPage = Loadable(lazy(() => import('../pages/Games/Hub')));

export const HubProvidersPage = Loadable(
  lazy(() => import('../pages/Games/Hub/HubProviders')),
);

export const HubOriginalsPage = Loadable(
  lazy(() => import('../pages/Games/Hub/HubOriginals')),
);

export const HubJackpotGamesPage = Loadable(
  lazy(() => import('../pages/Games/Hub/HubJackpot')),
);

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const Maintain = Loadable(lazy(() => import('../pages/Maintain')));

export const RegionProhibited = Loadable(
  lazy(() => import('../pages/RegionProhibited')),
);

export const ProfileSettings = Loadable(
  lazy(() => import('../pages/ProfileSettings')),
);

export const HelpCenter = Loadable(lazy(() => import('../pages/HelpCenter')));

export const VipClubPage2 = Loadable(
  lazy(() => import('../pages/VipClub/VipClubs_v2')),
);

export const V2AffiliatePage = Loadable(
  lazy(() => import('../pages/Referral/Affiliate')),
);

export const BlogPage = Loadable(lazy(() => import('../pages/Blog')));

export const PromotionsPage = Loadable(
  lazy(() => import('../pages/Blog/pages/Promotions/Promotions')),
);

export const DetailsPage = Loadable(
  lazy(() => import('../pages/Blog/pages/Detail/Detail')),
);
