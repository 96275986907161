import { Container, Typography } from '@mui/material';
import React from 'react';

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (
      this.state.errorInfo &&
      (process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_DEBUG === 'enabled')
    ) {
      return (
        <Container sx={{ mt: 5 }} maxWidth="lg">
          <img
            src="/assets/404.svg"
            alt="404page"
            style={{ width: '300px', height: 'auto', margin: 'auto' }}
          />
          <Typography mt={3} variant="h2" textAlign="center">
            Something went wrong.
          </Typography>
          <Typography
            component="details"
            sx={{
              whiteSpace: 'pre-wrap',
              color: 'error.main',
              maxWidth: 500,
              m: 'auto',
            }}
          >
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </Typography>
        </Container>
      );
    }
    return this.props.children;
  }
}
