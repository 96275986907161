import { ReactNode } from 'react';

import { SxProps } from '@mui/material';

import { TabItemWrapper } from './styles';

interface TabItemProps {
  label: ReactNode;
  isActive: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  sx?: SxProps;
}

const TabItem = ({
  isActive,
  label,
  onClick,
  isDisabled,
  sx,
}: TabItemProps) => {
  return (
    <TabItemWrapper
      $isDisabled={isDisabled}
      $isActive={isActive}
      onClick={onClick}
      sx={sx}
    >
      {label}
    </TabItemWrapper>
  );
};

TabItem.defaultProps = {
  isDisabled: false,
  sx: {},
};

export default TabItem;
