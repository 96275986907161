const tw = {
  demo: {
    title: `Taiwan`,
  },
  blog: `部落格`,
  chat: `聊天`,
  only_admin_can_see_this_item: `只有管理員能看到這個項目`,
  promotions: '促銷活動',
  fairness: '公平',
  blog_academy: '博客/學院',
  live_support: '實時支持',
  search_your_game: '搜索你的遊戲',
  random: '隨機的',
  sign_in: '登入',
  sign_up: '報名',
  wallet: '錢包',
  deposit: '訂金',
  withdraw: '提取',
  swap: '交換',
  crypto: '電子貨幣',
  search_by_name: '按名稱搜索',
  transactions: '交易',
  // Deposit
  overview: '概述',
  deposit_currency: '存款貨幣',
  choose_network: '選擇網絡',
  deposit_address: '充值地址',
  notice: '注意',
  notice_content:
    '僅發送 /currency/ 至此存款地址。 不支持智能合約地址（聯繫我們）',
  available: '可用的',
  balance: '平衡',
  copy: '複製',
  withdraw_amount: '取款數量',
  withdraw_address: '提現地址',
  deposit_address_placeholder: '根據具體幣種認真填寫',
  fee: '費用',
  receive_amount: '收到金額',
  confirm: '確認',
  balance_in_wallet: '錢包餘額',
  withdraw_fee: '提現費用',
  from: '從',
  to: '到',
  exchange_rate: '匯率',
  route: '路線',
  bill: '賬單',
  view_history_in_etherscan: '在 Pi.game blockexplorer 上查看交易歷史記錄',
  time: '時間',
  amount: '數量',
  status: '地位',
  detail: '細節',
  view_in_fiat: '以美元查看',
  common_assets: '共同資產',
  order_id: '訂單號',
  currency: '貨幣',
  quantity: '數量',
  type: '類型',
  empty_data: '空數據',
  currency_not_available: '貨幣不可用',
  all_feature: '所有功能',
  success: '成功',
  audit: '審計',
  insufficient: '不足的',
  processing: '加工',
  new: '新的',
  profile_settings: '配置文件設置',
  general: '一般的',
  security: '安全',
  verify: '核實',
  verified: '已驗證',
  id: 'ID',
  nickname: '暱稱',
  email: '電子郵件',
  phone_number: '電話號碼',
  change: '改變',
  not_use_symbol: '請勿使用特殊符號，否則您的帳戶可能不受支持.',
  hide_profile: '隱藏個人資料',
  registered_mail_cannot_changed:
    '根據Ohplay.club的安全政策，註冊郵箱不能更改.',
  phone_second_login: '添加電話號碼作為您的第二登錄方式.',
  hide_profile_title: '在個人資料上隱藏您的遊戲數據',
  hide_profile_content: '即使隱藏，您的頭像和用戶名在聊天室中始終可見.',
  copied: '已復制',
  enter_nickname: '在此輸入暱稱',
  enter_email: '在此輸入電子郵件',
  enter_phone_number_: '在此輸入電話號碼',
  change_password: '更改密碼',
  current_password: '當前密碼',
  new_password: '新密碼',
  confirm_password: '確認新密碼',
  existing_password_not_match: '現有密碼不匹配',
  password_not_match: '密碼不匹配',
  new_password_saved: '新密碼已保存',
  relogin_after_change_pw: '修改密碼後需要重新登錄。',
  setup_google_auth: '設置谷歌身份驗證器',
  download_google_auth: '下載適用於 iPhone 或 Android 的 Google 身份驗證器應用',
  save_account_token: '將以下帳戶令牌（密鑰）保存在安全的地方',
  account_token: '賬戶令牌（密鑰）',
  need_account_token:
    '如果您丟失手機，您將需要上面的帳戶令牌（密鑰）來訪問您的帳戶',
  scan_qr_title: '使用 Google Authenticator 應用掃描下面的二維碼',
  enter_token_below: '輸入下面的令牌',
  two_FA_code: '2FA 代碼 *',
  appear_on_google_auth: '顯示在您的 Google 身份驗證器應用上',
  verify_code_incorrect: '驗證碼不正確',
  enable_2fa: '啟用 2FA',
  enter_current_pw: '輸入當前密碼',
  enter_new_password: '輸入新密碼',
  enter_repeat_password: '重複新密碼',
  enter_2fa_code_to_disable: '輸入您的 2FA 代碼以禁用雙因素身份驗證。',
  update_nickname_failed: '更新暱稱失敗，請重試',
  disable_2fa: '禁用 2FA',
  verify_email: '驗證郵件',
  send_information_to_email: '我們會將信息發送到您註冊的電子郵件地址。',
  cancel: '取消',
  verify_phone_number: '驗證電話號碼',
  resend_code: '重新發送驗證碼',
  did_not_receive_code: '沒有收到代碼？',
  validate_nickname_length: '暱稱必須介於 4 到 13 個字符之間。',
  nickname_existing: '暱稱已存在。',
  new_nick_name_saved: '新暱稱已保存',
  new_email_saved: '新電子郵件已保存',
  new_phone_number_save: '新電話號碼已保存',
  invalid_phone_number: '無效的電話號碼',
  validate_invalid_email_address: '請輸入有效的電子郵件地址',
  view: '看法',
  choose_asset: '選擇資產',
  min: '最小',
  max: '最大限度',
  max2: '最大限度',
  all_currency: '所有貨幣',
  to_secure_assets: '為了保障您的資產安全，請',
  bet_id: '玩ID',
  bet_amount: '遊玩金額',
  payout: '支出',
  profit_amount: '利潤',
  hash: '哈希值',
  show_more: '展示更多',
  show_less: '顯示較少',
  welcome_aboard: '歡迎登機',
  first_deposit_bonus: '首次存款獎金',
  rewards: '獎勵',
  deposit_now: '立即存款',
  just_for_you: '只為你',
  top_games: '熱門遊戲',
  recently_played: '最近播放',
  recommended: '受到推崇的',
  favourites: '收藏夾',
  or_log_in_with: '或者使用以下方式登錄',
  dont_have_account: '您沒有帳戶嗎？',
  forgot_your_password: '忘記密碼了嗎？',
  password: '密碼',
  terms_of_service: '服務條款',
  confirm_accessing_site: '通過訪問該網站，我確認我已年滿 18 歲並且我已閱讀',
  include_lower_uppercase: '包括小寫和大寫',
  at_least_1_number: '至少 1 個號碼',
  minimum_6_char: '最少 6 個字符',
  agree_terms_service: '我同意並理解',
  terms_conditions: '條款和條件。',
  already_have_account: '已經有帳戶？',
  enter_password_here: '登錄密碼...',
  reset_password: '重設密碼',
  assets_portfolio: '資產組合',
  hide_small: '隱藏小',
  menu: '菜單',
  game_hub: '遊戲中心',
  notification: '通知',
  chat_room: '聊天室',
  login_fail: '登錄失敗。請重試',
  error_message: {
    server_error: '服務器錯誤，請幾分鐘後重試',
  },
  dark: '黑暗的',
  light: '光',
  casino_home: '賭場之家',
  refer_friend: '介紹個朋友',
  support_legal: '支持/法律',
  help_center: '幫助中心',
  faq: '常問問題',
  privacy_policy: '隱私政策',
  aml: '反洗錢',
  res_gambling: '負責任的賭博',
  provably_fair: '可證明公平',
  registration_login: '註冊和登錄',
  about_us: '關於我們',
  news: '消息',
  business_contact: '業務聯繫',
  work_with_us: '跟我們工作',
  coin_accuracy_limit: '硬幣精度限制',
  support: '支持',
  verify_representative: '驗證代表',
  join_our_community: '加入我們的社區',
  go_to_top: '转到顶部',
  game: '遊戲',
  game_info: '遊戲信息',
  description: '描述',
  reviews: '評論',
  latest_bet_race: '最新投注和比賽',
  all_bets: '所有的承諾',
  my_bets: '我的承諾',
  high_bets: '高承諾',
  player: '玩家',
  hidden: '隱',
  about_this_game: '關於這個遊戲',
  share_this_game: '分享這個遊戲',
  share_on_fb: '在臉書上分享',
  share_on_whatsapp: '在 WhatsApp 上分享',
  share_on_twitter: '分享到Twitter',
  share_on_discord: '在 Discord 上分享',
  share_on_linkedin: '在領英上分享',
  share_on_skype: '在 Skype 上分享',
  by: '經過: ',
  release: '發布',
  return_to_player: 'RTP（返回播放器）',
  provider: '提供者',
  max_win: '最大勝利',
  mobile: '移動的',
  stakes_range: '賭注範圍',
  active_devices: '有源設備',
  device: '設備',
  location: '地點',
  ip_address: 'IP地址',
  last_used: '最後使用',
  action: '行動',
  play_now: '現在播放',
  on: '在',
  activities: '活動',
  high_rollers: '豪客賽',
  jackpot_winners: '大獎得主',
  follow_us_on: '跟著我們',
  send_recover_link_to_email:
    '一封包含恢復密碼鏈接的電子郵件將發送到您的電子郵箱。',
  request_reset_email: '請求已發送. 請檢查您的電子郵件',
  something_wrong: '出了些問題。 請再試一次',
  vip_club: '貴賓俱樂部',
  coco_vip_club: 'Ohplay 貴賓俱樂部',
  jackpot: '大獎',
  responsible_gambling: '負責任的賭博',
  next: '下一個',
  step: '步',
  verification: '確認',
  verify_setup: '驗證設置',
  verify_setup_description1:
    '為了更好地為您服務，我們要求您提供身份證明文件原件。 這將在帳戶恢復時保護您的帳戶。 它還有助於確保您收到的禮物或實際獎勵被發送到正確的位置。',
  verify_setup_description2:
    '可接受的身份證明包括政府照片、批准的身份證、駕駛執照和護照。 此過程完成後，我們將通過電子郵件與您聯繫。',
  first_name: '名',
  last_name: '姓',
  gender: '性別',
  date_of_birth: '出生日期',
  address: '地址',
  city: '城市',
  postal_code: '郵政編碼',
  country: '國家',
  enter_your_first_name: '輸入您的名字...',
  enter_your_last_name: '輸入您的姓氏...',
  verify_government_id_description:
    '請上傳政府頒發的有效身份證件或駕駛執照。 它必須包含您的全名、出生日期、您的照片和文件的到期日期。',
  id_card: '身份證',
  passport: '護照',
  front_side: '前面',
  back_side: '背面',
  take_a_photo: '拍個照',
  upload_picture: '上傳圖片',
  information_page: '信息頁',
  verify_portrait_description:
    '請上傳您自己的照片。 在照片中，您需要持有護照或身份證件，以及一張寫有“Ohplay.club”、您的用戶名和日期的紙。 請確保您的面部和您所持有的信息清晰。',
  your_photo: '你的照片',
  references: '參考',
  all_information_submitted: '所有資料已提交',
  thank_you_for_cooperation: '謝謝您的合作。 審核完成後我們會通知您。',
  done: '完畢',
  this_field_is_required: '此字段是必需的',
  male: '男性',
  female: '女性',
  unspecified: '未指定',
  select_gender: '選擇性別',
  back: '後退',
  step1_update_identification_success: '第1步：成功更新身份信息',
  step23_update_identification_photo_success: '第2、3步：成功更新身份證照片',
  invalid_date_format: '日期格式無效',
  update_avatar_success: '更新頭像成功',
  update_avatar_failed: '更新頭像失敗',
  game_bank_overview: '概述',
  game_bank_history: '歷史',
  game_bank_list: '列表',
  game_bank_withdraw: '提取',
  confirm_password_must_match: '確認密碼必須匹配',
  recover_password: '恢復密碼',
  logout_failed: '註銷失敗，請重試！',
  at_least_18_years_old: '您需要年滿 18 歲',
  no_support: '沒有支持',
  gems: '寶石',
  sound: '聲音',
  invalid_wallet_address: '錢包地址無效',
  target_multiplier: '目標乘數',
  multiplier: '乘數',
  unexpected_error: '發生意外的錯誤。 請稍後再試',
  validate_nickname_message:
    '暱稱必須介於 4 到 13 個字符之間，無符號且不包含空格、符號。',
  swap_fee: '掉期費',
  verify_account: '驗證賬戶',
  history: '歷史',
  big_winner: '大贏家',
  jackpot_winner: '大獎得主',
  my_bet: '我的播放',
  all_bet: '所有的承諾',
  winner: '優勝者',
  insufficient_ballance: '餘額不足',
  gamebank: '遊戲庫',
  maximumFileSize: '最大文件大小為 4MB',
  verify_email_was_send: '驗證電子郵件已發送，請檢查您的電子郵件',
  error_code: {
    EMAIL_IN_USED: '該電子郵件已被其他用戶使用.',
  },
  account_verified: '您的帳戶已驗證',
  check_information_carefully: '請在確認前仔細檢查您的投資信息。',
  invest_now: '立即投資',
  more_information: '更多信息',
  host: '主持人',
  loading: '正在加載...',
  you_must_specify_a_number: '您必須指定一個數字',
  must_be_a_positive_value: '必須是正值',
  withdraw_success: '提現成功',
  withdraw_failed: '提現失敗',
  your_total_invest: '您的總投資',
  your_current_shares: '您當前的股票',
  your_balance: '您的餘額',
  your_profit: '您的利潤',
  leverage: '槓桿作用',
  game_bank: '遊戲銀行',
  game_bank_max: '遊戲銀行最大',
  your_invest: '您的投資',
  available_invest_amount: '可用投資金額',
  your_share: '你的分享',
  host_share: '主機分享',
  invest_success: '投資成功',
  invest_failed: '投資失敗',
  investor: '投資者',
  share: '分享',
  error_message_server_error: '錯誤消息. 服務器錯誤r',
  successful_balance_change: '成功，平衡變化：',
  permission_denied: '沒有權限',
  you_not_permission_page: '您沒有權限訪問此頁面',
  not_bad: '不錯',
  nice: '好的',
  awesome: '驚人的',
  wicked: '邪惡',
  godlike: '上帝喜歡',
  to_the_moon: '到月球',
  you_win: '你贏了！',
  bum: '好極了！',
  some_thing_went_wrong: '出了些問題',
  maximum_number: '所選單元格的最大數量為',
  auto_select: '自動選擇',
  number_of_spin: '旋轉次數',
  advanced_auto: '高級汽車',
  stop_if_anyone_win_jackpot: '如果有人贏得大獎就停止',
  stop_decrease_by: '如果餘額減少則停止',
  stop_increase_by: '如果餘額增加則停止',
  stop_exceeds_by: '如果單贏超過則停止',
  start_auto_spin: '開始自動旋轉',
  not_enough_balance: '餘額不足',
  set_auto_bet: '設置自動投注',
  cancel_auto_bet: '取消自動投注',
  cashout_at: '提現於',
  win_amount: '贏獎金額',
  increase: '增加',
  decrease: '減少',
  bet: '玩',
  waiting: '等待...',
  bet_next_round: '下注（下一輪）',
  cashout: '兌現',
  win: '贏',
  lose: '失去',
  increase_by: '通過增加',
  reset: '重置',
  manual: '手動的',
  auto: '汽車',
  max_profit: '最大利潤',
  max_bet: '最大注',
  stop_on_profit: '獲利止損',
  number_of_bet: '播放次數',
  on_loss: '虧損時',
  on_lose: '輸時',
  on_win: '論贏',
  stop_on_lose: '止損',
  stop_on_win: '止於勝利',
  live_stats: '實時統計',
  total_bet_amount: '總投注金額',
  total_profit: '總獎勵',
  game_bank2: '遊戲銀行',
  can_not_get_game_bank: '無法獲取遊戲銀行',
  trends: '趨勢',
  play_real: '玩真實遊戲',
  play_with_fun: '玩得開心',
  online: '在線的',
  welcome_bonus: '歡迎獎金',
  join_now: '立即加入',
  total: '全部的',
  invest: '投資',
  insufficient_balance: '餘額不足',
  please_switch_to_another_asset_to_continue_playing:
    '请切换到其他资产以继续玩.',
  insufficient_gamebank: '暂时不支持该资产',
  switch: '轉變',
  not_found: '未找到',
  no_results_found_for: '沒有找到結果',
  checking_for_typos: '嘗試檢查拼寫錯誤或使用完整的單詞。',
  please_enter_keywords: '請輸入關鍵詞',
  exit_fullscreen: '退出全屏',
  fullscreen: '全屏',
  settings: '設置',
  currency_do_not_supported: '不支持貨幣',
  update_photo: '更新照片',
  upload_photo: '上傳圖片',
  remove_all: '移除所有',
  upload_files: '上傳文件',
  drop_or_select_file: '刪除或選擇文件',
  drop_files_here_or_click: '將文件拖放到此處或單擊',
  thorough_your_machine: '徹底檢查你的機器',
  no_found_any_currency_can_swap: '沒有找到可以兌換的貨幣',
  account_not_verified: '賬戶尚未驗證',
  drawing_your_balance: '在提取餘額之前，您應該先驗證您的帳戶',
  withdraw_request_was_sent: '提款請求已發送，等待確認n',
  available_amount_insufficient: '可用金額不足',
  need_help: '需要幫忙？',
  coming_soon: '即將推出',
  introduction_to_coco:
    '屢獲殊榮的加密貨幣賭場。 憑藉以玩家為中心的方法，Ohplay.club 能夠滿足全球數百萬賭徒的需求。 Ohplay.club 的首要任務是社區，確保提供永恆且無盡娛樂的賭博體驗。',
  coming_soon2: '即將推出...',
  under_development: '該功能正在開發中',
  page_404: '404 頁面未找到 | Pi.game遊戲',
  page_not_found: '抱歉，找不到頁面！',
  check_your_spelling:
    '抱歉，我們找不到您要查找的頁面。 也許您輸錯了網址？ 請務必檢查您的拼寫。',
  go_to_home: '回家',
  remove_session_success: '刪除會話成功',
  remove_session_failed: '刪除會話失敗',
  in_use: '正在使用',
  remove_device: '刪除設備',
  your_profile: '你的個人資料',
  is_hidden: '被隱藏',
  is_public_now: '現已公開',
  hide_profile_failed: '隱藏個人資料失敗，請重試',
  change_password_success: '修改密碼成功. 請重新登錄',
  upload_image_failed: '上傳圖片失敗',
  file_size_limit_10MB: '.jpg、.png； 文件大小限制 10MB',
  roll_over: '滾下',
  win_chance: '贏得機會',
  profit_on_next_tile: '下一塊瓷磚的利潤',
  pick_a_tile_randomly: '隨機選擇一塊瓷磚',
  pay_table: '賠付表',
  game_rule: '遊戲規則',
  number_of_bets: '播放次數',
  bet_failed: '播放失敗',
  stop_auto_bet: '停止自動播放',
  currency_not_support: '幣種不支持',
  login_to_play: '登錄遊戲',
  yes: '是的',
  how_to_play: '怎麼玩',
  help: '幫助',
  start_bet_round_failed: '開始比賽失敗',
  cashout_failed: '提現失敗',
  auto_bet_round: '承諾成功',
  play_now2: '現在播放',
  under_min_bet: '低於最小賭注',
  invalid_bet_params: '無效的播放參數',
  game_not_available_now: '遊戲現在不可用',
  insufficient_amount: '金額不足',
  invalid_bet_state: '無效播放狀態',
  win_rate: '贏率',
  welcome_to_Coco_game: '歡迎來到 Ohplay.club',
  invalid_email_format: '電子郵件格式無效',
  resend_after: '之後重新發送',
  second: '第二',
  only_available_at_large_resolutions: '僅適用於 > 1600px (xl) 的大分辨率',
  provably_fairs: '事實證明公平',
  Fairness: '公平',
  become_a_coco: '成為可可',
  club_member: '俱樂部會員',
  vip_club_subtitle1: '玩時間最長和投入程度最高的玩家將收到獨家邀請',
  vip_club_subtitle2: '這為其會員提供了優質的體驗和獎金。',
  your_vip_progress: '您的 VIP 進度',
  your_vip_progress_tooltip: '請先登錄然後查看您的 VIP 進度',
  total_wager: '全面承諾',
  your_vip_progress_description: '所有承諾均按當前匯率轉換為美元',
  how_does_it_work: '它是如何工作的？',
  the_rewards: '獎勵',
  frequently_asked_questions: '經常問的問題',
  vip_frequent_questions_subtitle:
    '如果您有任何疑問，我們鼓勵您聯繫支持人員或您的 VIP 經理',
  login: '登錄',
  to_see_your_vip_progress: '查看您的 VIP 進度',
  original_wager_rate: '原創遊戲：1 XP = 1 美元賭注 * 賭場優勢 * 100 / 2',
  provider_wager_rate: '提供者遊戲：1 XP = 1 美元賭注',
  all_wagers_converted_to_usd: '賭注 1.00 美元 = 1XP',
  vip_club_benefit: 'VIP俱樂部優惠',
  vip_club_details: '貴賓俱樂部詳情',
  benefit_table: '效益表',
  wager_contest: '投注大賽',
  game_of_the_day: '每日遊戲',
  play_to_earn: '玩遊戲賺取賭注',
  time_remaining: '剩餘時間',
  rank: '秩',
  wagered: '賭注',
  prize: '獎',
  wager_contest_information: '挑戰資訊',
  rule: '規則',
  gamebank_chart: '遊戲庫圖表',
  next_round: '下一輪',
  start_auto_bet: '開始自動投注',
  understand_trend_chart: '了解趨勢圖',
  round_has_been_bet: '本輪已投注，下輪取消',
  crash_server_disconnect: '服務器崩潰斷開連接...',
  coco_info:
    '本網站由 Orisun N.V. 所有並運營，該公司是根據庫拉索島法律註冊成立的公司，註冊號為 163631，註冊地址 Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaça.',
  crash_result: '崩潰結果',
  wagers: '戲劇',
  reset_after: '之後重置',
  daily_wagering_contest_rules: '每日比賽規則',
  the_daily_race: '每日競賽比賽每天從 UTC 時間 00:00 到 23:59 進行，從',
  daily_contest_prize_pool: '每日競賽獎池',
  straight_flush_with_jack: '与杰克同花',
  straight_flush: '同花順',
  four_of_a_kind: '同一样四个',
  full_house: '客滿',
  flush: '沖洗',
  straight: '直的',
  three_of_a_kind: '三樣一類',
  tow_pair: '兩對',
  pair_of_jacks_or_better: '一对 J 或更好',
  you_won: '你贏了',
  show_all: '顯示所有',
  personal: '個人的',
  no_notifications_at_the_moment: '目前沒有任何通知。 請稍後再回來查看。',
  mode: '模式',
  contrast: '對比',
  direction: '方向',
  layout: '佈局',
  stretch: '拉緊',
  presets: '預設',
  status_success: '狀態成功',
  status_pending: '狀態待定',
  swap_successfully: '兌換成功！',
  swap_failed: '兌換失敗，請稍後再試',
  coco_member: '成為 Pi.game 會員',
  play_your_favorite_games: '玩你最喜歡的遊戲',
  instantly_sign_up: '立即註冊並開始玩遊戲或體育博彩。',
  unlock_further_benefits: '享受遊戲的樂趣，達到新的水平並解鎖更多的好處',
  rank_claim_bonus: '提高您的排名並領取獎金',
  go_up_to_the_next_rank: '晉級下一個等級！ 每個級別都有其獎勵和更多機會！',
  you_start_your_gaming: '您從第一名（新人）開始您的遊戲之路。',
  the_more_you_play:
    '您玩的越多，您的排名就越高。 根據在 Ohplay.club 上玩時的下注金額而增加。 所有投注均按當前匯率轉換為美元',
  mark_all_as_read: '標記為已讀',
  eligible_VIP_member: '我有資格成為 VIP 會員嗎？',
  how_much_has_coco_given_out_in_bonuses: 'Ohplay 發放了多少獎金？',
  why_is_Coco_VIP_program_the_best: '為什麼Ohplay的VIP計劃是最好的？',
  what_does_affect_the_weekly_monthly: '什麼影響每週/每月獎金',
  information_to_become_a_VIP_member:
    '我需要提供任何額外信息才能成為 VIP 會員嗎？',
  coco_VIP_telegram_channel: '在哪裡可以找到 Ohplay VIP Telegram 頻道？',
  one_min: '1分鐘',
  five_min: '5分鐘',
  one_hour: '1小時',
  for_hours: '4個小時',
  one_day: '1天',
  investment: '投資',
  my_investment: '我的投資',
  what_upp: '最近怎麼樣 ？？？',
  hide: '隱藏',
  play: '玩',
  stake_and_earn: '玩和賺',
  platform: '平台',
  //
  vip_level_comparison: 'VIP等級比較',
  bronze: '青銅',
  silver: '銀',
  gold: '金子',
  platinum: '鉑',
  diamond: '鑽石',
  level_up_bonus: '升級獎勵',
  surprise_box: '驚喜盒',
  free_spin_wheel_enable: '自由旋轉輪啟用',
  surprise_box_upgraded: '驚喜盒升級',
  weekly_bonus: '每週獎金',
  chat_enable: '聊天啟用',
  upload_avatar_enable: '上傳頭像啟用',
  vip_support_enable: 'VIP 支持啟用',
  surprise_box_upgraded_2: '驚喜盒子升級（高達180萬USDT）',
  where_coco_vip_telegram_channel: '在哪裡可以找到 Ohplay VIP Telegram 頻道？',
  me: '我',
  result: '結果',
  crush_of_crash: '崩潰的粉碎',
  // Banner new
  earn_real_money: '賺真錢',
  the_daily_game: '每日遊戲',
  earn_everyday_with_original_games: '每天透過原創遊戲賺錢',
  daily_race: '每日比賽',
  wager: '玩',
  and: '和',
  everyday: '每天',
  crush: '压碎',
  the_crash: '碰撞',
  wager_and_win_everyday: '每天下注並獲勝',
  show_off_your_prediction_skills: '展現你的預測技巧',
  deposit_now2: '立即存款',
  on_1st_deposit: '第一次存款时',
  // Just for you
  free_play: '免費玩',
  with_fun_coin: '使用FUN幣',
  show_me: '給我看看',
  low_risk_high_rewards: '低風險, 高回報',
  prize_100000: '獎金 10 萬美元',
  win_1000x_jackpot: '赢得 1000 倍大奖',
  // Notification
  balance_change: '平衡變化',
  your_casino: '您的遊戲中心',
  // Referral
  spread_the_word_and: '傳播世界並',
  earn_reward: '賺取獎勵',
  campaign_name: '活動名稱',
  id_number: '身分證號',
  registered: '掛號的',
  total_deposits: '存款總額',
  last_deposit: '最後存款',
  vip_point: '貴賓點',
  commission: '委員會',
  referral: '推薦',
  register_date: '註冊日期',
  display_name: '顯示名稱',
  agents: '代理商',
  transaction_date: '交易日期',
  transaction_type: '交易類型',
  export: '出口',
  campaigns: '活動',
  create_a_new_campaign: '建立新活動',
  claim_income: '索賠收入',
  per_players: '每個玩家',
  per_agents: '每個代理',
  per_day: '每天',
  per_week: '每個星期',
  per_month: '每月',
  per_country: '每個國家/地區',
  date: '日期',
  total_income: '總獎勵',
  total_user_register: 'T用戶註冊總數',
  total_user_bet: '用戶總遊玩次數',
  total_transaction: '交易總額',
  refer_description:
    '推荐朋友加入 Ohplay.club 的联盟计划，并为每个通过您的链接注册和玩游戏的用户赚取佣金，无论其结果如何',
  download_banner: '下載橫幅',
  search: '搜尋',
  clicks: '點擊次數',
  referrals: '推薦人',
  deposit_users: '儲值用戶',
  total_commission: '佣金總額',
  commission_rate: '佣金率',
  features: '特徵',
  link: '關聯',
  code: '程式碼',
  today: '今天',
  this_week: '本星期',
  last_week: '上個星期',
  overall: '全面的',
  commission_available: '佣金可用',
  claim: '宣稱',
  log_in: '登入',
  to_see_your_rank: '查看您的排名',
  start_in: '開始於...',
  reconnect: '重新連接',
  controls: '控制',
  leaderboard: '排行榜',
  bet_next: '玩下一個',
  betting: '玩',
  // Tip
  message_is_required: '需要留言',
  tip_with_any_currency_in_CoCo: '使用 Ohplay 中任何貨幣的小費',
  receiver_id: '接收者ID',
  enter_user_id: '輸入使用者 ID',
  transaction_fee: '手續費',
  message: '訊息',
  give_your_friends_some_messages_here: '在這裡給你的朋友留言...',
  continue: '繼續',
  successfully_tipped: '小費成功',
  you_are_tipping_to: '您正在給小費',
  your_tip_request_has_been_confirmed: '您的小費請求已得到確認。',
  you_can_track_the_progress_on_the_wallet_transaction_history:
    '您可以在錢包交易歷史記錄中追蹤進度。',
  view_history: '查看歷史記錄',
  tip_failed: '提示失敗',
  your_tip_request_was_unsuccessful_due_to_an_abnormality:
    '由於異常情況，您的小費請求未成功。',
  please_try_again_later: '請稍後再試。',
  ok_i_got_it: '好，我知道了',
  tip_information: '提示訊息',
  password_is_required: '密碼是必需的',
  tip_verification: '提示驗證',
  authentication_secure: '身份驗證安全',
  to_process_tipping_please: '如需處理小費，請',
  first_to_secure_your_assets: '首先確保您的資產安全',
  step_verification: '兩步驟驗證',
  enter_your_login_password: '輸入您的登入密碼',
  enter_the_2fa_code_appear_on_your_google_authenticator_app:
    '輸入您的 Google 身份驗證器應用程式上顯示的 2FA 代碼',
  authorize: '授權',
  // 30/09
  sign_up_in_and_earn: '注册/加入并赚取',
  learn_more_about_our_affiliate_program: '了解有关我们的联盟计划的更多信息',
  referral_link: '推荐链接',
  create: '创造',
  referral_users: '推荐用户',
  registered_date: '注册日期',
  send_tip: '发送提示',
  delete: '删除',
  accept: '接受',
  close: '关闭',
  member_downline: '会员下线',
  new_player: '新玩家',
  claim_commission_failed: '索赔佣金失败',
  claim_commission_successfully: '成功领取佣金',
  create_campaign_successfully: '成功创建营销活动',
  create_campaign_failed: '创建营销活动失败',
  any_date: '任何日期',
  // Mini CoCo
  stop_if_single_win_exceeds_by: '如果单赢超过则停止',
  // Host
  show_filter: '显示过滤器',
  filter: '筛选',
  default_campaign: '默认活动',
  select_all: '全选',
  role: '角色',
  hello_world: '你好世界',
  // GameBank => Overview
  gb_capacity: 'GB 容量',
  game_bank_capacity: '游戏库容量',
  the_maximum_amount_that_you_can_invest: '您可以投资的最大金额。',
  // Crash
  finish_bet: '完成投注',
  finishing_bet: '结束投注',
  // Referral
  delete_invitation: '删除邀请',
  you_had_been_invited_to_become_an_agent_by: '您已被邀请成为代理商y',
  confirming_to_delete_this_invitation:
    '确认删除此邀请后，此操作将无法撤消。 还想决定吗？',
  successfully_accepted_invitation: '您已成功接受邀请',
  the_house: '房子',
  invited_you_to_become_an_agent: '诚邀您成为代理商',
  campaign_name_1: '活动名称 1',
  available_commission: '可用佣金',
  there_are_no_commission: '没有佣金',
  please: '请',
  login2: '登录',
  to_see_your_rewards: '看到你的奖励',
  you_have_successfully_accepted_the_invitation: '您已成功接受邀请',
  //
  downline_report: '下线报告',
  referral_faq_subscription: '有关联属网络营销计划的更多详细信息，请联系 ',
  internet_connection_restored: '互联网连接已恢复',
  lost_connect_to_network: '失去网络连接',
  // 10/10
  user_profile: '유저 프로필',
  leave_a_tip: '팁을 남기다',
  your_commission_rate: '您的佣金率',
  affiliate_terms_conditions: '聯盟條款 & 狀況',
  REFERRAL_CODE_OR_NAME_EXISTS: '캠페인 이름 또는 추천 코드가 이미 존재합니다.',
  swap_unavailable:
    '스왑 쌍은 현재 지원되지 않습니다. 나중에 다시 시도해 주세요.',
  please_enter_swap_amount: '스왑금액을 입력해주세요',
  become_our_agent_Contact_business: '成為我們的代理商？ 聯絡業務：',
  earn_commission_rewards:
    '친구들이 우리 게임에 베팅할 때마다 커미션 보상을 받으세요',
  turn_your_friends_and_followers_into_weekly_commissions:
    '通过 Ohplay 的联属计划，将您的朋友和关注者转化为每周佣金。 简单、有利可图且完全透明',
  // FAQ
  why_should_i_be_a_Coco_affiliate: '我為什麼要成為 Ohplay 會員？',
  adding_our_link_and_or_banner_to_your_website:
    '如果您有一个具有一定流量的网站或社交渠道，为什么不最大限度地发挥其潜力呢？ 通过将我们的链接和/或横幅添加到您的网站，您可以创建一种新的、有效的方式来立即赚取额外收入.',
  how_do_I_get_paid_for_being_an_affiliate: '作為會員我如何獲得報酬？',
  the_banner_or_link_you_create_contains_your_personal_tracking_code:
    '您创建的横幅或链接包含您的个人跟踪代码. 当玩家访问《Ohplay》时，此代码会存储在他们计算机上的 cookie 中. 当用户注册时，我们会根据您的推荐来识别他们. 该玩家将与您永久关联，您将终生从他们的活动中赚取佣金.',
  how_a_new_player_linked_to_me: '新玩家如何與我連結？',
  how_exactly_is_my_affiliate_commission_calculated:
    '我的聯盟行銷佣金到底是如何計算的？',
  the_commission_for_each_bet: `每笔投注的佣金根据以下公式计算： 佣金 = 投注额 * 游戏庄家优势 * 佣金率 其中：
      - 총 베팅 금액: 제휴사가 추천한 모든 사용자가 생성한 총 게임 베팅 금액입니다.
      - 庄家优势：平均损失与初始赌注的比率。 每场比赛的庄家优势定义为 1 减去 RTP（返还给玩家）.
      - 커미션 비율: 비율은 사용자의 제휴 역할에 따라 다르며 제휴 사이트에 표시됩니다.`,
  how_to_join_the_affiliate_program: '如何加入聯盟計畫？',
  if_you_already_have_a_Coco_account: `如果您已經有 Ohplay 帳戶，只需按照以下步驟操作：
      1. 造訪聯盟頁面.
      2. 使用您獨特的推薦代碼產生新的活動。
      3. 在您的網站、社交媒體或任何高流量平台上分享您的推薦鏈接.
      4. 從您推薦的用戶領取每週佣金。`,
  how_can_i_keep_track_of_my_performance: '我如何追蹤我的表現？',
  once_your_affiliate_link_is_generated:
    '產生聯盟行銷連結後，您可以登入聯盟行銷儀表板。 在那裡，您可以查看所有統計數據，並可以根據各種因素過濾報告中的資訊。',
  at_the_start_of_each_new_week:
    '在每個新的一周開始時，系統都會計算您的佣金並以 COD 代幣的形式供您領取。 您可以輕鬆地將收入提取到您的帳戶錢包中。',
  i_have_big_audience_how_i_can_get_special_deals:
    '我有很多觀眾，如何獲得特別優惠？',
  if_you_have_a_website_with_substantial_traffic:
    '如果您拥有流量较大的网站或拥有大量受众的社交媒体帐户，您可以通过business@Ohplay.club 与我们联系以获得独家优惠.',
  // Crash
  maximum_is_9900: `最大值为“9900”`,
  minium_is_101: `最小值为“1.01”`,
  insufficient_currency: '没有足够的资金',
  this_game_does_not_support_this_currency:
    '该游戏不支持该货币，请切换至其他资产继续游戏.',
  unknown: '未知',
  you_should_verify_account_first_before_tipping_your_friends:
    '您應該先驗證帳戶 \n 在給朋友小費之前',
  must_be_only_digits: '必须只有数字',
  min_length_ID_at_least_14_number: 'ID 的最小长度至少为 14 个数字',
  receiver_is_required: '需要接收器',
  tip_amount_must_specify_a_number: '小费金额必须指定一个数字',
  tip_amount_must_greater_than_0: '小费金额必须大于0',
  tip_amount_is_required: '小费金额为必填项',
  how_to_see_other_ID: '如何查看其他ID ?',
  get_your_friends_ID_from_their_profiles: '从朋友的个人资料中获取他们的 ID.',
  tips_amount: '小费金额',
  total_spending_amount: '花费总额',
  withdrawing_your_balance: '您应该在提取余额之前验证您的帐户',
  pls_enter_code_send_to_tel: '请输入发送至您手机的验证码',
  please_enter_withdraw_address: '請輸入提現地址',
  your_profile_had_been_verified: '您的個人資料已經過驗證',
  SWAP_UNAVAILABLE: '餘額不足e',
  // Maintain
  this_page_is_under_maintenance: '對不起！ 該頁面正在維護中',
  please_give_us_some_minutes_to_fix_the_issue: '請給我們一些時間來解決該問題',
  // 404
  we_cant_find_that_page: `哎呀！ 我們找不到該頁面。`,
  you_can_still_enjoy_the_best_casino_experience_at_Coco: `不過不用擔心，您仍然可以在 Ohplay.club 享受最好的賭場體驗。`,
  //
  region_prohibited: '禁止地區',
  coco_game_is_not_available_in_your_region:
    '抱歉，Ohplay.club 在您所在的地區不可用。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country:
    '由於我們的遊戲許可證，我們無法接受您所在國家/地區的玩家。 如果您需要進一步協助，請透過 support@Ohplay.club 與我們聯繫。',
  congrats: '恭喜',
  won_the_most: '贏得最多！',
  coco_took_it_all: '可可全都拿走了！',
  page: '頁',
  refresh: '重新整理',
  // Popup
  register_to_get: '註冊即可獲得',
  player_to_earn_the: '玩家獲得',
  $10_prize: '獎金10美元',
  sign_up_now: '立即註冊',
  // Popup 2
  you_receive: '你收到',
  weekly: '每週',
  see_detail: '看詳情',
  swap_amount_need_to_larger_than_min: '掉期金額需大於最少金額',
  crypto_play: '加密遊戲',
  fun_play: '有趣的遊戲',
  // Popup 3
  play_with: '玩',
  earn: '賺',
  real: '真實的',
  dollars: '美元',
  big_wins: '大胜利',
  what_is_gift_code: '什么是礼品码？',
  gift_code_info:
    '礼品代码就像由字母和数字组成的特殊代码。 当您兑换此代码时，您将获得特别奖励',
  gift_code_cod:
    '每个礼品代码都有其自己的价值和持续时间。 所有奖励均以 COD 代币的形式支付',
  there_are_various_ways_to_get_Coco_gift_codes:
    '獲得 Ohplay 禮品代碼的方式有很多種，例如參加獨家社交媒體活動、社區活動、獎金或特殊任務。',
  how_to_get_Coco_gift_code: '如何获取Ohplay礼品码？',
  how_to_use_Coco_gift_code: '如何使用Ohplay礼品码？',
  simply_enter_the_gift_code:
    '只需输入礼品代码，奖励就会自动添加到您的余额中。',
  giftcode_required: '需要礼品码',
  bonus_code_claimed_successfully: '红利代码成功领取',
  claim_your_free_reward: '领取您的免费奖励',
  enter_your_giftcode: '输入您的礼品代码',
  claim_bonus: '领取奖金',
  what_is_giftcode: '什么是礼品码',
  what_is_COCO_giftcode: 'Ohplay 礼品码是什么？',
  got_it: '知道了',
  won: '韓元',

  // 8/12
  use_your_COD_gift_to_bet_and_win_at_Cocogame:
    '使用您的 Coco 禮物在 Coco.game 下注並獲勝',
  gift_code: '禮品碼',
  // 15/12
  mini_Coco_is_Allways_3x3_Slot_machine_game:
    'Mini Ohplay 是 27 線 3x3 老虎機遊戲。',
  rule_content: `規則：符號從第一列開始，從左到右連續。 賠付僅計算一行中的最高值。`,
  jackpot2: '累積獎金',
  explode_if_you_collect_3_WILD: '如果您收集 3 個百搭就會爆炸。',
  wild_can_subsitute_for_all_symbols: '百搭可以代替所有符號。',
  to_coco_vip_club: '前往Ohplay贵宾俱乐部',
  welcome_vip_club_text:
    '玩时间最长和赌注最高的玩家将收到 VIP 俱乐部的独家邀请。 我们为我们的会员提供优质的体验和奖金。',
  welcome_to_only: '欢迎来到',
  welcome_only: '欢迎',
  to_join_club: '加入我们的 VIP 俱乐部',
  sign_in_or_sign_up: '登录注册',
  how_vip_club_work: 'VIP俱乐部如何运作？',
  ranking_system_title: 'Ohplay VIP排名系统',
  ranking_system_text:
    '加入我们的平台排名系统，解锁充满机遇和认可的世界！ 立即加入我们，提升您的个人资料、玩我们的游戏并享受成为杰出参与者的好处。 不要错过获得认可的机会——立即成为成功故事的一部分！',
  start_your_referrals: '开始您的推荐',

  // 3/1
  series: '系列',
  multiple: '多種的',
  result_will_be_display_here: '結果將顯示在這裡',
  bet_head: '賭頭',
  bet_tail: '賭尾',
  instant: '立即的',
  multiply: '乘',
  coin_flip_instant_mode_desc: '只需投注正面或尾部或自動設定投注',
  auto_bet_setup: '自動投注設定',
  you_win_title: '你贏了',
  select_network_to_continue: '若要繼續，請選擇網絡',
  ensure_withdraw_address:
    '請確保您輸入的地址正確； 否則，您的資金將丟失且無法恢復。',
  click_bet_to_choose_coin_side: '點擊投注選擇硬幣面',

  copied_share_in_chat: '已複製，在聊天中分享！',
  copied_share_online: '已複製，線上分享！',
  game_id: '遊戲ID',
  server_seed_hash: '伺服器種子（哈希）',
  client_seed: '客戶種子',
  nonce: '隨機數',
  about_COD: '關於貨到付款',
  coco_dollar_info:
    'COD（Coco Dollar）是COCO.GAME推出的特殊貨幣。 透過 COD，您可以參與遊戲或向其他玩家提供提示。',
  can_COD_be_exchanged_to_any_other_currency: 'COD 可以兌換成其他貨幣嗎？',
  you_can_swap_cod:
    '您可以隨時使用 Coco Swap 根據當前匯率將 COD 兌換為其他貨幣。',
  get_daily_bonus_up_to_5000_COD: '每日獎金高達 5,000 COD',
  by_playing_games_tips_coin_dropping_and_rain_bonus:
    '透過玩遊戲、提示、投幣和雨獎金。',
  see_how: '怎麼看',
  how_to_unlock_COD: '如何解鎖 COD',
  locked_COD_is_earned_through_specific_bonuses_like_deposit_bonuses:
    '鎖定的 COD 是透過存款獎金等特定獎金獲得的。',
  unlocking_COD_is_simple:
    '解鎖COD很簡單； 它類似於返傭，並根據您的賭注按比例解鎖。',

  // Chat
  please_slow_down: '請減速慢行。',
  links_must_include_your_website_domain: '連結必須包含您的網站網域。',
  chat_is_hidden: '聊天已隱藏',
  show_chat: '顯示聊天',
  pinned_message: '固定訊息',

  // 12/01
  deposit_bonus: '存款獎金',
  rakeback: '回扣',
  spin: '旋轉',
  get_extra_300_bonus_on_minimum_of_10_USDT_deposit:
    '最低 10.00 USDT 存款即可獲得額外 300% 獎金',
  min_deposit: '最低存款：',
  max_claim: '最大索賠：',
  withdraw_successful: '提現成功',
  your_withdrawal_request_has_been_confirmed:
    '您的提款請求已被確認。 您可以在錢包交易歷史記錄中追蹤進度。',
  pi_balance_in_wallet: '錢包中的 Pi 餘額',
  your_Pi_address: '你的 Pi 位址',
  fill_in_carefully_according_to_the_specific_currency: '根據具體幣種認真填寫',
  you_will_receive_Pi_to_your_address_within_1_working_day:
    '您將在 1 個工作天內收到 Pi 到您的地址。',
  please_make_sure_you_enter_the_correct_Pi_address_otherwise_your_funds_will_not_be_reversed:
    '請確保輸入正確的Pi地址； 否則，您的資金將不會被撤回。',
  current_capacity: '電流容量',
  what_is_CODG_capacity: '什麼是 CODG 容量？',
  rng_certificated_by: 'RNG 認證機構',

  // 08/03
  buy_crypto: '購買加密貨幣',
  buy_fiat: '選擇貨幣法定貨幣',
  refund: '退款',
  bet_histories: '投注歷史',
  round_histories: '回合歷史',
  round_id: '回合ID',
  bet_place: '投注地點',
  profit: '利潤',
  top: '頂部',
  top_profit: '最高利潤',
  leader_board: '排行榜',
  how_to_join: '如何加入',
  see_more: '看更多',
  top_winning_streak: '最高連勝紀錄',
  top_losing_streak: '連敗紀錄最高',
  place: '地方',
  streak: '條紋',
  total_prized: '獎金總額',
  top_reward: '最高獎勵',
  top_10_winners: '前 10 名獲獎者',
  monthly_bonus: '每月獎金',
  available_rakeback: '可用回扣',
  no_rakeback_yet: '還沒有回扣',
  claim_my_rakeback: '索取我的回扣',
  wager_required: '需要下注',
  receive_every: '收到每',
  claim_bonus_successfully: '成功領取獎金',
  claim_bonus_failed: '領取獎金失敗，請重試！',
  no_bonus_available: '沒有可用獎金',
  play_to_earn_bonus: '玩遊戲並開始下注以獲得獎金',
  play_and_wager: '遊戲與投注',
  claim_all: '索取全部',
  duration: '期間',
  your_total_bonus: '您的總獎金',
  roll_under: '滾下',
  game_result_will_be_displayed_here: '遊戲結果將顯示在這裡。',
  level_up_bonus_upgrade: '升級獎勵升級',
  _24h_live_support: '24小時即時支援',
  bonus_growth: '獎金增長',
  xp_required: '需要 XP',

  account_not_conditions: '下注更多才能提款',
  play_increase_wager: '收到獎金後，您需要達到 120,000 美元的下注金額才能提款',
  platinum_1_to_6: '鉑金 I - VI',
  diamond_1_to_6: '鑽石 I - VI',

  // CODG
  total_received_CODG: '收到的 CODG 總額',
  unlocked_amount: '解鎖金額',
  how_to_unlock_CODG: '如何解鎖CODG？',
  swap_and_play: '交換並玩',
  unlocked_CODG: '您可以將解鎖的 CODG 兌換成其他貨幣來玩遊戲並賺取收益',
  available_to_swap: '可以交換',
  there_are_no_redeemable_currencies: '沒有可兌換貨幣',
  first_deposit: '首次存款',
  _300_bonus: '300% 獎金',
  get_up_to: '起身到',
  second_deposit: '第二次存款',
  _200_bonus: '200% 獎金',
  third_deposit: '第三次存款',
  _150_bonus: '150% 獎金',
  fourth_deposit: '第四次存款',
  _100_bonus: '100% 獎金',
  deposit_promotion: '存款優惠',
  unlock_codg_description:
    '提供特殊的 4 級存款匹配獎金，提供了賺取我們的獨家代幣 CODG 額外獎勵的絕佳機會。 抓住這一優勢，放大您的資金，並透過我們的平台踏上激動人心的旅程。',

  // Buy Crypto
  our_excited_bonus: '我們為您準備的令人興奮的獎勵之旅',
  something_is_wrong: '有問題。',
  disclaimer: '免責聲明',
  the_above_third_party:
    '上述第三方服務可用於購買可用於在 Coco 上玩的加密貨幣。 透過在他們的平台上註冊，您也接受他們的服務條款，並且需要通過他們的 KYC 流程，該流程獨立於我們的流程運作。',
  buy_with_transFI: '透過 TransFI 購買',
  order_processing: '訂單處理',
  your: '你的',
  buying_order_is_currently: '目前正在處理購買訂單。 請稍等幾分鐘',
  // Deposit
  transfer_pi: '傳輸Pi',
  transfer_Pi_to_the_address_above_which_is_our_agent_address:
    '將 Pi 轉帳到上面的位址，這是我們的代理地址。',
  meet_our_live_support: '認識我們的即時支持',
  click_the_i_transferred_Pi: '點擊“我轉移了 Pi”按鈕來滿足我們的即時支援。',
  confirm_transaction: '確認交易',
  send_your_transaction_and_screenshot_to_the_chat_box_as_confirmation:
    '將您的交易和螢幕截圖傳送到聊天框作為確認。',
  transfer_Pi_to_this_address: '將Pi轉入該位址',
  how_to_deposit_Pi: '如何充值Pi？',
  i_transferred_Pi: '我轉移了Pi',
  earn_an_extra_300_bonus_with_a_minimum_deposit_of_1000_USD:
    '最低存款 10.00 美元即可賺取額外 300% 獎金。',
  // Tip
  max_length_ID_not_more_than_20_number: 'ID最大長度不超過20個數字',
  receiver: '接收者',
  transaction_fee_rate: '交易費率',
  transaction_fee_amount: '交易費用金額',
  you_need_to_wager: '你需要下注',
  // Withdraw
  more_to_withdraw_due_to_the_bonus_money_policies:
    '由於獎金政策，可提取更多資金',
  pi_address_is_required: 'Pi 位址為必填項',
  pi_Amount_must_specify_a_number: 'Pi 金額必須指定一個數字',
  pi_amount_must_greater_than_10_Pi: 'Pi 金額必須大於 10 Pi',
  pi_amount_must_less_than_10000_Pi: 'Pi 金額必須小於 10.000 Pi',
  pi_amount_is_required: 'Pi 金額為必填項',
  withdraw_successfully_We_will_contact_you_later:
    '提現成功！ 我們稍後會聯絡您',
  hey_there: '嘿',
  please_process_my_Pi_coin_withdrawal_as_follows:
    '請按如下方式處理我的Pi幣提現',
  fees: '費用',
  input_your_Pi_address: '輸入你的Pi地址',
  input_number_of_Pi: '輸入Pi的個數',
  lucky_win: '幸運中獎',
  can_not_get_bet_detail: '無法取得投注詳情！',

  // Popup currency
  cannot_be_used_in_this_game:
    '本遊戲中不能用於真實遊戲模式。 請切換到其他資產。',
  the_selected_currency_will_be_displayed_in: '所選貨幣將顯示在',
  and_if_you_change_currency_while_playing:
    '如果您在玩遊戲時更改貨幣，遊戲將刷新並重新啟動。',

  // CODG
  about_CODG: '關於CODG',
  CODG_is_the_reward_token_of_Cocogame:
    'CODG是Coco.game的獎勵代幣。 您與平台的互動越多，您賺取的 CODG 就越多，以豐富您的資產。',
  can_CODG_be_swapped_for_other_currencies: 'CODG可以兌換成其他貨幣嗎？',
  you_can_swap_your_unlocked_CODG:
    '您可以根據當前匯率將解鎖的CODG兌換成其他貨幣。 但鎖定的CODG無法更換； 只能用於玩平台內的各種遊戲。',
  // GameBank => Overview
  the_maximum_share_of_all_users: '所有用戶的最大份額',
  your_maximum_share: '您的最大份額',
  total_available_investing_amount: '可用投資總額',
  your_available_investing_amount: '您可用的投資金額',
  // Game bank => InvestInformation
  what_is_investing_in_coco_gamebank: '什麼是投資Coco遊戲銀行',
  anyone_can_add_money_to_the_gamebank:
    '任何人都可以向遊戲銀行充值並加入莊家投注。 您的收入由遊戲銀行總利潤乘以您的份額決定。',
  why_should_i_invest: '我為什麼要投資？',
  profitable: '有利可圖',
  do_you_believe_in_the_bad_luck_of_other_gamblers:
    '你相信其他賭徒的運氣不好嗎？ 如果是這樣，成為房子是賺取被動收入的好方法。',
  real_yield: '實質報酬率',
  you_will_receive_profit_in_the_same_token_that_you_invest:
    '您將以與您投資相同的代幣形式獲得利潤。',
  analysis_tool: '分析工具',
  access_gamebank_value: '造訪 Gamebank 價值、股票、歷史和趨勢以做出最佳決策。',
  understand_the_risk_involved: '了解所涉及的風險',
  keep_in_mind_that_the_gamebank_value_may_temporarily_decrease_due_to_gamblers_wins:
    '請記住，遊戲銀行的價值可能會因賭徒的獲勝而暫時減少，因此您可能不會立即看到利潤。 謹慎投資。',
  invest_information: '投資資訊',
  details: '細節',
  // Gift code
  gift_received_successfully: '已領取禮品碼',
  congratulations_you_have_just_received: '恭喜！ 您剛剛收到：',

  cod_successfully: '貨到付款成功',
  // Transaction
  receiver_nickname: '接收者暱稱',
  sender_nickname: '寄件者暱稱',
  tip_successful: '提示成功',
  // JFU
  bet_win: '賭贏',
  challenge: '挑戰',
  cash_it_out: '兌現',
  tournament: '比賽',
  november_exclusive: '十一月獨家',
  new_game: '新遊戲',
  you_need_to_reach_the_rank_to_unlock_this_feature:
    '您需要達到{{val}}等級來解鎖此功能。 檢查您的排名',
  play_with_balance_in: '在游戏中保持平衡',

  // Host
  ohplay_vip_club: '哦玩VIP俱樂部',
  pi_vip_club: '圓周率貴賓俱樂部',
  to_pi_vip_club: '前往 Pi VIP 俱樂部',
  to_ohplay_vip_club: '前往 Ohplay VIP 俱樂部',
  welcome_to_Ohplay: '歡迎來到 Ohplay.club',
  welcome_to_Pi: '歡迎來到Pi.game',
  become_a_pi: '成為圓周率',
  become_a_ohplay: '成為Ohplay',
  pi_member: '成為 Pi 會員',
  ohplay_member: '成為Ohplay會員',
  why_is_Pi_VIP_program_the_best: '為什麼 Pi VIP 計畫是最好的？',
  why_is_Ohplay_VIP_program_the_best: '為什麼Ohplay VIP計畫是最好的？',
  how_much_has_pi_given_out_in_bonuses: 'Pi 發放了多少獎金？',
  how_much_has_ohplay_given_out_in_bonuses: 'Ohplay 發放了多少獎金？',
  pi_VIP_telegram_channel: '在哪裡可以找到 Pi VIP Telegram 頻道？',
  ohplay_VIP_telegram_channel: '在哪裡可以找到 Ohplay VIP Telegram 頻道？',
  tip_with_any_currency_in_Pi: '使用 Pi 中的任何貨幣支付小費',
  tip_with_any_currency_in_Ohplay: 'Ohplay 中使用任何貨幣的小費',
  why_should_i_be_a_Pi_affiliate: '我為什麼要成為 Pi 會員？',
  why_should_i_be_a_Ohplay_affiliate: '我為什麼要成為 Ohplay 會員？',
  if_you_already_have_a_Pi_account: `如果您已經有 Pi 帳戶，只需按照以下步驟操作：
       1. 造訪聯盟頁面。
       2. 使用您唯一的推薦代碼產生新的活動。
       3. 在您的網站、社群媒體或任何高流量平台上分享您的推薦連結。
       4. 從您推薦的用戶領取每週佣金。`,
  if_you_already_have_a_Ohplay_account: `如果您已經有 Ohplay 帳戶，只需按照以下步驟操作：
       1. 造訪聯盟頁面。
       2. 使用您唯一的推薦代碼產生新的活動。
       3. 在您的網站、社群媒體或任何高流量平台上分享您的推薦連結。
       4. 從您推薦的用戶領取每週佣金。`,
  you_can_still_enjoy_the_best_casino_experience_at_Pi: `不過不用擔心，您仍然可以在 Pi.game 享受最好的賭場體驗`,
  you_can_still_enjoy_the_best_casino_experience_at_Ohplay: `不過不用擔心，您仍然可以在 Ohplay.club 享受最好的賭場體驗`,
  pi_game_is_not_available_in_your_region:
    '抱歉，Pi.game 在您所在的地區不可用。',
  ohplay_game_is_not_available_in_your_region:
    '抱歉，您所在的地區無法使用 Ohplay.club。',
  pi_took_it_all: 'Pi 奪走了一切！',
  ohplay_took_it_all: 'Ohplay 奪走了一切！',
  there_are_various_ways_to_get_pi_gift_codes:
    '取得 Pi 禮品代碼的方式有很多種，例如參加獨家社群媒體活動、社區活動、獎金或特殊任務。',
  there_are_various_ways_to_get_ohplay_gift_codes:
    '獲得 Ohplay 禮品代碼的方式有很多種，例如參加獨家社群媒體活動、社群活動、獎金或特殊任務。',
  how_to_get_Pi_gift_code: '如何取得Coco.game禮包碼？',
  how_to_get_Ohplay_gift_code: '如何取得Coco.game禮包碼？',
  gift_code_pi: 'Pi.game',
  gift_code_ohplay: 'Ohplay.club',
  how_to_use_pi_gift_code: '如何使用Pi.game禮包碼？',
  how_to_use_ohplay_gift_code: '如何使用Ohplay.club禮品碼？',
  what_is_pi_giftcode: 'Pi 禮品代碼是什麼？?',
  what_is_ohplay_giftcode: 'Ohplay 禮包碼是什麼',
  use_your_COD_gift_to_bet_and_win_at_pi:
    '使用您的 Pi 禮物在 Pi.game 玩遊戲並獲勝',
  use_your_COD_gift_to_bet_and_win_at_ohplay:
    '使用您的 Ohplay 禮物在 Ohplay.club 下注並獲勝',
  the_above_third_party_pi:
    '上述第三方服務可用於購買可用於在 Pi 上玩的加密貨幣。 透過在他們的平台上註冊，您也接受他們的服務條款，並且需要通過他們的 KYC 流程，該流程獨立於我們的流程運作。',
  the_above_third_party_ohplay:
    '上述第三方服務可用於購買可用於在 Ohplay 上玩的加密貨幣。 透過在他們的平台上註冊，您也接受他們的服務條款，並且需要通過他們的 KYC 流程，該流程獨立於我們的流程運作。',
  CODG_is_the_reward_token_of_Pigame:
    'CODG是Pi.game的獎勵代幣。 您與平台的互動越多，您賺取的 CODG 就越多，以豐富您的資產。',
  CODG_is_the_reward_token_of_Ohplay:
    'CODG是Ohplay.club的獎勵代幣。 您與平台的互動越多，您賺取的 CODG 就越多，以豐富您的資產。',
  what_is_investing_in_pi_gamebank: '什麼是投資 Pi 遊戲銀行',
  what_is_investing_in_ohplay_gamebank: '什麼是投資 Ohplay 遊戲銀行',
  turn_your_friends_and_followers_into_weekly_commissions_pi:
    '透過 Pi 的聯盟計劃，將您的朋友和追蹤者轉化為每週佣金。 簡單、有利可圖且完全透明',
  turn_your_friends_and_followers_into_weekly_commissions_ohplay:
    '透過 Ohplay 的聯盟計劃，將您的朋友和追蹤者轉化為每週委託。 簡單、有利可圖且完全透明',
  if_you_have_a_website_with_substantial_traffic_pi:
    '如果您擁有流量較大的網站或擁有大量受眾的社交媒體帳戶，您可以透過business@Pi.game 與我們聯繫以獲得獨家優惠。',
  if_you_have_a_website_with_substantial_traffic_ohplay:
    '如果您擁有流量較大的網站或擁有大量受眾的社交媒體帳戶，您可以透過business@Ohplay.club 與我們聯繫以獲得獨家優惠。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_pi:
    '由於我們的遊戲許可證，我們無法接受來自您所在國家/地區的玩家。 如果您需要進一步協助，請透過 support@Pi.game 與我們聯繫。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_ohplay:
    '由於我們的遊戲許可證，我們無法接受來自您所在國家/地區的玩家。 如果您需要進一步協助，請透過 support@Ohplay.club 與我們聯繫。',
  all_plays: '全解放軍',
  my_plays: '我的玩法',
  high_play: '高玩',
  play_id: '播放ID',
  play_amount: '遊玩金額',
  set_auto_play: '設定自動投注',
  number_of_play: '播放次數',
  stop_auto_play: '停止自動播放',
  start_play_round_failed: '開始比賽失敗',
  wager_contest_pi: 'Pi.game 大賽',
  max_play: '最大播放量',
  total_play_amount: '總遊戲金額',
  under_min_play: '低於最小播放時間',
  invalid_play_params: '無效的播放參數',
  over_max_play: '超過最大播放量',
  invalid_play_state: '無效播放狀態',
  play_next: '播放下一個',
  playing: '演奏',
  play_head: '播放頭d',
  play_tail: '玩尾巴',
  auto_play_setup: '自動播放設定',
  click_play_to_choose_coin_side: '點擊播放選擇硬幣面',
  play_histories: '播放歷史記錄',
  play_place: '玩耍的地方',
  can_not_get_play_detail: '無法取得播放詳情！',
  play_win: '玩贏',

  // CODG 05/04
  locked_CODG_is_earned:
    '鎖定的 CODG 是透過特定獎金或促銷活動（例如存款獎金）賺取的。',
  unlocking_CODG_is_simple: `解鎖CODG很簡單； 它類似於返傭，並根據您的賭注按比例解鎖，如下所示：`,
  unlocking_formula: '解鎖金額 = 下注金額 * 賭場優勢（小數） * X',
  conversion_formula: `例如，如果您下注 100 美元，您將解鎖 0.2 個鎖定的 CODG。`,
  note_unlock_codg:
    '注意：要解鎖 CODG，您需要至少達到青銅排名，這需要 10,000 XP。',
  how_to_claim_COD: '如何索取貨到付款',
  as_you_place_wagers:
    '當您下注時，您的小豬將累積解鎖的 CODG，然後將其轉換為 COD。',
  check_to_claim: '檢查索賠',
  about_CODG_token: '關於 CODG 代幣',
  play_our_games_to_earn_CODG_for_free: '玩我們的遊戲即可免費賺取 CODG！',
  how_to_unlock: '如何解鎖',
  how_to_claim: '如何索賠',
  today_biggest_winners: '今日最大贏家',
  and_get_up_to: '並起身',
  to_get: '要得到',
  claim_now: '現在宣稱',
  learn_more: '了解更多',

  // Host 10/04
  welcome_to_satoshity: '歡迎來到 satoshity.io',
  welcome_to_betgecko: '歡迎來到betgecko.io',
  welcome_to_bitwin: '歡迎來到 bitwin.club',
  host_vip_club: `${process.env.REACT_APP_SITE} 貴賓會`,
  to_host_vip_club: `到 ${process.env.REACT_APP_SITE} 貴賓會`,
  learn_vip: `學習更多關於 ${process.env.REACT_APP_SITE} VIP系統`,
  become_a_host: `變得a ${process.env.REACT_APP_SITE}`,
  host_member: `成為一個 ${process.env.REACT_APP_SITE} 成員`,
  how_much_has_host_given_out_in_bonuses: `有多少 ${process.env.REACT_APP_SITE} 發放獎金？`,
  why_is_HOST_VIP_program_the_best: `為什麼是 ${process.env.REACT_APP_SITE} VIP計畫最好嗎？`,
  HOST_VIP_telegram_channel: `我在哪裡可以找到 ${process.env.REACT_APP_SITE} VIP 電報頻道？`,
  tip_with_any_currency_in_HOST: `使用任何貨幣的小費 ${process.env.REACT_APP_SITE}`,
  turn_your_friends_and_followers_into_weekly_commissions_HOST: `將您的朋友和追蹤者轉化為每週佣金 ${process.env.REACT_APP_SITE}'s 附屬計劃。 簡單、有利可圖且完全透明`,
  why_should_i_be_a_HOST_affiliate: `為什麼我應該成為一個 ${process.env.REACT_APP_SITE} 附屬機構?`,
  if_you_already_have_a_HOST_account: `如果您已經有 ${process.env.REACT_APP_SITE} 帳戶，只需按照以下步驟操作：
      1. 造訪聯盟頁面。
      2. 使用您唯一的推薦代碼產生新的活動。
      3. 在您的網站、社群媒體或任何高流量平台上分享您的推薦連結。
      4. 從您推薦的用戶領取每週佣金。`,
  HOST_took_it_all: `${process.env.REACT_APP_SITE} 全部拿走了！`,
  there_are_various_ways_to_get_HOST_gift_codes: `有多種取得方式 ${process.env.REACT_APP_SITE} 禮品代碼，例如參加獨家社交媒體活動、社區活動、獎金或特殊任務。`,
  what_is_HOST_giftcode: `'什麼是 ${process.env.REACT_APP_SITE} 禮品碼？`,
  mini_HOST_is_Allways_3x3_Slot_machine_game: `Mini ${process.env.REACT_APP_SITE} 是 27 線 3x3 老虎機遊戲。`,
  the_above_third_party_HOST: `上述第三方服務可用於購買可用於玩遊戲的加密貨幣 ${process.env.REACT_APP_SITE}. 透過在他們的平台上註冊，您也接受他們的服務條款，並且需要通過他們的 KYC 流程，該流程獨立於我們的流程運作。`,
  what_is_investing_in_HOST_gamebank: `投資什麼 ${process.env.REACT_APP_SITE} 遊戲銀行`,

  you_can_still_enjoy_the_best_casino_experience_at_satoshity: `不過不用擔心，您仍然可以在 satoshity.io 享受最好的賭場體驗`,
  you_can_still_enjoy_the_best_casino_experience_at_betgecko: `不過不用擔心，您仍然可以在 betgecko.io 享受最好的賭場體驗`,
  you_can_still_enjoy_the_best_casino_experience_at_bitwin: `不過不用擔心，您仍然可以在 bitwin.club 享受最好的賭場體驗`,
  satoshity_game_is_not_available_in_your_region:
    '抱歉，您所在的地區無法使用 satoshity.io。',
  betgecko_game_is_not_available_in_your_region:
    '抱歉，您所在的地區無法使用 betgecko.io。',
  bitwin_game_is_not_available_in_your_region:
    '抱歉，您所在的地區無法使用 bitwin.club。',
  how_to_get_satoshity_gift_code: '如何取得 satoshity.io 禮品碼？',
  how_to_get_betgecko_gift_code: '如何取得 betgecko.io 禮品代碼？',
  how_to_get_bitwin_gift_code: '如何取得 bitwin.club 禮品代碼？',
  how_to_use_satoshity_gift_code: '如何使用 satoshity.io 禮品碼？',
  how_to_use_betgecko_gift_code: '如何使用betgecko.io禮品代碼？',
  how_to_use_bitwin_gift_code: '如何使用bitwin.club禮品碼？',
  use_your_COD_gift_to_bet_and_win_at_satoshity:
    '使用您的 Satoshity 禮物在 satoshity.io 下注並獲勝',
  use_your_COD_gift_to_bet_and_win_at_betgecko:
    '使用您的 Betgecko 禮物在 betgecko.io 下注並獲勝',
  use_your_COD_gift_to_bet_and_win_at_bitwin:
    '使用您的 Bitwin 禮物在 bitwin.club 下注並獲勝',
  CODG_is_the_reward_token_of_satoshity:
    'CODG是satoshity.io的獎勵代幣。 您與平台的互動越多，您賺取的 CODG 就越多，以豐富您的資產。',
  CODG_is_the_reward_token_of_bitwin:
    'CODG是bitwin.club的獎勵代幣。 您與平台的互動越多，您賺取的 CODG 就越多，以豐富您的資產。',
  CODG_is_the_reward_token_of_betgecko:
    'CODG是betgecko.io的獎勵代幣。 您與平台的互動越多，您賺取的 CODG 就越多，以豐富您的資產。',
  if_you_have_a_website_with_substantial_traffic_satoshity:
    '如果您擁有流量較大的網站或擁有大量受眾的社交媒體帳戶，您可以透過business@satoshity.io 與我們聯繫以獲得獨家優惠。',
  if_you_have_a_website_with_substantial_traffic_betgecko:
    '如果您擁有流量較大的網站或擁有大量受眾的社交媒體帳戶，您可以透過business@betgecko.io 與我們聯繫以獲得獨家優惠。',
  if_you_have_a_website_with_substantial_traffic_bitwin:
    '如果您擁有流量較大的網站或擁有大量受眾的社交媒體帳戶，您可以透過business@bitwin.club 與我們聯繫以獲得獨家優惠。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_betgecko:
    '由於我們的遊戲許可證，我們無法接受您所在國家/地區的玩家。 如果您需要進一步協助，請透過 support@Betgecko.io 與我們聯繫。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_satoshity:
    '由於我們的遊戲許可證，我們無法接受您所在國家/地區的玩家。 如果您需要進一步協助，請透過 support@Satoshity.io 與我們聯繫。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_bitwin:
    '由於我們的遊戲許可證，我們無法接受您所在國家/地區的玩家。 如果您需要進一步協助，請透過 support@Bitwin.club 與我們聯繫。',
  claim_successful: '索賠成功',

  refer_description_coco:
    '推薦朋友加入 coco.game 的聯盟計劃，並為每個透過您的連結註冊和玩遊戲的用戶賺取佣金，無論其結果如何',
  refer_description_pi:
    '推薦朋友加入 pi.game 的聯盟計劃，並為每個透過您的連結註冊和玩遊戲的用戶賺取佣金，無論其結果如何',
  refer_description_ohplay:
    '推薦朋友加入 ohplay.club 的聯盟計劃，並為每個透過您的連結註冊和玩遊戲的用戶賺取佣金，無論其結果如何',
  refer_description_satoshity:
    '推薦朋友加入 satoshity.io 的聯盟計劃，並為每個透過您的連結註冊和玩遊戲的用戶賺取佣金，無論其結果如何',
  refer_description_betgecko:
    '推薦朋友加入 betgecko.io 的聯盟計劃，並為每個透過您的連結註冊和玩遊戲的用戶賺取佣金，無論其結果如何',
  refer_description_bitwin:
    '推薦朋友加入 bitwin.club 的聯盟計劃，並為每個透過您的連結註冊和玩遊戲的用戶賺取佣金，無論其結果如何',
  your_email_verified_coco:
    '您的電子郵件已被驗證。 讓我們來探索一下 coco.game。',
  your_email_verified_pi: '您的電子郵件已被驗證。 讓我們來探索一下 pi.game。',
  your_email_verified_ohplay:
    '您的電子郵件已被驗證。 讓我們來探索一下 ohplay.club',
  your_email_verified_satoshity:
    '您的電子郵件已被驗證。 讓我們來探索一下 satoshity.io',
  your_email_verified_betgecko:
    '您的電子郵件已被驗證。 讓我們來探索一下 betgecko.io',
  your_email_verified_bitwin:
    '您的電子郵件已被驗證。 讓我們來探索一下 bitwin.club',
  GAME: '遊戲',
  AFFILIATE: '附屬機構',
  ACCOUNT: '帳戶',
  BONUS: '獎金',
  INVEST: '投資',
  SWAP: '交換',
  deposit_reward: '存款獎勵',
  roll_dice_trio: '擲骰子三重奏',
  hit_1000: '達到 1000 美元',
  full_jackpot: '全額大獎',
  play_game2: '玩遊戲',
  up_to: '取決於',
  for: '為了',
  free_5_usdt: '免費 5 美元 USDT',
  non_deposited: '非存款',
  hands: '手',
  total_bet: '總投注額',
  deal: '交易',
  bet_and_win: '投注即贏',
  daily_wager_tournament: '每日投注錦標賽',
  prize_pool: '獎金池',
  race_now: '立即比賽',
  fuel_your_experience: '為您的體驗加油',
};

export default tw;
