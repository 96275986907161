import { SvgIcon } from '@mui/material';

export function IconBack2(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M11.5247 16.3604C11.2726 16.3612 11.0336 16.2479 10.8747 16.052L6.84973 11.052C6.59697 10.7445 6.59697 10.3012 6.84973 9.99369L11.0164 4.99369C11.3109 4.63931 11.837 4.5908 12.1914 4.88536C12.5458 5.17991 12.5943 5.70597 12.2997 6.06036L8.57473 10.527L12.1747 14.9937C12.3828 15.2435 12.4266 15.5914 12.287 15.885C12.1474 16.1786 11.8498 16.3642 11.5247 16.3604Z"
        fill="#798189"
      />
    </SvgIcon>
  );
}
