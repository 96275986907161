// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
  },
  blog: `blog`,
  chat: `chat`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  fairness: 'Fairness',
  blog_academy: 'Blog / Academy',
  live_support: 'Live Support',
  search_your_game: 'Search your game',
  random: 'Random',
  sign_in: 'Sign In',
  sign_up: 'Sign Up',
  wallet: 'Wallet',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  swap: 'Swap',
  crypto: 'Crypto',
  fiat: 'Fiat',
  nft: 'NFT',
  search_by_name: 'Search by name',
  transactions: 'Transactions',
  // Deposit
  overview: 'Overview',
  deposit_currency: 'Deposit Currency',
  buy_crypto: 'Buy Crypto', // TODO: dịch ngôn ngữ cho anh
  buy_fiat: 'Select Money Fiat',
  choose_network: 'Choose network',
  network: 'Network',
  deposit_address: 'Deposit Address',
  notice: 'Notice:',
  notice_content:
    'Send only /currency/ to this deposit address. Smart contract addresses are not supported (Contact us)',
  balance: 'Balance',
  copy: 'Copy',
  withdraw_amount: 'Withdraw Amount',
  withdraw_address: 'Withdraw address',
  deposit_address_placeholder: 'Enter your wallet address carefully',
  fee: 'Fee',
  receive_amount: 'Receive amount',
  confirm: 'confirm',
  balance_in_wallet: 'Balance in wallet',
  withdraw_fee: 'Withdraw Fee',
  from: 'From',
  to: 'To',
  bill: 'Bill',
  view_history_in_etherscan:
    'View transaction history on Pi.game blockexplorer',
  time: 'Time',
  amount: 'Amount',
  status: 'Status',
  detail: 'Detail',
  view_in_fiat: 'View in USD',
  common_assets: 'Common Assets',
  order_id: 'Order ID',
  currency: 'Currency',
  quantity: 'Quantity',
  type: 'Type',
  empty_data: 'Empty data',
  currency_not_available: 'Currency not available',
  all_feature: 'All features',
  success: 'Success',
  audit: 'Audit',
  insufficient: 'Insufficient',
  processing: 'Processing',
  new: 'New',
  profile_settings: 'Profile Settings',
  general: 'general',
  security: 'security',
  verify: 'verify',
  verified: 'verified',
  id: 'id',
  nickname: 'nickname',
  email: 'Email',
  phone_number: 'phone number',
  change: 'Change',
  not_use_symbol:
    'Do not use special symbols, otherwise your account may not be supported.',
  hide_profile: 'Hide profile',
  registered_mail_cannot_changed:
    'According to the security policy of Ohplay.club, the registered email cannot be changed.',
  phone_second_login: 'Add Phone Number as your second login method.',
  hide_profile_title: 'Hide your gaming data on profile',
  hide_profile_content:
    'Even if hidden, your avatar and username are always visible in chatrooms.',
  copied: 'Copied',
  enter_nickname: 'Enter nickname here',
  enter_email: 'Enter email here',
  enter_phone_number_: 'Enter phone number here',
  change_password: 'Change Password',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_password: 'Confirm New Password',
  existing_password_not_match: 'Existing password doesn’t match',
  password_not_match: 'Password do not match',
  new_password_saved: 'New password saved',
  relogin_after_change_pw:
    'Re-login will be required after changing the password.',
  setup_google_auth: 'Set up Google Authenticator',
  download_google_auth:
    'Download the Google Authenticator app for iPhone or Android',
  save_account_token:
    'Save the following Account Token (Key) in a secure place',
  account_token: 'Account Token (Key)',
  need_account_token:
    'You will need your Account Token (Key) above to access your account in case you lose your phone',
  scan_qr_title: 'Scan below QR Code with the Google Authenticator app',
  enter_token_below: 'Enter the Token below',
  two_FA_code: '2FA Code *',
  appear_on_google_auth: 'Appear on your Google Authenticator app',
  verify_code_incorrect: 'Verification code incorrect',
  enable_2fa: 'Enable 2FA',
  enter_current_pw: 'Enter current password',
  enter_new_password: 'Enter new password',
  enter_repeat_password: 'Repeat new password',
  enter_2fa_code_to_disable:
    'Enter your 2FA code to disable two-factor authentication.',
  update_nickname_failed: 'Update nickname failed, please try again',
  disable_2fa: 'Disable 2FA',
  verify_email: 'Verify Email',
  send_information_to_email:
    'We will send information to your registered email address.',
  cancel: 'Cancel',
  cancel2: '(Cancel)',
  verify_phone_number: 'Verify Phone Number',
  resend_code: 'Resend code',
  did_not_receive_code: 'Didn’t receive the code?',
  validate_nickname_length: 'Nickname must be between 4 and 13 characters.',
  nickname_existing: 'Nickname already exists.',
  new_nick_name_saved: 'New nickname saved',
  new_email_saved: 'New email saved',
  new_phone_number_save: 'New phone number saved',
  invalid_phone_number: 'Invalid phone number',
  validate_invalid_email_address: 'Please enter a valid email address',
  view: 'View',
  choose_asset: 'Choose assets',
  min: 'Min',
  max: 'MAX',
  max2: 'Max',
  all_currency: 'All Currencies',
  to_secure_assets: 'To secure your assets, please',
  // Host
  bet_id: 'Bet ID',
  play_id: 'Play ID',

  bet_amount: 'Bet amount',
  play_amount: 'Play amount',
  //
  payout: 'Payout',
  profit_amount: 'Profit',
  hash: 'Hash',
  show_more: 'Show more',
  show_less: 'Show less',
  welcome_aboard: 'Welcome aboard',
  first_deposit_bonus: 'First deposit bonus',
  rewards: 'rewards',
  deposit_now: 'Deposit now',
  just_for_you: 'Just for you',
  top_games: 'Top games',
  recently_played: 'Recently played',
  recommended: 'Recommended',
  favourites: 'Favourites',
  or_log_in_with: 'Or log in with',
  dont_have_account: "Don't you have an account?",
  forgot_your_password: 'Forgot your password?',
  password: 'Password',
  terms_of_service: 'terms of service',
  confirm_accessing_site:
    'By accessing the site, I confirm that I am 18 years old and I have read the',
  include_lower_uppercase: 'Include lower and upper case',
  at_least_1_number: 'At least 1 number',
  minimum_6_char: 'Minimum 6 characters',
  agree_terms_service: 'I agree and understand the',
  terms_conditions: 'Terms & Conditions',
  already_have_account: 'Already have an account?',
  enter_password_here: 'Login password...',
  reset_password: 'Reset password',
  assets_portfolio: 'Assets Portfolio',
  hide_small: 'Hide small',
  menu: 'Menu',
  game_hub: 'Game Hub',
  notification: 'Notification',
  chat_room: 'Chat',
  login_fail: 'Login failed, please try again',
  error_message: {
    server_error: 'Server error. Please try again in a few minutes',
  },
  dark: 'Dark',
  light: 'Light',
  casino_home: 'Game hub',
  promotions: 'Promotions',
  refer_friend: 'Refer a friend',
  support_legal: 'Support / Legal',
  help_center: 'Help Center',
  faq: 'FAQ',
  privacy_policy: 'Privacy Policy',
  aml: 'AML',
  res_gambling: 'Play with responsible ',
  provably_fair: 'Provably Fair',
  registration_login: 'Registration and Login',
  about_us: 'About us',
  news: 'News',
  business_contact: 'Business contact',
  work_with_us: 'Work with us',
  coin_accuracy_limit: 'Coin Accuracy Limit',
  support: 'Support',
  verify_representative: 'Verify Representative',
  crypto_quest: 'Crypto Quest',
  video_poker: 'Video Poker',
  classic_dice: 'Classic Dice',
  join_our_community: 'Join our Community',
  go_to_top: 'Go to top',
  game: 'Game',
  game_info: 'Game Info',
  description: 'Description',
  reviews: 'Reviews',
  latest_bet_race: 'Races',
  // Host
  all_bets: 'All bet',
  all_plays: 'All play',

  my_bets: 'My bet',
  my_plays: 'My play',

  high_bets: 'High bet',
  high_play: 'High play',
  //
  player: 'Player',
  hidden: 'Hidden',
  about_this_game: 'About This Game',
  share_this_game: 'Share This Game',
  share_on_fb: 'Share on FaceBook',
  share_on_whatsapp: 'share on WhatsApp',
  share_on_twitter: 'Share on X (Twitter)',
  share_on_discord: 'Share on Discord',
  share_on_linkedin: 'Share on LinkedIn',
  share_on_skype: 'Share on Skype',
  by: 'By: ',
  release: 'Release',
  return_to_player: 'RTP (Return to player)',
  provider: 'Provider',
  max_win: 'Max win',
  mobile: 'Mobile',
  stakes_range: 'Stakes range',
  active_devices: 'Active devices',
  device: 'Device',
  location: 'Location',
  ip_address: 'Ip Address',
  last_used: 'Last Used',
  action: 'Action',
  play_now: 'Play now',
  on: 'On',
  activities: 'Activities',
  high_rollers: 'High Rollers',
  jackpot_winners: 'Jackpot Winner',
  follow_us_on: 'Follow us on',
  INTERNAL_TRANSFER: 'Tipped',
  send_recover_link_to_email:
    'An email with recovery password link will be send to your email.',
  request_reset_email: 'Request was sent. Please check your email',
  something_wrong: 'Something went wrong. Please try again',
  vip_club: 'VIP club',
  host_vip_club: `${process.env.REACT_APP_SITE} VIP Club`,
  to_host_vip_club: `to ${process.env.REACT_APP_SITE} VIP Club`,
  welcome_vip_club_text:
    'The longest-playing and highest-betting players will be granted exclusive invitations to join our VIP club. We offer our members a premium experience and special bonuses.',
  learn_vip: `Learn more about ${process.env.REACT_APP_SITE} VIP’s system`,
  jackpot: 'Jackpot',
  responsible_gambling: 'Responsible gambling',
  next: 'Next',
  step: 'Step',
  verification: 'Verification',
  verify_setup: 'Verify Setup',
  verify_setup_description1:
    'To serve you better we ask that you provide original identifying documents. This will secure your account in cases of account recovery. It also helps to ensure that the gifts or actual rewards you receive are sent to there correct location.',
  verify_setup_description2:
    "An acceptable proof of identification includes a photo of your Govt, approved ID card, driver's license and passport. We will reach out to you via email once this process has been completed.",
  first_name: 'First name',
  last_name: 'Last name',
  gender: 'Gender',
  date_of_birth: 'Date of birth',
  address: 'Address',
  city: 'City',
  postal_code: 'Postal code',
  country: 'Country',
  enter_your_first_name: 'Enter your first name...',
  enter_your_last_name: 'Enter your last name...',
  verify_government_id_description:
    "Please upload a valid government-issued ID document or driving license. It must contain your full name, date of birth, your photo and the document's expiration date.",
  id_card: 'ID Card',
  passport: 'Passport',
  front_side: 'Front Side',
  back_side: 'Back Side',
  take_a_photo: 'Take a Photo',
  upload_picture: 'Upload Picture',
  information_page: 'Information Page',
  verify_portrait_description:
    'Please upload a photo of yourself. In the photo you need to hold your passport or ID, and a piece of paper with "Ohplay.club", your username and the date on it. Please make sure your face and the information you hold are clear.',
  your_photo: 'Your Photo',
  references: 'References',
  all_information_submitted: 'All information are submitted',
  thank_you_for_cooperation: 'Thank you for your cooperation.',
  done: 'Done',
  this_field_is_required: 'This field is required',
  male: 'Male',
  female: 'Female',
  unspecified: 'Unspecified',
  select_gender: 'Select gender',
  back: 'Back',
  step1_update_identification_success:
    'Step 1: Update identification successfully',
  step23_update_identification_photo_success:
    'Step 2, 3: Update identification photos successfully',
  invalid_date_format: 'Invalid date format',
  update_avatar_success: 'Update avatar successfully',
  update_avatar_failed: 'Update avatar failed',
  game_bank_overview: 'Overview',
  game_bank_history: 'History',
  game_bank_list: 'List',
  game_bank_withdraw: 'Withdraw',
  confirm_password_must_match: 'Confirm password must match',
  recover_password: 'Recover password',
  logout_failed: 'Logout failed, please try again!',
  at_least_18_years_old: 'You need to be at least 18 years old',
  no_support: 'No support',
  gems: 'Gems',
  sound: 'Sound',
  invalid_wallet_address: 'Invalid wallet address',
  target_multiplier: 'Target Multiplier',
  unexpected_error: 'An unexpected error has occurred. Please try again later',
  validate_nickname_message:
    'Nickname must be between 4 and 13 characters, unsigned and not contain space, symbol.',
  swap_fee: 'Swap fee',
  verify_account: 'KYC Now',
  history: 'History',
  big_winner: 'Big winner',
  jackpot_winner: 'Jackpot winner',
  my_bet: 'My play',
  all_bet: 'All play',
  winner: 'Winner',
  insufficient_ballance: 'Insufficient ballance',
  gamebank: 'Gamebank',
  maximumFileSize: 'Maximum file size is 4MB',
  verify_email_was_send: 'Verify email was sent, please check your email',
  error_code: {
    EMAIL_IN_USED: 'This email has been used by another user.',
  },
  account_verified: 'Your account was verified',
  check_information_carefully:
    'Please check your invest information carefully before confirming.',
  invest_now: 'Invest Now',
  more_information: 'More information',
  host: 'Host',
  loading: 'Loading...',
  you_must_specify_a_number: 'You must specify a number',
  must_be_a_positive_value: 'Must be a positive value',
  withdraw_success: 'Withdraw success',
  withdraw_failed: 'Withdraw failed',
  your_total_invest: 'Your Total Invest',
  your_current_shares: 'Your current shares',
  your_balance: 'Your balance',
  your_profit: 'Your profit',
  leverage: 'Leverage',
  game_bank: 'Game bank',
  game_bank_max: 'GameBank Max',
  your_invest: 'Your Invest',
  available_invest_amount: 'Available Invest Amount',
  your_share: 'Your Share',
  host_share: 'Host Share',
  invest_success: 'Invest success',
  invest_failed: 'Invest failed',
  investor: 'Investor',
  share: 'Share',
  error_message_server_error: 'error_message.server_error',
  successful_balance_change: 'Successful, balance change',
  permission_denied: 'Permission Denied',
  you_not_permission_page: 'You do not have permission to access this page',
  not_bad: 'NOT BAD',
  nice: 'NICE',
  awesome: 'AWESOME',
  wicked: 'WICKED',
  godlike: 'GODLIKE',
  to_the_moon: 'TO THE MOON',
  you_win: 'YOU WINNN!',
  bum: 'BUMMM!',
  some_thing_went_wrong: 'Some thing went wrong',
  maximum_number: 'The maximum number of cells selected is',
  auto_select: 'AUTO SELECT',
  number_of_spin: 'Number of spin',
  // Mini Coco
  advanced_auto: 'Advanced auto',
  stop_if_anyone_win_jackpot: 'Stop if anyone win Jackpot',
  stop_decrease_by: 'Stop if balance decrease by',
  stop_increase_by: 'Stop if balance increase by',
  stop_exceeds_by: 'Stop if single win exceeds by',
  start_auto_spin: 'Start Auto Spin',
  not_enough_balance: 'Not enough balance',
  // Host
  set_auto_bet: 'Set Auto Bet',
  set_auto_play: 'Set Auto Bet',
  //
  cancel_auto_bet: 'Cancel Auto Bet',
  cashout_at: 'Cashout At',
  win_amount: 'Win Amount',
  increase: 'increase',
  decrease: 'decrease',
  // Host
  bet: 'Bet',
  play: 'Play',
  //
  waiting: 'Waiting...',
  bet_next_round: 'BET (next round)',
  cashout: 'Cashout',
  win: 'WIN',
  lose: 'LOSE',
  increase_by: 'Increase by',
  reset: 'Reset',
  manual: 'Manual',
  auto: 'Auto',
  max_profit: 'Max profit',
  max_bet: 'Max bet',
  max_play: 'Max play',
  stop_on_profit: 'Stop on Profit',
  // Host
  number_of_bet: 'Number of bet',
  number_of_play: 'Number of play',
  //
  on_loss: 'On Loss',
  on_lose: 'On Lose',
  on_win: 'On Win',
  stop_on_lose: 'Stop On Lose',
  stop_on_win: 'Stop On Win',
  live_stats: 'Live Stats',
  // Host
  total_bet_amount: 'Total Bet Amount',
  total_play_amount: 'Total Play Amount',
  total_profit: 'Total reward',
  game_bank2: 'GAME BANK',
  can_not_get_game_bank: 'Can not get game bank',
  trends: 'Trends',
  play_real: 'Play Real',
  play_games: 'Play Games',
  play_with_fun: 'Play with FUN',
  online: 'Online',
  welcome_bonus: 'Welcome bonus',
  join_now: 'Join Now',
  total: 'Total',
  invest: 'Invest',
  insufficient_balance: 'Insufficient Balance',
  please_switch_to_another_asset_to_continue_playing:
    'Please switch to another asset to continue playing.',
  insufficient_gamebank: 'Insufficient Gamebank',
  switch: 'Switch',
  not_found: 'Not found',
  no_results_found_for: 'No results found for',
  checking_for_typos: 'Try checking for typos or using complete words.',
  please_enter_keywords: 'Please enter keywords',
  exit_fullscreen: 'Exit Fullscreen',
  fullscreen: 'Fullscreen',
  settings: 'Settings',
  currency_do_not_supported: 'This currency is not supported.',
  update_photo: 'Update photo',
  upload_photo: 'Upload photo',
  remove_all: 'Remove all',
  upload_files: 'Upload files',
  drop_or_select_file: 'Drop or Select file',
  drop_files_here_or_click: 'Drop files here or click',
  thorough_your_machine: 'Through your machine',
  no_found_any_currency_can_swap: 'No found any currency can swap',
  account_not_verified: 'The account has not been KYC verified',
  account_not_conditions: 'Wagering more to withdraw',
  withdraw_request_was_sent: 'Withdraw request was sent, waiting confirmation',
  available_amount_insufficient: 'Available amount insufficient',
  need_help: 'Need Help?',
  coming_soon: 'coming-soon',
  introduction_to_coco:
    'A multi-award winning crypto casino. With a player-centric approach, Ohplay.club is able to satisfy millions of gamblers across the globe. Ohplay.club has its priority set on its community, ensuring an everlasting and endlessly entertaining gambling experience.',
  coming_soon2: 'Coming soon...',
  under_development: 'The function is under development',
  page_404: '404 Page Not Found | Ohplay.club',
  page_not_found: 'Sorry, page not found!',
  check_your_spelling:
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  go_to_home: 'Go to Home',
  remove_session_success: 'Remove session success',
  remove_session_failed: 'Remove session failed',
  in_use: 'In Use',
  remove_device: 'Remove device',
  your_profile: 'Your profile',
  is_hidden: 'is hidden',
  is_public_now: 'is public now',
  hide_profile_failed: 'Hide profile failed, please try again',
  change_password_success: 'Change password success. Please login again',
  upload_image_failed: 'Upload image failed',
  file_size_limit_10MB: 'jpg, png; File size limit 10MB',
  roll_over: 'Roll Over',
  win_chance: 'Win Chance',
  profit_on_next_tile: 'Profit on Next Tile',
  pick_a_tile_randomly: 'Pick a Tile Randomly',
  pay_table: 'Pay table',
  game_rule: 'Game rule',
  bet_failed: 'Play failed',
  // Host
  stop_auto_bet: 'Stop auto bet',
  stop_auto_play: 'Stop auto play',
  //
  currency_not_support: 'Currency not support',
  login_to_play: 'Login to PLAY',
  yes: 'Yes',
  how_to_play: 'How to play',
  help: 'Help',
  // Host
  start_bet_round_failed: 'Start bet round failed',
  start_play_round_failed: 'Start play round failed',
  //
  cashout_failed: 'Cashout failed',
  auto_bet_round: 'Commit success',
  play_now2: 'PLAY NOW',
  // Host
  under_min_bet: 'Under min bet',
  under_min_play: 'Under min play',
  invalid_bet_params: 'invalid bet params',
  invalid_play_params: 'invalid play params',
  over_max_bet: 'Over max bet',
  over_max_play: 'Over max play',
  invalid_bet_state: 'invalid bet state',
  invalid_play_state: 'invalid play state',
  game_not_available_now: 'Game not available now',
  insufficient_amount: 'Insufficient amount',
  gamebank_not_available: 'Gamebank not available',
  win_rate: 'Win Rate',
  // Host
  welcome_to_Coco_game: 'Welcome to Coco.game',
  welcome_to_Ohplay: 'Welcome to Ohplay.club',
  welcome_to_Pi: 'Welcome to Pi.game',
  welcome_to_satoshity: 'Welcome to satoshity.game',
  welcome_to_betgecko: 'Welcome to betgecko.io',
  welcome_to_bitwin: 'Welcome to bitwin.club',
  //
  welcome_to_only: 'Welcome to',
  welcome_only: 'Welcome',
  to_join_club: 'to join our VIP Club',
  sign_in_or_sign_up: 'Sign In/ Sign Up',
  invalid_email_format: 'Invalid email format',
  resend_after: 'Resend after',
  second: 'second',
  only_available_at_large_resolutions:
    'Only available at large resolutions > 1600px (xl)',
  provably_fairs: 'Provably fairs',
  Fairness: 'Fairness',
  become_a_host: `Become a ${process.env.REACT_APP_SITE}`,
  club_member: 'Club member',
  vip_club_subtitle1:
    'The longest-playing and highest-commiting players will receive exclusive invites to the',
  vip_club_subtitle2:
    'That provides a premium experience and bonuses for its members.',
  your_vip_progress: 'Your VIP progress',
  your_vip_progress_tooltip: 'Please sign in first then see your VIP progress',
  total_wager: 'Total play',
  your_vip_progress_description:
    'All plays are converted to USD at the current rate',
  play_to_level_up: 'to level up your rank and gain more benefits now.',
  level_text_only: 'level',
  how_does_it_work: 'How does it work?',
  how_vip_club_work: 'How does VIP Club work?',
  the_rewards: 'The rewards',
  frequently_asked_questions: 'Frequently asked questions',
  vip_frequent_questions_subtitle:
    'We encourage you to contact support or your VIP manager if you have any questions',
  login: 'Login',
  to_see_your_vip_progress: 'to see your VIP progress',
  original_wager_rate:
    'Originals games: 1 XP = $1 Wager * House Edge  * 100 / 2',
  provider_wager_rate: 'Providers games: 1 XP = $1 Wager',
  all_wagers_converted_to_usd:
    'All wagers are converted to USD at the current rate',
  vip_club_benefit: 'VIP Club Benefit',
  vip_club_details: 'VIP Club Details',
  benefit_table: 'Benefit Table',
  // Host
  wager_contest: 'Daily Tournament',
  wager_contest_pi: 'Pi.game Contest',
  //
  game_of_the_day: 'Game of The Day',
  play_to_earn: 'Play to earn',
  time_remaining: 'Time remaining',
  rank: 'Rank',
  wagered: 'Wagered',
  prize: 'Prize',
  wager_contest_information: 'Challenge Information',
  rule: 'Rule',
  gamebank_chart: 'Gamebank Chart',
  next_round: 'Next round',
  next_round2: '(Next Round)',
  start_auto_bet: 'Start Auto Bet',
  understand_trend_chart: 'Understand Trend Chart',
  round_has_been_bet: 'This round has been bet, cancel from next round',
  crash_server_disconnect: 'Service interruption',
  crash_server_disconnect_alerts:
    'Re-connect your server to continue exploring Ohplay.club. Thank you for your patience.',
  coco_info:
    'This website is owned and operated by Orisun N.V., a company registered and established under the laws of Curaçao, with registration number 163631, with registered address Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao.',
  crash_result: 'Crash Result',
  wagers: 'Plays',
  reset_after: 'Reset after',
  daily_wagering_contest_rules: 'Daily Play Contest Rules',
  the_daily_race:
    'The Daily Race contest runs daily from 00:00 UTC to 23:59 UTC, starting from',
  daily_contest_prize_pool: 'Daily Contest prize pool',
  straight_flush_with_jack: 'Straight flush with Jack',
  straight_flush: 'Straight Flush',
  four_of_a_kind: 'Four of a Kind',
  full_house: 'Full House',
  flush: 'Flush',
  straight: 'Straight',
  three_of_a_kind: 'Three of a Kind',
  tow_pair: 'Two Pair',
  pair_of_jacks_or_better: 'Pair of Jacks or Better',
  you_won: 'You won',
  show_all: 'Show All',
  personal: 'Personal',
  no_notifications_at_the_moment:
    'No notifications at the moment. Please check back later.',
  mode: 'Mode',
  contrast: 'Contrast',
  direction: 'Direction',
  layout: 'Layout',
  stretch: 'Stretch',
  presets: 'Presets',
  status_success: 'status success',
  status_pending: 'status pending',
  swap_successfully: 'Swap successfully!',
  swap_failed: 'Swap failed, please try later',
  host_member: `Become a ${process.env.REACT_APP_SITE} member`,
  play_your_favorite_games: 'Play your favorite games',
  instantly_sign_up:
    'Instantly sign up and start playing on either the game or sports book.',
  unlock_further_benefits:
    'Enjoy playing, reach new levels and unlock further benefits',
  rank_claim_bonus: 'Increase your rank & claim bonus',
  go_up_to_the_next_rank:
    'Go up to the next Rank! Each level has its bonus and even more opportunities!',
  ranking_system_title: 'Ohplay VIP Ranking System',
  ranking_system_text:
    "Unlock a world of opportunities and recognition by joining our platform ranking system! Join us today to elevate your profile, play our games and enjoy the benefits of being a standout participant. Don't miss out on the chance to be recognized – be part of the success story now!",
  you_start_your_gaming:
    'You start your gaming way from the 1st Rank – NEWCOMER.',
  the_more_you_play:
    'The more you play – the higher your Rank is. It goes up according to the wagered amount while playing on Ohplay.club. All wagers are converted to USD at the current rate',
  mark_all_as_read: 'Mark all as read',
  eligible_VIP_member: 'Am I eligible to become a VIP member?',
  how_much_has_host_given_out_in_bonuses: `How much has ${process.env.REACT_APP_SITE} given out in bonuses?`,
  why_is_HOST_VIP_program_the_best: `Why is ${process.env.REACT_APP_SITE} VIP program the best?`,
  what_does_affect_the_weekly_monthly:
    'What does affect the weekly/monthly bonus?',
  information_to_become_a_VIP_member:
    'Will I have to provide any additional information to become a VIP member?',
  HOST_VIP_telegram_channel: `Where can I find the ${process.env.REACT_APP_SITE} VIP Telegram channel?`,
  one_min: '1 min',
  five_min: '5 min',
  one_hour: '1 hour',
  for_hours: '4 hours',
  one_day: '1 day',
  investment: 'Investment',
  my_investment: 'My Investment',
  what_upp: "What's up???",
  hide: 'Hide',
  stake_and_earn: 'Play and earn',
  //
  vip_level_comparison: 'VIP Level Comparison',
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  platinum_1_to_6: 'Platinum I - VI',
  diamond_1_to_6: 'Diamond I - VI',
  platinum: 'Platinum',
  diamond: 'Diamond',
  level_up_bonus: 'Level Up Bonus',
  surprise_box: 'Surprise Box',
  free_spin_wheel_enable: 'Free Spin Wheel Enable',
  surprise_box_upgraded: 'Surprise Box Upgraded',
  weekly_bonus: 'Weekly Bonus',
  chat_enable: 'Chat Enable',
  upload_avatar_enable: 'Upload Avatar Enable',
  vip_support_enable: 'VIP Support Enable',
  surprise_box_upgraded_2: 'Surprise Box Upgraded (up to 1.8M USDT)',
  we_have_given_over_1_billion:
    'We have given over $1 Billion in bonuses. This is primarily the reason we have the best VIP program online. We truly believe in rewarding our players for their gameplay and loyalty.',
  me: 'me',
  result: 'Result',
  crush_of_crash: 'Crush of Crash',
  // Banner new
  earn_real_money: 'Earn real money',
  the_daily_game: 'The daily game',
  earn_everyday_with_original_games: 'Earn everyday with original games',
  daily_race: 'DAILY RACE',
  wager: 'Play',
  and: 'AND',
  everyday: 'EVERYDAY',
  crush: 'CRUSH',
  the_crash: 'THE CRASH',
  show_off_your_prediction_skills: 'Show off your prediction skills',
  deposit_now2: 'DEPOSIT NOW',
  on_1st_deposit: 'On 1st Deposit',
  upto: 'UP TO',
  reward: 'REWARDS',
  // Just for you
  free_play: 'FREE PLAY',
  with_fun_coin: 'With FUN Coin',
  show_me: 'SHOW ME',
  low_risk_high_rewards: 'Low Risk, High Rewards',
  prize_100000: 'PRIZE $100,000',
  win_1000x_jackpot: 'Win 1000x Jackpot',
  // Notification
  balance_change: 'Balance change',
  your_casino: 'Your game hub',
  // Referral
  spread_the_word_and: 'Spread the word and',
  earn_reward: 'Earn reward',
  campaign_name: 'Campaign Name',
  start_your_referrals: 'Start your referrals',
  id_number: 'Id Number',
  registered: 'Registered',
  total_deposits: 'Total Deposits',
  last_deposit: 'Last deposit',
  vip_point: 'Vip point',
  commission: 'Commission',
  referral: 'Referral',
  register_date: 'Register Date',
  display_name: 'Display Name',
  agents: 'Agents',
  transaction_date: 'Transaction Date',
  transaction_type: 'Transaction Type',
  export: 'Export',
  campaigns: 'Campaigns',
  create_a_new_campaign: 'Create a new campaign',
  claim_income: 'Claim income',
  per_players: 'Per Players',
  per_agents: 'Per Agents',
  per_day: 'Per Day',
  per_week: 'Per Week',
  per_month: 'Per Month',
  per_country: 'Per Country',
  date: 'Date',
  total_income: 'Total reward',
  total_user_register: 'Total User Register',
  total_user_bet: 'Total User Play',
  total_transaction: 'Total Transaction',
  download_banner: 'Download Banner',
  search: 'Search',
  clicks: 'Clicks',
  referrals: 'Referrals',
  deposit_users: 'Deposit Users',
  total_commission: 'Total Commission',
  commission_rate: 'Commission Rate',
  features: 'Features',
  link: 'Link',
  code: 'Code',
  today: 'Today',
  this_week: 'This Week',
  last_week: 'Last Week',
  overall: 'Overall',
  commission_available: 'Commission rewards',
  start_your_referral: 'Start your referrals now to see your rewards.',
  claim: 'Claim',
  log_in: 'Log In',
  to_see_your_rank: 'to see your rank',
  // Crash
  start_in: 'Start in ...',
  process: 'process...',
  reconnect: 'Reconnect',
  controls: 'controls',
  leaderboard: 'leaderboard',
  bet_next: 'Bet next',
  play_next: 'Play next',
  betting: 'Betting',
  playing: 'Playing',
  // Tip
  message_is_required: 'Message is required',
  // Host
  tip_with_any_currency_in_HOST: `Tip with any currency in ${process.env.REACT_APP_SITE}`,
  //
  receiver_id: 'Receiver ID',
  enter_user_id: 'Enter user ID',
  transaction_fee: 'Transaction Fee',
  message: 'Message',
  give_your_friends_some_messages_here:
    'Give your friends some messages here...',
  continue: 'Continue',
  successfully_tipped: 'Successfully tipped',
  you_are_tipping_to: 'You are tipping to',
  your_tip_request_has_been_confirmed: 'Your tip request has been confirmed.',
  you_can_track_the_progress_on_the_wallet_transaction_history:
    'You can track the progress on the Wallet Transaction History.',
  view_history: 'View History',
  tip_failed: 'Tip Failed',
  your_tip_request_was_unsuccessful_due_to_an_abnormality:
    'Your tip request was unsuccessful due to an abnormality.',
  please_try_again_later: 'Please try again later.',
  ok_i_got_it: 'OK, I got it',
  tip_information: 'Tip Information',
  password_is_required: 'Password is required',
  tip_verification: 'Tip Verification',
  authentication_secure: 'Authentication Secure',
  to_process_tipping_please: 'To process tipping, please',
  first_to_secure_your_assets: 'first to secure your assets',
  step_verification: '2-Step Verification',
  enter_your_login_password: 'Enter your Login Password',
  enter_the_2fa_code_appear_on_your_google_authenticator_app:
    'Enter the 2FA Code appear on your Google Authenticator App',
  authorize: 'Authorize',
  // 30/09
  sign_up_in_and_earn: 'Sign Up/In & Earn',
  learn_more_about_our_affiliate_program:
    'Learn more about our affiliate program',
  referral_link: 'Referral Link',
  create: 'Create',
  referral_users: 'Referral Users',
  registered_date: 'Registered Date',
  tip: 'Tip',
  send_tip: 'Send tip',
  delete: 'Delete',
  accept: 'Accept',
  close: 'Close',
  member_downline: 'Member downline',
  new_player: 'New Player',
  claim_commission_failed: 'Claim Commission Failed',
  claim_commission_successfully: 'Claim Commission successfully',
  create_campaign_successfully: 'Create Campaign successfully',
  create_campaign_failed: 'Create Campaign Failed',
  any_date: 'Any Date',
  // Mini CoCo
  stop_if_single_win_exceeds_by: 'Stop if single win exceeds by',
  // Host
  show_filter: 'Show filter',
  filter: 'Filter',
  default_campaign: 'Default Campaign',
  select_all: 'Select All',
  role: 'role',
  hello_world: 'Hello world',
  // GameBank => Overview
  gb_capacity: 'GB Capacity',
  game_bank_capacity: 'Game Bank Capacity',
  the_maximum_amount_that_you_can_invest:
    'The maximum amount that you can invest.',
  // Crash
  finish_bet: 'Finish Bet',
  finishing_bet: 'Finishing Bet',
  // Referral
  delete_invitation: 'Delete Invitation',
  you_had_been_invited_to_become_an_agent_by:
    'You had been invited to become an agent by',
  confirming_to_delete_this_invitation:
    'By confirming to delete this invitation, this action will not be undo. Still want to decined?',
  successfully_accepted_invitation:
    'You have successfully accepted th invitation',
  the_house: 'The house ',
  invited_you_to_become_an_agent: 'invited you to become an agent',
  campaign_name_1: 'Campaign Name 1',
  available_commission: 'Available Commission',
  there_are_no_commission: 'There are no commission',
  please: 'Please',
  login2: 'login',
  to_see_your_rewards: 'to see your rewards',
  you_have_successfully_accepted_the_invitation:
    'You have successfully accepted the invitation',
  //
  downline_report: 'Downline Report',
  referral_faq_subscription:
    'For more details about the affiliate program, please contact ',
  internet_connection_restored: 'Internet connection restored',
  lost_connect_to_network: 'Lost connection to the Network',
  // 10/10
  user_profile: 'User Profile',
  leave_a_tip: 'Leave a tips',
  your_commission_rate: 'Your Commission rate',
  affiliate_terms_conditions: 'Affiliate Terms & Conditions',
  REFERRAL_CODE_OR_NAME_EXISTS:
    'Campaign name or referral code are already exists',
  swap_unavailable:
    'Swap pair is currently not supported, please try again later',
  please_enter_swap_amount: 'Please enter swap amount',
  become_our_agent_Contact_business: 'Become our agent? Contact business:',
  earn_commission_rewards:
    'Earn commission rewards whenever your friends place wagers on our games',
  turn_your_friends_and_followers_into_weekly_commissions_HOST: `Turn your friends and followers into weekly commissions with ${process.env.REACT_APP_SITE}'s affiliate program. It's simple, profitable, and completely transparent`,

  // FAQ
  why_should_i_be_a_HOST_affiliate: `Why should I be a ${process.env.REACT_APP_SITE} Affiliate?`,
  adding_our_link_and_or_banner_to_your_website:
    'If you have a website or social channels with some traffic, why not maximize its potential? By adding our link and/or banner to your website, you can create a new, effective way to earn additional income instantly.',
  how_do_I_get_paid_for_being_an_affiliate:
    'How do I get paid for being an affiliate?',
  the_banner_or_link_you_create_contains_your_personal_tracking_code:
    'The banner or link you create contains your personal tracking code. When players visit Ohplay, this code is stored in a cookie on their computer. When the user signs up, we identify them as referred by you. This player becomes permanently associated with you, and you continue to generate commissions from their activity for a lifetime.',
  how_a_new_player_linked_to_me: 'How a new player linked to me?',
  how_exactly_is_my_affiliate_commission_calculated:
    'How exactly is my affiliate commission calculated?',
  the_commission_for_each_bet: `The commission for each bet is calculated based on the formula below: Commission = Total Playing amount * the house edge of the game * commission rate. Where:
      - Total playing amount: The total game playing amount generated by all users referred by the affiliate..
      - House edge: The ratio of the average loss to the initial bet. The house edge of each game is defined as 1 minus the RTP (Return to Player).
      - Commission rate: The rate depends on the affiliate role of users and is displayed on the Affiliate site.`,
  how_to_join_the_affiliate_program: 'How to join the Affiliate program?',
  if_you_already_have_a_HOST_account: `If you already have a ${process.env.REACT_APP_SITE} account, simply follow these steps:
      1. Visit the Affiliate page.
      2. Generate a new campaign using your unique referral code.
      3. Share your referral link on your website, social media, or any high-traffic platforms.
      4. Claim your weekly commission from users you've referred.`,
  how_can_i_keep_track_of_my_performance:
    'How can I keep track of my performance?',
  once_your_affiliate_link_is_generated:
    'Once your affiliate link is generated, you can log in to the affiliate dashboard. There, you have access to view all your statistics and can filter the information in the reports based on various factors.',
  at_the_start_of_each_new_week:
    'At the start of each new week, your commission is calculated and made available for claiming in the form of Ohplay tokens. You can easily withdraw your earnings to your account wallet.',
  i_have_big_audience_how_i_can_get_special_deals:
    'I have big audience how I can get special deals?',
  // Host
  if_you_have_a_website_with_substantial_traffic_coco:
    'If you have a website with substantial traffic or a social media account with a large audience, you can reach out to us at business@Coco.game for exclusive deals.',
  if_you_have_a_website_with_substantial_traffic_pi:
    'If you have a website with substantial traffic or a social media account with a large audience, you can reach out to us at business@Pi.game for exclusive deals.',
  if_you_have_a_website_with_substantial_traffic_ohplay:
    'If you have a website with substantial traffic or a social media account with a large audience, you can reach out to us at business@Ohplay.club for exclusive deals.',
  if_you_have_a_website_with_substantial_traffic_satoshity:
    'If you have a website with substantial traffic or a social media account with a large audience, you can reach out to us at business@satoshity.io for exclusive deals.',
  if_you_have_a_website_with_substantial_traffic_betgecko:
    'If you have a website with substantial traffic or a social media account with a large audience, you can reach out to us at business@betgecko.io for exclusive deals.',
  if_you_have_a_website_with_substantial_traffic_bitwin:
    'If you have a website with substantial traffic or a social media account with a large audience, you can reach out to us at business@bitwin.club for exclusive deals.',

  // Crash
  maximum_is_9900: `Maximum is "9900"`,
  minium_is_101: `Minium is "1.01"`,
  insufficient_currency: 'Insufficient Currency',
  this_game_does_not_support_this_currency:
    'This game does not support this currency, please switch to another asset to continue playing.',
  unknown: 'unknown',
  you_should_verify_account_first_before_tipping_your_friends:
    'You MUST pass the KYC verification to tip others',
  must_be_only_digits: 'Must be only digits',
  min_length_ID_at_least_14_number: 'Min length ID at least 14 number',
  receiver_is_required: 'Receiver is required',
  tip_amount_must_specify_a_number: 'Tip Amount must specify a number',
  tip_amount_must_greater_than_0: 'Tip Amount must greater than 0',
  tip_amount_is_required: 'Tip Amount is required',
  how_to_see_other_ID: 'How to see other ID?',
  get_your_friends_ID_from_their_profiles:
    'Get your friends ID from their profiles.',
  tips_amount: 'Tips Amount',
  total_spending_amount: 'Total spending amount',
  withdrawing_your_balance:
    'You should verify your account first before withdrawing your balance',
  play_increase_wager:
    'Upon receiving bonus money, you will need to reach a $120,000 wagering amount to withdraw',
  pls_enter_code_send_to_tel: 'please enter code send to telephone',
  please_enter_withdraw_address: 'Please enter withdraw address',
  your_profile_had_been_verified: 'Your profile had been verified',
  SWAP_UNAVAILABLE: 'Insufficient balance',
  // Maintain
  this_page_is_under_maintenance: 'SORRY! This page is under maintenance',
  please_give_us_some_minutes_to_fix_the_issue:
    'Please give us some minutes to fix the issue',
  // 404
  we_cant_find_that_page: `Whoops! We can't find that page.`,
  // Host
  you_can_still_enjoy_the_best_casino_experience_at_Coco: `Don't worry though, you can still enjoy the BEST casino experience at Coco.game`,
  you_can_still_enjoy_the_best_casino_experience_at_Pi: `Don't worry though, you can still enjoy the BEST casino experience at Pi.game`,
  you_can_still_enjoy_the_best_casino_experience_at_Ohplay: `Don't worry though, you can still enjoy the BEST casino experience at Ohplay.club`,
  you_can_still_enjoy_the_best_casino_experience_at_satoshity: `Don't worry though, you can still enjoy the BEST casino experience at satoshity.io`,
  you_can_still_enjoy_the_best_casino_experience_at_betgecko: `Don't worry though, you can still enjoy the BEST casino experience at betgecko.io`,
  you_can_still_enjoy_the_best_casino_experience_at_bitwin: `Don't worry though, you can still enjoy the BEST casino experience at bitwin.club`,

  //
  region_prohibited: 'Region Prohibited',
  coco_game_is_not_available_in_your_region:
    'Sorry, Coco.game isn’t available in your region.',
  pi_game_is_not_available_in_your_region:
    'Sorry, Pi.game isn’t available in your region.',
  ohplay_game_is_not_available_in_your_region:
    'Sorry, Ohplay.club isn’t available in your region.',
  satoshity_game_is_not_available_in_your_region:
    'Sorry, satoshity.io isn’t available in your region.',
  betgecko_game_is_not_available_in_your_region:
    'Sorry, betgecko.io isn’t available in your region.',
  bitwin_game_is_not_available_in_your_region:
    'Sorry, bitwin.club isn’t available in your region.',
  //
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_coco:
    'Due to our gaming license, we cannot accept players from your country. Contact us via support@Coco.game if you require further assistance.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_pi:
    'Due to our gaming license, we cannot accept players from your country. Contact us via support@Pi.game if you require further assistance.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_ohplay:
    'Due to our gaming license, we cannot accept players from your country. Contact us via support@Ohplay.club if you require further assistance.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_betgecko:
    'Due to our gaming license, we cannot accept players from your country. Contact us via support@Betgecko.io if you require further assistance.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_satoshity:
    'Due to our gaming license, we cannot accept players from your country. Contact us via support@Satoshity.io if you require further assistance.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_bitwin:
    'Due to our gaming license, we cannot accept players from your country. Contact us via support@Bitwin.club if you require further assistance.',
  congrats: 'Congrats',
  won_the_most: 'won the most!',
  HOST_took_it_all: `${process.env.REACT_APP_SITE} took it all!`,
  page: 'Page',
  refresh: 'Refresh',
  // Popup
  register_to_get: 'Register to get',
  player_to_earn_the: 'Player to earn the',
  $10_prize: '$10 prize',
  sign_up_now: 'Sign Up Now',
  // Popup 2
  you_receive: 'YOU RECEIVE',
  weekly: 'weekly',
  see_detail: 'see detail',
  swap_amount_need_to_larger_than_min:
    'Swap amount need to larger than minimum',
  crypto_play: 'Crypto Play',
  fun_play: 'FUN Play',
  // Popup 3
  play_with: 'PLAY WITH',
  earn: 'EARN',
  real: 'REAL',
  dollars: 'DOLLARS',
  big_wins: 'Big Wins',
  // Gift code
  what_is_gift_code: 'What is gift code?',
  gift_code_info:
    'A gift code is like a special code made up of letters and numbers. When you redeem this code, you get special rewards from',
  gift_code_cod:
    'Each gift code has its own value and duration. All rewards are paid in the form of Ohplay tokens',
  // Host
  there_are_various_ways_to_get_HOST_gift_codes: `There are various ways to get ${process.env.REACT_APP_SITE} gift codes, such as participating in exclusive social media events, community activities, bonuses, or special quests.`,
  how_to_get_Coco_gift_code: 'How to get Coco.game gift code?',
  how_to_get_Pi_gift_code: 'How to get Pi.game gift code?',
  how_to_get_Ohplay_gift_code: 'How to get Ohplay.club gift code?',
  how_to_get_satoshity_gift_code: 'How to get satoshity.io gift code?',
  how_to_get_betgecko_gift_code: 'How to get betgecko.io gift code?',
  how_to_get_bitwin_gift_code: 'How to get bitwin.club gift code?',

  gift_code_coco: 'Coco.game',
  gift_code_pi: 'Pi.game',
  gift_code_ohplay: 'Ohplay.club',
  gift_code_satoshity: 'satoshity.io',
  gift_code_betgecko: 'betgecko.io',
  gift_code_bitwin: 'bitwin.club',

  how_to_use_Coco_gift_code: 'How to use Coco.game gift code?',
  how_to_use_pi_gift_code: 'How to use Pi.game gift code?',
  how_to_use_ohplay_gift_code: 'How to use Ohplay.club gift code?',
  how_to_use_satoshity_gift_code: 'How to use satoshity.io gift code?',
  how_to_use_betgecko_gift_code: 'How to use betgecko.io gift code?',
  how_to_use_bitwin_gift_code: 'How to use bitwin.club gift code?',

  //
  simply_enter_the_gift_code:
    'Simply enter the gift code, and the rewards will be automatically added to your balance.',
  giftcode_required: 'Gift code required',
  bonus_code_claimed_successfully: 'Bonus code claimed successfully',
  claim_your_free_reward: 'Claim your free reward',
  enter_your_giftcode: 'Enter your gift code',
  claim_bonus: 'Claim Bonus',
  what_is_giftcode: 'What is Giftcode',
  what_is_HOST_giftcode: `What is ${process.env.REACT_APP_SITE} Giftcode?`,
  got_it: 'Got It',
  won: 'won',

  // 8/12
  // Host
  use_your_COD_gift_to_bet_and_win_at_Cocogame:
    'Use your Coco gift to bet and win at Coco.game',
  use_your_COD_gift_to_bet_and_win_at_pi:
    'Use your Pi gift to play and win at Pi.game',
  use_your_COD_gift_to_bet_and_win_at_ohplay:
    'Use your Ohplay gift to bet and win at Ohplay.club',
  use_your_COD_gift_to_bet_and_win_at_satoshity:
    'Use your Satoshity gift to bet and win at satoshity.io',
  use_your_COD_gift_to_bet_and_win_at_betgecko:
    'Use your Betgecko gift to bet and win at betgecko.io',
  use_your_COD_gift_to_bet_and_win_at_bitwin:
    'Use your Bitwin gift to bet and win at bitwin.club',
  gift_code: 'Gift code',
  mini_HOST_is_Allways_3x3_Slot_machine_game: `Mini ${process.env.REACT_APP_SITE} is 27 lines 3x3 Slot machine game.`,
  rule_content: `Rules: symbols start from the first column, consecutively from the left to the right. The payout only calculates the highest value on one line.`,
  jackpot2: 'JACKPOT',
  explode_if_you_collect_3_WILD: 'Explode if you collect 3 WILD.',
  wild_can_subsitute_for_all_symbols: 'Wild can subsitute for all symbols.',

  // 3/1
  series: 'Series',
  multiple: 'Multiple',
  result_will_be_display_here: 'Result will be display here',
  // Host
  bet_head: 'Bet Head',
  play_head: 'Play Head',
  bet_tail: 'Bet Tail',
  play_tail: 'Play Tail',
  auto_bet_setup: 'Auto Bet Setup',
  auto_play_setup: 'Auto Play Setup',
  click_bet_to_choose_coin_side: 'Click bet to choose coin side',
  click_play_to_choose_coin_side: 'Click play to choose coin side',
  //
  instant: 'Instant',
  multiply: 'multiply',
  coin_flip_instant_mode_desc:
    'Simply Bet Head or Tail or set up Bet Automatically',
  you_win_title: 'You Win',
  select_network_to_continue: 'To continue, select network',
  ensure_withdraw_address:
    'Please ensure you enter the correct address; otherwise, your funds will be lost and cannot be recovered.',

  copied_share_in_chat: 'Copied, share in chat!',
  copied_share_online: 'Copied, share online!',
  game_id: 'Game ID',
  server_seed_hash: 'Server Seed (Hash)',
  client_seed: 'Client Seed',
  nonce: 'Nonce',
  about_COD: 'About COD',
  coco_dollar_info:
    'COD (Coco Dollar) is a special currency launched by COCO.GAME. With COD, you can participate in games or give tips to other players.',
  can_COD_be_exchanged_to_any_other_currency:
    'Can COD be exchanged to any other currency?',
  you_can_swap_cod:
    'You can swap COD for other currencies at any time using Coco Swap, based on the current exchange rate.',
  get_daily_bonus_up_to_5000_COD: 'Get daily bonus up to 5,000 COD',
  by_playing_games_tips_coin_dropping_and_rain_bonus:
    'By playing games, tips, coin-dropping and rain bonus.',
  see_how: 'See how',
  how_to_unlock_COD: 'How to unlock COD',
  locked_COD_is_earned_through_specific_bonuses_like_deposit_bonuses:
    'Locked COD is earned through specific bonuses like deposit bonuses.',
  unlocking_COD_is_simple:
    'Unlocking COD is simple; it is similar to rake back and unlocks proportionally with your wagers.',

  // Chat
  please_slow_down: 'Please slow down.',
  links_must_include_your_website_domain:
    'Links must include your website domain.',
  chat_is_hidden: 'Chat is hidden',
  show_chat: 'Show Chat',
  pinned_message: 'Pinned message',

  // 12/01
  deposit_bonus: 'Deposit Bonus',
  rakeback: 'Rakeback',
  spin: 'Spin',
  get_extra_300_bonus_on_minimum_of_10_USDT_deposit:
    'Get extra 300% bonus on minimum of 10.00 USDT deposit',
  min_deposit: 'Min Deposit:',
  max_claim: 'Max Claim:',
  withdraw_successful: 'Withdraw Successful',
  your_withdrawal_request_has_been_confirmed:
    'Your withdrawal request has been confirmed. You can track the progress on the Wallet Transaction History.',
  pi_balance_in_wallet: 'Pi Balance in wallet',
  your_Pi_address: 'Your Pi Address',
  fill_in_carefully_according_to_the_specific_currency:
    'Fill in carefully according to the specific currency',
  you_will_receive_Pi_to_your_address_within_1_working_day:
    'You will receive Pi to your address within 1 working day.',
  please_make_sure_you_enter_the_correct_Pi_address_otherwise_your_funds_will_not_be_reversed:
    'Please make sure you enter the correct Pi address; otherwise, your funds will not be reversed.',
  current_capacity: 'Current Capacity',
  what_is_CODG_capacity: 'What is CODG capacity?',
  rng_certificated_by: 'RNG Certificated by',

  // 08/03
  refund: 'Refund',
  // Host
  bet_histories: 'Bet Histories',
  play_histories: 'Play Histories',
  bet_place: 'Bet Place',
  play_place: 'Play Place',
  //
  round_histories: 'Round Histories',
  round_id: 'Round ID',
  profit: 'Profit',
  top: 'Top',
  top_profit: 'Top Profit',
  leader_board: 'Leader Board',
  how_to_join: 'How to join',
  see_more: 'See more',
  top_winning_streak: 'Top winning streak',
  top_losing_streak: 'Top losing streak',
  place: 'Place',
  streak: 'Streak',
  total_prized: 'Total Prized',
  top_reward: 'Top reward',
  top_10_winners: 'Top 10 winners',
  monthly_bonus: 'Monthly Bonus',
  available_rakeback: 'Available Rakeback',
  no_rakeback_yet: 'No rakeback yet',
  claim_my_rakeback: 'Claim My Rakeback',
  wager_required: 'Wager required',
  receive_every: 'Receive every',
  claim_bonus_successfully: 'Claim bonus successfully',
  claim_bonus_failed: 'Claim bonus failed, Please try again!',
  no_bonus_available: 'No bonus available',
  play_to_earn_bonus: 'Play games and start wagering to earn bonus',
  play_and_wager: 'Play & Wager',
  claim_all: 'Claim All',
  duration: 'Duration',
  your_total_bonus: 'Your total bonus',
  roll_under: 'Roll Under',
  game_result_will_be_displayed_here: 'Game result will be displayed here.',
  level_up_bonus_upgrade: 'Level Up Bonus Upgrade',
  _24h_live_support: '24h Live Support',
  bonus_growth: 'Bonus growth',
  xp_required: 'XP required',

  // CODG
  total_received_CODG: 'Total received CODG',
  unlocked_amount: 'Unlocked amount',
  how_to_unlock_CODG: 'How to unlock CODG?',
  swap_and_play: 'Swap and play',
  unlocked_CODG:
    'You could swap your unlocked CODG to other currencies to play and earn',
  available_to_swap: 'Available to swap',
  there_are_no_redeemable_currencies: 'There are no redeemable currencies',
  first_deposit: 'First Deposit',
  _300_bonus: '300% Bonus',
  get_up_to: 'Get up to',
  second_deposit: 'Second Deposit',
  _200_bonus: '200% Bonus',
  third_deposit: 'Third Deposit',
  _150_bonus: '150% Bonus',
  fourth_deposit: 'Fourth Deposit',
  _100_bonus: '100% Bonus',
  deposit_promotion: 'Deposit Promotion',
  unlock_codg_description:
    'Presents an exceptional 4-tiered deposit match bonus, providing a prime opportunity to earn extra rewards in our exclusive token, CODG. Seize this advantage, amplify your funds, and embark on an exhilarating journey with our platform.',

  // Buy Crypto
  our_excited_bonus: 'Our excited bonus journey for you',
  something_is_wrong: 'Something is wrong.',
  disclaimer: 'Disclaimer',
  the_above_third_party_HOST: `The above third party services can be used to purchase crypto that can be used to play on ${process.env.REACT_APP_SITE}. By registering on their platform, you are also accepting to their terms of service and will be required to pass their KYC process, which runs independently to ours.`,
  buy_with_transFI: 'Buy with TransFI',
  order_processing: 'Order Processing',
  your: 'Your',
  buying_order_is_currently:
    'buying order is currently being processed. Please wait for a few minutes',
  // Deposit
  transfer_pi: 'Transfer Pi',
  transfer_Pi_to_the_address_above_which_is_our_agent_address:
    'Transfer Pi to the address above which is our agent address.',
  meet_our_live_support: 'Meet our Live Support',
  click_the_i_transferred_Pi:
    'Click the “I Transferred Pi” button to meet our Live Support.',
  confirm_transaction: 'Confirm transaction',
  send_your_transaction_and_screenshot_to_the_chat_box_as_confirmation:
    'Send your transaction and screenshot to the chat box as confirmation.',
  transfer_Pi_to_this_address: 'Transfer Pi to this address',
  how_to_deposit_Pi: 'How to deposit Pi?',
  i_transferred_Pi: 'I Transferred Pi',
  earn_an_extra_300_bonus_with_a_minimum_deposit_of_1000_USD:
    'Earn an extra 300% bonus with a minimum deposit of 10.00 USD.',
  // Tip
  max_length_ID_not_more_than_20_number:
    'Max length ID not more than 20 number',
  receiver: 'receiver',
  transaction_fee_rate: 'Transaction Fee Rate',
  transaction_fee_amount: 'Transaction Fee Amount',
  you_need_to_wager: 'You need to wager',
  // Withdraw
  more_to_withdraw_due_to_the_bonus_money_policies:
    'more to withdraw due to the bonus money policies',
  pi_address_is_required: 'Pi Address is required',
  pi_Amount_must_specify_a_number: 'Pi Amount must specify a number',
  pi_amount_must_greater_than_10_Pi: 'Pi Amount must greater than 10 Pi',
  pi_amount_must_less_than_10000_Pi: 'Pi Amount must less than 10.000 Pi',
  pi_amount_is_required: 'Pi Amount is required',
  withdraw_successfully_We_will_contact_you_later:
    'Withdraw successfully ! We will contact you later',
  hey_there: 'Hey there',
  please_process_my_Pi_coin_withdrawal_as_follows:
    'Please process my Pi coin withdrawal as follows',
  fees: 'Fees',
  input_your_Pi_address: 'Input your Pi address',
  input_number_of_Pi: 'Input number of Pi',
  over: 'Over',
  under: 'Under',
  lucky_win: 'Lucky win',
  can_not_get_bet_detail: 'Can not get bet detail !',
  can_not_get_play_detail: 'Can not get play detail !',

  // Popup currency
  cannot_be_used_in_this_game:
    'cannot be used in this game for Real Play mode. Please switch to other assets.',
  the_selected_currency_will_be_displayed_in:
    'The selected currency will be displayed in',
  and_if_you_change_currency_while_playing:
    'and if you change currency while playing, the game will refresh and restart.',

  // CODG
  about_CODG: 'About CODG',
  // Host
  CODG_is_the_reward_token_of_Cocogame:
    'CODG is the reward token of Coco.game. The more you engage with the platform, the more CODG you earn to enrich your assets.',
  CODG_is_the_reward_token_of_Pigame:
    'CODG is the reward token of Pi.game. The more you engage with the platform, the more CODG you earn to enrich your assets.',
  CODG_is_the_reward_token_of_Ohplay:
    'CODG is the reward token of Ohplay.club. The more you engage with the platform, the more CODG you earn to enrich your assets.',
  CODG_is_the_reward_token_of_satoshity:
    'CODG is the reward token of satoshity.io. The more you engage with the platform, the more CODG you earn to enrich your assets.',
  CODG_is_the_reward_token_of_betgecko:
    'CODG is the reward token of betgecko.io. The more you engage with the platform, the more CODG you earn to enrich your assets.',
  CODG_is_the_reward_token_of_bitwin:
    'CODG is the reward token of bitwin.club. The more you engage with the platform, the more CODG you earn to enrich your assets.',
  //
  can_CODG_be_swapped_for_other_currencies:
    'Can CODG be swapped for other currencies?',
  you_can_swap_your_unlocked_CODG:
    'You can swap your unlocked CODG for other currencies based on the current exchange rate. However, locked CODG cannot be swapped; it can only be used to play in various games within the platform.',
  // GameBank => Overview
  the_maximum_share_of_all_users: 'The maximum share of all users',
  your_maximum_share: 'Your maximum share',
  total_available_investing_amount: 'Total available investing amount',
  your_available_investing_amount: 'Your available investing amount',
  // Game bank => InvestInformation
  what_is_investing_in_HOST_gamebank: `What is Investing in ${process.env.REACT_APP_SITE} game bank`,
  anyone_can_add_money_to_the_gamebank:
    'Anyone can add money to the Gamebank and join the house side of bets. Your earnings are determined by multiplying the total Gamebank profit by your share.',
  why_should_i_invest: 'Why should I invest?',
  profitable: 'Profitable',
  do_you_believe_in_the_bad_luck_of_other_gamblers:
    'Do you believe in the bad luck of other gamblers? If so, becoming the house is a great way to earn passive income.',
  real_yield: 'Real Yield',
  you_will_receive_profit_in_the_same_token_that_you_invest:
    'You will receive profit in the same token that you invest.',
  analysis_tool: 'Analysis Tool',
  access_gamebank_value:
    'Access Gamebank value, shares, history, and trends to make the best decisions.',
  understand_the_risk_involved: 'Understand the risk involved',
  keep_in_mind_that_the_gamebank_value_may_temporarily_decrease_due_to_gamblers_wins:
    "Keep in mind that the Gamebank's value may temporarily decrease due to gamblers' wins, so you might not see immediate profits. Invest cautiously.",
  invest_information: 'Invest Information',
  details: 'Details',
  // Gift code
  gift_received_successfully: 'Gift received successfully!',
  congratulations_you_have_just_received:
    'Congratulations! You have just received:',
  cod_successfully: 'COD successfully',
  // Transaction
  receiver_nickname: 'Receiver Nickname',
  sender_nickname: 'Sender Nickname',
  tip_successful: 'Tip successful',
  // JFU
  bet_win: 'BET&WIN',
  play_win: 'PLAY&WIN',
  challenge: 'CHALLENGE',
  cash_it_out: 'CASH IT OUT',
  tournament: 'TOURNAMENT',
  november_exclusive: 'NOVEMBER EXCLUSIVE',
  new_game: 'NEW GAME',
  what_is_touch_feature: 'What is Touch feature?',
  touch: 'Touch',
  touch_feature_description_1:
    'To increase excitement and mystery in your game, experience our',
  feature: 'feature',
  touch_feature_description_2: 'During the spinning wheel of fortune',
  touch_enabled_title: 'Touch feature is enabled',
  touch_enabled_description:
    'The screen will be covered and will only be revealed upon the result.',
  touch_disabled_title: 'Touch feature is disabled',
  touch_disabled_description: 'The screen will not be covered while spinning.',
  successfully: 'successfully',
  update_identification_success: 'Update identification successfully',
  kyc_required: 'KYC Required',
  kyc_step1_dialog_subtitle:
    'Complete KYC verification Step 1 to enable withdrawal <$500',
  must_kyc_step_1_to_withdrawal:
    'You must complete KYC Step 1 to enable withdrawal',
  you_need_to_reach_the_rank_to_unlock_this_feature:
    'You need to reach the {{val}} rank to unlock this feature. Check your rank in',
  play_with_balance_in: 'Play with balance in',

  // CODG 05/04
  locked_CODG_is_earned:
    'Locked CODG is earned through specific bonuses or promotions, such as deposit bonuses.',
  unlocking_CODG_is_simple: `Unlocking CODG is simple; it's similar to rakeback and unlocks proportionally with your wagers as follows:`,
  unlocking_formula:
    'Unlock Amount = Wager Amount * House edge as a decimal * X ',
  conversion_formula: `For instance, if you wager $100, you'll unlock 0.2 locked CODG.`,
  note_unlock_codg:
    'Note: To unlock CODG, you need to achieve at least Bronze ranking, which requires 10,000 XP.',
  how_to_claim_COD: 'How to claim COD',
  as_you_place_wagers:
    'As you place wagers, your Piggy will accumulate unlocked CODG, which will then be converted into COD.',
  check_to_claim: 'Check to claim',
  about_CODG_token: 'About CODG Token',
  play_our_games_to_earn_CODG_for_free: 'Play our games to earn CODG for free!',
  how_to_unlock: 'How to unlock',
  how_to_claim: 'How to claim',
  today_biggest_winners: 'Today Biggest Winners',
  and_get_up_to: 'and get up to',
  to_get: 'to get',
  claim_successful: 'Claim successful',
  email_verified: 'Email Verified',

  refer_description_coco:
    "Refer friends to coco.game's Affiliate Program and earn commissions for every user who registers and plays through your links, regardless of their outcomes",
  refer_description_pi:
    "Refer friends to pi.game's Affiliate Program and earn commissions for every user who registers and plays through your links, regardless of their outcomes",
  refer_description_ohplay:
    "Refer friends to ohplay.club's Affiliate Program and earn commissions for every user who registers and plays through your links, regardless of their outcomes",
  refer_description_satoshity:
    "Refer friends to satoshity.io's Affiliate Program and earn commissions for every user who registers and plays through your links, regardless of their outcomes",
  refer_description_betgecko:
    "Refer friends to betgecko.io's Affiliate Program and earn commissions for every user who registers and plays through your links, regardless of their outcomes",
  refer_description_bitwin:
    "Refer friends to bitwin.club's Affiliate Program and earn commissions for every user who registers and plays through your links, regardless of their outcomes",
  your_email_verified_coco:
    'Your email had been verified. Let’s explore coco.game.',
  your_email_verified_pi:
    'Your email had been verified. Let’s explore pi.game.',
  your_email_verified_ohplay:
    'Your email had been verified. Let’s explore ohplay.club.',
  your_email_verified_satoshity:
    'Your email had been verified. Let’s explore satoshity.io.',
  your_email_verified_betgecko:
    'Your email had been verified. Let’s explore betgecko.io.',
  your_email_verified_bitwin:
    'Your email had been verified. Let’s explore bitwin.club.',
  GAME: 'Game',
  AFFILIATE: 'Affiliate',
  ACCOUNT: 'Account',
  BONUS: 'Bonus',
  INVEST: 'Invest',
  SWAP: 'Swap',
  claim_now: 'Claim Now',
  learn_more: 'Learn more',
  deposit_reward: 'DEPOSIT REWARD',
  roll_dice_trio: 'ROLL DICE TRIO',
  hit_1000: 'HIT $1000',
  full_jackpot: 'FULL JACKPOT',
  play_game2: 'Play Game',
  up_to: 'Up to',
  for: 'for',
  free_5_usdt: 'FREE $5 USDT',
  non_deposited: 'NON-DEPOSITED',
  hands: 'Hands',
  total_bet: 'Total Bet',
  deal: 'Deal',
  bet_and_win: 'BET & WIN',
  daily_wager_tournament: 'DAILY WAGER TOURNAMENT',
  prize_pool: 'PRIZE POOL',
  race_now: 'Race Now',
  fuel_your_experience: 'Fuel your experience',
};

export default en;
