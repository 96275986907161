import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, BoxProps, Link } from '@mui/material';
import { LogoFooter } from 'overrides/images/footer';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          display: 'inline-flex',
          mt: 1,
          ...sx,
        }}
        {...other}
      >
        <LogoFooter sx={{ width: 150, height: 50 }} />
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  },
);

export default Logo;
