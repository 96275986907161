import { IconBookOpen, IconCoinQuanlity, IconUnlock } from 'assets/icons';
import { pigCoinCodLock } from 'assets/images';
import Button from 'common/Button';
import { DialogAnimate } from 'components/animate';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import * as React from 'react';
/* eslint-disable react/no-unescaped-entities */
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import {
  DialogBody,
  DialogHeader,
  DialogWrapper,
} from 'components/Dialog/styles';

import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';

import { CODGInfo } from 'overrides/components/text-host/TextHost';
import { AboutCodgBg } from './styles';

// ------------------------------------------------------------------

export interface ILockCurrencyDialogProps {
  open: boolean;
  handleClose: () => void;
  handleOpenDeposit: () => void;
}

export function LockCurrencyDialog(props: ILockCurrencyDialogProps) {
  const { open, handleClose, handleOpenDeposit } = props;
  const theme = useTheme();
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'md');
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isShowOverview = () => (
    <>
      <Box mt="24px">
        <Typography
          fontSize={isDesktop ? '18px' : '16px'}
          fontWeight="700"
          color="white"
        >
          {translate('about_CODG')}
        </Typography>

        <Typography
          color="#9998D4"
          fontSize={isDesktop ? '15px' : '14px'}
          fontWeight="400"
          whiteSpace="normal"
          mt={1}
        >
          {translate(CODGInfo())}
        </Typography>
      </Box>

      <Box mt="24px">
        <Typography
          color="white"
          fontSize={isDesktop ? '18px' : '16px'}
          fontWeight="700"
          whiteSpace="normal"
        >
          {translate('can_CODG_be_swapped_for_other_currencies')}
        </Typography>
        <Typography
          color="#9998D4"
          fontSize={isDesktop ? '15px' : '14px'}
          fontWeight="400"
          whiteSpace="normal"
          mt={1}
        >
          {translate('you_can_swap_your_unlocked_CODG')}
        </Typography>
      </Box>
    </>
  );

  const isShowHowToUnlock = () => (
    <Box mt="24px">
      <Typography
        color="white"
        fontSize={isDesktop ? '18px' : '16px'}
        fontWeight="700"
      >
        {translate('how_to_unlock_COD')}
      </Typography>
      <Typography
        color="#9998D4"
        fontSize={isDesktop ? '15px' : '14px'}
        fontWeight="400"
        whiteSpace="normal"
        mt={1}
      >
        <p>{translate('locked_CODG_is_earned')}</p>
        <p>{translate('unlocking_CODG_is_simple')}</p>
        <p>{translate('unlocking_formula')}</p>
        <p>{translate('conversion_formula')}</p>
        <p>{translate('note_unlock_codg')}</p>
      </Typography>
    </Box>
  );

  const isShowHowToClaim = () => (
    <>
      <Box mt="24px">
        <Typography
          color="white"
          fontSize={isDesktop ? '18px' : '16px'}
          fontWeight="700"
        >
          {translate('how_to_claim_COD')}
        </Typography>
        <Box justifyContent="center" display="flex">
          <img height={132} src={pigCoinCodLock} alt="pigCoinCodLock" />
        </Box>
        <Typography
          color="#9998D4"
          fontSize={isDesktop ? '15px' : '14px'}
          fontWeight="400"
          whiteSpace="normal"
          mt={1}
        >
          <p style={{ margin: '0' }}>{translate('as_you_place_wagers')}</p>
          {/* <p style={{ margin: '0' }}>
            Once your Piggy holds a minimum of 10 COD, simply click the 'Claim'
            button to transfer the COD directly to your balance.
          </p> */}
        </Typography>
      </Box>
      <Box mt="24px">
        <Button
          style={{
            width: '100%',
            padding: theme.spacing(1.5),
            [theme.breakpoints.down(450)]: {
              fontSize: 14,
              padding: theme.spacing(1.25),
            },
          }}
          onClick={handleOpenDeposit}
        >
          {translate('check_to_claim')}
        </Button>
      </Box>
    </>
  );

  return (
    <DialogAnimate
      open={open}
      onClose={handleClose}
      sx={{
        width: 600,
        maxWidth: 'max-content',
        justifyContent: 'center',
        height: 710,
        [theme.breakpoints.down(600)]: {
          maxHeight: 'unset',
          maxWidth: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
    >
      <DialogWrapper>
        <DialogHeader
          className="about-header"
          hasCloseBtn
          backgroundHeader={theme.palette.background.secondary}
          padding="16px 16px 0 !important"
        >
          <Typography variant="h6" className="about-codg-title">
            {translate('about_CODG_token')}
          </Typography>

          <IconButton onClick={() => handleClose()} sx={{ padding: '0' }}>
            <CloseCircle />
          </IconButton>
        </DialogHeader>

        <DialogBody
          sx={{
            // overflowY: isDesktop ? 'hidden' : '',
            // backgroundImage: `url(${LockCurrencyBackground})`,
            background: theme.palette.background.secondary,
            padding: '24px 24px',
          }}
        >
          <Box display="flex" flexDirection="column" py={1} pb={2}>
            <AboutCodgBg className="about-codg-bg">
              <Typography variant="h4">1 CODG ~ 1 USD</Typography>

              <Typography component="span">
                {translate('play_our_games_to_earn_CODG_for_free')}
              </Typography>
            </AboutCodgBg>

            <Box className="about-codg-tab" mt="24px" width="100%">
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                  width: '100%',
                  backgroundColor: '#161432',
                  borderRadius: '12px',
                  '.css-1pyy021-MuiTabs-flexContainer': {
                    gap: theme.spacing(5),
                  },
                }}
                TabIndicatorProps={{
                  sx: {
                    height: '0',
                  },
                }}
                variant="fullWidth"
              >
                <Tab
                  sx={{
                    width: '184px',
                    mr: '0px !important',
                    height: '48px !important',
                    borderRadius: '12px',
                    background:
                      value === 0
                        ? 'linear-gradient(180deg, #313064 0%, #43416C 100%)'
                        : '',
                  }}
                  icon={
                    isDesktop && (
                      <IconBookOpen color={value === 0 ? 'white' : ''} />
                    )
                  }
                  label={
                    <Typography
                      fontSize={isDesktop ? '14px' : '12px'}
                      fontWeight={isDesktop ? '500' : '600'}
                      color={value === 0 ? 'white' : '#9998D4'}
                    >
                      {translate('overview')}
                    </Typography>
                  }
                />
                <Tab
                  sx={{
                    width: '184px',
                    height: '48px !important',
                    mr: '0px !important',
                    borderRadius: '12px',
                    background:
                      value === 1
                        ? 'linear-gradient(180deg, #313064 0%, #43416C 100%)'
                        : '',
                  }}
                  icon={
                    isDesktop && (
                      <IconUnlock color={value === 1 ? 'white' : ''} />
                    )
                  }
                  iconPosition="start"
                  label={
                    <Typography
                      fontSize={isDesktop ? '14px' : '12px'}
                      fontWeight={isDesktop ? '500' : '600'}
                      color={value === 1 ? 'white' : '#9998D4'}
                    >
                      {translate('how_to_unlock')}
                    </Typography>
                  }
                />
                <Tab
                  sx={{
                    width: '184px',
                    height: '48px !important',
                    mr: '0px !important',
                    borderRadius: '12px',
                    background:
                      value === 2
                        ? 'linear-gradient(180deg, #313064 0%, #43416C 100%)'
                        : '',
                  }}
                  icon={
                    isDesktop && (
                      <IconCoinQuanlity color={value === 2 ? 'white' : ''} />
                    )
                  }
                  iconPosition="start"
                  label={
                    <Typography
                      fontSize={isDesktop ? '14px' : '12px'}
                      fontWeight={isDesktop ? '500' : '600'}
                      color={value === 2 ? 'white' : '#9998D4'}
                    >
                      {translate('how_to_claim')}
                    </Typography>
                  }
                />
              </Tabs>
            </Box>
            {value === 0 && isShowOverview()}
            {value === 1 && isShowHowToUnlock()}
            {value === 2 && isShowHowToClaim()}
            {/* <EffectCodLock /> */}
          </Box>
        </DialogBody>
      </DialogWrapper>
    </DialogAnimate>
  );
}
