import { DialogHeaderStyled } from 'common/Dialog';
import { DialogWrapper } from 'components/Dialog/styles';

import { Box, styled } from '@mui/material';

export const GeneralInfoUploadingWrapper = styled(Box)(({ theme }) => ({
  '.MuiStack-root .MuiBox-root': {
    flex: 1,
  },
  '.MuiOutlinedInput-root': {
    '.MuiSelect-icon': {
      top: 'initial',
    },
  },
  '&& .MuiInputBase-root': {
    maxHeight: 44,
    backgroundColor: theme.palette.background.main,
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 48,
      height: 48,
    },
  },
  '.MuiAutocomplete-root': {
    '.MuiInputBase-root': {
      padding: 10,
    },
  },
}));

export const GeneralInfoDialogHeader = styled(DialogHeaderStyled)`
  .dialog-title {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    font-size: 24px;
  }
  .close-btn {
    margin-left: auto;
  }
`;

export const GeneralInfoDialogBody = styled(DialogWrapper)(({ theme }) => ({
  borderRadius: 'initial',
  '.general-info-subtitle': {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    margin: theme.spacing(2, 'auto', 3),
  },
  '.label': {
    color: theme.palette.text.contrast,
  },
  '.general-info-wrapper': {
    '& > .MuiStack-root': {
      gap: theme.spacing(1.5),
    },
  },
  '.MuiStack-root': {
    flexWrap: 'wrap',
    '.MuiBox-root': {
      minWidth: 170,
    },
  },
  '.update-general-info-btn': {
    minHeight: 44,
    fontWeight: 700,
    marginTop: theme.spacing(3),
  },
}));
