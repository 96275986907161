import { useAuthContext } from 'auth/useAuthContext';
import { useLocales } from 'locales';
import VipLevelsSlider from 'pages/VipClub/components/VipLevel';
import VipProgress from 'pages/VipClub/components/VipPoint/VipProgress_v2';

import { Box, Typography } from '@mui/material';

export default function VipClubOverviewTab({ handleClickLogin }) {
  const { translate } = useLocales();
  const { isAuthenticated } = useAuthContext();

  return (
    <>
      <Box>
        {isAuthenticated ? (
          <VipProgress />
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              my={{ sm: 1, xs: 0.5 }}
            >
              <Typography color="text.contrast" fontWeight={500}>
                {translate('your_vip_progress')}
              </Typography>
            </Box>
            <Box>
              <Typography>
                <Typography
                  onClick={() => handleClickLogin()}
                  component="span"
                  color="primary.main"
                  sx={{
                    cursor: 'pointer',
                    mr: 0.5,
                    fontWeight: 700,
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {translate('login')}
                </Typography>
                {translate('to_see_your_vip_progress')}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box mt={{ xs: 3 }}>
        <Typography
          color="text.contrast"
          fontSize={16}
          fontWeight={700}
          textAlign="center"
        >
          {translate('vip_club_benefit')}
        </Typography>
      </Box>
      <VipLevelsSlider />
    </>
  );
}
