import { useSettingsContext } from 'components/settings';
import MiniGameBetHistoryDialog from 'components/_Games/MiniGameBetHistoryDialog';
import MiniGameBigWinnerDialog from 'components/_Games/MiniGameBigWinnerDialog';
import MiniCrash from 'components/_Games/_GameMiniCrash';
import Paytable from 'components/_Games/_GameMiniPoker/Paytable';
import MiniDiceBetHistories from 'components/_Games/_GamesMiniDice/bet-histories';
import MiniDiceBetRoundDetailDialog from 'components/_Games/_GamesMiniDice/bet-round-detail/MiniDiceBetRoundDetailDialog';
import MiniDiceEventDialog from 'components/_Games/_GamesMiniDice/mini-dice-event';
import MiniDiceTrends from 'components/_Games/_GamesMiniDice/mini-dice-trends';
import MiniDiceTopProfitDialog from 'components/_Games/_GamesMiniDice/top-profit/MiniDiceTopProfitDialog';
import MiniDiceTouchFeatureDetail from 'components/_Games/_GamesMiniDice/touch-feature-detail';
import { HEADER, NAV } from 'config/config-global';
import { useGamesQuery } from 'graph';
import useHandleWalletChange from 'hooks/useHanldeWalletChanged';
import { useMiniGame } from 'hooks/useMiniGame';
import useResponsive from 'hooks/useResponsive';
import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import Header from './header';
import Main from './Main';
import NavHorizontal from './nav/NavHorizontal';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';

// ----------------------------------------------------------------------

const IS_NAV_COLLAPSE = 'isNavCollapse';

function DashboardLayout() {
  useHandleWalletChange();
  const location = useLocation();
  const { data: gamesData } = useGamesQuery({
    variables: {
      page: 1,
      pageSize: 100,
    },
    fetchPolicy: 'cache-only',
  });

  const {
    showMiniCrash,
    setShowMiniCrash,
    showPaytable,
    hidePaytable,
    showBetHistory,
    hideHistory,
    showWinnerHistory,
    hideWinner,
    isShowEventDialog,
    isShowTrendsDialog,
    isShowTopProfitDialog,
    isShowRoundDetailDialog,
    isShowTouchFeatureDetail,
  } = useMiniGame(['minipoker', 'minicrypto', 'threedice', 'minicrash']);
  const { themeBottomBar, themeHeader } = useSettingsContext();
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');
  const isDesktop = useResponsive('up', 'lg');
  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState(false);

  const storageIsNavCollapse = JSON.parse(
    localStorage.getItem(IS_NAV_COLLAPSE),
  );

  const [isLeftNavBarOpen, setIsLeftNavBarOpen] = useState(
    storageIsNavCollapse || true,
  );

  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] =
    useState(false);

  const renderNavMobile = (() => {
    if (isMobile)
      return <NavHorizontal onOpenNav={() => setIsLeftNavBarOpen(true)} />;
    return null;
  })();

  const renderNavVertical = (() => {
    if (isLeftNavBarOpen)
      return (
        <NavVertical
          isNavOpen={isLeftNavBarOpen}
          setOpenNav={setIsLeftNavBarOpen}
        />
      );
    return null;
  })();

  const renderNavVerticalCollapse = (() => {
    if (!isLeftNavBarOpen && !isMobile)
      return (
        <NavMini
          isNavOpen={isLeftNavBarOpen}
          handleToggleNav={() => setIsLeftNavBarOpen((prev) => !prev)}
        />
      );
  })();

  useEffect(() => {
    localStorage.setItem(IS_NAV_COLLAPSE, JSON.stringify(isLeftNavBarOpen));
  }, [isLeftNavBarOpen]);

  useEffect(() => {
    if (isTablet) {
      setIsLeftNavBarOpen(false);
    }
  }, [location]);

  return (
    <Box display="flex" height="100%">
      {themeBottomBar && renderNavMobile}
      {renderNavVertical}
      {renderNavVerticalCollapse}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: `calc(100% - ${
            !isLeftNavBarOpen ? NAV.W_DASHBOARD_MINI : NAV.W_BASE
          }px - ${isChatDrawerOpen || isNotificationDrawerOpen ? 360 : 0}px)`,
          ...(isDesktop &&
            isLeftNavBarOpen && {
              marginLeft: `${NAV.W_BASE}px`,
            }),
          ...(isDesktop &&
            (isNotificationDrawerOpen || isChatDrawerOpen) && {
              marginRight: `${360}px`,
              transition: (theme) =>
                theme.transitions.create(['margin', 'width'], {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }),
          ...(themeHeader && { marginTop: `${HEADER.H_MAIN_DESKTOP}px` }),
          ...(isMobile && {
            ...(themeHeader && { marginTop: `${HEADER.H_MOBILE}px` }),
            width: '100%',
          }),
          position: 'relative',
        }}
      >
        <Header
          isNavOpen={isLeftNavBarOpen}
          setOpenNav={setIsLeftNavBarOpen}
          isDrawerNotiOpen={isNotificationDrawerOpen}
          isDrawerChatOpen={isChatDrawerOpen}
          setIsDrawerNoti={(e) => setIsNotificationDrawerOpen(e)}
          setIsDrawerChat={(e) => setIsChatDrawerOpen(e)}
        />
        <Main open={isNotificationDrawerOpen || isChatDrawerOpen}>
          <Outlet />
        </Main>
      </Box>
      {showMiniCrash && <MiniCrash open={setShowMiniCrash} />}
      {gamesData?.games?.data
        ?.filter(
          (r) =>
            r?.game?.slug === 'minipoker' ||
            r?.game?.slug === 'minicrypto' ||
            r?.game?.slug === 'threedice',
        )
        ?.map((ccg) => {
          const gg = ccg?.game;
          if (!gg) return;

          return (
            <Fragment key={gg.id}>
              {showPaytable[gg?.slug] && (
                <Paytable
                  onClose={() => {
                    hidePaytable(gg?.slug);
                  }}
                  slug={gg?.slug}
                />
              )}
              {showBetHistory[gg?.slug] && (
                <>
                  {gg?.slug === 'threedice' ? (
                    <MiniDiceBetHistories
                      open={showBetHistory[gg?.slug]}
                      onClose={() => {
                        hideHistory(gg?.slug);
                      }}
                    />
                  ) : (
                    <MiniGameBetHistoryDialog
                      isOpen={showBetHistory[gg?.slug]}
                      handleClose={() => {
                        hideHistory(gg?.slug);
                      }}
                      gameId={gg?.id}
                    />
                  )}
                </>
              )}
              {showWinnerHistory[gg?.slug] && (
                <MiniGameBigWinnerDialog
                  isOpen={showWinnerHistory[gg?.slug]}
                  handleClose={() => {
                    hideWinner(gg?.slug);
                  }}
                  gameId={gg?.id}
                />
              )}
              {isShowEventDialog[gg?.slug] && (
                <MiniDiceEventDialog
                  open={isShowEventDialog[gg?.slug]}
                  onClose={() => {
                    window[`ccg_${gg?.slug}`].onOpenGameEventDialog(false);
                  }}
                />
              )}
              {isShowRoundDetailDialog[gg?.slug] && (
                <MiniDiceBetRoundDetailDialog
                  open={isShowRoundDetailDialog[gg?.slug]}
                  onClose={() => {
                    window[`ccg_${gg?.slug}`].onOpenRoundDetailDialog(
                      false,
                      null,
                    );
                  }}
                />
              )}
              {isShowTopProfitDialog[gg?.slug] && (
                <MiniDiceTopProfitDialog
                  open={isShowTopProfitDialog[gg?.slug]}
                  onClose={() => {
                    window[`ccg_${gg?.slug}`].onOpenTopProfitDialog(false);
                  }}
                />
              )}

              {isShowTrendsDialog[gg?.slug] && (
                <MiniDiceTrends
                  onClose={() => {
                    window[`ccg_${gg?.slug}`].onOpenTrendsDialog(false);
                  }}
                />
              )}
              {isShowTouchFeatureDetail[gg?.slug] && (
                <MiniDiceTouchFeatureDetail
                  open={isShowTouchFeatureDetail[gg?.slug]}
                  onClose={() => {
                    window[`ccg_${gg?.slug}`].onOpenTouchFeatureDialog(false);
                  }}
                />
              )}
            </Fragment>
          );
        })}
    </Box>
  );
}

export default React.memo(DashboardLayout);
