import Button from 'common/Button';
import { useLocales } from 'locales';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { PopupBuyCryptoWrapper } from './style';

// -------------------------------------------------------------------

interface PopupBuyCryptoProps {
  crypto: string;
}
const PopupBuyCrypto = ({ crypto }: PopupBuyCryptoProps) => {
  const { translate } = useLocales();
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const navigate = useNavigate();

  return (
    <PopupBuyCryptoWrapper>
      <Box className={isPopupVisible ? 'popup' : 'hidden'}>
        <Box className="popup-content">
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: '800',
              color: 'white',
            }}
          >
            {translate('order_processing')}
          </Typography>

          <Box component="p">
            {translate('your')}{' '}
            <b
              style={{
                color: '#EFC516',
              }}
            >
              {crypto}
            </b>{' '}
            {translate('buying_order_is_currently')}
          </Box>
          <Box className="popup-btn">
            <Button
              size="large"
              onClick={() => {
                setIsPopupVisible(false);
                navigate('/');
              }}
            >
              {translate('close')}
            </Button>
          </Box>
        </Box>
      </Box>
    </PopupBuyCryptoWrapper>
  );
};

export default PopupBuyCrypto;
