import Button from 'common/Button';
import { DialogAnimate } from 'components/animate';
import { DialogWrapper } from 'components/Dialog/styles';
import Iconify from 'components/iconify';
import WalletDialog from 'components/WalletDialog';
import WalletDialogHeader from 'components/WalletDialog/components/WalletDialogHeader';
import { User } from 'graph';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { Body } from './styles';

// ------------------------------------------------------------

interface Props {
  open: boolean;
  onClose: VoidFunction;
  user:
    | DeepPartial<User>
    | {
        id: string;
        nickname: string;
        avatar: {
          uri: string;
          square: string;
        };
      };
}

const UserProfileDialog = ({ open, onClose, user }: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        width: 600,
        backgroundColor: 'unset',
        overflow: 'hidden',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogWrapper
        sx={{
          padding: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 1.5),
          },
        }}
      >
        <WalletDialogHeader title="user_profile" handleClose={onClose} />
        <Body>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                  gap: theme.spacing(1),
                },
              }}
            >
              <Avatar
                className="user_avatar"
                src={user?.avatar?.square}
                alt={user?.nickname}
              />

              <Stack>
                <Typography
                  variant="h6"
                  fontSize={{ xs: 16, sm: 18 }}
                  fontWeight={700}
                  color="secondary.main"
                  lineHeight="normal"
                  textTransform="none"
                >
                  {user?.nickname || translate('unknown')}
                </Typography>

                {user.id && (
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: 'normal',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 12,
                      },
                      [theme.breakpoints.down(450)]: {
                        fontSize: 10,
                      },
                    }}
                    variant="caption"
                  >
                    {`ID: ${user?.id}`}
                    <Typography component="span">
                      <IconButton
                        sx={{
                          p: 0,
                          ml: 0.5,
                        }}
                        onClick={() => {
                          copy(user.id);
                          enqueueSnackbar(translate('copied'));
                        }}
                      >
                        <Iconify icon="bxs:copy" width={16} />
                      </IconButton>
                    </Typography>
                  </Typography>
                )}
              </Stack>
            </Box>

            <Button className="tip_btn" onClick={() => setSelectedUser(user)}>
              <Iconify icon="fluent:money-24-filled" />

              {translate('leave_a_tip')}
            </Button>
          </Stack>
        </Body>
      </DialogWrapper>

      {!!selectedUser && (
        <WalletDialog
          isOpen
          handleClose={() => {
            onClose();
            setSelectedUser(null);
          }}
          tabIdDefault={4}
          user={selectedUser}
        />
      )}
    </DialogAnimate>
  );
};

export default UserProfileDialog;
