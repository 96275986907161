import { DialogHeader } from 'components/Dialog/styles';

import { styled } from '@mui/material';

export const DialogHeaderStyled = styled(DialogHeader)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: 0,
  '.MuiIconButton-root': {
    padding: 0,
  },
  '.navigation': {
    padding: theme.spacing(0.5, 1.5),
    gap: theme.spacing(1.25),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.primary,
    alignItems: 'center',
  },
}));
