import { SvgIcon } from '@mui/material';

export function IconTransfer(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5 15.7V15.81L2.5 15.81V15.7C2.5 14.8162 2.62269 14.4656 2.79472 14.2949C2.96812 14.1228 3.32548 14 4.22 14H9.78C10.6745 14 11.0319 14.1228 11.2053 14.2949C11.3773 14.4656 11.5 14.8162 11.5 15.7Z"
        fill="#858997"
        stroke="#858997"
      />
      <path
        d="M2.5 18.8096V17.8096H11.5V18.8096V19.7996C11.5 20.6833 11.3773 21.0339 11.2053 21.2047C11.0319 21.3768 10.6745 21.4996 9.78 21.4996H4.22C3.32548 21.4996 2.96812 21.3768 2.79472 21.2047C2.62269 21.0339 2.5 20.6833 2.5 19.7996V18.8096Z"
        fill="#858997"
        stroke="#858997"
      />
      <path
        d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25"
        stroke="#858997"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 9C2 5.13 5.13 2 9 2L7.95 3.75"
        stroke="#858997"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 11C20.9853 11 23 8.98528 23 6.5C23 4.01472 20.9853 2 18.5 2C16.0147 2 14 4.01472 14 6.5C14 8.98528 16.0147 11 18.5 11Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
