import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { BetAmountHost } from 'overrides/components/text-host/TextHost';
import { memo } from 'react';

interface IHeaderHighBetWrapperProps {
  gameId: string;
  isBetsByGame: boolean;
}

const HeaderHighBetWrapper = memo(
  (props: Partial<IHeaderHighBetWrapperProps>) => {
    const { gameId, isBetsByGame } = props;
    const { translate } = useLocales();
    const isMobile = useResponsive('down', 'sm');

    return (
      <TableHead>
        <TableRow>
          {!isBetsByGame && (
            <TableCell align="left" width="15%">
              <Typography color="text.primary" ml={1}>
                {translate('game')}
              </Typography>
            </TableCell>
          )}
          <TableCell align={isBetsByGame ? 'left' : 'center'} width="25%">
            <Typography color="text.primary">{translate('player')}</Typography>
          </TableCell>
          {(!isMobile || gameId) && (
            <TableCell align="right" width="25%">
              <Typography color="text.primary">
                {translate(BetAmountHost())}
              </Typography>
            </TableCell>
          )}
          <TableCell align="center" width="20%">
            <Typography color="text.primary">
              {translate('multiplier')}
            </Typography>
          </TableCell>
          <TableCell align="right" sx={{ width: { sm: '20%', xs: '15%' } }}>
            <Typography color="text.primary" mr={1}>
              {translate('payout')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  },
);

export { HeaderHighBetWrapper };
