import { SvgIcon } from '@mui/material';

export function IconTwitter(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_6403_139318)">
        <path
          d="M34.1422 34.1425C41.9527 26.3319 41.9527 13.6685 34.1422 5.8579C26.3316 -1.95267 13.6682 -1.95267 5.85761 5.8579C-1.95295 13.6685 -1.95295 26.3319 5.85761 34.1425C13.6682 41.953 26.3316 41.953 34.1422 34.1425Z"
          fill="#858997"
        />
        <path
          d="M22.3976 18.7498L30.0248 9.66022H27.9568L21.3638 17.5176L14.7708 9.66022H7.87744L17.9171 21.625L10.6041 30.3402H12.6722L18.9509 22.8572L25.2296 30.3402H32.123L22.3976 18.7498ZM10.8338 11.0388H14.1278L29.1666 28.9616H25.8726L10.8338 11.0388Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6403_139318">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
