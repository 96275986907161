import { NavSectionVertical as V2 } from 'components/nav-section';
import Scrollbar from 'components/scrollbar';
import { NAV } from 'config/config-global';
import { HouseGame, useGamesQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { Dispatch, memo, SetStateAction } from 'react';

import { Box, Drawer } from '@mui/material';

import { getConfigNavWithGames } from './config-navigation';

// ----------------------------------------------------------------------

type Props = {
  isNavOpen: boolean;
  setOpenNav: Dispatch<SetStateAction<boolean>>;
};

function NavVertical({ isNavOpen, setOpenNav }: Props) {
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');

  const { data: gamesData } = useGamesQuery({
    variables: {
      page: 1,
      pageSize: 100,
    },
    fetchPolicy: 'cache-only',
  });

  const navConfig = getConfigNavWithGames(
    gamesData?.games?.data as HouseGame[],
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100vh',
        '& .simplebar-content': {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <V2 data={navConfig} isNavOpen={isNavOpen} setOpenNav={setOpenNav} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      className="nav-vertical"
      sx={{
        position: 'fixed',
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
        boxShadow:
          '0px 7px 0px -4px rgba(0, 0, 0, 0.2), 0px 12px 0px 2px rgba(0, 0, 0, 0.14), 0px 5px 0px 4px rgba(0, 0, 0, 0.12);',
        zIndex: 99,
        top: ' 64px',
      }}
    >
      {isDesktop ? (
        renderContent
      ) : (
        <Drawer
          transitionDuration={300}
          open={isNavOpen}
          onClose={() => setOpenNav(false)}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
              ...(isMobile && {
                width: NAV.W_MOBILE,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

export default memo(NavVertical);
