// auth
import { ChatWindown } from 'components/Chats/ChatWindown';
import VerifyEmail from 'pages/Authentication/VerifyEmail';
import PageComingSoon from 'pages/PageComingSoon';
import { Navigate, useRoutes } from 'react-router-dom';

import AuthGuard from '../auth/AuthGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import RecoverPassword from '../pages/Authentication/RecoverPassword';
import { PATH_AUTH, PATH_DASHBOARD } from './paths';
// config
import { helpCenterRoutes, profileSettingsRoutes } from './routesPage';
//
import {
  GameDetail,
  GameHubPage,
  HubJackpotGamesPage,
  HubOriginalsPage,
  HubProvidersPage,
  Maintain,
  Page404,
  RegionProhibited,
  V2AffiliatePage,
  VipClubPage2,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: <DashboardLayout />,

      children: [
        { element: <GameHubPage />, index: true },
        {
          path: 'casino',
          children: [
            {
              element: <Navigate to="/casino/home" replace />,
              index: true,
            },
            { path: 'home', element: <GameHubPage /> },
            {
              path: 'original',
              children: [
                {
                  element: <Navigate to="/casino/home" replace />,
                  index: true,
                },
                { path: ':slug', element: <GameDetail isExGame={false} /> },
                {
                  path: 'filter',
                  element: <HubOriginalsPage />,
                },
              ],
            },
            {
              path: 'jackpot',
              children: [
                {
                  element: <Navigate to="/casino/home" replace />,
                  index: true,
                },
                { path: ':slug', element: <GameDetail isExGame={false} /> },
                {
                  path: 'filter',
                  element: <HubJackpotGamesPage />,
                },
              ],
            },
            {
              path: 'provider',
              children: [
                {
                  element: <Navigate to="/casino/home" replace />,
                  index: true,
                },
                { path: ':slug', element: <GameDetail isExGame /> },
                {
                  path: 'filter',
                  element: <HubProvidersPage />,
                },
              ],
            },
          ],
        },
        { path: PATH_DASHBOARD.affiliate.root, element: <V2AffiliatePage /> },
        { path: PATH_AUTH.recoverPassword, element: <RecoverPassword /> },
        { path: PATH_AUTH.verifyEmail, element: <VerifyEmail /> },
        helpCenterRoutes,
        { path: PATH_DASHBOARD.vipClub.root, element: <VipClubPage2 /> },
        {
          path: 'coming-soon',
          element: <PageComingSoon />,
        },
      ],
    },
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [profileSettingsRoutes()],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: 'unavailable-region', element: <RegionProhibited /> },
        { path: 'maintenance', element: <Maintain /> },
      ],
    },
    { path: '*', element: <Navigate to="/" replace /> },
    {
      path: PATH_DASHBOARD.chat.root,
      element: <ChatWindown />,
    },
  ]);
}
