import { SvgIcon } from '@mui/material';

export function IconWarning2(props: any) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0073 3.89967L9.04727 1.61301C8.4006 1.23967 7.6006 1.23967 6.94727 1.61301L2.99393 3.89967C2.34727 4.27301 1.94727 4.96634 1.94727 5.71967V10.2797C1.94727 11.0263 2.34727 11.7197 2.99393 12.0997L6.95393 14.3863C7.6006 14.7597 8.4006 14.7597 9.05393 14.3863L13.0139 12.0997C13.6606 11.7263 14.0606 11.033 14.0606 10.2797V5.71967C14.0539 4.96634 13.6539 4.27967 13.0073 3.89967ZM7.5006 5.16634C7.5006 4.89301 7.72727 4.66634 8.0006 4.66634C8.27393 4.66634 8.5006 4.89301 8.5006 5.16634V8.66634C8.5006 8.93967 8.27393 9.16634 8.0006 9.16634C7.72727 9.16634 7.5006 8.93967 7.5006 8.66634V5.16634ZM8.61393 11.0863C8.5806 11.1663 8.53393 11.2397 8.47393 11.3063C8.34727 11.433 8.1806 11.4997 8.0006 11.4997C7.91393 11.4997 7.82727 11.4797 7.74727 11.4463C7.6606 11.413 7.59393 11.3663 7.52727 11.3063C7.46727 11.2397 7.4206 11.1663 7.3806 11.0863C7.34727 11.0063 7.33393 10.9197 7.33393 10.833C7.33393 10.6597 7.4006 10.4863 7.52727 10.3597C7.59393 10.2997 7.6606 10.253 7.74727 10.2197C7.99393 10.113 8.28727 10.173 8.47393 10.3597C8.53393 10.4263 8.5806 10.493 8.61393 10.5797C8.64727 10.6597 8.66727 10.7463 8.66727 10.833C8.66727 10.9197 8.64727 11.0063 8.61393 11.0863Z"
        fill="#FF5C00"
      />
    </SvgIcon>
  );
}
