import { ApolloClient, ApolloLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { clearLocalStorage, getLocalStorage } from 'auth/utils';
import {
  ACCESS_TOKEN,
  GRAPHQL_API_URI,
  GRAPHQL_SUBSCRIPTIONS_URI,
} from 'constants/index';
import { cache } from 'graph/cache';
import { createClient } from 'graphql-ws';
import jwtDecode from 'jwt-decode';

export const SITE_HOUSE_DOMAINS = {
  coco: '',
  pi: 'pi.game',
  bitwin: 'bitwin.club',
  ohplay: 'ohplay.club',
  satoshity: 'satoshity.io',
  betgecko: 'betgecko.io',
};

const httpLink = createUploadLink({
  uri: GRAPHQL_API_URI,
  credentials: 'same-origin',
});

const wsLink: any = () => {
  const token = getLocalStorage(ACCESS_TOKEN);
  return new GraphQLWsLink(
    createClient({
      url: `${GRAPHQL_SUBSCRIPTIONS_URI}?authToken=${token}`,
      connectionParams: {
        authToken: token,
      },
    }),
  );
};

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink(),
  httpLink,
);

const errorLink = onError(({ graphQLErrors, networkError }: any) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions?.code === 'AUTH_NOT_AUTHORIZED') {
        clearLocalStorage();
      }
    });
  }
  if (networkError) {
    const { statusCode } = networkError;
    if (statusCode === 401) {
      clearLocalStorage();
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = getLocalStorage(ACCESS_TOKEN);
  if (token) {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken) {
      const { exp } = decodedToken;
      const noww = Math.floor(Date.now() / 1000);
      if (noww > exp) {
        clearLocalStorage();
      }
    }
  }
  return {
    headers: {
      ...headers,
      'GraphQL-Preflight': 1,
      authorization: token ? `Bearer ${token}` : '',
      HouseDomain: SITE_HOUSE_DOMAINS[process.env.REACT_APP_SITE] || '',
    },
  };
});

const link = ApolloLink.from([errorLink, authLink.concat(splitLink)]);

export const client = new ApolloClient({
  link,
  cache,
  connectToDevTools:
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_DEBUG === 'enabled',
});
