import { ReactComponent as RankFirst } from 'assets/icons/rank-first.svg';
import { ReactComponent as RankSecond } from 'assets/icons/rank-second.svg';
import { ReactComponent as RankThird } from 'assets/icons/rank-third.svg';
import StickyHeaderTable from 'common/Table/StickyHeaderTable';
import PlayerBox from 'components/PlayerBox';
import { ThreeDiceEventHistoriesQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { memo } from 'react';

import {
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

interface IMiniDiceEventDetailTableProps {
  selectedEvent: ArrayElement<
    ThreeDiceEventHistoriesQuery['threeDiceCurrentGame']['jackpotWins']
  >;
}

const MiniDiceEventDetailTable = ({
  selectedEvent,
}: IMiniDiceEventDetailTableProps) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const getRankEl = (rank: number) => {
    if (rank === 1) {
      return <RankFirst />;
    }
    if (rank === 2) {
      return <RankSecond />;
    }
    if (rank === 3) return <RankThird />;
    return rank;
  };

  return (
    <StickyHeaderTable isMaxHeightByParent>
      <TableHead>
        <TableRow>
          <TableCell className="top-rank" align="left">
            {translate('place')}
          </TableCell>
          <TableCell className="streak" align="center">
            {translate('streak')}
          </TableCell>
          <TableCell className="wagered" align="center">
            {translate('wagered')}
          </TableCell>
          <TableCell className="prize" align="center">
            {translate('prize')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedEvent?.jackpotWinList?.map((row, index) => (
          <TableRow key={row.user?.id}>
            <TableCell className="top-rank" align="left">
              <Stack
                {...(index + 1 > 3 && {
                  ml: index + 1 >= 10 ? 0.25 : 1,
                })}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {getRankEl(index + 1)}
                <PlayerBox
                  ml={index + 1 <= 3 ? 0.5 : 1}
                  user={row.user as any}
                />
              </Stack>
            </TableCell>
            <TableCell className="streak" align="center">
              {row.count}
            </TableCell>
            <TableCell className="wagered" align="center">
              {`$${getCurrencyAmount(row.wager, null, {
                onlyUsd: true,
              })}`}
            </TableCell>
            <TableCell className="prize" align="center">
              {`$${getCurrencyAmount(row.winValue, null, {
                onlyUsd: true,
              })}`}
            </TableCell>
          </TableRow>
        ))}
        {/* // Hide my rank, update when BE have api
        <TableRow className="sticky-row sticky-row-bottom my-rank">
          <TableCell className="top-rank" align="left">
            <Stack
              {...(myRank.rank > 3 && {
                ml: 1,
              })}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {getRankEl(myRank.rank)}
              <PlayerBox ml={myRank.rank <= 3 ? 0.5 : 1.25} user={null} />
            </Stack>
          </TableCell>
          <TableCell className="streak" align="center">
            {12}
          </TableCell>
          <TableCell className="wagered" align="center">
            {getCurrencyAmount(myRank.amount, myRank.currency, {
              onlyUsd: true,
            })}
          </TableCell>
          <TableCell className="prize" align="center">
            {getCurrencyAmount(myRank.amount, myRank.currency, {
              onlyUsd: true,
            })}
          </TableCell>
        </TableRow> */}
      </TableBody>
    </StickyHeaderTable>
  );
};

export default memo(MiniDiceEventDetailTable);
