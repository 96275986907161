import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { ReactComponent as HotFlag } from 'assets/icons/hot-flag.svg';
import { USD } from 'assets/images';
import ImgNavigtionBonus from 'assets/images/navigation-bonus.png';
import giftCodeVfx from 'assets/vfxs/gift-code.json';
import ChangeLanguageDialog from 'components/ChangeLanguageDialog';
import useResponsive from 'hooks/useResponsive';
import { LanguageFiat } from 'layouts/dashboard/header/styles';
import { useLocales } from 'locales';
import Lottie from 'lottie-react';
import { Logo } from 'overrides/images/header';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { GiftCodeDialog } from '../GiftCodeDialog';
import { NavSectionProps } from '../types';
import NavigationItem from './NavItem';
import { GiftCode, NavigationWrapper } from './styles';

/* -------------------------------------------------------------------------- */

interface Props extends NavSectionProps {
  isNavOpen?: boolean;
  setOpenNav: Dispatch<SetStateAction<boolean>>;
}

const NavSectionVertical = ({ data, setOpenNav }: Props) => {
  const [isGiftCodeDialogOpen, setIsGiftCodeDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { onChangeLang, currentLang, translate } = useLocales();
  const [selectedLanguage, setSelectedLanguage] = useState(currentLang);

  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'sm');

  const contentQuickAction = [
    {
      index: 1,
      title: '',
      image: ImgNavigtionBonus,
      action: () => {
        setIsGiftCodeDialogOpen(true);
      },
      block: false,
    },
  ];

  const handleCloseNav = () => {
    setOpenNav(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (language) => {
    setSelectedLanguage(language);
    onChangeLang(language.value);
    handleClose();
  };

  const QuickAction = () =>
    contentQuickAction.map((value) => (
      <GiftCode key={value.index} onClick={() => value.action()}>
        <Lottie className="lottie-gift-code" animationData={giftCodeVfx} />
        <Typography component="span">{translate('gift_code')}</Typography>
        <HotFlag style={{ marginLeft: 'auto' }} />
      </GiftCode>
    ));

  return (
    <NavigationWrapper className="nav-left">
      <Box>
        {isMobile && (
          <Link className="logo" to="/" onClick={() => navigate('/')}>
            <Logo />

            <CloseCircle
              height="56px"
              width="56px"
              style={{ margin: '-1rem', padding: '1rem' }}
              onClick={() => setOpenNav(false)}
            />
          </Link>
        )}

        <Box className="gift-code">{QuickAction()}</Box>

        <Box className="nav__box">
          {data.map((navItem) => (
            <NavigationItem
              handleCloseNav={handleCloseNav}
              navItem={navItem}
              key={navItem.key || navItem.type}
            />
          ))}
        </Box>

        {isMobile && (
          <LanguageFiat className="language-mobile">
            <Box className="language_box" onClick={handleClick}>
              <img src={selectedLanguage.icon} alt={selectedLanguage.label} />

              <Typography variant="subtitle2" fontSize="14px">
                {selectedLanguage.fullLabel}
              </Typography>

              {/* <KeyboardArrowDownRoundedIcon className="arrow-icon" /> */}
            </Box>

            <ChangeLanguageDialog
              anchorEl={anchorEl}
              onClose={handleClose}
              onSelect={handleSelect}
            />

            <Box className="fiat_box">
              <img src={USD} alt="USD" />
              <Typography component="span" variant="subtitle1">
                USD
              </Typography>
            </Box>
          </LanguageFiat>
        )}
      </Box>

      {isGiftCodeDialogOpen && (
        <GiftCodeDialog onClose={() => setIsGiftCodeDialogOpen(false)} />
      )}
    </NavigationWrapper>
  );
};

export default React.memo(NavSectionVertical);
