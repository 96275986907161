import { useLocales } from 'locales';
import { useCallback, useMemo } from 'react';

import { BoxProps } from '@mui/material';

import { FirstStep, LastStep, MiddleStep, Wrapper } from './styles';

export interface IStepperProps extends BoxProps {
  steps: { label: string; value: number }[];
  currentStep: number;
  setStep?: (step: number) => void;
}

const Stepper = ({ steps, currentStep, setStep, ...rest }: IStepperProps) => {
  const { translate } = useLocales();
  const stepClassname = useCallback(
    (position: number) => {
      if (currentStep === position) return 'active-step';
      if (position < currentStep) return 'visited-step';
      if (currentStep < position) return 'inactive-step';
    },
    [currentStep, steps],
  );

  const middleSteps = useMemo(() => {
    const res = [...steps];

    res.shift();

    res.pop();

    return res;
  }, [steps]);

  return (
    <Wrapper {...rest}>
      <FirstStep
        className={`${stepClassname(0)} stepper-item`}
        onClick={() => setStep && setStep(0)}
      >
        {steps[0].label}
      </FirstStep>
      {middleSteps.length &&
        middleSteps.map((step, index) => (
          <MiddleStep
            className={`${stepClassname(index + 1)} stepper-item`}
            key={step.value}
            onClick={() => setStep && setStep(index + 1)}
          >
            {translate(step.label)}
          </MiddleStep>
        ))}
      <LastStep
        className={`${stepClassname(steps.length - 1)} stepper-item`}
        onClick={() => setStep && setStep(steps.length - 1)}
      >
        {steps[steps.length - 1].label}
      </LastStep>
    </Wrapper>
  );
};

export default Stepper;
