import isPropValid from '@emotion/is-prop-valid';

import { styled, Tabs } from '@mui/material/';
import Box, { BoxProps } from '@mui/material/Box';

interface TabItemWrapperProps extends BoxProps {
  $isActive: boolean;
  $isDisabled?: boolean;
}

export const TabItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<TabItemWrapperProps>(({ $isActive, $isDisabled, theme }) => ({
  fontWeight: $isActive && 700,
  lineHeight: 'normal',
  borderRadius: theme.shape.borderRadius,
  transition: 'all ease 0.3s',
  cursor: $isDisabled ? 'not-allowed' : 'pointer',
  padding: theme.spacing(1.5, 5),

  [theme.breakpoints.down('lg')]: {
    fontSize: 18,
    padding: theme.spacing(1.25, 4),
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
    padding: theme.spacing(1.25, 2),
  },
  [theme.breakpoints.down('sm')]: {
    fontWeight: $isActive && 600,
    padding: theme.spacing(1, 1.5),
  },
  [theme.breakpoints.down(450)]: {
    padding: theme.spacing(1),
  },

  ...($isActive && {
    color: '#fff',
    background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
    '& svg path, circle': {
      fill: '#fff',
    },
  }),

  ...(!$isActive && {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.secondary,
  }),
}));

export const TabList = styled(Tabs)<any>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: 'fit-content',
  backgroundColor: theme.palette.background.main,
  borderRadius: theme.shape.borderRadius,
  minHeight: 'unset',
  '.MuiTabs-flexContainer': {
    height: '100%',
    gap: theme.spacing(1),
  },
  '.MuiTabScrollButton-root': {
    width: 32,
    '&.Mui-disabled': {
      display: 'none',
      transition: 'all ease .3s',
    },
  },
  '.MuiTabs-indicator': {
    display: 'none',
  },
}));
