import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 60,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  '.title': {
    color: theme.palette.text.contrast,
  },
  '.close-btn': {
    padding: 0,
  },
}));

export const StatsBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: `'amount multiplier stat'`,
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: 'auto auto',
  rowGap: '12px',
  columnGap: '12px',
  marginTop: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    gridTemplateAreas: `
  'amount multiplier'
  'stat stat'
  `,
    gridTemplateColumns: '1fr 1fr',
    '.item_wrap': {
      maxWidth: '100% !important',
    },
  },

  [theme.breakpoints.down(450)]: {
    marginTop: theme.spacing(2),
  },
}));

export const Body = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '&&& p': {
      fontSize: 14,
    },
  },
  height: 'calc(100% - 60px)',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    display: 'none',
  },

  '.copy_btn': {
    marginLeft: theme.spacing(1),
    padding: 'unset',
    svg: {
      fontSize: 20,
    },
    path: {
      fill: theme.palette.text.primary,
    },
    'svg:hover': {
      path: {
        fill: theme.palette.text.contrast,
      },
    },
  },

  img: {
    '&.avatar': {
      marginRight: theme.spacing(0.5),
      borderRadius: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      height: 14,
      width: 14,
    },
  },

  '.item_wrap': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(1.5),

    div: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:first-of-type': {
        gap: theme.spacing(0.5),
        svg: {
          width: 20,
          height: 20,
        },
        span: {
          fontSize: 14,
          fontWeight: 400,
          color: theme.palette.text.primary,
          lineHeight: 'normal',
        },
      },

      '&:last-child': {
        gap: theme.spacing(0.5),
        span: {
          fontSize: 18,
          fontWeight: 700,
          color: theme.palette.text.contrast,
          lineHeight: 'normal',
        },
        img: {
          width: 16,
          height: 16,
        },
      },
    },

    'p.increase': {
      color: theme.palette.primary.main,
    },

    'p.decrease': {
      color: theme.palette.error.main,
    },
  },

  '.bet-detail-game-name': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(0.5),

    h6: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.text.contrast,
      lineHeight: 'normal',
      [theme.breakpoints.down('md')]: {
        fontSize: 22,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },

    span: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.primary,
      lineHeight: 'normal',
      margin: 0,
    },
  },
}));

export const GameInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 1.5),
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.main,

  '& > .MuiBox-root': {
    display: 'flex',
  },

  'img.game_preview': {
    width: 70,
    height: 70,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50,
    },
  },

  '.play_btn': {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.background.main,
    lineHeight: 'normal',
    background:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    padding: theme.spacing(1, 3),
  },
}));
