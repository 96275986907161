/* eslint-disable no-nested-ternary */
import BetDetail from 'components/_Games/BetDetail';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { Currency, JackpotWinnersQueryResult } from 'graph';
import useResponsive from 'hooks/useResponsive';
import React, { memo, useState } from 'react';

import { TableBody } from '@mui/material';

import BetTable from '../BetTable';
import { HeaderJackpotWrapper } from './HeaderJackpot';
import { RowJackpotTableWrapper } from './RowsJackpot';

/* -------------------------------------------------------------------------- */

export type JackpotWinnerRow = ArrayElement<
  JackpotWinnersQueryResult['data']['jackpotWinBets']['data']
>;

interface Props {
  rows: JackpotWinnerRow[];
  isBetsByGame?: boolean;
  isShowInMiniGameDialog?: boolean;
}

interface TableRowProps {
  row: JackpotWinnerRow;
  betTimeAt: string;
}
const JackpotWinnerTable = ({
  rows,
  isBetsByGame,
  isShowInMiniGameDialog,
}: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const defaultShowedRows = isShowInMiniGameDialog && isMobile ? 20 : 10;
  const [showedRows, setShowedRows] = useState(defaultShowedRows);
  const hasMore =
    rows.length > defaultShowedRows && showedRows <= defaultShowedRows;
  const [selectedBet, setSelectedBet] = useState<JackpotWinnerRow>(null);

  React.useEffect(() => {
    setShowedRows(defaultShowedRows);
  }, [isMobile]);

  const TableRowComponent = memo(({ row, betTimeAt }: TableRowProps) => {
    const { ...rowData } = row;

    return (
      <RowJackpotTableWrapper
        key={rowData.betId}
        isBetsByGame={isBetsByGame}
        betTimeAt={betTimeAt}
        row={{
          id: row.betId,
          currency: row.currency as Currency,
          gameName: row.game.name,
          user: row.user,
          jackpotCount: row.jackpotCount,
          jackpotTotalAmount: row.jackpotTotalAmount,
          handleClick: () => setSelectedBet(row),
        }}
      />
    );
  });

  return (
    <>
      <BetTable
        rows={rows}
        hasMore={hasMore}
        setShowedRows={setShowedRows}
        defaultShowedRows={defaultShowedRows}
      >
        <>
          <HeaderJackpotWrapper />

          <TableBody>
            {rows.slice(0, showedRows).map((row) => {
              const betTimeAt =
                Math.sqrt(differenceInDays(new Date(row.at), new Date())) < 3
                  ? formatDistanceToNowStrict(new Date(row.at), {
                      addSuffix: true,
                    })
                  : format(new Date(row.at), 'dd/MM/yyyy, h:mm');

              return (
                <TableRowComponent
                  key={row.betId}
                  row={row}
                  betTimeAt={betTimeAt}
                />
              );
            })}
          </TableBody>
        </>
      </BetTable>
      {selectedBet !== null && (
        <BetDetail
          open={selectedBet !== null}
          onClose={() => {
            setSelectedBet(null);
          }}
          betId={selectedBet.betId}
        />
      )}
    </>
  );
};

export { JackpotWinnerTable };
