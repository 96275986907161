/* eslint-disable react/no-unescaped-entities */
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import {
  bg_unlock_codg1,
  bg_unlock_codg2,
  bg_unlock_codg3,
  bg_unlock_codg4,
} from 'assets/images/codg/unlock';
import { useLocales } from 'locales';

import { Box, Button, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { UnlockCodgWrapper } from './style';

// ------------------------------------------------------------------------

interface PropsType {
  isOpen: boolean;
  handleClose: VoidFunction;
  handleDeposit: VoidFunction;
}

const UnlockCodg = ({ isOpen, handleClose, handleDeposit }: PropsType) => {
  const { translate } = useLocales();

  const unlock_codg_data = [
    {
      id: 1,
      subTitle: 'first_deposit',
      title: '_300_bonus',
      label1: 'get_up_to',
      money1: '$5,000',
      label2: 'min_deposit',
      money2: '$10',
      button: 'deposit_now',
      background: bg_unlock_codg1,
    },
    {
      id: 2,
      subTitle: 'second_deposit',
      title: '_200_bonus',
      label1: 'get_up_to',
      money1: '$10,000',
      label2: 'min_deposit',
      money2: '$50',
      button: 'deposit_now',
      background: bg_unlock_codg2,
    },
    {
      id: 3,
      subTitle: 'third_deposit',
      title: '_150_bonus',
      label1: 'get_up_to',
      money1: '$20,000',
      label2: 'min_deposit',
      money2: '$100',
      button: 'deposit_now',
      background: bg_unlock_codg3,
    },
    {
      id: 4,
      subTitle: 'fourth_deposit',
      title: '_100_bonus',
      label1: 'get_up_to',
      money1: '$50,000',
      label2: 'min_deposit',
      money2: '$200',
      button: 'deposit_now',
      background: bg_unlock_codg4,
    },
  ];

  return (
    <Dialog
      className="unlock-codg"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <UnlockCodgWrapper>
        <Box className="unlock-header">
          <Typography variant="h4">{translate('deposit_promotion')}</Typography>
          <IconButton onClick={() => handleClose()} sx={{ padding: '0' }}>
            <CloseCircle />
          </IconButton>
        </Box>

        <Typography className="unlock-description">
          {translate('unlock_codg_description')}
        </Typography>

        <Typography variant="h6" className="unlock-label">
          {translate('our_excited_bonus')}
        </Typography>

        <Box className="unlock-list">
          {unlock_codg_data.map((item) => (
            <Box
              className="unlock-item"
              key={item.id}
              sx={{
                background: `url(${item.background})`,
              }}
            >
              <Box className="unlock-item-header">
                <Typography variant="h6">{translate(item.subTitle)}</Typography>
                <Typography variant="h5">{translate(item.title)}</Typography>
              </Box>

              <Box className="unlock-item-money">
                <Box>
                  <Typography component="span">
                    {translate(item.label1)}
                  </Typography>
                  <Typography component="span">
                    {translate(item.money1)}
                  </Typography>
                </Box>
                <Box>
                  <Typography component="span">
                    {translate(item.label2)}
                  </Typography>
                  <Typography component="span">
                    {translate(item.money2)}
                  </Typography>
                </Box>
              </Box>

              <Button
                onClick={() => {
                  handleDeposit();
                }}
              >
                {translate(item.button)}
              </Button>
            </Box>
          ))}
        </Box>
      </UnlockCodgWrapper>
    </Dialog>
  );
};

export default UnlockCodg;
