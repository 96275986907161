import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import coinImages from 'assets/images/coins';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { useCreateInternalTransferMutation, useUserWalletsQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { currenciesVar } from 'store';
import * as Yup from 'yup';

import { Box, Button, Typography } from '@mui/material';

import { SelectCurrencyWrapper } from '../SelectCurrency/styles';
import { ConfirmedButtonWithdrawPi, WithdrawPiWrapper } from './styles';

// --------------------------------------------------

interface IWithdrawPiProps {
  isAccountNotVerified: boolean;
  onAccountNotVerified: VoidFunction;
}

export function WithdrawPi({
  isAccountNotVerified,
  onAccountNotVerified,
}: IWithdrawPiProps) {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'sm');
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const { currencies } = useReactiveVar(currenciesVar);
  const { data: userWalletsData } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });

  const currencyPi = currencies?.filter((e) => e.shortName === 'pi')[0];

  const schemaInvest = Yup.object().shape({
    addressPi: Yup.string().required(translate('pi_address_is_required')),
    piAmount: Yup.number()
      .typeError(translate('pi_Amount_must_specify_a_number'))
      .min(10, translate('pi_amount_must_greater_than_10_Pi'))
      .max(10000, translate('pi_amount_must_less_than_10000_Pi'))
      .required(translate('pi_amount_is_required')),
  });

  const form = useForm({
    defaultValues: {
      addressPi: '',
      piAmount: 0,
    },
    resolver: yupResolver(schemaInvest),
    mode: 'onSubmit',
  });
  const [internalTranfer] = useCreateInternalTransferMutation();
  const onSubmitData = (data) => {
    if (isAccountNotVerified) {
      onAccountNotVerified();
      return;
    }
    internalTranfer({
      variables: {
        receiverId: '141403413503082496',
        currencyId: currencyPi?.id,
        amount: +data.piAmount,
        message: data.addressPi,
      },
    }).then((value) => {
      if (value.data.createInternalTransfer.content !== null) {
        enqueueSnackbar(
          translate('withdraw_successfully_We_will_contact_you_later'),
          {
            variant: 'success',
          },
        );

        window.Intercom(
          'showNewMessage',
          `${translate('hey_there')},\n ${translate(
            'please_process_my_Pi_coin_withdrawal_as_follows',
          )}:,\n ${translate('address')}: ${data.addressPi}\n ${translate(
            'amount',
          )}: ${+data.piAmount + 0.5} Pi\n ${translate(
            'fees',
          )}: ${0.5} Pi\n ${translate('receive_amount')}: ${+data.piAmount} Pi`,
        );
      } else {
        enqueueSnackbar(value?.data?.createInternalTransfer?.error?.message, {
          variant: 'error',
        });
      }
    });

    form.reset();
  };

  const balance: number =
    userWalletsData?.me?.userWallets.find(
      (userWallet) => userWallet.currencyId === currencyPi?.id,
    )?.amount || 0;

  return (
    <WithdrawPiWrapper>
      <FormProvider methods={form} onSubmit={form.handleSubmit(onSubmitData)}>
        <Typography className="title">
          {translate('your_Pi_address')}
        </Typography>
        <RHFTextField
          // multiline
          fullWidth
          name="addressPi"
          //   error
          placeholder={translate('input_your_Pi_address')}
        />
        {form.formState.errors && (
          <Typography mt={2} color="red">
            {form.formState.errors?.addressPi?.message}
          </Typography>
        )}
        <Typography className="title">Withdraw currency</Typography>
        <SelectCurrencyWrapper>
          <Box display="flex" flexDirection="row" gap={1}>
            <img src={coinImages[currencyPi?.shortName]} alt="bnb coin" />
            <Typography textTransform="uppercase">
              {currencyPi?.shortName}
            </Typography>
          </Box>
          <Box className="balance_box">
            <Typography className="balance_amount" fontSize="14px">
              <>
                {translate('balance')}
                :&nbsp;
                <span className="balance-value">
                  {getCurrencyAmount(balance, currencyPi, {
                    onlyCurrency: true,
                  })}
                </span>
              </>
            </Typography>
            <Typography fontSize="12px">
              ~{' '}
              {getCurrencyAmount(Number(balance), currencyPi, {
                onlyUsd: true,
              })}
            </Typography>
          </Box>
        </SelectCurrencyWrapper>
        <Box className="input_number_wrapper">
          <RHFTextField
            fullWidth
            name="piAmount"
            placeholder={translate('input_number_of_Pi')}
            InputProps={{
              endAdornment: (
                <Box display="flex" flexDirection="row" gap={0.5} px={1}>
                  {!isMobile && (
                    <>
                      {/* <IconButton className="close-btn" sx={{ padding: '0' }}>
                        <CloseCircle width="24px" height="24px" />
                      </IconButton> */}

                      <Button
                        className="button_option_pi"
                        onClick={() =>
                          balance > 0 && form.setValue('piAmount', 10)
                        }
                      >
                        <Typography className="content">
                          {translate('min')}
                        </Typography>
                      </Button>

                      <Button
                        className="button_option_pi"
                        onClick={() => form.setValue('piAmount', balance / 4)}
                      >
                        <Typography className="content">25%</Typography>
                      </Button>

                      <Button
                        className="button_option_pi"
                        onClick={() => form.setValue('piAmount', balance / 2)}
                      >
                        <Typography className="content">50%</Typography>
                      </Button>

                      {/* <Button
                        className="button_option_pi"
                        onClick={() => form.setValue('piAmount', balance)}
                      >
                        <Typography className="content">Max</Typography>
                      </Button> */}
                    </>
                  )}
                </Box>
              ),
            }}
          />
        </Box>
        {form.formState.errors && (
          <Typography mt={2} color="red">
            {form.formState.errors?.piAmount?.message}
          </Typography>
        )}
        <Box display="flex" flexDirection="column" gap={1} my={1}>
          {/* <Typography>The minimum withdrawal amount is 1 USDT.</Typography> */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography>{translate('fee')}</Typography>
            <Typography>0.5 Pi</Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography>{translate('receive_amount')}</Typography>
            <Typography>
              {+form.watch('piAmount') > 0
                ? getCurrencyAmount(+form.watch('piAmount'), currencyPi, {
                    onlyCurrency: true,
                  })
                : 0}
              Pi
            </Typography>
          </Box>
        </Box>

        <ConfirmedButtonWithdrawPi
          variant="contained"
          color="secondary"
          type="submit"
        >
          <Typography variant="button">{translate('withdraw')}</Typography>
        </ConfirmedButtonWithdrawPi>
      </FormProvider>
    </WithdrawPiWrapper>
  );
}
