import { SvgIcon } from '@mui/material';

export function IconLiveStats(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      {...props}
    >
      <path
        d="M19.1692 20.5809H4.50101C2.71494 20.5809 1.26538 19.1313 1.26538 17.3452V2.67703C1.26538 2.32327 1.55874 2.02991 1.91251 2.02991C2.26627 2.02991 2.55963 2.32327 2.55963 2.67703V17.3452C2.55963 18.4151 3.4311 19.2866 4.50101 19.2866H19.1692C19.523 19.2866 19.8163 19.58 19.8163 19.9337C19.8163 20.2875 19.523 20.5809 19.1692 20.5809Z"
        fill="white"
      />
      <path
        d="M4.50098 16.2667C4.35429 16.2667 4.19898 16.2149 4.07819 16.1114C3.81071 15.8784 3.7762 15.4729 4.00916 15.1968L7.96957 10.572C8.40099 10.0715 9.02223 9.76954 9.67799 9.74365C10.3337 9.7264 10.9809 9.96799 11.4468 10.4339L12.2665 11.2536C12.4822 11.4693 12.7583 11.5729 13.0689 11.5729C13.3709 11.5642 13.647 11.4262 13.8455 11.1932L17.8059 6.56842C18.0389 6.30094 18.4444 6.26643 18.7205 6.49939C18.988 6.73236 19.0225 7.13789 18.7895 7.414L14.8291 12.0388C14.3977 12.5392 13.7765 12.8412 13.1207 12.8671C12.4563 12.8844 11.8178 12.6428 11.3519 12.1768L10.5408 11.3572C10.3251 11.1414 10.0404 11.0293 9.73838 11.0379C9.43639 11.0465 9.16029 11.1846 8.96183 11.4176L5.00142 16.0423C4.86337 16.189 4.68217 16.2667 4.50098 16.2667Z"
        fill="white"
      />
    </SvgIcon>
  );
}
