import { SvgIcon } from '@mui/material';

export function LogoCoco(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="32"
      viewBox="0 0 124 32"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_49_2234)">
        <path
          d="M15.765 31.9651C7.07383 31.9651 0 24.8913 0 16.1957C0 7.50015 7.07383 0.430664 15.765 0.430664C19.9737 0.430664 23.9345 2.06978 26.9127 5.048C28.4127 6.54799 28.4127 8.97839 26.9127 10.4784C25.4128 11.9784 22.9867 11.9784 21.4824 10.4784C19.952 8.94796 17.9215 8.10884 15.7607 8.10884C11.2999 8.10884 7.66948 11.7392 7.66948 16.2001C7.66948 20.6609 11.2999 24.2913 15.7607 24.2913C18.1041 24.2913 20.3302 23.2739 21.8693 21.5043C23.2563 19.9044 25.6823 19.7348 27.2823 21.1217C28.8823 22.513 29.0519 24.9347 27.6649 26.5347C24.6693 29.9869 20.3302 31.9651 15.7607 31.9651H15.765Z"
          fill="#8527FF"
        />
        <path
          d="M78.2602 31.9651C69.5689 31.9651 62.4951 24.8913 62.4951 16.2001C62.4951 7.50884 69.5646 0.430664 78.2602 0.430664C82.4732 0.430664 86.4296 2.06978 89.4079 5.048C90.9078 6.54799 90.9078 8.97839 89.4079 10.474C87.9122 11.974 85.4775 11.974 83.9818 10.474C82.4514 8.94796 80.421 8.10449 78.2602 8.10449C73.7993 8.10449 70.1689 11.7349 70.1689 16.1957C70.1689 20.6565 73.7993 24.2869 78.2602 24.2869C80.6036 24.2869 82.8297 23.2695 84.3688 21.5C85.7601 19.9 88.1818 19.7304 89.7818 21.1174C91.3818 22.5087 91.5513 24.9304 90.1644 26.5304C87.1688 29.9825 82.8297 31.9607 78.2602 31.9607V31.9651Z"
          fill="#8527FF"
        />
        <path
          d="M107.247 31.8871C98.5169 31.8871 91.417 24.7828 91.417 16.0568C91.417 7.33083 98.5169 0.226562 107.247 0.226562C111.478 0.226562 115.452 1.87437 118.443 4.86564C121.434 7.85691 123.082 11.8308 123.082 16.0612C123.082 19.8785 121.704 23.5654 119.204 26.4393C116.195 29.9045 111.834 31.8914 107.247 31.8914V31.8871ZM107.247 7.90039C102.752 7.90039 99.0908 11.5569 99.0908 16.0568C99.0908 20.5568 102.747 24.2132 107.247 24.2132C109.612 24.2132 111.856 23.1872 113.408 21.4046C114.695 19.922 115.404 18.0264 115.404 16.0612C115.404 13.8829 114.556 11.8351 113.017 10.296C111.478 8.7569 109.43 7.90908 107.252 7.90908L107.247 7.90039Z"
          fill="#8527FF"
        />
        <path
          d="M52.1815 15.8086C52.1815 19.6216 49.0989 22.7129 45.2903 22.726C45.2816 22.726 45.2686 22.726 45.2599 22.726C41.4382 22.726 38.3382 19.626 38.3382 15.8043C38.2947 12.8782 39.9599 10.7261 42.499 8.53481C42.6164 8.43481 42.7208 8.33482 42.8295 8.23917L42.8208 8.23047C42.8208 8.23047 44.2425 7.00874 44.8816 5.39572C44.8816 5.39137 44.886 5.38268 44.8903 5.37833C45.1859 4.62181 45.3033 3.78269 45.0251 2.93922C44.8773 2.4914 44.6164 2.04793 44.2077 1.61315C42.1512 -0.460738 38.886 0.830552 37.7252 1.39142C37.4556 1.52185 37.2991 1.61315 37.2991 1.61315V1.60446C32.2296 4.31747 28.7861 9.65654 28.7861 15.8043C28.7861 20.6608 30.9339 25.0129 34.3339 27.965C39.1425 28.6824 42.6816 28.4433 45.2859 27.6433C54.1119 24.9346 52.1772 15.8043 52.1772 15.8043L52.1815 15.8086Z"
          fill="#EFC516"
        />
        <path
          d="M52.9044 1.8437C52.7348 1.73936 52.5349 1.62631 52.3044 1.51327C52.2609 1.49153 52.2218 1.46545 52.1783 1.44371C52.0262 1.36979 51.8305 1.28284 51.6131 1.19154C51.6131 1.19154 51.6131 1.19154 51.6088 1.19154H51.6001C51.4523 1.13067 51.2914 1.0698 51.1218 1.01328C45.5828 -1.0867 39.4872 0.748063 39.4872 0.748063C40.948 0.356763 42.8437 0.248068 44.2132 1.62631C44.6219 2.05674 44.8828 2.50456 45.0306 2.95239C45.1871 3.4176 45.2132 3.87846 45.161 4.33063C45.1871 4.51759 45.2262 4.70454 45.2828 4.8828C45.3002 4.95236 45.3306 5.06541 45.3915 5.22627C45.3915 5.23062 45.3958 5.23932 45.4001 5.24366C45.6393 5.85235 46.2871 7.04799 48.0262 8.54363C50.5697 10.7349 52.2305 12.8914 52.187 15.8131C52.187 15.8131 54.1131 24.9304 45.2958 27.6434C42.6915 28.4434 39.1567 28.6912 34.3438 27.9738C37.1698 30.4303 40.8611 31.9173 44.9002 31.9173C53.7957 31.9173 61.0043 24.7087 61.0043 15.8131C61.0043 9.83492 57.7435 4.62628 52.9088 1.84805L52.9044 1.8437Z"
          fill="#8527FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_49_2234">
          <rect width="123.077" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
