import { useReactiveVar } from '@apollo/client';
import {
  IconCloseMiniGame,
  IconMaximizeMiniGame,
  IconTrendMiniGame,
} from 'assets/icons';
import { useAuthContext } from 'auth/useAuthContext';
import PredictorDialog from 'components/_Events/_CrashEvent/PredictorDialog';
import {
  formatAmount,
  formatUSD,
} from 'components/_Games/gameControl/betControl';
import { SOCKET_KEY, SOCKET_SECRET } from 'constants/config';
import { ACCESS_TOKEN } from 'constants/localStorage';
import { addDays, differenceInDays } from 'date-fns';
import { motion, useDragControls } from 'framer-motion';
import {
  useCrashPredictorQuery,
  useCrashRoundResultQuery,
  useGameQuery,
  useUserInfoQuery,
  useUserWalletsQuery,
} from 'graph';
import useResponsive from 'hooks/useResponsive';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import numeral from 'numeral';
import { BET_BUTTON_STATE } from 'pages/Games/types';
import {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { storageCurrencyVar, viewInFiatVar } from 'store';
import { toN } from 'utils/number-help';

import { Stack } from '@mui/material';

import { setMiniGameOnTop } from 'components/_Games/game-control-v2/utils';
import {
  BetHost,
  StartBetRoundHost,
} from 'overrides/components/text-host/TextHost';
import StatisticTable from '../StatisticTable';
import { CRASH_BET_RESULT } from '../_GameCrash/CrashServerType';
import { useCrashSever } from '../_GameCrash/useCrashSever';
import BetAmountInput from './BetAmountInput';
import CashoutAtInput from './CashoutAtInput';
import CrashLine from './CrashLineMini';
import PlayButton from './PlayButton';
import RoundHistories from './RoundHistories';
import {
  Footer,
  GroupHeaderButtons,
  Header,
  HeaderButton,
  Wrapper,
} from './styles';

export const crashRoundsQueryVariables = {
  page: 1,
  pageSize: 25,
};

interface PlayButtonProps {
  open: (show: boolean) => void;
}

const endPoint = `wss://${process.env.REACT_APP_CRASH_SERVER_DOMAIN}`;

function GameMiniCrash({ open }: PlayButtonProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const timerIntervalId = useRef(null);
  const minicrashGameRef = useRef<HTMLDivElement>(null);
  const { translate } = useLocales();
  const WD = useWindowDimensions();
  const controls = useDragControls();
  const isDesktop = useResponsive('up', 'sm');
  const { isAuthenticated, openLoginModal } = useAuthContext();
  // const { storageCurrency, viewInFiat, addMyBet } = useGlobalContext();
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const [isShowStatisticTable, setIsStatisticTable] = useState<boolean>(false);

  const [betButtonState, setBetButtonState] = useState(BET_BUTTON_STATE.BET);
  const [betButtonCap, setBetButtonCap] = useState('BET');
  const [betButtonSubCap, setBetButtonSubCap] = useState('BET');
  const [isBetDone, setIsBetDone] = useState(false);

  const [cashoutAt, setCashoutAt] = useState('100');

  const [chartData, setChartData] = useState([{ x: 0, y: 1 }]);
  const [countTime, setCountTime] = useState(0);
  const [payOutHistories, setPayOutHistories] = useState([]);
  const [isOpenPredictorDialog, setIsOpenPredictorDialog] = useState(false);
  const [selectedCrashEventDate, setSelectedCrashEventDate] = useState(
    new Date(),
  );

  const [betNextData, setBetNextData] = useState(null);
  const [meWinAmount, setMeWinAmount] = useState(0);
  const [meWinRate, setMeWinRatet] = useState(0);

  const { data: userInfo } = useUserInfoQuery({ fetchPolicy: 'cache-only' });
  const { data: userWallets } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });

  const { data: info } = useGameQuery({
    variables: {
      slug: 'crash',
      currencyId: storageCurrency?.id,
    },
    fetchPolicy: 'cache-first',
  });

  const { data: crashPredictorData, refetch: refetchCrashPredictorQuery } =
    useCrashPredictorQuery({
      variables: {
        date: selectedCrashEventDate,
        gameId: info?.game?.game?.id,
      },
    });

  const { data: dataRoundResult, refetch: f5RoundResult } =
    useCrashRoundResultQuery({
      variables: {
        gameId: info?.game?.game?.id || '0',
        ...crashRoundsQueryVariables,
      },
    });

  const [betAmount, setBetAmount] = useState('0');

  const getWalletBalance = () =>
    userWallets?.me?.userWallets.filter(
      (value) => value.currencyId === storageCurrency?.id,
    );

  useEffect(() => {
    if (
      info?.game?.gameFund?.betRooms[0]?.betRoomSetting?.defaultBetAmount &&
      betAmount === '0'
    )
      setBetAmount(
        viewInFiat?.status === true && storageCurrency?.equivalentUsdRate !== 0
          ? formatUSD(
              (info?.game?.gameFund?.betRooms[0]?.betRoomSetting
                ?.defaultBetAmount || 0) *
                (storageCurrency?.equivalentUsdRate || 1),
            )
          : formatAmount(
              info?.game?.gameFund?.betRooms[0]?.betRoomSetting
                ?.defaultBetAmount,
            ),
      );
  }, [info?.game?.gameFund?.betRooms[0]?.betRoomSetting]);

  const {
    bet,
    join,
    ping,
    process,
    crashAt,
    cashout,
    players,
    betError,
    roomState,
    connectStatus,
    playerCashOut,
    changeCurrency,
  } = useCrashSever(endPoint);

  const playerMeCashout = () =>
    playerCashOut?.find((r) => r.user_id === userInfo?.me?.user?.id);

  const playerMe = () =>
    players?.filter((r) => r.user_id === userInfo?.me?.user?.id)[0] || null;

  const highestCrashResult = useMemo(() => {
    if (crashPredictorData?.crashPredictor?.crashPredictorResults.length === 0)
      return null;

    return crashPredictorData?.crashPredictor?.crashPredictorResults[0];
  }, [crashPredictorData]);

  useEffect(() => {
    const difInDays = differenceInDays(
      new Date(),
      new Date(selectedCrashEventDate),
    );

    if (
      crashPredictorData !== undefined &&
      !crashPredictorData?.crashPredictor?.crashPredictorResults.length &&
      difInDays < 10
    ) {
      setSelectedCrashEventDate((prev) => addDays(prev, -1));
    }
  }, [crashPredictorData]);

  useLayoutEffect(() => {
    if (dataRoundResult) {
      const hs = dataRoundResult?.rounds?.data?.map((r) => ({
        payout: r.roundData?.result,
      }));
      setPayOutHistories(hs.reverse());
    }
  }, [dataRoundResult]);

  useLayoutEffect(() => {
    if (connectStatus === 'CONNECTED') {
      const access_token =
        typeof window !== 'undefined'
          ? localStorage.getItem(ACCESS_TOKEN)
          : null;
      const opt = {
        access_token,
        currency_id: storageCurrency?.id,
        slug: info?.game?.game?.slug,
        game_id: info?.game?.game?.id,
        bet_room_id: info?.game?.gameFund?.betRooms[0]?.id,
      };
      opt[SOCKET_KEY] = SOCKET_SECRET;
      join(opt);
    }
    if (connectStatus === 'JOINED') {
      changeCurrency({
        currency_id: storageCurrency?.id,
        bet_room_id: info?.game?.gameFund?.betRooms[0]?.id,
      });
    }
  }, [connectStatus, isAuthenticated, info?.game]);

  useEffect(() => {
    const thisPlayer = playerMeCashout();
    if (thisPlayer && thisPlayer.win_amount > 0) {
      setMeWinAmount(thisPlayer.win_amount);
      setMeWinRatet(thisPlayer.win_payout);
      setBetButtonState(BET_BUTTON_STATE.BET_NEXT);
      setBetButtonCap(translate(BetHost()));
      setBetButtonSubCap(translate('next_round2'));
      setIsBetDone(false);
      // setBetting(false);
      // addMyBet(
      //   +thisPlayer.bet_amount,
      //   thisPlayer.win_amount - +thisPlayer.bet_amount,
      // );
    }
  }, [playerMeCashout()]);

  const startTickCountDown = (interval: number, onEvent: () => void) => {
    if (!timerIntervalId.current)
      timerIntervalId.current = setInterval(onEvent, interval);
  };

  const stopTick = () => {
    clearInterval(timerIntervalId.current);
    timerIntervalId.current = null;
  };

  useEffect(() => {
    if (roomState?.status === 'STOPED') {
      if (betButtonState === BET_BUTTON_STATE.CANCEL) {
        setBetButtonState(BET_BUTTON_STATE.WAIT);
        setBetButtonCap(translate('loading'));
        setBetButtonSubCap('');
      } else {
        const thisPlayer = playerMe();
        if (thisPlayer && thisPlayer.status === 'LOSE') {
          // addMyBet(thisPlayer.bet_amount, -thisPlayer.bet_amount);
        }
        setIsBetDone(false);
        // setBetting(false);
        setBetButtonState(BET_BUTTON_STATE.BET_NEXT);
        setBetButtonCap(translate(BetHost()));
        setBetButtonSubCap(translate('next_round2'));
      }
      setTimeout(() => {
        // TODO - GEMON : work with cocoon update cache of crash game
        // refetchMe();
        f5RoundResult();
        refetchCrashPredictorQuery();
      }, 500);
    }

    if (roomState?.status === 'WAITING_START') {
      setBetButtonState(
        !isBetDone ? BET_BUTTON_STATE.BET : BET_BUTTON_STATE.WAIT,
      );

      setBetButtonCap(!isBetDone ? translate(BetHost()) : translate('loading'));
      setBetButtonSubCap('');

      if (betNextData) {
        bet(betNextData);
        setBetNextData(null);
      }

      setChartData([{ x: 0, y: 1 }]);
      setMeWinRatet(0);
      setMeWinAmount(0);
      const deltaT =
        (roomState?.end_time_count_down || 0) - (roomState?.server_time || 0);
      setCountTime(deltaT > 0 ? deltaT : 0);
      startTickCountDown(100, () => {
        setCountTime((c) => (c - 100 < 0 ? 0 : c - 100));
      });
    }

    if (roomState?.status === 'STARTED') {
      setBetButtonState(
        !isBetDone ? BET_BUTTON_STATE.BET_NEXT : BET_BUTTON_STATE.CASHOUT,
      );

      setBetButtonCap(!isBetDone ? translate(BetHost()) : translate('cashout'));
      setBetButtonSubCap(!isBetDone ? translate('next_round2') : '');

      stopTick();
      setCountTime(0);
    }
  }, [roomState?.status]);

  useEffect(() => {
    if (betError?.error_code > 2) {
      setIsBetDone(false);
      // setBetting(false);
      setBetNextData(null);

      if (roomState.status === 'WAITING_START') {
        setBetButtonState(BET_BUTTON_STATE.BET);
        setBetButtonCap(translate(BetHost()));
        setBetButtonSubCap('');
      } else {
        setBetButtonState(BET_BUTTON_STATE.BET_NEXT);
        setBetButtonCap(translate(BetHost()));
        setBetButtonSubCap(translate('next_round2'));
      }

      switch (betError.error_code) {
        case CRASH_BET_RESULT.UNDER_MIN_BET:
          enqueueSnackbar(translate('under_min_bet'), { variant: 'error' });
          break;
        case CRASH_BET_RESULT.INVALID_BET_PARAMS:
          enqueueSnackbar(translate('invalid_bet_params'), {
            variant: 'error',
          });
          break;
        case CRASH_BET_RESULT.GAME_NOT_AVAILABLE:
          enqueueSnackbar(translate('game_not_available_now'), {
            variant: 'error',
          });
          break;
        case CRASH_BET_RESULT.INSUFFICIENT_AMOUNT:
          enqueueSnackbar(translate('insufficient_amount'), {
            variant: 'error',
          });
          break;
        case CRASH_BET_RESULT.INVALID_BET_STATE:
          enqueueSnackbar(translate('invalid_bet_state'), { variant: 'error' });
          break;

        case CRASH_BET_RESULT.GAMEBANK_NOT_AVAILABLE:
          enqueueSnackbar(translate('gamebank_not_available'), {
            variant: 'error',
          });
          break;

        case CRASH_BET_RESULT.OVER_MAX_BET:
          enqueueSnackbar(translate('over_max_bet'), { variant: 'error' });
          break;
        default:
      }
    }
  }, [betError?.error_code]);

  useEffect(
    () => () => {
      stopTick();
    },
    [],
  );

  useEffect(() => {
    if (roomState?.status === 'STARTED')
      setChartData((e) => {
        const last = e.length > 0 ? e[e.length - 1] : null;
        if (last) {
          const newX = roomState?.x;
          const newY = roomState?.fx;
          return [...e, { x: newX, y: newY }];
        }
        return [{ x: 0, y: 1 }];
      });
  }, [roomState?.x]);

  useEffect(() => {
    if (roomState?.status === 'STARTED') {
      const cashoutNumber = numeral(
        Number(roomState?.fx?.toFixed(2)) * toN(betAmount),
      ).format('0.[000000]');
      if (betButtonState === BET_BUTTON_STATE.CASHOUT) {
        setBetButtonSubCap(cashoutNumber);
      }
    }
  }, [roomState?.fx]);

  const handleManualBet = async () => {
    try {
      if (!isAuthenticated) {
        openLoginModal();
        return;
      }

      if (isBetDone) {
        if (roomState?.status === 'STARTED') {
          if (betButtonState === BET_BUTTON_STATE.CASHOUT) {
            setIsBetDone(false);
            cashout();
            setBetButtonState(BET_BUTTON_STATE.BET_NEXT);
            setBetButtonCap(translate(BetHost()));
            setBetButtonSubCap(translate('next_round2'));
          }
          if (betButtonState === BET_BUTTON_STATE.CANCEL) {
            setIsBetDone(false);
            // setBetting(false);
            setBetButtonState(BET_BUTTON_STATE.BET);
            setBetButtonCap(translate(BetHost()));
            setBetButtonSubCap('');
            setBetNextData(null);
          }
        }
        if (roomState?.status === 'WAITING_START') {
          setBetButtonState(BET_BUTTON_STATE.WAIT);
          setBetButtonCap(translate('loading'));
          setBetButtonSubCap('');
        }
        if (roomState?.status === 'STOPED') {
          setBetButtonState(BET_BUTTON_STATE.WAIT);
          setBetButtonCap(translate('loading'));
          setBetButtonSubCap('');
        }
      } else {
        let amountP = toN(betAmount);

        if (
          viewInFiat.status === true &&
          storageCurrency?.equivalentUsdRate !== 0
        ) {
          amountP /= storageCurrency?.equivalentUsdRate || 1;
        }
        if (
          getWalletBalance().length === 0 ||
          getWalletBalance()[0]?.amount < amountP
        ) {
          enqueueSnackbar(translate('insufficient_amount'), {
            variant: 'error',
          });
          return;
        }

        if (roomState?.status === 'STARTED' || roomState?.status === 'STOPED') {
          setIsBetDone(true);
          // setBetting(true);
          setBetButtonState(BET_BUTTON_STATE.CANCEL);
          setBetButtonCap(translate('loading'));
          setBetButtonSubCap(translate('cancel2'));
          setBetNextData({ bet_amount: amountP, cashout_at: +cashoutAt });
        }
        if (roomState?.status === 'WAITING_START') {
          setIsBetDone(true);
          // setBetting(true);
          setBetButtonState(BET_BUTTON_STATE.WAIT);
          setBetButtonCap(translate('loading'));
          setBetButtonSubCap('');

          bet({ bet_amount: amountP, cashout_at: +cashoutAt });
        }
      }
    } catch (err) {
      enqueueSnackbar(translate(StartBetRoundHost()), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    setMiniGameOnTop(minicrashGameRef.current);
  }, []);

  return (
    <>
      <motion.div
        id="minicrash"
        ref={minicrashGameRef}
        onPointerDownCapture={() => {
          setMiniGameOnTop(minicrashGameRef.current);
        }}
        drag
        dragConstraints={{
          left: -(WD.width - (isDesktop ? 1100 : 130)),
          right: isDesktop ? 620 : 300,
          top: -(WD.height - (isDesktop ? 800 : 725)),
          bottom: isDesktop ? 416 : 330,
        }}
        onPointerDown={(event) => {
          controls.start(event);
        }}
        dragControls={controls}
        style={{
          zIndex: 999,
          position: 'fixed',
          top: `calc(100% - ${isDesktop ? 800 : 725}px)`,
          left: `calc(100% - ${isDesktop ? 1100 : 410}px)`,
        }}
      >
        <Wrapper>
          <Header>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Stack spacing={1.5}>
                <GroupHeaderButtons>
                  <HeaderButton
                    onClick={() => {
                      open(false);
                      navigate('/casino/original/crash');
                    }}
                  >
                    <IconMaximizeMiniGame />
                  </HeaderButton>
                  <HeaderButton
                    onClick={() => {
                      setIsStatisticTable((v) => !v);
                    }}
                  >
                    <IconTrendMiniGame />
                  </HeaderButton>
                </GroupHeaderButtons>
              </Stack>
              <RoundHistories payOutHistories={payOutHistories} />
            </Stack>
            <HeaderButton
              onClick={() => {
                open(false);
              }}
            >
              <IconCloseMiniGame />
            </HeaderButton>
          </Header>
          <CrashLine
            ping={ping}
            process={process}
            winAmount={meWinAmount}
            winRate={meWinRate}
            currencyId={playerMe()?.currency_id}
            payout={roomState?.fx}
            countTime={(countTime! / 1000).toFixed(1)}
            crashAt={crashAt}
            chartData={chartData}
            status={roomState?.status}
            cashOutPoint={playerCashOut}
            handleOpenPredictorDialog={() => setIsOpenPredictorDialog(true)}
            highestCrashResult={highestCrashResult}
          />
          <Footer>
            <Stack gap="4px">
              <BetAmountInput
                disabled={isBetDone}
                minBet={
                  info?.game?.gameFund?.betRooms[0]?.betRoomSetting
                    ?.defaultBetAmount || 0
                }
                value={betAmount}
                onChange={(value) => {
                  setBetAmount(value);
                }}
              />
              <CashoutAtInput
                disabled={isBetDone}
                minCashout={1.01}
                value={cashoutAt}
                onChange={(value) => {
                  setCashoutAt(value);
                }}
              />
            </Stack>
            <PlayButton
              buttonState={betButtonState}
              caption={betButtonCap}
              subCaption={betButtonSubCap}
              onClick={handleManualBet}
            />
          </Footer>

          <PredictorDialog
            isOpen={isOpenPredictorDialog}
            onClose={() => setIsOpenPredictorDialog(false)}
            gameId={info?.game?.game?.id}
          />
        </Wrapper>
      </motion.div>
      {isShowStatisticTable && (
        <StatisticTable
          onClose={() => {
            setIsStatisticTable(false);
          }}
          results={payOutHistories}
        />
      )}
    </>
  );
}

export default memo(GameMiniCrash);
