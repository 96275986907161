import { ErrorMessage } from '@hookform/error-message';
import Iconify from 'components/iconify/Iconify';
import {
  RejectionFiles,
  SingleFilePreview,
  UploadProps,
} from 'components/upload';
import ValidationMessage from 'components/ValidationMessage';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';

import { alpha, IconButton } from '@mui/material';

import Placeholder from './PlaceHolder';
import { StyledDropZone, Wrapper } from './styles';

/* -------------------------------------------------------------------------- */

const maxFileSize = 10000000; // 10MB

interface UploadImageProps extends UploadProps {
  label: string;
  name: string;
}

const UploadImage = ({
  label,
  onDelete,
  placeholder,
  error,
  sx,
  name,
  multiple = false,
  ...other
}: UploadImageProps) => {
  const {
    clearErrors,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();
  const file = getValues(name);

  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file) {
      clearErrors();
      setValue(
        name,
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
    }
  };

  const handleDelete = () => {
    setValue(name, null);
    if (onDelete) onDelete();
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxSize: maxFileSize,
    onDrop: handleDrop,
    multiple,
    ...other,
  });

  const hasFile = !!file;

  const isError = isDragReject || !!error;

  return (
    <Wrapper sx={{ flex: 1, position: 'relative', ...sx }}>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          my: 3,
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
        }}
      >
        {placeholder || (
          <Placeholder
            sx={{
              ...(hasFile && {
                opacity: 0,
              }),
            }}
            label={label}
          />
        )}
        <input {...getInputProps()} hidden />
        {hasFile && <SingleFilePreview file={file} />}
        {hasFile && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            sx={{
              top: '8%',
              right: '5%',
              zIndex: 0,
              position: 'absolute',
              color: (theme) => alpha(theme.palette.common.white, 0.8),
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              p: 0.5,
              '&&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }}
          >
            <Iconify icon="eva:close-fill" width={18} />
          </IconButton>
        )}
      </StyledDropZone>
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => (
          <ValidationMessage
            sx={{ justifyContent: { sm: 'center', xs: 'flex-start' } }}
            mb={2}
            mt={-1}
            ml={0}
            message={message}
          />
        )}
      />
      {/* <Stack spacing={1.5} direction="row">
        <Button
          component="label"
          variant="text"
          {...(isDesktop && {
            startIcon: <Iconify icon="solar:camera-bold" />,
          })}
        >
          <input {...getInputProps()} capture="environment" hidden />
          <Typography variant="subtitle2">
            {translate('take_a_photo')}
          </Typography>
        </Button>
        <Button
          component="label"
          variant="text"
          {...(isDesktop && { startIcon: <Iconify icon="uiw:cloud-upload" /> })}
        >
          <input {...getInputProps()} hidden />
          <Typography variant="subtitle2">
            {translate('upload_picture')}
          </Typography>
        </Button>
      </Stack> */}

      <RejectionFiles fileRejections={fileRejections} />
    </Wrapper>
  );
};

export default UploadImage;
