import Select from 'common/Select';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const DepositWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 570,
  overflowY: 'auto',
  alignItems: 'flex-start',
  'p.label': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    height: '100%',
  },
}));

export const DepositAddressBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2.5),
  '.deposit_address .address_string': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
    marginBottom: theme.spacing(2.5),
    p: {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 700,
    },
    path: { fill: theme.palette.text.primary },
    'p.deposit_address_typo': {
      '&:first-letter': {
        textTransform: 'unset',
      },
    },
  },
  '.address_qrcode': {
    backgroundColor: theme.palette.background.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
    display: 'flex',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '12px',
    },
    '#qrcode': {
      height: '40%',
      width: '40%',
      maxHeight: 80,
      maxWidth: 80,
      borderRadius: theme.shape.borderRadius,
    },
    '.notice': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignSelf: 'center',
      padding: theme.spacing(1, 1),
      background: 'rgba(47, 43, 96, 0.50)',
      borderRadius: theme.shape.borderRadius,
      p: {
        whiteSpace: 'break-spaces',
        lineClamp: 5,
        '-webkit-line-clamp': 5,
        color: '#fff',
        fontSize: '12px',
      },
      span: {
        color: '#fff',
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
        justifyContent: 'center',
      },
    },
  },
  '.bonus300': {
    marginTop: theme.spacing(2.5),
    background: theme.palette.background.secondaryBgImage,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.2),
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    p: {
      color: 'yellow',
      marginLeft: theme.spacing(1.5),
      fontSize: 13.5,
    },
  },
}));

export const DepositPiWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  '.address_wrapper': {
    backgroundColor: theme.palette.background.main,
    borderRadius: theme.shape.borderRadius,
    padding: 12,
    gap: 24,
    width: '100%',
  },
  '.qr_code_image': {
    backgroundColor: '#FFF',
    borderRadius: theme.shape.borderRadius,
    padding: 8,
  },
  '.address_detail': {
    backgroundColor: 'rgba(47, 43, 96, 0.50);',
    borderRadius: theme.shape.borderRadius,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '.title_address': { color: '#FFF', fontWeight: 700 },
    '.box_detail': {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      alignItems: 'flex-start',
      p: {
        color: '#FFF',
        whiteSpace: 'break-spaces',
        lineBreak: 'anywhere',
      },
    },
  },
  '.how_to_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    // margin: '0 50px',
    width: '100%',
    paddingTop: '16px',
    '.line_image': {
      height: '2px',
      width: '100%',
    },
  },
  '.content_wrapper': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    // justifyContent: '',
    gap: 15,
    '.content_space': {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '148px',
      // minWidth: '134px',
      textAlign: 'center',
      gap: '8px',
      margin: '8px 0',
    },
  },
}));

export const ConfirmedButton = styled(Button)(({ theme }) => ({
  width: '100%',
  minHeight: 48,
  borderRadius: theme.shape.borderRadius,
  marginTop: '12px',
  backgroundImage:
    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  color: '#2E2C5E',
  '&.Mui-disabled': {
    opacity: 0.7,
  },
  // position: 'absolute',
  // bottom: 0,
  ':hover': {
    backgroundImage:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.7,
  },
}));

export const SelectNetWork = styled(Select)(({ theme }) => ({
  '.MuiInputBase-input': {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: 'normal',
    padding: theme.spacing(2.3, 2),
  },
}));
