import { MutationResult } from '@apollo/client';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemedButton } from 'common/Button/style';
import FormProvider, { RHFTextField } from 'components/hook-form';
import ValidationMessage from 'components/ValidationMessage';
import { emailRegex, REFERRAL_CODE } from 'constants/index';
import { RegisterMutation } from 'graph';
import { useLocales } from 'locales';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { HELP_CENTER_PATHS } from 'routes/routesPage/helpCenter';
import * as Yup from 'yup';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Checkbox, Typography } from '@mui/material';

import { EmailBox, PasswordBox } from '../styles';
import { SignUpWrapper } from './styles_v2';

interface ISignUpProps {
  openSignIn: () => void;
  registerMutationResult: MutationResult<RegisterMutation>;
  handleCloseAuthDialog: () => void;
  onSubmitSignUp: (data: SignUpForm) => void;
}

export interface SignUpForm {
  email: string;
  password: string;
}

enum PASSWORD_RULES {
  NUMBER = 'REQUIRED_NUMBER',
  LOWER_UPPER = 'REQUIRED_LOWER_UPPERCASE',
  LENGTH = 'AT_LEAST_6',
}

const SignUp = ({
  openSignIn,
  onSubmitSignUp,
  handleCloseAuthDialog,
  registerMutationResult: { data, error: apolloError, loading },
}: ISignUpProps) => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const referralCode = localStorage.getItem(REFERRAL_CODE);
  const [isAgreeTermsService, setIsAgreeTermsService] = React.useState(false);
  const handleCheckboxChange = () => {
    setIsAgreeTermsService(!isAgreeTermsService);
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, translate('validate_invalid_email_address'))
      .required()
      .trim()
      .lowercase(),
    password: Yup.string().required().trim(),
  });

  const form = useForm<SignUpForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const password = form.watch('password');

  const errors = React.useMemo(() => {
    const errors = [];

    if (password.trim().length < 6) errors.push(PASSWORD_RULES.LENGTH);
    if (!password.trim().match(/[0-9]+/)) errors.push(PASSWORD_RULES.NUMBER);
    if (!password.trim().match(/(?=.*?[A-Z])(?=.*?[a-z])/))
      errors.push(PASSWORD_RULES.LOWER_UPPER);

    return errors;
  }, [password]);

  const handleViewTermsService = () => {
    navigate(HELP_CENTER_PATHS.TERMS_SERVICE);
    handleCloseAuthDialog();
  };

  const isDisableSubmit =
    errors.length > 0 ||
    !form.formState.isValid ||
    !isAgreeTermsService ||
    loading;

  return (
    <SignUpWrapper>
      <FormProvider methods={form} onSubmit={form.handleSubmit(onSubmitSignUp)}>
        <EmailBox>
          <RHFTextField name="email" placeholder="Enter your email" fullWidth />
          <ErrorMessage
            name="email"
            errors={form?.formState?.errors}
            render={({ message }) => (
              <ValidationMessage mt={1} ml={0} message={message} />
            )}
          />
        </EmailBox>
        <PasswordBox>
          <RHFTextField
            fullWidth
            name="password"
            placeholder={translate('enter_password_here')}
            isPasswordInput
          />
          <Box
            mt={2.25}
            display="flex"
            alignItems="center"
            sx={{ marginLeft: '0.25rem' }}
          >
            <div
              className={`box_item ${
                !errors.includes(PASSWORD_RULES.LOWER_UPPER) && 'isMatch'
              }`}
            >
              {!errors.includes(PASSWORD_RULES.LOWER_UPPER) && (
                <CheckRoundedIcon />
              )}
            </div>
            <Typography>{translate('include_lower_uppercase')}</Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{ marginLeft: '0.25rem' }}
          >
            <div
              className={`box_item ${
                !errors.includes(PASSWORD_RULES.NUMBER) && 'isMatch'
              }`}
            >
              {!errors.includes(PASSWORD_RULES.NUMBER) && <CheckRoundedIcon />}
            </div>
            <Typography>{translate('at_least_1_number')}</Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{ marginLeft: '0.25rem' }}
          >
            <div
              className={`box_item ${
                !errors.includes(PASSWORD_RULES.LENGTH) && 'isMatch'
              }`}
            >
              {!errors.includes(PASSWORD_RULES.LENGTH) && <CheckRoundedIcon />}
            </div>
            <Typography>{translate('minimum_6_char')}</Typography>
          </Box>
          {referralCode && (
            <PasswordBox mt={1}>
              <Typography variant="subtitle1" className="label">
                {translate('Code')}:
              </Typography>
              <RHFTextField
                name=""
                fullWidth
                variant="outlined"
                value={referralCode}
                disabled
              />
            </PasswordBox>
          )}
        </PasswordBox>
        <label htmlFor="agree-terms-service">
          <Checkbox
            id="agree-terms-service"
            color="primary"
            size="small"
            checked={isAgreeTermsService}
            onChange={handleCheckboxChange}
            sx={{
              width: { xs: 16, md: 24 },
              height: { xs: 16, md: 24 },
            }}
          />
          <Typography
            fontSize={{ xs: 12, sm: 14, lg: 16 }}
            ml={1}
            variant="body2"
            whiteSpace="pre-wrap"
          >
            {translate('agree_terms_service')}
            <span
              onClick={() => handleViewTermsService()}
              className="primary_green underline ml4"
            >
              {translate('terms_conditions')}
            </span>
          </Typography>
        </label>
        {data?.register?.error?.message && (
          <ValidationMessage
            mt={{ lg: 1, md: 1, sm: 1, xs: 2 }}
            mb={{ lg: -2, md: -1, sm: -1 }}
            message={data.register.error?.message}
            ml={0}
          />
        )}
        {apolloError && (
          <ValidationMessage
            mt={{ lg: 1, md: 1, sm: 1, xs: 2 }}
            mb={{ lg: -2, md: -1, sm: -1 }}
            message={translate('unexpected_error')}
            ml={0}
          />
        )}

        <ThemedButton
          className="confirm_button"
          type="submit"
          fullWidth
          variant={isDisableSubmit ? 'disable' : 'contained'}
          color="secondary"
          disabled={isDisableSubmit}
          style={{
            fontSize: 16,
            fontWeight: 700,
            color: '#2E2C5E',
            lineHeight: 'normal',
          }}
        >
          {translate('sign_up')}
        </ThemedButton>

        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <Typography
            fontSize={{ xs: 12, lg: 16 }}
            sx={{ color: 'text.contrast' }}
          >
            {translate('already_have_account')}
            <span
              onClick={() => openSignIn()}
              className="primary_green underline ml4"
            >
              {translate('sign_in')}
            </span>
          </Typography>
        </Box>
      </FormProvider>
    </SignUpWrapper>
  );
};

export default SignUp;
