import { Box, styled } from '@mui/material';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 60,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  '.title': {
    color: theme.palette.text.contrast,
  },
  '.close-btn': {
    padding: 0,
  },
}));

export const Body = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '&&& p': {
      fontSize: 14,
    },
  },

  height: 'calc(100% - 60px)',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    display: 'none',
  },

  '.user_avatar': {
    width: 60,
    height: 60,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },

  '&& .tip_btn': {
    fontSize: 14,
    fontWeight: 700,
    color: '#2E2C5E',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 3),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 10,
      padding: theme.spacing(1, 2),
    },
  },
}));
