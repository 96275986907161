import { varTranEnter } from 'components/animate';
import { motion } from 'framer-motion';
import useLocales from 'locales/useLocales';
import { useState } from 'react';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import {
  Box,
  Button,
  Table,
  TableContainerProps,
  Typography,
} from '@mui/material';

import { BetResultContainerWrapper } from '../GameDetail/BetResult/styles';

interface Props extends TableContainerProps {
  children: React.ReactElement;
  hasMore: boolean;
  setShowedRows: (state) => void;
  rows: any[];
  defaultShowedRows: number;
}

const BetTable = ({
  children,
  hasMore,
  setShowedRows,
  rows,
  defaultShowedRows,
  ...props
}: Props) => {
  const { translate } = useLocales();
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const handleShowMore = () => {
    setIsShowMore(true);
    setShowedRows(50);
  };

  const handleShowLess = () => {
    setIsShowMore(false);
    setShowedRows(defaultShowedRows);
  };

  return (
    <BetResultContainerWrapper
      {...props}
      $shadowLastRow={!isShowMore && hasMore}
    >
      <Box
        component={motion.div}
        initial={{ y: 50 }}
        animate={{ y: 0, transition: varTranEnter({ durationIn: 0.3 }) }}
      >
        <Table
          stickyHeader
          className="bet-table-wrapper"
          aria-label="result-table"
          sx={{ borderSpacing: '0 8px' }}
        >
          {children}
        </Table>
        {hasMore && rows.length > 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            className="show_more"
          >
            <Button
              onClick={() => handleShowMore()}
              endIcon={<KeyboardArrowDownRoundedIcon />}
            >
              <Typography>{translate('show_more')}</Typography>
            </Button>
          </Box>
        )}
        {!hasMore && rows.length > 10 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="show_less"
            style={{ paddingBottom: 0, width: '100%' }}
          >
            <Button
              onClick={() => handleShowLess()}
              endIcon={<KeyboardArrowUpRoundedIcon />}
            >
              <Typography>{translate('show_less')}</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </BetResultContainerWrapper>
  );
};

export default BetTable;
