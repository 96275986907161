import { DialogWrapper } from 'components/Dialog/styles';

import { styled } from '@mui/material';

export const MiniDiceBetHistoriesDialogWrapper = styled(DialogWrapper)(
  ({ theme }) => ({
    padding: '0 !important',
    backgroundColor: 'inherit',
    overflowY: 'auto',
    // my bet table
    '.mini-dice-my-bet': {
      '.primary-text': {
        color: theme.palette.text.primary,
      },
      '.MuiTableCell-head': {
        lineHeight: '16px',
        [theme.breakpoints.down('sm')]: {
          fontSize: 13,
        },
      },
      '.MuiTableCell-root': {
        padding: theme.spacing(1, 1.5),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 1),
        },
      },

      '.MuiTableCell-body.profit p': {
        fontWeight: 700,
      },
      '.MuiTableCell-body, p': {
        fontWeight: 400,
        lineHeight: '16px',
        textTransform: 'capitalize',
        fontSize: 13,
        [theme.breakpoints.down('sm')]: {
          fontSize: 10,
        },
        'img.currency-image': {
          width: 20,
          height: 20,
          [theme.breakpoints.down('sm')]: {
            width: 16,
            height: 16,
          },
        },
        '&.round-id, &.bet-id': {
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    // round histories table
    '.mini-dice-round-histories': {
      '.MuiTableCell-body': {
        fontWeight: 400,
        '.total-num': {
          borderRadius: '50%',
          width: 22,
          height: 22,
          backgroundColor: theme.palette.primary.main,
          fontSize: 12,
          fontWeight: 600,
          lineHeight: '22px',
        },
        '&.round-id': {
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    // rule tab
    '&:has(div#mini-dice-rules)': {
      borderRadius: 0,
    },
    '#mini-dice-rules': {
      h2: { lineHeight: 'initial' },
    },
  }),
);
