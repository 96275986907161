import { TabsStyled } from 'common/Tabs';
import { memo } from 'react';

import { Tab } from '@mui/material';

interface IMiniDiceEventTabsProps<TabValue> {
  tabs: TabsType<TabValue>;
  selectedTab: TabValue;
  onChangeTab: (tab: TabValue) => void;
}

const MiniDiceEventTabs = <TabValue extends string>({
  tabs,
  selectedTab,
  onChangeTab,
}: IMiniDiceEventTabsProps<TabValue>) => (
  <TabsStyled
    scrollButtons={false}
    value={selectedTab}
    onChange={(_e, selectedTab) => onChangeTab(selectedTab)}
  >
    {tabs.map((tab) => (
      <Tab key={tab.value} label={tab.label} value={tab.value} />
    ))}
  </TabsStyled>
);

export default memo(MiniDiceEventTabs) as typeof MiniDiceEventTabs;
