import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthContext } from 'auth/useAuthContext';
import FormProvider, { RHFTextField } from 'components/hook-form';
import ValidationMessage from 'components/ValidationMessage';
import { useChangePasswordMutation, useUserProfileQuery } from 'graph';
import useLocales from 'locales/useLocales';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import { ChangePasswordBox, SecurityWrapper } from './styles_v2';

// ----------------------------------------------------------------

enum PASSWORD_RULES {
  NUMBER = 'REQUIRED_NUMBER',
  LOWER_UPPER = 'REQUIRED_LOWER_UPPERCASE',
  LENGTH = 'AT_LEAST_6',
}

interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const V2Security = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { logout, openLoginModal } = useAuthContext();

  const [
    changePassword,
    { loading: isChangingPassword, data: changePwData, error: changePwError },
  ] = useChangePasswordMutation();
  const { data: userProfileData } = useUserProfileQuery({
    fetchPolicy: 'cache-only',
  });

  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required().trim(),
    newPassword: Yup.string().required().trim(),
    confirmPassword: Yup.string().required(),
  });

  const changePasswordForm = useForm<ChangePasswordForm>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(changePasswordSchema),
    mode: 'onChange',
  });

  const password = changePasswordForm.watch(['newPassword', 'confirmPassword']);

  const errors = React.useMemo(() => {
    const errors = [];
    const newPassword = password[0];

    if (newPassword.trim().length < 6) errors.push(PASSWORD_RULES.LENGTH);
    if (!newPassword.trim().match(/[0-9]+/)) errors.push(PASSWORD_RULES.NUMBER);
    if (!newPassword.trim().match(/(?=.*?[A-Z])(?=.*?[a-z])/))
      errors.push(PASSWORD_RULES.LOWER_UPPER);

    return errors;
  }, [password]);

  const handleChangePassword = async (data: ChangePasswordForm) => {
    const response = await changePassword({
      variables: {
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
    });
    if (response?.data.changePassword.success) {
      enqueueSnackbar(translate('change_password_success'), {
        variant: 'success',
      });
      logout();
      navigate('/');
      openLoginModal();
    }
  };

  const isNewPasswordInValid =
    changePasswordForm.getFieldState('newPassword').isDirty && !!errors.length;

  const isConfirmPasswordValid = React.useMemo(() => {
    const newPassword = password[0];
    const confirmPassword = password[1];
    return (
      (confirmPassword.length && newPassword === confirmPassword) ||
      !confirmPassword.length
    );
  }, [password]);

  const isDisableChangePassword =
    isNewPasswordInValid ||
    !changePasswordForm.formState.isValid ||
    isChangingPassword ||
    !isConfirmPasswordValid;

  return (
    <SecurityWrapper>
      <Stack direction={{ xl: 'row' }} spacing={{ xl: 4 }}>
        {userProfileData?.me?.profile?.hasPassword && (
          <ChangePasswordBox mb={3}>
            <FormProvider
              methods={changePasswordForm}
              onSubmit={changePasswordForm.handleSubmit(handleChangePassword)}
            >
              <Typography variant="h6" className="title">
                {translate('change_password')}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className="label">
                    {translate('current_password')}
                  </Typography>
                  <RHFTextField
                    fullWidth
                    className="security-input"
                    name="currentPassword"
                    placeholder={translate('enter_current_pw')}
                    isPasswordInput
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className="label">
                    {translate('new_password')}
                  </Typography>
                  <RHFTextField
                    fullWidth
                    className="security-input"
                    name="newPassword"
                    placeholder={translate('enter_new_password')}
                    isPasswordInput
                  />
                  <Box mt={1} display="flex" alignItems="center">
                    <div
                      className={`box_item ${
                        !errors.includes(PASSWORD_RULES.LOWER_UPPER) &&
                        'isMatch'
                      }`}
                    >
                      {!errors.includes(PASSWORD_RULES.LOWER_UPPER) && (
                        <CheckRoundedIcon />
                      )}
                    </div>
                    <Typography>
                      {translate('include_lower_uppercase')}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <div
                      className={`box_item ${
                        !errors.includes(PASSWORD_RULES.NUMBER) && 'isMatch'
                      }`}
                    >
                      {!errors.includes(PASSWORD_RULES.NUMBER) && (
                        <CheckRoundedIcon />
                      )}
                    </div>
                    <Typography>{translate('at_least_1_number')}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <div
                      className={`box_item ${
                        !errors.includes(PASSWORD_RULES.LENGTH) && 'isMatch'
                      }`}
                    >
                      {!errors.includes(PASSWORD_RULES.LENGTH) && (
                        <CheckRoundedIcon />
                      )}
                    </div>
                    <Typography>{translate('minimum_6_char')}</Typography>
                  </Box>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className="label">
                    {translate('confirm_password')}
                  </Typography>
                  <RHFTextField
                    fullWidth
                    className="security-input"
                    name="confirmPassword"
                    placeholder={translate('enter_repeat_password')}
                    isPasswordInput
                  />
                  {!isConfirmPasswordValid && (
                    <ValidationMessage
                      message={translate('password_not_match')}
                    />
                  )}
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {changePwData?.changePassword?.error && (
                    <ValidationMessage
                      mb={2}
                      mt={0}
                      ml={0}
                      message={changePwData?.changePassword?.error.message}
                    />
                  )}
                  {changePwError?.message && (
                    <ValidationMessage
                      mt={0}
                      mb={2}
                      ml={0}
                      message={changePwError.message}
                    />
                  )}
                  <Button
                    className="change_password_btn"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isDisableChangePassword}
                    sx={{
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                      background:
                        !isDisableChangePassword &&
                        'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
                      color: '#2E2C5E',
                      backgroundColor:
                        isDisableChangePassword && 'rgba(145, 158, 171, 0.24)',
                    }}
                  >
                    <Typography variant="body1">
                      {translate('change_password')}
                    </Typography>
                  </Button>
                  <Typography textAlign="center" whiteSpace="pre-wrap" mt={3}>
                    {translate('relogin_after_change_pw')}
                  </Typography>
                </Grid>
              </Grid>
            </FormProvider>
          </ChangePasswordBox>
        )}
      </Stack>
    </SecurityWrapper>
  );
};

export default V2Security;
