import { ReactComponent as SuccessGreen } from 'assets/icons/success-green.svg';
import {
  bronzeVip,
  diamondVip,
  goldVip,
  platinumVip,
  silverVip,
} from 'assets/images/vip_clubs';
import { useLoyalUserQuery } from 'graph';
import i18n from 'locales/i18n';
import { VipRankLinearTitle } from 'pages/VipClub/components/VipPoint/styles_v2';
import { useMemo } from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getVipRankByVipPoint, RANK_VALUE } from 'utils/getVipRank';

import { Box, Typography } from '@mui/material';

import { LevelsSliderWrapper, VipLevelCard } from './style';

interface IVipLevelSlideProps {
  range: { start: number; end: number };
  name: 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond';
  benefits: string[];
  image: string;
  id: number;
  rank: RANK_VALUE;
  isActive: boolean;
}

const LEVELS: Omit<IVipLevelSlideProps, 'isActive'>[] = [
  {
    id: 0,
    rank: 'bronze',
    name: i18n.t('bronze'),
    range: { start: 1, end: 11 },
    image: bronzeVip,
    benefits: [i18n.t('level_up_bonus'), i18n.t('rakeback')],
  },
  {
    id: 1,
    rank: 'silver',
    name: i18n.t('silver'),
    range: { start: 12, end: 21 },
    image: silverVip,
    benefits: [i18n.t('level_up_bonus'), i18n.t('rakeback')],
  },
  {
    id: 2,
    rank: 'gold',
    name: i18n.t('gold'),
    range: { start: 22, end: 31 },
    image: goldVip,
    benefits: [
      i18n.t('level_up_bonus_upgrade'),
      i18n.t('rakeback'),
      i18n.t('weekly_bonus'),
      i18n.t('monthly_bonus'),
      i18n.t('_24h_live_support'),
      i18n.t('bonus_growth'),
    ],
  },
  {
    id: 3,
    rank: 'platinum',
    name: i18n.t('platinum_1_to_6'),
    range: { start: 32, end: 46 },
    image: platinumVip,
    benefits: [
      i18n.t('level_up_bonus_upgrade'),
      i18n.t('rakeback'),
      i18n.t('weekly_bonus'),
      i18n.t('monthly_bonus'),
      i18n.t('_24h_live_support'),
      i18n.t('bonus_growth'),
    ],
  },
  {
    id: 4,
    rank: 'diamond',
    name: i18n.t('diamond_1_to_6'),
    range: { start: 47, end: 74 },
    image: diamondVip,
    benefits: [
      i18n.t('level_up_bonus_upgrade'),
      i18n.t('rakeback'),
      i18n.t('weekly_bonus'),
      i18n.t('monthly_bonus'),
      i18n.t('_24h_live_support'),
      i18n.t('bonus_growth'),
    ],
  },
];

const VipLevelSlide = (props: IVipLevelSlideProps) => {
  const { name, benefits, image, isActive, rank } = props;

  return (
    <VipLevelCard className={isActive ? 'active-rank' : ''}>
      <Box className="vip-level-card-image">
        <img src={image} alt={`${name}-slide`} />
      </Box>
      <Box className="vip-level-card-name">
        <VipRankLinearTitle
          color="white"
          textTransform="capitalize"
          textAlign="center"
          fontSize="20px"
          fontWeight={700}
          className={`vip-rank-title ${rank}`}
        >
          {name}
        </VipRankLinearTitle>
        {/* <LevelLabel>
          lv&nbsp;{range.start}&nbsp;-&nbsp;{range.end}
        </LevelLabel> */}
      </Box>
      <Box className="vip-level-card-benefits" mt={1.5}>
        {benefits.length &&
          benefits.map((i, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box display="flex" gap={0.5} key={idx}>
              <SuccessGreen width="16px" height="16px" />
              <Typography
                fontSize="12px"
                fontWeight={400}
                color="#9998D4"
                sx={{
                  overflow: 'unset',
                  textOverflow: 'initial',
                  whiteSpace: 'break-spaces',
                }}
              >
                {i}
              </Typography>
            </Box>
          ))}
      </Box>
    </VipLevelCard>
  );
};

const breakpoints = {
  900: {
    slidesPerView: 3,
    slidesPerGroup: 2,
  },
  600: {
    slidesPerView: 3,
    slidesPerGroup: 2,
  },
  400: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  320: {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
  },
  0: {
    slidesPerView: 1,
    slidesPerGroup: 1,
  },
};

const VipLevelsSlider = () => {
  const { data: loyalUserData } = useLoyalUserQuery({
    fetchPolicy: 'cache-only',
  });

  const currentRank = useMemo(
    () => getVipRankByVipPoint(loyalUserData?.me?.vipUser.vipPoint || 0).value,
    [loyalUserData],
  );

  return (
    <LevelsSliderWrapper my={1.5} px={3}>
      <Swiper
        spaceBetween={12}
        breakpoints={breakpoints}
        pagination
        modules={[Pagination]}
      >
        {LEVELS.map((level) => (
          <SwiperSlide key={level.id} style={{ height: 'auto' }}>
            <VipLevelSlide
              range={{
                start: level.range.start,
                end: level.range.end,
              }}
              rank={level.rank}
              isActive={level.rank === currentRank}
              name={level.name}
              benefits={level.benefits}
              image={level.image}
              id={level.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </LevelsSliderWrapper>
  );
};

export default VipLevelsSlider;
