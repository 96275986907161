import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { hideScrollbarY } from '../../utils/cssStyles';

// -----------------------------------------------------------------

export const AuthenticationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  overflow: 'auto',
  height: '100%',
  width: '100%',
  margin: 0,
  borderRadius: theme.shape.borderRadius,

  [theme.breakpoints.up(700)]: {
    minHeight: '626px',
  },

  '.confirm_button': {
    height: 48,
    color: '#2E2C5E',
    margin: theme.spacing(2, 0),
    borderRadius: theme.shape.borderRadius,
    background:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',

    '&.MuiButton-disable': {
      opacity: 0.7,
      color: theme.palette.background.main,
    },

    h6: {
      fontSize: 16,
      fontWeight: 700,
    },
    [theme.breakpoints.between(700, 'lg')]: {
      margin: theme.spacing(2, 0),
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 40,
      margin: theme.spacing(1.5, 0),
      h6: {
        fontWeight: 500,
        fontSize: 12,
      },
    },
  },
  [theme.breakpoints.between(700, 'lg')]: {
    p: {
      fontSize: 12,
    },
  },
  [theme.breakpoints.down(700)]: {
    backgroundColor: theme.palette.background.main,
    ...hideScrollbarY,
    flexDirection: 'column',
    maxHeight: 'unset',
    '.thumb': {
      width: '100%',
      height: '25%',
    },
  },
}));

export const AuthenticationWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '0 12px 12px 0',
  backgroundColor: theme.palette.background.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  [theme.breakpoints.up(700)]: {
    maxWidth: 420,
    overflow: 'auto',
  },
  [theme.breakpoints.down(700)]: {
    width: '100%',
    height: 544,
    borderRadius: theme.shape.borderRadius,
  },
  [theme.breakpoints.down(450)]: {
    height: 520,
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 3, 0, 3),
  '.close-btn': {
    padding: 0,
  },
  [theme.breakpoints.between(700, 'lg')]: {
    padding: theme.spacing(3, 2, 0, 2),
  },
  [theme.breakpoints.down(700)]: {
    paddingLeft: theme.spacing(2),
    h6: {
      fontWeight: 400,
    },
  },
}));

export const Body = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3.5, 3, 0),
  '.label': {
    color: theme.palette.text.contrast,
    marginBottom: theme.spacing(1.5),
  },
  '.primary_green': {
    color: '#63D8FE',
    cursor: 'pointer',
    fontWeight: 500,
    width: 'fit-content',
  },
  '.underline': {
    textDecoration: 'underline',
  },
  '.ml4': {
    marginLeft: theme.spacing(0.5),
  },
  [theme.breakpoints.down(700)]: {
    padding: theme.spacing(3, 2, 0),
    '.label': {
      fontSize: 12,
    },
  },
  [theme.breakpoints.between(700, 'lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
    '.label': {
      marginBottom: theme.spacing(1),
    },
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    input: {
      fontSize: 14,
    },
  },
}));

export const Footer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3, 3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.between(700, 'lg')]: {
    marginBottom: theme.spacing(2),
  },
  '.MuiDivider-root': {
    '&::before, &::after': {
      borderColor: theme.palette.text.primary,
      transform: 'none',
    },
  },
}));

export const SSOItemBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.5, 1.5, 0),
  button: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#2F2B60',
    '&:hover': {
      backgroundColor: theme.palette.background.main,
    },
  },
}));

export const EmailBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '&& .MuiOutlinedInput-root': {
    input: {
      color: theme.palette.common.white,
    },
    color: theme.palette.error.main,
    paddingRight: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
    border: 'none',
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  [theme.breakpoints.between(700, 'lg')]: {
    marginBottom: theme.spacing(1.5),
    input: {
      padding: theme.spacing(1, 2),
    },
  },
}));

export const PasswordBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.between(700, 'lg')]: {
    marginBottom: theme.spacing(1.5),
    input: {
      fontSize: 14,
      padding: theme.spacing(1, 2),
    },
  },
  '&& .MuiOutlinedInput-root': {
    input: {
      color: theme.palette.common.white,
    },
    color: theme.palette.error.main,
    paddingRight: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.secondary,
    border: 'none',
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
}));
