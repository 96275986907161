import { ASSETS_TABS } from 'constants/index';
import { Currency } from 'graph';
import useResponsive from 'hooks/useResponsive';

import { Box, Typography } from '@mui/material';

import { SubtabWrapper } from '../../styles';
import WalletDialogHeader from '../WalletDialogHeader';
import { DepositCrypto } from './DepositCrypto';
import DepositPi from './DepositPi';
import { DepositWrapper } from './styles';

/* -------------------------------------------------------------------------- */

interface Props {
  handleCloseWalletDialog: VoidFunction;
  handleSelectCurrency: (currency: Currency) => void;
  selectedCurrency: Currency;
  handleClose: () => void;
  selectedSubTab?: {
    id: number;
    label: string;
    icon: JSX.Element;
  };
  subTab?: Array<{
    id: number;
    label: string;
    icon: JSX.Element;
  }>;
  handleSelectSubTab: (e: any) => void;
  handleChangeWalletTab: (tabId: number) => void;
}

const Deposit = ({
  handleCloseWalletDialog,
  selectedCurrency,
  handleSelectCurrency,
  selectedSubTab,
  handleClose,
  subTab,
  handleSelectSubTab,
  handleChangeWalletTab,
}: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');

  return (
    <DepositWrapper>
      {isTablet && (
        <SubtabWrapper>
          {subTab.map((e) => (
            <Box
              key={e.id}
              className="sub_tab_container"
              sx={{
                background:
                  selectedSubTab.label === e.label &&
                  'linear-gradient(180deg, #313064 0%, #43416C 100%)',
                color: selectedSubTab.label === e.label && '#FFF',
              }}
              onClick={() => handleSelectSubTab(e)}
            >
              <Box
                className={`sub_tab ${
                  selectedSubTab.label === e.label && `subtab_icon_${e.label}`
                }`}
              >
                {e.icon}
              </Box>

              <Typography component="span">{e.label}</Typography>
            </Box>
          ))}
        </SubtabWrapper>
      )}
      {!isMobile && !isTablet && (
        <WalletDialogHeader handleClose={handleClose} title="deposit" />
      )}
      {selectedSubTab.label === ASSETS_TABS.CRYPTO.name && (
        <DepositCrypto
          handleCloseWalletDialog={handleCloseWalletDialog}
          selectedCurrency={selectedCurrency}
          handleSelectCurrency={handleSelectCurrency}
          handleChangeWalletTab={handleChangeWalletTab}
        />
      )}
      {selectedSubTab.label === ASSETS_TABS.PI.name && <DepositPi />}
    </DepositWrapper>
  );
};

export default Deposit;
