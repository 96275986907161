export enum COIN_SIDE {
  HEAD = 'head',
  TAIL = 'tail',
}

export enum COIN_FLIP_MODE {
  INSTANT = 'instant',
  MULTIPLY = 'multiply',
}

export interface CoinflipBetFormType {
  series: number;
  betMode: COIN_FLIP_MODE;
  currentMultiplier: number;
  nextMultiplier: number;
  isShowAutoBetSetup: boolean;
  autoBetCoinSide: COIN_SIDE;
  flippedRounds: boolean[];
  flipCoinFn: (side: COIN_SIDE, enabledTurbo: boolean) => Promise<void>;
}
