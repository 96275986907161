import { DialogWrapper } from 'components/Dialog/styles';

import { Box, styled } from '@mui/material';

export const MiniDiceEventDialogWrapper = styled(DialogWrapper)(
  ({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    // leader board tab
    '.mini-dice-top-streak': {
      '.MuiTableCell-root': {
        padding: theme.spacing(1, 1.5),
      },
      '.MuiTableCell-body': {
        '&.top-rank svg': {
          width: 20,
          height: 20,
        },
        '&, & p': {
          fontSize: 13,
          [theme.breakpoints.down('sm')]: {
            fontSize: 12,
          },
        },
      },
      '.mini-dice-top-streak-title': {
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        svg: {
          width: 20,
          height: 20,
        },
        '.title': {
          textTransform: 'uppercase',
          fontSize: 14,
          fontWeight: 700,
          lineHeight: '20px',
        },
      },
    },
    // rule tab
    '#mini-dice-event-rules': {
      maxHeight: '100%',
      div: {
        maxHeight: 'inherit',
        overflowY: 'scroll',
        h2: { lineHeight: 'initial' },
      },
    },
  }),
);

export const EventHistoryWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  '&& .MuiTableCell-root': {
    padding: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
    '&:first-of-type': {
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0.5),
      },
    },
    '&.MuiTableCell-head': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
  },
  '& .MuiTableBody-root': {
    '.MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
  '& .MuiTableCell-body': {
    'img.currency-image': {
      width: 16,
      height: 16,
      marginRight: 0,
    },
    '.ranking-icon': {
      width: 24,
      height: 24,
      lineHeight: '16px',
      padding: theme.spacing(0.5),
      borderRadius: '4px',
      background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
    },
    '.dice-num': {
      width: 16,
      height: 16,
      display: 'flex',
      justifyContent: 'center',
      lineHeight: '16px',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderRadius: '50%',
      color: theme.palette.background.main,
      fontWeight: 700,
      '&.dice-first': {
        backgroundColor: '#7660FE',
      },
      '&.dice-second': {
        backgroundColor: '#63D8FE',
      },
      '&.dice-third': {
        backgroundColor: '#FFE500',
      },
    },
    '&, & p': {
      fontSize: 12,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
  },
}));
