export const WelcomeMessage = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'welcome_to_Coco_game';
    case 'pi':
      return 'welcome_to_Pi';
    case 'ohplay':
      return 'welcome_to_Ohplay';
    case 'satoshity':
      return 'welcome_to_satoshity';
    case 'betgecko':
      return 'welcome_to_betgecko';
    case 'bitwin':
      return 'welcome_to_bitwin';
    default:
      return 'welcome_to_Coco_game';
  }
};

export const DontWorryThough = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'you_can_still_enjoy_the_best_casino_experience_at_Coco';
    case 'pi':
      return 'you_can_still_enjoy_the_best_casino_experience_at_Pi';
    case 'ohplay':
      return 'you_can_still_enjoy_the_best_casino_experience_at_Ohplay';
    case 'satoshity':
      return 'you_can_still_enjoy_the_best_casino_experience_at_satoshity';
    case 'betgecko':
      return 'you_can_still_enjoy_the_best_casino_experience_at_betgecko';
    case 'bitwin':
      return 'you_can_still_enjoy_the_best_casino_experience_at_bitwin';
    default:
      return 'you_can_still_enjoy_the_best_casino_experience_at_Coco';
  }
};

export const NoAvailable = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'coco_game_is_not_available_in_your_region';
    case 'pi':
      return 'pi_game_is_not_available_in_your_region';
    case 'ohplay':
      return 'ohplay_game_is_not_available_in_your_region';
    case 'satoshity':
      return 'satoshity_game_is_not_available_in_your_region';
    case 'betgecko':
      return 'betgecko_game_is_not_available_in_your_region';
    case 'bitwin':
      return 'bitwin_game_is_not_available_in_your_region';
    default:
      return 'coco_game_is_not_available_in_your_region';
  }
};

export const HowToGetGiftCode = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'how_to_get_Coco_gift_code';
    case 'pi':
      return 'how_to_get_Pi_gift_code';
    case 'ohplay':
      return 'how_to_get_Ohplay_gift_code';
    case 'satoshity':
      return 'how_to_get_satoshity_gift_code';
    case 'betgecko':
      return 'how_to_get_betgecko_gift_code';
    case 'bitwin':
      return 'how_to_get_bitwin_gift_code';
    default:
      return 'how_to_get_Coco_gift_code';
  }
};

export const GiftCodeHost = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'gift_code_coco';
    case 'pi':
      return 'gift_code_pi';
    case 'ohplay':
      return 'gift_code_ohplay';
    case 'satoshity':
      return 'gift_code_satoshity';
    case 'betgecko':
      return 'gift_code_betgecko';
    case 'bitwin':
      return 'gift_code_bitwin';
    default:
      return 'gift_code_coco';
  }
};

export const HowToYouGiftCode = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'how_to_use_Coco_gift_code';
    case 'pi':
      return 'how_to_use_pi_gift_code';
    case 'ohplay':
      return 'how_to_use_ohplay_gift_code';
    case 'satoshity':
      return 'how_to_use_satoshity_gift_code';
    case 'betgecko':
      return 'how_to_use_betgecko_gift_code';
    case 'bitwin':
      return 'how_to_use_bitwin_gift_code';
    default:
      return 'how_to_use_Coco_gift_code';
  }
};

export const UseYourGiftToBet = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'use_your_COD_gift_to_bet_and_win_at_Cocogame';
    case 'pi':
      return 'use_your_COD_gift_to_bet_and_win_at_pi';
    case 'ohplay':
      return 'use_your_COD_gift_to_bet_and_win_at_ohplay';
    case 'satoshity':
      return 'use_your_COD_gift_to_bet_and_win_at_satoshity';
    case 'betgecko':
      return 'use_your_COD_gift_to_bet_and_win_at_betgecko';
    case 'bitwin':
      return 'use_your_COD_gift_to_bet_and_win_at_bitwin';
    default:
      return 'use_your_COD_gift_to_bet_and_win_at_Cocogame';
  }
};

export const CODGInfo = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'CODG_is_the_reward_token_of_Cocogame';
    case 'pi':
      return 'CODG_is_the_reward_token_of_Pigame';
    case 'ohplay':
      return 'CODG_is_the_reward_token_of_Ohplay';
    case 'satoshity':
      return 'CODG_is_the_reward_token_of_satoshity';
    case 'betgecko':
      return 'CODG_is_the_reward_token_of_betgecko';
    case 'bitwin':
      return 'CODG_is_the_reward_token_of_bitwin';
    default:
      return 'CODG_is_the_reward_token_of_Cocogame';
  }
};

export const ContactHost = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'if_you_have_a_website_with_substantial_traffic_coco';
    case 'pi':
      return 'if_you_have_a_website_with_substantial_traffic_pi';
    case 'ohplay':
      return 'if_you_have_a_website_with_substantial_traffic_ohplay';
    case 'satoshity':
      return 'if_you_have_a_website_with_substantial_traffic_satoshity';
    case 'betgecko':
      return 'if_you_have_a_website_with_substantial_traffic_betgecko';
    case 'bitwin':
      return 'if_you_have_a_website_with_substantial_traffic_bitwin';
    default:
      return 'if_you_have_a_website_with_substantial_traffic_coco';
  }
};

export const LicenseHost = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'due_to_our_gaming_license_we_cannot_accept_players_from_your_country_coco';
    case 'pi':
      return 'due_to_our_gaming_license_we_cannot_accept_players_from_your_country_pi';
    case 'ohplay':
      return 'due_to_our_gaming_license_we_cannot_accept_players_from_your_country_ohplay';
    case 'satoshity':
      return 'due_to_our_gaming_license_we_cannot_accept_players_from_your_country_satoshity';
    case 'betgecko':
      return 'due_to_our_gaming_license_we_cannot_accept_players_from_your_country_betgecko';
    case 'bitwin':
      return 'due_to_our_gaming_license_we_cannot_accept_players_from_your_country_bitwin';
    default:
      return 'due_to_our_gaming_license_we_cannot_accept_players_from_your_country_coco';
  }
};

export const AllBetHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'all_plays' : 'all_bets';
};

export const MyBetHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'my_plays' : 'my_bets';
};

export const HighBetHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'high_play' : 'high_bets';
};

export const BetIDHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play_id' : 'bet_id';
};

export const BetAmountHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play_amount' : 'bet_amount';
};

export const SetAutoBetHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'set_auto_play' : 'set_auto_bet';
};

export const NumberOffHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'number_of_play' : 'number_of_bet';
};
``;

export const StopAutoBetHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'stop_auto_play' : 'stop_auto_bet';
};

export const StartBetRoundHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'start_play_round_failed' : 'start_bet_round_failed';
};

export const WagerContestPi = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'wager_contest_pi' : 'wager_contest';
};

export const BetHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play' : 'bet';
};

export const MaxBet = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'max_play' : 'max_bet';
};

export const TotalBetAmount = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'total_play_amount' : 'total_bet_amount';
};

export const UnderMinBet = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'under_min_play' : 'under_min_bet';
};

export const InvalidBetParams = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'invalid_play_params' : 'invalid_bet_params';
};

export const OverMaxBet = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'over_max_play' : 'over_max_bet';
};

export const InvalidBetState = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'invalid_play_state' : 'invalid_bet_state';
};

export const BetNext = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play_next' : 'bet_next';
};

export const BetTing = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'playing' : 'betting';
};

export const BetHead = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play_head' : 'bet_head';
};

export const BetTail = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play_tail' : 'bet_tail';
};

export const AutoBetSetupHost = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'auto_play_setup' : 'auto_bet_setup';
};

export const ClickBetToChooseCoinSide = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi'
    ? 'click_play_to_choose_coin_side'
    : 'click_bet_to_choose_coin_side';
};

export const BetHistories = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play_histories' : 'bet_histories';
};

export const BetPlace = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play_place' : 'bet_place';
};

export const CanNotGetBetDetail = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'can_not_get_play_detail' : 'can_not_get_bet_detail';
};

export const BetWin = () => {
  const host = process.env.REACT_APP_SITE;
  return host === 'pi' ? 'play_win' : 'bet_win';
};

export const EmailSupport = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'support@coco.game';
    case 'pi':
      return 'support@pi.game';
    case 'ohplay':
      return 'support@ohplay.club';
    case 'satoshity':
      return 'support@satoshity.io';
    case 'betgecko':
      return 'support@betgecko.io';
    case 'bitwin':
      return 'support@bitwin.club';
    default:
      return 'support@coco.game';
  }
};

export const EmailBusiness = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'business@coco.game';
    case 'pi':
      return 'business@pi.game';
    case 'ohplay':
      return 'business@ohplay.club';
    case 'satoshity':
      return 'business@satoshity.io';
    case 'betgecko':
      return 'business@betgecko.io';
    case 'bitwin':
      return 'business@bitwin.club';
    default:
      return 'business@coco.game';
  }
};

export const AffiliateHost = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'refer_description_coco';
    case 'pi':
      return 'refer_description_pi';
    case 'ohplay':
      return 'refer_description_ohplay';
    case 'satoshity':
      return 'refer_description_satoshity';
    case 'betgecko':
      return 'refer_description_betgecko';
    case 'bitwin':
      return 'refer_description_bitwin';
    default:
      return 'refer_description_coco';
  }
};

export const EmailVerifiedHost = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
      return 'your_email_verified_coco';
    case 'pi':
      return 'your_email_verified_pi';
    case 'ohplay':
      return 'your_email_verified_ohplay';
    case 'satoshity':
      return 'your_email_verified_satoshity';
    case 'betgecko':
      return 'your_email_verified_betgecko';
    case 'bitwin':
      return 'your_email_verified_bitwin';
    default:
      return 'refer_description_coco';
  }
};

// Replace Host

export const ReplaceHost = (text) => {
  let replaceValue = '';
  switch (process.env.REACT_APP_SITE) {
    case 'coco':
    case 'pi':
      replaceValue = `${process.env.REACT_APP_SITE}.game`;
      break;
    case 'ohplay':
    case 'bitwin':
      replaceValue = `${process.env.REACT_APP_SITE}.club`;
      break;
    case 'satoshity':
    case 'betgecko':
      replaceValue = `${process.env.REACT_APP_SITE}.io`;
      break;
    default:
      return text;
  }
  return text.replaceAll('ohplay.club', replaceValue);
};
