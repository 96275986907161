import { useReactiveVar } from '@apollo/client';
import { ReactComponent as ConfettiIcon } from 'assets/icons/confetti.svg';
import LoadingAnimated from 'components/LoadingAnimated';
import MiniDiceTopStreakTable from 'components/_Games/_GamesMiniDice/mini-dice-event/components/leader-board/MiniDiceTopStreakTable';
import {
  ThreeDiceLeaderBoardQuery,
  useThreeDiceLeaderBoardQuery,
  useUserInfoQuery,
} from 'graph';
import { useLocales } from 'locales';
import { memo, useMemo } from 'react';
import { storageCurrencyVar } from 'store';

import { Box, Stack, Typography } from '@mui/material';

type MyRank = ArrayElement<
  ThreeDiceLeaderBoardQuery['threeDiceCurrentGame']['streakLeaderBoards']
> & {
  place: number;
};

const MiniDiceLeaderBoard = () => {
  const { translate } = useLocales();
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const { data: threeDiceLeaderBoardData, loading } =
    useThreeDiceLeaderBoardQuery({
      fetchPolicy: 'network-only',
      variables: {
        currencyId: storageCurrency.id,
      },
    });
  const { data: userInfoData } = useUserInfoQuery({
    fetchPolicy: 'cache-only',
  });

  const topWinStreakRows = useMemo(() => {
    if (!threeDiceLeaderBoardData?.threeDiceCurrentGame?.streakLeaderBoards)
      return [];

    return threeDiceLeaderBoardData?.threeDiceCurrentGame?.streakLeaderBoards?.filter(
      (streakLog) => streakLog.streakType === 1, // 0 is Lose, 1 is Win
    );
  }, [threeDiceLeaderBoardData]);

  const topLoseStreakRows = useMemo(() => {
    if (!threeDiceLeaderBoardData?.threeDiceCurrentGame?.streakLeaderBoards)
      return [];

    return threeDiceLeaderBoardData?.threeDiceCurrentGame?.streakLeaderBoards?.filter(
      (streakLog) => streakLog.streakType === 0, // 0 is Lose, 1 is Win
    );
  }, [threeDiceLeaderBoardData]);

  const myRank: MyRank = useMemo(() => {
    if (!topLoseStreakRows?.length || !topWinStreakRows.length) return null;

    let myRankInStreakLeaderBoard;

    (threeDiceLeaderBoardData?.threeDiceCurrentGame?.currentStreak
      ?.streakType === 0
      ? topLoseStreakRows
      : topWinStreakRows
    ).forEach((streakLog, index) => {
      if (streakLog?.userId === userInfoData?.me?.user?.id) {
        myRankInStreakLeaderBoard = {
          ...streakLog,
          place: index + 1,
        };
      }
    });

    return {
      ...threeDiceLeaderBoardData.threeDiceCurrentGame?.currentStreak,
      user: userInfoData?.me?.user,
      ...myRankInStreakLeaderBoard,
    };
  }, [
    userInfoData,
    topWinStreakRows,
    topLoseStreakRows,
    threeDiceLeaderBoardData,
  ]);

  if (loading)
    return (
      <Box
        position="absolute"
        width="100%"
        height="100%"
        sx={{ top: 0, left: '50%', transform: 'translate(-50%, -5%)' }}
      >
        <LoadingAnimated />
      </Box>
    );

  return (
    <Stack width="100%" height="-webkit-fill-available" rowGap={3}>
      <Stack className="mini-dice-top-streak">
        <Stack
          className="mini-dice-top-streak-title"
          direction="row"
          columnGap={1}
        >
          <ConfettiIcon />
          <Typography className="title">
            {translate('top_winning_streak')}
          </Typography>
        </Stack>
        <MiniDiceTopStreakTable
          rows={topWinStreakRows}
          myRank={myRank?.streakType === 1 ? myRank : null}
        />
      </Stack>
      <Stack className="mini-dice-top-streak">
        <Stack
          className="mini-dice-top-streak-title"
          direction="row"
          columnGap={1}
        >
          <ConfettiIcon />
          <Typography className="title">
            {translate('top_losing_streak')}
          </Typography>
        </Stack>
        <MiniDiceTopStreakTable
          rows={topLoseStreakRows}
          myRank={myRank?.streakType === 0 ? myRank : null}
        />
      </Stack>
    </Stack>
  );
};

export default memo(MiniDiceLeaderBoard);
