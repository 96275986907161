/* eslint-disable @typescript-eslint/no-use-before-define */
import { useAuthContext } from 'auth/useAuthContext';
import ChangeLanguageDialog from 'components/ChangeLanguageDialog';
import {
  useBetLazyQuery,
  useChatSubscription,
  useSentChatMutation,
  useUserChatsLazyQuery,
  useUserInfoQuery,
} from 'graph';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import numeral from 'numeral';
import { useEffect, useRef, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Badge, Box, IconButton, Stack, Typography } from '@mui/material';

import ItemMessageBase from './ItemMessageBase';
import MentionAutocomplete from './MentionAutocomplete';

// =================================================================================================

const ChatWindown = () => {
  const { currentLang, translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const drawerContentRef = useRef(null);
  const { isAuthenticated, openLoginModal } = useAuthContext();

  const EndRef: any = useRef(null);
  const [message, setMessage] = useState('');
  const [listChats, setlistChats] = useState([]);
  const [disableSend, setDisableSend] = useState(false);
  const [countNewMessage, setCountNewMessage] = useState([]);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLang);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: userInfoData } = useUserInfoQuery({
    fetchPolicy: 'cache-only',
  });

  const handleScrollToBottoms = () => {
    if (EndRef.current) {
      EndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
      setCountNewMessage([]);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      handleScrollToBottoms();
    }, 1000);
  }, []);

  const handleClickLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLanguage = () => {
    setAnchorEl(null);
  };

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    handleCloseLanguage();
  };

  const [getListChats] = useUserChatsLazyQuery({
    variables: {
      channel: selectedLanguage.label,
    },
  });

  const [sendMessage] = useSentChatMutation();
  const [getDetailBet] = useBetLazyQuery({});

  const { data: newMessage } = useChatSubscription({
    variables: {
      channel: selectedLanguage.label,
    },
  });

  const detectCommandChat = async (message: string) => {
    let commands: any = false;
    switch (true) {
      case message.includes('share:'):
        if (message.indexOf('share:') !== -1) {
          const result = message.slice(message.indexOf('share:'));
          if (result.match(/\d+/g).toString().length !== 18) {
            return;
          }
          const { data } = await getDetailBet({
            variables: {
              betId: result.match(/\d+/g).toString(),
            },
          });
          const match = message.match(/(.*)\bshare:(\d+)\b(.*)/);
          commands = {
            id: data.bet.id,
            square: data.bet.game.gameBase.thumbnail.square,
            name: data.bet.game.name,
            betAmount: data?.bet?.betAmount,
            currency: data?.bet?.currency,
            equivalentUsdRate: data?.bet?.equivalentUsdRate,
            multiplier: +numeral(data?.bet?.payoutRate).format('0.00'),
            payoutAmount: data.bet.payoutAmount,
            textBeforeShare: match ? match[1] : '',
            textAfterShare: match ? match[3] : '',
          };

          message = message.replaceAll(
            `share:${result.match(/\d+/g).toString()}`,
            '',
          );
        }
        break;

      default:
        commands = false;
        break;
    }

    return { message, commands };
  };
  useEffect(() => {
    getListChats({
      variables: {
        channel: selectedLanguage.label,
      },
    }).then(async (value) => {
      const chatLst = await Promise.all(
        value.data.chats.map(async (r) => {
          try {
            const detectCommand = await detectCommandChat(r?.message);
            return {
              id: r?.id,
              message: detectCommand.message,
              sender: {
                id: r?.sender?.userId,
                nickname: r?.sender?.nickname,
                vipLevel: r?.sender?.vipLevel,
                avatar: {
                  square: r?.sender?.avatar?.square,
                },
                commands: detectCommand.commands,
              },
            };
          } catch (error) {
            return {
              id: r?.id,
              message: r?.message,
              sender: {
                id: r?.sender?.userId,
                nickname: r?.sender?.nickname,
                vipLevel: r?.sender?.vipLevel,
                avatar: {
                  square: r?.sender?.avatar?.square,
                },
                commands: false,
              },
            };
          }
        }),
      );

      setlistChats(chatLst.reverse());
    });
  }, [selectedLanguage.label]);

  useEffect(() => {
    const processNewMessage = async () => {
      const getUserBlock = JSON.parse(localStorage.getItem('ignoreUser'));

      if (
        newMessage?.chat &&
        newMessage?.chat?.sender?.nickname !== userInfoData?.me?.user?.nickname
      ) {
        if (getUserBlock?.length > 0) {
          const checklist = getUserBlock.filter(
            (e) => e === newMessage?.chat?.sender?.userId,
          );
          if (checklist.length > 0) {
            return;
          }
        }

        let newM;
        try {
          const detectCommand = await detectCommandChat(
            newMessage?.chat?.message,
          );
          newM = {
            id: newMessage?.chat?.id,
            message: detectCommand.message,
            sender: {
              id: newMessage?.chat?.sender?.userId,
              nickname: newMessage?.chat?.sender?.nickname,
              avatar: {
                square: newMessage?.chat?.sender?.avatar?.square,
              },
              vipLevel: newMessage?.chat?.sender?.vipLevel,
              commands: detectCommand.commands,
            },
          };
        } catch (error) {
          newM = {
            id: newMessage?.chat?.id,
            message: newMessage?.chat?.message,
            sender: {
              id: newMessage?.chat?.sender?.userId,
              nickname: newMessage?.chat?.sender?.nickname,
              avatar: {
                square: newMessage?.chat?.sender?.avatar?.square,
              },
              vipLevel: newMessage?.chat?.sender?.vipLevel,
            },
          };
        }
        setTimeout(() => {
          if (!showScrollToBottom) {
            handleScrollToBottoms();
          }
        }, 100);
        setCountNewMessage((lists) => [...lists, newM]);
        setlistChats((lists) => [...lists, newM]);
      }
    };

    processNewMessage();
  }, [newMessage?.chat]);

  const getListUser = () => {
    const users = [];
    listChats.forEach((r) => {
      const find = users.find((u) => u.nickname === r.sender.nickname);
      if (!find) users.push(r.sender);
    });
    const filterUser = users?.filter(
      (r) => r.nickname !== userInfoData?.me?.user?.nickname,
    );
    return filterUser;
  };

  const handleSendMessage = async () => {
    if (disableSend) {
      enqueueSnackbar(translate('please_slow_down'), { variant: 'error' });
      return;
    }

    if (message.length > 0) {
      if (!isAuthenticated) {
        openLoginModal();
        return;
      }

      const linkRegex = /(http[s]?:\/\/[^\s]+)/gi;
      const containsLink = linkRegex.test(message);
      const allowedDomain = window.location.host;

      if (containsLink) {
        const containsAllowedDomain = new RegExp(
          `http[s]?://${allowedDomain}`,
          'gi',
        ).test(message);
        if (!containsAllowedDomain) {
          return enqueueSnackbar(
            translate('links_must_include_your_website_domain'),
            {
              variant: 'error',
            },
          );
        }
      }
      const value = await sendMessage({
        variables: {
          channel: selectedLanguage.label,
          message,
        },
      });
      const detectCommand = await detectCommandChat(message);

      const newM = {
        id: value?.data?.chat?.id,
        message: detectCommand.message,
        sender: {
          id: value?.data?.chat?.sender?.userId,
          nickname: value?.data?.chat?.sender?.nickname,
          avatar: {
            square: value?.data?.chat?.sender?.avatar?.square,
          },
          vipLevel: value?.data?.chat?.sender?.vipLevel,
          commands: detectCommand.commands,
        },
      };
      setlistChats((lists) => [...lists, newM]);
      setMessage('');
      setTimeout(() => {
        handleScrollToBottoms();
      }, 100);
    }
    setDisableSend(true);
    setTimeout(() => {
      setDisableSend(false);
    }, 3000);
  };

  const handleDrawerScroll = () => {
    const drawerContent = drawerContentRef.current;
    const isScrolledToBottom =
      drawerContent.scrollTop + drawerContent.clientHeight ===
      drawerContent.scrollHeight;

    if (isScrolledToBottom) {
      setShowScrollToBottom(false);
      setCountNewMessage([]);
    } else {
      setShowScrollToBottom(true);
    }
  };
  return (
    <Box height="100%">
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          //   position: 'inherit',
          height: 'auto',
          backgroundColor: '#262936',
          zIndex: 1,
          position: 'fixed',
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            p: 1,
            height: 'auto !important',
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ pl: 1 }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                backgroundColor: (theme) => theme.palette.background.hovering,
                p: 1,
                borderRadius: 1,
              }}
              onClick={handleClickLanguage}
            >
              <img
                width="20px"
                height="20px"
                src={selectedLanguage.icon}
                alt={selectedLanguage.label}
              />

              <Typography variant="subtitle2" fontSize="14px">
                {selectedLanguage.label}
              </Typography>

              <KeyboardArrowDownRoundedIcon className="arrow-icon" />
            </Stack>
            <ChangeLanguageDialog
              anchorEl={anchorEl}
              onClose={handleCloseLanguage}
              onSelect={handleSelectLanguage}
            />
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        onScroll={handleDrawerScroll}
        style={{ overflowY: 'auto', height: '100%' }}
        ref={drawerContentRef}
        // pb={5}
      >
        <Box sx={{ height: 'auto !important' }} pt={8} />
        {listChats?.map((value) => (
          <Box
            sx={{ height: 'auto !important' }}
            px={1}
            py={0.5}
            key={value.id}
          >
            <ItemMessageBase
              message={value}
              myInfo={userInfoData?.me?.user}
              userRecent={getListUser()}
            />
          </Box>
        ))}
        <Box sx={{ height: 'auto !important' }} py={5} />
        <div ref={EndRef} />
      </Box>
      {showScrollToBottom && (
        <Box
          sx={{
            height: 'auto !important',
            background: '#262936',
            borderRadius: '30px',
          }}
          position="fixed"
          bottom="80px"
          right="20px"
        >
          <Badge
            badgeContent={countNewMessage.length}
            color="secondary"
            overlap="circular"
            sx={{
              span: {
                height: '24px',
                width: '24px',
                borderRadius: '24px',
                fontSize: '8px',
                fontWeight: 600,
              },
            }}
          >
            <IconButton onClick={handleScrollToBottoms}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Badge>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{
          width: '100%',
          position: 'fixed',
          height: 'auto',
          backgroundColor: '#323646',
          zIndex: 1,
        }}
        sx={{
          width: '100%',
          boxShadow: (theme) => theme.shadows[12],
        }}
        bottom={0}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          p={0.5}
          sx={{
            width: '100%',
            background: '#262936',
          }}
        >
          <MentionAutocomplete
            userRecent={getListUser()}
            message={message}
            setMessage={(e: string) => setMessage(e)}
            sendMessage={handleSendMessage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { ChatWindown };
