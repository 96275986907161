import { SvgIcon } from '@mui/material';

export function IconHome(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M10 15.4999C9.59 15.4999 9.25 15.8399 9.25 16.2499V17.7499C9.25 18.1599 9.59 18.4999 10 18.4999C10.41 18.4999 10.75 18.1599 10.75 17.7499V16.2499C10.75 15.8399 10.41 15.4999 10 15.4999Z"
        fill="#9998D4"
      />
      <path
        d="M22 21.2507H21V9.9807C21 9.3607 20.72 8.7807 20.23 8.4007L13.23 2.9607C12.51 2.3907 11.49 2.3907 10.77 2.9607L3.77 8.4007C3.28 8.7807 3 9.3607 3 9.9707L2.95 21.2507H2C1.59 21.2507 1.25 21.5807 1.25 22.0007C1.25 22.4107 1.59 22.7507 2 22.7507H22C22.41 22.7507 22.75 22.4107 22.75 22.0007C22.75 21.5807 22.41 21.2507 22 21.2507ZM10.5 6.7507H13.5C13.91 6.7507 14.25 7.0907 14.25 7.5007C14.25 7.9107 13.91 8.2507 13.5 8.2507H10.5C10.09 8.2507 9.75 7.9107 9.75 7.5007C9.75 7.0907 10.09 6.7507 10.5 6.7507ZM17 21.2507H7V12.5007C7 11.6707 7.67 11.0007 8.5 11.0007H15.5C16.33 11.0007 17 11.6707 17 12.5007V21.2507Z"
        fill="#9998D4"
      />
    </SvgIcon>
  );
}
