import { useReactiveVar } from '@apollo/client';
import { USD } from 'assets/images';
import coinImages from 'assets/images/coins';
import {
  formatAmount,
  formatUSD,
} from 'components/_Games/gameControl/betControl';
import { useEffect, useState } from 'react';
import { storageCurrencyVar, viewInFiatVar } from 'store';
import { toN } from 'utils/number-help';

import { IconButton, InputAdornment, Stack } from '@mui/material';

import { BetTextField, LabelTextField } from './styles';

interface BetAmountInputProps {
  value: string;
  onChange: (value) => void;
  minBet: number;
  disabled?: boolean;
}

function BetAmountInput({
  value,
  onChange,
  minBet,
  disabled = false,
}: BetAmountInputProps) {
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);

  const [betAmount, setBetAmount] = useState<string>(
    viewInFiat?.status === true && storageCurrency?.equivalentUsdRate !== 0
      ? formatUSD(toN(value) * (storageCurrency?.equivalentUsdRate || 1))
      : formatAmount(value),
  );

  useEffect(() => {
    setBetAmount(
      viewInFiat?.status === true && storageCurrency?.equivalentUsdRate !== 0
        ? formatUSD(minBet * (storageCurrency?.equivalentUsdRate || 1))
        : formatAmount(minBet),
    );
    onChange(
      viewInFiat?.status === true && storageCurrency?.equivalentUsdRate !== 0
        ? formatUSD(minBet * (storageCurrency?.equivalentUsdRate || 1))
        : formatAmount(minBet),
    );
  }, [minBet, viewInFiat?.status, storageCurrency?.shortName]);

  return (
    <Stack direction="row">
      <LabelTextField>Bet Amount</LabelTextField>
      <BetTextField
        disabled={disabled}
        type="number"
        fullWidth
        value={betAmount}
        onChange={(event) => {
          if (!Number.isNaN(Number(event.target.value))) {
            setBetAmount(event.target.value);
            onChange(event.target.value);
          }
        }}
        onBlur={(event) => {
          if (
            viewInFiat.status === true &&
            storageCurrency?.equivalentUsdRate !== 0
          ) {
            if (
              toN(event.target.value) <
              Number(minBet * (storageCurrency?.equivalentUsdRate || 1))
            ) {
              setBetAmount(
                formatUSD(
                  Number(minBet * (storageCurrency?.equivalentUsdRate || 1)),
                ),
              );
              onChange(
                formatUSD(
                  Number(minBet * (storageCurrency?.equivalentUsdRate || 1)),
                ),
              );
              return;
            }

            setBetAmount(formatUSD(toN(event.target.value)));
            onChange(formatUSD(toN(event.target.value)));
          } else {
            if (toN(event.target.value) < minBet) {
              setBetAmount(formatAmount(minBet.toFixed(7)));
              onChange(formatAmount(minBet.toFixed(7)));
              return;
            }
            setBetAmount(formatAmount(toN(event.target.value)));
            onChange(formatAmount(toN(event.target.value)));
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton sx={{ p: 0 }}>
                <img
                  src={
                    viewInFiat.status === true &&
                    storageCurrency?.equivalentUsdRate !== 0
                      ? USD
                      : coinImages[storageCurrency?.shortName]
                  }
                  alt="coin"
                  width={20}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onFocus={(event) => {
          event.target.select();
        }}
      />
    </Stack>
  );
}

export default BetAmountInput;
