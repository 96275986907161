import { Select, styled } from '@mui/material';

export const SelectStyled = styled(Select)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.main,
  width: '100%',

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));
