import { ReactiveVar } from '@apollo/client';
import { Bet, LiveStatsData, LiveStatsReactiveVar } from 'graph';
import { DeepPartial } from 'react-hook-form';

export default (liveStatsVar: ReactiveVar<LiveStatsReactiveVar>) =>
  (
    bet: DeepPartial<Bet>,
    options?: { onCompleted: (liveStatsData: LiveStatsData) => void },
  ) => {
    const { betAmount, equivalentUsdRate, payoutRate } = bet;
    const {
      liveStats: {
        profits,
        winRounds,
        loseRounds,
        totalProfit,
        totalBetAmount,
      },
    } = liveStatsVar();

    const profitInUsd = betAmount * (payoutRate - 1) * equivalentUsdRate;

    // NOTE: use reference to array to reduce array allocation, usage of `profits` should care
    profits.push(totalProfit + profitInUsd);

    // betAmount, profit use usd rate
    const newLiveStatsData: LiveStatsData = {
      totalBetAmount: totalBetAmount + betAmount * equivalentUsdRate,
      winRounds: payoutRate > 0 ? winRounds + 1 : winRounds,
      loseRounds: payoutRate === 0 ? loseRounds + 1 : loseRounds,
      totalProfit: totalProfit + profitInUsd,
      profits: profits.length > 300 ? profits.splice(150) : profits,
    };

    liveStatsVar({
      liveStats: newLiveStatsData,
    });

    /* -------------------------------------------------------------------------- */
    if (options?.onCompleted) {
      setTimeout(() => {
        options.onCompleted(newLiveStatsData);
      }, 0);
    }
  };
