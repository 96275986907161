import { useReactiveVar } from '@apollo/client';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle-2.svg';
import currencyImages from 'assets/images/coins';
import {
  BlockchainNetwork,
  Currency,
  useCryptoDepositAddressLazyQuery,
} from 'graph';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { currenciesVar } from 'store';

import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { SelectCoinBox } from '../../styles';
import AssetsPortfolioDialog from '../AssetsPortfolioDialog';
import UnlockCodg from '../CODG/UnlockCodg/UnlockCodg';
import SelectCurrency from '../SelectCurrency';
import { DepositAddressBox, SelectNetWork } from './styles';

// -------------------------------------------------------------

export interface IDepositCryptoProps {
  selectedCurrency: Currency;
  handleSelectCurrency: (currency: Currency) => void;
  handleCloseWalletDialog: () => void;
  handleChangeWalletTab: (tabId: number) => void;
}

export function DepositCrypto({
  selectedCurrency,
  handleSelectCurrency,
  handleChangeWalletTab,
}: IDepositCryptoProps) {
  const [selectedNetwork, setSelectedNetwork] = useState<
    BlockchainNetwork | false
  >(false);
  const [depositAmount, setDepositAmount] = useState('0.0000000');
  const [isAssetPortfolioOpen, setIsAssetPortfolioOpen] = useState(false);
  const { currencies } = useReactiveVar(currenciesVar);
  const depositCurrencies = React.useMemo(
    () =>
      currencies
        .filter(
          (currency) =>
            currency.blockchainNetworks &&
            currency.blockchainNetworks.length > 0,
        )
        .map((currency: any) => ({
          ...currency,
          image: currencyImages[currency?.shortName],
        })),
    [currencies],
  );

  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { copy } = useCopyToClipboard();

  const [isUnlockCodgOpen, setIsUnlockCodgOpen] = useState(false);

  const [getCryptoDeposit, { data: depositAddressData }] =
    useCryptoDepositAddressLazyQuery();

  const handleChooseNetwork = (event: any) => {
    setSelectedNetwork(event.target.value);
  };

  React.useEffect(() => {
    if (selectedCurrency?.blockchainNetworks) {
      setSelectedNetwork(selectedCurrency.blockchainNetworks[0]);
    }
  }, [selectedCurrency]);

  React.useEffect(() => {
    const isValidNetwork = selectedCurrency?.blockchainNetworks?.some(
      (network) => network === selectedNetwork,
    );

    if (
      selectedCurrency?.id &&
      isValidNetwork &&
      selectedNetwork &&
      selectedNetwork !== BlockchainNetwork.None
    ) {
      getCryptoDeposit({
        variables: {
          currencyId: selectedCurrency?.id,
          network: selectedNetwork,
        },
      });
    }
  }, [selectedCurrency, selectedNetwork]);

  useEffect(() => {
    if (!selectedCurrency?.blockchainNetworks)
      handleSelectCurrency(depositCurrencies[0]);
  }, []);

  return (
    <Box width="100%">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { md: '3fr 1fr', sm: '1fr' },
          gridTemplateRows: { md: 'auto', sm: 'auto auto' },
          width: '100%',
          gap: '0.75rem',
        }}
      >
        <Box>
          <Typography className="label">{`${translate(
            'deposit_currency',
          )}`}</Typography>

          <SelectCoinBox>
            <SelectCurrency
              showInputAmount={false}
              amount={depositAmount}
              setAmount={setDepositAmount}
              selectedCurrency={selectedCurrency}
              setIsAssetPortfolioOpen={setIsAssetPortfolioOpen}
            />
          </SelectCoinBox>
        </Box>

        <Box>
          {selectedCurrency?.blockchainNetworks?.length && (
            <>
              <Typography
                className="label"
                sx={{ textAlign: { md: 'end', sm: 'start' } }}
              >{`${translate('network')}`}</Typography>

              <SelectNetWork
                className="network-option"
                options={selectedCurrency.blockchainNetworks.map((i) => ({
                  label: i,
                  value: i,
                }))}
                onChange={(e) => {
                  handleChooseNetwork(e);
                }}
                defaultValue={selectedCurrency.blockchainNetworks[0] || ''}
                value={selectedNetwork}
              />
            </>
          )}
        </Box>
      </Box>

      <DepositAddressBox>
        <Box className="deposit_address">
          <div className="address_string">
            <Typography className="deposit_address_typo">
              {depositAddressData?.cryptoDepositAddress?.address}
            </Typography>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                if (depositAddressData?.cryptoDepositAddress?.address) {
                  copy(depositAddressData?.cryptoDepositAddress?.address);
                  enqueueSnackbar(translate('copied'));
                }
              }}
            >
              <Copy width="20px" height="20px" />
            </IconButton>
          </div>
          <div className="address_qrcode">
            <QRCode
              id="qrcode"
              value={depositAddressData?.cryptoDepositAddress?.address || ''}
              size={290}
              level="H"
              includeMargin
            />
            <Box className="notice">
              <span>{`${translate('notice')}`}</span>
              <Typography>
                {`${translate('notice_content')}`.replace(
                  '/currency/',
                  selectedCurrency?.shortName?.toUpperCase(),
                )}
              </Typography>
            </Box>
          </div>
          <div className="bonus300">
            <Typography>
              {translate(
                'Earn an extra 300% bonus with a minimum deposit of 10.00 USD.',
              )}
            </Typography>
            <IconButton
              sx={{ p: 0.5 }}
              onClick={() => setIsUnlockCodgOpen(true)}
            >
              <InfoCircleIcon />
            </IconButton>
          </div>
        </Box>
      </DepositAddressBox>
      {isUnlockCodgOpen && (
        <UnlockCodg
          isOpen={isUnlockCodgOpen}
          handleClose={() => setIsUnlockCodgOpen(false)}
          handleDeposit={() => {
            setIsUnlockCodgOpen(false);
            handleChangeWalletTab(0);
          }}
        />
      )}
      {isAssetPortfolioOpen && (
        <AssetsPortfolioDialog
          isOpen={isAssetPortfolioOpen}
          currencies={depositCurrencies}
          selectedCurrency={selectedCurrency}
          handleSelectCurrency={handleSelectCurrency}
          handleClose={() => {
            // handleCloseWalletDialog();
            setIsAssetPortfolioOpen(false);
          }}
          handleBack={() => setIsAssetPortfolioOpen(false)}
        />
      )}
    </Box>
  );
}
