import { PATH_DASHBOARD } from 'routes/paths';

import { HELP_CENTER_PATHS } from '../../../routes/routesPage';

export const configFooter = [
  {
    section: 'game_hub',
    items: [
      {
        key: 'crash',
        path: '/casino/original/crash',
      },
      {
        key: 'dice',
        path: '/casino/original/dice',
      },
      {
        key: 'mines',
        path: '/casino/original/mines',
      },
      {
        key: 'mochi monster',
        path: '/casino/original/mochimonster',
      },
      {
        key: 'crypto quest',
        path: '/casino/original/cryptoquest',
      },
      {
        key: 'mini poker',
        path: '/casino/original/minipoker',
      },
      {
        key: 'bit farm',
        path: '/casino/original/bitfarm',
      },
      {
        key: 'limbo',
        path: '/casino/original/limbo',
      },
      {
        key: 'baccarat',
        path: '/casino/original/baccarat',
      },
    ],
  },
  {
    section: 'promotions',
    items: [
      {
        key: 'vip_club',
        path: PATH_DASHBOARD.vipClub.root,
      },
      {
        key: 'affiliate',
        path: PATH_DASHBOARD.affiliate.root,
      },
    ],
  },
  {
    section: 'support_legal',
    items: [
      {
        key: 'terms_of_service',
        path: HELP_CENTER_PATHS.TERMS_SERVICE,
      },
      {
        key: 'AML',
        path: HELP_CENTER_PATHS.AML,
      },
      {
        key: 'responsible_gambling',
        path: HELP_CENTER_PATHS.RES_GAMBLING,
      },
    ],
  },
  {
    section: 'about_us',
    items: [
      {
        key: 'business_contact',
        path: 'coming-soon',
      },
    ],
  },
];
