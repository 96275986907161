import Snackbar from 'components/snackbar/Snackbar';
import useResponsive from 'hooks/useResponsive';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import React from 'react';

/* -------------------------------------------------------------------------- */

const SnackbarProvider = ({ children }: { children: React.ReactChild }) => {
  const isMobile = useResponsive('down', 'sm');

  return (
    <NotistackSnackbarProvider
      Components={{
        error: Snackbar,
        success: Snackbar,
        default: Snackbar,
      }}
      autoHideDuration={5000}
      maxSnack={3}
      {...(isMobile && {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default React.memo(SnackbarProvider);
