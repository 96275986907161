import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconEyeSlash, IconSuccess } from 'assets/icons';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import FormProvider, { RHFTextField } from 'components/hook-form';
import ClickTooltip from 'components/Tooltip/ClickTooltip';
import ValidationMessage from 'components/ValidationMessage';
import { emailRegex } from 'constants/regex';
import {
  useSetHiddenProfileMutation,
  useUpdateAvatarMutation,
  useUpdateEmailMutation,
  useUpdateNickNameMutation,
  useUserInfoQuery,
  useUserProfileQuery,
} from 'graph';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useLocales from 'locales/useLocales';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { updateCacheUserInfo } from 'store/operations/update-cache-opts/updateCacheUserInfo';
import { updateCacheUserProfile } from 'store/operations/update-cache-opts/updateCacheUserProfile';
import { getImageCompression } from 'utils/compression';
import * as Yup from 'yup';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';

import {
  AvatarBox,
  GeneralContentGrid,
  HideProfileRatioBox,
  NicknameBox,
  VerifyEmailBox,
} from './styles_v2';
import VerifyEmailDialog from './VerifyEmailDialog';
import { VerifyPhoneNumberDialog } from './VerifyPhoneNumberDialog';

/* -------------------------------------------------------------------------- */

const V2General = () => {
  const { translate } = useLocales();
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = React.useState(false);
  const [isVerifyPhoneNumOpen, setIsVerifyPhoneNumOpen] = React.useState(false);
  const [
    updateNickname,
    { data: updateNicknameData, loading: isUpdatingNickname },
  ] = useUpdateNickNameMutation();
  const [updateEmail, { loading: isUpdatingEmail }] = useUpdateEmailMutation();
  const { data: userProfileData } = useUserProfileQuery({
    fetchPolicy: 'cache-only',
  });
  const { data: userInfoData } = useUserInfoQuery({
    fetchPolicy: 'cache-only',
  });

  const [hiddenProfile, { loading: isHidingProfile }] =
    useSetHiddenProfileMutation();
  const [updateAvatarMutation] = useUpdateAvatarMutation();

  const isVerifiedEmail = userProfileData?.me?.profile.emailVerified;

  const isHideProfile = userProfileData?.me?.profile.hidden;

  const nicknameForm = useForm<{ nickname: string }>({
    defaultValues: {
      nickname: userInfoData?.me?.user?.nickname || '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        nickname: Yup.string()
          .min(4, translate('validate_nickname_length'))
          .max(13, translate('validate_nickname_length'))
          .test('nickname', translate('validate_nickname_message'), (value) => {
            if (value.match(/^[A-Za-z0-9]+$/)) {
              return true;
            }
            return false;
          })
          .required(null),
      }),
    ),
    mode: 'onChange',
  });

  nicknameForm.watch();

  const emailForm = useForm<{ email: string }>({
    defaultValues: {
      email: userProfileData?.me?.profile.email || '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .matches(emailRegex, translate('validate_invalid_email_address'))
          .required(null)
          .trim(),
      }),
    ),
    mode: 'onSubmit',
  });

  const handleCopyToClipboard = (copyText) => {
    copy(copyText);
    enqueueSnackbar(translate('copied'));
  };

  const sendVerifyEmail = async () => {
    const data = emailForm.getValues();
    setIsVerifyEmailOpen(false);

    if (data?.email && !isUpdatingEmail) {
      try {
        const email = data.email.trim();
        const response = await updateEmail({
          variables: {
            email,
          },
        });
        if (response.data?.updateEmail.success) {
          updateCacheUserProfile({ ...response.data?.updateEmail?.content });
          enqueueSnackbar(translate('verify_email_was_send'), {
            variant: 'success',
          });
          emailForm.reset({ email });
          return;
        }
        if (response?.data?.updateEmail?.error) {
          emailForm.setError('email', {
            message:
              translate(
                `error_code.${response?.data?.updateEmail?.error?.code}`,
              ) || response?.data?.updateEmail?.error?.message,
          });
        }
      } catch (e) {
        enqueueSnackbar(translate('something_wrong'), { variant: 'error' });
      }
    }
  };

  const handleChangeNickname = async (data) => {
    if (data?.nickname && !isUpdatingNickname) {
      const nickname = data.nickname.trim();
      const response = await updateNickname({
        variables: {
          nickname,
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        },
      });
      if (response.data?.updateNickname.success) {
        updateCacheUserInfo({
          nickname,
        });
        enqueueSnackbar(translate('new_nick_name_saved'), {
          variant: 'success',
        });
        return;
      }
      enqueueSnackbar(translate('update_nickname_failed'), {
        variant: 'error',
      });
    }
  };

  const handleChangeHideProfileStatus = async (checked) => {
    if (checked !== userProfileData?.me?.profile.hidden && !isHidingProfile) {
      const response = await hiddenProfile({
        variables: {
          isHidden: checked,
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        },
      });
      if (response.data?.setHidden.success) {
        updateCacheUserProfile({
          ...response.data?.setHidden?.content,
        });
        enqueueSnackbar(
          `${translate('your_profile')} ${
            response.data.setHidden.content.hidden
              ? translate('is_hidden')
              : translate('is_public_now')
          }`,
          { variant: 'success' },
        );
        return;
      }
      enqueueSnackbar(translate('hide_profile_failed'), { variant: 'error' });
    }
  };

  const handleSubmitVerifyEmail = () => {
    emailForm.handleSubmit(() => {
      setIsVerifyEmailOpen(true);
    })();
  };

  const handleSelectAvatar = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        const compressAvatar = await getImageCompression(file);

        if (compressAvatar.size / 1024 / 1024 > 4) {
          enqueueSnackbar(translate('maximumFileSize'), { variant: 'error' });
          return;
        }

        const response = await updateAvatarMutation({
          variables: {
            file: compressAvatar,
          },
        });

        if (response?.data?.updateAvatar?.success) {
          enqueueSnackbar(translate('update_avatar_success'), {
            variant: 'success',
          });
        }
      }
    } catch (_) {
      enqueueSnackbar(translate('update_avatar_failed'), { variant: 'error' });
    }
  };

  React.useEffect(() => {
    if (userProfileData && userInfoData) {
      nicknameForm.reset({ nickname: userInfoData?.me?.user.nickname });
      emailForm.reset({ email: userProfileData?.me?.profile.email });
    }
  }, [userInfoData, userProfileData]);

  return (
    <Box id="general-settings" display="flex" flexDirection="column" gap="12px">
      <GeneralContentGrid
        id="profile-info"
        spacing={3}
        container
        display="flex"
        flexDirection="column"
        sx={{
          margin: ' 0 !important',
          width: '100%',
        }}
      >
        <Typography variant="h6" color="white">
          Profile Info
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '0.75rem',
            marginTop: '12px',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          <AvatarBox>
            <Badge
              className="avatar-badge"
              sx={{ zIndex: 0 }}
              componentsProps={{ badge: { className: 'upload-btn' } }}
              badgeContent={
                <Button component="label">
                  <Typography
                    sx={{
                      padding: '3px 12px',
                      backgroundColor: '#161432',
                      color: '#9998D4',
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                    }}
                  >
                    Upload
                    <input
                      type="file"
                      alt="avatar_images"
                      accept="image/jpeg,image/png"
                      hidden
                      max={10000000}
                      onChange={handleSelectAvatar}
                    />
                  </Typography>
                </Button>
              }
              overlap="circular"
            >
              <Avatar
                className="avatar"
                src={userInfoData?.me?.user?.avatar?.square}
                sx={{ borderRadius: '36px', width: '100px', height: '100px' }}
              />
            </Badge>
            <Box display="flex" alignItems="center" gap="12px">
              <Typography fontSize={{ sm: 16, xs: 14 }}>
                {userInfoData?.me?.user.id}
              </Typography>
              <CopyIcon
                width="20px"
                height="20px"
                className="copy_btn"
                onClick={() => handleCopyToClipboard(userInfoData?.me?.user.id)}
              />
            </Box>
          </AvatarBox>
          <Grid container item>
            <NicknameBox item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="body1"
                className="label"
                color="white"
              >{`${translate('nickname')}:`}</Typography>
              <FormProvider
                methods={nicknameForm}
                onSubmit={nicknameForm.handleSubmit(handleChangeNickname)}
              >
                <Grid
                  container
                  display="grid"
                  item
                  alignItems="center"
                  gap="12px"
                  sx={{
                    gridTemplateColumns: { xs: 'auto', lg: 'auto 150px' },
                  }}
                >
                  <Grid item padding="0 !important">
                    <RHFTextField
                      fullWidth
                      name="nickname"
                      className="nickname"
                      placeholder={translate('enter_nickname')}
                    />
                  </Grid>
                  <Grid className="button_grid" item padding="0 !important">
                    <Button
                      fullWidth
                      variant="contained"
                      className="change_nickname_btn"
                      type="submit"
                      sx={{
                        borderRadius: (theme) =>
                          `${theme.shape.borderRadius}px`,
                        background:
                          nicknameForm.formState.isDirty &&
                          nicknameForm.formState.isValid &&
                          'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
                        color: '#2E2C5E',
                        backgroundColor:
                          (!nicknameForm.formState.isDirty ||
                            !nicknameForm.formState.isValid) &&
                          'rgba(145, 158, 171, 0.24)',
                        width: { xs: '100%', lg: '150px' },
                      }}
                      disabled={
                        !nicknameForm.formState.isDirty ||
                        !nicknameForm.formState.isValid
                      }
                    >
                      {translate('change')}
                    </Button>
                  </Grid>
                  {nicknameForm.formState.errors?.nickname && (
                    <ValidationMessage
                      message={nicknameForm.formState.errors.nickname?.message}
                    />
                  )}
                  {updateNicknameData &&
                    !updateNicknameData?.updateNickname && (
                      <ValidationMessage
                        message={translate('nickname_existing')}
                      />
                    )}
                </Grid>
              </FormProvider>
              <Typography variant="body2" mt={1.5} whiteSpace="pre-wrap">
                {translate('not_use_symbol')}
              </Typography>
            </NicknameBox>
            <HideProfileRatioBox item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isHideProfile || false}
                    disabled={isHidingProfile}
                  />
                }
                label={translate('hide_profile')}
                onChange={(_, checked) => {
                  handleChangeHideProfileStatus(checked);
                }}
              />
              <ClickTooltip
                title={
                  <>
                    <Box display="flex" alignItems="center">
                      <IconEyeSlash
                        style={{
                          width: 16,
                          height: 16,
                        }}
                      />
                      <Typography
                        variant="subtitle1"
                        ml={1}
                        sx={{ color: 'text.contrast' }}
                      >
                        {translate('hide_profile_title')}
                      </Typography>
                    </Box>
                    <Typography whiteSpace="pre-wrap">
                      {translate('hide_profile_content')}
                    </Typography>
                  </>
                }
              >
                <InfoRoundedIcon />
              </ClickTooltip>
            </HideProfileRatioBox>
          </Grid>
        </Box>
      </GeneralContentGrid>
      <GeneralContentGrid
        id="contact-info"
        spacing={3}
        container
        sx={{
          margin: ' 0 !important',
          width: '100%',
        }}
      >
        <Grid
          container
          item
          gap="12px"
          sx={{ padding: '0 !important', flexDirection: 'column' }}
        >
          <Typography variant="h6" color="white">
            Contact Info
          </Typography>
          <VerifyEmailBox item>
            <Typography variant="body1" className="label">{`${translate(
              'email',
            )}:`}</Typography>
            <FormProvider methods={emailForm}>
              <Grid
                display="grid"
                container
                gap="12px"
                width="100%"
                flexWrap="nowrap"
                sx={{
                  gridTemplateColumns: { xs: 'auto', lg: 'auto 150px' },
                }}
              >
                <Grid item>
                  <RHFTextField
                    fullWidth
                    className="email"
                    name="email"
                    InputProps={
                      isVerifiedEmail && {
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              '&&&:hover svg': {
                                path: {
                                  fill: (theme) => theme.palette.primary.main,
                                },
                              },
                            }}
                            position="end"
                          >
                            <IconSuccess
                              sx={{
                                width: 16,
                                height: 16,
                              }}
                            />
                            <Typography ml={0.5} color="primary">
                              {translate('verified')}
                            </Typography>
                          </InputAdornment>
                        ),
                      }
                    }
                    disabled={isVerifiedEmail}
                    placeholder={translate('enter_email')}
                  />
                  <ErrorMessage
                    name="email"
                    errors={emailForm.formState.errors}
                    render={({ message }) => (
                      <ValidationMessage mt={1} ml={0} message={message} />
                    )}
                  />
                </Grid>
                {!isVerifiedEmail && (
                  <Grid className="button_grid" alignItems="flex-end" item>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="verify_email_btn"
                      onClick={() => handleSubmitVerifyEmail()}
                      sx={{
                        width: { xs: '100%', lg: '150px' },
                        borderRadius: (theme) =>
                          `${theme.shape.borderRadius}px`,
                        background:
                          'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
                        color: '#2E2C5E',
                      }}
                    >
                      {translate('verify')}
                    </Button>
                  </Grid>
                )}
              </Grid>

              <Typography
                variant="body2"
                ml={1.5}
                mt={1.5}
                whiteSpace="pre-wrap"
              >
                {translate('registered_mail_cannot_changed')}
              </Typography>
            </FormProvider>
          </VerifyEmailBox>
        </Grid>
      </GeneralContentGrid>

      {isVerifyEmailOpen && (
        <VerifyEmailDialog
          isOpen={isVerifyEmailOpen}
          handleClose={() => setIsVerifyEmailOpen(false)}
          handleConfirm={sendVerifyEmail}
        />
      )}
      {isVerifyPhoneNumOpen && (
        <VerifyPhoneNumberDialog
          isOpen={isVerifyPhoneNumOpen}
          handleClose={() => setIsVerifyPhoneNumOpen(false)}
        />
      )}
    </Box>
  );
};

export default V2General;
