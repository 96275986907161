import { SvgIcon } from '@mui/material';

export function IconDice1(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 7.66496C3 10.2622 3 17.7112 3 17.7112C3 18.1612 3.26498 18.5701 3.67614 18.7551L11.2919 22.1495C11.6688 22.3186 12.096 22.0422 12.096 21.6287V11.2124C12.096 10.9863 11.9635 10.783 11.7579 10.6916L3.80406 7.14186C3.42487 6.97511 3 7.2515 3 7.66496ZM7.30357 12.7794C7.79697 12.7475 8.23783 13.3871 8.29037 14.2071C8.34293 15.0271 7.9843 15.717 7.49088 15.7467C6.99747 15.7787 6.55661 15.1391 6.50407 14.319C6.45153 13.499 6.81016 12.8091 7.30357 12.7794Z"
        fill="#fff"
      />
      <path
        d="M21.9892 17.7089C21.9892 17.7089 21.9892 10.2599 21.9892 7.6627C21.9892 7.24925 21.562 6.97285 21.1851 7.14189L13.2336 10.6916C13.028 10.783 12.8955 10.9886 12.8955 11.2125V21.6287C12.8955 22.0422 13.3227 22.3186 13.6996 22.1495L21.3153 18.7528C21.7242 18.5701 21.9892 18.1612 21.9892 17.7089ZM16.7034 17.4348C16.6509 18.2548 16.21 18.8922 15.7166 18.8625C15.2232 18.8305 14.8669 18.1406 14.9171 17.3229C14.9696 16.5028 15.4105 15.8655 15.9039 15.8952C16.3996 15.9272 16.7559 16.617 16.7034 17.4348ZM20.0796 11.6465C20.027 12.4665 19.5861 13.1038 19.0928 13.0741C18.5994 13.0422 18.243 12.3523 18.2933 11.5345C18.3458 10.7145 18.7867 10.0772 19.2801 10.1069C19.7734 10.1366 20.1321 10.8264 20.0796 11.6465Z"
        fill="#fff"
      />
      <path
        d="M4.20175 6.44519L12.2629 10.0406C12.4114 10.1069 12.5805 10.1069 12.7289 10.0406L20.7901 6.44519C21.247 6.24189 21.2401 5.59087 20.7787 5.39671L12.9391 2.08909C12.7975 2.0297 12.6467 2 12.496 2C12.3452 2 12.1944 2.0297 12.0528 2.08909L4.21091 5.39671C3.75174 5.59087 3.74489 6.24189 4.20175 6.44519ZM16.8452 4.97641C17.6652 4.97184 18.3323 5.37158 18.3345 5.86727C18.3368 6.36296 17.6744 6.76956 16.8543 6.77184C16.0343 6.77641 15.3673 6.37666 15.365 5.88098C15.3627 5.38529 16.0252 4.97869 16.8452 4.97641ZM12.4914 4.97641C13.3114 4.97184 13.9784 5.37158 13.9807 5.86727C13.983 6.36296 13.3206 6.76956 12.5005 6.77184C11.6805 6.77641 11.0134 6.37666 11.0112 5.88098C11.0089 5.38529 11.6713 4.97869 12.4914 4.97641ZM8.13757 4.97641C8.9576 4.97184 9.62463 5.37158 9.62689 5.86727C9.6292 6.36296 8.96676 6.76956 8.14668 6.77184C7.32663 6.77413 6.65962 6.37666 6.65734 5.88098C6.65505 5.38529 7.31749 4.97869 8.13757 4.97641Z"
        fill="#fff"
      />
    </SvgIcon>
  );
}
