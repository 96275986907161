import LoadingAnimated from 'components/LoadingAnimated';
import AccountNotConditions from 'components/WalletDialog/components/Withdraw/AccountNotConditions';
import { ASSETS_TABS } from 'constants/index';
import { Currency, useLoyalUserQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { enqueueSnackbar } from 'notistack';
import GeneralInfoUploadingDialog from 'pages/ProfileSettings/components/Verify/GeneralInfoUploading/GeneralInfoUploadingDialog';
import { useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { SubtabWrapper } from '../../styles';
import WalletDialogHeader from '../WalletDialogHeader';
import { WithdrawWrapper } from './styles';
import { WithdrawCrypto } from './WithdrawCrypto';
import { WithdrawPi } from './WithdrawPi';

// ----------------------------------------------------------

interface Props {
  setIsWalletDialogHidden: (hidden: boolean) => void;
  handleCloseWallet: VoidFunction;
  selectedCurrency: Currency;
  handleSelectCurrency: (currency: Currency | null) => void;
  isAccountNotVerified: boolean;
  selectedSubTab?: {
    id: number;
    label: string;
    icon: JSX.Element;
  };
  subTab?: Array<{
    id: number;
    label: string;
    icon: JSX.Element;
  }>;
  handleSelectSubTab: (e) => void;
}

const Withdraw = ({
  setIsWalletDialogHidden,
  handleCloseWallet,
  selectedCurrency,
  handleSelectCurrency,
  isAccountNotVerified,
  selectedSubTab,
  subTab,
  handleSelectSubTab,
}: Props) => {
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');
  const [isKycStepOneDialogOpen, setIsKycStepOneDialogOpen] = useState(false);
  const { data: loyalUser, loading } = useLoyalUserQuery({
    fetchPolicy: 'network-only',
  });

  const isValidWagerCondition = useMemo(() => {
    if (!loyalUser || loading) return false;

    return (
      Number(loyalUser?.me?.vipUser?.betAmountUsd) >=
      Number(loyalUser?.me?.withdrawalRequiredBetAmountUsd)
    );
  }, [loyalUser, loading]);

  const onAccountNotVerified = () => {
    setIsKycStepOneDialogOpen(true);
    enqueueSnackbar(translate('must_kyc_step_1_to_withdrawal'), {
      variant: 'error',
    });
  };

  return (
    <WithdrawWrapper>
      {isTablet && (
        <SubtabWrapper>
          {subTab.map((e) => (
            <Box
              key={e.id}
              className="sub_tab_container"
              sx={{
                background:
                  selectedSubTab.label === e.label &&
                  'linear-gradient(180deg, #313064 0%, #43416C 100%)',
                color: selectedSubTab.label === e.label && '#FFF',
              }}
              onClick={() => handleSelectSubTab(e)}
            >
              <Box
                className={`sub_tab ${
                  selectedSubTab.label === e.label && `subtab_icon_${e.label}`
                }`}
              >
                {e.icon}
              </Box>

              <Typography component="span">{e.label}</Typography>
            </Box>
          ))}
        </SubtabWrapper>
      )}
      {!isMobile && !isTablet && (
        <WalletDialogHeader handleClose={handleCloseWallet} title="withdraw" />
      )}

      {loading && <LoadingAnimated />}

      {!isValidWagerCondition && !loading && (
        <AccountNotConditions
          wager={Number(loyalUser?.me?.vipUser?.betAmountUsd)}
          wagerRequre={Number(loyalUser?.me?.withdrawalRequiredBetAmountUsd)}
          handleClose={handleCloseWallet}
        />
      )}

      {isValidWagerCondition &&
        Number(loyalUser?.me?.vipUser?.betAmountUsd) >=
          Number(loyalUser?.me?.withdrawalRequiredBetAmountUsd) &&
        selectedSubTab.label === ASSETS_TABS.CRYPTO.name && (
          <WithdrawCrypto
            setIsWalletDialogHidden={setIsWalletDialogHidden}
            handleCloseWallet={handleCloseWallet}
            selectedCurrency={selectedCurrency}
            handleSelectCurrency={handleSelectCurrency}
            isAccountNotVerified={isAccountNotVerified}
            onAccountNotVerified={onAccountNotVerified}
          />
        )}
      {isValidWagerCondition &&
        selectedSubTab.label === ASSETS_TABS.PI.name && (
          <WithdrawPi
            isAccountNotVerified={isAccountNotVerified}
            onAccountNotVerified={onAccountNotVerified}
          />
        )}

      {isKycStepOneDialogOpen && (
        <GeneralInfoUploadingDialog
          open={isKycStepOneDialogOpen}
          onClose={() => setIsKycStepOneDialogOpen(false)}
        />
      )}
    </WithdrawWrapper>
  );
};

export default Withdraw;
