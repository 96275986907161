import { SvgIcon } from '@mui/material';

export function IconVisibility(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
        fill="#858997"
      />
      <path
        d="M12.0004 9.13965C10.4304 9.13965 9.15039 10.4196 9.15039 11.9996C9.15039 13.5696 10.4304 14.8496 12.0004 14.8496C13.5704 14.8496 14.8604 13.5696 14.8604 11.9996C14.8604 10.4296 13.5704 9.13965 12.0004 9.13965Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
