import { GameDocument, GameQuery } from 'graph';
import { cache } from 'graph/cache';
import lodash from 'lodash';

export const updateCacheGame = (gameQuery: GameQuery) => {
  const currentCachedGame: GameQuery = cache.readQuery({
    query: GameDocument,
    variables: {
      slug: gameQuery?.game?.game?.slug,
      currencyId: gameQuery?.game?.gameFund?.gameBank?.currency.id,
    },
  });

  if (gameQuery?.game?.id && cache.modify) {
    cache.modify({
      id: cache.identify(currentCachedGame.game),
      fields: {
        ...lodash.merge(currentCachedGame, gameQuery.game),
      } as any,
    });
  }
};
