import { BET_BUTTON_STATE } from 'pages/Games/types';
import { memo } from 'react';

import { Stack, Typography } from '@mui/material';

import { BetButton } from './styles';

interface PlayButtonProps {
  buttonState: BET_BUTTON_STATE;
  caption: string;
  subCaption: string;
  onClick?: () => void;
}

function PlayButton({
  buttonState,
  caption,
  subCaption,
  onClick,
}: PlayButtonProps) {
  return (
    <BetButton status={buttonState} onClick={onClick}>
      <Stack direction="column">
        <Typography fontWeight="900" fontSize={18} sx={{ width: 100 }}>
          {caption}
        </Typography>
        {subCaption && (
          <Typography fontWeight="700" fontSize={14} sx={{ width: 100 }}>
            {subCaption}
          </Typography>
        )}
      </Stack>
    </BetButton>
  );
}

export default memo(PlayButton);
