import imageCompression, { Options } from 'browser-image-compression';

export const getImagesCompression: (
  files: File[],
  opts?: Options,
) => Promise<File[]> = async (
  files,
  opts = {
    maxSizeMB: 4,
    maxWidthOrHeight: 1024,
  },
) => {
  try {
    const compressImages = await Promise.all(
      files.map(async (file) => {
        if (!file) return null;
        return imageCompression(file, opts);
      }),
    );

    return compressImages;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getImageCompression: (
  file: File,
  opts?: Options,
) => Promise<File> = async (
  file,
  opts = {
    maxSizeMB: 4,
    maxWidthOrHeight: 1024,
  },
) => {
  try {
    return await imageCompression(file, opts);
  } catch (e: any) {
    throw new Error(e);
  }
};
