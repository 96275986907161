import {
  closeSnackbar,
  CustomContentProps,
  SnackbarKey,
  VariantType,
} from 'notistack';
import React, { memo } from 'react';

import { CircularProgress } from '@mui/material';
import Box from '@mui/system/Box';

import { StyledMessage, StyledSnackbar } from './styles';

/* -------------------------------------------------------------------------- */
interface ICustomCircularProgressProps {
  snackbarId: SnackbarKey;
  variant: VariantType;
  autoHideDuration: number;
}

const CustomCircularProgress = memo((props: ICustomCircularProgressProps) => {
  const [progress, setProgress] = React.useState(0);
  const autoHideDuration = props?.autoHideDuration || 5000;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 10000 / autoHideDuration;
        }
        closeSnackbar(props.snackbarId);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <CircularProgress
      variant="determinate"
      thickness={6}
      value={Math.floor(progress)}
      {...(props.variant === 'error' && { color: 'error' })}
    />
  );
});

/* -------------------------------------------------------------------------- */

interface CustomSnackbarProps extends CustomContentProps {
  message: string | React.ReactElement;
}

const Snackbar = React.forwardRef<any, CustomSnackbarProps>(
  ({ message = '', id, ...props }, ref) => (
    <StyledSnackbar
      {...props}
      ref={ref}
      onClick={() => {
        closeSnackbar(id);
      }}
    >
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <StyledMessage
            $variant={props.variant}
            fontWeight={700}
            variant="subtitle2"
          >
            {message}
          </StyledMessage>
        </Box>
        <CustomCircularProgress
          snackbarId={id}
          autoHideDuration={props?.autoHideDuration}
          variant={props.variant}
        />
      </Box>
    </StyledSnackbar>
  ),
);

export default React.memo(Snackbar);
