import { about_codg_bg } from 'assets/images/codg';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const AssetPopoverWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 1.5, 0),
  backgroundColor: theme.palette.background.secondary,
}));

export const SearchBarBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1.25),

  input: {
    fontSize: '14px !important',
  },
}));

export const CurrenciesBox = styled(Box)(() => ({
  height: 450,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}));

export const ViewInFiatBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  padding: theme.spacing(0, 1.25, 2),
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '.MuiFormControlLabel-label': {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.text.contrast,
  },
}));

export const AboutCodgBg = styled(Box)(({ theme }) => ({
  width: '100%',
  background: `url(${about_codg_bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center center',
  aspectRatio: '553/170',
  padding: theme.spacing(6.25, 3),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down(450)]: {
    padding: theme.spacing(4.5, 2.5),
    marginBottom: 0,
  },

  h4: {
    fontSize: 34,
    fontWeight: 700,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 28,
    },
  },

  span: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    [theme.breakpoints.down(450)]: {
      fontSize: 12,
    },
  },
}));
