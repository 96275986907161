import { useReactiveVar } from '@apollo/client';
import { IconDollar } from 'assets/icons';
import { SearchDocument } from 'assets/icons/SearchDocument';
import currencyImages from 'assets/images/coins';
import { DialogAnimate } from 'components/animate';
import {
  CloseBox,
  DialogBody,
  DialogHeader,
  DialogWrapper,
} from 'components/Dialog/styles';
import Iconify from 'components/iconify';
import LoadingAnimated from 'components/LoadingAnimated';
import { format } from 'date-fns';
import {
  InternalTransfer,
  InternalTransferStatus,
  useUserInfoQuery,
} from 'graph';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { viewInFiatVar } from 'store';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import {
  StyledTableCell,
  StyledTableHeaderSticker,
  StyledTableRow,
  TransactionTableBox,
} from './styles';

// ---------------------------------------------------------------------

interface PropsType {
  rows: InternalTransfer[];
  handleLoadMore: (event) => void;
  loading: boolean;
}

export interface DetailVerificationProps {
  open: boolean;
  handleClose: () => void;
  data: InternalTransfer;
  userId: string;
}

const DetailInternalTransaction = (props: DetailVerificationProps) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const { open, handleClose, data, userId } = props;
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const detailTranfer = [
    {
      head: 'status',
      data: data.status === InternalTransferStatus.Completed && (
        <Typography color="#22FF45">{translate('tip_successful')}</Typography>
      ),
    },
    {
      head: 'order_id',
      data: data.id,
    },
    {
      head:
        userId !== data.receiverId ? 'receiver_nickname' : 'sender_nickname',
      data:
        userId !== data.receiverId
          ? data.receiverNickname
          : data.senderNickname,
    },
    {
      head: 'currency',
      data: (
        <Typography textTransform="uppercase">
          {data.currency.shortName}
        </Typography>
      ),
    },
    {
      head: 'quantity',
      data: (
        <Typography textTransform="uppercase">
          {getCurrencyAmount(
            data.amount - data.fee,
            { ...data.currency, equivalentUsdRate: data.equivalentUsdRate },
            {
              onlyCurrency: true,
            },
          )}{' '}
          {data.currency.shortName}
        </Typography>
      ),
    },
    {
      head: 'fees',
      data: userId !== data.receiverId && (
        <Typography textTransform="uppercase">
          {getCurrencyAmount(
            data.fee,
            { ...data.currency, equivalentUsdRate: data.equivalentUsdRate },
            { onlyCurrency: true },
          )}{' '}
          {data.currency.shortName}
        </Typography>
      ),
    },
    {
      head: 'message',
      data: (
        <Typography
          whiteSpace="normal"
          maxWidth="100%"
          sx={{
            textOverflow: 'unset',
            wordWrap: 'break-word',
          }}
        >
          {data.message}
        </Typography>
      ),
    },
    {
      head: 'time',
      data: format(new Date(data.createdAt), 'MM/dd/yyyy h:mm:ss aa'),
    },
  ];

  return (
    <DialogAnimate
      open={open}
      onClose={handleClose}
      sx={{
        width: 633,
        justifyContent: 'center',
        '&': {
          maxHeight: 850,
        },
        [theme.breakpoints.down(600)]: {
          maxHeight: 'unset',
          maxWidth: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
    >
      <DialogWrapper>
        <DialogHeader
          hasCloseBtn
          backgroundHeader="#11141E"
          sx={{
            justifyContent: 'center',
          }}
        >
          <Typography className="dialog-title" variant="h6">
            {translate('detail')}
          </Typography>
          <CloseBox key="close-button">
            <IconButton className="close-btn" onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </CloseBox>
        </DialogHeader>
        <DialogBody
          sx={{
            overflowY: 'hidden',
            background: '#11141E',
          }}
        >
          {/* <Typography display="flex" justifyContent="center">
            View Transaction History on{' '}
            <Typography color="#00B8D9" ml={0.5}>
              Etherscan
            </Typography>
            .
          </Typography> */}

          {data &&
            detailTranfer.map((row) => (
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                key={uniqueId()}
                my={3}
              >
                <Box width="30%">
                  <Typography fontSize="14px">{translate(row.head)}</Typography>
                </Box>
                <Box width="70%">
                  <Typography
                    sx={{
                      color: 'text.contrast',
                      '&:first-letter': {
                        textTransform: 'unset',
                      },
                    }}
                    variant="inherit"
                    fontSize="14px"
                  >
                    {row.data}
                  </Typography>
                </Box>
              </Box>
            ))}
        </DialogBody>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export const TipsTransactionsTable = ({
  rows,
  handleLoadMore,
  loading,
}: PropsType) => {
  const { translate } = useLocales();
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const [isOpenDetailTranfer, setIsOpenDetailTranfer] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useResponsive('up', 'md');

  const { data: userInfoData } = useUserInfoQuery({
    fetchPolicy: 'cache-only',
  });

  const userId = userInfoData?.me?.user?.id;

  const handleCopyBetId = (betId: string) => {
    copy(betId);
    enqueueSnackbar(translate('copied'));
  };

  return (
    <>
      <TransactionTableBox>
        <StyledTableHeaderSticker>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" width={isDesktop ? '30%' : '45%'}>
                  {translate('type')}/{translate('time')}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  width={isDesktop ? '40%' : '45%'}
                >
                  {translate('amount')}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  width={isDesktop ? '30%' : '10%'}
                >
                  {translate('detail')}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </StyledTableHeaderSticker>
        <TableContainer
          sx={{
            borderTopLeftRadius: '0px !important',
            borderTopRightRadius: '0px !important',
          }}
          onScroll={handleLoadMore}
        >
          <Table>
            <TableBody>
              {rows &&
                rows.map(({ equivalentUsdRate, ...row }) => (
                  <StyledTableRow key={uniqueId()}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      width={isDesktop ? '30%' : '45%'}
                    >
                      <Box>
                        <Typography
                          sx={{ color: 'text.contrast' }}
                          display="flex"
                        >
                          {userId === row.receiverId
                            ? row.senderNickname
                            : row.receiverNickname}{' '}
                          (ID:{' '}
                          {userId === row.receiverId
                            ? row.senderId
                            : row.receiverId}
                          )
                          <Iconify
                            icon="bxs:copy"
                            width={16}
                            ml={1}
                            onClick={() =>
                              handleCopyBetId(
                                userId === row.receiverId
                                  ? row.receiverId
                                  : row.senderId,
                              )
                            }
                            sx={{
                              ':hover': {
                                cursor: 'pointer',
                              },
                            }}
                          />
                        </Typography>
                        <div>
                          <span>
                            {format(
                              new Date(row.createdAt),
                              'MM/dd/yyyy h:mm:ss aa',
                            )}
                          </span>
                        </div>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{ color: 'text.contrast' }}
                      width={isDesktop ? '30%' : '45%'}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography
                          color={
                            userId === row.receiverId
                              ? 'success.main'
                              : 'error.main'
                          }
                        >
                          {getCurrencyAmount(row.amount - row.fee, {
                            ...row.currency,
                            equivalentUsdRate,
                          })}
                        </Typography>
                        {viewInFiat.status === true &&
                        row.currency.equivalentUsdRate !== 0 ? (
                          <IconDollar />
                        ) : (
                          <img
                            style={{
                              width: '22px',
                              height: '22px',
                              marginLeft: '10px',
                            }}
                            className="coin_img"
                            src={currencyImages[row.currency.shortName]}
                            alt={row.currency.name}
                          />
                        )}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{ color: 'text.contrast' }}
                      width={isDesktop ? '30%' : '5%'}
                    >
                      <SearchDocument
                        onClick={() => {
                          setIsOpenDetailTranfer(true);
                          setSelectedData(row);
                        }}
                        sx={{
                          ':hover': {
                            cursor: 'pointer',
                          },
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {loading && (
                <TableRow>
                  <StyledTableCell colSpan={4}>
                    <LoadingAnimated />
                  </StyledTableCell>
                </TableRow>
              )}
              {!rows?.length && !loading && (
                <TableRow>
                  <StyledTableCell colSpan={4} align="center">
                    {translate('empty_data')}
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TransactionTableBox>
      {isOpenDetailTranfer && (
        <DetailInternalTransaction
          open={isOpenDetailTranfer}
          handleClose={() => setIsOpenDetailTranfer(false)}
          data={selectedData}
          userId={userId}
        />
      )}
    </>
  );
};

export default TipsTransactionsTable;
