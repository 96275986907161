import {
  Box,
  BoxProps,
  Button,
  ButtonBase,
  ButtonProps,
  Divider,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PayoutInput = styled(TextField)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.table,
  borderRadius: theme.shape.borderRadius,
  border: 0,
  '&:hover': {
    backgroundColor: theme.palette.background.disable,
    border: 0,
  },
}));

export const TitlePayout = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  borderRadius: '0px 0px 4px 4px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1.5),
}));

export const SlideButton = styled(ButtonBase)(({ theme }) => ({
  minWidth: theme.spacing(5),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.hovering,
  border: 0,
  '&:hover': {
    backgroundColor: theme.palette.background.disable,
    border: 0,
  },
}));

export const MultButton = styled(ButtonBase)(({ theme }) => ({
  minWidth: theme.spacing(5),
  padding: theme.spacing(1),
  margin: '0px 4px !important',
  fontWeight: 500,
  borderRadius: 12,
  color: theme.palette.text.primary,
  background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
  border: 0,
  '&:hover': {
    backgroundColor: theme.palette.background.disable,
    border: 0,
  },
}));

export const ButtonSuffix = styled(ButtonBase)(({ theme }) => ({
  minWidth: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  backgroundColor: 'white',
  border: 0,
  '&:hover': {
    backgroundColor: theme.palette.background.hovering,
    border: 0,
  },
  '&:disabled': {
    backgroundColor: theme.palette.background.disable,
    border: 0,
  },
}));

export const CurrencyInput = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.background.table,
  border: 0,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.background.disable,
    border: 0,
  },
}));

interface TextMinMaxProps extends BoxProps {
  $right?: boolean;
  $left?: boolean;
}

export const TextMinMax = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$right' && prop !== '$left',
})<TextMinMaxProps>(({ $left, $right, theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.hovering,
  border: 0,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.background.disable,
    border: 0,
  },
  borderRadius: $right && !$left ? '0px 6px 6px 0px' : '6px 0px 0px 6px',
}));

export const AmountSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.background.secondary, // '#52af77',
  backgroundColor: theme.palette.background.hovering,
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  borderRadius: 0,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 13,
    backgroundColor: theme.palette.text.primary,
    borderRadius: 4,
    border: '0px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.secondary.dark,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));

interface ContinueButtonProps extends ButtonProps {
  $click?: boolean;
  themeMode: string;
}

export const OptionInput = styled(Button, {
  shouldForwardProp: (prop) => prop !== '$click' && prop !== 'themeMode',
})<ContinueButtonProps>(({ $click, themeMode, theme }) => ({
  fontSize: 12,
  height: theme.spacing(5),
  padding: 12,
  color: theme.palette.primary.contrastText,
  background: $click
    ? theme.palette.background.secondaryBgImage
    : theme.palette.background.secondary,
  border: 0,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    border: 0,
  },
  ...(themeMode === 'light' && { color: theme.palette.grey[700] }),
}));

export const DividerControlGame = styled(Divider)(() => ({
  border: '1.5px solid #181c2a',
  borderRadius: '4px',
  margin: '8px 0px !important',
  marginLeft: '8px',
}));

export const TitleControlGame = styled(Typography)(() => ({
  color: 'white',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
}));

export const SubTitleControlGame = styled(Typography)(() => ({
  fontWeight: 500,
}));

export const BetStateButtonStyle = styled(Button)(({ theme }) => ({
  minHeight: 52,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0.5),
  borderRadius: 12,
  border: 0,
  width: '100%',
  '&:hover': {
    opacity: 0.8,
  },
  cursor: 'pointer',
}));

export const Tabs = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  flexDirection: 'row',
  width: '100%',
  height: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.main,

  '.tab': {
    borderRadius: theme.shape.borderRadius,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& p': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    '&.active': {
      background: theme.palette.background.secondaryBgImage,
      '& p': {
        color: theme.palette.text.secondary,
      },
    },
    transition: 'all ease .15s',
  },
}));
