import { styled } from '@mui/material/styles';

export const FooterContactWrapper = styled('ul')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(2),
  },

  li: {
    height: 'fit-content',
    listStyle: 'none',
    cursor: 'pointer',

    svg: {
      width: 40,
      height: 40,
      [theme.breakpoints.down(400)]: {
        width: 36,
        height: 36,
      },
    },
  },

  'li:first-child, li:last-child': {
    position: 'relative',

    div: {
      display: 'flex',
      gap: theme.spacing(1),
      position: 'absolute',
      top: 0,
      left: '-180%',
      paddingTop: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(5.5),
      },
      [theme.breakpoints.down('sm')]: {
        left: '-125%',
      },
      [theme.breakpoints.down(400)]: {
        gap: theme.spacing(0.5),
        left: '-135%',
        paddingTop: theme.spacing(4.75),
      },

      a: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.text.contrast,
        lineHeight: 'normal',
        background: theme.palette.background.overlay,
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(1, 2),
        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
          padding: theme.spacing(0.5, 1),
        },
        '&:hover': {
          backgroundColor: theme.palette.background.hovering,
          transition: 'all ease 0.3s',
        },
      },
    },
  },
}));
