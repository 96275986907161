import { Currency } from 'graph';

export const WALLET_TAB_KEY = {
  DEPOSIT: 'Deposit',
  WITHDRAW: 'Withdraw',
  SWAP: 'Swap',
  TIP: 'Tip',
  BUY_CRYPTO: 'BuyCrypto',
  CODG: 'COD Gift',
};

export const ASSETS_TABS = {
  CRYPTO: {
    name: 'Crypto',
    icon: '',
  },
  // FIAT: 'fiat',
  // NFT: 'nft',
  PI: {
    name: 'Pi',
    icon: 'pi',
  },
};

export const GAME_BANK_TABS = {
  OVERVIEW: 'overview',
  HISTORY: 'history',
  LIST: 'list',
  WITHDRAW: 'withdraw',
};

export const COD_CURRENCY = {
  id: '1',
  name: 'COD',
  shortName: 'cod',
  type: 'CRYPTO',
  decimalDigits: 8,
  equivalentUsdRate: 1,
  blockchainNetworks: null,
  __typename: 'Currency',
} as Currency;

export const FUN_CURRENCY = {
  id: '2',
  name: 'FUN',
  shortName: 'fun',
  type: 'CRYPTO',
  decimalDigits: 2,
  equivalentUsdRate: 0,
  blockchainNetworks: null,
  __typename: 'Currency',
} as Currency;

export const REFERRAL_SEARCH_PARAM = 'ref';

// Banner

export const BANNER_LINK = {
  NFT: 'https://x.com/OhPlayOfficial/status/1792885302845161831',
  EARN_REAL_MONEY: 'https://blog.coco.game/p/free-to-earn-play-with-fun-coins',
  THE_DAILY_GAME: 'https://blog.coco.game/p/the-daily-challenge',
  DAILY_RACE: 'https://blog.coco.game/p/cocos-daily-races-contest',
  CLAIM_NOW_COCO: 'https://t.me/cocogame_official',
  CLAIM_NOW_OHPLAY: 'https://t.me/OhPlayOfficial',
  ORISUN: 'https://orisun.club/',
};

export const JFU_LINK = {
  FREE_PLAY: 'https://blog.coco.game/p/how-to-play-for-free-at-cocogame',
  BLOG_PI_CURACAO: 'https://blog.pi.game/p/pigame-achieves-curacao-license',
  FREE_PLAY_PI: 'https://blog.pi.game/p/play-for-free-and-earn-pi-daily',
  PI_AFFILIATE: 'https://pi.game/affiliate',
  BLOG_PI_GAME: 'https://blog.pi.game/p/pigame-sign-up-and-and-referral-bonus',
  DAILY_TOURNAMENT: 'https://blog.coco.game/p/the-31k-daily-tournament',
};

// Footer

export const SOCIAL_COMMUNITY_LINK = {
  // Coco
  TWITTER_COCO: 'https://twitter.com/Casino_coco',
  TELEGRAM_COCO: 'https://t.me/cocogame_official',
  DISCORD_COCO: 'https://discord.gg/P3mfFfYpNU',
  FACEBOOK_COCO: 'https://www.facebook.com/Cocogameofficial',
  // Ohplay
  TWITTER_OHPLAY: 'https://twitter.com/OhPlayOfficial',
  DISCORD_OHPLAY: 'https://discord.gg/ohplayofficial',
  INSTA_OHPLAY: 'https://www.instagram.com/ohplay.club',
  TELEGRAM_CHANEL_OHPLAY: 'https://t.me/ohplayofficialchannel',
  TELEGRAM_COMMUNITY_OHPLAY: 'https://t.me/OhPlayOfficial/1',
  FACEBOOK_CHANEL_OHPLAY: 'https://www.facebook.com/OhPlayClubOfficial',
  FACEBOOK_COMMUNITY_OHPLAY: 'https://www.facebook.com/groups/ohplayofficial',
  // Pi
  TWITTER_PI: 'https://twitter.com/Pigameofficial',
  DISCORD_PI: 'https://discord.gg/P3mfFfYpNU',
  FACEBOOK_PI: 'https://www.facebook.com/groups/580558766139294/',
  TELEGRAM_CHANEL_PI: 'https://t.me/PigameOfficialChannel',
  TELEGRAM_GROUP_PI: 'https://t.me/PigameOfficial',
};

export const LOGO_LINK = {
  GC: 'https://licensing.gaming-curacao.com/validator/?lh=e1124482aed486775f0823a212ddeaa3',
  ORISUN: 'https://orisun.club/',
  RNG_COCO:
    'https://itechlabs.com/certificates/OrisunNV/RNG_Certificate_UK__OrisunNV__29Jun23.pdf',
  RNG_OHPLAY:
    'https://www.itechlabs.com/certificates/OrisunNV/RNG_Certificate_UK_OrisunNV_Ohplay.club_12Jan24.pdf',
  RNG_PI:
    'https://www.itechlabs.com/certificates/OrisunNV/RNG_Certificate_UK_OrisunNV_Pi.game_12Jan24.pdf',
  RNG_SATOSHITY:
    'https://www.itechlabs.com/certificates/OrisunNV/RNG_Certificate_UK_OrisunNV_Satoshity_27Mar24.pdf',
  RNG_BETGECKO:
    'https://www.itechlabs.com/certificates/OrisunNV/RNG_Certificate_UK_OrisunNV_Betgecko_27Mar24.pdf',
};

export const env = {
  LOCAL: 'local',
  PIGAME: 'pigame',
  COCO: 'coco',
  BITWIN: 'bitwin',
  OHPLAY: 'ohplay',
};

export * from './api';
export * from './config';
export * from './enum';
export * from './localStorage';
export * from './regex';
