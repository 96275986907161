import { MutationResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemedButton } from 'common/Button/style';
import FormProvider, { RHFTextField } from 'components/hook-form';
import ValidationMessage from 'components/ValidationMessage';
import { emailRegex } from 'constants/regex';
import { RequestRecoverPasswordMutation } from 'graph';
import { useLocales } from 'locales';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Box, Typography } from '@mui/material';

import { EmailBox } from '../styles_v2';

export interface ForgotPasswordForm {
  email: string;
}

interface IForgotPasswordProps {
  onSubmitForgotPassword: (
    data: ForgotPasswordForm,
    countdown: VoidFunction,
  ) => void;
  resetPasswordMutationResult: MutationResult<RequestRecoverPasswordMutation>;
}

const defaultCountdownTime = 60;

const ForgotPassword = ({
  onSubmitForgotPassword,
  resetPasswordMutationResult: { data, error: apolloError },
}: IForgotPasswordProps) => {
  const { translate } = useLocales();
  const [timer, setTimer] = useState(defaultCountdownTime);
  const timerIntervalId = useRef(null);

  const schema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, translate('validate_invalid_email_address'))
      .required(null)
      .trim()
      .lowercase(),
  });

  const form = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const countdown = () => {
    if (!timerIntervalId.current) {
      timerIntervalId.current = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) return prev - 1;
        });
      }, 1000);
    }
  };

  const onSubmit = (data: ForgotPasswordForm) => {
    onSubmitForgotPassword(data, countdown);
  };

  useEffect(() => {
    if (timer === 0) {
      clearInterval(timerIntervalId.current);
      setTimer(defaultCountdownTime);
      timerIntervalId.current = null;
    }
  }, [timer]);

  return (
    <Box>
      <FormProvider methods={form} onSubmit={form.handleSubmit(onSubmit)}>
        <EmailBox>
          <RHFTextField name="email" placeholder="Enter your email" fullWidth />
        </EmailBox>
        <Typography
          fontSize={{ xs: 12, md: 14 }}
          variant="caption"
          whiteSpace="pre-wrap"
        >
          {translate('send_recover_link_to_email')}
        </Typography>
        {form.formState.isDirty &&
          !form.formState.isValid &&
          form.formState.isSubmitted && (
            <ValidationMessage
              mt={{ md: 1, sm: 1, xs: 2 }}
              message={translate('invalid_email_format')}
              ml={0}
            />
          )}
        {data?.requestRecoverPassword?.error?.message && (
          <ValidationMessage
            mt={{ md: 1, sm: 1, xs: 2 }}
            message={data.requestRecoverPassword.error.message}
            ml={0}
          />
        )}
        {apolloError && (
          <ValidationMessage
            mt={{ md: 1, sm: 1, xs: 2 }}
            message={apolloError.message}
            ml={0}
          />
        )}
        {timer !== defaultCountdownTime && (
          <Typography
            mt={2}
            textAlign="center"
            style={{ textDecoration: 'underline' }}
          >{`${translate('resend_after')} ${timer} ${translate(
            'second',
          )}`}</Typography>
        )}
        <ThemedButton
          sx={{
            '&&': {
              marginTop: 2,
              color: '#2E2C5E !important',
              opacity:
                timer !== defaultCountdownTime || !form.formState.isValid
                  ? 0.7
                  : 1,
            },
          }}
          className="confirm_button"
          type="submit"
          fullWidth
          disabled={timer !== defaultCountdownTime || !form.formState.isValid}
        >
          <Typography variant="subtitle1">
            {translate('reset_password')}
          </Typography>
        </ThemedButton>
      </FormProvider>
    </Box>
  );
};

export default ForgotPassword;
