import { differenceInYears } from 'date-fns';
import i18n from 'locales/i18n';
import * as Yup from 'yup';

export const generalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required(i18n.t('this_field_is_required')),
  lastName: Yup.string().required(i18n.t('this_field_is_required')),
  gender: Yup.string().required(i18n.t('this_field_is_required')),
  dateOfBirth: Yup.date()
    .required(i18n.t('this_field_is_required'))
    .typeError(i18n.t('invalid_date_format'))
    .test(
      'dateOfBirth',
      i18n.t('at_least_18_years_old'),
      (value) => differenceInYears(new Date(), new Date(value)) >= 18,
    ),
  address: Yup.string().required(i18n.t('this_field_is_required')),
  city: Yup.string().required(i18n.t('this_field_is_required')),
  postalCode: Yup.string().required(i18n.t('this_field_is_required')),
  countryCode: Yup.string().required(i18n.t('this_field_is_required')),
});

export const governmentIdSchema = Yup.object().shape({
  idCard: Yup.object().shape({
    frontSide: Yup.mixed()
      .notRequired()
      .test(
        'frontSide',
        i18n.t('this_field_is_required'),
        (frontSideCard, ctx) => !!frontSideCard || !!ctx.from[1].value.passport,
      ),
    backSide: Yup.mixed()
      .notRequired()
      .test(
        'backSide',
        i18n.t('this_field_is_required'),
        (backSideCard, ctx) => !!backSideCard || ctx.from[1].value.passport,
      ),
  }),
  passport: Yup.mixed()
    .notRequired()
    .test(
      'passport',
      i18n.t('this_field_is_required'),
      (passport, ctx) =>
        !!passport ||
        (ctx.from[0].value.idCard?.frontSide &&
          ctx.from[0].value.idCard?.backSide),
    ),
});

export const portraitSchema = Yup.object().shape({
  portrait: Yup.mixed().required(i18n.t('this_field_is_required')),
});
