import { SvgIcon } from '@mui/material';

export function IconDiscord(props: any) {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_34_1367)">
        <mask
          id="mask0_34_1367"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <path d="M40 0H0V40H40V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_34_1367)">
          <path
            d="M20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0Z"
            fill="#2F2B60"
          />
        </g>
        <path
          d="M29.0001 11.8002C27.3001 11.0002 25.5001 10.5002 23.7001 10.2002C23.5001 10.6002 23.2001 11.2002 23.0001 11.6002C21.0001 11.3002 19.1001 11.3002 17.1001 11.6002C16.9001 11.1002 16.7001 10.7002 16.4001 10.2002C14.6001 10.5002 12.8001 11.1002 11.1001 11.9002C7.70012 16.9002 6.80012 21.8002 7.30012 26.6002C9.30012 28.1002 11.5001 29.2002 13.8001 29.9002C14.3001 29.2002 14.8001 28.4002 15.2001 27.6002C14.4001 27.3002 13.7001 27.0002 13.0001 26.6002C13.2001 26.5002 13.4001 26.3002 13.5001 26.2002C17.6001 28.2002 22.4001 28.2002 26.6001 26.2002C26.8001 26.3002 27.0001 26.5002 27.1001 26.6002C26.4001 27.0002 25.7001 27.4002 24.9001 27.7002C25.3001 28.5002 25.8001 29.3002 26.3001 30.0002C28.7001 29.3002 30.9001 28.2002 32.8001 26.7002C33.3001 20.9002 31.9001 16.1002 29.0001 11.8002ZM15.8001 23.6002C14.5001 23.6002 13.5001 22.4002 13.5001 21.0002C13.5001 19.6002 14.5001 18.4002 15.8001 18.4002C17.1001 18.4002 18.1001 19.6002 18.1001 21.0002C18.1001 22.4002 17.0001 23.6002 15.8001 23.6002ZM24.3001 23.6002C23.0001 23.6002 22.0001 22.4002 22.0001 21.0002C22.0001 19.6002 23.0001 18.4002 24.3001 18.4002C25.6001 18.4002 26.6001 19.6002 26.6001 21.0002C26.6001 22.5002 25.6001 23.6002 24.3001 23.6002Z"
          fill="#5865F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_34_1367">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
