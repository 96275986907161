import { SvgIcon } from '@mui/material';

export function IconMaximizeMiniGame(props: any) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33335 2.5H11.6667C14.8094 2.5 16.3807 2.5 17.357 3.47631C18.1479 4.26717 18.2981 5.72188 18.3267 7.91645C18.3368 8.69715 18.3419 9.08749 18.0972 9.33541C17.8525 9.58333 17.4572 9.58333 16.6667 9.58333H14.5834C12.2263 9.58333 11.0478 9.58333 10.3156 10.3156C9.58335 11.0478 9.58335 12.2263 9.58335 14.5833V16.25C9.58335 16.6383 9.58335 16.8324 9.51992 16.9856C9.43534 17.1898 9.27311 17.352 9.06892 17.4366C8.91578 17.5 8.72164 17.5 8.33335 17.5C5.19066 17.5 3.61931 17.5 2.643 16.5237C1.66669 15.5474 1.66669 13.976 1.66669 10.8333V9.16667C1.66669 6.02397 1.66669 4.45262 2.643 3.47631C3.61931 2.5 5.19066 2.5 8.33335 2.5ZM9.14141 10.0253C9.38549 10.2694 9.78122 10.2694 10.0253 10.0253C10.2694 9.7812 10.2694 9.38547 10.0253 9.14139L7.7589 6.875H8.75002C9.0952 6.875 9.37502 6.59518 9.37502 6.25C9.37502 5.90482 9.0952 5.625 8.75002 5.625H6.25002C5.90484 5.625 5.62502 5.90482 5.62502 6.25V8.75C5.62502 9.09518 5.90484 9.375 6.25002 9.375C6.5952 9.375 6.87502 9.09518 6.87502 8.75V7.75888L9.14141 10.0253Z"
        fill="#9A99D5"
      />
      <path
        d="M11.3215 11.3215C10.8334 11.8096 10.8334 12.5953 10.8334 14.1667C10.8334 15.738 10.8334 16.5237 11.3215 17.0118C11.8097 17.5 12.5953 17.5 14.1667 17.5H15C16.5714 17.5 17.357 17.5 17.8452 17.0118C18.3334 16.5237 18.3334 15.738 18.3334 14.1667C18.3334 12.5953 18.3334 11.8096 17.8452 11.3215C17.357 10.8333 16.5714 10.8333 15 10.8333H14.1667C12.5953 10.8333 11.8097 10.8333 11.3215 11.3215Z"
        fill="#9A99D5"
      />
    </SvgIcon>
  );
}
