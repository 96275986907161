import { makeVar } from '@apollo/client';
import { getLocalStorage } from 'auth/utils';
import { FUN_CURRENCY } from 'constants/index';
import { CURRENCY, FIAT, IS_HIDE_SMALL } from 'constants/localStorage';
import {
  CurrenciesReactiveVar,
  HideSmallWalletReactiveVar,
  LiveStatsReactiveVar,
  ShowInsufficientBalancePopoverReactiveVar,
  ShowOverGameBankPopoverReactiveVar,
  StorageCurrencyReactiveVar,
  ViewInFiatReactiveVar,
} from 'graph';

export const viewInFiatVar = makeVar<ViewInFiatReactiveVar>({
  viewInFiat: {
    status: JSON.parse(localStorage.getItem(FIAT)) || true,
    fiat: {
      name: 'usd',
      rate: 1,
    },
  },
});

export const hideSmallWalletAmountVar = makeVar<HideSmallWalletReactiveVar>({
  hideSmallWalletAmount:
    JSON.parse(localStorage.getItem(IS_HIDE_SMALL)) || false,
});

export const storageCurrencyVar = makeVar<StorageCurrencyReactiveVar>({
  storageCurrency: JSON.parse(getLocalStorage(CURRENCY)) || FUN_CURRENCY,
});

export const currenciesVar = makeVar<CurrenciesReactiveVar>({
  currencies: [],
});

export const liveStatsVar = makeVar<LiveStatsReactiveVar>({
  liveStats: {
    profits: [0],
    winRounds: 0,
    loseRounds: 0,
    totalProfit: 0,
    totalBetAmount: 0,
  },
});

export const showOverGameBankPopoverVar =
  makeVar<ShowOverGameBankPopoverReactiveVar>({
    isShowOverGameBankPopOver: false,
  });

export const showInsufficientBalancePopoverVar =
  makeVar<ShowInsufficientBalancePopoverReactiveVar>({
    isShowInsufficientBalancePopOver: false,
  });
