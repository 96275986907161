import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useLocales } from 'locales';
import {
  BetAmountHost,
  BetIDHost,
} from 'overrides/components/text-host/TextHost';
import { memo } from 'react';

const HeaderMyBetWrapper = memo(() => {
  const { translate } = useLocales();

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" width="10%">
          <Typography color="text.primary" ml={1}>
            {translate(BetIDHost())}
          </Typography>
        </TableCell>
        <TableCell align="center" width="25%">
          <Typography color="text.primary">
            {translate(BetAmountHost())}
          </Typography>
        </TableCell>
        <TableCell align="center" width="15%">
          <Typography color="text.primary">
            {translate('multiplier')}
          </Typography>
        </TableCell>
        <TableCell align="right" width="20%">
          <Typography color="text.primary" mr={1}>
            {translate('payout')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
});

export { HeaderMyBetWrapper };
