import { SvgIcon } from '@mui/material';

export function IconGameGemStone(props: any) {
  return (
    <SvgIcon
      fill="#848798"
      style={{ fillRule: 'evenodd' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100.71 120.48"
      {...props}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="m96.67,96.67s-50.12,22.27-55.24,23.68c-4.36,1.19-15.78-5.92-15.78-5.92L0,39.46,21.7,0l3.94,3.95L5.92,37.48l7.89,1.97,61.06-30.53,6.02,2.91s13.82,59.59,19.73,76.94c.81,2.39-3.95,7.89-3.95,7.89Zm-18.19-65.78l-8.04,3.45-2.3-11.49-39.08,18.39,4.48,10.06-11.84,3.95,16.55,49.21,11.07-5.81,1.57,11.55,39.08-17.24-5.14-8.12,7.89-3.95-14.25-50Zm-39.02,42.11l11.84-35.51,27.62,21.7-11.84,33.54-27.62-19.73ZM12.97,35.49L57.22,1.97l3.94,9.86L15.78,35.51l-2.82-.02Zm-3.1.02L37.49,0l3.95,7.89-29.61,27.6-1.95.02Z"
        />
      </g>
    </SvgIcon>
  );
}
