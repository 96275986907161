import { SvgIcon } from '@mui/material';

export function IconTurbo(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.91 10.7199H14.82V3.5199C14.82 1.8399 13.91 1.4999 12.8 2.7599L12 3.6699L5.23001 11.3699C4.30001 12.4199 4.69001 13.2799 6.09001 13.2799H9.18001V20.4799C9.18001 22.1599 10.09 22.4999 11.2 21.2399L12 20.3299L18.77 12.6299C19.7 11.5799 19.31 10.7199 17.91 10.7199Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
