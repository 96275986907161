import {
  img_1,
  img_2,
  img_3,
  img_4,
  img_5,
  img_6,
  img_7,
  wild,
} from 'assets/images/minicoco/Rule';
import { useLocales } from 'locales';

import { Box, Typography } from '@mui/material';

import { RuleWrapper } from './styles';

// -------------------------------------------------------------------------

const Rule = () => {
  const { translate } = useLocales();

  return (
    <RuleWrapper>
      <Box className="p-12 header ">
        <Typography variant="h6">
          {translate('mini_HOST_is_Allways_3x3_Slot_machine_game')}
        </Typography>
        <Typography>{translate('rule_content')}</Typography>

        <Box>
          <img src={img_1} alt="img 1" />
          <img src={img_2} alt="img 2" />
          <img src={img_3} alt="img 3" />
        </Box>
      </Box>

      <Box className="p-12 jackpot">
        <Typography variant="h6">{translate('jackpot2')}</Typography>
        <Box>
          <img src={wild} alt="wild" />
          <img src={wild} alt="wild" />
          <img src={wild} alt="wild" />
        </Box>
        <Typography>{translate('explode_if_you_collect_3_WILD')}</Typography>
      </Box>

      <Box className="p-12 wild">
        <Box>
          <img src={wild} alt="wild" />
          <Typography>
            {translate('wild_can_subsitute_for_all_symbols')}
          </Typography>
        </Box>

        <Box>
          <img src={img_4} alt="img 4" />
          <img src={img_5} alt="img 5" />
          <Typography>x82</Typography>
        </Box>

        <Box>
          <img src={img_6} alt="img 6" />
          <img src={img_7} alt="img 7" />
          <Typography>x82</Typography>
        </Box>
      </Box>
    </RuleWrapper>
  );
};

export default Rule;
