/* eslint-disable no-nested-ternary */
import { tipFailed } from 'assets/images';
import { DialogAnimate } from 'components/animate';
import {
  CloseBox,
  DialogBody,
  DialogHeader,
  DialogWrapper,
} from 'components/Dialog/styles';
import Iconify from 'components/iconify';
import TransactionsDialog from 'components/TransactionsDialog';
import { InternalTransfer, TransactionGroup } from 'graph';
import { useLocales } from 'locales';
import { useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

// ----------------------------------------------------------------

export interface ModalTipSuccessProps {
  isSuccess: {
    message: string;
  };
  handleClose: () => void;
  handleBack: () => void;
  content: InternalTransfer;
  loading: boolean;
}

export function ModalTipSuccess(props: Partial<ModalTipSuccessProps>) {
  const { isSuccess, handleClose, content, loading, handleBack } = props;
  const theme = useTheme();
  const { translate } = useLocales();
  const [isTransactionsDialogOpen, setIsTransactionDialogOpen] =
    useState(false);
  const handleClickBackTransactionDialog = () => {
    setIsTransactionDialogOpen(false);
  };

  return (
    <>
      <DialogAnimate
        open
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogWrapper>
          <DialogHeader
            hasCloseBtn
            backgroundHeader={theme.palette.background.secondary}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              px={theme.spacing(1)}
              pt={theme.spacing(3)}
            >
              <CloseBox key="close-button">
                <IconButton className="close-btn" onClick={handleClose}>
                  <CloseRoundedIcon />
                </IconButton>
              </CloseBox>
            </Box>
          </DialogHeader>
        </DialogWrapper>
        <DialogBody
          sx={{
            overflowY: 'hidden',
          }}
        >
          {loading ? (
            <Box textAlign="center">
              <Iconify icon="eos-icons:bubble-loading" width={62} />
            </Box>
          ) : content ? (
            <>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="white"
                display="flex"
                gap={1}
                justifyContent="center"
              >
                {translate('successfully_tipped')}
                <Typography
                  color="#53BA0C"
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  textTransform="uppercase"
                >
                  {content.amount} {content.currency.shortName}
                </Typography>
                {translate('to')}
              </Typography>

              <Box display="flex" flexDirection="column" alignItems="center">
                {content.receiverAvatar && (
                  <Avatar
                    alt="Remy Sharp"
                    src={content.receiverAvatar.uri}
                    sx={{ width: 107, height: 107, my: 1 }}
                  />
                )}
                <Typography
                  fontWeight="700"
                  fontSize="24px"
                  color="#813FFA"
                  textAlign="center"
                  pb={2}
                  sx={{
                    color: '#EFC516',
                  }}
                >
                  {content.receiverNickname}
                </Typography>
                <Typography fontWeight="400" fontSize="16px" textAlign="center">
                  ID: {content.receiverId}
                </Typography>
                <Typography
                  fontSize="16px"
                  color="white"
                  pb={3}
                  whiteSpace="normal"
                  maxWidth="100%"
                  sx={{
                    '&:first-letter': {
                      textTransform: 'unset',
                    },
                    wordWrap: 'break-word',
                    textOverflow: 'unset',
                  }}
                >
                  {`"${content.message}"`}
                </Typography>
                <Box fontSize="14px" color="white">
                  {translate('your_tip_request_has_been_confirmed')}
                </Box>
                <Box fontSize="14px" color="white">
                  {translate(
                    'you_can_track_the_progress_on_the_wallet_transaction_history',
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="24px"
                mt={3}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => setIsTransactionDialogOpen(true)}
                >
                  {translate('view_history')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleClose}
                >
                  {translate('done')}
                </Button>
              </Box>
            </>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <img src={tipFailed} alt="tipFailed" width="176px" />
              <Typography
                fontSize="20px"
                color="#FF5C00"
                fontWeight="700"
                my={2}
              >
                {translate('tip_failed')}
              </Typography>
              <Box fontSize="14px" color="white">
                {isSuccess?.message}
              </Box>
              <Box fontSize="14px" color="white">
                {translate('please_try_again_later')}
              </Box>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 3 }}
                // onClick={handleClose}
                onClick={handleBack}
              >
                {translate('ok_i_got_it')}
              </Button>
            </Box>
          )}
          {}
        </DialogBody>
      </DialogAnimate>
      {isTransactionsDialogOpen && (
        <TransactionsDialog
          isOpen={isTransactionsDialogOpen}
          handleClose={() => {
            handleClickBackTransactionDialog();
          }}
          handleBack={handleClickBackTransactionDialog}
          directExact={TransactionGroup.InternalTransfer}
        />
      )}
    </>
  );
}
