import { useReactiveVar } from '@apollo/client';
import { IOSSwitch } from 'common/Switch';
import SearchBar from 'components/AutocompleteField';
import AssetItem from 'components/WalletDialog/components/AssetsPortfolioDialog/AssetItem';
import { Currency, useUserWalletsQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import lodash from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { currenciesVar, hideSmallWalletAmountVar, viewInFiatVar } from 'store';
import { currencyLocalStateOperations } from 'store/operations';

import { Divider, FormControlLabel, Popover } from '@mui/material';

import {
  AssetPopoverWrapper,
  CurrenciesBox,
  SearchBarBox,
  ViewInFiatBox,
} from './styles';

// -------------------------------------------------------------

interface PropsType {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  selectedCurrency: Currency | null;
  handleSelectCurrency: (currency: any) => void;
  handleOpenLockCurrency: () => void;
  handleOpenWalletCODG: () => void;
}

const AssetsPopover = ({
  anchorEl,
  handleClose,
  selectedCurrency,
  handleSelectCurrency,
  handleOpenLockCurrency,
  handleOpenWalletCODG,
}: PropsType) => {
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const [searchString, setSearchString] = useState('');
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { currencies } = useReactiveVar(currenciesVar);
  const { hideSmallWalletAmount } = useReactiveVar(hideSmallWalletAmountVar);
  const { setHideSmallWalletAmount, setViewInFiat } =
    currencyLocalStateOperations;

  const { data: userWalletsData } = useUserWalletsQuery({
    fetchPolicy: 'cache-only',
  });

  const onSearchBarInputChange = lodash.debounce((searchInput) => {
    setSearchString(searchInput);
  }, 300);

  const filteredCurrencies = React.useMemo(() => {
    const walletCurrencies = userWalletsData?.me?.userWallets
      .filter((userWallet) => userWallet.amount > 0)
      .map((userWallet) =>
        currencies.find((currency) => currency?.id === userWallet.currencyId),
      );

    const currenciesClone = hideSmallWalletAmount
      ? walletCurrencies
      : currencies;

    const currenciesSort = [...currenciesClone].sort((a, b) => {
      const blA =
        userWalletsData?.me?.userWallets?.find((w) => w?.currencyId === a?.id)
          ?.amount || 0;
      const blB =
        userWalletsData?.me?.userWallets?.find((w) => w?.currencyId === b?.id)
          ?.amount || 0;
      return blB * b.equivalentUsdRate - blA * a.equivalentUsdRate;
    });

    return (currenciesSort || []).filter((currency) => {
      const lowercaseName = currency?.name?.toLowerCase();
      const lowercaseShortname = currency?.shortName?.toLowerCase();
      const trimLowercaseSearchString = searchString.trim().toLowerCase();

      return (
        lowercaseName?.includes(trimLowercaseSearchString) ||
        lowercaseShortname?.includes(trimLowercaseSearchString)
      );
    });
  }, [
    currencies,
    searchString,
    hideSmallWalletAmount,
    userWalletsData?.me?.userWallets,
  ]);
  const { slug } = useParams();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        ...(isMobile && {
          sx: {
            maxWidth: 'unset',
            position: 'sticky',
            mt: 2.5,
            marginLeft: 1.5,
            marginRight: 1.5,
            borderRadius: '0 0 12px 12px',
          },
        }),
        ...(!isMobile && {
          sx: {
            minWidth: 320,
          },
        }),
      }}
      {...(isMobile && {
        slotProps: { backdrop: { invisible: false } },
      })}
      onClose={handleClose}
      sx={{ mt: { sm: 1.5, xs: 3 } }}
    >
      <AssetPopoverWrapper>
        <SearchBarBox>
          <SearchBar
            placeholder={translate('search_by_name')}
            $shouldHaveSearchBar
            options={[]}
            onSelect={() => {}}
            $onSearchBarInputChange={onSearchBarInputChange}
            $isContrastBackground
          />
        </SearchBarBox>

        <CurrenciesBox>
          {filteredCurrencies.map((currency) => (
            <AssetItem
              key={currency?.name}
              isActive={selectedCurrency?.id === currency?.id}
              handleSelectCurrency={() => handleSelectCurrency(currency)}
              handleSelectCODG={handleOpenLockCurrency}
              handleSelectOpenWalletCODG={handleOpenWalletCODG}
              currency={currency}
            />
          ))}
        </CurrenciesBox>
      </AssetPopoverWrapper>

      <Divider
        sx={{
          margin: '10px 12px',
          borderColor: '#2F2B60',
        }}
      />

      <ViewInFiatBox>
        {slug !== 'baccarat' && (
          <FormControlLabel
            control={
              <IOSSwitch
                checked={viewInFiat.status}
                onChange={(_, checked) => {
                  setViewInFiat({
                    status: checked,
                  });
                }}
              />
            }
            label={translate('view_in_fiat')}
            componentsProps={{
              typography: {
                sx: {
                  gap: '8px',
                  margin: 0,
                  fontSize: 12,
                  marginLeft: '8px',
                },
              },
            }}
            sx={{
              margin: 0,
            }}
          />
        )}

        <FormControlLabel
          control={
            <IOSSwitch
              checked={hideSmallWalletAmount}
              onChange={(_, checked) => {
                setHideSmallWalletAmount(checked);
              }}
            />
          }
          label={translate('hide_small')}
          componentsProps={{
            typography: {
              sx: {
                gap: '8px',
                margin: 0,
                fontSize: 12,
                marginLeft: '8px',
              },
            },
          }}
          sx={{
            margin: 0,
          }}
        />
      </ViewInFiatBox>
    </Popover>
  );
};

export default AssetsPopover;
