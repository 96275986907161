import { Box, Button, styled } from '@mui/material';

export const SelectCoinBox = styled(Box)(() => ({
  justifySelf: 'flex-start',
  width: '100%',
}));

export const ContinueButton = styled(Button)(({ theme }) => ({
  width: '100%',
  minHeight: 48,
  borderRadius: theme.shape.borderRadius,
  marginTop: '12px',
  backgroundImage:
    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  color: '#2E2C5E',
  '&.Mui-disabled': {
    opacity: 0.7,
  },
}));

export const SelectCurrencyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  backgroundColor: theme.palette.background.table,
  borderRadius: theme.shape.borderRadius,
  img: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(0.5),
  },
  '.selected-coin': {
    minWidth: 120,
    height: 48,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.overlay,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    p: {
      marginRight: theme.spacing(2.5),
      [theme.breakpoints.down(400)]: {
        marginRight: 0,
      },
    },
    'p, svg': {
      color: theme.palette.text.contrast,
    },
  },
  '.balance_box': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '.MuiInputBase-root': {
      display: 'flex',
      justifyContent: 'flex-end',
      input: {
        textAlign: 'end',
        padding: 0,
        color: theme.palette.text.contrast,
        fontSize: '14px',
        backgroundColor: '#11141E',
        height: 'fit-content',
      },
    },
    p: {
      color: theme.palette.text.primary,
    },
    '.balance_amount': {
      color: theme.palette.text.primary,
      span: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1.5),
      },
    },
    '.input_balance': {
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          border: 'none !important',
        },
      },
    },
  },
}));
export const AccountNotVerifiedWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  flex: 1,
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  'p, h6': {
    textAlign: 'center',
  },
  '.frame-wrapper': {
    border: 'none',
    height: 347,
    width: 361,
  },
  '.frame': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  '.img': {
    height: 225,
    minWidth: 289,
  },
  '.text-wrapper': {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '26px',
    whiteSpace: 'nowrap',
  },
}));
export const AmountBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#161432',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.75, 2),
  marginTop: theme.spacing(1.5),
  '.MuiInputBase-root': {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: '#161432',
    input: {
      textAlign: 'start',
      padding: 0,
      color: theme.palette.text.contrast,
      [theme.breakpoints.down(400)]: {
        fontSize: 12,
      },
    },
  },
  '.MuiTypography-root': {
    fontWeight: 500,
  },
  '.coin_image': {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    width: 'max-content',
    img: {
      height: 24,
      width: 24,
      marginRight: theme.spacing(0.5),
    },
  },
}));

export const ChangeButton = styled(Button)(({ theme }) => ({
  background: 'var(--transparent-primary-24, rgba(129, 63, 250, 0.24))',
  padding: theme.spacing(2, 5),
  borderRadius: 5,
  border: 0,
  width: '100%',
  '&:hover': {
    border: 0,
    transform: 'scale(1.02)',
    background: 'var(--transparent-primary-24, rgba(129, 63, 250, 0.24))',
  },
  '&:active': { transform: 'scale(0.98)' },
  cursor: 'pointer',
  zIndex: 100,
}));

export const TipWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));
