import { Stack, styled } from '@mui/material';

export const MiniDiceEventDetailOverView = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  justifyContent: 'space-between',
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.main,
  p: {
    color: theme.palette.text.contrast,
    '&.total-prized-title': {
      color: theme.palette.text.primary,
    },
    '&.total-prized-num': {
      fontWeight: 700,
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  '.MuiDivider-root': {
    height: 16,
    margin: 'auto',
    borderColor: theme.palette.text.primary,
  },
  '.dice-num': {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '14px',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: '50%',
    color: theme.palette.background.main,
    fontSize: 14,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      width: 16,
      height: 16,
      fontSize: 12,
      '&.dice-total': {
        width: 20,
        height: 20,
        fontSize: 12,
      },
    },
    '&.dice-first': {
      backgroundColor: '#7660FE',
    },
    '&.dice-second': {
      backgroundColor: '#63D8FE',
    },
    '&.dice-third': {
      backgroundColor: '#FFE500',
    },
    '&.dice-total': {
      backgroundColor: '#53BA0C',
      color: theme.palette.text.contrast,
    },
  },
}));
