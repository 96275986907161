import { ReactComponent as ArrowLeftBoldIcon } from 'assets/icons/arrow-left-bold.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { DialogHeaderStyled } from 'common/Dialog';
import { useLocales } from 'locales';
import { memo } from 'react';

import { Button, IconButton, Typography } from '@mui/material';

interface IMiniDiceEventDetailDialogHeaderProps {
  onClose: VoidFunction;
  onClickBack: VoidFunction;
}

const MiniDiceEventDetailDialogHeader = ({
  onClose,
  onClickBack,
}: IMiniDiceEventDetailDialogHeaderProps) => {
  const { translate } = useLocales();
  return (
    <DialogHeaderStyled>
      <Button
        sx={{ p: 0, color: 'text.contrast' }}
        onClick={() => onClickBack()}
        startIcon={<ArrowLeftBoldIcon />}
      >
        {translate('back')}
      </Button>
      <Typography
        className="dialog-title"
        sx={{ transform: 'translateX(-10%)' }}
        variant="h6"
      >
        {translate('top_10_winners')}
      </Typography>
      <IconButton className="close-btn" onClick={() => onClose()}>
        <CloseCircle width="20px" height="20px" />
      </IconButton>
    </DialogHeaderStyled>
  );
};

export default memo(MiniDiceEventDetailDialogHeader);
