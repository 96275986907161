import { ReactComponent as CasinoChipIcon } from 'assets/icons/casino-chip.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as MultiplierIcon } from 'assets/icons/multiplier.svg';
import { ReactComponent as ProfitIcon } from 'assets/icons/profit.svg';
import currencyImages from 'assets/images/coins';
import DialogAnimate from 'components/animate/DialogAnimate';
import { DialogWrapper } from 'components/Dialog/styles';
import LoadingAnimated from 'components/LoadingAnimated';
import PlayerBox from 'components/PlayerBox';
import WalletDialogHeader from 'components/WalletDialog/components/WalletDialogHeader';
import { format } from 'date-fns';
import { Currency, useBetQuery } from 'graph';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import useLocales from 'locales/useLocales';
import { useSnackbar } from 'notistack';
import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';

import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';

import {
  BetAmountHost,
  BetIDHost,
  CanNotGetBetDetail,
} from 'overrides/components/text-host/TextHost';
import BetDetailInfo from './BetDetailInfo/BetDetailInfo';
import { Body, GameInfoBox, StatsBox } from './styles';

// ------------------------------------------------------------------

interface Props {
  open: boolean;
  onClose: VoidFunction;
  betId: string;
}

const BetDetail = ({ open, onClose, betId }: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');

  const { data: betData, loading } = useBetQuery({
    variables: {
      betId,
    },
  });

  const handleCopyBetIdShareInChat = (betId: string) => {
    copy(`share:${betId}`);
    enqueueSnackbar(translate('copied_share_in_chat'));
  };

  const handleCopyBetIdShareOnline = (betId: string) => {
    copy(`https://${window.location.host}?betDetail=${betId}`);
    enqueueSnackbar(translate('copied_share_online'));
  };

  const handlePlayNow = (slug) => {
    onClose();
    navigate(`/casino/original/${slug}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: 'unset',
        overflow: 'hidden',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'unset',
          maxWidth: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
    >
      <DialogWrapper className="popup-bet-detail">
        <Box className="popup-bet-detail-content">
          <WalletDialogHeader title="details" handleClose={onClose} />

          <Body className="bet-detail-content">
            {!loading ? (
              <Box className="details-content">
                {betData?.bet ? (
                  <>
                    <Box className="bet-detail-id">
                      <Typography component="span">
                        {`${translate(BetIDHost())}: ${betData?.bet?.id}`}
                      </Typography>

                      <IconButton
                        className="copy_btn"
                        onClick={() =>
                          handleCopyBetIdShareInChat(betData?.bet?.id)
                        }
                      >
                        <CopyIcon />
                      </IconButton>
                      <IconButton
                        className="copy_btn"
                        onClick={() =>
                          handleCopyBetIdShareOnline(betData?.bet?.id)
                        }
                      >
                        <LinkIcon />
                      </IconButton>
                    </Box>

                    <Box className="bet-detail-info">
                      <Typography component="span">
                        {translate('by')}
                      </Typography>

                      <PlayerBox user={betData?.bet?.user as any} />

                      {isDesktop ? <>&nbsp;/&nbsp;</> : <br />}

                      <Typography component="span">
                        {translate('on')}
                      </Typography>

                      <Typography component="span">
                        {betData?.bet?.createdAt &&
                          format(
                            new Date(betData?.bet?.createdAt),
                            'dd/MM/yyyy, h:mm:ss aa',
                          )}
                      </Typography>
                    </Box>

                    <StatsBox className="bet-detail-number">
                      <Box className="item_wrap" sx={{ gridArea: 'amount' }}>
                        <Box className="label_box">
                          <CasinoChipIcon />

                          <Typography component="span">
                            {translate(BetAmountHost())}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography component="span">
                            {getCurrencyAmount(betData?.bet?.betAmount || 0, {
                              ...betData?.bet?.currency,
                              equivalentUsdRate:
                                betData?.bet?.equivalentUsdRate,
                            } as Currency)}
                          </Typography>
                          <img
                            className="currency_image"
                            alt={betData?.bet?.currency?.shortName}
                            src={
                              currencyImages[betData?.bet?.currency?.shortName]
                            }
                          />
                        </Box>
                      </Box>

                      <Box
                        className="item_wrap"
                        sx={{ gridArea: 'multiplier' }}
                      >
                        <Box className="label_box">
                          <MultiplierIcon />
                          <Typography component="span">
                            {translate('multiplier')}
                          </Typography>
                        </Box>

                        <Typography
                          component="span"
                          color="text.contrast"
                          sx={{
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: 'normal',
                          }}
                        >
                          {numeral(betData?.bet?.payoutRate).format('0.00')}x
                        </Typography>
                      </Box>

                      <Box className="item_wrap" sx={{ gridArea: 'stat' }}>
                        <Box className="label_box">
                          <ProfitIcon />
                          <Typography component="span">
                            {translate('payout')}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography
                            color="text.contrast"
                            className={
                              betData?.bet.payoutRate > 0
                                ? 'increase'
                                : 'decrease'
                            }
                            sx={{
                              fontSize: '18px',
                              fontWeight: 700,
                              lineHeight: 'normal',
                            }}
                          >
                            {getCurrencyAmount(betData?.bet?.payoutAmount, {
                              ...betData?.bet.currency,
                              equivalentUsdRate:
                                betData?.bet?.equivalentUsdRate,
                            } as Currency)}
                          </Typography>
                          <img
                            className="currency_image"
                            alt={betData?.bet?.currency?.shortName}
                            src={
                              currencyImages[betData?.bet?.currency?.shortName]
                            }
                          />
                        </Box>
                      </Box>
                    </StatsBox>

                    {betData?.bet.game && (
                      <GameInfoBox className="bet-detail-game-info">
                        <Box display="flex" gap="16px">
                          <Box position="relative">
                            <img
                              className="game_preview"
                              alt={betData?.bet?.game.name}
                              src={
                                betData?.bet.game?.gameBase?.thumbnail
                                  ?.square || ''
                              }
                            />
                          </Box>

                          <Box className="bet-detail-game-name">
                            <Typography variant="h6">
                              {betData?.bet?.game.name}
                            </Typography>

                            <Typography component="span">
                              {betData?.bet?.game?.gameBase?.producer.name}
                            </Typography>
                          </Box>
                        </Box>

                        <Button
                          className="play_btn"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handlePlayNow(betData?.bet?.game?.slug)
                          }
                        >
                          {translate('play_now')}
                        </Button>
                      </GameInfoBox>
                    )}
                    <Box alignItems="center">
                      {/* <ResultComponent /> */}
                      <BetDetailInfo betId={betId} />
                    </Box>
                  </>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <Typography color="#FFF" fontSize="18px" fontWeight={600}>
                      {translate(CanNotGetBetDetail())}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <LoadingAnimated />
              </Box>
            )}
          </Body>
          {/* {loading && } */}
        </Box>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default BetDetail;
