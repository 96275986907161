import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FooterWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.secondary,
  padding: theme.spacing(7.5, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 3),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3.75, 2.5),
  },
  [theme.breakpoints.down(400)]: {
    padding: theme.spacing(3, 1.5),
  },

  '.footer-menu': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },

    ul: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      [theme.breakpoints.down(400)]: {
        gap: theme.spacing(1.5),
      },

      h6: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.text.contrast,
        lineHeight: 'normal',
        [theme.breakpoints.down('md')]: {
          fontSize: 14,
          textAlign: 'center',
        },
        [theme.breakpoints.down(400)]: {
          fontSize: 12,
        },
      },

      li: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'row',
          justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
          gap: theme.spacing(1.75),
        },
        [theme.breakpoints.down(400)]: {
          gap: theme.spacing(1.5),
        },

        a: {
          width: 'fit-content',
          fontSize: 14,
          fontWeight: 400,
          color: theme.palette.text.primary,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          '&:hover': {
            color: theme.palette.text.contrast,
          },
          [theme.breakpoints.down(400)]: {
            fontSize: 12,
          },
        },
      },
    },
  },

  '.footer-line': {
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(3.75, 0),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3.5, 0),
    },
    [theme.breakpoints.down(400)]: {
      margin: theme.spacing(3, 0),
    },
  },

  // FT BOTTOM

  '.footer-bottom': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(3.75),
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(4),
    },
    [theme.breakpoints.down(400)]: {
      gap: theme.spacing(3),
    },
  },

  '.footer-logo': {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },

    '.logo-coco': {
      width: 'fit-content',
      display: 'flex',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },

      svg: {
        width: 180,
        height: 'fit-content',
        objectFit: 'cover',
      },
    },

    p: {
      fontSize: 12,
      lineHeight: 'normal',
      margin: theme.spacing(1.5, 0, 2.5),
    },

    '.logo-GC': {
      width: 'fit-content',
      paddingLeft: theme.spacing(1.25),
      img: {
        width: '100%',
      },
    },

    '.logo-Orisun': {
      width: 'fit-content',
      paddingLeft: theme.spacing(1.25),
      img: {
        width: '192px',
        // height: '70px',
      },
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
      alignItems: 'center',

      p: {
        textAlign: 'center',
        padding: 0,
        margin: theme.spacing(3, 0),
      },

      '.logo-GC': {
        padding: 0,
        img: {
          width: '172px',
          height: '60px',
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      '.logo-GC': {
        img: {
          width: '152px',
          height: '50px',
        },
      },
    },

    [theme.breakpoints.down(400)]: {
      '.logo-GC': {
        img: {
          width: '138px',
          height: '45px',
        },
      },
    },
  },

  '.footer-RNG': {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),

    a: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(1.5),
      textDecoration: 'none',
      p: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.text.contrast,
        lineHeight: 'normal',
      },
      img: {
        width: 80,
        height: 80,
      },

      [theme.breakpoints.down('sm')]: {
        p: {
          fontSize: 15,
        },
        img: {
          width: 70,
          height: 70,
        },
      },
      [theme.breakpoints.down(400)]: {
        p: {
          fontSize: 14,
        },
        img: {
          width: 60,
          height: 60,
        },
      },
    },
  },

  '.footer-contact': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1.5),

    h6: {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.text.contrast,
      lineHeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down(400)]: {
        fontSize: 14,
      },
    },

    ul: {
      display: 'flex',
      gap: theme.spacing(2.5),
      [theme.breakpoints.down('lg')]: {
        gap: theme.spacing(2),
      },

      li: {
        height: 'fit-content',
        listStyle: 'none',
        cursor: 'pointer',

        svg: {
          width: 40,
          height: 40,
          [theme.breakpoints.down(400)]: {
            width: 36,
            height: 36,
          },
        },
      },

      'li:first-child, li:last-child': {
        position: 'relative',

        div: {
          display: 'flex',
          gap: theme.spacing(1),
          position: 'absolute',
          top: 0,
          left: '-180%',
          paddingTop: theme.spacing(6),
          [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(5.5),
          },
          [theme.breakpoints.down('sm')]: {
            left: '-125%',
          },
          [theme.breakpoints.down(400)]: {
            gap: theme.spacing(0.5),
            left: '-135%',
            paddingTop: theme.spacing(4.75),
          },

          a: {
            fontSize: 16,
            fontWeight: 500,
            color: theme.palette.text.contrast,
            lineHeight: 'normal',
            background: theme.palette.background.overlay,
            borderRadius: theme.spacing(1.5),
            padding: theme.spacing(1, 2),
            [theme.breakpoints.down('sm')]: {
              fontSize: 14,
              padding: theme.spacing(0.5, 1),
            },
            '&:hover': {
              backgroundColor: theme.palette.background.hovering,
              transition: 'all ease 0.3s',
            },
          },
        },
      },
    },
  },
}));

export const GoTopBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  gap: theme.spacing(1.625),
  borderRadius: '20px',
  backgroundColor: theme.palette.background.main,
  cursor: 'pointer',
  padding: theme.spacing(2.5),
  '&:hover': {
    backgroundColor: theme.palette.background.hovering,
    transition: 'all ease 0.3s',
  },

  '.icon-top': {
    width: '40px',
    height: '40px',
    color: theme.palette.primary.main,
  },

  p: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
  },

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
    '.icon-top': {
      width: '35px',
      height: '35px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
    '.icon-top': {
      width: '35px',
      height: '35px',
    },
    p: {
      fontSize: '14px',
    },
  },

  [theme.breakpoints.down(400)]: {
    gap: theme.spacing(1),
    padding: theme.spacing(1.75),
    '.icon-top': {
      width: '30px',
      height: '30px',
    },
  },
}));
