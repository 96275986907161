import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import {
  BetAmountHost,
  BetIDHost,
} from 'overrides/components/text-host/TextHost';
import { memo } from 'react';

interface IHeaderAllBetWrapperProps {
  isShowInMiniGameDialog: boolean;
  isBetsByGame: boolean;
}

const HeaderAllBetWrapper = memo(
  (props: Partial<IHeaderAllBetWrapperProps>) => {
    const { isShowInMiniGameDialog, isBetsByGame } = props;
    const { translate } = useLocales();
    const isDesktop = useResponsive('up', 'sm');

    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell
              width={isShowInMiniGameDialog ? '25%' : '20%'}
              align="left"
            >
              <Typography color="text.primary" sx={{ ml: 1 }}>
                {isBetsByGame ? translate(BetIDHost()) : translate('game')}
              </Typography>
            </TableCell>
            <TableCell align="center" width="25%">
              <Typography color="text.primary">
                {translate('player')}
              </Typography>
            </TableCell>
            {isDesktop && !isShowInMiniGameDialog && (
              <TableCell align="right" width="20%">
                <Typography color="text.primary">
                  {translate(BetAmountHost())}
                </Typography>
              </TableCell>
            )}
            <TableCell align="center" width="20%">
              <Typography color="text.primary">
                {translate('multiplier')}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={{ width: { sm: '30%', xs: '20%' } }}>
              <Typography color="text.primary" sx={{ mr: 1 }}>
                {translate('payout')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </>
    );
  },
);

export { HeaderAllBetWrapper };
