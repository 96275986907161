import { SvgIcon } from '@mui/material';

export function IconLanguage(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4129 11.3792V7.81824H7.71292C7.51296 8.86884 7.38927 10.0569 7.3623 11.3792H11.4129Z"
        fill="#858997"
      />
      <path
        d="M11.4127 6.64428V2C10.0389 2.34271 8.72576 3.91271 7.98242 6.64428H11.4127Z"
        fill="#858997"
      />
      <path
        d="M6.1895 12.5532H2C2.07134 13.7833 2.36619 14.9728 2.86551 16.075H6.52126C6.32892 14.9907 6.21709 13.8112 6.1895 12.5532Z"
        fill="#858997"
      />
      <path
        d="M2 11.3792H6.18828C6.21309 10.1063 6.32423 8.91333 6.51848 7.81824H2.88351C2.37339 8.93145 2.07216 10.1345 2 11.3792Z"
        fill="#858997"
      />
      <path
        d="M12.5869 2.00488V6.64428H16.007C15.7257 5.62146 15.3888 4.8519 15.0779 4.28997C14.3829 3.03373 13.5147 2.24351 12.5869 2.00488Z"
        fill="#858997"
      />
      <path
        d="M11.4131 12.5532H7.36377C7.39457 13.9224 7.52558 15.0882 7.7131 16.075H11.4131L11.4131 12.5532Z"
        fill="#858997"
      />
      <path
        d="M16.1055 3.72163C16.569 4.55932 16.9418 5.54113 17.22 6.64429H20.4806C20.0838 6.01728 19.6143 5.42918 19.0758 4.89073C17.9848 3.79962 16.6899 2.99113 15.2798 2.50046C15.5769 2.85773 15.853 3.26524 16.1055 3.72163Z"
        fill="#858997"
      />
      <path
        d="M16.6364 11.3792C16.6051 9.99176 16.4709 8.81335 16.2794 7.81824H12.5869V11.3792H16.6364Z"
        fill="#858997"
      />
      <path
        d="M17.2415 17.2489C16.9628 18.3757 16.5854 19.377 16.1135 20.2284C15.8667 20.6735 15.5973 21.0718 15.3076 21.4221C16.7068 20.9305 17.9915 20.1254 19.0753 19.0416C19.6249 18.4921 20.1024 17.8905 20.5043 17.2489H17.2415Z"
        fill="#858997"
      />
      <path
        d="M6.76807 6.64429C7.04583 5.5335 7.41981 4.54563 7.88622 3.70414C8.13302 3.2589 8.40244 2.86059 8.69221 2.51016C7.29302 3.00177 6.00814 3.80686 4.92431 4.89069C4.38586 5.42914 3.91629 6.01725 3.51953 6.64425L6.76807 6.64429Z"
        fill="#858997"
      />
      <path
        d="M17.4717 7.81824C17.6684 8.91337 17.7828 10.1059 17.8108 11.3792H22.0001C21.928 10.1345 21.6267 8.93145 21.1166 7.81824H17.4717Z"
        fill="#858997"
      />
      <path
        d="M7.89489 20.2109C7.4259 19.3633 7.04988 18.3681 6.77072 17.2489H3.49561C3.89749 17.8905 4.37501 18.4921 4.92457 19.0416C6.01564 20.1327 7.31046 20.9412 8.72056 21.4319C8.4234 21.0747 8.14736 20.6672 7.89489 20.2109Z"
        fill="#858997"
      />
      <path
        d="M12.5869 12.5532V16.075H16.2944C16.49 15.034 16.6111 13.8591 16.6377 12.5532H12.5869Z"
        fill="#858997"
      />
      <path
        d="M22.0002 12.5532H17.8121C17.7876 13.811 17.6788 14.9906 17.4888 16.075H21.1347C21.634 14.9727 21.9289 13.7833 22.0002 12.5532Z"
        fill="#858997"
      />
      <path
        d="M12.5869 17.2489V21.9324C13.9675 21.5881 15.2868 20.0049 16.0282 17.2489H12.5869Z"
        fill="#858997"
      />
      <path
        d="M11.4132 21.9275V17.2489H7.98242C8.26589 18.2914 8.60739 19.0735 8.92224 19.6425C9.6173 20.8987 10.4854 21.6888 11.4132 21.9275Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
