import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export const ThemedButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.background.main,
  background: theme.palette.background.primaryBgImage,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  '&:hover': {
    filter: 'brightness(1.2)',
  },
}));

export const LinearButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
  minWidth: 'unset',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  '&:hover': {
    filter: 'brightness(1.2)',
  },
}));
