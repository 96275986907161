import { UserWalletsDocument, UserWalletsQuery } from 'graph';
import { cache } from 'graph/cache';

export const updateCacheUserWallet: UpdateBalanceFn = ({
  amount,
  balance,
  currencyId,
}) => {
  const myUserWallets: UserWalletsQuery = cache.readQuery({
    query: UserWalletsDocument,
  });
  const updatedWallet = myUserWallets.me.userWallets?.find(
    (wallet) => wallet.currencyId === currencyId,
  );

  if (updatedWallet && updatedWallet.amount !== amount) {
    cache.modify({
      id: cache.identify(updatedWallet),
      fields: {
        amount(currentValue) {
          if (balance) {
            return balance;
          }
          return currentValue + amount;
        },
      },
    });
  }
};
