import { GamesQuery } from 'graph';
import { cache } from 'graph/cache';

export const updateCacheGames = (gamesQuery: GamesQuery) => {
  if (gamesQuery?.games) {
    cache.modify({
      fields: {
        games(currentGamesRef) {
          return {
            ...currentGamesRef,
            data: gamesQuery?.games?.data,
          };
        },
      } as any,
    });
  }
};
