import birthNotAllow from 'assets/images/coco-birth-not-allow.png';
import { ThemedButton } from 'common/Button/style';
import LoadingAnimated from 'components/LoadingAnimated';
import { useLoyalUserQuery } from 'graph';
import { useLocales } from 'locales';
import { VipProgressWrapper } from 'pages/VipClub/components/VipPoint/styles_v2';
import { useMemo } from 'react';

import { Box, Stack, styled, Typography } from '@mui/material';

const RateBackTabWrapper = styled(VipProgressWrapper)(({ theme }) => ({
  '.claim-rakeback-btn': {
    maxWidth: 250,
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(1, 5),
  },
  '.not-available-rakeback': {
    margin: theme.spacing(1, 'auto', 0),
    paddingBottom: theme.spacing(1.5),
    maxWidth: 150,
    alignItems: 'center',
  },
}));

export default function VipClubRakebackTab({
  handleCloseVipDialog,
  handleOpenWalletDialog,
}) {
  const { translate } = useLocales();

  const { data: loyalUserData, loading } = useLoyalUserQuery({
    fetchPolicy: 'network-only',
  });

  const onClickClaimRakeback = () => {
    handleCloseVipDialog();
    handleOpenWalletDialog();
  };

  const hasAvailableRakeback = useMemo(() => {
    if (!loyalUserData) return false;

    return loyalUserData?.me?.vipUser?.vipUserCurrencies?.some(
      (vipUserCurrency) => Boolean(vipUserCurrency.instantBonusAvailableAmount),
    );
  }, [loyalUserData]);

  if (loading) {
    return (
      <Box
        position="absolute"
        width="100%"
        height="100%"
        sx={{ top: 0, left: '50%', transform: 'translate(-50%, -5%)' }}
      >
        <LoadingAnimated />
      </Box>
    );
  }

  return (
    <RateBackTabWrapper>
      <Box>
        <Typography
          color="text.contrast"
          textAlign="center"
          variant="subtitle1"
          fontWeight={700}
        >
          {translate('available_rakeback')}
        </Typography>
        {hasAvailableRakeback ? (
          <Stack pb={5}>
            <ThemedButton
              className="claim-rakeback-btn"
              onClick={() => onClickClaimRakeback()}
            >
              {translate('claim_my_rakeback')}
            </ThemedButton>
          </Stack>
        ) : (
          <Stack className="not-available-rakeback">
            <img src={birthNotAllow} alt="not-available-rake-back" />
            <Typography fontWeight={600} textAlign="center" variant="body2">
              {translate('no_rakeback_yet')}
            </Typography>
          </Stack>
        )}
      </Box>
    </RateBackTabWrapper>
  );
}
