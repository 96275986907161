import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { DialogHeaderStyled } from 'common/Dialog';
import { memo } from 'react';

import { IconButton, Typography } from '@mui/material';

interface IMiniDiceEventDialogHeaderProps {
  onClose: VoidFunction;
}

const MiniDiceEventDialogHeader = ({
  onClose,
}: IMiniDiceEventDialogHeaderProps) => (
  <DialogHeaderStyled>
    <Typography className="dialog-title" variant="h6" color="text.contrast">
      Jackpot
    </Typography>
    <IconButton className="close-btn" onClick={() => onClose()}>
      <CloseCircle width="24px" height="24px" />
    </IconButton>
  </DialogHeaderStyled>
);

export default memo(MiniDiceEventDialogHeader);
