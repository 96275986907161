import { ReactElement, useState } from 'react';

import { MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';

import { SelectStyled } from './styles';

export interface ISelectProps extends SelectProps {
  options: { label: ReactElement | string | number; value: string | number }[];
  onChange: (event: SelectChangeEvent) => void;
}

const Select = (props: ISelectProps) => {
  const { options, onChange, ...rest } = props;

  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    if (onChange) onChange(event);
  };
  return (
    <SelectStyled value={selectedValue} onChange={handleChange} {...rest}>
      {options &&
        options.map((i, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={`${i.value}_${index}`} value={i.value}>
            {i.label}
          </MenuItem>
        ))}
    </SelectStyled>
  );
};

export default Select;
