import { useReactiveVar } from '@apollo/client';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as RefreshCodgIcon } from 'assets/icons/RefreshCodgIcon.svg';
import currencyImages from 'assets/images/coins';
import FiatImages from 'assets/images/fiats';
import { Currency, TransfiFiat, useUserWalletsQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { FunctionComponent, SVGProps } from 'react';
import { viewInFiatVar } from 'store';
import { fNumber } from 'utils/formatNumber';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import { AssetsItemWrapper } from './styles';

// -----------------------------------------------------------------

interface PropsType {
  isActive: boolean;
  currency: Currency | TransfiFiat;
  handleSelectCurrency: () => void;
  handleSelectCODG?: () => void;
  handleSelectOpenWalletCODG?: () => void;
  hiddenBalance?: boolean;
}

const AssetItem = ({
  isActive,
  handleSelectCurrency,
  handleSelectCODG,
  handleSelectOpenWalletCODG,
  currency,
  hiddenBalance,
}: PropsType) => {
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const { data: userWalletsData } = useUserWalletsQuery();

  const isCurrency = (cc: Currency | TransfiFiat) =>
    (cc as Currency)?.id !== undefined;

  const IconFiat = () => {
    const IC: FunctionComponent<SVGProps<SVGSVGElement>> =
      FiatImages[`Ic${(currency as TransfiFiat)?.symbol.toUpperCase()}`];
    return IC && <IC />;
  };

  const amountBalance =
    userWalletsData?.me?.userWallets?.find(
      (userWallet) => userWallet.currencyId === (currency as Currency)?.id,
    )?.amount || 0.0;

  return (
    <AssetsItemWrapper
      isActive={isActive}
      onClick={() => handleSelectCurrency()}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        {(currency as Currency).shortName && (
          <img
            src={currencyImages[(currency as Currency)?.shortName]}
            alt={
              currency &&
              (isCurrency(currency)
                ? (currency as Currency)?.name
                : (currency as TransfiFiat)?.symbol.toLowerCase())
            }
          />
        )}

        {(currency as TransfiFiat).symbol && <IconFiat />}

        <Box className="name_box">
          <Typography variant="subtitle1" className="sort_name">
            {currency &&
              (isCurrency(currency)
                ? (currency as Currency)?.shortName
                : (currency as TransfiFiat)?.symbol)}
          </Typography>
          {(currency as TransfiFiat).country && (
            <Typography variant="caption">
              ({(currency as TransfiFiat)?.country})
            </Typography>
          )}
        </Box>
        {(currency as Currency).id === '13' && (
          <IconButton
            sx={{ p: 1, pr: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectCODG && handleSelectCODG();
            }}
          >
            <InfoCircleIcon />
          </IconButton>
        )}
      </Box>

      {!hiddenBalance && (
        <Box className="amount_box">
          <Stack direction="row" alignItems="center">
            {(currency as Currency).id === '13' && (
              <IconButton
                sx={{ p: 0.3, pr: 0, pl: 0.9, width: 32 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectOpenWalletCODG && handleSelectOpenWalletCODG();
                }}
              >
                <RefreshCodgIcon />
              </IconButton>
            )}
            <Typography
              width="100%"
              sx={{ color: 'text.contrast', fontSize: '14px !important' }}
              variant="subtitle1"
            >
              {getCurrencyAmount(amountBalance, currency as Currency, {
                onlyCurrency: true,
              })}
            </Typography>
          </Stack>
          {viewInFiat.status &&
            (currency as Currency).equivalentUsdRate !== 0 && (
              <Typography
                variant={viewInFiat.status ? 'caption' : 'body2'}
                {...(!viewInFiat.status && {
                  sx: { color: 'text.contrast', fontSize: '12px !important' },
                })}
                textAlign="right"
              >
                {getCurrencyAmount(amountBalance, currency as Currency)}
              </Typography>
            )}
        </Box>
      )}

      {currency && !isCurrency(currency) && (
        <Box className="minmax_box">
          <Typography>{`Min: ${fNumber((currency as TransfiFiat).minAmount)} (${
            (currency as TransfiFiat).symbol
          })`}</Typography>
          <Typography>{`Max: ${fNumber((currency as TransfiFiat).maxAmount)} (${
            (currency as TransfiFiat).symbol
          })`}</Typography>
        </Box>
      )}
    </AssetsItemWrapper>
  );
};

export default AssetItem;

AssetItem.defaultProps = {
  hiddenBalance: false,
  handleSelectCODG: undefined,
  handleSelectOpenWalletCODG: undefined,
};
