import { ReactComponent as IcARS } from './ars.svg';
import { ReactComponent as IcAUD } from './aud.svg';
import { ReactComponent as IcBRL } from './brl.svg';
import { ReactComponent as IcCAD } from './cad.svg';
import { ReactComponent as IcCHF } from './chf.svg';
import { ReactComponent as IcCOP } from './cop.svg';
import { ReactComponent as IcCZK } from './czk.svg';
import { ReactComponent as IcEUR } from './eur.svg';
import { ReactComponent as IcGBP } from './gbp.svg';
import { ReactComponent as IcHKD } from './hkd.svg';
import { ReactComponent as IcIDR } from './idr.svg';
import { ReactComponent as IcILS } from './ils.svg';
import { ReactComponent as IcINR } from './inr.svg';
import { ReactComponent as IcJPY } from './jpy.svg';
import { ReactComponent as IcKRW } from './krw.svg';
import { ReactComponent as IcMXN } from './mxn.svg';
import { ReactComponent as IcPHP } from './php.svg';
import { ReactComponent as IcPLN } from './pln.svg';
import { ReactComponent as IcSGD } from './sgd.svg';
import { ReactComponent as IcTHB } from './thb.svg';
import { ReactComponent as IcTRY } from './try.svg';
import { ReactComponent as IcTWD } from './twd.svg';
import { ReactComponent as IcUAH } from './uah.svg';
import { ReactComponent as IcUSD } from './usd.svg';
import { ReactComponent as IcVND } from './vnd.svg';

export default {
  IcARS,
  IcAUD,
  IcBRL,
  IcCAD,
  IcCHF,
  IcCOP,
  IcCZK,
  IcEUR,
  IcGBP,
  IcHKD,
  IcIDR,
  IcILS,
  IcINR,
  IcJPY,
  IcKRW,
  IcMXN,
  IcPHP,
  IcPLN,
  IcSGD,
  IcTHB,
  IcTRY,
  IcTWD,
  IcUAH,
  IcUSD,
  IcVND,
};
