import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import coinImages from 'assets/images/coins';
import Dialog from 'components/Dialog';
import { DialogHeader } from 'components/Dialog/styles';
import { CryptoWithdrawalInfoQuery, Currency } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useLocales from 'locales/useLocales';

import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { ConfirmedButton, ConfirmWithdrawDialogBody } from './styles';

// ----------------------------------------------------------------

interface ConfirmWithdrawDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  handleBack: () => void;
  handleConfirm: () => void;
  selectedCurrency: Currency;
  withdrawInfo?: CryptoWithdrawalInfoQuery;
  receiveAmount: number;
  available: number;
}

export const ConfirmWithdrawDialog = ({
  isOpen,
  handleClose,
  handleBack,
  receiveAmount,
  withdrawInfo,
  handleConfirm,
  selectedCurrency,
  available,
}: ConfirmWithdrawDialogProps) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const totalDecreaseAmount =
    // eslint-disable-next-line no-unsafe-optional-chaining
    receiveAmount + withdrawInfo?.cryptoWithdrawalInfo?.feeAmount;

  const handleConfirmWithdraw = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={() => {}}
      hasBackButton={false}
      handleBack={handleBack}
      style={{
        width: 633,
      }}
      bodySx={{
        padding: '0 12px 12px',
      }}
    >
      <DialogHeader
        hasCloseBtn={false}
        sx={{ padding: 0, paddingTop: '16px', marginBottom: '24px' }}
      >
        <Box onClick={handleBack} sx={{ visibility: 'hidden' }}>
          <IconButton>
            <CloseCircle width="24px" height="24px" />
          </IconButton>
        </Box>
        <Typography className="dialog-title" variant="h6" fontSize="20px">
          {translate('withdraw')}
        </Typography>
        <Box onClick={handleBack}>
          <IconButton>
            <CloseCircle width="24px" height="24px" />
          </IconButton>
        </Box>
      </DialogHeader>
      <ConfirmWithdrawDialogBody>
        <Box className="withdraw_amount">
          <div>
            <img
              src={coinImages[selectedCurrency?.shortName]}
              alt={selectedCurrency?.name}
            />
            <Typography variant="h6" fontSize="14px" color="#9998D4">
              {selectedCurrency?.shortName.toUpperCase()}
            </Typography>
          </div>
          <Typography variant="h6" color="white">
            {getCurrencyAmount(totalDecreaseAmount, selectedCurrency)}
          </Typography>
        </Box>
        <Box>
          <div className="flex">
            <Typography>
              {selectedCurrency?.shortName.toUpperCase()}{' '}
              {`${translate('balance_in_wallet')}`}
            </Typography>
            <Typography>
              {getCurrencyAmount(available, selectedCurrency)}
            </Typography>
          </div>
          <div className="flex">
            <Typography>{`${translate('withdraw_fee')}`}</Typography>
            <Typography>
              {getCurrencyAmount(
                withdrawInfo?.cryptoWithdrawalInfo?.feeAmount || 0,
                selectedCurrency,
              )}
            </Typography>
          </div>
          <div className="flex">
            <Typography>{`${translate('receive_amount')}`}</Typography>
            <Typography>
              {getCurrencyAmount(receiveAmount, selectedCurrency)}
            </Typography>
          </div>
        </Box>
        <ConfirmedButton variant="contained" onClick={handleConfirmWithdraw}>
          <Typography variant="button">{translate('withdraw')}</Typography>
        </ConfirmedButton>
      </ConfirmWithdrawDialogBody>
    </Dialog>
  );
};

export default ConfirmWithdrawDialog;
