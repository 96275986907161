import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const BuyWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '.top_box': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  'p.label': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  '.exchange_box': {
    marginTop: theme.spacing(1),
    '.exchange_rate, .route': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      'p.label': {
        marginTop: theme.spacing(1.5),
        marginBottom: 0,
        color: theme.palette.text.primary,
      },
    },
    '.exchange_rate': {
      justifyContent: 'flex-end',
    },
  },
  '.notice': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.main,
    alignSelf: 'baseline',
    marginTop: theme.spacing(6),
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.shape.borderRadius,
    p: {
      whiteSpace: 'break-spaces',
      lineClamp: 5,
      '-webkit-line-clamp': 5,
    },
    span: {
      color: '#fff',
      textTransform: 'uppercase',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
      justifyContent: 'center',
    },
  },
  '.MuiInputBase-root': {
    backgroundColor: 'transparent',
  },
}));

export const ThemedTranFiButton = styled(Button)(({ theme }) => ({
  width: '100%',
  minHeight: 48,
  borderRadius: theme.shape.borderRadius,
  marginTop: '12px',
  backgroundImage:
    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  color: '#2E2C5E',
  '&.Mui-disabled': {
    opacity: 0.7,
  },
  // position: 'absolute',
  // bottom: 0,
  ':hover': {
    backgroundImage:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.7,
  },
}));
