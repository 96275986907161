import BetDetail from 'components/_Games/BetDetail';
import { BetSummary, useHighBetsQuery, useHighBetSubscription } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { memo, useEffect, useState } from 'react';

import { TableBody } from '@mui/material';

import BetTable from '../BetTable';
import { HeaderHighBetWrapper } from './HeaderHighBet';
import { RowHighBetTableWrapper } from './RowsHighBet';

/* -------------------------------------------------------------------------- */

interface Props {
  isBetsByGame?: boolean;
  isShowInMiniGameDialog?: boolean;
  gameId?: string;
}

interface TableRowProps {
  row: Partial<BetSummary>;
}

const HighBetTable = ({
  isBetsByGame,
  isShowInMiniGameDialog,
  gameId,
}: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const defaultShowedRows = isShowInMiniGameDialog && isMobile ? 15 : 10;
  const [showedRows, setShowedRows] = useState(defaultShowedRows);
  const [rows, setRows] = useState<Partial<BetSummary>[]>([]);

  const { data: highBetsData } = useHighBetsQuery({
    variables: {
      gameId,
    },
  });

  const { data: highBetSubsData } = useHighBetSubscription({
    variables: {
      gameId,
    },
  });

  const hasMore =
    rows.length > defaultShowedRows && showedRows <= defaultShowedRows;
  const [selectedBet, setSelectedBet] = useState<Partial<BetSummary>>(null);

  useEffect(() => {
    if (highBetSubsData) {
      setRows((rows) => {
        const highBets = [...rows];
        const newLength = highBets.unshift(
          highBetSubsData.highBet as BetSummary,
        );

        if (newLength > 50) highBets.pop();
        return highBets;
      });
    }
  }, [highBetSubsData]);

  useEffect(() => {
    if (highBetsData?.highBets && rows.length === 0) {
      setRows((prev) => {
        const rows = [...prev];
        const allBets = rows.concat(highBetsData?.highBets as BetSummary[]);
        return allBets;
      });
    }
  }, [highBetsData]);

  useEffect(() => {
    setShowedRows(defaultShowedRows);
  }, [isMobile]);

  const TableRowComponent = memo(({ row }: TableRowProps) => {
    const { equivalentUsdRate, ...rowData } = row;
    return (
      <RowHighBetTableWrapper
        key={rowData.id}
        isBetsByGame={isBetsByGame}
        isShowInMiniGameDialog={isShowInMiniGameDialog}
        gameId={gameId}
        equivalentUsdRate={equivalentUsdRate}
        row={{
          id: row.id,
          betAmount: row.betAmount,
          currency: row.currency,
          payoutRate: row.payoutRate,
          payout: row.payoutAmount,
          gameName: row.game.name,
          user: row.user,
          handleClick: () => setSelectedBet(row),
        }}
      />
    );
  });

  return (
    <>
      <BetTable
        rows={rows}
        hasMore={hasMore}
        setShowedRows={setShowedRows}
        defaultShowedRows={defaultShowedRows}
      >
        <>
          <HeaderHighBetWrapper isBetsByGame={isBetsByGame} gameId={gameId} />
          <TableBody>
            {rows.slice(0, showedRows).map((row) => (
              <TableRowComponent key={row.id} row={row} />
            ))}
          </TableBody>
        </>
      </BetTable>

      {selectedBet !== null && (
        <BetDetail
          open={selectedBet !== null}
          onClose={() => {
            setSelectedBet(null);
          }}
          betId={selectedBet.id}
        />
      )}
    </>
  );
};

export { HighBetTable };
