import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { BetRoundDetailDialogHeaderStyled } from 'components/_Games/_GamesMiniDice/bet-round-detail/components/bet-round-detail-dialog-header/style';
import { memo } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';

interface IBetRoundDetailDialogHeaderProps {
  roundId: number;
  onChangeRoundId: (roundId: number) => void;
  onClose: VoidFunction;
  disableNext: boolean;
}

const BetRoundDetailDialogHeader = ({
  roundId,
  onClose,
  disableNext,
  onChangeRoundId,
}: IBetRoundDetailDialogHeaderProps) => (
  <BetRoundDetailDialogHeaderStyled
    sx={{
      padding: 0,
      height: 'unset',
    }}
  >
    <Stack direction="row" className="navigation navigation-round-id">
      <IconButton
        className="arrow-left-btn"
        onClick={() => onChangeRoundId(roundId - 1)}
      >
        <ArrowLeft />
      </IconButton>
      <Typography className="round-id" variant="subtitle2">
        #{roundId}
      </Typography>
      <IconButton
        className="arrow-right-btn"
        onClick={() => onChangeRoundId(roundId + 1)}
        disabled={disableNext}
        {...(disableNext && {
          sx: {
            cursor: 'not-allowed',
          },
        })}
      >
        <ArrowRight />
      </IconButton>
    </Stack>
    <IconButton className="close-btn" onClick={() => onClose()}>
      <CloseCircle width="24px" height="24px" />
    </IconButton>
  </BetRoundDetailDialogHeaderStyled>
);

export default memo(BetRoundDetailDialogHeader);
