import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import giftcodeVfx from 'assets/vfxs/gift-code.json';
import V2Chats from 'components/Chats';
import { GiftCodeDialog } from 'components/nav-section/GiftCodeDialog';
import V2Notifications from 'components/Notifications';
import { useLocales } from 'locales';
import Lottie from 'lottie-react';
import { useState } from 'react';

import NavItem from './NavItem';
import { NavHorizontalWrapper } from './styles';

// ----------------------------------------------------------------

interface Props {
  onOpenNav: VoidFunction;
}

export default function NavSectionHorizontal({ onOpenNav }: Props) {
  const { translate } = useLocales();
  const [isGiftcodeDialogOpen, setIsGiftcodeDialogOpen] = useState(false);
  const [isDrawerNotiOpen, setIsDrawerNoti] = useState(false);
  const [isDrawerChatOpen, setIsDrawerChat] = useState(false);

  const navItems = [
    {
      key: 'menu',
      onClick: onOpenNav,
      icon: <Menu width="24px" height="24px" />,
    },
    {
      key: 'gift_code',
      onClick: () => setIsGiftcodeDialogOpen(true),
      icon: (
        <Lottie
          style={{ maxHeight: 30, maxWidth: 30 }}
          animationData={giftcodeVfx}
        />
      ),
    },
    {
      key: 'notification',
      onClick: () => {},
      icon: (
        <V2Notifications
          drawer={isDrawerNotiOpen}
          setDrawer={(e) => setIsDrawerNoti(e)}
          isShow
        />
      ),
    },
    {
      key: 'chat_room',
      onClick: () => {},
      icon: (
        <V2Chats
          drawer={isDrawerChatOpen}
          setDrawer={(e) => setIsDrawerChat(e)}
          isShow
        />
      ),
    },
  ];

  return (
    <NavHorizontalWrapper>
      {navItems.map((navItem) => (
        <NavItem
          icon={navItem.icon}
          key={navItem.key}
          title={translate(navItem.key)}
          handleClick={navItem.onClick}
        />
      ))}
      {isGiftcodeDialogOpen && (
        <GiftCodeDialog onClose={() => setIsGiftcodeDialogOpen(false)} />
      )}
    </NavHorizontalWrapper>
  );
}
