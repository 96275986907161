import { SvgIcon } from '@mui/material';

export function IconDownMiniGame(props: any) {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="32"
        height="32"
        rx="8"
        fill="url(#paint0_linear_439_11233)"
      />
      <path
        d="M15.8252 18.6667C15.6821 18.6667 15.5391 18.6106 15.4301 18.4988L11.9987 14.9787C11.7804 14.7548 11.7804 14.3917 11.9987 14.1679C12.2169 13.944 12.5707 13.944 12.789 14.1679L15.8252 17.2827L18.8613 14.168C19.0796 13.9442 19.4334 13.9442 19.6516 14.168C19.87 14.3918 19.87 14.7549 19.6516 14.9788L16.2202 18.4989C16.1111 18.6108 15.9681 18.6667 15.8252 18.6667Z"
        fill="#9998D4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_439_11233"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#313064" />
          <stop offset="1" stopColor="#43416C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
