import Iconify from 'components/iconify';
import { useLocales } from 'locales';
import Markdown from 'markdown/Markdown';
import { useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { ReplaceHost } from 'overrides/components/text-host/TextHost';

//  =================================================================

interface Props {
  handleBack: VoidFunction;
}

const Rule = ({ handleBack }: Props) => {
  const { translate, currentLang } = useLocales();
  const [crashEventRules, setCrashEventRules] = useState('');

  useEffect(() => {
    try {
      import(`markdown/crashEvent/${currentLang.value}.md`)
        .then((response) => fetch(response.default))
        .then((response) => response.text())
        .then((text) => {
          const replacedText = ReplaceHost(text);
          setCrashEventRules(replacedText);
        })
        .catch(() => {
          setCrashEventRules(translate('coming_soon2'));
        });
    } catch (error) {
      setCrashEventRules(translate('coming_soon2'));
    }
  }, [currentLang]);

  return (
    <Box sx={{ overflowY: 'auto' }}>
      <Box display="flex" mb={2}>
        <IconButton
          sx={{
            p: 0,
            '&:hover': {
              color: 'text.contrast',
            },
          }}
          onClick={handleBack}
        >
          <Iconify icon="ep:back" />
          <Typography ml={1} variant="subtitle2" fontWeight="bold">
            {translate('back')}
          </Typography>
        </IconButton>
      </Box>
      <Markdown>{crashEventRules}</Markdown>
    </Box>
  );
};

export default Rule;
