import { ReactiveVar } from '@apollo/client';
import { ShowInsufficientBalancePopoverReactiveVar } from 'graph';

export default (
    overGameBankVar: ReactiveVar<ShowInsufficientBalancePopoverReactiveVar>,
  ) =>
  (
    isShowInsufficientBalancePopOver: boolean,
    options?: {
      onCompleted: (isShowInsufficientBalancePopOver: boolean) => void;
    },
  ) => {
    overGameBankVar({ isShowInsufficientBalancePopOver });

    /* -------------------------------------------------------------------------- */
    if (options?.onCompleted) {
      setTimeout(() => {
        options.onCompleted(isShowInsufficientBalancePopOver);
      }, 0);
    }
  };
