import { useReactiveVar } from '@apollo/client';
import { hiddenUser } from 'assets/images';
import currencyImages from 'assets/images/coins';
import Iconify from 'components/iconify/Iconify';
import { Currency } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import _ from 'lodash';
import { memo, useState } from 'react';
import { currenciesVar } from 'store';
import { fCurrency } from 'utils/formatNumber';
import { rnn } from 'utils/number-help';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import {
  Box,
  Button,
  StackProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';

import { BetNext, BetTing } from 'overrides/components/text-host/TextHost';
import { PlayerType } from './CrashServerType';
import { CrashPlayersTableContainer } from './styles';

// ----------------------------------------------------------------

interface Props extends StackProps {
  players: PlayerType[];
  roomState: 'WAITING_START' | 'STARTED' | 'STOPED';
}

function CrashPlayers({ players, roomState, ...props }: Props) {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const { currencies } = useReactiveVar(currenciesVar);

  const [showLess, setShowLess] = useState(true);

  const getCurrency = (currencyId: string | number): Currency => {
    if (!currencyId) return null;
    return currencies.find((currency) => currency.id === String(currencyId));
  };

  const filterPlayers = () =>
    players
      ?.filter(
        (r) =>
          r?.status === 'BET' ||
          r?.status === 'WIN' ||
          r?.status === 'LOSE' ||
          r?.status === 'PROCESS',
        // r?.status === 'BET_NEXT',
      )
      ?.sort((a, b) => b.win_amount - a.win_amount);

  const totalFilterPlayer = () => filterPlayers()?.length || 0;

  const totalFilterPlayerBet = () =>
    filterPlayers()?.filter((r) => r?.status === 'WIN')?.length || 0;

  const totalFilterPlayerBetUSD = () =>
    filterPlayers()
      ?.filter(
        (p) =>
          p?.status === 'BET' &&
          p?.currency_id !== '2' &&
          p?.currency_id !== '13',
      )
      ?.reduce(
        (sum, p) =>
          sum +
          (p?.bet_amount || 0) *
            (getCurrency(p?.currency_id)?.equivalentUsdRate || 0),
        0,
      );

  const playerWinMax = () =>
    _.maxBy(
      filterPlayers()?.filter((r) => r.currency_id !== '2'),
      'win_amount',
    );

  return (
    <Stack ml={{ md: 1.5 }} mt={{ md: 0, xs: 1.5 }} spacing={1.5} {...props}>
      <CrashPlayersTableContainer>
        {roomState !== 'STOPED' && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 1.5, borderBottom: `dashed #2F2B60;` }}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Iconify icon="bxs:user" color="text.contrast" width={16} />
              <Typography variant="subtitle2" color="text.contrast">
                {totalFilterPlayerBet()} / {totalFilterPlayer()}
              </Typography>
            </Stack>
            <Typography variant="subtitle2" color="text.contrast">
              {fCurrency(totalFilterPlayerBetUSD())}
            </Typography>
          </Stack>
        )}

        {roomState === 'STOPED' && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 1.5, borderBottom: `dashed #2F2B60;` }}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="caption" color="text.contrast">
                {playerWinMax()?.win_amount > 0 &&
                  `🎉 ${translate('congrats')} ${
                    playerWinMax().display_name || 'Hidden'
                  } ${translate('won_the_most')}`}
                {(!playerWinMax() || playerWinMax()?.win_amount === 0) &&
                  `🤪 ${translate('HOST_took_it_all')}`}
              </Typography>
            </Stack>
            <Typography variant="subtitle2" color="text.contrast">
              {fCurrency(
                (playerWinMax()?.win_amount || 0) *
                  (getCurrency(playerWinMax()?.currency_id)
                    ?.equivalentUsdRate || 0),
              )}
            </Typography>
          </Stack>
        )}

        <Table stickyHeader aria-label="crash-players">
          <TableBody>
            {filterPlayers()
              ?.slice(0, showLess ? 14 : totalFilterPlayer())
              ?.map((player) => (
                <TableRow
                  key={player?.user_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" width="30%">
                    {player?.display_name ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="left"
                      >
                        <img
                          className="avatar"
                          alt={player?.display_name}
                          src={player?.avata_url || '/assets/ava1.jpg'}
                        />
                        <Typography color="text.contrast" className="user_name">
                          {player?.display_name}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="left"
                      >
                        <img className="avatar" alt="hidden" src={hiddenUser} />
                        <Typography className="user_name" color="text.primary">
                          {translate('hidden')}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    width="20%"
                  >
                    <Typography color="text.contrast">
                      {player?.status === 'WIN' &&
                        `${
                          !Number.isNaN(Number(player?.win_payout))
                            ? rnn(Number(player?.win_payout), 2)
                            : player?.win_payout
                        }x`}

                      {player?.status === 'LOSE' && `bumm`}
                      {player?.status === 'BET' && translate(BetTing())}
                      {player?.status === 'BET_NEXT' && translate(BetNext())}
                      {player?.status === 'WAIT' && `---`}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={player?.win_payout > 0 ? 'increase' : 'decrease'}
                    align="right"
                    sx={{ width: { sm: '40%', xs: '30%' } }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="right"
                    >
                      <Typography className="profit">
                        {(player?.status === 'WIN' ||
                          player?.status === 'LOSE') &&
                          getCurrencyAmount(
                            player?.win_amount,
                            getCurrency(player?.currency_id),
                          )}
                        {player?.status !== 'WIN' &&
                          player?.status !== 'LOSE' &&
                          getCurrencyAmount(
                            player?.bet_amount,
                            getCurrency(player?.currency_id),
                          )}
                      </Typography>
                      <img
                        className="currency_image"
                        alt={getCurrency(player?.currency_id)?.shortName || ''}
                        src={
                          currencyImages[
                            getCurrency(player?.currency_id)?.shortName || ''
                          ]
                        }
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {filterPlayers()?.length > 9 && (
          <Button
            onClick={() => {
              setShowLess(!showLess);
            }}
            endIcon={
              showLess ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpRoundedIcon />
              )
            }
            size="small"
            variant="soft"
            sx={{ p: 0.1, '&:hover': { backgroundColor: 'transparent' } }}
          >
            <Typography>
              {showLess ? translate('show_more') : translate('show_less')}
            </Typography>
          </Button>
        )}
      </CrashPlayersTableContainer>
    </Stack>
  );
}
export default memo(CrashPlayers);
