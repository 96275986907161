import { SvgIcon } from '@mui/material';

export function IconTwitterX(props: any) {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="_&#195;&#171;&#195;&#142;&#195;&#147;&#195;&#136;_1"
        clipPath="url(#clip0_34_1382)"
      >
        <path
          id="Vector"
          d="M34.4268 34.1423C42.2374 26.3317 42.2374 13.6683 34.4268 5.85772C26.6163 -1.95285 13.9528 -1.95285 6.14228 5.85772C-1.66828 13.6683 -1.66828 26.3317 6.14228 34.1423C13.9528 41.9529 26.6163 41.9529 34.4268 34.1423Z"
          fill="#2F2B60"
        />
        <path
          id="Vector_2"
          d="M22.0427 18.7907L29.4192 10H27.4191L21.0429 17.599L14.6667 10H8L17.7095 21.5713L10.637 30H12.6371L18.7094 22.763L24.7816 30H31.4483L22.0427 18.7907ZM10.8592 11.3333H14.0448L28.5891 28.6667H25.4035L10.8592 11.3333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_34_1382">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
