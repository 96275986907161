import { styled, Tabs } from '@mui/material';

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  height: 40,
  minHeight: 40,
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1.5),
  backgroundColor: theme.palette.background.main,
  'div[role="tablist"]': {
    height: '100%',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
  },
  '&& .MuiTab-root': {
    flex: 1,
    fontSize: 14,
    lineHeight: '20px',
    marginRight: 0,
    minHeight: 40,
    minWidth: 'fit-content',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
    path: {
      fill: theme.palette.text.primary,
    },
    '&.Mui-selected': {
      background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
      color: theme.palette.text.contrast,
      path: {
        fill: theme.palette.text.contrast,
      },
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'inherit',
    },
  },
  '.MuiTabs-indicator': {
    visibility: 'hidden',
  },
}));
