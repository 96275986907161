import { useReactiveVar } from '@apollo/client';
import coins from 'assets/images/coins';
import StickyHeaderTable from 'common/Table/StickyHeaderTable';
import PlayerBox from 'components/PlayerBox';
import { RoomDetail } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { memo } from 'react';
import { DeepPartial } from 'react-hook-form';
import { currenciesVar } from 'store';

import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BetAmountHost } from 'overrides/components/text-host/TextHost';

interface IOverTableProps {
  winSide: 'over' | 'under';
  overData: DeepPartial<RoomDetail>;
}

const OverTable = ({ winSide, overData }: IOverTableProps) => {
  const { translate } = useLocales();
  const { currencies } = useReactiveVar(currenciesVar);
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const getCurrencyById = (id) =>
    currencies.find((currency) => currency.id === id);

  return (
    <StickyHeaderTable>
      <TableHead>
        <TableRow>
          <TableCell className="player" align="left">
            {translate('player')}
          </TableCell>
          <TableCell className="bet-amount" align="center">
            {translate(BetAmountHost())}
          </TableCell>
          <TableCell className="refund" align="center">
            {translate('refund')}
          </TableCell>
          {winSide === 'over' && (
            <TableCell className="win-amount" align="right">
              <Typography textTransform="lowercase">
                {translate('win')}
              </Typography>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {overData?.listBet.map((row) => (
          <TableRow key={row.id}>
            <TableCell className="player" align="left">
              <PlayerBox user={row.user as any} align="left" />
            </TableCell>
            <TableCell className="bet-amount" align="center">
              <Box display="flex" alignItems="center" justifyContent="right">
                <Typography>
                  {getCurrencyAmount(row.betValue, {
                    ...getCurrencyById(row.currencyId),
                    equivalentUsdRate: row.rateToBaseCurrency,
                  })}
                </Typography>
                <img
                  className="currency-image"
                  alt={getCurrencyById(row.currencyId)?.shortName}
                  src={coins[getCurrencyById(row.currencyId)?.shortName]}
                />
              </Box>
            </TableCell>
            <TableCell className="refund" align="center">
              <Box display="flex" alignItems="center" justifyContent="right">
                <Typography>
                  {getCurrencyAmount(row.refundValue, {
                    ...getCurrencyById(row.currencyId),
                    equivalentUsdRate: row.rateToBaseCurrency,
                  })}
                </Typography>
                <img
                  className="currency-image"
                  alt={getCurrencyById(row.currencyId)?.shortName}
                  src={coins[getCurrencyById(row.currencyId)?.shortName]}
                />
              </Box>
            </TableCell>
            {winSide === 'over' && (
              <TableCell className="win-amount" align="center">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography>
                    {getCurrencyAmount(row.winValue, {
                      ...getCurrencyById(row.currencyId),
                      equivalentUsdRate: row.rateToBaseCurrency,
                    })}
                  </Typography>
                  <img
                    className="currency-image"
                    alt={getCurrencyById(row.currencyId)?.shortName}
                    src={coins[getCurrencyById(row.currencyId)?.shortName]}
                  />
                </Box>
              </TableCell>
            )}
          </TableRow>
        ))}
        {!overData?.listBet?.length && (
          <TableRow>
            <TableCell colSpan={winSide === 'over' ? 4 : 3}>
              <Typography textAlign="center" color="text.primary">
                {translate('empty_data')}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </StickyHeaderTable>
  );
};

export default memo(OverTable);
