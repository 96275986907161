import { IP_REGISTRY } from 'constants/config';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

//
import enLocales from './langs/en';
import esLocales from './langs/es';
import jaLocales from './langs/ja';
import krLocales from './langs/kr';
import ptLocales from './langs/pt';
import trLocales from './langs/tr';
import twLocales from './langs/tw';
import viLocales from './langs/vi';
import { IPRegistry } from './type';
//
import { allLangs, defaultLang } from './config-lang';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';

// ----------------------------------------------------------------------

let lng = defaultLang.value;

const storageAvailable = localStorageAvailable();

let dataRegistry: IPRegistry = null;
try {
  dataRegistry = JSON.parse(localStorage.getItem(IP_REGISTRY));
} catch (error) {
  /* empty */
}

if (storageAvailable) {
  lng =
    localStorage.getItem('i18nextLng') ||
    allLangs?.find((l) => l?.value === dataRegistry?.location?.language?.code)
      ?.value ||
    defaultLang.value;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      vi: { translations: viLocales },
      kr: { translations: krLocales },
      tw: { translations: twLocales },
      es: { translations: esLocales },
      pt: { translations: ptLocales },
      tr: { translations: trLocales },
      ja: { translations: jaLocales },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
