import { ReactComponent as SnapshotIcon } from 'assets/icons/id-snapshot.svg';
import { useLocales } from 'locales';

import { Stack, StackProps, Typography } from '@mui/material';

export default function Placeholder({ sx, label, ...other }: StackProps<any>) {
  const { translate } = useLocales();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={3}
      sx={{
        width: 1,
        textAlign: {
          xs: 'center',
          md: 'left',
        },
        ...sx,
        '&:hover': {
          'h6, svg': { color: 'text.contrast' },
        },
      }}
      {...other}
    >
      <Typography
        className="label"
        textAlign={{ sm: 'center', xs: 'left' }}
        color="#9998D4 !important"
      >
        {label}
      </Typography>
      <SnapshotIcon width="70px" height="56px" />
      <Typography variant="body2">
        {translate('file_size_limit_10MB')}
      </Typography>
    </Stack>
  );
}
