import { ReactComponent as IconArrowDownWallet } from 'assets/icons/IconArrowDownWallet.svg';
import { ReactComponent as IconArrowUpWallet } from 'assets/icons/IconArrowUpWallet.svg';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import React from 'react';

import { Box, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';

import { WalletSubTabItemWrapper, WalletTabItemWrapper } from './styles';

/* -------------------------------------------------------------------------- */

interface WalletTabItemProps {
  sx?: SxProps;

  isActive: boolean;
  isDisable?: boolean;

  tab: {
    key: string;
    icon: React.ReactNode;
    iconColor?: boolean;
    subTab?: Array<any>;
    label?: string;
  };

  selectedSubTab?: {
    id: number;
    label: string;
    icon: React.ReactNode;
  };
  handleSelectTab: () => void;
  handleSelectSubTab?: (e: {
    id: number;
    label: string;
    icon: React.ReactNode;
  }) => void;
}
const WalletTabItem = ({
  sx,
  isActive,
  isDisable,
  tab,
  selectedSubTab,
  handleSelectTab,
  handleSelectSubTab,
}: WalletTabItemProps) => {
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        backgroundColor: '#24234A',
        borderRadius: '12px',
        display: isMobile ? 'contents' : 'unset',
      }}
    >
      <WalletTabItemWrapper
        $isIconColor={tab.iconColor}
        $isActive={isActive}
        onClick={handleSelectTab}
        $isDisable={isDisable}
        sx={sx}
        className="wallet-tab-item"
      >
        {tab.icon && (
          <div
            className={`wallet_item_icon ${
              isActive ? 'wallet_item_icon--active' : ''
            }`}
          >
            {tab.icon}
          </div>
        )}

        {(!isMobile || isActive) && (
          <Typography className="wallet_item_label">
            {translate(tab.label || tab.key)}
          </Typography>
        )}

        {!isTablet && tab.subTab && tab.subTab?.length > 0 && (
          <Box className="wallet_item_arrow_icon">
            {isActive ? <IconArrowUpWallet /> : <IconArrowDownWallet />}
          </Box>
        )}
      </WalletTabItemWrapper>

      {!isTablet && isActive && tab.subTab && tab.subTab?.length > 0 && (
        <Box>
          {tab.subTab?.map((e) => (
            <WalletSubTabItemWrapper
              $isActive={selectedSubTab.id === e.id}
              key={e.id}
              onClick={() => handleSelectSubTab(e)}
            >
              <Box
                className={`sub_tab ${
                  selectedSubTab.label === e.label && `subtab_icon_${e.label}`
                }`}
              >
                {e.icon}
              </Box>
              <Typography className="wallet_item_label">{e.label}</Typography>
            </WalletSubTabItemWrapper>
          ))}
        </Box>
      )}
    </Box>
  );
};

WalletTabItem.defaultProps = {
  sx: {},
  isDisable: false,
  selectedSubTab: undefined,
  handleSelectSubTab: undefined,
};
export default WalletTabItem;
