import { useReactiveVar } from '@apollo/client';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { DialogBody, DialogWrapper } from 'components/Dialog/styles';
import Iconify from 'components/iconify';
import LoadingAnimated from 'components/LoadingAnimated';
import { StaticTableContent } from 'components/_Games/StatisticTable';
import { StatisticBox } from 'components/_Games/StatisticTable/styles';
import {
  threeDicePerDiceChartConfig,
  threeDiceSumChartConfig,
} from 'components/_Games/_GamesMiniDice/mini-dice-trends/chart-config';
import { StyledMiniDiceTrendsChart } from 'components/_Games/_GamesMiniDice/mini-dice-trends/styles';
import { motion, useDragControls } from 'framer-motion';
import { ThreeDiceHistoriesQuery, useThreeDiceHistoriesQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import {
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { storageCurrencyVar } from 'store';

import {
  Box,
  ClickAwayListener,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { debounce } from 'lodash';

type DicesSeriesData = {
  dice1s: number[];
  dice2s: number[];
  dice3s: number[];
};

interface IMiniDiceTrendProps {
  onClose: VoidFunction;
}

const MiniDiceTrends = ({ onClose }: IMiniDiceTrendProps) => {
  const { translate } = useLocales();
  const controls = useDragControls();
  const isMobile = useResponsive('down', 'sm');
  const [ref, setRef] = useState<MutableRefObject<HTMLElement>>({
    current: null,
  });
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const lastRoundId = useRef('');
  const diceSumChartRef = useRef(null);
  const dicesSeriesChartRef = useRef(null);
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const [diceSumSeriesData, setDiceSumSeriesData] = useState([]);
  const [dicesSeriesData, setDicesSeriesData] = useState<DicesSeriesData>(null);
  const {
    data: threeDiceHistories,
    loading,
    refetch,
  } = useThreeDiceHistoriesQuery({
    variables: {
      currencyId: storageCurrency.id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const threeDiceCanvas = document.getElementById('threedice');

  const getDiceSumSeriesData = (
    foreCasts: ThreeDiceHistoriesQuery['threeDiceCurrentGame']['foreCasts'],
  ) =>
    foreCasts
      .slice(0, 20)
      .reverse()
      ?.map((item) => item.diceSum);

  const getDicesSeriesData = (
    foreCasts: ThreeDiceHistoriesQuery['threeDiceCurrentGame']['foreCasts'],
  ) => {
    const defaultReduceValue = {
      dice1s: [],
      dice2s: [],
      dice3s: [],
    };

    return foreCasts
      .slice(0, 20)
      .reverse()
      ?.reduce((result, curVal) => {
        lastRoundId.current = curVal.id;
        return {
          dice1s: [...result.dice1s, curVal.dice1],
          dice2s: [...result.dice2s, curVal.dice2],
          dice3s: [...result.dice3s, curVal.dice3],
        };
      }, defaultReduceValue);
  };

  const statisticTableData = useMemo(
    () =>
      (threeDiceHistories?.threeDiceCurrentGame?.foreCasts || [])
        ?.slice(0, 20)
        ?.reverse()
        ?.map((item) => ({
          payout: item.diceSum,
        })),
    [threeDiceHistories],
  );

  const getStatisticCircleColor = (diceSum) => {
    if (diceSum <= 10) return '#FF5C00';
    if (diceSum > 10) return '#53BA0C';

    return '';
  };

  const refetchForeCasts = async () => {
    try {
      const response = await refetch();

      if (diceSumChartRef?.current?.chart?.updateSeries) {
        const newSeriesData = getDiceSumSeriesData(
          response?.data?.threeDiceCurrentGame?.foreCasts,
        );

        diceSumChartRef?.current?.chart?.updateSeries([
          {
            data: newSeriesData,
          },
        ]);
      }

      if (dicesSeriesChartRef?.current?.chart?.updateSeries) {
        const newSeries = getDicesSeriesData(
          response?.data?.threeDiceCurrentGame?.foreCasts,
        );

        dicesSeriesChartRef?.current?.chart?.updateSeries([
          { data: newSeries.dice1s },
          { data: newSeries.dice2s },
          { data: newSeries.dice3s },
        ]);
      }
    } catch (e) {
      /* empty */
    }
  };

  useEffect(() => {
    if (
      !threeDiceHistories?.threeDiceCurrentGame?.foreCasts ||
      diceSumSeriesData.length > 0
    )
      return;
    setDiceSumSeriesData(() =>
      getDiceSumSeriesData(threeDiceHistories?.threeDiceCurrentGame?.foreCasts),
    );
  }, [threeDiceHistories]);

  useEffect(() => {
    if (!threeDiceHistories?.threeDiceCurrentGame?.foreCasts || dicesSeriesData)
      return;

    setDicesSeriesData(() =>
      getDicesSeriesData(threeDiceHistories?.threeDiceCurrentGame?.foreCasts),
    );
  }, [threeDiceHistories]);

  useEffect(() => {
    window.ccg_threedice.onStartNewRound = () => {
      refetchForeCasts();
    };

    window.addEventListener('focus', refetchForeCasts);

    return () => {
      window.removeEventListener('focus', refetchForeCasts);
    };
  }, [diceSumSeriesData, diceSumSeriesData]);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      const threediceTrendsEl = document.getElementById('threedice-trends');
      setRef({ current: threediceTrendsEl });
    }, 300);

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (ref?.current) {
          ref.current.style.zIndex = '999';
        }
      }}
      mouseEvent="onPointerDown"
      touchEvent="onTouchStart"
    >
      <Box
        id="threedice-trends"
        drag={!isMobile}
        component={motion.div}
        dragControls={controls}
        dragConstraints={{
          top: -ref.current?.getBoundingClientRect()?.top,
          left: -ref.current?.getBoundingClientRect()?.left,
          right: windowWidth - ref.current?.getBoundingClientRect()?.right,
          bottom: windowHeight - ref.current?.getBoundingClientRect()?.bottom,
        }}
        onPointerDown={(event) => {
          controls.start(event);
        }}
        ref={(ref: HTMLElement) => setRef({ current: ref })}
        onPointerDownCapture={() => {
          if (ref?.current) {
            ref.current.style.zIndex =
              `${Number(threeDiceCanvas?.style?.zIndex) + 1}` || '10000';
          }
        }}
        style={{
          position: 'fixed',
          ...(isMobile && {
            width: '100vw',
            height: '100vh',
          }),
          zIndex: threeDiceCanvas?.style?.zIndex + 1 || 10000,
          ...(!isMobile && {
            top: threeDiceCanvas?.offsetTop + 20,
            left: threeDiceCanvas?.offsetLeft + threeDiceCanvas?.clientWidth,
          }),
        }}
        {...(isMobile && {
          sx: { transform: 'none !important' },
        })}
      >
        <StatisticBox
          sx={{
            pt: 1.5,
            width: { sm: 430, xs: '100vw' },
            height: { xs: '100%', sm: 'fit-content' },
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={0.7}>
              <Iconify icon="icon-park-solid:trend" width={22} />
              <Typography variant="subtitle1">{translate('trends')}</Typography>
            </Stack>
            <IconButton
              sx={{ p: 0 }}
              className="close-btn"
              onClick={() => onClose()}
            >
              <CloseCircle width="24px" height="24px" />
            </IconButton>
          </Stack>

          <DialogWrapper sx={{ p: { xs: 0 } }}>
            <DialogBody
              sx={{
                display: 'flex',
                overflow: 'hidden',
                height: 'fit-content',
                padding: { xs: 0 },
              }}
            >
              {loading ? (
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  sx={{
                    top: 0,
                    left: '50%',
                    transform: 'translate(-50%, -5%)',
                  }}
                >
                  <LoadingAnimated />
                </Box>
              ) : (
                <Box width="100%">
                  <Box
                    pl={5}
                    pr={1.5}
                    mt={2}
                    sx={{
                      rect: {
                        fill: 'rgba(22, 20, 50, .7)',
                      },
                    }}
                  >
                    <StaticTableContent
                      results={statisticTableData}
                      getCircleColor={getStatisticCircleColor}
                      getCircleType={(diceSum) => {
                        if (diceSum <= 10) {
                          return 1;
                        }
                        return 2;
                      }}
                    />
                  </Box>
                  <StyledMiniDiceTrendsChart
                    height={isMobile ? 140 : 155}
                    ref={diceSumChartRef}
                    width="100%"
                    series={[
                      {
                        data: diceSumSeriesData,
                      },
                    ]}
                    options={threeDiceSumChartConfig}
                  />
                  <StyledMiniDiceTrendsChart
                    height={isMobile ? 160 : 170}
                    ref={dicesSeriesChartRef}
                    width="100%"
                    series={[
                      {
                        name: 'Dice 1',
                        data: dicesSeriesData?.dice1s,
                      },
                      {
                        name: 'Dice 2',
                        data: dicesSeriesData?.dice2s,
                      },
                      {
                        name: 'Dice 3',
                        data: dicesSeriesData?.dice3s,
                      },
                    ]}
                    options={threeDicePerDiceChartConfig}
                  />
                </Box>
              )}
            </DialogBody>
          </DialogWrapper>
        </StatisticBox>
      </Box>
    </ClickAwayListener>
  );
};

export default memo(MiniDiceTrends);
