import { accountNotConditions } from 'assets/images';
import Button from 'common/Button';
import { useLocales } from 'locales';
import numeral from 'numeral';
import { useNavigate } from 'react-router';

import { Box, Stack, Typography } from '@mui/material';

import { AccountNotConditionsWrapper } from './styles';

// -----------------------------------------------------

export default function AccountNotConditions({
  wager,
  wagerRequre,
  handleClose,
}) {
  const { translate } = useLocales();
  const navigate = useNavigate();

  return (
    <AccountNotConditionsWrapper className="box">
      <Box className="frame-wrapper">
        <Box className="frame">
          <img className="xmg" alt="Frame" src={accountNotConditions} />
          <Stack spacing={2} justifyContent="center">
            <Typography color="text.contrast" className="text-wrapper">
              {translate('account_not_conditions')}
            </Typography>
            <Typography variant="subtitle2" className="p" whiteSpace="pre-wrap">
              {translate('you_need_to_wager')}{' '}
              <b style={{ color: 'yellow', fontWeight: '700' }}>
                {' '}
                {numeral(wagerRequre - wager).format('$0,0.00')}{' '}
              </b>
              {translate('more_to_withdraw_due_to_the_bonus_money_policies')}
            </Typography>
            <Box>
              <Button
                onClick={() => {
                  navigate('/casino');
                  handleClose();
                }}
                sx={{ minHeight: 44, mt: 4, width: '100%' }}
              >
                Play Game
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </AccountNotConditionsWrapper>
  );
}
