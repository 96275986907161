import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { qrCodeDepositPi } from 'assets/images/index';
import {
  contactAgent,
  sendYourID,
  transferPi,
} from 'assets/images/quickAction';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';

import { Box, IconButton, Typography } from '@mui/material';

import { ConfirmedButton, DepositPiWrapper } from './styles';

// ------------------------------------------------------------

export default function DepositPi() {
  const isMobile = useResponsive('down', 'sm');
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { copy } = useCopyToClipboard();

  const imageDeposit = [
    {
      id: 1,
      image: transferPi,
      lineColor: 'linear-gradient(90deg, #9123FF 0%, #BB53FA 100%)',
      title: 'transfer_pi',
      content: 'transfer_Pi_to_the_address_above_which_is_our_agent_address',
    },
    {
      id: 2,
      image: contactAgent,
      lineColor: 'linear-gradient(90deg, #E03FFA 0%, #EC65DE 100%)',
      title: 'meet_our_live_support',
      content: 'click_the_i_transferred_Pi',
    },
    {
      id: 3,
      image: sendYourID,
      title: 'confirm_transaction',
      content:
        'send_your_transaction_and_screenshot_to_the_chat_box_as_confirmation',
    },
  ];

  const handleShowConfirm = () => {
    window.Intercom(
      'showNewMessage',
      // 'Wait we in few minutes. Supporter will appear now...',
    );
  };

  const HowToDepositPi = () => (
    <Box className="how_to_wrapper">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '0 10%',
        }}
      >
        {imageDeposit.map((e) => (
          <>
            <Box component="img" src={e.image} />
            {e.lineColor && (
              <Box
                component="div"
                className="line_image"
                sx={{
                  backgroundImage: e.lineColor,
                }}
              />
            )}
          </>
        ))}
      </Box>

      <Box className="content_wrapper">
        {imageDeposit.map((e) => (
          <Box key={e.id} className="content_space">
            <Typography fontSize="12px" color="#FFF" fontWeight={600}>
              {translate(e.title)}
            </Typography>
            <Typography whiteSpace="normal" fontSize="12px">
              {translate(e.content)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <DepositPiWrapper>
      <Typography>{translate('transfer_Pi_to_this_address')}</Typography>
      <Box
        className="address_wrapper"
        display="flex"
        flexDirection={!isMobile ? 'row' : 'column'}
        alignItems="center"
        my={1}
      >
        <Box component="img" src={qrCodeDepositPi} className="qr_code_image" />

        <Box className="address_detail">
          <Typography className="title_address">Deposit address</Typography>
          <Box className="box_detail">
            <Typography>
              GDSFRRCPAPWE5WTZNOQXUPGOBU55QD3EEEEW6J34ILCTADPKSHZ3WWJS
            </Typography>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                copy(
                  'GDSFRRCPAPWE5WTZNOQXUPGOBU55QD3EEEEW6J34ILCTADPKSHZ3WWJS',
                );
                enqueueSnackbar(translate('copied'));
              }}
            >
              <Copy width="20px" height="20px" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Typography>{translate('how_to_deposit_Pi')}</Typography>
      <HowToDepositPi />
      <ConfirmedButton
        variant="contained"
        color="secondary"
        onClick={handleShowConfirm}
      >
        <Typography variant="button">
          {translate('i_transferred_Pi')}
        </Typography>
      </ConfirmedButton>
    </DepositPiWrapper>
  );
}
