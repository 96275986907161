import { IconDice1 } from 'assets/icons/IconDice1';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { USD } from 'assets/images';
import signUpVfx from 'assets/vfxs/sign-up.json';
import { useAuthContext } from 'auth/useAuthContext';
import Button from 'common/Button';
import ChangeLanguageDialog from 'components/ChangeLanguageDialog';
import V2Chats from 'components/Chats';
import {
  LogoBox,
  MenuIconButton,
} from 'components/nav-section/vertical/styles';
import { useSettingsContext } from 'components/settings';
import { motion } from 'framer-motion';
import { useGamesQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import Lottie from 'lottie-react';
import { Logo } from 'overrides/images/header';
import { LogoMobile } from 'overrides/images/logo-mobile';
import { AuthScreen } from 'pages/Authentication/Authentication';
import { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { updateCacheGames } from 'store/operations/update-cache-opts/updateCacheGames';

import { Box, Typography } from '@mui/material';

import AuthenticatedUser from './AuthenticatedUser';
import {
  AuthenticationBox,
  HeaderButtonIcon,
  LanguageFiat,
  V2StyledContainer,
  V2_HeaderWrapper,
} from './styles';

// ----------------------------------------------------------------------

type Props = {
  isNavOpen: boolean;
  isDrawerNotiOpen: boolean;
  isDrawerChatOpen: boolean;
  setOpenNav: (e) => void;
  setIsDrawerNoti: (e) => void;
  setIsDrawerChat: (e) => void;
};

function Header({
  isNavOpen,
  isDrawerNotiOpen,
  isDrawerChatOpen,
  setIsDrawerNoti,
  setIsDrawerChat,
  setOpenNav,
}: Props) {
  const { onChangeLang, currentLang, translate } = useLocales();
  const { isAuthenticated, openLoginModal } = useAuthContext();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLang);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (language) => {
    setSelectedLanguage(language);
    onChangeLang(language.value);
    handleClose();
  };

  const { onToggleRightSideBar } = useSettingsContext();

  const { data: gamesData } = useGamesQuery({
    variables: {
      page: 1,
      pageSize: 100,
    },
    onCompleted(data) {
      updateCacheGames(data);
    },
  });

  const navigateToHomePage = () => {
    navigate('/');
  };

  const isMobile = useResponsive('down', 'sm');

  const randomGamePath = (() => {
    if (!gamesData?.games?.data?.length) {
      return;
    }
    const randomIndex = Math.floor(Math.random() * gamesData.games.data.length);

    return `casino/original/${gamesData.games.data[randomIndex].game?.slug}`;
  })();

  const variants = {
    show: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
    hide: {
      opacity: 0,
      y: -100,
      transition: { duration: 1, ease: 'easeInOut' },
    },
  };

  const handleToggleNav = () => {
    setOpenNav((prev) => !prev);
  };

  const renderLogo = () => (
    <LogoBox id="logo-box">
      <MenuIconButton
        onClick={() => {
          handleToggleNav();
        }}
      >
        <Menu width="24px" height="24px" />
      </MenuIconButton>
      <Link className="logo" to="/" onClick={() => navigateToHomePage()}>
        <Logo />
      </Link>
    </LogoBox>
  );

  useEffect(() => {
    if (isDrawerChatOpen || isDrawerNotiOpen) onToggleRightSideBar(true);
    if (!isDrawerChatOpen && !isDrawerNotiOpen) onToggleRightSideBar(false);
  }, [isDrawerChatOpen, isDrawerNotiOpen]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <V2_HeaderWrapper
      id="header"
      key="header"
      component={motion.div}
      variants={variants}
      animate="show"
      exit="hide"
    >
      {isMobile && (
        <Link
          className="mobile_logo"
          to="/"
          onClick={() => navigateToHomePage()}
        >
          <LogoMobile />
        </Link>
      )}

      {!isMobile && renderLogo()}

      <V2StyledContainer isNavCollapse={!isNavOpen}>
        {!isMobile && (
          <Box className="random_dice" component={Link} to={randomGamePath}>
            <HeaderButtonIcon>
              <IconDice1
                id="dice-header"
                style={{ width: '24px', height: '24px' }}
              />
            </HeaderButtonIcon>
          </Box>
        )}

        <Box
          justifySelf="flex-end"
          display="flex"
          justifyItems="flex-end"
          alignItems="center"
        >
          {isAuthenticated ? (
            <AuthenticatedUser
              isDrawerNotiOpen={isDrawerNotiOpen}
              setIsDrawerNoti={(e) => setIsDrawerNoti(e)}
              isDrawerChatOpen={isDrawerChatOpen}
              setIsDrawerChat={(e) => setIsDrawerChat(e)}
            />
          ) : (
            <AuthenticationBox className="unauthenticated-box">
              <Button onClick={() => openLoginModal()}>
                {translate('sign_in')}
              </Button>

              <Button
                onClick={() => {
                  openLoginModal(AuthScreen.SIGN_UP);
                  window.Telegram.WebApp.MainButton.text = 'Pay :)';
                  window.Telegram?.WebApp?.MainButton?.show();
                }}
              >
                {translate('sign_up')}
                <Lottie className="lottie-sign-up" animationData={signUpVfx} />
              </Button>
            </AuthenticationBox>
          )}

          {!isMobile && (
            <>
              <V2Chats
                drawer={isDrawerChatOpen}
                setDrawer={(e) => {
                  setIsDrawerNoti(false);
                  setIsDrawerChat(e);
                }}
                isShow
              />

              <LanguageFiat>
                <Box className="language_box" onClick={handleClick}>
                  <img
                    src={selectedLanguage.icon}
                    alt={selectedLanguage.label}
                  />
                </Box>

                <ChangeLanguageDialog
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  onSelect={handleSelect}
                />

                {isAuthenticated && (
                  <Box className="fiat_box">
                    <img src={USD} alt="USD" />
                    <Typography component="span" variant="subtitle1">
                      USD
                    </Typography>
                  </Box>
                )}
              </LanguageFiat>
            </>
          )}
        </Box>
      </V2StyledContainer>
    </V2_HeaderWrapper>
  );
}

export default memo(Header);
