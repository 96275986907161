import { ReactiveVar } from '@apollo/client';
import { setStorageCurrency } from 'auth/utils';
import { CurrenciesQuery, StorageCurrencyReactiveVar } from 'graph';

export default (storageCurrencyVar: ReactiveVar<StorageCurrencyReactiveVar>) =>
  (
    storageCurrency: ArrayElement<CurrenciesQuery['currencies']>,
    options?: {
      onCompleted: (
        storageCurrency: ArrayElement<CurrenciesQuery['currencies']>,
      ) => void;
    },
  ) => {
    storageCurrencyVar({ storageCurrency });
    setStorageCurrency(storageCurrency);

    /* -------------------------------------------------------------------------- */
    if (options?.onCompleted) {
      setTimeout(() => {
        options.onCompleted(storageCurrency);
      }, 0);
    }
  };
