import { ReactComponent as EighTeenPlusIcon } from 'assets/icons/icon-18-plus.svg';
import { logoGC, logoOrisun } from 'assets/images';
import Iconify from 'components/iconify/Iconify';
import { LOGO_LINK } from 'constants/index';
import useActiveLink from 'hooks/useActiveLink';
import useLocales from 'locales/useLocales';
import { LogoFooter } from 'overrides/images/footer';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { FooterContact } from 'overrides/components/footer-contact';
import { RNGLink } from 'overrides/components/RNG';
import { configFooter } from './config-footer';
import { FooterWrapper, GoTopBox } from './styles';

// ----------------------------------------------------------------

export default function Footer() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const navigateToHomePage = () => {
    navigate('/');
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <FooterWrapper>
      <Box className="footer-menu" maxWidth="lg">
        {configFooter.map((footerItem) => (
          <Box component="ul" key={footerItem.section}>
            <Typography variant="h6">
              {translate(footerItem.section)}
            </Typography>

            <Box component="li">
              {footerItem.items.map((childItem) => {
                const { isExternalLink } = useActiveLink(childItem.path);
                return (
                  <Link
                    key={childItem.key}
                    to={childItem.path}
                    target={isExternalLink ? '_blank' : '_self'}
                  >
                    {translate(childItem.key)}
                  </Link>
                );
              })}
            </Box>
          </Box>
        ))}
      </Box>

      <Divider className="footer-line" />

      {/* FT BOTTOM */}

      <Box className="footer-bottom" maxWidth="lg">
        <Box className="footer-logo">
          <Link
            className="logo-coco"
            to="/"
            onClick={() => {
              navigateToHomePage();
            }}
          >
            <LogoFooter />
          </Link>

          <Typography whiteSpace="pre-wrap" textAlign="left">
            {translate('coco_info')}
          </Typography>

          <Stack
            direction="row"
            justifyContent={{ sm: 'space-between', xs: 'space-around' }}
            alignItems="center"
            sx={{
              '& > a, & > div': {
                maxWidth: { xs: '30%' },
              },
            }}
          >
            <Link className="logo-GC" to={LOGO_LINK.GC} target="_blank">
              <img src={logoGC} alt="logo GC" />
            </Link>

            <Link className="logo-Orisun" to={LOGO_LINK.ORISUN} target="_blank">
              <img src={logoOrisun} alt="logo Orisun" />
            </Link>
            <Box className="eighteen-plus">
              <EighTeenPlusIcon />
            </Box>
          </Stack>
        </Box>

        <Box className="footer-RNG">
          <RNGLink />

          <Box className="footer-contact">
            <Typography variant="h6">{translate('follow_us_on')}</Typography>

            <FooterContact />
          </Box>
        </Box>

        <GoTopBox onClick={() => handleScrollToTop()}>
          <Iconify
            className="icon-top"
            icon="material-symbols:arrow-circle-up-outline"
          />
          <Typography>{translate('go_to_top')}</Typography>
        </GoTopBox>
      </Box>
    </FooterWrapper>
  );
}
