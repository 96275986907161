// form
import { KeyboardEvent } from 'react';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

export type FormContextProps = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  width?: string | number;
  height?: string | number;
};

export default function FormProvider({
  children,
  onSubmit,
  methods,
  width,
  height,
}: FormContextProps) {
  const preventSubmitOnEnterInput = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <Form {...methods}>
      <form
        style={{ width: width || 'inherit', height: height || 'inherit' }}
        autoComplete="off"
        onSubmit={onSubmit}
        onKeyDown={(e) => preventSubmitOnEnterInput(e)}
        noValidate
      >
        {children}
      </form>
    </Form>
  );
}
