import { SvgIcon } from '@mui/material';

export function IconWithdraw(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.625 9.74998V12.75C14.625 12.8494 14.5855 12.9448 14.5152 13.0151C14.4448 13.0855 14.3495 13.125 14.25 13.125C13.3549 13.125 12.4964 13.4806 11.8635 14.1135C11.2306 14.7464 10.875 15.6049 10.875 16.5C10.875 16.5994 10.8355 16.6948 10.7652 16.7651C10.6948 16.8355 10.5995 16.875 10.5 16.875C10.4005 16.875 10.3052 16.8355 10.2348 16.7651C10.1645 16.6948 10.125 16.5994 10.125 16.5C10.1248 15.8382 10.2838 15.1861 10.5886 14.5987C10.8934 14.0113 11.335 13.5058 11.8763 13.125H6.75V16.5C6.75 17.0967 6.98705 17.669 7.40901 18.091C7.83097 18.5129 8.40326 18.75 9 18.75H14.625C15.2217 18.75 15.794 18.5129 16.216 18.091C16.6379 17.669 16.875 17.0967 16.875 16.5C16.875 9.19873 17.01 9.43123 16.5 8.93248C16.3432 8.77829 16.1438 8.67453 15.9276 8.63454C15.7113 8.59454 15.488 8.62013 15.2864 8.70803C15.0848 8.79593 14.9141 8.94211 14.7963 9.12778C14.6784 9.31346 14.6188 9.53014 14.625 9.74998Z"
        fill="#858997"
      />
      <path
        d="M15.375 19.5H8.25C7.83579 19.5 7.5 19.8358 7.5 20.25V21.75C7.5 22.1642 7.83579 22.5 8.25 22.5H15.375C15.7892 22.5 16.125 22.1642 16.125 21.75V20.25C16.125 19.8358 15.7892 19.5 15.375 19.5Z"
        fill="#858997"
      />
      <path
        d="M13.875 11.625H6.75C6.65054 11.625 6.55516 11.5855 6.48483 11.5152C6.41451 11.4448 6.375 11.3495 6.375 11.25C6.375 10.9516 6.25647 10.6655 6.0455 10.4545C5.83452 10.2435 5.54837 10.125 5.25 10.125C5.15054 10.125 5.05516 10.0855 4.98484 10.0152C4.91451 9.94483 4.875 9.84945 4.875 9.75V6C4.875 5.90054 4.91451 5.80516 4.98484 5.73483C5.05516 5.6645 5.15054 5.625 5.25 5.625C5.54643 5.62474 5.83078 5.50749 6.04125 5.29875C6.11625 5.22375 6.34875 5.25 4.125 5.25V11.25C4.125 11.5484 4.24353 11.8345 4.4545 12.0455C4.66548 12.2565 4.95163 12.375 5.25 12.375H13.875V11.625Z"
        fill="#858997"
      />
      <path
        d="M18 9.53624C18.1203 9.4819 18.246 9.44042 18.375 9.41249V6.33749C18.027 6.26713 17.7067 6.09786 17.4525 5.84999C17.2763 5.67796 17.1338 5.47445 17.0325 5.24999H13.5262C15.0262 6.96374 14.055 10.125 12 10.125C9.945 10.125 9 6.95999 10.4775 5.24999C6.9225 5.24999 6.9675 5.21624 6.9675 5.24999C6.84733 5.52562 6.66262 5.76831 6.42897 5.95758C6.19532 6.14685 5.91958 6.27715 5.625 6.33749V9.41249C5.98533 9.48597 6.3161 9.6638 6.57614 9.92384C6.83618 10.1839 7.01401 10.5147 7.0875 10.875H13.875C13.875 9.82499 13.755 9.10124 14.4225 8.42624C14.6845 8.16356 15.0186 7.98452 15.3824 7.91178C15.7462 7.83903 16.1234 7.87585 16.4663 8.01758C16.8091 8.15931 17.1022 8.39956 17.3085 8.70795C17.5148 9.01633 17.6249 9.37898 17.625 9.74999C17.741 9.66404 17.8669 9.59228 18 9.53624Z"
        fill="#858997"
      />
      <path
        d="M17.9584 5.29876C18.1689 5.50751 18.4533 5.62476 18.7497 5.62501C18.8491 5.62501 18.9445 5.66452 19.0149 5.73485C19.0852 5.80518 19.1247 5.90056 19.1247 6.00001V9.75001C19.1247 9.84947 19.0852 9.94485 19.0149 10.0152C18.9445 10.0855 18.8491 10.125 18.7497 10.125C18.6014 10.1247 18.4544 10.1536 18.3174 10.2103C18.1803 10.2669 18.0557 10.3501 17.9509 10.455C17.5384 10.875 17.6247 11.25 17.6247 12.375C18.8472 12.375 18.8472 12.375 19.0422 12.3338C19.2807 12.2696 19.4914 12.1286 19.6419 11.9328C19.7923 11.7369 19.8741 11.497 19.8747 11.25V5.25001C17.7109 5.25001 17.8722 5.22751 17.9584 5.29876Z"
        fill="#858997"
      />
      <path
        d="M20.625 2.25H3.375C3.07663 2.25 2.79048 2.36853 2.5795 2.5795C2.36853 2.79048 2.25 3.07663 2.25 3.375V6.375C2.25 6.67337 2.36853 6.95952 2.5795 7.1705C2.79048 7.38147 3.07663 7.5 3.375 7.5V5.25C3.27554 5.25 3.18016 5.21049 3.10984 5.14016C3.03951 5.06984 3 4.97446 3 4.875C3 4.77554 3.03951 4.68016 3.10984 4.60984C3.18016 4.53951 3.27554 4.5 3.375 4.5H20.625C20.7245 4.5 20.8198 4.53951 20.8902 4.60984C20.9605 4.68016 21 4.77554 21 4.875C21 4.97446 20.9605 5.06984 20.8902 5.14016C20.8198 5.21049 20.7245 5.25 20.625 5.25V7.5C20.9234 7.5 21.2095 7.38147 21.4205 7.1705C21.6315 6.95952 21.75 6.67337 21.75 6.375V3.375C21.75 3.07663 21.6315 2.79048 21.4205 2.5795C21.2095 2.36853 20.9234 2.25 20.625 2.25Z"
        fill="#858997"
      />
      <path
        d="M12 9.375C12.8284 9.375 13.5 8.45159 13.5 7.3125C13.5 6.17341 12.8284 5.25 12 5.25C11.1716 5.25 10.5 6.17341 10.5 7.3125C10.5 8.45159 11.1716 9.375 12 9.375Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
