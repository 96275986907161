import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as BackIcon } from 'assets/icons/back-curved-arrow.svg';
import { verifiedSettings } from 'assets/images';
import Stepper from 'common/Stepper';
import FormProvider from 'components/hook-form';
import Iconify from 'components/iconify/Iconify';
import { format, startOfDay } from 'date-fns';
import {
  IdentificationStatus,
  useUpdateIdentificationMutation,
  useUploadIdentificationPhotosMutation,
  useUserIdentificationQuery,
} from 'graph';
import { useLocales } from 'locales';
import lodashHelper from 'lodash';
import { useSnackbar } from 'notistack';
import {
  generalInfoSchema,
  governmentIdSchema,
  portraitSchema,
} from 'pages/ProfileSettings/components/Verify/schema';
import React from 'react';
import { useForm } from 'react-hook-form';
import { getImagesCompression } from 'utils/compression';

import { Box, Button, Stack, Typography } from '@mui/material';

import GeneralInfoUploading from './GeneralInfoUploading';
import GovernmentIdUploading from './GovernmentIdUploading';
import PortraitUploading from './PortraitUploading';
import { VerifyWrapper } from './styles_v2';
import { GeneralInfoForm, GovernmentIdForm, PortraitForm } from './types';

// =================================================================

const steps = [1, 2, 3];
const mySteps = [
  { value: 1, label: 'Step 1' },
  { value: 2, label: 'Step 2' },
  { value: 3, label: 'Step 3' },
];

const V2Verify = () => {
  const { translate } = useLocales();
  const [activeStep, setActiveStep] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const [updateIdentification] = useUpdateIdentificationMutation();
  const [uploadIdentificationPhotos] = useUploadIdentificationPhotosMutation();
  const { data: userIdentificationData, refetch: refetchUserIdentification } =
    useUserIdentificationQuery();

  const generalInfoForm = useForm<GeneralInfoForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      gender: '',
      dateOfBirth: '',
      address: '',
      city: '',
      postalCode: '',
      countryCode: '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(generalInfoSchema),
    shouldFocusError: true,
  });

  const isGeneralFormDirty = generalInfoForm.formState.isDirty;

  const governmentIdForm = useForm<GovernmentIdForm>({
    defaultValues: {
      idCard: {
        frontSide: null,
        backSide: null,
      },
      passport: null,
    },
    mode: 'onSubmit',
    resolver: yupResolver(governmentIdSchema),
    shouldFocusError: true,
  });

  const portraitForm = useForm<PortraitForm>({
    defaultValues: {
      portrait: null,
    },
    mode: 'onSubmit',
    resolver: yupResolver(portraitSchema),
  });

  governmentIdForm.watch();
  portraitForm.watch();

  const handleUpdateIdentification = async (data: GeneralInfoForm) => {
    const dateOfBirth = format(
      startOfDay(new Date(data.dateOfBirth)),
      'MM/dd/yyyy',
    );

    const response = await updateIdentification({
      variables: {
        input: {
          ...data,
          dateOfBirth,
        },
      },
    });

    if (response?.data?.updateIdentification.success) {
      const { ...identification } = lodashHelper.omit(
        response.data?.updateIdentification.content.identification,
        ['__typename'],
      );
      enqueueSnackbar(translate('step1_update_identification_success'), {
        variant: 'success',
      });

      generalInfoForm.reset({
        ...identification,
        dateOfBirth: new Date(dateOfBirth),
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }
    if (
      response?.data?.updateIdentification.error.code ===
      'KYC_COMPLETED_OR_REVIEWING'
    ) {
      enqueueSnackbar(response?.data?.updateIdentification?.error.message, {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar(response.data?.updateIdentification.error?.message, {
      variant: 'error',
    });
  };

  const handleUploadIdentificationPhotos = async (data: PortraitForm) => {
    try {
      const governmentIdPhotos = governmentIdForm.getValues();

      const [selfie, idFront, idBack, passport] = await getImagesCompression(
        [
          data.portrait,
          governmentIdPhotos.idCard.frontSide,
          governmentIdPhotos.idCard.backSide,
          governmentIdPhotos.passport,
        ],
        {
          maxSizeMB: 6,
        },
      );

      const response = await uploadIdentificationPhotos({
        variables: {
          selfie,
          idFront,
          idBack,
          passport,
        },
      });

      if (response?.data?.uploadIdentificationPhotos.success) {
        enqueueSnackbar(
          translate('step23_update_identification_photo_success'),
          { variant: 'success' },
        );
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        refetchUserIdentification();
        return;
      }
      enqueueSnackbar(translate('upload_image_failed'), { variant: 'error' });
    } catch (e) {
      enqueueSnackbar(translate('upload_image_failed'), { variant: 'error' });
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);

    if (activeStep === 2) portraitForm.reset();
    else governmentIdForm.reset();
  };

  const handleNext = (
    data: GeneralInfoForm | GovernmentIdForm | PortraitForm,
  ) => {
    if (activeStep === steps.length) return;

    if (activeStep === 0) {
      if (
        isGeneralFormDirty ||
        !userIdentificationData?.userIdentification?.identification
      ) {
        handleUpdateIdentification(data as GeneralInfoForm);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }

    if (activeStep === 1) {
      setActiveStep((prev) => prev + 1);
    }

    if (activeStep === 2) {
      handleUploadIdentificationPhotos(data as PortraitForm);
    }
  };

  const formSubmit = () => {
    if (activeStep === 0) {
      return generalInfoForm.handleSubmit(handleNext)();
    }

    if (activeStep === 1) {
      return governmentIdForm.handleSubmit(handleNext)();
    }

    if (activeStep === 2) {
      return portraitForm.handleSubmit(handleNext)();
    }
  };

  React.useEffect(() => {
    generalInfoForm.reset({
      firstName:
        userIdentificationData?.userIdentification?.identification?.firstName ||
        '',
      lastName:
        userIdentificationData?.userIdentification?.identification?.lastName ||
        '',
      gender:
        userIdentificationData?.userIdentification?.identification?.gender ||
        '',
      dateOfBirth: userIdentificationData?.userIdentification?.identification
        ?.dateOfBirth
        ? new Date(
            userIdentificationData?.userIdentification?.identification?.dateOfBirth,
          )
        : '',
      address:
        userIdentificationData?.userIdentification?.identification?.address ||
        '',
      city:
        userIdentificationData?.userIdentification?.identification?.city || '',
      postalCode:
        userIdentificationData?.userIdentification?.identification
          ?.postalCode || '',
      countryCode:
        userIdentificationData?.userIdentification?.identification
          ?.countryCode || '',
    });
  }, [userIdentificationData]);

  React.useEffect(() => {
    if (
      userIdentificationData?.userIdentification?.status ===
        IdentificationStatus.New &&
      userIdentificationData?.userIdentification?.identification
    ) {
      setActiveStep(1);
    }
  }, [userIdentificationData]);

  const isReviewingAccount =
    userIdentificationData?.userIdentification.status ===
    IdentificationStatus.Reviewing;

  const isNotVerifiedAccount =
    userIdentificationData?.userIdentification?.status ===
      IdentificationStatus.New ||
    userIdentificationData?.userIdentification?.status ===
      IdentificationStatus.Rejected;

  const isVerifiedAccount =
    userIdentificationData?.userIdentification?.status ===
    IdentificationStatus?.Accepted;

  return (
    <VerifyWrapper>
      {(activeStep > 2 || isReviewingAccount) && (
        <Stack mb={5} justifyContent="center" spacing={{ sm: 3, xs: 1.5 }}>
          <Box display="flex" justifyContent="center">
            <Iconify icon="uiw:cloud-upload" width={80} color="primary.main" />
          </Box>
          <Typography
            color="text.contrast"
            variant="subtitle2"
            textAlign="center"
            fontWeight={{ sm: 700, xs: 500 }}
            fontSize={{ sm: 20, xs: 14 }}
            lineHeight={{ sm: '26px', xs: '20px' }}
            textTransform="uppercase"
          >
            {translate('all_information_submitted')}
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ sm: 16, xs: 14 }}
            textAlign="center"
            whiteSpace="pre-wrap"
          >
            {translate('thank_you_for_cooperation')}
          </Typography>
        </Stack>
      )}
      {isNotVerifiedAccount && (
        <>
          <Typography
            variant="subtitle2"
            fontSize={{ sm: 20, xs: 16 }}
            fontWeight={{ sm: 700, xs: 500 }}
            color="text.contrast"
            mb={{ sm: 3, xs: 5 }}
          >
            {translate('verification')}
          </Typography>
          <Box mt={3} mb={{ sm: 10, xs: 5 }}>
            <Stepper
              steps={mySteps}
              currentStep={activeStep}
              setStep={(step) => {
                if (step > activeStep) {
                  if (
                    activeStep === 0 &&
                    step === 1 &&
                    generalInfoForm.formState.isValid
                  )
                    setActiveStep(step);
                  else if (
                    activeStep === 1 &&
                    step === 2 &&
                    governmentIdForm.formState.isValid
                  )
                    setActiveStep(step);
                  else formSubmit();
                } else setActiveStep(step);

                if (activeStep === 2) portraitForm.reset();
                else governmentIdForm.reset();
              }}
              sx={{
                width: { xs: '250px', md: '350px' },
              }}
            />
            <Box
              sx={{
                py: 3,
                minHeight: 120,
              }}
            >
              {activeStep === 0 && (
                <FormProvider methods={generalInfoForm}>
                  <GeneralInfoUploading form={generalInfoForm} />
                </FormProvider>
              )}
              {activeStep === 1 && (
                <FormProvider methods={governmentIdForm}>
                  <GovernmentIdUploading />
                </FormProvider>
              )}
              {activeStep === 2 && (
                <FormProvider methods={portraitForm}>
                  <PortraitUploading />
                </FormProvider>
              )}
            </Box>

            <Stack
              direction={{ sm: 'row', xs: 'column' }}
              rowGap={3}
              justifyContent="flex-start"
              className="action_stack"
            >
              {activeStep !== 0 && (
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={
                    <BackIcon
                      width="16px"
                      height="16px"
                      style={{ marginRight: '10px' }}
                    />
                  }
                  onClick={handleBack}
                  sx={{
                    height: { sm: 44, xs: 48 },
                    maxHeight: 'unset',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    fontSize={{ sm: 16, xs: 14 }}
                  >
                    {translate('back')}
                  </Typography>
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={formSubmit}
                sx={{
                  height: { sm: 44, xs: 48 },
                  maxHeight: 'unset',
                  background:
                    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
                  color: '#2E2C5E',
                  borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  fontSize={{ sm: 16, xs: 14 }}
                >
                  {translate('next')}
                </Typography>
              </Button>
            </Stack>
          </Box>{' '}
        </>
      )}
      {isVerifiedAccount && (
        <Stack mb={5} justifyContent="center" spacing={{ sm: 3, xs: 1.5 }}>
          <Box display="flex" justifyContent="center">
            <img
              src={verifiedSettings}
              alt="verified-account"
              id="verified-account-img"
            />
          </Box>
          <Typography
            color="text.contrast"
            variant="subtitle2"
            textAlign="center"
            fontWeight={{ sm: 700, xs: 500 }}
            fontSize={{ sm: 20, xs: 14 }}
            lineHeight={{ sm: '26px', xs: '20px' }}
            textTransform="uppercase"
          >
            {translate('your_profile_had_been_verified')}
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ sm: 16, xs: 14 }}
            textAlign="center"
            whiteSpace="pre-wrap"
          >
            {translate('thank_you_for_cooperation')}
          </Typography>
        </Stack>
      )}
      {isNotVerifiedAccount && (
        <Box>
          <Typography
            variant="subtitle2"
            fontSize={{ sm: 20, xs: 16 }}
            fontWeight={{ sm: 700, xs: 500 }}
            color="text.contrast"
            mb={3}
          >
            {translate('verify_setup')}
          </Typography>
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={400}
              whiteSpace="pre-wrap"
            >
              {translate('verify_setup_description1')}
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={400}
              whiteSpace="pre-wrap"
              mt={{ sm: 1.5, xs: 3 }}
            >
              {translate('verify_setup_description2')}
            </Typography>
          </Box>
        </Box>
      )}
    </VerifyWrapper>
  );
};

export default V2Verify;
