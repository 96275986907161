import { LogoCocoFull } from 'assets/icons/LogoCocoFull';

const LogoLoading = () => (
  <LogoCocoFull
    style={{
      width: 120,
    }}
  />
);
export { LogoLoading };
