import { useReactiveVar } from '@apollo/client';
import { IconDollar } from 'assets/icons';
import coinImages from 'assets/images/coins';
import { CurrencyInput } from 'components/_Games/CurrencyField/styles';
import { useLocales } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { storageCurrencyVar, viewInFiatVar } from 'store';

import { Box, InputAdornment, Stack } from '@mui/material';

import { SubTitleControlGame, TitleControlGame, TitlePayout } from './styles';
import { FieldsBetProps } from './types';

// ---------------------------------------------------------------------------

const WinAmount = (props: Partial<FieldsBetProps>) => {
  const { subtitle, name, minBet, index } = props;
  const { translate } = useLocales();
  const { control } = useFormContext();
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);

  return (
    props && (
      <Box sx={{ order: index, pb: 1.5 }}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Stack direction="column" pt={1}>
              <TitlePayout>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={0}
                >
                  <TitleControlGame>{translate('win_amount')}</TitleControlGame>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={0}
                >
                  <SubTitleControlGame>{subtitle}</SubTitleControlGame>
                </Stack>
              </TitlePayout>

              <Stack
                spacing={1}
                direction="row"
                sx={{
                  borderRadius: '4px',
                }}
              >
                <CurrencyInput
                  fullWidth
                  disabled
                  size="small"
                  autoComplete="off"
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  // type="number"
                  value={value}
                  onChange={onChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {viewInFiat.status === true &&
                        storageCurrency?.equivalentUsdRate !== 0 ? (
                          <IconDollar />
                        ) : (
                          <img
                            src={coinImages[storageCurrency?.shortName]}
                            alt="coin"
                            width={24}
                          />
                        )}
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: 'rgba(145, 158, 171, 0.24)',
                    },
                  }}
                  onBlur={() => {
                    if (viewInFiat.status === true && Number(value) < minBet) {
                      onChange(minBet);
                    } else if (
                      Number(value) <
                      minBet * (storageCurrency.equivalentUsdRate || 1)
                    ) {
                      onChange(
                        minBet * (storageCurrency.equivalentUsdRate || 1),
                      );
                    }
                  }}
                  {...props}
                />
              </Stack>
            </Stack>
          )}
        />
      </Box>
    )
  );
};
export default WinAmount;
