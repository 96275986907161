import currencyImages from 'assets/images/coins';
import {
  firstRank,
  secondRank,
  thirdRank,
} from 'assets/images/events/wager_contest';
import { useAuthContext } from 'auth/useAuthContext';
import LoadingAnimated from 'components/LoadingAnimated';
import PlayerBox from 'components/PlayerBox';
import { COD_CURRENCY, FUN_CURRENCY } from 'constants/index';
import {
  CrashPredictorResult,
  FunCrashPredictorResult,
  User,
  useUserInfoQuery,
} from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import numeral from 'numeral';

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { PredictorTableContainer, Wrapper } from './styles';

// =================================================================

interface Props {
  data: DeepPartial<CrashPredictorResult | FunCrashPredictorResult>[];
  myPredictor: DeepPartial<CrashPredictorResult | FunCrashPredictorResult>;
  isFun?: boolean;
  loading: boolean;
}

export default function Winner({ data, myPredictor, isFun, loading }: Props) {
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const { isAuthenticated, openLoginModal } = useAuthContext();
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const { data: userInfo } = useUserInfoQuery({
    fetchPolicy: 'cache-only',
  });
  const prizeCurrency = COD_CURRENCY;
  const funCurrency = FUN_CURRENCY;

  const getRankElement = (rank) => {
    switch (rank) {
      case 1:
        return <img src={firstRank} alt="1st" />;
      case 2:
        return <img src={secondRank} alt="2st" />;
      case 3:
        return <img src={thirdRank} alt="3rd" />;
      default:
        return `${rank}th`;
    }
  };

  const getWagersElement = (totalWager) => {
    if (isFun)
      return (
        <Box display="flex" alignItems="center" justifyContent="right">
          <Typography className="wagers">
            {getCurrencyAmount(totalWager, funCurrency)}
          </Typography>
          <img
            className="currency_image"
            alt={prizeCurrency?.shortName}
            src={currencyImages.fun}
          />
        </Box>
      );

    return `$${numeral(totalWager).format('0,0.00')}`;
  };

  const renderTable = () => {
    if (isMobile)
      return (
        <>
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate('rank')}</TableCell>
              <TableCell align="center" className="wagers">
                <Stack>
                  <Typography fontWeight={500} color="text.contrast">
                    {translate('player')}
                  </Typography>
                  <Typography color="text.primary">
                    {translate('wagers')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="center">
                <Stack>
                  <Typography fontWeight={500} color="text.contrast">
                    {translate('cashout')}
                  </Typography>
                  <Typography color="text.primary">
                    {translate('result')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">{translate('prize')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className="rank" align="left">
                  {getRankElement(row.rank)}
                </TableCell>
                <TableCell align="center" className="wagers">
                  <Stack justifyContent="center">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <PlayerBox user={row.user as User} />
                    </Box>
                    <Typography color="text.primary">
                      {getWagersElement(row.totalWager)}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack justifyContent="center">
                    <Typography className="cashout">
                      {numeral(row.cashout).format('0,0.00')}x
                    </Typography>
                    <Typography color="text.primary">
                      {numeral(row.crashResult).format('0,0.00')}x
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="right" className="prize">
                  {row?.amount && prizeCurrency?.equivalentUsdRate ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="right"
                    >
                      <Typography className="prize">
                        {numeral(
                          row.amount / (prizeCurrency.equivalentUsdRate || 1),
                        ).format('0,0.00')}
                      </Typography>
                      <img
                        className="currency_image"
                        alt={prizeCurrency?.shortName}
                        src={currencyImages[prizeCurrency?.shortName]}
                      />
                    </Box>
                  ) : (
                    `$${numeral(
                      row.amount / (prizeCurrency.equivalentUsdRate || 1),
                    ).format('0,0.00')}`
                  )}
                </TableCell>
              </TableRow>
            ))}
            {data.length === 0 && !loading && (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {translate('empty_data')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </>
      );

    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell className="rank" align="left">
              {translate('rank')}
            </TableCell>
            <TableCell className="player" align="center">
              {translate('player')}
            </TableCell>
            <TableCell className="cashout" align="center">
              {translate('cashout')}
            </TableCell>
            <TableCell className="result" align="center">
              {translate('crash_result')}
            </TableCell>
            <TableCell className="wagers" align="right">
              {translate('wagers')}
            </TableCell>
            <TableCell className="prize" align="right">
              {translate('prize')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="rank" align="left">
                {getRankElement(row.rank)}
              </TableCell>
              <TableCell className="player" align="center">
                <PlayerBox user={row.user as User} />
              </TableCell>
              <TableCell align="center" className="cashout">
                {numeral(row.cashout).format('0,0.00')}x
              </TableCell>
              <TableCell className="result" align="center">
                {numeral(row.crashResult).format('0,0.00')}x
              </TableCell>
              <TableCell align="right" className="wagers">
                {getWagersElement(row.totalWager)}
              </TableCell>
              <TableCell align="right" className="prize">
                {row?.amount && prizeCurrency?.equivalentUsdRate ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="right"
                  >
                    <Typography className="prize">
                      {numeral(
                        row.amount / (prizeCurrency.equivalentUsdRate || 1),
                      ).format('0,0.00')}
                    </Typography>
                    <img
                      className="currency_image"
                      alt={prizeCurrency?.shortName}
                      src={currencyImages[prizeCurrency?.shortName]}
                    />
                  </Box>
                ) : (
                  `$${numeral(row.amount || 0).format('0,0.00')}`
                )}
              </TableCell>
            </TableRow>
          ))}
          {data.length === 0 && !loading && (
            <TableRow>
              <TableCell align="center" colSpan={6}>
                {translate('empty_data')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </>
    );
  };

  return (
    <Wrapper>
      <PredictorTableContainer sx={{ pb: { sm: 6, xs: 7 } }}>
        <Table stickyHeader>{renderTable()}</Table>
      </PredictorTableContainer>
      <PredictorTableContainer
        sx={{
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Table>
          {isAuthenticated && !isMobile && data.length > 0 && (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className="my_rank"
            >
              <TableCell className="rank" align="left">
                {myPredictor?.rank
                  ? getRankElement(myPredictor.rank)
                  : `${data.length || 0}th+`}
              </TableCell>
              <TableCell className="player" align="center">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {userInfo?.me?.user?.avatar?.square && (
                      <img
                        className="user_avatar"
                        alt={userInfo?.me?.user.nickname}
                        src={userInfo?.me?.user.avatar.square}
                      />
                    )}
                    <Typography color="text.contrast" className="user_name">
                      {translate('me')}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="center" className="cashout">
                {myPredictor?.cashout
                  ? `${numeral(myPredictor.cashout).format('0,0.00')}x`
                  : '-'}
              </TableCell>
              <TableCell className="result" align="center">
                {myPredictor?.crashResult
                  ? `${numeral(myPredictor.crashResult).format('0,0.00')}x`
                  : '-'}
              </TableCell>
              <TableCell align="center" className="wagers">
                {myPredictor?.totalWager
                  ? getWagersElement(myPredictor.totalWager)
                  : '-'}
              </TableCell>
              <TableCell align="right" className="prize">
                {myPredictor?.amount && prizeCurrency?.equivalentUsdRate && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="right"
                  >
                    <Typography className="prize">
                      {numeral(
                        myPredictor.amount / prizeCurrency.equivalentUsdRate ||
                          1,
                      ).format('0,0.00')}
                    </Typography>
                    <img
                      className="currency_image"
                      alt={prizeCurrency?.shortName}
                      src={currencyImages[prizeCurrency?.shortName]}
                    />
                  </Box>
                )}
                {myPredictor?.amount &&
                  !prizeCurrency?.equivalentUsdRate &&
                  `$${numeral(myPredictor?.amount || '-').format('0,0.00')}`}
                {!myPredictor?.amount && '-'}
              </TableCell>
            </TableRow>
          )}
          {isAuthenticated && isMobile && (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className="my_rank"
            >
              <TableCell className="rank" align="left">
                {myPredictor
                  ? getRankElement(myPredictor?.rank)
                  : `${data.length}th+`}
              </TableCell>
              <TableCell align="center">
                <Stack justifyContent="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {userInfo?.me?.user?.avatar?.square && (
                        <img
                          className="user_avatar"
                          alt={userInfo?.me?.user.nickname}
                          src={userInfo?.me?.user.avatar.square}
                        />
                      )}
                      <Typography color="text.contrast" className="user_name">
                        {translate('me')}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography color="text.primary">
                    {myPredictor?.totalWager
                      ? getWagersElement(myPredictor.totalWager)
                      : '-'}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="center">
                <Stack justifyContent="center">
                  <Typography className="cashout">
                    {myPredictor?.cashout
                      ? `${numeral(myPredictor.cashout).format('0,0.00')}x`
                      : '-'}
                  </Typography>
                  <Typography color="text.primary">
                    {myPredictor?.crashResult
                      ? `${numeral(myPredictor?.crashResult).format('0,0.00')}x`
                      : '-'}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right" className="prize">
                {myPredictor?.amount && prizeCurrency?.equivalentUsdRate && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="right"
                  >
                    <Typography className="prize">
                      {numeral(
                        myPredictor.amount / prizeCurrency.equivalentUsdRate ||
                          1,
                      ).format('0,0.00')}
                    </Typography>
                    <img
                      className="currency_image"
                      alt={prizeCurrency?.shortName}
                      src={currencyImages[prizeCurrency?.shortName]}
                    />
                  </Box>
                )}
                {myPredictor?.amount &&
                  !prizeCurrency?.equivalentUsdRate &&
                  `$${numeral(myPredictor.amount || '-').format('0,0.00')}`}
                {!myPredictor?.amount && '-'}
              </TableCell>
            </TableRow>
          )}
          {!isAuthenticated && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography
                  variant="subtitle1"
                  color="text.contrast"
                  textAlign="center"
                >
                  <Typography
                    component="span"
                    variant="subtitle1"
                    sx={{
                      mr: 0.5,
                      fontWeight: 700,
                      color: 'primary.main',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      openLoginModal();
                    }}
                  >
                    {translate('login')}
                  </Typography>
                  {translate('to_see_your_rank')}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </PredictorTableContainer>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <LoadingAnimated />
        </Box>
      )}
    </Wrapper>
  );
}

Winner.defaultProps = {
  isFun: false,
};
