import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { useBetQuery } from 'graph';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useLocales } from 'locales';
import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { BetDetailInfoWrapper } from './style';

// --------------------------------------------------------------------

interface Props {
  betId: string;
}

const BetDetailInfo = ({ betId }: Props) => {
  const { translate } = useLocales();
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const textRefs = useRef([]);
  const [showTexts, setShowTexts] = useState([true, true, true, true]);
  const handleCopy = (text) => {
    copy(text);
    enqueueSnackbar(translate('copied'));
  };

  const handleToggleTextVisibility = (index) => {
    setShowTexts((prevShowTexts) =>
      prevShowTexts.map((showText, i) => (i === index ? !showText : showText)),
    );
  };

  const getMaskedText = (text, index) =>
    showTexts[index] ? text : '*'.repeat(text.length);

  const { data: betData, loading } = useBetQuery({
    variables: {
      betId,
    },
  });

  const data = [
    {
      id: uniqueId(),
      title: 'game_id',
      label: betData?.bet?.game?.id,
      icon: showTexts[0] ? (
        <Visibility className="eye" />
      ) : (
        <VisibilityOff className="eye" />
      ),
      onClick: () => handleToggleTextVisibility(0),
    },
    {
      id: uniqueId(),
      title: 'server_seed_hash',
      label: betData?.bet?.rngResult?.message,
      icon: <CopyIcon width="20px" height="20px" />,
      onClick: () => handleCopy(betData?.bet?.rngResult?.message),
    },
    {
      id: uniqueId(),
      title: 'client_seed',
      label: betData?.bet?.rngResult?.rng,
      icon: <CopyIcon width="20px" height="20px" />,
      onClick: () => handleCopy(betData?.bet?.rngResult?.rng),
    },
    {
      id: uniqueId(),
      title: 'nonce',
      label: betData?.bet?.rngResult?.rngPublicKey,
      icon: <CopyIcon width="20px" height="20px" />,
      onClick: () => handleCopy(betData?.bet?.rngResult?.rngPublicKey),
    },
  ];

  return (
    <BetDetailInfoWrapper>
      {!loading && (
        <Box className="info-list">
          {data.map((item, index) => {
            const textRef = useRef(null);
            textRefs.current[index] = textRef;

            return (
              <Box className="info-item" key={item.id}>
                <Typography variant="h6">{translate(item.title)}</Typography>

                <Box className="info-item-content">
                  <Typography component="span" ref={textRef}>
                    {getMaskedText(item.label, index)}
                  </Typography>

                  <IconButton className="copy_btn" onClick={item.onClick}>
                    {item.icon}
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </BetDetailInfoWrapper>
  );
};

export default BetDetailInfo;
