import { SvgIcon } from '@mui/material';

export function IconBill(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.2343 21.9999H5.17188C5.8191 21.9999 6.34374 21.4753 6.34374 20.8281C6.34374 20.3966 6.6935 20.0468 7.12498 20.0468H19.6249C20.0564 20.0468 20.4061 20.3966 20.4061 20.8281C20.4061 21.4753 19.8815 21.9999 19.2343 21.9999Z"
        fill="#858997"
      />
      <path
        d="M18.0351 2.57871C17.8588 2.69445 17.6472 2.78124 17.3298 2.78124C16.3775 2.78124 16.3775 2 15.4253 2C14.4732 2 14.4732 2.78124 13.5211 2.78124C12.5689 2.78124 12.5689 2 11.6168 2C10.6647 2 10.6647 2.78124 9.71261 2.78124C8.76055 2.78124 8.76054 2 7.80844 2C6.8563 2 6.8563 2.78124 5.90416 2.78124C5.58678 2.78124 5.37522 2.69445 5.1989 2.57871C4.68437 2.2409 4 2.62437 4 3.23987V20.2037C4 20.559 4.29687 20.845 4.65609 20.8272C4.93011 20.8136 5.1639 20.6155 5.23116 20.3495C5.44507 19.5031 6.21303 18.8749 7.12497 18.8749H18.453C18.8845 18.8749 19.2342 18.5251 19.2342 18.0936V3.24003C19.2342 2.62441 18.5498 2.2409 18.0351 2.57871ZM12.789 14.539C12.789 14.9704 12.4392 15.3202 12.0077 15.3202H7.32028C6.8888 15.3202 6.53904 14.9704 6.53904 14.539C6.53904 14.1075 6.8888 13.7577 7.32028 13.7577H12.0077C12.4392 13.7577 12.789 14.1075 12.789 14.539ZM15.914 11.8046H7.32028C6.8888 11.8046 6.53904 11.4548 6.53904 11.0234C6.53904 10.5919 6.8888 10.2421 7.32028 10.2421H15.914C16.3454 10.2421 16.6952 10.5919 16.6952 11.0234C16.6952 11.4548 16.3454 11.8046 15.914 11.8046ZM15.914 8.28901H7.32028C6.8888 8.28901 6.53904 7.93925 6.53904 7.50776C6.53904 7.07628 6.8888 6.72652 7.32028 6.72652H15.914C16.3454 6.72652 16.6952 7.07628 16.6952 7.50776C16.6952 7.93925 16.3454 8.28901 15.914 8.28901Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
