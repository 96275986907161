import BetDetail from 'components/_Games/BetDetail';
import { Bet, useMyBetsLazyQuery } from 'graph';
import useResponsive from 'hooks/useResponsive';
import React, { memo, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { TableBody } from '@mui/material';

import BetTable from '../BetTable';
import { HeaderMyBetWrapper } from './HeaderMyBet';
import { RowMyBetTableWrapper } from './RowsMyBet';

/* -------------------------------------------------------------------------- */

interface Props {
  gameId?: string;
  showInMiniGame?: boolean;
}

interface TableRowProps {
  row: Omit<Bet, '__typename'>;
}
export const myBetsQueryVariables = {
  page: 0,
  pageSize: 50,
};

const MyBetTable = ({ gameId, showInMiniGame = false }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  const [rows, setRows] = useState([]);
  const defaultShowedRows = showInMiniGame && isMobile ? 15 : 10;
  const [showedRows, setShowedRows] = useState(defaultShowedRows);
  const hasMore =
    rows.length > defaultShowedRows && showedRows <= defaultShowedRows;
  const [selectedBet, setSelectedBet] = useState<Omit<Bet, '__typename'>>(null);
  const [getMyBets, { data: myBets }] = useMyBetsLazyQuery({
    variables: {
      ...myBetsQueryVariables,
      gameId,
    },
  });

  useEffect(() => {
    if (gameId) {
      getMyBets();
    }
  }, [gameId]);

  useEffect(() => {
    if (myBets?.myBets) {
      setRows(myBets.myBets.data);
    }

    const betFunCount = myBets?.myBets?.data.filter(
      (r) => r.currency.id === '2',
    ).length;

    const betNonFunCount = myBets?.myBets?.data.filter(
      (r) => r.currency.id !== '2',
    ).length;
    if (betFunCount === 1)
      ReactGA.event({
        category: 'Button Click',
        action: 'First Bet FUN',
        label: 'User Play',
      });
    if (betNonFunCount === 1)
      ReactGA.event({
        category: 'Button Click',
        action: 'First Bet COIN',
        label: 'User Play',
      });
  }, [myBets]);

  React.useEffect(() => {
    setShowedRows(defaultShowedRows);
    return () => {
      setRows([]);
    };
  }, [isMobile]);

  const TableRowComponent = memo(({ row }: TableRowProps) => {
    const { equivalentUsdRate, ...rowData } = row;
    return (
      <RowMyBetTableWrapper
        key={rowData.id}
        showInMiniGame={showInMiniGame}
        equivalentUsdRate={equivalentUsdRate}
        row={{
          id: row.id,
          currency: row.currency,
          betAmount: row.betAmount,
          payoutRate: row.payoutRate,
          payout: row.payoutAmount,
          handleClick: () => setSelectedBet(row),
        }}
      />
    );
  });

  return (
    <>
      <BetTable
        rows={rows}
        hasMore={hasMore}
        setShowedRows={setShowedRows}
        defaultShowedRows={defaultShowedRows}
      >
        <>
          <HeaderMyBetWrapper />

          <TableBody sx={{ overflow: 'auto' }}>
            {rows.slice(0, showedRows).map((row) => (
              <TableRowComponent key={row.id} row={row} />
            ))}
          </TableBody>
        </>
      </BetTable>
      {selectedBet !== null && (
        <BetDetail
          open={selectedBet !== null}
          onClose={() => {
            setSelectedBet(null);
          }}
          betId={selectedBet.id}
        />
      )}
    </>
  );
};

export { MyBetTable };
