import { useMemo } from 'react';
// components
import { useSettingsContext } from 'components/settings/';
//
import NunitoSansBold from 'assets/fonts/NunitoSans-Bold.ttf';
import NunitoSansMedium from 'assets/fonts/NunitoSans-Medium.ttf';
import NunitoSans from 'assets/fonts/NunitoSans-Regular.ttf';
import NunitoSansSemiBold from 'assets/fonts/NunitoSans-SemiBold.ttf';

// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';

import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import v2 from './index';
import componentsOverride from './overrides';
import typography from './typography';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext();

  const typo = typography() as any;

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: v2.palette(themeMode),
      typography: typo,
      // TODO: shape
      shape: { borderRadius: 12 },
      direction: themeDirection,
      shadows: v2.shadows(themeMode),
      customShadows: customShadows(themeMode),
      components: {
        MuiCssBaseline: {
          styleOverrides: `
          @font-face {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Nunito Sans'), local('Nunito Sans-Regular'), url(${NunitoSans}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          };
          @font-face {
            font-family: 'Nunito Sans Medium';
            font-style: medium;
            font-display: swap;
            font-weight: 600;
            src: local('Nunito Sans'), local('Nunito Sans-Medium'), url(${NunitoSansMedium}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          };
          @font-face {
            font-family: 'Nunito Sans SemiBold';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('Nunito Sans-SemiBold'), local('Nunito Sans-SemiBold'), url(${NunitoSansSemiBold}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          };
          @font-face {
            font-family: 'Nunito Sans Bold';
            font-style: bold;
            font-display: swap;
            font-weight: 900;
            src: local('Nunito Sans Bold'), local('Nunito Sans-Bold'), url(${NunitoSansBold}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          };
          `,
        },
      },
    }),
    [themeDirection, themeMode],
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
