import { SvgIcon } from '@mui/material';

export function IconLiveCasino(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9697 20.5948H7.07164C6.4249 20.5948 5.89062 19.9937 5.89062 19.2661V4.16068C5.89062 3.43309 6.4249 2.83203 7.07164 2.83203H16.9697C17.6165 2.83203 18.1507 3.43309 18.1507 4.16068V19.2661C18.1507 19.9937 17.6165 20.5948 16.9697 20.5948ZM12.0207 14.4893C11.9504 14.4893 11.8801 14.4577 11.8238 14.3944C11.388 13.9041 10.9381 13.5403 10.5585 13.2081C9.743 12.528 9.04001 11.9269 9.04001 10.7723C9.04001 9.75998 9.77112 8.93748 10.6709 8.93748C11.3318 8.93748 11.7114 9.23801 12.0207 9.74416C12.33 9.23801 12.7237 8.93748 13.3704 8.93748C14.2702 8.93748 15.0013 9.75998 15.0013 10.7723C15.0013 11.9269 14.2984 12.528 13.4829 13.2081C13.1033 13.5403 12.6534 13.9041 12.2175 14.3944C12.1613 14.4577 12.091 14.4893 12.0207 14.4893ZM8.26672 6.83379C8.18237 6.83379 8.11207 6.80215 8.06989 6.73889C7.87305 6.53326 7.69027 6.37509 7.5075 6.21692C7.14194 5.90057 6.77639 5.60005 6.77639 4.99899C6.77639 4.44539 7.17006 4.00251 7.66215 4.00251C7.92929 4.00251 8.12613 4.11323 8.26672 4.2714C8.40732 4.11323 8.5901 4.00251 8.87129 4.00251C9.34932 4.00251 9.743 4.44539 9.743 4.99899C9.743 5.60005 9.3915 5.90057 9.01189 6.21692C8.84317 6.35927 8.64634 6.53326 8.46356 6.73889C8.40732 6.80215 8.33702 6.83379 8.26672 6.83379ZM15.6059 19.6616C15.5356 19.6616 15.4653 19.6299 15.4091 19.5667C15.2263 19.361 15.0295 19.2029 14.8607 19.0605C14.4811 18.7442 14.1296 18.4278 14.1296 17.8268C14.1296 17.2732 14.5233 16.8303 15.0154 16.8303C15.2825 16.8303 15.4794 16.941 15.6059 17.0992C15.7465 16.941 15.9433 16.8303 16.2105 16.8303C16.7026 16.8303 17.0962 17.2732 17.0962 17.8268C17.0962 18.4278 16.7448 18.7442 16.3651 19.0605C16.1964 19.2029 15.9996 19.361 15.8168 19.5667C15.7606 19.6299 15.6903 19.6616 15.6059 19.6616Z"
        fill="#9998D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.32865 17.7632H1.18102C0.534271 17.7632 0 17.1622 0 16.4346V1.32914C0 0.601543 0.534271 0.000488281 1.18102 0.000488281H11.0791C11.7258 0.000488281 12.2601 0.601543 12.2601 1.32914V2.19908H7.07206C6.11599 2.19908 5.32865 3.08485 5.32865 4.16042V6.18502C5.17399 6.13757 4.99121 6.09012 4.78032 6.09012C3.88049 6.09012 3.14939 6.91262 3.14939 7.92492C3.14939 9.09539 3.85237 9.69645 4.65378 10.3766C4.87874 10.5506 5.10369 10.7404 5.32865 10.9618V17.7632ZM2.3761 4.00225C2.29174 4.00225 2.22144 3.97061 2.1652 3.90734C1.98243 3.70172 1.78559 3.52773 1.61687 3.38537C1.23726 3.06903 0.885765 2.7685 0.885765 2.15163C0.885765 1.61385 1.27944 1.17096 1.77153 1.17096C2.03866 1.17096 2.2355 1.28168 2.36204 1.42404C2.50264 1.28168 2.69947 1.17096 2.96661 1.17096C3.4587 1.17096 3.85237 1.61385 3.85237 2.15163C3.85237 2.7685 3.50088 3.06903 3.12127 3.38537C2.95255 3.52773 2.75571 3.70172 2.57294 3.90734C2.5167 3.97061 2.4464 4.00225 2.3761 4.00225Z"
        fill="#9998D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8193 23.9634H12.9213C12.2745 23.9634 11.7402 23.3624 11.7402 22.6348V21.227H16.9705C17.9265 21.227 18.7139 20.3413 18.7139 19.2657V17.1146C18.9248 16.9248 19.1357 16.735 19.3325 16.5768C20.148 15.8966 20.851 15.2956 20.851 14.1251C20.851 13.1286 20.1198 12.3061 19.22 12.3061C19.0232 12.3061 18.8545 12.3219 18.7139 12.3694V6.20068H22.8193C23.4661 6.20068 24.0003 6.80174 24.0003 7.52933V22.6348C24.0003 23.3624 23.4661 23.9634 22.8193 23.9634ZM21.4555 23.0302C21.3852 23.0302 21.3149 22.9986 21.2587 22.9353C21.0759 22.7297 20.8791 22.5715 20.7104 22.4292C20.3307 22.097 19.9793 21.7965 19.9793 21.1954C19.9793 20.6418 20.3729 20.1989 20.851 20.1989C21.1322 20.1989 21.3149 20.3096 21.4555 20.4678C21.5961 20.3096 21.793 20.1989 22.0601 20.1989C22.5522 20.1989 22.9459 20.6418 22.9459 21.1954C22.9459 21.7965 22.5803 22.097 22.2148 22.4292C22.032 22.5715 21.8492 22.7297 21.6524 22.9353C21.6102 22.9986 21.5399 23.0302 21.4555 23.0302Z"
        fill="#9998D4"
      />
    </SvgIcon>
  );
}
