import { Link, useLocation } from 'react-router-dom';

import { Box, Divider } from '@mui/material';

import { NavListProps } from '../types';
import { NavigationItemWrapper } from './styles';

const NavigationItem = ({ navItem }: { navItem: NavListProps }) => {
  const { pathname } = useLocation();

  return (
    <NavigationItemWrapper className={`${navItem?.type} ${navItem?.key}`}>
      {navItem?.key === 'affiliate' && (
        <Divider
          sx={{
            margin: '10px 0',
            borderColor: '#2F2B60',
          }}
        />
      )}
      <Box
        className={`item__flex ${pathname === navItem?.path ? 'active' : ''}`}
        {...(navItem?.path?.includes('http') && {
          target: '_blank',
        })}
        {...(navItem?.path &&
          !navItem?.link && {
            component: Link,
          })}
        {...(navItem?.path &&
          !navItem?.link && {
            to: navItem?.path,
          })}
        {...(navItem?.link && {
          onClick: () => {
            window.open(navItem?.link, '_blank');
          },
        })}
        {...(!navItem?.link &&
          !navItem?.path && {
            onClick: navItem?.click,
          })}
      >
        <Box className="icon__box">{navItem?.icon}</Box>
      </Box>
    </NavigationItemWrapper>
  );
};

export default NavigationItem;
