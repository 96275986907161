import MiniDiceEventDetailDialogHeader from 'components/_Games/_GamesMiniDice/mini-dice-event/components/event-detail-content/MiniDiceEventDetailDialogHeader';
import MiniDiceEventDetailTable from 'components/_Games/_GamesMiniDice/mini-dice-event/components/event-detail-content/MiniDiceEventDetailTable';
import { MiniDiceEventDetailOverView } from 'components/_Games/_GamesMiniDice/mini-dice-event/components/event-detail-content/styles';
import { format } from 'date-fns';
import { ThreeDiceEventHistoriesQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { memo, useMemo } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

interface IMiniDiceEventDetailContentProps {
  selectedEvent: ArrayElement<
    ThreeDiceEventHistoriesQuery['threeDiceCurrentGame']['jackpotWins']
  >;
  onClose: VoidFunction;
  onClickBack: VoidFunction;
}

const MiniDiceEventDetailContent = ({
  onClose,
  onClickBack,
  selectedEvent,
}: IMiniDiceEventDetailContentProps) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const diceSum = useMemo(
    () => selectedEvent.result3D.reduce((sum, curVal) => sum + curVal, 0),
    [selectedEvent],
  );

  return (
    <>
      <MiniDiceEventDetailDialogHeader
        onClickBack={onClickBack}
        onClose={onClose}
      />
      <MiniDiceEventDetailOverView direction="row">
        <Stack>
          <Typography>
            {format(new Date(selectedEvent.createdTime), 'dd/MM/yyyy - hh:mm')}
          </Typography>
          <Typography>#{selectedEvent.roundId}</Typography>
        </Stack>
        <Stack direction="row" columnGap={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            columnGap={1}
          >
            <Box className="dice-num dice-first">
              {selectedEvent.result3D[0]}
            </Box>
            <Box className="dice-num dice-second">
              {selectedEvent.result3D[1]}
            </Box>
            <Box className="dice-num dice-third">
              {selectedEvent.result3D[2]}
            </Box>
          </Stack>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box alignSelf="center" className="dice-num dice-total">
            {diceSum || '-'}
          </Box>
        </Stack>
        <Stack>
          <Typography className="total-prized-title">
            {translate('total_prized')}
          </Typography>
          <Typography className="total-prized-num">
            {`$${getCurrencyAmount(selectedEvent.jackpotPay, null, {
              onlyUsd: true,
            })}`}
          </Typography>
        </Stack>
      </MiniDiceEventDetailOverView>
      <MiniDiceEventDetailTable selectedEvent={selectedEvent} />
    </>
  );
};

export default memo(MiniDiceEventDetailContent);
