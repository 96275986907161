import { SvgIcon } from '@mui/material';

export function IconCategory(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M18.6699 2.52698H16.7699C14.5899 2.52698 13.4399 3.67698 13.4399 5.85698V7.75698C13.4399 9.93698 14.5899 11.087 16.7699 11.087H18.6699C20.8499 11.087 21.9999 9.93698 21.9999 7.75698V5.85698C21.9999 3.67698 20.8499 2.52698 18.6699 2.52698Z"
        fill="white"
      />
      <path
        d="M7.24 13.957H5.34C3.15 13.957 2 15.107 2 17.287V19.187C2 21.377 3.15 22.527 5.33 22.527H7.23C9.41 22.527 10.56 21.377 10.56 19.197V17.297C10.57 15.107 9.42 13.957 7.24 13.957Z"
        fill="white"
      />
      <path
        d="M6.29 11.107C8.6593 11.107 10.58 9.18628 10.58 6.81698C10.58 4.44768 8.6593 2.52698 6.29 2.52698C3.9207 2.52698 2 4.44768 2 6.81698C2 9.18628 3.9207 11.107 6.29 11.107Z"
        fill="white"
      />
      <path
        d="M17.7099 22.527C20.0792 22.527 21.9999 20.6063 21.9999 18.237C21.9999 15.8677 20.0792 13.947 17.7099 13.947C15.3406 13.947 13.4199 15.8677 13.4199 18.237C13.4199 20.6063 15.3406 22.527 17.7099 22.527Z"
        fill="white"
      />
    </SvgIcon>
  );
}
