import { Box, Button, ButtonProps, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GameHeaderBox = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: '12px 12px 0 0',
  // [theme.breakpoints.down('md')]: {
  //   borderRadius: '0px 12px 0 0',
  // },
}));

export const GameFooterBox = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: '0 0 12px 12px',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export const BetControlBody = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  '.MuiInputBase-root': {
    backgroundColor: theme.palette.background.main,
  },
  overflowY: 'auto',
  maxHeight: '793px',
}));

interface ContinueButtonProps extends ButtonProps {
  $click?: boolean;
  themeMode: string;
}

export const BetModeButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== '$click' && prop !== 'themeMode',
})<ContinueButtonProps>(({ $click, theme }) => ({
  fontSize: `14px !important`,
  fontStyle: 'normal',
  fontWeight: 700,
  height: theme.spacing(5),
  color: $click ? theme.palette.text.secondary : theme.palette.text.primary,
  background: $click
    ? 'linear-gradient(180deg, #313064 0%, #43416C 100%)'
    : theme.palette.background.main,
  border: 0,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.background.tertiary,
    opacity: 0.8,
    border: 0,
  },
}));

export const BetControllerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  background: theme.palette.background.secondary,
  borderRadius: theme.shape.borderRadius,
}));
