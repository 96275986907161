import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-bold.svg';
import coins from 'assets/images/coins';
import { ThemedButton } from 'common/Button/style';
import LoadingAnimated from 'components/LoadingAnimated';
import {
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useLoyalUserQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import numeral from 'numeral';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';

import { Box, Stack, Typography } from '@mui/material';

import { PeriodicBonusTop, PeriodicBonusWrapper } from './styles';

interface IPeriodicBonusTabProps {
  bonusType: 'weekly' | 'monthly';
  onClickClaimBonus: () => Promise<boolean>;
  handleCloseVipDialog: VoidFunction;
}

const PeriodicBonusTab = ({
  bonusType,
  onClickClaimBonus,
  handleCloseVipDialog,
}: IPeriodicBonusTabProps) => {
  const isDesktop = useResponsive('up', 'sm');
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const {
    data: loyalUserData,
    loading,
    refetch: refetchLoyalUser,
  } = useLoyalUserQuery({
    fetchPolicy: 'network-only',
  });

  const availableVipCurrenciesBonus = useMemo(() => {
    if (!loyalUserData) return [];

    if (bonusType === 'weekly') {
      return loyalUserData?.me?.vipUser?.vipUserCurrencies?.filter(
        (vipUserCurrency) => Boolean(vipUserCurrency.lastWeekBonusAmount),
      );
    }

    if (bonusType === 'monthly') {
      return loyalUserData?.me?.vipUser?.vipUserCurrencies?.filter(
        (vipUserCurrency) => Boolean(vipUserCurrency.lastMonthBonusAmount),
      );
    }
    return [];
  }, [loyalUserData, bonusType]);

  const onClickPlayAndWager = () => {
    handleCloseVipDialog();
    navigate(PATH_DASHBOARD.casino.root);
  };

  if (loading) {
    return (
      <Box
        position="absolute"
        width="100%"
        height="100%"
        sx={{ top: 0, left: '50%', transform: 'translate(-50%, -5%)' }}
      >
        <LoadingAnimated />
      </Box>
    );
  }

  return (
    <PeriodicBonusWrapper>
      <PeriodicBonusTop>
        {loyalUserData?.me?.vipUser?.betAmountUsd < 50000 && (
          <Typography
            color="text.contrast"
            textAlign="center"
            variant="subtitle1"
            className="background-text"
            fontWeight={700}
          >
            {translate('xp_required')}:&nbsp;
            {`${numeral(loyalUserData?.me?.vipUser?.vipPoint).format('0,00')} /
            ${numeral(50000).format('0,00')}XP`}
          </Typography>
        )}
        <Typography
          textAlign="center"
          variant="body2"
          className="background-text"
          fontWeight={600}
          {...(loyalUserData?.me?.vipUser?.betAmountUsd > 50000 && { mt: 2 })}
          mb={3}
        >
          {translate('receive_every')}{' '}
          {bonusType === 'weekly' ? 'Monday' : '1st day of the month'}
        </Typography>
      </PeriodicBonusTop>
      {availableVipCurrenciesBonus.length > 0 ? (
        <Stack p={{ sm: 3 }} pt={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              color="text.contrast"
              display="inline-flex"
              alignItems="center"
            >
              {isDesktop ? `${translate('duration')}: ` : <CalendarIcon />}
              &nbsp;
              <Typography component="span" color="text.primary">
                {bonusType === 'weekly'
                  ? `${format(
                      startOfWeek(addWeeks(new Date(), -1), {
                        weekStartsOn: 1,
                      }),
                      'dd/MM/yyyy',
                    )}-${format(
                      endOfWeek(addWeeks(new Date(), -1), { weekStartsOn: 1 }),
                      'dd/MM/yyyy',
                    )}`
                  : `${format(
                      addMonths(startOfMonth(new Date()), -1),
                      'dd/MM/yyyy',
                    )}-${format(
                      endOfMonth(addMonths(new Date(), -1)),
                      'dd/MM/yyyy',
                    )}`}
              </Typography>
            </Typography>

            <ThemedButton
              className="claim-bonus-btn"
              onClick={async () => {
                const isClaimSuccessful = await onClickClaimBonus();
                if (isClaimSuccessful) {
                  enqueueSnackbar(translate('claim_bonus_successfully'), {
                    variant: 'success',
                  });
                  refetchLoyalUser();
                } else {
                  enqueueSnackbar(translate('claim_bonus_failed'), {
                    variant: 'error',
                  });
                }
              }}
            >
              {translate('claim_all')}
            </ThemedButton>
          </Stack>
          <Stack mt={2}>
            <Typography
              variant="subtitle1"
              color="text.contrast"
              textAlign="center"
              flexGrow={1}
              fontWeight={700}
            >
              {translate('your_total_bonus')}:
            </Typography>
            <Box className="vip-bonus-currencies">
              {availableVipCurrenciesBonus?.map((c) => (
                <Box key={c.id} className="vip-bonus-currency">
                  <Typography component="span">
                    {getCurrencyAmount(
                      bonusType === 'weekly'
                        ? c.lastWeekBonusAmount
                        : c.lastMonthBonusAmount,
                      c.currency,
                      {
                        onlyCurrency: true,
                      },
                    )}
                  </Typography>

                  <img
                    width={20}
                    src={coins[c.currency.shortName]}
                    alt="image_coin"
                  />
                </Box>
              ))}
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Stack
          p={3}
          pt={5}
          alignItems="center"
          className="no-bonus-available"
          gap={3}
        >
          <div>
            <Typography
              color="text.contrast"
              textAlign="center"
              variant="subtitle1"
              fontWeight={700}
            >
              {translate('no_bonus_available')}
            </Typography>
            <Typography fontWeight={600} textAlign="center" variant="body2">
              {translate('play_to_earn_bonus')}
            </Typography>
          </div>
          <ThemedButton
            className="play-and-wager-btn"
            onClick={() => onClickPlayAndWager()}
          >
            {translate('play_and_wager')}
          </ThemedButton>
        </Stack>
      )}
    </PeriodicBonusWrapper>
  );
};

export const VipClubWeeklyBonusTab = (
  props: Omit<IPeriodicBonusTabProps, 'bonusType'>,
) => <PeriodicBonusTab bonusType="weekly" {...props} />;

export const VipClubMonthlyBonusTab = (
  props: Omit<IPeriodicBonusTabProps, 'bonusType'>,
) => <PeriodicBonusTab {...props} bonusType="monthly" />;
