import { EmailVerifyIllustration } from 'assets/illustrations';
import ConfirmDialog from 'components/ConfirmDialog';

import { Stack, Typography } from '@mui/material';
import { useLocales } from 'locales';
import { EmailVerifiedHost } from 'overrides/components/text-host/TextHost';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}

export default function VerifyEmailSuccessDialog({
  open,
  onClose,
  onConfirm,
}: Props) {
  const { translate } = useLocales();

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={translate('email_verified')}
      titleAlign="center"
      sx={{ height: 385 }}
      actions={[
        {
          label: 'Done',
          variant: 'contained',
          color: 'secondary',
          onClick: onConfirm,
        },
      ]}
    >
      <Stack justifyContent="center">
        <EmailVerifyIllustration
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: { xs: 2, md: 3 },
          }}
        />
        <Typography
          variant="subtitle2"
          textAlign="center"
          whiteSpace="pre-wrap"
        >
          {translate(EmailVerifiedHost())}
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
}
