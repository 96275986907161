import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { DialogHeader as DialogHeaderStyled } from 'components/Dialog/styles';
import { useLocales } from 'locales';

import { IconButton, Stack, Typography } from '@mui/material';

// ------------------------------------------------------------

export interface IDialogHeaderProps {
  handleClose: () => void;
  title: string;
  helpLink?: React.ReactNode;
  hideCloseIcon?: boolean;
}

export const WalletDialogHeader = ({
  handleClose,
  title,
  helpLink,
  hideCloseIcon,
}: IDialogHeaderProps) => {
  const { translate } = useLocales();

  return (
    <DialogHeaderStyled
      className="bet-detail-header"
      hasCloseBtn
      mb={{ xs: 3 }}
    >
      <Typography variant="h5">{translate(title)}</Typography>

      <Stack direction="row" spacing={2}>
        {helpLink}
        {!hideCloseIcon && (
          <IconButton
            className="close-btn"
            onClick={() => handleClose()}
            sx={{ padding: '0' }}
          >
            <CloseCircle width="24px" height="24px" />
          </IconButton>
        )}
      </Stack>
    </DialogHeaderStyled>
  );
};

WalletDialogHeader.defaultProps = {
  hideCloseIcon: false,
  helpLink: undefined,
};
