import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  position: 'relative',
  backgroundColor: 'transparent',

  '.stepper-item': {
    flex: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.background.main,
    fontSize: '0.875rem',
    fontWeight: 700,
    padding: '10px 20px',
    cursor: 'pointer',
    '-webkit-clip-path':
      'polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)',

    '&:first-of-type': {
      '-webkit-clip-path':
        'polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)',
    },

    '&:last-child': {
      '-webkit-clip-path':
        'polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    },
  },
  '.active-step': {
    backgroundImage:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  },
  '.visited-step': {
    opacity: 0.5,
    color: theme.palette.background.main,
    backgroundImage:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  },
  '.inactive-step': {
    color: theme.palette.text.primary,
  },
}));

export const FirstStep = styled(Box)(() => ({
  display: 'flex',
  borderRadius: '24px 0 0 24px',
}));

export const MiddleStep = styled(Box)(() => ({
  display: 'flex',
  marginLeft: '-14px',
}));

export const LastStep = styled(Box)(() => ({
  display: 'flex',
  marginLeft: '-14px',
  borderRadius: '0 24px 24px 0',
}));
