import { useReactiveVar } from '@apollo/client';
import { DialogAnimate } from 'components/animate';
import LoadingAnimated from 'components/LoadingAnimated';
import BetHistoriesDialogHeader from 'components/_Games/_GamesMiniDice/bet-histories/components/bet-histories-dialog-header';
import BetHistoriesTabs from 'components/_Games/_GamesMiniDice/bet-histories/components/bet-histories-tabs';
import MiniDiceMyBet from 'components/_Games/_GamesMiniDice/bet-histories/components/my-bet';
import MiniDiceRoundHistories from 'components/_Games/_GamesMiniDice/bet-histories/components/rounds-histories';
import MiniDiceRules from 'components/_Games/_GamesMiniDice/bet-histories/components/rules';
import { MiniDiceBetHistoriesDialogWrapper } from 'components/_Games/_GamesMiniDice/bet-histories/styles';
import { useThreeDiceHistoriesQuery } from 'graph';
import { useLocales } from 'locales';
import { memo, useEffect, useState } from 'react';
import { storageCurrencyVar } from 'store';

import { Box, useTheme } from '@mui/material';

interface IMiniDiceBetHistoriesProps {
  open: boolean;
  onClose: VoidFunction;
}

type TabValue = 'my_bet' | 'round_histories' | 'rules';

const MiniDiceBetHistories = ({
  open,
  onClose,
}: IMiniDiceBetHistoriesProps) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const defaultTab: TabValue = window.history.state?.dialogTab || 'my_bet';
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const [selectedTab, setSelectedTab] = useState<TabValue>(defaultTab);
  const { data: threeDiceBetHistoriesData, loading } =
    useThreeDiceHistoriesQuery({
      variables: { currencyId: storageCurrency.id },
      fetchPolicy: 'network-only',
    });
  const tabs: TabsType<TabValue> = [
    {
      label: translate('my_bet'),
      value: 'my_bet',
    },
    {
      label: translate('round_histories'),
      value: 'round_histories',
    },
    {
      label: translate('rules'),
      value: 'rules',
    },
  ];

  useEffect(
    () => () => {
      window.history.replaceState({ dialogTab: null }, undefined);
    },
    [],
  );

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        width: 800,
        height: 600,
        maxWidth: 'unset',
        overflow: 'hidden',
        padding: (theme) => theme.spacing(2, 1.5),
        [theme.breakpoints.down(800)]: {
          '&&&': { margin: 0 },
          width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'unset',
          minHeight: 'unset',
          width: '100%',
          height: '100%',
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: (theme) => theme.shape.borderRadius,
        },
      }}
    >
      <BetHistoriesDialogHeader onClose={onClose} />
      <BetHistoriesTabs<TabValue>
        tabs={tabs}
        disabled={loading}
        selectedTab={selectedTab}
        onChangeTab={(tab) => setSelectedTab(tab)}
      />
      <MiniDiceBetHistoriesDialogWrapper>
        {loading ? (
          <Box
            position="absolute"
            width="100%"
            height="100%"
            sx={{ top: 0, left: '50%', transform: 'translate(-50%, -5%)' }}
          >
            <LoadingAnimated />
          </Box>
        ) : (
          <>
            {selectedTab === 'my_bet' && (
              <MiniDiceMyBet
                myHistories={
                  threeDiceBetHistoriesData?.threeDiceCurrentGame?.userHistories
                }
              />
            )}

            {selectedTab === 'round_histories' && (
              <MiniDiceRoundHistories
                roundHistories={
                  threeDiceBetHistoriesData?.threeDiceCurrentGame?.foreCasts
                }
              />
            )}

            {selectedTab === 'rules' && <MiniDiceRules />}
          </>
        )}
      </MiniDiceBetHistoriesDialogWrapper>
    </DialogAnimate>
  );
};

export default memo(MiniDiceBetHistories);
