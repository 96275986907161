import { useLocales } from 'locales';
import Markdown from 'markdown';
import { memo, useEffect, useState } from 'react';

import { Box } from '@mui/material';

const MiniDiceEventRule = () => {
  const { translate, currentLang } = useLocales();
  const [crashEventRules, setCrashEventRules] = useState('');

  useEffect(() => {
    if (!crashEventRules) {
      try {
        import(`markdown/threedice/event/${currentLang.value}.md`)
          .then((response) => fetch(response.default))
          .then((response) => response.text())
          .then((text) => setCrashEventRules(text))
          .catch(() => {
            setCrashEventRules(translate('coming_soon2'));
          });
      } catch {
        setCrashEventRules(translate('coming_soon2'));
      }
    }
  }, [currentLang]);

  return (
    <Box id="mini-dice-event-rules">
      <Markdown>{crashEventRules}</Markdown>
    </Box>
  );
};

export default memo(MiniDiceEventRule);
