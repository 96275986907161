import { allLangs } from 'locales';
import { useState } from 'react';

import { Box, Popover, Typography } from '@mui/material';

import { LanguageBox } from './style';

// ------------------------------------------------------------------

const ChangeLanguageDialog = ({ anchorEl, onClose, onSelect }) => {
  const id = 'language-popover';
  const open = Boolean(anchorEl);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'ENG',
  );
  const handleSelect = (language) => {
    onSelect(language);
    // i18n.changeLanguage(language.value);
    localStorage.setItem('selectedLanguage', language.label);
    onClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        position: 'absolute',
        top: '3px',
        left: '-10px',
      }}
    >
      <LanguageBox className="language-box">
        {allLangs.map((language) => (
          <Box
            className={`language-item ${
              selectedLanguage === language.label ? 'selected' : ''
            }`}
            key={language.label}
            onClick={() => {
              onSelect(language);
              setSelectedLanguage(language.label);
              handleSelect(language);
            }}
          >
            <img src={language.icon} alt={language.label} />
            <Typography className="language-name">
              {language.fullLabel}
            </Typography>
          </Box>
        ))}
      </LanguageBox>
    </Popover>
  );
};

export default ChangeLanguageDialog;
