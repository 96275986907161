import { SvgIcon } from '@mui/material';

export function IconNewRelease(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.71467 13.975C5.37801 13.6385 5.25123 13.8076 5.17635 13.8825C3.23773 15.8212 1.51666 20.4988 2.42652 20.0419C5.73877 18.3788 6.29065 18.8739 8.22921 16.9355C8.30415 16.8605 8.47649 16.737 8.13003 16.3905L5.71467 13.975Z"
        fill="#9998D4"
      />
      <path
        d="M16.1657 13.9489C16.0779 13.6693 16.2619 13.5346 16.3394 13.4509C20.6181 8.83154 23.3374 2.67703 21.3223 0.661742C19.3177 -1.34269 13.353 1.45607 8.73485 5.70394C8.64349 5.78808 8.46341 5.94744 8.14125 5.88018L6.70241 5.53756C6.30293 5.44256 5.73847 5.60225 5.44809 5.89262L0.168549 11.1721C-0.121827 11.4625 -0.0274849 11.7523 0.378221 11.816L4.19674 12.4161C4.60245 12.4799 5.17195 12.2945 5.46226 12.004C5.46226 12.004 5.65928 11.8065 5.85724 12.0044C6.94936 13.0964 8.97882 15.1258 10.0193 16.1664C10.2261 16.3731 10.028 16.5698 10.028 16.5698C9.7376 16.8603 9.55222 17.4296 9.61604 17.8354L10.2161 21.6539C10.2798 22.0597 10.5696 22.154 10.86 21.8636L16.1396 16.584C16.4299 16.2936 16.5896 15.7293 16.4945 15.3298L16.1657 13.9489ZM14.9837 7.12808C14.0895 6.23384 14.0895 4.78415 14.9837 3.89004C15.8779 2.99581 17.3276 2.99581 18.2219 3.89004C19.116 4.78408 19.116 6.23384 18.2219 7.12801C17.3276 8.02225 15.8778 8.02225 14.9837 7.12808Z"
        fill="#9998D4"
      />
    </SvgIcon>
  );
}
