import { SvgIcon } from '@mui/material';

export function IconQuestion(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M14.1665 2.02505H5.83317C3.33317 2.02505 1.6665 3.69171 1.6665 6.19171V11.1917C1.6665 13.6917 3.33317 15.3584 5.83317 15.3584V17.1334C5.83317 17.8 6.57484 18.2 7.12484 17.825L10.8332 15.3584H14.1665C16.6665 15.3584 18.3332 13.6917 18.3332 11.1917V6.19171C18.3332 3.69171 16.6665 2.02505 14.1665 2.02505ZM9.99984 12.1667C9.64984 12.1667 9.37484 11.8834 9.37484 11.5417C9.37484 11.2 9.64984 10.9167 9.99984 10.9167C10.3498 10.9167 10.6248 11.2 10.6248 11.5417C10.6248 11.8834 10.3498 12.1667 9.99984 12.1667ZM11.0498 8.70838C10.7248 8.92505 10.6248 9.06671 10.6248 9.30005V9.47505C10.6248 9.81671 10.3415 10.1 9.99984 10.1C9.65817 10.1 9.37484 9.81671 9.37484 9.47505V9.30005C9.37484 8.33338 10.0832 7.85838 10.3498 7.67505C10.6582 7.46671 10.7582 7.32505 10.7582 7.10838C10.7582 6.69171 10.4165 6.35005 9.99984 6.35005C9.58317 6.35005 9.2415 6.69171 9.2415 7.10838C9.2415 7.45005 8.95817 7.73338 8.6165 7.73338C8.27484 7.73338 7.9915 7.45005 7.9915 7.10838C7.9915 6.00005 8.8915 5.10005 9.99984 5.10005C11.1082 5.10005 12.0082 6.00005 12.0082 7.10838C12.0082 8.05838 11.3082 8.53338 11.0498 8.70838Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
