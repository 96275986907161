import { BetResultContainerWrapper } from 'pages/Games/GameDetail/BetResult/styles';

import { Stack, styled } from '@mui/material';

export const CrashPlayersTableContainer = styled(BetResultContainerWrapper)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    minWidth: '390px',
    maxWidth: '750px',
    '&': {
      borderRadius: theme.shape.borderRadius,
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      flex: 1,
      maxHeight: 757,
      backgroundColor: theme.palette.background.secondary,
      padding: 0,
      '.MuiTableRow-head .MuiTableCell-root': {
        backgroundColor: theme.palette.background.secondary,
        backgroundImage: 'unset',
        padding: theme.spacing(1),
        p: {
          color: theme.palette.text.contrast,
          fontWeight: 600,
          fontSize: 14,
          border: 'none',
        },
      },
      '.MuiTableBody-root .MuiTableRow-root': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.hovering,
        },
        transition: 'all ease 0.3s',
        '& td:first-of-type': {
          borderRadius: 'unset',
        },
        '& td:last-child': {
          borderRadius: 'unset',
        },
      },
      '.MuiTableBody-root': {
        '.MuiTableCell-root': {
          border: 'none',
          p: {
            fontSize: 14,
            fontWeight: 500,
          },
          '&.time p': {
            textTransform: 'uppercase',
          },
          '&.increase p': {
            color: theme.palette.primary.main,
          },
          '&.decrease p': {
            color: theme.palette.support.orange,
          },
          '& .user_name, & .profit, & .bet_amount, & .jackpot_amount': {
            fontWeight: 600,
          },
          img: {
            '&.currency_image': {
              marginLeft: theme.spacing(1),
            },
            '&.avatar': {
              marginRight: theme.spacing(1),
              borderRadius: '50%',
              '&[alt=hidden]': {
                height: 16,
                width: 20,
              },
            },
            [theme.breakpoints.up('xs')]: {
              height: 16,
              width: 16,
              '&.currency_image': {
                marginLeft: theme.spacing(0.5),
              },
              '&.avatar': {
                marginRight: theme.spacing(0.5),
              },
            },
          },
        },
      },
      '.MuiTableCell-root': {
        border: 'none',
        padding: theme.spacing(1.5, 1),
      },
      '.show_more p, .show_less p': {
        fontSize: 12,
      },
    },
  }),
);

export const OpenPredictorDialogButton = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  left: '10%',
  top: '17%',
  cursor: 'pointer',
  transform: 'scale(0.9)',
  alignItems: 'center',
  paddingRight: theme.spacing(3),
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.1)',
    },
  },
  animation: 'bounce 2s infinite',
  ':hover': {
    transform: 'scale(1)',
    transition: 'all ease-in-out .2s',
  },
  img: {
    aspectRatio: '81/44',
    width: 89,
    [theme.breakpoints.down('sm')]: {
      width: 53,
    },
  },
  '& > p': {
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  borderRadius: theme.shape.borderRadius,
  background:
    'radial-gradient(100.54% 103.57% at 67.78% -44.32%, #B893FB 0%, #813FFA 100%)',
  '& > div': {
    alignItems: 'center',
    p: {
      fontWeight: 700,
      color: theme.palette.text.contrast,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
  },
}));
