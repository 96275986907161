import { SvgIcon } from '@mui/material';

export function IconGithub(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_6403_139311)">
        <circle cx="20" cy="20" r="20" fill="white" />
        <path
          d="M24.3402 18.4268C23.0442 18.4268 22.0195 19.6022 22.0195 21.0368C22.0195 22.4714 23.0623 23.6347 24.3402 23.6347C25.6181 23.6347 26.6428 22.4653 26.6609 21.0307C26.6729 19.5961 25.6422 18.4328 24.3402 18.4328V18.4268Z"
          fill="#858997"
        />
        <path
          d="M15.7689 18.4268C14.473 18.4268 13.4482 19.6022 13.4482 21.0368C13.4482 22.4714 14.491 23.6347 15.7689 23.6347C17.0468 23.6347 18.0775 22.4653 18.0896 21.0307C18.1016 19.5961 17.0649 18.4328 15.7689 18.4328V18.4268Z"
          fill="#858997"
        />
        <path
          d="M19.994 0C8.95117 0 0 8.9572 0 20C0 31.0488 8.9572 40 20 39.994C31.0428 39.994 40 31.0368 39.994 19.994C39.994 8.95117 31.0428 0 19.994 0ZM32.8451 26.5883C30.874 28.053 28.6679 29.1682 26.3171 29.8855C25.7866 29.1742 25.3225 28.4147 24.9186 27.619C25.6841 27.3357 26.4195 26.9801 27.1248 26.5642C26.9439 26.4316 26.7631 26.2869 26.5883 26.1423C22.4533 28.0952 17.6612 28.0952 13.5262 26.1423C13.3574 26.2809 13.1766 26.4195 12.9958 26.5582C13.695 26.9741 14.4364 27.3237 15.1959 27.607C14.792 28.4027 14.3279 29.1621 13.7975 29.8734C11.4467 29.1561 9.24051 28.041 7.26944 26.5763C6.81736 21.7601 7.72755 16.8776 11.085 11.8686C12.7788 11.085 14.569 10.5304 16.4135 10.217C16.6727 10.6691 16.9017 11.1392 17.1127 11.6154C19.0657 11.3261 21.0549 11.3261 23.0078 11.6154C23.1947 11.1694 23.4539 10.6269 23.689 10.217C25.5274 10.5304 27.3177 11.079 29.0115 11.8626C31.9289 16.1543 33.3816 21.0006 32.8511 26.5823L32.8451 26.5883Z"
          fill="#858997"
        />
      </g>
      <defs>
        <clipPath id="clip0_6403_139311">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
