import { VipProgressWrapper } from 'pages/VipClub/components/VipPoint/styles_v2';

import { Stack, styled } from '@mui/material';

export const PeriodicBonusWrapper = styled(Stack)(({ theme }) => ({
  button: {
    width: '100%',
    padding: theme.spacing(1.25, 3),
  },

  '.claim-bonus-btn': {
    maxWidth: 170,
  },

  '.play-and-wager-btn': {
    maxWidth: 220,
  },

  '.vip-bonus-currencies': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    background: theme.palette.background.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 1.5),
    fontWeight: 700,

    '.vip-bonus-currency': {
      width: 'calc((100% - 16px) / 3)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
      background: theme.palette.background.tertiary,
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(1, 0.75),

      span: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.text.contrast,
        lineHeight: 'normal',
      },
    },
  },
}));

export const PeriodicBonusTop = styled(VipProgressWrapper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '.background-text': {
    borderRadius: theme.shape.borderRadius,
  },
}));
