import { BET_BUTTON_STATE } from 'pages/Games/types';
import { PRIMARY_GREEN_COLOR, WHITE_COLOR } from 'theme/colors';

import {
  ButtonBase,
  ButtonBaseProps,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => ({
  zIndex: 998,
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '480px',
  height: '382px',

  [theme.breakpoints.down('sm')]: {
    width: '388px',
    height: '342px',
  },

  // position: 'fixed',
  backgroundColor: '#413C7C',
  boxShadow: '0px 0px 4px 0px #0000004D',
  borderRadius: theme.shape.borderRadius,
}));

export const Header = styled(Stack)(({ theme }) => ({
  zIndex: 999,
  alignItems: 'center',
  flexDirection: 'row',
  width: '472px',
  height: '36px',
  [theme.breakpoints.down('sm')]: {
    width: '380px',
  },
  justifyContent: 'space-between',
  backgroundColor: '#24244A',
  borderRadius: '8px',
  marginTop: '4px',
  padding: '4px 4px',
}));

export const Footer = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '12px',
  gap: '12px',
  minWidth: '368px',
}));

export const HeaderButton = styled(IconButton)(() => ({
  padding: '4px',
}));

export const GroupHeaderButtons = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '4px',
}));

interface SubmitButtonProps extends ButtonBaseProps {
  status?: BET_BUTTON_STATE;
}

export const BetButton = styled(ButtonBase)<SubmitButtonProps>(
  ({ status, theme }) => {
    const colos = {
      BET: 'linear-gradient(180deg, #62E115 0%, #00A807 24.48%, #1DCD00 100%)',
      BET_NEXT:
        'linear-gradient(180deg, #62E115 0%, #00A807 24.48%, #1DCD00 100%)',
      CANCEL:
        'linear-gradient(180deg, #9A99D5 0%, #6861B3 24.48%, #726BC7 100%)',
      WAIT: 'linear-gradient(180deg, #FFD873 0%, #E76E00 24.48%, #FF7A00 100%)',
      CASHOUT:
        'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    };
    return {
      width: '124px',
      height: '84px',
      background: colos[status],
      borderRadius: theme.shape.borderRadius,
      transition: 'box-shadow 0.15s,transform 0.15s;',
      boxShadow:
        'rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px',
      color: 'white',
      '&:hover': {
        boxShadow:
          'rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px',
        transform: 'translateY(-3px);',
      },
      '&:active': {
        boxShadow: '#d6d6e7 0 3px 7px inset',
        transform: 'translateY(3px);',
      },
    };
  },
);

export const LabelTextField = styled(Typography)(() => ({
  color: '#9A99D5',
  backgroundColor: '#161432',
  borderRadius: '12px 0px 0px 12px',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  padding: '11px',
  borderRight: '1px solid #2F2B60',
  width: '140px',
}));

export const BetTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: '#161432',
    borderRadius: theme.shape.borderRadius,
    border: 0,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: PRIMARY_GREEN_COLOR,
      border: 0,
    },
    input: {
      color: WHITE_COLOR,
    },
    backgroundColor: '#161432',
    borderRadius: '0px 12px 12px 0px',
    height: 40,
    border: 0,
  },
}));

export const OpenPredictorDialogButtonMini = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  left: '8%',
  top: '11%',
  cursor: 'pointer',
  transform: 'scale(0.9)',
  alignItems: 'center',
  paddingRight: theme.spacing(1),
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.1)',
    },
  },
  animation: 'bounce 2s infinite',
  ':hover': {
    transform: 'scale(1)',
    transition: 'all ease-in-out .2s',
  },
  img: {
    aspectRatio: '81/44',
    width: 69,
    [theme.breakpoints.down('sm')]: {
      width: 53,
    },
  },
  '& > p': {
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  borderRadius: '2px 12px 12px 2px',
  background:
    'radial-gradient(100.54% 103.57% at 67.78% -44.32%, #B893FB 0%, #813FFA 100%)',
  '& > div': {
    alignItems: 'center',
    p: {
      fontWeight: 700,
      color: theme.palette.text.contrast,
      fontSize: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
  },
}));
