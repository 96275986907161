import { ReactComponent as WobbleIcon } from 'assets/icons/wobble.svg';
import {
  BET_CONTROL_ELEMENT,
  BET_CONTROL_MODE,
} from 'components/_Games/game-control-v2/constant';
import useFormValues from 'hooks/useFormValues';
import { memo } from 'react';
import { BACKGROUND_IMAGE_MAIN } from 'theme/colors';

import { Box, Stack, Typography } from '@mui/material';
import {
  BetStateButtonStyle,
  wobbleKeyFrames,
} from 'components/_Games/game-control-v2/bet-control/components/bet-state-btn/styles';

export const commonStateBtnStyle = {
  state: '',
  title: '',
  subtitle: '',
  startIcon: null,
  disabled: false,
  color: 'text.contrast',
  backgroundColor: BACKGROUND_IMAGE_MAIN,
};

export const commonBetStateBtn = {
  bet: {
    ...commonStateBtnStyle,
    title: 'Bet',
  },
  cashout: {
    ...commonStateBtnStyle,
    title: 'Cashout',
  },
  startAutoBet: {
    ...commonStateBtnStyle,
    title: 'Start Auto Bet',
  },
  stopAutoBet: {
    ...commonStateBtnStyle,
    title: 'Stop Auto Bet',
  },
};

const BetStateButton = () => {
  const [betStateBtn, isBetProcessing, mode]: [
    BetStateButtonGameForm,
    boolean,
    BET_CONTROL_MODE,
  ] = useFormValues([
    BET_CONTROL_ELEMENT.BET_STATE_BTN,
    'betControl.isBetProcessing',
    'betControl.mode',
  ]);

  return (
    <BetStateButtonStyle
      type="submit"
      id={BET_CONTROL_ELEMENT.BET_STATE_BTN.replace('.', '-')}
      className="bet-control-element"
      startIcon={betStateBtn.startIcon}
      sx={{
        color: (theme) => theme.palette.background.main,
        borderRadius: (theme) => `${theme.shape.borderRadius}px`,
        background: (theme) =>
          betStateBtn.backgroundColor ||
          theme.palette.background.primaryBgImage,
        ...(betStateBtn.disabled && {
          opacity: 0.6,
          cursor: 'not-allowed !important',
          pointerEvents: 'inherit !important',
        }),
        ...(betStateBtn.startIcon && {
          flexDirection: 'row',
        }),
        flexDirection: 'row',
        width: '100%',
        '&.MuiButton-root:active': {
          backgroundColor: betStateBtn.backgroundColor,
          opacity: 0.6,
        },
        '&.MuiButton-root:disabled': {
          opacity: 0.6,
        },
      }}
      disabled={betStateBtn.disabled}
    >
      <Stack>
        <Typography
          fontSize={16}
          lineHeight="16px"
          fontWeight="bold"
          textAlign="center"
        >
          {`${betStateBtn.title}`}
        </Typography>
        {betStateBtn.subtitle && (
          <Typography fontSize={12} fontWeight="bold" textAlign="center">
            {`(${betStateBtn.subtitle})`}
          </Typography>
        )}
      </Stack>
      {mode === BET_CONTROL_MODE.AUTO && isBetProcessing && (
        <Box
          ml={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            svg: {
              animation: `${wobbleKeyFrames} 1.6s  infinite`,
              animationTimingFunction: 'cubic-bezier(.87,-.41,.19,1.44)',
            },
          }}
        >
          <WobbleIcon />
        </Box>
      )}
    </BetStateButtonStyle>
  );
};

export default memo(BetStateButton);
