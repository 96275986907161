import { IconDownMiniGame, IconUpMiniGame } from 'assets/icons';
import { memo, useState } from 'react';
import { toN } from 'utils/number-help';

import { IconButton, InputAdornment, Stack } from '@mui/material';

import { formatAmount } from '../gameControl/betControl';
import { BetTextField, LabelTextField } from './styles';

interface CashoutAtInputProps {
  value: string;
  onChange: (value) => void;
  minCashout: number;
  disabled?: boolean;
}

function CashoutAtInput({
  value,
  onChange,
  minCashout,
  disabled = false,
}: CashoutAtInputProps) {
  const [cashoutAt, setCashoutAt] = useState(value);

  return (
    <Stack direction="row">
      <LabelTextField>Cashout at</LabelTextField>
      <BetTextField
        type="number"
        disabled={disabled}
        fullWidth
        value={cashoutAt}
        onChange={(event) => {
          if (!Number.isNaN(Number(event.target.value))) {
            setCashoutAt(event.target.value);
            onChange(event.target.value);
          }
        }}
        onBlur={(event) => {
          if (toN(event.target.value) < minCashout) {
            setCashoutAt(formatAmount(minCashout.toFixed(2)));
            onChange(formatAmount(minCashout.toFixed(2)));
            return;
          }
          setCashoutAt(formatAmount(toN(event.target.value).toFixed(2)));
          onChange(formatAmount(toN(event.target.value).toFixed(2)));
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Stack spacing={1} direction="row">
                <IconButton
                  disabled={disabled}
                  sx={{ p: 0 }}
                  onClick={() => {
                    if (toN(cashoutAt) <= minCashout) return;
                    setCashoutAt((val) =>
                      formatAmount((toN(val) - 0.01).toFixed(2)),
                    );
                  }}
                >
                  <IconDownMiniGame />
                </IconButton>
                <IconButton
                  disabled={disabled}
                  sx={{ p: 0 }}
                  onClick={() => {
                    setCashoutAt((val) =>
                      formatAmount((toN(val) + 0.01).toFixed(2)),
                    );
                  }}
                >
                  <IconUpMiniGame />
                </IconButton>
              </Stack>
            </InputAdornment>
          ),
        }}
        onFocus={(event) => {
          event.target.select();
        }}
      />
    </Stack>
  );
}

export default memo(CashoutAtInput);
