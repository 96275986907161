import coinImages from 'assets/images/coins';
import { DialogAnimate } from 'components/animate';
import {
  DialogBody,
  DialogHeader,
  DialogWrapper,
} from 'components/Dialog/styles';
import {
  Currency,
  TransferSetting,
  useCreateInternalTransferMutation,
} from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { isString } from 'lodash';
import { useState } from 'react';

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Avatar, Box, IconButton, Typography, useTheme } from '@mui/material';

import { ModalTipSuccess } from './ModalTipSuccess';
import { ContinueButton } from './styles';

// ----------------------------------------------------------------

export interface TipInformationProps {
  handleClose: () => void;
  handleBack: () => void;
  selectedCurrency: Currency;
  dataTipping: {
    message: string;
    receiver: string | any;
    tipAmount: string | number;
    avatar: string;
    label: string;
  };
  transactionsFee: TransferSetting;
  handleCloseWalletDialog: () => void;
}

export function TipInformation(props: TipInformationProps) {
  const {
    handleClose,
    handleBack,
    selectedCurrency,
    dataTipping,
    transactionsFee,
    handleCloseWalletDialog,
  } = props;

  const theme = useTheme();
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const [openVerification, setOpenVerification] = useState(false);
  const [isError, setIsError] = useState(null);
  const [dataTranfer, setDataTranfer] = useState(null);
  const [internalTranfer, { loading: loadingTranfer }] =
    useCreateInternalTransferMutation();

  const format = (amount) =>
    Intl.NumberFormat('en-US', {
      maximumSignificantDigits: 9,
      // minimumSignificantDigits: 9,
      maximumFractionDigits: 9,
    }).format(Number(amount));
  const totalTips = (rate: number, fee: number, tips: number) => {
    let total = tips;
    if (rate > 0) {
      total += total * rate;
    }
    if (fee > 0) {
      total += fee;
    }
    return total;
  };
  const submitTransactionTip = () => {
    internalTranfer({
      variables: {
        receiverId: dataTipping?.receiver,
        currencyId: selectedCurrency?.id,
        amount: +dataTipping.tipAmount,
        message: dataTipping?.message,
      },
    }).then((value) => {
      if (value.data.createInternalTransfer.content !== null) {
        setDataTranfer(value.data.createInternalTransfer.content);
        setOpenVerification(true);
        // handleClose();
        // setOpenVerification(false);
        // handleCloseWalletDialog();
      } else {
        setOpenVerification(true);

        setIsError(value.data.createInternalTransfer.error);
      }
    });
  };

  return (
    <DialogAnimate
      open
      onClose={handleClose}
      sx={{
        width: 633,
        height: '100%',
        justifyContent: 'center',
        '&': {
          maxHeight: 540,
        },
        [theme.breakpoints.down(600)]: {
          maxHeight: 'unset',
          maxWidth: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
    >
      <DialogWrapper>
        <DialogHeader
          hasBackBtn
          hasCloseBtn
          backgroundHeader={theme.palette.background.secondary}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
            px={theme.spacing(1)}
            pt={theme.spacing(3)}
          >
            <IconButton onClick={handleBack}>
              <ArrowBackIosNewRoundedIcon fontSize="small" />
            </IconButton>
            <Typography className="dialog-title" variant="h6">
              {translate('tip_information')}
            </Typography>
          </Box>
        </DialogHeader>
        <DialogBody>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              fontWeight="700"
              fontSize="20px"
              color="white"
              textAlign="center"
              py={1}
            >
              {translate('you_are_tipping_to')}
            </Typography>
            {dataTipping.avatar ? (
              <>
                <Avatar
                  alt="Remy Sharp"
                  src={dataTipping.avatar}
                  sx={{ width: 107, height: 107 }}
                />
                <Typography
                  fontWeight="700"
                  fontSize="24px"
                  color="white"
                  textAlign="center"
                  py={1}
                  sx={{
                    color: '#EFC516',
                  }}
                >
                  {dataTipping.label}
                </Typography>
                <Typography
                  fontWeight="400"
                  fontSize="16px"
                  color="white"
                  textAlign="center"
                  pb={2}
                  sx={{
                    color: '#858997',
                  }}
                >
                  {/* {dataTipping?.receiver || dataTipping?.receiver?.label} */}
                  ID:{' '}
                  {isString(dataTipping?.receiver)
                    ? dataTipping?.receiver
                    : dataTipping?.receiver?.label}
                </Typography>
              </>
            ) : (
              <Typography
                fontWeight="700"
                fontSize="24px"
                color="white"
                textAlign="center"
                pb={2}
                sx={{
                  color: '#EFC516',
                }}
              >
                {/* {dataTipping?.receiver || dataTipping?.receiver?.label} */}
                ID:{' '}
                {isString(dataTipping?.receiver)
                  ? dataTipping?.receiver
                  : dataTipping?.receiver?.label}
              </Typography>
            )}

            {/* <Typography fontWeight="400" fontSize="16px" textAlign="center">
              ID:
            </Typography> */}
          </Box>
          <Box display="flex" justifyContent="space-between" py={1}>
            <Box display="flex" gap={1}>
              <img
                src={coinImages[selectedCurrency?.shortName]}
                alt="coin"
                width={32}
              />
              <Typography
                fontSize="20px"
                color="white"
                fontWeight="700"
                textTransform="uppercase"
              >
                {selectedCurrency?.shortName}
              </Typography>
            </Box>
            <Typography color="#53BA0C" fontSize="20px" fontWeight="600">
              {getCurrencyAmount(
                Number(dataTipping?.tipAmount),
                selectedCurrency,
                { onlyCurrency: true },
              )}
            </Typography>
          </Box>

          {transactionsFee?.transferFeeRate > 0 && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              py={1}
            >
              <Typography fontSize="16px" color="white" fontWeight="500">
                {translate('transaction_fee_rate')}
              </Typography>
              <Typography
                color="white"
                fontSize="16px"
                textTransform="uppercase"
              >
                {format(transactionsFee.transferFeeRate * 100)} %
              </Typography>
            </Box>
          )}
          {transactionsFee?.transferFeeAmount > 0 && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              py={1}
            >
              <Typography fontSize="16px" color="white" fontWeight="500">
                {translate('transaction_fee_amount')}
              </Typography>
              <Typography
                color="white"
                fontSize="16px"
                textTransform="uppercase"
              >
                {getCurrencyAmount(
                  Number(transactionsFee.transferFeeAmount),
                  selectedCurrency,
                  {
                    onlyCurrency: true,
                  },
                )}{' '}
                {selectedCurrency?.shortName}
              </Typography>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between" py={1}>
            <Typography fontSize="16px" color="white" fontWeight="500">
              {translate('total_spending_amount')}
            </Typography>
            <Typography color="white" fontSize="16px" textTransform="uppercase">
              {/* {`${format(+dataTipping.tipAmount + transactionsFee)}`}{' '} */}
              {getCurrencyAmount(
                Number(
                  totalTips(
                    transactionsFee?.transferFeeRate,
                    transactionsFee?.transferFeeAmount,
                    +dataTipping.tipAmount,
                  ),
                ),
                selectedCurrency,
                { onlyCurrency: true },
              )}{' '}
              {selectedCurrency?.shortName}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" py={1}>
            <Typography fontSize="16px" color="white" fontWeight="500">
              {translate('message')}
            </Typography>
            <Typography
              color="white"
              fontSize="16px"
              whiteSpace="normal"
              maxWidth="50%"
              sx={{
                '&:first-letter': {
                  textTransform: 'unset',
                },
                textOverflow: 'unset',
                wordWrap: 'break-word',
              }}
            >
              {dataTipping?.message}
            </Typography>
          </Box>

          <ContinueButton
            variant="contained"
            color="secondary"
            disabled={loadingTranfer}
            type="submit"
            sx={{ mt: 3 }}
            onClick={submitTransactionTip}
          >
            {translate('confirm')}
          </ContinueButton>
        </DialogBody>
      </DialogWrapper>
      {/* {dataTranfer && ( */}
      {openVerification && (
        <ModalTipSuccess
          content={dataTranfer}
          isSuccess={isError}
          handleClose={() => {
            handleClose();
            setOpenVerification(false);
            handleCloseWalletDialog();
          }}
          handleBack={() => {
            setOpenVerification(false);
            handleClose();
          }}
          loading={loadingTranfer}
        />
      )}
    </DialogAnimate>
  );
}
