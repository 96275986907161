import { Box, Button, styled } from '@mui/material';

export const CODGWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',

  '.codg-help': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(1),
    },

    a: {
      fontSize: 12,
      fontWeight: 600,
      color: '#63D8FE',
      lineHeight: 'normal',
    },

    svg: {
      width: 20,
      height: 20,
    },
  },

  '.codg-content': {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '.codg-total': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      margin: theme.spacing(3, 0),
    },

    '.codg-item': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      span: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 'normal',
      },

      div: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        span: {
          fontSize: 14,
          fontWeight: 600,
          lineHeight: 'normal',
        },
        svg: {
          width: 20,
          height: 20,
        },
      },
    },

    '.codg-swap': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      position: 'relative',

      '.codg-swap-group': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),

        '.codg-swap-header': {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1.5),

          h6: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.text.contrast,
            lineHeight: 'normal',
          },

          span: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.text.primary,
            lineHeight: 'normal',
            whiteSpace: 'pre-wrap',
            span: {
              color: '#FFE500',
            },
            a: {
              color: '#63D8FE',
            },
          },
        },

        '.codg-swap-info': {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: theme.palette.background.main,
          borderRadius: theme.spacing(1.5),
          padding: theme.spacing(1, 1.5),

          'div:first-of-type': {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            svg: {
              width: 24,
              height: 24,
            },
            span: {
              fontSize: 16,
              fontWeight: 600,
              color: theme.palette.text.primary,
              lineHeight: 'normal',
            },
          },

          'div:last-child': {
            display: 'flex',
            flexDirection: 'column',

            span: {
              fontSize: 12,
              fontWeight: 600,
              color: theme.palette.text.primary,
              lineHeight: 'normal',
            },
          },

          '.balance_box': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',

            '.MuiInputBase-root': {
              display: 'flex',
              justifyContent: 'flex-end',
              input: {
                textAlign: 'end',
                padding: 0,
                color: theme.palette.text.contrast,
                fontSize: '14px',
                backgroundColor: theme.palette.background.main,
                height: 'fit-content',
              },
              '&.disabled': {
                color: theme.palette.text.contrast,
                webkitTextFillColor: theme.palette.text.contrast,
              },
            },
            p: {
              color: theme.palette.text.primary,
            },
          },
        },
      },
    },
  },
}));

export const CodgSwapList = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1.5),
  background: theme.palette.background.main,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 1.5),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(1),
  },

  div: {
    width: '48%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.background.tertiary,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1, 3),
    [theme.breakpoints.down('lg')]: {
      width: 'calc((100% - 16px) / 3)',
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      padding: theme.spacing(1, 1.5),
    },

    span: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.text.contrast,
      lineHeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },
}));

export const ClaimButton = styled(Button)(({ theme }) => ({
  width: '100%',
  minHeight: 48,
  borderRadius: theme.shape.borderRadius,
  marginTop: '12px',
  backgroundImage:
    'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
  color: '#2E2C5E',
  '&.MuiButton-disable': {
    opacity: 0.7,
    color: theme.palette.background.main,
  },
  ':hover': {
    backgroundImage:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    opacity: 0.8,
  },
  ':active': {
    opacity: 0.7,
  },
}));
