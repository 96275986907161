import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthContext } from 'auth/useAuthContext';
import FormProvider, { RHFTextField } from 'components/hook-form';
import ValidationMessage from 'components/ValidationMessage';
import { useRecoverPasswordMutation } from 'graph';
import useLocales from 'locales/useLocales';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Container, Stack, Typography } from '@mui/material';

import {
  ChangePasswordBox,
  SecurityWrapper,
} from '../../ProfileSettings/components/Security/styles';

interface Form {
  newPassword: string;
  confirmPassword: string;
}

enum PASSWORD_RULES {
  NUMBER = 'REQUIRED_NUMBER',
  LOWER_UPPER = 'REQUIRED_LOWER_UPPERCASE',
  LENGTH = 'AT_LEAST_6',
}

const RecoverPassword = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openLoginModal, logout, isAuthenticated } = useAuthContext();
  const [
    resetPasswordMutation,
    { data: resetPwData, loading, error: resetPwError },
  ] = useRecoverPasswordMutation();

  const schema = Yup.object().shape({
    newPassword: Yup.string().required().trim(),
    confirmPassword: Yup.string().required().trim(),
  });

  const form = useForm<Form>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const password = form.watch(['newPassword', 'confirmPassword']);

  const errors = React.useMemo(() => {
    const errors = [];
    const newPassword = password[0];

    if (newPassword.trim().length < 6) errors.push(PASSWORD_RULES.LENGTH);
    if (!newPassword.trim().match(/[0-9]+/)) errors.push(PASSWORD_RULES.NUMBER);
    if (!newPassword.trim().match(/(?=.*?[A-Z])(?=.*?[a-z])/))
      errors.push(PASSWORD_RULES.LOWER_UPPER);

    return errors;
  }, [password]);

  const isNewPasswordInValid =
    form.getFieldState('newPassword').isDirty && !!errors.length;

  const resetPassword = async (data: Form) => {
    const token = searchParams.get('token');
    const response = await resetPasswordMutation({
      variables: {
        token: token || '',
        newPassword: data.newPassword,
      },
    });

    if (response?.data?.recoverPassword?.success) {
      navigate('/');
      openLoginModal();
    }
  };

  const isConfirmPasswordValid = React.useMemo(() => {
    const newPassword = password[0];
    const confirmPassword = password[1];
    return (
      (confirmPassword.length && newPassword === confirmPassword) ||
      !confirmPassword.length
    );
  }, [password]);

  const isDisableChangePassword =
    isNewPasswordInValid ||
    !form.formState.isValid ||
    loading ||
    !isConfirmPasswordValid;

  useEffect(() => {
    if (isAuthenticated) {
      logout();
    }
  }, []);

  return (
    <Container maxWidth="md">
      <SecurityWrapper>
        <Typography
          mb={8}
          variant="h4"
          color="text.contrast"
          textAlign="center"
        >
          {translate('recover_password')}
        </Typography>
        <ChangePasswordBox>
          <FormProvider
            methods={form}
            onSubmit={form.handleSubmit(resetPassword)}
          >
            <Stack>
              <Typography className="label">
                {translate('new_password')}
              </Typography>
              <RHFTextField
                fullWidth
                name="newPassword"
                placeholder={translate('enter_new_password')}
                isPasswordInput
              />
              <Box mt={2} display="flex" alignItems="center">
                <div
                  className={`box_item ${
                    !errors.includes(PASSWORD_RULES.LOWER_UPPER) && 'isMatch'
                  }`}
                >
                  {!errors.includes(PASSWORD_RULES.LOWER_UPPER) && (
                    <CheckRoundedIcon />
                  )}
                </div>
                <Typography>{translate('include_lower_uppercase')}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <div
                  className={`box_item ${
                    !errors.includes(PASSWORD_RULES.NUMBER) && 'isMatch'
                  }`}
                >
                  {!errors.includes(PASSWORD_RULES.NUMBER) && (
                    <CheckRoundedIcon />
                  )}
                </div>
                <Typography>{translate('at_least_1_number')}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <div
                  className={`box_item ${
                    !errors.includes(PASSWORD_RULES.LENGTH) && 'isMatch'
                  }`}
                >
                  {!errors.includes(PASSWORD_RULES.LENGTH) && (
                    <CheckRoundedIcon />
                  )}
                </div>
                <Typography>{translate('minimum_6_char')}</Typography>
              </Box>
            </Stack>
            <Stack mt={3}>
              <Typography className="label">
                {translate('confirm_password')}
              </Typography>
              <RHFTextField
                fullWidth
                name="confirmPassword"
                placeholder={translate('enter_repeat_password')}
                isPasswordInput
              />
              {!isConfirmPasswordValid && (
                <ValidationMessage
                  ml={0}
                  message={translate('password_not_match')}
                />
              )}
            </Stack>
            <Stack mt={6}>
              {resetPwData?.recoverPassword?.error && (
                <ValidationMessage
                  mb={2}
                  mt={0}
                  ml={0}
                  message={resetPwData?.recoverPassword?.error.message}
                />
              )}
              {resetPwError?.message && (
                <ValidationMessage
                  mt={0}
                  mb={1}
                  ml={0}
                  message={resetPwError.message}
                />
              )}
              <LoadingButton
                loading={loading}
                loadingPosition="end"
                className="change_password_btn"
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isDisableChangePassword}
              >
                <Typography variant="body1">
                  {translate('reset_password')}
                </Typography>
              </LoadingButton>
            </Stack>
          </FormProvider>
        </ChangePasswordBox>
      </SecurityWrapper>
    </Container>
  );
};

export default RecoverPassword;
