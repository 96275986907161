import { ReactElement, useState } from 'react';

import { Box, ClickAwayListener, Tooltip, TooltipProps } from '@mui/material';

interface Props extends TooltipProps {
  title: string | React.ReactNode;
  children: ReactElement<any, any>;
}

export default function ClickTooltip({ title, children, ...other }: Props) {
  const [isShow, setIsShow] = useState(false);

  const handleToggle = () => {
    setIsShow((prev) => !prev);
  };

  const handleClose = () => {
    setIsShow(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        onClose={handleClose}
        open={isShow}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
        placement="bottom"
        arrow
        {...other}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleToggle();
          }}
        >
          {children}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
}
