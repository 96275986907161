import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Button,
  Fade,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';

import { AssetPopoverWrapper, CloseBox } from './styles';

export interface IOverBalancePopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  openWalletDialog: () => void;
  openSwitchCrypto: () => void;
}

const OverBalancePopover = (props: IOverBalancePopoverProps) => {
  const { open, anchorEl, handleClose, openWalletDialog, openSwitchCrypto } =
    props;
  const isMobile = useResponsive('down', 'sm');
  const { translate } = useLocales();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        ...(isMobile && {
          sx: {
            width: '90%',
            maxWidth: 'unset',
            position: 'fixed',
            top: '50% !important',
            left: '50% !important',
            transform: 'translate(-50%, -50%)',
          },
        }),
      }}
      {...(isMobile && {
        slotProps: { backdrop: { invisible: false } },
      })}
      onClose={handleClose}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      closeAfterTransition
    >
      <div>
        <AssetPopoverWrapper>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            width="100%"
            marginBottom={3}
            mt={1}
          >
            <Typography
              fontSize={20}
              display="flex"
              width="100%"
              justifyContent="center"
              fontWeight="700"
              color="#FFFFFF"
            >
              {translate('insufficient_balance')}
            </Typography>

            <CloseBox
              key="close-button"
              onClick={() => {
                handleClose();
              }}
            >
              <IconButton className="close-btn">
                <CloseRoundedIcon />
              </IconButton>
            </CloseBox>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            width="100%"
            marginBottom={2}
            justifyContent="center"
          >
            <Typography
              fontSize={16}
              justifyContent="center"
              // display="flex"
              color="#858997"
              textAlign="center"
              whiteSpace="break-spaces"
            >
              {translate('please_switch_to_another_asset_to_continue_playing')}
            </Typography>
          </Box>
          <Stack
            display="flex"
            alignItems="center"
            flexDirection="row"
            width="100%"
            marginBottom={isMobile ? 0 : 3}
            spacing={2}
            direction="row"
            justifyContent="center"
          >
            <Button
              onClick={() => {
                openSwitchCrypto();
              }}
              variant="outlined"
              color="secondary"
              fullWidth
              sx={
                !isMobile && {
                  paddingTop: !isMobile && '16px',
                  paddingBottom: !isMobile && '16px',
                  maxHeight: !isMobile && '48px',
                  minHeight: isMobile && '36px',
                  width: !isMobile ? '43%' : '100%',
                }
              }
            >
              {translate('switch')}
            </Button>
            <Button
              onClick={() => {
                openWalletDialog();
              }}
              variant="contained"
              color="secondary"
              fullWidth
              sx={{
                paddingTop: !isMobile && '16px',
                paddingBottom: !isMobile && '16px',
                maxHeight: !isMobile && '48px',
                width: !isMobile ? '43%' : '100%',
              }}
            >
              {translate('deposit')}
            </Button>
          </Stack>
        </AssetPopoverWrapper>
      </div>
    </Popover>
  );
};
export default OverBalancePopover;
