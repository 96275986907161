import { useReactiveVar } from '@apollo/client';
import coinImages from 'assets/images/coins';
import crashPredictorCup from 'assets/images/events/crash_event/crash_predictor_cup.png';
import { useSettingsContext } from 'components/settings/SettingsContext';
import * as d3 from 'd3';
import { motion } from 'framer-motion';
import { CrashPredictorResult } from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import Lottie from 'lottie-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { storageCurrencyVar, viewInFiatVar } from 'store';
import { toN } from 'utils/number-help';

import { Box, Stack, styled, Typography } from '@mui/material';

import numeral from 'numeral';
import { formatAmount, formatUSD } from '../gameControl/betControl';
import explosion from './explosion.json';
import { OpenPredictorDialogButtonMini } from './styles';

//---------------------------------------------

const makeAlert = (payout: number) => {
  if (payout < 2) return 'not_bad';
  if (payout < 5) return 'nice';
  if (payout < 10) return 'awesome';
  if (payout < 50) return 'wicked';
  if (payout < 100) return 'godlike';
  return 'to_the_moon';
};

const makeAlertColorGradien = (payout: number) => {
  if (payout < 2) return 'linear-gradient(180deg, #FF5B00 50%, #FFDECB 100%)';
  if (payout < 5) return 'linear-gradient(180deg, #0073F7 50%, #FFDECB 100%)';
  if (payout < 10) return 'linear-gradient(180deg, #18BDF9 50%, #FFDECB 100%)';
  if (payout < 50) return 'linear-gradient(180deg, #53BA0C 50%, #FFDECB 100%)';
  if (payout < 100) return 'linear-gradient(180deg, #FFC107 50%, #FFDECB 100%)';
  return 'linear-gradient(180deg, #7B02FD 50%, #FFDECB 100%)';
};

const makeAlertColor = (payout: number) => {
  if (payout < 2) return '#FF5B00';
  if (payout < 5) return '#0073F7';
  if (payout < 10) return '#18BDF9';
  if (payout < 50) return '#53BA0C';
  if (payout < 100) return '#FFC107';
  return '#7B02FD';
};

const makePingColor = (ping: number) => {
  if (ping < 80) return '#53BA0C';
  if (ping < 150) return '#FFC107';
  return '#FF5C00';
};

interface Props {
  ping: number;
  process: boolean;
  winAmount: number;
  winRate: number;
  currencyId: number | string;
  payout: number;
  countTime: string;
  crashAt: number | 'gogo';
  chartData: any[];
  cashOutPoint: any[];
  status: 'WAITING_START' | 'STARTED' | 'STOPED';
  handleOpenPredictorDialog: VoidFunction;
  highestCrashResult: DeepPartial<CrashPredictorResult>;
}

//-----------------------------------------------

// const width = 480;
// const height = 274;

export const CrashLineWrapper = styled(Box)(() => ({
  // display: 'flex',
  position: 'absolute',
  // justifyContent: 'center',
  // alignProperty: 'center',
  width: '100%',
  minHeight: 460,
  // zIndex: 0,
}));

const CrashLine = ({
  ping,
  process,
  winAmount,
  winRate,
  payout,
  countTime,
  crashAt,
  chartData,
  cashOutPoint,
  status,
  highestCrashResult,
  handleOpenPredictorDialog,
}: // highestCrashResult,
// handleOpenPredictorDialog,
Props) => {
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const { themeMode } = useSettingsContext();
  const [isExplosion, setIsExplosion] = useState(false);
  const [isShowWin, setIsShowWin] = useState(false);
  const isDesktop = useResponsive('up', 'md');
  const { translate } = useLocales();
  const lottieRef: any = useRef();

  //---------------------------------------------------------------------------

  const margin = { top: 30, right: 40, bottom: 15, left: 5 };
  const width = 480 * (isDesktop ? 1.3 : 1.1) - margin.left - margin.right;
  const height =
    (isDesktop ? 274 : 290) * (isDesktop ? 1.3 : 1.1) -
    margin.top -
    margin.bottom;
  //---------------------------------------------------------------------------
  useEffect(() => {
    lottieRef?.current?.setSpeed(0.6);
  }, [lottieRef?.current]);

  useEffect(() => {
    if (status === 'STOPED') {
      if ('vibrate' in navigator) {
        // vibration API supported
        navigator.vibrate(1000);
      }
      setIsExplosion(true);
      setTimeout(() => {
        setIsExplosion(false);
      }, 500);
    }
  }, [status]);

  useEffect(() => {
    if (winRate > 0 && winAmount > 0 && status === 'STARTED') {
      setIsShowWin(true);
      setTimeout(() => {
        setIsShowWin(false);
      }, 3000);
    }
  }, [winAmount]);

  const xMinValue = d3.min(chartData, (d) => d.x);
  const xMaxValue = d3.max(chartData, (d) => d.x);
  const yMinValue = d3.min(chartData, (d) => d.y);
  const yMaxValue = d3.max(chartData, (d) => d.y);

  const getX = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([xMinValue, xMaxValue])
        .range([
          0,
          xMaxValue < 8 ? ((width - 35) * xMaxValue) / 8 : width - 35,
        ]),
    [chartData],
  );

  const getX2 = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([xMinValue, xMaxValue < 8 ? 8 : xMaxValue])
        .range([0, width - 30]),
    [chartData],
  );

  const getY = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([yMinValue, yMaxValue + 1])
        .range([height, 50]),
    [chartData],
  );

  const getY2 = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([yMinValue, yMaxValue + 1])
        .range([height, 50]),
    [chartData],
  );

  const getYAxis = (ref: any) => {
    const yAxis = d3.axisLeft(getY2);
    d3.select(ref).call(
      yAxis
        .tickPadding(6)
        .tickSize(0)
        .ticks(3)
        .tickFormat((d: any) => `${d.toFixed(1)}x`),
    );
  };

  const getXAxis = (ref: any) => {
    const xAxis = d3.axisBottom(getX2);
    d3.select(ref).call(
      xAxis
        .tickPadding(8)
        .tickSize(0)
        .ticks(3)
        .tickFormat((d: any) => `${d.toFixed(0)}s`),
    );
  };

  const linePath = useMemo(
    () =>
      d3
        .line()
        .x((d: any) => getX(d.x))
        .y((d: any) => getY(d.y))
        .curve(d3.curveMonotoneX)(chartData),
    [chartData],
  );

  return (
    <CrashLineWrapper>
      {payout >= 100 && (
        <Box
          sx={{
            width: '100%',
            height: 100,
            background:
              'linear-gradient(180deg, rgba(129, 63, 250, 0.3) 0%, rgba(17, 20, 28, 0) 100%)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
      {isShowWin && (
        <Stack
          sx={{
            width: 'calc(100% - 4px)',
            height: 'calc(100% - 4px)',
            zIndex: 999,
            position: 'absolute',
            top: 2,
            left: 2,
            // backdropFilter: 'blur(1px)',
            background: 'rgba(17, 20, 28, 0.86)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              pt: 4,
              fontWeight: 800,
              background: makeAlertColorGradien(winRate),
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {translate(makeAlert(winRate))?.toUpperCase()}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              src={coinImages[storageCurrency?.shortName]}
              alt="coin"
              width={24}
              height={24}
            />
            <Typography variant="h4" textAlign="center" color="white">
              {viewInFiat?.status === true &&
              storageCurrency?.equivalentUsdRate !== 0
                ? `${formatUSD(
                    toN(winAmount) * (storageCurrency?.equivalentUsdRate || 1),
                  )} $`
                : formatAmount(winAmount)}
            </Typography>
          </Stack>
          <Typography variant="h5" textAlign="center" color="white">
            {`@${winRate}x`}
          </Typography>
        </Stack>
      )}
      {isExplosion && (
        <Box
          sx={{
            width: 200,
            position: 'absolute',
            top: 'calc(50% - 100px)',
            left: 'calc(50% - 100px)',
          }}
        >
          <Lottie
            animationData={explosion}
            lottieRef={lottieRef}
            loop={false}
          />
        </Box>
      )}
      {highestCrashResult && (
        <OpenPredictorDialogButtonMini
          onClick={() => handleOpenPredictorDialog()}
          direction="row"
          justifyContent="center"
        >
          <img src={crashPredictorCup} alt="crown" />
          <Stack my={0.5}>
            <Typography
              color="text.contrast"
              sx={{
                fontSize: 12,
              }}
            >
              {highestCrashResult?.user?.nickname || translate('hidden')}
            </Typography>
            <Typography sx={{ fontSize: 20, lineHeight: 1 }}>
              {numeral(highestCrashResult.cashout).format('0,0.00')}x
            </Typography>
          </Stack>
        </OpenPredictorDialogButtonMini>
      )}
      <svg
        style={{
          backgroundColor: '#100E24',
          borderRadius: '15px 15px 0px 0px',
        }}
        viewBox={`-45 -20 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`}
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stopColor="#FFFFFF" />
            <stop offset="100%" stopColor="#53BA0C" />
          </linearGradient>
          <linearGradient id="gotomoon" x1="0" x2="0" y1="0" y2="100%">
            <stop stopColor="#7B02FD" offset="50%" />
            <stop stopColor="#EEDFFF" offset="100%" />
          </linearGradient>
        </defs>

        <g ref={getYAxis} color="#454F5B" fill="none" />

        <g
          ref={getXAxis}
          color="#454F5B"
          fill="none"
          transform={`translate(0, ${height})`}
        />
        <path
          stroke="url(#gradient)"
          fill="none"
          strokeWidth="4"
          d={linePath}
        />
        {cashOutPoint.map((e) => (
          <motion.g
            viewBox="0 0 10 10"
            key={e}
            variants={{
              hidden: {
                opacity: 0,
                x: getX(e.x - 0.7),
                y: height,
                transition: {
                  opacity: { duration: 8 },
                  x: { duration: 6 },
                  y: { duration: 6 },
                },
              },
              show: {
                opacity: 1,
                x: getX(e.x),
                y: getY(e.win_payout),
              },
            }}
            initial="show"
            animate="hidden"
          >
            <image
              href={e.avata_url}
              height="14"
              width="14"
              clipPath="inset(0% round 12px)"
            />
            <text
              fill={e.win_payout < 2 ? '#fff' : makeAlertColor(e.win_payout)}
              fontSize={10}
              fontStretch="100%"
              x={23}
              y={15}
            >
              {`${e.display_name ? e.display_name : 'Hidden'} ⇢ ${
                e.win_payout
              }`}
            </text>
          </motion.g>
        ))}

        {status === 'WAITING_START' && !process && (
          <g>
            <text
              fill="#FFC107"
              x={width / 2 + margin.left / 2 + margin.right / 2 - 40}
              y={130}
              textAnchor="middle"
              fontSize={30}
            >
              {translate('start_in')}
            </text>
            <text
              fill={themeMode === 'dark' ? 'white' : '#637381'}
              x={width / 2 + margin.left / 2 + margin.right / 2 - 40}
              y={180}
              textAnchor="middle"
              fontSize={40}
              fontWeight="bold"
            >
              {`${countTime}s`}
            </text>
          </g>
        )}

        {status === 'STARTED' && !process && (
          <>
            <text
              fill="url(#gotomoon)"
              x={width / 2 + margin.left / 2 + margin.right / 2 - 50}
              y={130}
              textAnchor="middle"
              fontWeight="bold"
              fontSize={30}
            >
              {payout >= 100 ? translate('to_the_moon') : ''}
            </text>
            <text
              fill={themeMode === 'dark' ? 'white' : '#637381'}
              x={width / 2 + margin.left / 2 + margin.right / 2 - 50}
              y={180}
              textAnchor="middle"
              fontSize={45}
              fontWeight="bold"
            >
              {`${payout?.toFixed(2)}x`}
            </text>
          </>
        )}

        {status === 'STOPED' && !process && (
          <g>
            {crashAt !== 'gogo' && (
              <text
                fill="#FF5C00"
                x={width / 2 + margin.left / 2 + margin.right / 2 - 50}
                y={180}
                textAnchor="middle"
                fontWeight="bold"
                fontSize={45}
              >
                {`@${Number(crashAt).toFixed(2)}x`}
              </text>
            )}
          </g>
        )}

        {process && (
          <g>
            <text
              fill="#FFC107"
              x={width / 2 + margin.left / 2 + margin.right / 2 - 40}
              y={180}
              textAnchor="middle"
              fontSize={42}
            >
              {translate('process')}
            </text>
          </g>
        )}

        {chartData.map((item, index) => (
          <g key={item}>
            {index === chartData.length - 1 && (
              <circle
                cx={getX(item.x)}
                cy={getY(item.y)}
                r={5}
                fill="#53BA0C"
                className="path blur"
              />
            )}
          </g>
        ))}

        <circle
          cx={width - 13}
          cy={height + 30}
          r={5}
          fill={makePingColor(ping)}
        />
        <text
          fill="#fff"
          x={width - 5}
          y={height + 34}
          textAnchor="left"
          fontSize={10}
        >
          {ping}ms
        </text>
        <text
          fill="#919EAB"
          x={width - 5}
          y={height + 42}
          textAnchor="left"
          fontSize={7}
        >
          Ping
        </text>
      </svg>
    </CrashLineWrapper>
  );
};

export default CrashLine;
