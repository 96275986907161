import { Box, Typography } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const BoxDescription = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  fontSize: '14px',
  color: 'white',
  transition: theme.transitions.create(['margin', 'height'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    display: '-webkit-box !important',
    transition: theme.transitions.create(['margin', 'height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginTop: 1,
  }),
}));

export const ChatWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 1.5),

  '.chat-content': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.secondary,
    borderRadius: theme.shape.borderRadius,
  },

  '.chat-user-info': {
    display: 'block',
    padding: theme.spacing(0.75, 1.2),
  },

  '.chat-username': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
    cursor: 'pointer',
  },

  '.chat-support': {
    fontSize: 14,
    fontWeight: 500,
    color: '#1B193E',
    lineHeight: 'normal',
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#FFE500',
    cursor: 'pointer',
    padding: theme.spacing(0, 0.5),
    marginLeft: theme.spacing(0.5),
  },

  '.chat-text': {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    padding: theme.spacing(0.5, 1.2),
  },

  '.chat-bigwin': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    backgroundSize: '100% 100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: theme.spacing(0, 1.2, 1.5),
    marginTop: theme.spacing(0.5),

    '.chat-bigwin-img': {
      width: 40,
      height: 40,
      borderRadius: theme.spacing(1.5),
    },

    '.chat-bigwin-data': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.main,
      borderRadius: theme.spacing(1),
      cursor: 'pointer',
      padding: theme.spacing(0.6, 1),
      svg: {
        width: 28,
        height: 28,
        transform: 'rotate(-90deg)',
      },
    },

    '.chat-bigwin-number': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),

      img: {
        width: 15,
        height: 15,
        alignSelf: 'center',
      },

      span: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.text.contrast,
        lineHeight: 'normal',
      },

      '.chat-bigwin-payout': {
        fontSize: 18,
        fontWeight: 700,
        color: '#FFE500',
      },
    },
  },
}));

export const ChatField = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  background: theme.palette.background.secondary,
  padding: theme.spacing(1.5),
  '.MuiAutocomplete-input': {
    padding: '0 !important',
  },
  '#chat-send-btn': {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    padding: theme.spacing(1.25, 2.5),
    background:
      'linear-gradient(180deg, #FFEDA0 0%, #EFC516 22.4%, #EFC516 22.41%, #FFCE00 100%)',
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ChatHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  position: 'inherit',
  height: 'auto',
  backgroundColor: theme.palette.background.secondary,
  zIndex: 1,
  width: 360,

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export const ChatBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  overflowY: 'auto',
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.background.main,
  padding: '68px 0',
}));

export const LanguageSelectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 1.5),
  background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
  borderRadius: theme.spacing(1.5),
  cursor: 'pointer',
}));

export const BoxBigWinWrapper = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(180deg, #783737 0%, #24234A 28%)',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(1.5),
  borderTopLeftRadius: theme.spacing(1.5),
  borderTopRightRadius: theme.spacing(1.5),
  transition: 'visibility 0s, opacity 0.5s linear',
}));

export const BigWinnerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const UserBigwin = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'rgba(22, 20, 50, 0.80)',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  borderRadius: theme.spacing(1.5),
  width: '100%',
  justifyContent: 'space-between',
}));

export const ItemBigwin = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const ListUserBigWin = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

export const HeaderBigWinner = styled(Typography)(() => ({
  textAlign: 'center',
  width: '100%',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 600,
}));

export const NameUserWinner = styled(Typography)(() => ({
  color: '#FFF',
  fontWeight: 700,
}));

export const ValueBigWinner = styled(Typography)(() => ({
  color: '#FFE500',
}));
