import isPropValid from '@emotion/is-prop-valid';

import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const VerifyWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.secondary,
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
  '.label': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    overflow: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  '.action_stack': {
    '.MuiButton-root': {
      height: 44,
      width: 140,
      padding: theme.spacing(1.25, 1),
      maxHeight: 'unset',
      [theme.breakpoints.down('sm')]: {
        height: 48,
        width: '100%',
      },
    },
  },
  '.step1': {
    justifyContent: 'flex-start',
  },
  '.step2': {
    justifyContent: 'center',
    '.MuiStepConnector-root': {
      left: 'calc(-100% + 138px)',
      right: 'calc(50% + 69px)',
      [theme.breakpoints.down('sm')]: {
        left: 'calc(-100% + 68px)',
        right: 'calc(50% + 34px)',
      },
    },
  },
  '.step3': {
    justifyContent: 'flex-end',
    '.MuiStepConnector-root': {
      left: 'calc(-50% + 69px)',
      right: 138,
      [theme.breakpoints.down('sm')]: {
        left: 'calc(-50% + 34px)',
        right: 68,
      },
    },
  },
  '.MuiStepConnector-root': {
    position: 'absolute',
    top: 20,
    justifyContent: 'center',
  },
}));

interface CustomStepProps extends BoxProps {
  active: boolean;
  completed: boolean;
}

export const CustomStepLabel = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<CustomStepProps>(({ theme, active, completed }) => ({
  padding: theme.spacing(1.25, 5),
  height: 44,
  background: theme.palette.background.secondary,
  borderRadius: theme.shape.borderRadius,
  width: 138,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
  },
  ...(active && {
    border: `1px solid ${theme.palette.secondary.main}`,
    p: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.text.contrast,
      textAlign: 'center',
    },
  }),
  ...(completed && {
    backgroundColor: 'rgba(129, 63, 250, 0.4)',
    p: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.text.contrast,
      textAlign: 'center',
    },
  }),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.75, 3),
    width: 68,
    height: 36,
  },
}));
