import { DialogAnimate } from 'components/animate';
import MiniDiceEventDetail from 'components/_Games/_GamesMiniDice/mini-dice-event/components/event-detail-content';
import MiniDiceEventDialogHeader from 'components/_Games/_GamesMiniDice/mini-dice-event/components/event-dialog-header';
import MiniDiceEventHistory from 'components/_Games/_GamesMiniDice/mini-dice-event/components/event-history';
import MiniDiceEventRule from 'components/_Games/_GamesMiniDice/mini-dice-event/components/event-rule';
import MiniDiceLeaderBoard from 'components/_Games/_GamesMiniDice/mini-dice-event/components/leader-board';
import MiniDiceEventTabs from 'components/_Games/_GamesMiniDice/mini-dice-event/components/mini-dice-event-tabs';
import { MiniDiceEventDialogWrapper } from 'components/_Games/_GamesMiniDice/mini-dice-event/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { ThreeDiceEventHistoriesQuery } from 'graph';
import { useLocales } from 'locales';
import { memo, useState } from 'react';

import { Box, Stack, useTheme } from '@mui/material';

interface IMiniDiceEventDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

type TabValue = 'leader-board' | 'event-history' | 'event-rule';

const MiniDiceEventDialog = ({ open, onClose }: IMiniDiceEventDialogProps) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const [selectedTab, setSelectedTab] = useState<TabValue>('leader-board');
  const [isViewDetailEvent, setIsViewDetailEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] =
    useState<
      ArrayElement<
        ThreeDiceEventHistoriesQuery['threeDiceCurrentGame']['jackpotWins']
      >
    >(null);

  const tabs: TabsType<TabValue> = [
    {
      label: translate('leader_board'),
      value: 'leader-board',
    },
    {
      label: translate('history'),
      value: 'event-history',
    },
    {
      label: translate('how_to_join'),
      value: 'event-rule',
    },
  ];

  const onViewDetailEvent = (event: any) => {
    setIsViewDetailEvent(true);
    setSelectedEvent(event);
  };

  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 100 : -100,
      opacity: 0,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      zIndex: 0,
      x: direction < 0 ? 100 : -100,
      opacity: 0,
    }),
  };

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        width: 480,
        maxHeight: 630,
        height: '100%',
        overflow: 'hidden',
        maxWidth: 'unset',
        borderRadius: '12px !important',
        padding: (theme) => theme.spacing(2, 1.5),
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'unset',
          minHeight: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: (theme) => theme.shape.borderRadius,
        },
      }}
    >
      <AnimatePresence
        mode="wait"
        initial={false}
        custom={isViewDetailEvent ? 1 : -1}
      >
        <Box
          key={selectedEvent?.id}
          component={motion.div}
          custom={isViewDetailEvent ? 1 : -1}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30, velocity: 2 },
            opacity: { duration: 0.1 },
            duration: 0.3,
          }}
          height="100%"
        >
          <Stack height="100%">
            {!isViewDetailEvent ? (
              <>
                <MiniDiceEventDialogHeader onClose={onClose} />
                <MiniDiceEventTabs<TabValue>
                  tabs={tabs}
                  selectedTab={selectedTab}
                  onChangeTab={(tab) => setSelectedTab(tab)}
                />
                <MiniDiceEventDialogWrapper p={{ xs: 0 }} mt={1}>
                  {selectedTab === 'leader-board' && <MiniDiceLeaderBoard />}

                  {selectedTab === 'event-history' && (
                    <MiniDiceEventHistory
                      onViewDetailEvent={onViewDetailEvent}
                    />
                  )}

                  {selectedTab === 'event-rule' && <MiniDiceEventRule />}
                </MiniDiceEventDialogWrapper>
              </>
            ) : (
              <MiniDiceEventDetail
                onClose={() => {
                  onClose();
                  setSelectedEvent(null);
                }}
                selectedEvent={selectedEvent}
                onClickBack={() => {
                  setIsViewDetailEvent(false);
                  setSelectedEvent(null);
                }}
              />
            )}
          </Stack>
        </Box>
      </AnimatePresence>
    </DialogAnimate>
  );
};

export default memo(MiniDiceEventDialog);
