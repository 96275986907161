// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const pt = {
  demo: {
    title: `Portugal`,
  },
  blog: 'blog',
  chat: 'chat',
  only_admin_can_see_this_item: 'Somente o administrador pode ver este item',
  fairness: 'Justiça',
  blog_academy: 'Blog / Academia',
  live_support: 'Suporte ao vivo',
  search_your_game: 'Pesquise seu jogo',
  random: 'Aleatório',
  sign_in: 'Assinar em',
  sign_up: 'Inscrever-se',
  wallet: 'Carteira',
  deposit: 'Depósito',
  withdraw: 'Saque',
  swap: 'Troque',
  crypto: 'Crypto',
  fiat: 'Fiat',
  nft: 'NFT',
  search_by_name: 'Procura por nome',
  transactions: 'Transações',
  // Deposit
  overview: 'Visão geral',
  deposit_currency: 'Moeda de depósito',
  choose_network: 'Escolha a rede',
  deposit_address: 'Endereço de depósito',
  notice: 'Aviso:',
  notice_content:
    'Send only /currency/ to this deposit address. Smart contract addresses are not supported (Contact us)',
  balance: 'Equilíbrio',
  copy: 'Cópia',
  withdraw_amount: 'retirar montante',
  withdraw_address: 'Retirar endereço',
  deposit_address_placeholder:
    'Preencha com cuidado de acordo com a moeda específica',
  fee: 'Taxa',
  receive_amount: 'Receber quantia',
  confirm: 'confirme',
  balance_in_wallet: 'Saldo na carteira',
  withdraw_fee: 'Taxa de retirada',
  from: 'A partir de',
  to: 'Para',
  bill: 'Projeto de lei',
  view_history_in_etherscan:
    'Veja o histórico de transações no Pi.game blockexplorer',
  time: 'Tempo',
  amount: 'Quantia',
  status: 'Status',
  detail: 'Detalhe',
  view_in_fiat: 'Ver em dólares americanos',
  common_assets: 'Ativos Comuns',
  order_id: 'ID do pedido',
  currency: 'Moeda',
  quantity: 'Quantidade',
  type: 'Tipo',
  empty_data: 'Dados vazios',
  currency_not_available: 'Moeda não disponível',
  all_feature: 'Todos os recursos',
  success: 'Sucesso',
  audit: 'Auditoria',
  insufficient: 'Insuficiente',
  processing: 'Processamento',
  new: 'Novo',
  profile_settings: 'Configurações de perfil',
  general: 'geral',
  security: 'segurança',
  verify: 'verificar',
  verified: 'verificado',
  id: 'id',
  nickname: 'nickname',
  email: 'Email',
  phone_number: 'número de telefone',
  change: 'Altere',
  not_use_symbol:
    'Não use símbolos especiais, caso contrário sua conta poderá não ser suportada.',
  hide_profile: 'Ocultar perfil',
  registered_mail_cannot_changed:
    'De acordo com a política de segurança da Ohplay.club, o email cadastrado não pode ser alterado.',
  phone_second_login:
    'Adicione o número de telefone como seu segundo método de login.',
  hide_profile_title: 'Oculte seus dados de jogo no perfil',
  hide_profile_content:
    'Mesmo oculto, seu avatar e nome de usuário estarão sempre visíveis nas salas de chat.',
  copied: 'Copiado',
  enter_nickname: 'Digite o apelido aqui',
  enter_email: 'Digite o e-mail aqui',
  enter_phone_number_: 'Insira o número de telefone aqui',
  change_password: 'Alterar a senha',
  current_password: 'Senha atual',
  new_password: 'Nova Senha',
  confirm_password: 'Confirme a nova senha',
  existing_password_not_match: 'A senha existente não corresponde',
  password_not_match: 'Senha não confere',
  new_password_saved: 'Nova senha salva',
  relogin_after_change_pw:
    'Será necessário fazer login novamente após alterar a senha.',
  setup_google_auth: 'Configurar o Google Autenticador',
  download_google_auth:
    'Baixe o aplicativo Google Authenticator para iPhone ou Android',
  save_account_token:
    'Salve o seguinte token de conta (chave) em um local seguro',
  account_token: 'Token da conta (chave)',
  need_account_token:
    'Você precisará do seu token de conta (chave) acima para acessar sua conta caso perca seu telefone',
  scan_qr_title:
    'Digitalize o código QR abaixo com o aplicativo Google Authenticator',
  enter_token_below: 'Insira o token abaixo',
  two_FA_code: 'Código 2FA *',
  appear_on_google_auth: 'Aparecer no seu aplicativo Google Authenticator',
  verify_code_incorrect: 'Código de verificação incorreto',
  enable_2fa: 'Habilitar 2FA',
  enter_current_pw: 'Digite a senha atual',
  enter_new_password: 'Insira a nova senha',
  enter_repeat_password: 'Repita a nova senha',
  enter_2fa_code_to_disable:
    'Digite seu código 2FA para desativar a autenticação de dois fatores.',
  update_nickname_failed: 'Falha na atualização do apelido. Tente novamente',
  disable_2fa: 'Desativar 2FA',
  verify_email: 'Verificar e-mail',
  send_information_to_email:
    'Enviaremos informações para o seu endereço de e-mail cadastrado.',
  cancel: 'Cancelar',
  verify_phone_number: 'Verifique o número de telefone',
  resend_code: 'Reenviar código',
  did_not_receive_code: 'Não recebeu o código?',
  validate_nickname_length: 'O apelido deve ter entre 4 e 13 caracteres.',
  nickname_existing: 'Apelido já existe.',
  new_nick_name_saved: 'Novo apelido salvo',
  new_email_saved: 'Novo e-mail salvo',
  new_phone_number_save: 'Novo número de telefone salvo',
  invalid_phone_number: 'Número de telefone inválido',
  validate_invalid_email_address:
    'Por favor insira um endereço de e-mail válido',
  view: 'Visualizar',
  choose_asset: 'Escolha ativos',
  min: 'Mínimo',
  max: 'MÁX.',
  max2: 'Máx.',
  all_currency: 'Todas as moedas',
  to_secure_assets: 'Para proteger seus ativos, por favor',
  bet_id: 'ID de reprodução',
  bet_amount: 'Quantidade de jogo',
  payout: 'Pagamento',
  profit_amount: 'Lucro',
  hash: 'Cerquilha',
  show_more: 'Mostre mais',
  show_less: 'Mostre menos',
  welcome_aboard: 'Bem vindo a bordo',
  first_deposit_bonus: 'Bônus de primeiro depósito',
  rewards: 'Recompensas',
  deposit_now: 'Deposite agora',
  just_for_you: 'Apenas para você',
  top_games: 'Principais jogos',
  recently_played: 'Tocado recentemente',
  recommended: 'Recomendado',
  favourites: 'Favoritos',
  or_log_in_with: 'Ou faça login com',
  dont_have_account: 'Você não tem uma conta?',
  forgot_your_password: 'Esqueceu sua senha?',
  password: 'Senha',
  terms_of_service: 'termos de serviço',
  confirm_accessing_site:
    'Ao acessar o site, confirmo que tenho 18 anos e li o',
  include_lower_uppercase: 'Incluir letras minúsculas e maiúsculas',
  at_least_1_number: 'Pelo menos 1 número',
  minimum_6_char: 'Mínimo 6 caracteres',
  agree_terms_service: 'Eu concordo e entendo o',
  terms_conditions: 'termos e Condições',
  already_have_account: 'já tem uma conta?',
  enter_password_here: 'Senha de login...',
  reset_password: 'Redefinir senha',
  assets_portfolio: 'Carteira de Ativos',
  hide_small: 'Esconder pequeno',
  menu: 'Cardápio',
  game_hub: 'Central de jogos',
  notification: 'Notificação',
  chat_room: 'Sala de bate-papo',
  login_fail: 'Falha no login, tente novamente',
  error_message: {
    server_error:
      'Erro de servidor. Por favor, tente novamente em alguns minutos',
  },
  dark: 'Escuro',
  light: 'Luz',
  casino_home: 'Página inicial do cassino',
  promotions: 'Promoções',
  refer_friend: 'Indique um Amigo',
  support_legal: 'Suporte / Jurídico',
  help_center: 'Centro de ajuda',
  faq: 'FAQ',
  privacy_policy: 'política de Privacidade',
  aml: 'AML',
  res_gambling: 'Jogo Responsável',
  provably_fair: 'Provavelmente Justo',
  registration_login: 'Cadastro e Login',
  about_us: 'Sobre nós',
  news: 'Notícias',
  business_contact: 'Contato de negócios',
  work_with_us: 'Trabalhe Conosco',
  coin_accuracy_limit: 'Limite de precisão da moeda',
  support: 'Apoiar',
  verify_representative: 'Verifique o representante',
  crypto_quest: 'Missão criptográfica',
  video_poker: 'Vídeo pôquer',
  classic_dice: 'Dados Clássicos',
  join_our_community: 'Junte-se a nossa comunidade',
  go_to_top: 'Ir para o topo',
  game: 'Jogo',
  game_info: 'Informação do Jogo',
  description: 'Descrição',
  reviews: 'Avaliações',
  latest_bet_race: 'Últimas apostas e corridas',
  all_bets: 'Todo o comprometimento',
  my_bets: 'meus compromissos',
  high_bets: 'Altos compromissos',
  player: 'Jogador',
  hidden: 'Escondido',
  about_this_game: 'Sobre este jogo',
  share_this_game: 'Compartilhe este jogo',
  share_on_fb: 'Compartilhar no Facebook',
  share_on_whatsapp: 'compartilhe no WhatsApp',
  share_on_twitter: 'Compartilhar no X (Twitter)',
  share_on_discord: 'Compartilhar no Discord',
  share_on_linkedin: 'Compartilhe no LinkedIn',
  share_on_skype: 'Compartilhar no Skype',
  by: 'Por: ',
  release: 'Liberar',
  return_to_player: 'RTP (retorno ao jogador)',
  provider: 'Fornecedor',
  max_win: 'Vitória máxima',
  mobile: 'Móvel',
  stakes_range: 'Faixa de apostas',
  active_devices: 'Dispositivos ativos',
  device: 'Dispositivo',
  location: 'Localização',
  ip_address: 'Endereço de IP',
  last_used: 'Usado por último',
  action: 'Ação',
  play_now: 'Jogue agora',
  on: 'Sobre',
  activities: 'Atividades',
  high_rollers: 'Grandes apostadores',
  jackpot_winners: 'Vencedores do jackpot',
  follow_us_on: 'Siga-nos no',
  INTERNAL_TRANSFER: 'Gorjeta',
  send_recover_link_to_email:
    'Um e-mail com link de recuperação de senha será enviado para seu e-mail.',
  request_reset_email:
    'A solicitação foi enviada. Por favor verifique seu email',
  something_wrong: 'Algo deu errado. Por favor, tente novamente',
  vip_club: 'Clube VIP',
  coco_vip_club: 'Ohplay Clube VIP',
  to_coco_vip_club: "Ohplay VIP Club'a",
  welcome_vip_club_text:
    'En uzun süre oynayan ve en yüksek bahis yapan oyuncular, VIP kulübüne özel davetler alacak. Üyelerimize premium bir deneyim ve bonuslar sağlıyoruz.',
  jackpot: 'Jackpot',
  responsible_gambling: 'Jogo responsável',
  next: 'Próximo',
  step: 'Etapa',
  verification: 'Verificação',
  verify_setup: 'Verifique a configuração',
  verify_setup_description1:
    'Para melhor atendê-lo solicitamos que você forneça documentos de identificação originais. Isso protegerá sua conta em casos de recuperação de conta. Também ajuda a garantir que os presentes ou recompensas reais que você recebe sejam enviados para o local correto.',
  verify_setup_description2:
    'Uma prova de identificação aceitável inclui uma foto do seu governo, carteira de identidade aprovada, carteira de motorista e passaporte. Entraremos em contato com você por e-mail assim que esse processo for concluído.',
  first_name: 'Primeiro nome',
  last_name: 'Sobrenome',
  gender: 'Gênero',
  date_of_birth: 'Data de nascimento',
  address: 'Endereço',
  city: 'Cidade',
  postal_code: 'Código postal',
  country: 'País',
  enter_your_first_name: 'Digite seu primeiro nome...',
  enter_your_last_name: 'Digite seu sobrenome...',
  verify_government_id_description:
    'Faça upload de um documento de identificação válido emitido pelo governo ou carteira de motorista. Deve conter seu nome completo, data de nascimento, sua foto e o prazo de validade do documento.',
  id_card: 'Carteira de identidade',
  passport: 'Passaporte',
  front_side: 'Parte frontal',
  back_side: 'Verso',
  take_a_photo: 'Tire uma foto',
  upload_picture: 'Carregar foto',
  information_page: 'Página de informações',
  verify_portrait_description:
    'Por favor, carregue uma foto sua. Na foto você precisa portar seu passaporte ou RG, e um pedaço de papel com “Ohplay.club”, seu nome de usuário e a data. Certifique-se de que seu rosto e as informações que você possui estejam claras.',
  your_photo: 'Sua foto',
  references: 'Referências',
  all_information_submitted: 'Todas as informações são enviadas',
  thank_you_for_cooperation:
    'Obrigado pela sua cooperação. Iremos informá-lo quando a revisão for concluída.',
  done: 'Feito',
  this_field_is_required: 'Este campo é obrigatório',
  male: 'Macho',
  female: 'Fêmea',
  unspecified: 'Não especificado',
  select_gender: 'Selecione o sexo',
  back: 'Voltar',
  step1_update_identification_success:
    'Etapa 1: atualize a identificação com sucesso',
  step23_update_identification_photo_success:
    'Etapa 2, 3: atualize as fotos de identificação com sucesso',
  invalid_date_format: 'Formato de data inválido',
  update_avatar_success: 'Atualizar avatar com sucesso',
  update_avatar_failed: 'Falha ao atualizar avatar',
  game_bank_overview: 'Visão geral',
  game_bank_history: 'História',
  game_bank_list: 'Lista',
  game_bank_withdraw: 'Retirar',
  confirm_password_must_match: 'A confirmação da senha deve corresponder',
  recover_password: 'Recuperar senha',
  logout_failed: 'Falha ao sair, tente novamente!',
  at_least_18_years_old: 'Você precisa ter pelo menos 18 anos',
  no_support: 'Sem suporte',
  gems: 'Gemas',
  sound: 'Som',
  invalid_wallet_address: 'Endereço de carteira inválido',
  target_multiplier: 'Target Multiplier',
  multiplier: 'Multiplicador',
  unexpected_error:
    'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde',
  validate_nickname_message:
    'O apelido deve ter entre 4 e 13 caracteres, sem sinal e não conter espaço, símbolo.',
  swap_fee: 'Taxa de troca',
  verify_account: 'Verificar conta',
  history: 'História',
  big_winner: 'Grande vencedor',
  jackpot_winner: 'Vencedor do jackpot',
  my_bet: 'Minha peça',
  all_bet: 'Todo o comprometimento',
  winner: 'Ganhador',
  insufficient_ballance: 'Balanço insuficiente',
  gamebank: 'Gamebank',
  maximumFileSize: 'O tamanho máximo do arquivo é 4MB',
  verify_email_was_send:
    'Verifique se o e-mail foi enviado, verifique seu e-mail',
  error_code: {
    EMAIL_IN_USED: 'Este e-mail foi usado por outro usuário.',
  },
  account_verified: 'Sua conta foi verificada',
  check_information_carefully:
    'Por favor, verifique suas informações de investimento cuidadosamente antes de confirmar.',
  invest_now: 'Invista agora',
  more_information: 'Mais Informações',
  host: 'Hospedar',
  loading: 'Carregando...',
  you_must_specify_a_number: 'Você deve especificar um número',
  must_be_a_positive_value: 'Deve ser um valor positivo',
  withdraw_success: 'Retirada com sucesso',
  withdraw_failed: 'Falha na retirada',
  your_total_invest: 'Seu investimento total',
  your_current_shares: 'Suas ações atuais',
  your_balance: 'Seu balanço',
  your_profit: 'Seu lucro',
  leverage: 'Aproveitar',
  game_bank: 'Banco de jogos',
  game_bank_max: 'GameBank Máx.',
  your_invest: 'Seu investimento',
  available_invest_amount: 'Valor de investimento disponível',
  your_share: 'Sua parte',
  host_share: 'Compartilhamento de host',
  invest_success: 'Invista com sucesso',
  invest_failed: 'Investir falhou',
  investor: 'Investidor',
  share: 'Compartilhar',
  error_message_server_error: 'error_message.server_error',
  successful_balance_change: 'Mudança de equilíbrio bem-sucedida:',
  permission_denied: 'Permissão negada',
  you_not_permission_page: 'Você não tem permissão para acessar esta página',
  not_bad: 'NADA MAL',
  nice: 'LEGAL',
  awesome: 'INCRÍVEL',
  wicked: 'MALVADO',
  godlike: 'DEUS',
  to_the_moon: 'PARA A LUA',
  you_win: 'VOCÊ GANHOU!',
  bum: 'BUMMM!',
  some_thing_went_wrong: 'Alguma coisa deu errado',
  maximum_number: 'O número máximo de células selecionadas é',
  auto_select: 'AUTO SELEÇÃO',
  number_of_spin: 'Número de giro',
  // Mini Coco
  advanced_auto: 'Automático avançado',
  stop_if_anyone_win_jackpot: 'Pare se alguém ganhar o Jackpot',
  stop_decrease_by: 'Pare se o saldo diminuir em',
  stop_increase_by: 'Pare se o saldo aumentar em',
  stop_exceeds_by: 'Pare se a vitória única exceder em',
  start_auto_spin: 'Iniciar rotação automática',
  not_enough_balance: 'Equilíbrio insuficiente',
  set_auto_bet: 'Definir aposta automática',
  cancel_auto_bet: 'Cancelar aposta automática',
  cashout_at: 'Sacar em',
  win_amount: 'Valor do ganho',
  increase: 'aumentar',
  decrease: 'diminuir',
  bet: 'Jogada',
  waiting: 'Esperando...',
  bet_next_round: 'APOSTA (próxima rodada)',
  cashout: 'Sacar',
  win: 'GANHAR',
  lose: 'PERDER',
  increase_by: 'Aumentar em',
  reset: 'Reiniciar',
  manual: 'Manual',
  auto: 'Auto',
  max_profit: 'Lucro máximo',
  max_bet: 'aposta máxima',
  stop_on_profit: 'Pare no lucro',
  number_of_bet: 'Número de jogo',
  on_loss: 'Em caso de perda',
  on_lose: 'Em Perder',
  on_win: 'Na vitória',
  stop_on_lose: 'Pare em perder',
  stop_on_win: 'Pare na vitória',
  live_stats: 'Estatísticas ao vivo',
  total_bet_amount: 'Valor total da aposta',
  total_profit: 'Recompensa total',
  game_bank2: 'BANCO DE JOGOS',
  can_not_get_game_bank: 'Não consigo obter o banco do jogo',
  trends: 'Tendências',
  play_real: 'Jogue de verdade',
  play_with_fun: 'Jogue com diversão',
  online: 'On-line',
  welcome_bonus: 'BÔNUS DE BEM-VINDO',
  join_now: 'Entrar',
  total: 'Total',
  invest: 'Investir',
  insufficient_balance: 'Saldo insuficiente',
  please_switch_to_another_asset_to_continue_playing:
    'Mude para outro ativo para continuar jogando.',
  insufficient_gamebank: 'Saldo insuficiente',
  switch: 'Trocar',
  not_found: 'Não encontrado',
  no_results_found_for: 'Nenhum resultado encontrado para',
  checking_for_typos:
    'Tente verificar erros de digitação ou usar palavras completas.',
  please_enter_keywords: 'Insira palavras-chave',
  exit_fullscreen: 'Sair da tela cheia',
  fullscreen: 'Tela cheia',
  settings: 'Configurações',
  currency_do_not_supported: 'Esta moeda não é suportada.',
  update_photo: 'Atualizar foto',
  upload_photo: 'Carregar foto',
  remove_all: 'Deletar tudo',
  upload_files: 'Fazer upload de arquivos',
  drop_or_select_file: 'Soltar ou selecionar arquivo',
  drop_files_here_or_click: 'Solte os arquivos aqui ou clique',
  thorough_your_machine: 'Através da sua máquina',
  no_found_any_currency_can_swap: 'Nenhuma moeda encontrada pode ser trocada',
  account_not_verified: 'A conta não foi verificada',
  account_not_conditions: 'Apostar mais para sacar',
  withdraw_request_was_sent:
    'Solicitação de saque foi enviada, aguardando confirmação',
  available_amount_insufficient: 'Quantidade disponível insuficiente',
  need_help: 'Preciso de ajuda?',
  coming_soon: 'coming-soon',
  introduction_to_coco:
    'Um cripto-cassino multipremiado. Com uma abordagem centrada no jogador, Ohplay.club é capaz de satisfazer milhões de jogadores em todo o mundo. Ohplay.club tem como prioridade a sua comunidade, garantindo uma experiência de jogo duradoura e infinitamente divertida.',
  coming_soon2: 'Em breve...',
  under_development: 'A função está em desenvolvimento',
  page_404: '404 Página não encontrada | Ohplay.Jogo',
  page_not_found: 'Desculpe, página não encontrada!',
  check_your_spelling:
    'Lamentamos, mas não conseguimos encontrar a página que procura. Talvez você tenha digitado incorretamente o URL? Certifique-se de verificar sua ortografia.',
  go_to_home: 'Vá para casa',
  remove_session_success: 'Remover sucesso da sessão',
  remove_session_failed: 'Falha ao remover sessão',
  in_use: 'Em uso',
  remove_device: 'Remover dispositivo',
  your_profile: 'Seu perfil',
  is_hidden: 'está escondido',
  is_public_now: 'é público agora',
  hide_profile_failed: 'Falha ao ocultar perfil. Tente novamente',
  change_password_success:
    'Alteração de senha com sucesso. Por favor faça login novamente',
  upload_image_failed: 'Falha no upload da imagem',
  file_size_limit_10MB: 'jpg, png; Limite de tamanho de arquivo 10 MB',
  roll_over: 'Rolar',
  win_chance: 'Chance de ganhar',
  profit_on_next_tile: 'Lucro no próximo bloco',
  pick_a_tile_randomly: 'Escolha um bloco aleatoriamente',
  pay_table: 'Mesa de pagamentos',
  game_rule: 'Regra do jogo',
  number_of_bets: 'Número de jogo',
  bet_failed: 'Falha na reprodução',
  stop_auto_bet: 'Parar a reprodução automática',
  currency_not_support: 'Moeda não suportada',
  login_to_play: 'Faça login para JOGAR',
  yes: 'Sim',
  how_to_play: 'Como jogar',
  help: 'Ajuda',
  start_bet_round_failed: 'Falha ao iniciar a rodada de jogo',
  cashout_failed: 'Falha no saque',
  auto_bet_round: 'Comprometa o sucesso',
  play_now2: 'JOGUE AGORA',
  under_min_bet: 'Aposta abaixo do mínimo',
  invalid_bet_params: 'parâmetros de reprodução inválidos',
  game_not_available_now: 'Jogo não disponível agora',
  insufficient_amount: 'Quantidade insuficiente',
  invalid_bet_state: 'estado de jogo inválido',
  over_max_bet: 'Aposta acima do máximo',
  gamebank_not_available: 'Gamebank não disponível',
  win_rate: 'Taxa de vitórias',
  welcome_to_Coco_game: 'Bem vindo ao Ohplay.club',
  welcome_to_only: 'Bem vindo ao',
  welcome_only: 'Bem vindo ',
  to_join_club: 'VIP Kulübümüze katılmak için',
  sign_in_or_sign_up: 'Giriş yap Kayıt Ol',
  invalid_email_format: 'Formato de email inválido',
  resend_after: 'Reenviar depois',
  second: 'segundo',
  only_available_at_large_resolutions:
    'Disponível apenas em resoluções grandes > 1600px (xl)',
  provably_fairs: 'Provavelmente feiras',
  Fairness: 'Justiça',
  become_a_coco: 'Torne-se um Ohplay',
  club_member: 'Membro do clube',
  vip_club_subtitle1:
    'Os jogadores com mais tempo de jogo e maior comprometimento receberão convites exclusivos para o',
  vip_club_subtitle2:
    'Isso proporciona uma experiência premium e bônus para seus membros.',
  your_vip_progress: 'Seu progresso VIP',
  your_vip_progress_tooltip:
    'Faça login primeiro e depois veja seu progresso VIP',
  total_wager: 'Compromisso total',
  your_vip_progress_description:
    'Todos os compromissos são convertidos em USD à taxa atual',
  how_does_it_work: 'Como funciona?',
  how_vip_club_work: 'VIP Kulübü nasıl çalışır?',
  the_rewards: 'As recompensas',
  frequently_asked_questions: 'Perguntas frequentes',
  vip_frequent_questions_subtitle:
    'Recomendamos que você entre em contato com o suporte ou com seu gerente VIP se tiver alguma dúvida',
  login: 'Conecte-se',
  to_see_your_vip_progress: 'para ver seu progresso VIP',
  original_wager_rate:
    'Jogos originais: 1 XP = $ 1 de aposta * House Edge * 100/2',
  provider_wager_rate: 'Jogos de provedores: 1 XP = $ 1 de aposta',
  all_wagers_converted_to_usd:
    'Todas as apostas são convertidas para dólares americanos à taxa atual',
  vip_club_benefit: 'Benefício do Clube VIP',
  vip_club_details: 'Detalhes do Clube VIP',
  benefit_table: 'Tabela de benefícios',
  wager_contest: 'Concurso de Apostas',
  game_of_the_day: 'Jogo do dia',
  play_to_earn: 'Jogue para ganhar apostas',
  time_remaining: 'Tempo restante',
  rank: 'Classificação',
  wagered: 'Apostado',
  prize: 'Prêmio',
  wager_contest_information: 'Informações do desafio',
  rule: 'Regra',
  gamebank_chart: 'Gráfico GameBank',
  next_round: 'Proxima rodada',
  start_auto_bet: 'Iniciar aposta automática',
  understand_trend_chart: 'Entenda o gráfico de tendências',
  round_has_been_bet: 'Esta rodada foi apostada, cancele na próxima rodada',
  crash_server_disconnect: 'Desconexão do servidor com falha...',
  coco_info:
    'Este site pertence e é operado pela Orisun N.V., uma empresa registrada e estabelecida sob as leis de Curaçao, com número de registro 163631, com endereço registrado Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao.',
  crash_result: 'Resultado da falha',
  wagers: 'Tocam',
  reset_after: 'Redefinir depois',
  daily_wagering_contest_rules: 'Regras do Concurso Diário',
  the_daily_race:
    'O concurso Daily Race acontece diariamente das 00:00 UTC às 23:59 UTC, começando em',
  daily_contest_prize_pool: 'Prêmio do Concurso Diário',
  straight_flush_with_jack: 'Straight flush com Jack',
  straight_flush: 'Straight flush',
  four_of_a_kind: 'Quatro de um tipo',
  full_house: 'Casa cheia',
  flush: 'Rubor',
  straight: 'Direto',
  three_of_a_kind: 'Três de um tipo',
  tow_pair: 'Dois pares',
  pair_of_jacks_or_better: 'Par de valetes ou melhor',
  you_won: 'Você ganhou',
  show_all: 'Mostre tudo',
  personal: 'Pessoal',
  no_notifications_at_the_moment:
    'Nenhuma notificação no momento. Por favor, volte mais tarde.',
  mode: 'Modo',
  contrast: 'Contraste',
  direction: 'Direção',
  layout: 'Disposição',
  stretch: 'Esticar',
  presets: 'Predefinições',
  status_success: 'sucesso de status',
  status_pending: 'Status: Pendente',
  swap_successfully: 'Troca com sucesso!',
  swap_failed: 'Falha na troca, tente mais tarde',
  coco_member: 'Torne-se um membro Ohplay',
  play_your_favorite_games: 'Jogue seus jogos favoritos',
  instantly_sign_up:
    'Inscreva-se instantaneamente e comece a jogar no jogo ou nas apostas esportivas.',
  unlock_further_benefits:
    'Divirta-se jogando, alcance novos níveis e desbloqueie mais benefícios',
  rank_claim_bonus: 'Aumente sua classificação e reivindique bônus',
  go_up_to_the_next_rank:
    'Suba para a próxima classificação! Cada nível tem seu bônus e ainda mais oportunidades!',
  ranking_system_title: 'Ohplay VIP Ranking System',
  ranking_system_text:
    'Platform sıralama sistemimize katılarak fırsatlar ve tanınma dünyasının kilidini açın! Profilinizi yükseltmek, oyunlarımızı oynamak ve öne çıkan bir katılımcı olmanın avantajlarından yararlanmak için bugün bize katılın. Tanınma şansını kaçırmayın; şimdi başarı hikayesinin bir parçası olun!',
  you_start_your_gaming:
    'Você começa seu jogo a partir do 1º Rank – RECÉM-CHEGADO.',
  the_more_you_play:
    'Quanto mais você joga, maior é a sua classificação. Aumenta de acordo com o valor apostado ao jogar no Ohplay.club. Todas as apostas são convertidas para dólares americanos à taxa atual',
  mark_all_as_read: 'marcar tudo como lido',
  eligible_VIP_member: 'Sou elegível para me tornar um membro VIP?',
  how_much_has_coco_given_out_in_bonuses: 'Quanto Ohplay distribuiu em bônus?',
  why_is_Coco_VIP_program_the_best:
    'Por que o programa VIP da Ohplay é o melhor?',
  what_does_affect_the_weekly_monthly: 'O que afeta o bônus semanal/mensal?',
  information_to_become_a_VIP_member:
    'Terei que fornecer alguma informação adicional para me tornar um membro VIP?',
  coco_VIP_telegram_channel:
    'Onde posso encontrar o canal Ohplay VIP Telegram?',
  one_min: '1 minuto',
  five_min: '5 minutos',
  one_hour: '1 hora',
  for_hours: '4 horas',
  one_day: '1 dia',
  investment: 'Investimento',
  my_investment: 'Meu Investimento',
  what_upp: 'E aí???',
  hide: 'Esconder',
  play: 'Jogar',
  stake_and_earn: 'Jogue e ganhe',
  //
  vip_level_comparison: 'Comparação de nível VIP',
  bronze: 'Bronze',
  silver: 'Prata',
  gold: 'Ouro',
  platinum: 'Platina',
  diamond: 'Diamante',
  level_up_bonus: 'Bônus de subida de nível',
  surprise_box: 'Caixa Surpresa',
  free_spin_wheel_enable: 'Habilitar roda giratória grátis',
  surprise_box_upgraded: 'Caixa surpresa atualizada',
  weekly_bonus: 'Bônus Semanal',
  chat_enable: 'Ativar bate-papo',
  upload_avatar_enable: 'Ativar upload de avatar',
  vip_support_enable: 'Suporte VIP habilitado',
  surprise_box_upgraded_2: 'Caixa surpresa atualizada (até 1,8 milhão de USDT)',
  where_coco_vip_telegram_channel:
    'Onde posso encontrar o canal Ohplay VIP Telegram?',
  we_have_given_over_1_billion:
    'Demos mais de US$ 1 bilhão em bônus. Esta é principalmente a razão pela qual temos o melhor programa VIP online. Nós realmente acreditamos em recompensar nossos jogadores por sua jogabilidade e lealdade.',
  me: 'me',
  result: 'Resultado',
  crush_of_crash: 'Esmagamento do acidente',
  // Banner new
  earn_real_money: 'Ganhe dinheiro real',
  the_daily_game: 'O jogo diário',
  earn_everyday_with_original_games: 'Ganhe todos os dias com jogos originais',
  daily_race: 'CORRIDA DIÁRIA',
  wager: 'Jogada',
  and: 'E',
  everyday: 'DIARIAMENTE',
  crush: 'CRUSH',
  the_crash: 'O CRASH',
  show_off_your_prediction_skills: 'Mostre suas habilidades de previsão',
  deposit_now2: 'DEPOSITE AGORA',
  on_1st_deposit: 'No 1º Depósito',
  // Just for you
  free_play: 'JOGO GRÁTIS',
  with_fun_coin: 'Com moeda divertida',
  show_me: 'MOSTRE-ME',
  low_risk_high_rewards: 'Baixo risco, altas recompensas',
  prize_100000: 'PRÊMIO $ 100,000',
  win_1000x_jackpot: 'Ganhe Jackpot 1000x',
  // Notification
  balance_change: 'Mudança de saldo',
  your_casino: 'Seu centro de jogo',
  // Referral
  spread_the_word_and: 'Espalhe a palavra e',
  earn_reward: 'Ganhe recompensa',
  campaign_name: 'Nome da campanha',
  start_your_referrals: 'Comece suas referências',
  id_number: 'Número de identidade',
  registered: 'Registrado',
  total_deposits: 'Depósitos totais',
  last_deposit: 'Último depósito',
  vip_point: 'Ponto VIP',
  commission: 'Comissão',
  referral: 'Referência',
  register_date: 'Data de registo',
  display_name: 'Nome de exibição',
  agents: 'Agentes',
  transaction_date: 'Data da transação',
  transaction_type: 'Tipo de transação',
  export: 'Exportar',
  campaigns: 'Campanhas',
  create_a_new_campaign: 'Crie uma nova campanha',
  claim_income: 'Reivindicar renda',
  per_players: 'Por jogadores',
  per_agents: 'Por Agentes',
  per_day: 'Por dia',
  per_week: 'Por semana',
  per_month: 'Por mês',
  per_country: 'Por país',
  date: 'Data',
  total_income: 'Recompensa total',
  total_user_register: 'Cadastro total de usuários',
  total_user_bet: 'Jogo Total do Usuário',
  total_transaction: 'Transação total',
  refer_description:
    'Indique amigos para o Programa de Afiliados do Ohplay.club e ganhe comissões para cada usuário que se registrar e jogar através de seus links, independentemente de seus resultados',
  download_banner: 'Baixar banner',
  search: 'Procurar',
  clicks: 'Cliques',
  referrals: 'Referências',
  deposit_users: 'Usuários de depósito',
  total_commission: 'Comissão Total',
  commission_rate: 'Taxa de comissão',
  features: 'Características',
  link: 'Link',
  code: 'Código',
  today: 'Hoje',
  this_week: 'Essa semana',
  last_week: 'Semana passada',
  overall: 'Geral',
  commission_available: 'Comissão disponível',
  claim: 'Alegar',
  log_in: 'Conecte-se',
  to_see_your_rank: 'para ver sua classificação',
  // Crash
  start_in: 'Começar ...',
  reconnect: 'Reconectar',
  controls: 'controles',
  leaderboard: 'Entre os melhores',
  bet_next: 'Jogue a seguir',
  betting: 'jogada',
  // Tip
  message_is_required: 'A mensagem é obrigatória',
  tip_with_any_currency_in_CoCo: 'Gorjeta com qualquer moeda no Ohplay',
  receiver_id: 'ID do receptor',
  enter_user_id: 'Insira o ID do usuário',
  transaction_fee: 'Taxa de transação',
  message: 'Mensagem',
  give_your_friends_some_messages_here:
    'Mande algumas mensagens para seus amigos aqui...',
  continue: 'Continuar',
  successfully_tipped: 'Dica bem-sucedida',
  you_are_tipping_to: 'Você está dando gorjeta para',
  your_tip_request_has_been_confirmed: 'Seu pedido de gorjeta foi confirmado.',
  you_can_track_the_progress_on_the_wallet_transaction_history:
    'Você pode acompanhar o progresso no Histórico de transações da carteira.',
  view_history: 'Ver histórico',
  tip_failed: 'Falha na dica',
  your_tip_request_was_unsuccessful_due_to_an_abnormality:
    'Sua solicitação de gorjeta não foi bem-sucedida devido a uma anormalidade.',
  please_try_again_later: 'Por favor, tente novamente mais tarde.',
  ok_i_got_it: 'OK, entendi',
  tip_information: 'Informações sobre dicas',
  password_is_required: 'Senha requerida',
  tip_verification: 'Verificação de dicas',
  authentication_secure: 'Autenticação segura',
  to_process_tipping_please: 'Para processar gorjetas, por favor',
  first_to_secure_your_assets: 'primeiro a proteger seus ativos',
  step_verification: 'Verificação em duas etapas',
  enter_your_login_password: 'Digite sua senha de login',
  enter_the_2fa_code_appear_on_your_google_authenticator_app:
    'Digite o código 2FA que aparece em seu Google Authenticator App',
  authorize: 'Autorizar',
  // 30/09
  sign_up_in_and_earn: 'Inscreva-se/Inscreva-se e Ganhe',
  learn_more_about_our_affiliate_program:
    'Saiba mais sobre nosso programa de afiliados',
  referral_link: 'Link de referência',
  create: 'Criar',
  referral_users: 'Usuários de referência',
  registered_date: 'Data de registro',
  tip: 'Tip',
  send_tip: 'Enviar dica',
  delete: 'Excluir',
  accept: 'Aceitar',
  close: 'Fechar',
  member_downline: 'Linha descendente de membros',
  new_player: 'Novo jogador',
  claim_commission_failed: 'Comissão de reivindicação falhou',
  claim_commission_successfully: 'Comissão de reivindicação com sucesso',
  create_campaign_successfully: 'Criar campanha com sucesso',
  create_campaign_failed: 'Falha ao criar campanha',
  any_date: 'Qualquer data',
  // Mini CoCo
  stop_if_single_win_exceeds_by: 'Pare se a vitória única exceder em',
  // Host
  show_filter: 'Mostrar filtro',
  filter: 'Filtro',
  default_campaign: 'Campanha padrão',
  select_all: 'Selecionar tudo',
  role: 'papel',
  hello_world: 'Olá Mundo',
  // GameBank => Overview
  gb_capacity: 'Capacidade em GB',
  game_bank_capacity: 'Capacidade do banco de jogos',
  the_maximum_amount_that_you_can_invest:
    'O valor máximo que você pode investir.',
  // Crash
  finish_bet: 'Terminar aposta',
  finishing_bet: 'Aposta final',
  // Referral
  delete_invitation: 'Excluir convite',
  you_had_been_invited_to_become_an_agent_by:
    'Você foi convidado para se tornar um agente por',
  confirming_to_delete_this_invitation:
    'Ao confirmar a exclusão deste convite, esta ação não será desfeita. Ainda quer decidir?',
  successfully_accepted_invitation: 'Você aceitou o convite com sucesso',
  the_house: 'A casa ',
  invited_you_to_become_an_agent: 'convidei você para se tornar um agente',
  campaign_name_1: 'Nome da campanha 1',
  available_commission: 'Comissão Disponível',
  there_are_no_commission: 'Não há comissão',
  please: 'Por favor',
  login2: 'Conecte-se',
  to_see_your_rewards: 'para ver suas recompensas',
  you_have_successfully_accepted_the_invitation:
    'Você aceitou o convite com sucesso',
  //
  downline_report: 'Relatório de linha descendente',
  referral_faq_subscription:
    'Para mais detalhes sobre o programa de afiliados, entre em contato ',
  internet_connection_restored: 'Conexão com a Internet restaurada',
  lost_connect_to_network: 'Conexão perdida com a rede',
  // 10/10
  user_profile: 'Perfil de usuário',
  leave_a_tip: 'Deixe uma dica',
  your_commission_rate: 'Sua taxa de comissão',
  affiliate_terms_conditions: 'Termos e Condições do Afiliado',
  REFERRAL_CODE_OR_NAME_EXISTS:
    'O nome da campanha ou o código de referência já existe',
  swap_unavailable:
    'O par de troca não é compatível no momento. Tente novamente mais tarde.',
  please_enter_swap_amount: 'Por favor insira o valor do swap',
  become_our_agent_Contact_business:
    'Torne-se nosso agente? Entre em contato com a empresa:',
  earn_commission_rewards:
    'Ganhe recompensas de comissão sempre que seus amigos fizerem apostas em nossos jogos',
  turn_your_friends_and_followers_into_weekly_commissions:
    'Transforme seus amigos e seguidores em comissões semanais com o programa de afiliados da Ohplay. É simples, lucrativo e totalmente transparente',
  // FAQ
  why_should_i_be_a_Coco_affiliate: 'Por que devo ser um afiliado Ohplay?',
  adding_our_link_and_or_banner_to_your_website:
    'Se você tem um site ou canais sociais com algum tráfego, por que não maximizar seu potencial? Ao adicionar nosso link e/ou banner ao seu site, você pode criar uma maneira nova e eficaz de obter renda adicional instantaneamente.',
  how_do_I_get_paid_for_being_an_affiliate:
    'Como sou pago por ser um afiliado?',
  the_banner_or_link_you_create_contains_your_personal_tracking_code:
    'O banner ou link que você cria contém seu código de rastreamento pessoal. Quando os jogadores visitam o Ohplay, esse código é armazenado em um cookie no computador. Quando o usuário se cadastra, nós o identificamos como indicado por você. Este jogador torna-se permanentemente associado a você e você continua a gerar comissões por suas atividades por toda a vida.',
  how_a_new_player_linked_to_me: 'Como um novo jogador se vinculou a mim?',
  how_exactly_is_my_affiliate_commission_calculated:
    'Como exatamente minha comissão de afiliado é calculada?',
  the_commission_for_each_bet: `The commission for each bet is calculated based on the formula below: Commission = Wager * the house edge of the game * commission rate Where:
      - Aposta total: O valor total das apostas no jogo gerado por todos os usuários indicados pelo afiliado.
      - Vantagem da casa: A relação entre a perda média e a aposta inicial. A vantagem da casa de cada jogo é definida como 1 menos o RTP (Return to Player).
      - Taxa de comissão: A taxa depende da função de afiliado dos usuários e é exibida no site do Afiliado.`,
  how_to_join_the_affiliate_program: 'Como aderir ao programa de afiliados?',
  if_you_already_have_a_Coco_account: `Se você já possui uma conta Ohplay, basta seguir estas etapas:
      1. Visite a página de afiliados.
      2. Gere uma nova campanha usando seu código de referência exclusivo.
      3. Compartilhe seu link de indicação em seu site, mídia social ou qualquer plataforma de alto tráfego.
      4. Reivindique sua comissão semanal dos usuários que você indicou.`,
  how_can_i_keep_track_of_my_performance:
    'Como posso acompanhar meu desempenho?',
  once_your_affiliate_link_is_generated:
    'Depois que seu link de afiliado for gerado, você poderá fazer login no painel de afiliados. Lá, você tem acesso para visualizar todas as suas estatísticas e pode filtrar as informações dos relatórios com base em diversos fatores.',
  at_the_start_of_each_new_week:
    'No início de cada nova semana, sua comissão é calculada e disponibilizada para reivindicação na forma de tokens COD. Você pode facilmente retirar seus ganhos para a carteira de sua conta.',
  i_have_big_audience_how_i_can_get_special_deals:
    'Tenho um grande público, como posso conseguir ofertas especiais?',
  if_you_have_a_website_with_substantial_traffic:
    'Se você tem um site com tráfego substancial ou uma conta de mídia social com um grande público, entre em contato conosco pelo e-mail business@Ohplay.club para ofertas exclusivas.',
  // Crash
  maximum_is_9900: `O máximo é "9900"`,
  minium_is_101: `O mínimo é "1,01"`,
  insufficient_currency: 'Moeda insuficiente',
  this_game_does_not_support_this_currency:
    'Este jogo não suporta esta moeda. Mude para outro ativo para continuar jogando.',
  unknown: 'desconhecido',
  you_should_verify_account_first_before_tipping_your_friends:
    'Você deve verificar a conta primeiro \n antes de dar gorjeta aos seus amigos',
  must_be_only_digits: 'Deve haver apenas dígitos',
  min_length_ID_at_least_14_number:
    'ID de comprimento mínimo de pelo menos 14 números',
  receiver_is_required: 'O receptor é obrigatório',
  tip_amount_must_specify_a_number:
    'O valor da gorjeta deve especificar um número',
  tip_amount_must_greater_than_0: 'O valor da gorjeta deve ser maior que 0',
  tip_amount_is_required: 'O valor da gorjeta é obrigatório',
  how_to_see_other_ID: 'Como ver outro ID?',
  get_your_friends_ID_from_their_profiles:
    'Obtenha a ID dos seus amigos nos perfis deles.',
  tips_amount: 'Quantidade de gorjetas',
  total_spending_amount: 'Valor total gasto',
  withdrawing_your_balance:
    'Você deve verificar sua conta primeiro antes de sacar seu saldo',
  play_increase_wager:
    'Ao receber o dinheiro do bônus, você precisará atingir um valor de aposta de $ 120.000 para sacar',
  pls_enter_code_send_to_tel:
    'por favor insira o código e envie para o telefone',
  please_enter_withdraw_address: 'Por favor insira o endereço de retirada',
  your_profile_had_been_verified: 'Seu perfil foi verificado',
  SWAP_UNAVAILABLE: 'Saldo insuficiente',
  // Maintain
  this_page_is_under_maintenance: 'DESCULPE! Esta página está em manutenção',
  please_give_us_some_minutes_to_fix_the_issue:
    'Dê-nos alguns minutos para corrigir o problema',
  // 404
  we_cant_find_that_page: `Opa! Não conseguimos encontrar essa página.`,
  you_can_still_enjoy_the_best_casino_experience_at_Coco: `Não se preocupe, você ainda pode desfrutar da MELHOR experiência de cassino no Ohplay.club.`,
  //
  region_prohibited: 'Região Proibida',
  coco_game_is_not_available_in_your_region:
    'Desculpe, Ohplay.club não está disponível em sua região.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country:
    'Devido à nossa licença de jogo, não podemos aceitar jogadores do seu país. Contate-nos através de support@Ohplay.club se precisar de mais assistência.',
  congrats: 'Parabéns',
  won_the_most: 'ganhou mais!',
  coco_took_it_all: 'Ohplay levou tudo!',
  page: 'Página',
  refresh: 'Atualizar',
  // Popup
  register_to_get: 'Registre-se para obter',
  player_to_earn_the: 'Jogador para ganhar o',
  $10_prize: 'Prêmio de $ 10',
  sign_up_now: 'Inscreva-se agora',
  // Popup 2
  you_receive: 'TU RECEBES',
  weekly: 'semanalmente',
  see_detail: 'veja detalhes',
  swap_amount_need_to_larger_than_min:
    'O valor do swap precisa ser maior que o mínimo',
  crypto_play: 'Jogo criptográfico',
  fun_play: 'Jogo divertido',
  // Popup 3
  play_with: 'JOGAR COM',
  earn: 'GANHAR',
  real: 'REAL',
  dollars: 'DÓLARES',
  big_wins: 'Grandes vitórias',
  what_is_gift_code: 'O que é código de presente?',
  gift_code_info:
    'Um código de presente é como um código especial composto de letras e números. Ao resgatar este código, você recebe recompensas especiais de',
  gift_code_cod:
    'Cada código de presente tem seu próprio valor e duração. Todas as recompensas são pagas na forma de tokens COD',
  there_are_various_ways_to_get_Coco_gift_codes:
    'Existem várias maneiras de obter códigos de presente Ohplay, como participando de eventos exclusivos de mídia social, atividades comunitárias, bônus ou missões especiais.',
  how_to_get_Coco_gift_code: 'Como obter o código de presente Ohplay?',
  how_to_use_Coco_gift_code: 'Como usar o código de presente Ohplay?',
  simply_enter_the_gift_code:
    'Basta inserir o código do presente e as recompensas serão adicionadas automaticamente ao seu saldo.',
  giftcode_required: 'Código de presente necessário',
  bonus_code_claimed_successfully: 'Código de bônus reivindicado com sucesso',
  claim_your_free_reward: 'Reivindique sua recompensa grátis',
  enter_your_giftcode: 'Digite seu código presente',
  claim_bonus: 'Reivindicar bônus',
  what_is_giftcode: 'O que é código de presente',
  what_is_COCO_giftcode: 'O que é o código de presente Ohplay?',
  got_it: 'Entendi',
  won: 'venceu',

  // 8/12
  use_your_COD_gift_to_bet_and_win_at_Cocogame:
    'Use seu presente Coco para apostar e ganhar no Coco.game',
  gift_code: 'Código de presente',
  // 15/12
  mini_Coco_is_Allways_3x3_Slot_machine_game:
    'Mini Ohplay é um jogo de caça-níqueis 3x3 de 27 linhas.',
  rule_content: `Regras: os símbolos começam na primeira coluna, consecutivamente da esquerda para a direita. O pagamento calcula apenas o valor mais alto em uma linha.`,
  jackpot2: 'JACKPOT',
  explode_if_you_collect_3_WILD: 'Exploda se você coletar 3 WILD.',
  wild_can_subsitute_for_all_symbols: 'Wild pode substituir todos os símbolos.',

  // 3/1
  series: 'Series',
  multiple: 'Múltiplo',
  result_will_be_display_here: 'O resultado será exibido aqui',
  bet_head: 'Cabeça de aposta',
  bet_tail: 'Cauda de Aposta',
  instant: 'Instante',
  multiply: 'multiplicar',
  coin_flip_instant_mode_desc:
    'Basta apostar cara ou cauda ou configurar aposta automaticamente',
  auto_bet_setup: 'Configuração de aposta automática',
  you_win_title: 'Você ganha',
  select_network_to_continue: 'Para continuar, selecione rede',
  ensure_withdraw_address:
    'Certifique-se de inserir o endereço correto; caso contrário, seus fundos serão perdidos e não poderão ser recuperados.',
  click_bet_to_choose_coin_side:
    'Clique na aposta para escolher o lado da moeda',

  copied_share_in_chat: 'Copiado, compartilhe no chat!',
  copied_share_online: 'Copiado, compartilhe online!',
  game_id: 'ID do jogo',
  server_seed_hash: 'Semente do Servidor (Hash)',
  client_seed: 'Semente do cliente',
  nonce: 'Nonce',
  about_COD: 'Sobre COD',
  coco_dollar_info:
    'COD (Coco Dollar) é uma moeda especial lançada pela COCO.GAME. Com o COD você pode participar de jogos ou dar dicas para outros jogadores.',
  can_COD_be_exchanged_to_any_other_currency:
    'O COD pode ser trocado por qualquer outra moeda?',
  you_can_swap_cod:
    'Você pode trocar COD por outras moedas a qualquer momento usando Coco Swap, com base na taxa de câmbio atual.',
  get_daily_bonus_up_to_5000_COD: 'Ganhe bônus diário de até 5.000 CODD',
  by_playing_games_tips_coin_dropping_and_rain_bonus:
    'Jogando jogos, dicas, lançamento de moedas e bônus de chuva.',
  see_how: 'Veja como',
  how_to_unlock_COD: 'Como desbloquear COD',
  locked_COD_is_earned_through_specific_bonuses_like_deposit_bonuses:
    'O COD bloqueado é ganho através de bônus específicos, como bônus de depósito.',
  unlocking_COD_is_simple:
    'Desbloquear o COD é simples; é semelhante ao rake back e desbloqueia proporcionalmente às suas apostas.',

  // Chat
  please_slow_down: 'Por favor, diminua a velocidade.',
  links_must_include_your_website_domain:
    'Os links devem incluir o domínio do seu site.',
  chat_is_hidden: 'O bate-papo está oculto',
  show_chat: 'Mostrar bate-papo',
  pinned_message: 'Mensagem fixada',

  // 12/01
  deposit_bonus: 'Bônus de Depósito',
  rakeback: 'Rakeback',
  spin: 'Rodar',
  get_extra_300_bonus_on_minimum_of_10_USDT_deposit:
    'Obtenha um bônus extra de 300% em um depósito mínimo de 10,00 USDT',
  min_deposit: 'Depósito mínimo:',
  max_claim: 'Reivindicação máxima:',
  withdraw_successful: 'Retirada bem-sucedida',
  your_withdrawal_request_has_been_confirmed:
    'Seu pedido de saque foi confirmado. Você pode acompanhar o progresso no Histórico de transações da carteira.',
  pi_balance_in_wallet: 'Saldo Pi na carteira',
  your_Pi_address: 'Seu endereço Pi',
  fill_in_carefully_according_to_the_specific_currency:
    'Preencha cuidadosamente de acordo com a moeda específica',
  you_will_receive_Pi_to_your_address_within_1_working_day:
    'Você receberá Pi em seu endereço dentro de 1 dia útil.',
  please_make_sure_you_enter_the_correct_Pi_address_otherwise_your_funds_will_not_be_reversed:
    'Certifique-se de inserir o endereço Pi correto; caso contrário, seus fundos não serão revertidos.',
  current_capacity: 'Capacidade atual',
  what_is_CODG_capacity: 'Qual é a capacidade CODG?',
  rng_certificated_by: 'RNG Certificado por',

  // 08/03
  buy_crypto: 'Compre criptografia',
  buy_fiat: 'Selecione Dinheiro Fiat',
  refund: 'Reembolso',
  bet_histories: 'Históricos de apostas',
  round_histories: 'Histórias de rodadas',
  round_id: 'ID da rodada',
  bet_place: 'Local de Aposta',
  profit: 'Lucro',
  top: 'Principal',
  top_profit: 'Lucro máximo',
  leader_board: 'Entre os melhores',
  how_to_join: 'Como entrar',
  see_more: 'Ver mais',
  top_winning_streak: 'Maior sequência de vitórias',
  top_losing_streak: 'Maior sequência de derrotas',
  place: 'Lugar',
  streak: 'Onda',
  total_prized: 'Total Premiado',
  top_reward: 'Melhor recompensa',
  top_10_winners: 'Os 10 melhores vencedores',
  monthly_bonus: 'Bônus Mensal',
  available_rakeback: 'Rakeback disponível',
  no_rakeback_yet: 'Ainda não há rakeback',
  claim_my_rakeback: 'Reivindique meu Rakeback',
  wager_required: 'Aposta necessária',
  receive_every: 'Receba cada',
  claim_bonus_successfully: 'Reivindicar bônus com sucesso',
  claim_bonus_failed: 'Falha na reivindicação do bônus. Tente novamente!',
  no_bonus_available: 'Nenhum bônus disponível',
  play_to_earn_bonus: 'Jogue e comece a apostar para ganhar bônus',
  play_and_wager: 'Jogue e aposte',
  claim_all: 'Reivindique tudo',
  duration: 'Duração',
  your_total_bonus: 'Seu bônus total',
  roll_under: 'Role para baixo',
  game_result_will_be_displayed_here: 'O resultado do jogo será exibido aqui.',
  level_up_bonus_upgrade: 'Atualização de bônus para subir de nível',
  _24h_live_support: 'Suporte ao vivo 24 horas',
  bonus_growth: 'Crescimento de bônus',
  xp_required: 'XP necessário',
  platinum_1_to_6: 'Platina I - VI',
  diamond_1_to_6: 'Diamante I - VI',

  // CODG
  total_received_CODG: 'Total de CODG recebido',
  unlocked_amount: 'Valor desbloqueado',
  how_to_unlock_CODG: 'Como desbloquear o CODG?',
  swap_and_play: 'Troque e jogue',
  unlocked_CODG:
    'Você pode trocar seu CODG desbloqueado por outras moedas para jogar e ganhar',
  available_to_swap: 'Disponível para troca',
  there_are_no_redeemable_currencies: 'Não há moedas resgatáveis',
  first_deposit: 'Primeiro Depósito',
  _300_bonus: 'Bônus de 300%',
  get_up_to: 'Levante-se para',
  second_deposit: 'Segundo Depósito',
  _200_bonus: 'Bônus de 200%',
  third_deposit: 'Terceiro Depósito',
  _150_bonus: 'Bônus de 150%',
  fourth_deposit: 'Quarto Depósito',
  _100_bonus: 'Bônus de 100%',
  deposit_promotion: 'Promoção de Depósito',
  unlock_codg_description:
    'Presenta un excepcional bono de depósito de 4 niveles, que brinda una excelente oportunidad para ganar recompensas adicionales en nuestro token exclusivo, CODG. Aprovecha esta ventaja, amplifica tus fondos y embárcate en un emocionante viaje con nuestra plataforma.',

  // Buy Crypto
  our_excited_bonus: 'Nossa animada jornada de bônus para você',
  something_is_wrong: 'Algo está errado.',
  disclaimer: 'Isenção de responsabilidade',
  the_above_third_party:
    'Los servicios de terceros anteriores se pueden utilizar para comprar criptomonedas que se pueden utilizar para jugar en Coco. Al registrarse en su plataforma, también acepta sus términos de servicio y se le pedirá que pase su proceso KYC, que se ejecuta de forma independiente al nuestro.',
  buy_with_transFI: 'Compre com TransFI',
  order_processing: 'processamento de pedido',
  your: 'Seu',
  buying_order_is_currently:
    'pedido de compra está sendo processado. Aguarde alguns minutos',
  // Deposit
  transfer_pi: 'Transferir Pi',
  transfer_Pi_to_the_address_above_which_is_our_agent_address:
    'Transfira Pi para o endereço acima, que é o endereço do nosso agente.',
  meet_our_live_support: 'Conheça nosso suporte ao vivo',
  click_the_i_transferred_Pi:
    'Clique no botão “I Transferred Pi” para conhecer nosso suporte ao vivo.',
  confirm_transaction: 'Confirmar transação',
  send_your_transaction_and_screenshot_to_the_chat_box_as_confirmation:
    'Envie sua transação e captura de tela para a caixa de bate-papo como confirmação.',
  transfer_Pi_to_this_address: 'Transferir Pi para este endereço',
  how_to_deposit_Pi: 'Como depositar Pi?',
  i_transferred_Pi: 'Eu transferi Pi',
  earn_an_extra_300_bonus_with_a_minimum_deposit_of_1000_USD:
    'Ganhe um bônus extra de 300% com um depósito mínimo de 10,00 USD.',
  // Tip
  max_length_ID_not_more_than_20_number:
    'ID de comprimento máximo não superior a 20 números',
  receiver: 'receptor',
  transaction_fee_rate: 'Taxa de transação',
  transaction_fee_amount: 'Valor da taxa de transação',
  you_need_to_wager: 'Você precisa apostar',
  // Withdraw
  more_to_withdraw_due_to_the_bonus_money_policies:
    'mais para sacar devido às políticas de dinheiro de bônus',
  pi_address_is_required: 'O endereço Pi é obrigatório',
  pi_Amount_must_specify_a_number: 'Pi Amount deve especificar um número',
  pi_amount_must_greater_than_10_Pi:
    'A quantidade de Pi deve ser maior que 10 Pi',
  pi_amount_must_less_than_10000_Pi:
    'A quantidade de Pi deve ser inferior a 10.000 Pi',
  pi_amount_is_required: 'O valor do Pi é obrigatório',
  withdraw_successfully_We_will_contact_you_later:
    'Retirada com sucesso! Entraremos em contato com você mais tarde',
  hey_there: 'Ei',
  please_process_my_Pi_coin_withdrawal_as_follows:
    'Por favor, processe minha retirada de moedas Pi da seguinte maneira',
  fees: 'Tarifas',
  input_your_Pi_address: 'Insira seu endereço Pi',
  input_number_of_Pi: 'Número de entrada de Pi',
  lucky_win: 'Vitória de sorte',
  can_not_get_bet_detail: 'Não é possível obter detalhes da aposta!',

  // Popup currency
  cannot_be_used_in_this_game:
    'não pode ser usado neste jogo no modo Real Play. Mude para outros ativos.',
  the_selected_currency_will_be_displayed_in:
    'A moeda selecionada será exibida em',
  and_if_you_change_currency_while_playing:
    'e se você alterar a moeda enquanto joga, o jogo será atualizado e reiniciado.',

  // CODG
  about_CODG: 'Sobre CODG',
  CODG_is_the_reward_token_of_Cocogame:
    'CODG es el token de recompensa de Coco.game. Cuanto más interactúes con la plataforma, más CODG ganarás para enriquecer tus activos.',
  can_CODG_be_swapped_for_other_currencies:
    'O CODG pode ser trocado por outras moedas?',
  you_can_swap_your_unlocked_CODG:
    'Puede cambiar su CODG desbloqueado por otras monedas según el tipo de cambio actual. Sin embargo, el CODG bloqueado no se puede intercambiar; sólo se puede utilizar para jugar en varios juegos dentro de la plataforma.',
  // GameBank => Overview
  the_maximum_share_of_all_users: 'A participação máxima de todos os usuários',
  your_maximum_share: 'Sua participação máxima',
  total_available_investing_amount: 'Valor total de investimento disponível',
  your_available_investing_amount: 'Seu valor de investimento disponível',
  // Game bank => InvestInformation
  what_is_investing_in_coco_gamebank: 'O que é investir no Coco gamebank',
  anyone_can_add_money_to_the_gamebank:
    'Cualquiera puede agregar dinero al Gamebank y unirse a las apuestas de la casa. Sus ganancias se determinan multiplicando las ganancias totales del banco de juegos por su parte.',
  why_should_i_invest: 'Por que devo investir?',
  profitable: 'Rentável',
  do_you_believe_in_the_bad_luck_of_other_gamblers:
    '¿Crees en la mala suerte de otros jugadores? Si es así, convertirse en la casa es una excelente manera de obtener ingresos pasivos.',
  real_yield: 'Rendimento Real',
  you_will_receive_profit_in_the_same_token_that_you_invest:
    'Você receberá lucro no mesmo token que investir.',
  analysis_tool: 'Ferramenta de análise',
  access_gamebank_value:
    'Acesse valor, ações, histórico e tendências do Gamebank para tomar as melhores decisões.',
  understand_the_risk_involved: 'Entenda o risco envolvido',
  keep_in_mind_that_the_gamebank_value_may_temporarily_decrease_due_to_gamblers_wins:
    'Tenga en cuenta que el valor del banco del juego puede disminuir temporalmente debido a las ganancias de los jugadores, por lo que es posible que no vea ganancias inmediatas. Invierta con cautela.',
  invest_information: 'Informações sobre investimentos',
  details: 'Detalhes',
  // Gift code
  gift_received_successfully: 'Código de presente reivindicado',
  congratulations_you_have_just_received: 'Parabéns! Você acabou de receber:',
  cod_successfully: 'COD com sucesso',
  // Transaction
  receiver_nickname: 'Apelido do receptor',
  sender_nickname: 'Apelido do remetente',
  tip_successful: 'Dica bem-sucedida',
  // JFU
  bet_win: 'APOSTE E GANHE',
  challenge: 'DESAFIO',
  cash_it_out: 'DESCONTAR',
  tournament: 'TORNEIO',
  november_exclusive: 'EXCLUSIVO DE NOVEMBRO',
  new_game: 'NOVO JOGO',
  you_need_to_reach_the_rank_to_unlock_this_feature:
    'Você precisa chegar ao {{val}} classificação para desbloquear esse recurso. Verifique sua classificação em',
  play_with_balance_in: 'Jogue com equilíbrio em',

  // Host
  ohplay_vip_club: 'Clube VIP Ohplay',
  pi_vip_club: 'Clube VIP Pi',
  to_pi_vip_club: 'para o Clube VIP Pi',
  to_ohplay_vip_club: 'para o Clube VIP Ohplay',
  welcome_to_Ohplay: 'Bem-vindo ao Ohplay.club',
  welcome_to_Pi: 'Bem-vindo ao Pi.game',
  become_a_pi: 'Torne-se um Pi',
  become_a_ohplay: 'Torne-se um Ohplay',
  pi_member: 'Torne-se um membro Pi',
  ohplay_member: 'Torne-se um membro Ohplay',
  why_is_Pi_VIP_program_the_best: 'Por que o programa Pi VIP é o melhor?',
  why_is_Ohplay_VIP_program_the_best:
    'Por que o programa Ohplay VIP é o melhor?',
  how_much_has_pi_given_out_in_bonuses: 'Quanto Pi distribuiu em bônus?',
  how_much_has_ohplay_given_out_in_bonuses:
    'Quanto Ohplay distribuiu em bônus?',
  pi_VIP_telegram_channel: 'Onde posso encontrar o canal Pi VIP Telegram?',
  ohplay_VIP_telegram_channel:
    'Onde posso encontrar o canal Ohplay VIP Telegram?',
  tip_with_any_currency_in_Pi: 'Dê gorjeta com qualquer moeda em Pi',
  tip_with_any_currency_in_Ohplay: 'Dê gorjeta com qualquer moeda no Ohplayy',
  why_should_i_be_a_Pi_affiliate: 'Por que devo ser um afiliado Pi?',
  why_should_i_be_a_Ohplay_affiliate: 'Por que devo ser um afiliado Ohplay?',
  if_you_already_have_a_Pi_account: `Se você já possui uma conta Pi, basta seguir estas etapas:
       1. Visite a página do Afiliado.
       2. Gere uma nova campanha usando seu código de referência exclusivo.
       3. Compartilhe seu link de indicação em seu site, mídia social ou qualquer plataforma de alto tráfego.
       4. Reivindique sua comissão semanal dos usuários que você indicou.`,
  if_you_already_have_a_Ohplay_account: `Se você já possui uma conta Ohplay, basta seguir estes passos:
       1. Visite a página do Afiliado.
       2. Gere uma nova campanha usando seu código de referência exclusivo.
       3. Compartilhe seu link de indicação em seu site, mídia social ou qualquer plataforma de alto tráfego.
       4. Reivindique sua comissão semanal dos usuários que você indicou.`,
  you_can_still_enjoy_the_best_casino_experience_at_Pi: `Não se preocupe, você ainda pode aproveitar a MELHOR experiência de cassino em Pi.game`,
  you_can_still_enjoy_the_best_casino_experience_at_Ohplay: `Não se preocupe, você ainda pode desfrutar da MELHOR experiência de cassino em Ohplay.club`,
  pi_game_is_not_available_in_your_region:
    'Desculpe, Pi.game não está disponível em sua região.',
  ohplay_game_is_not_available_in_your_region:
    'Desculpe, Ohplay.club não está disponível em sua região.',
  pi_took_it_all: 'Pi levou tudo!',
  ohplay_took_it_all: 'Ohplay levou tudo!',
  there_are_various_ways_to_get_pi_gift_codes:
    'Existem várias maneiras de obter códigos de presente Pi, como participar de eventos exclusivos de mídia social, atividades comunitárias, bônus ou missões especiais.',
  there_are_various_ways_to_get_ohplay_gift_codes:
    'Existem várias maneiras de obter códigos de presente Ohplay, como participar de eventos exclusivos de mídia social, atividades comunitárias, bônus ou missões especiais.',
  how_to_get_Pi_gift_code: 'Como obter o código de presente Coco.game?',
  how_to_get_Ohplay_gift_code: 'Como obter o código de presente Coco.game?',
  gift_code_pi: 'Pi.game',
  gift_code_ohplay: 'Ohplay.club',
  how_to_use_pi_gift_code: 'Como usar o código de presente Pi.game?',
  how_to_use_ohplay_gift_code: 'Como usar o código de presente Ohplay.club?',
  what_is_pi_giftcode: 'O que é o código Pi Gift?',
  what_is_ohplay_giftcode: 'O que é o código de presente Ohplay',
  use_your_COD_gift_to_bet_and_win_at_pi:
    'Use seu presente Pi para jogar e ganhar no Pi.game',
  use_your_COD_gift_to_bet_and_win_at_ohplay:
    'Use seu presente Ohplay para apostar e ganhar em Ohplay.club',
  the_above_third_party_pi:
    'Os serviços de terceiros acima podem ser usados para comprar criptografia que pode ser usada para jogar no Pi. Ao se registrar em sua plataforma, você também aceita seus termos de serviço e será obrigado a passar no processo KYC, que é executado de forma independente do nosso.',
  the_above_third_party_ohplay:
    'Os serviços de terceiros acima podem ser usados para comprar criptografia que pode ser usada para jogar no Ohplay. Ao se registrar em sua plataforma, você também aceita seus termos de serviço e será obrigado a passar no processo KYC, que é executado de forma independente do nosso.',
  CODG_is_the_reward_token_of_Pigame:
    'CODG é o token de recompensa do Pi.game. Quanto mais você se envolve com a plataforma, mais CODG você ganha para enriquecer seus ativos.',
  CODG_is_the_reward_token_of_Ohplay:
    'CODG é o token de recompensa do Ohplay.club. Quanto mais você se envolve com a plataforma, mais CODG você ganha para enriquecer seus ativos.',
  what_is_investing_in_pi_gamebank: 'O que é investir no banco de jogos Pi',
  what_is_investing_in_ohplay_gamebank:
    'O que é investir no banco de jogos Ohplay',
  turn_your_friends_and_followers_into_weekly_commissions_pi:
    'Transforme seus amigos e seguidores em comissões semanais com o programa de afiliados do Pi. É simples, rentável e totalmente transparente',
  turn_your_friends_and_followers_into_weekly_commissions_ohplay:
    'Transforme seus amigos e seguidores em comissões semanais com o programa de afiliados da Ohplay. É simples, lucrativo e totalmente transparente',
  if_you_have_a_website_with_substantial_traffic_pi:
    'Se você tem um site com tráfego substancial ou uma conta de mídia social com um grande público, entre em contato conosco pelo e-mail business@Pi.game para ofertas exclusivas.',
  if_you_have_a_website_with_substantial_traffic_ohplay:
    'Se você tem um site com tráfego substancial ou uma conta de mídia social com um grande público, entre em contato conosco pelo e-mail business@Ohplay.club para ofertas exclusivas.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_pi:
    'Devido à nossa licença de jogo, não podemos aceitar jogadores do seu país. Contate-nos através de support@Pi.game se precisar de mais assistência.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_ohplay:
    'Devido à nossa licença de jogo, não podemos aceitar jogadores do seu país. Contate-nos através de support@Ohplay.club se precisar de mais assistência.',
  all_plays: 'Todos pla',
  my_plays: 'Minha peça',
  high_play: 'Jogo alto',
  play_id: 'ID do jogo',
  play_amount: 'Quantidade de jogo',
  set_auto_play: 'Definir aposta automática',
  number_of_play: 'Número de jogo',
  stop_auto_play: 'Parar a reprodução automática',
  start_play_round_failed: 'Falha ao iniciar a rodada de jogo',
  wager_contest_pi: 'Concurso Pi.game',
  max_play: 'Jogo máximo',
  total_play_amount: 'Valor total do jogo',
  under_min_play: 'Menos de jogo',
  invalid_play_params: 'parâmetros de reprodução inválidos',
  over_max_play: 'Jogo acima do máximo',
  invalid_play_state: 'estado de jogo inválido',
  play_next: 'Jogue a seguir',
  playing: 'Jogando',
  play_head: 'Cabeça de jogo',
  play_tail: 'Jogar caudal',
  auto_play_setup: 'Configuração de reprodução automática',
  click_play_to_choose_coin_side:
    'Clique em play para escolher o lado da moeda',
  play_histories: 'Históricos de jogos',
  play_place: 'Lugar de brincar',
  can_not_get_play_detail: 'Não é possível obter detalhes da reprodução!',
  play_win: 'JOGUE E GANHE',

  // CODG 05/04
  locked_CODG_is_earned:
    'O CODG bloqueado é ganho por meio de bônus ou promoções específicas, como bônus de depósito.',
  unlocking_CODG_is_simple: `Desbloquear o CODG é simples; é semelhante ao rakeback e é desbloqueado proporcionalmente às suas apostas da seguinte forma:`,
  unlocking_formula:
    'Valor de desbloqueio = Valor da aposta * Margem da casa como decimal * X',
  conversion_formula: `Por exemplo, se você apostar $ 100, desbloqueará 0,2 CODG bloqueado.`,
  note_unlock_codg:
    'Nota: Para desbloquear o CODG, você precisa atingir pelo menos a classificação Bronze, o que requer 10.000 XP.',
  how_to_claim_COD: 'Como reivindicar COD',
  as_you_place_wagers:
    'À medida que você faz apostas, seu Piggy acumula CODG desbloqueado, que será então convertido em COD.',
  check_to_claim: 'Verifique para reivindicar',
  about_CODG_token: 'Sobre CODG Token',
  play_our_games_to_earn_CODG_for_free:
    'Jogue nossos jogos para ganhar CODG gratuitamente!',
  how_to_unlock: 'Como desbloquear',
  how_to_claim: 'Como reivindicar',
  today_biggest_winners: 'Hoje Os Maiores Vencedores',
  and_get_up_to: 'e levante-se para',
  to_get: 'obter',
  claim_now: 'Reivindique agora',
  learn_more: 'Aprenda mais',

  // Host 10/04
  welcome_to_satoshity: 'Bem-vindo ao satoshity.io',
  welcome_to_betgecko: 'Bem-vindo ao betgecko.io',
  welcome_to_bitwin: 'Bem-vindo ao bitwin.club',
  host_vip_club: `${process.env.REACT_APP_SITE} Clube VIP`,
  to_host_vip_club: `para ${process.env.REACT_APP_SITE} Clube VIP`,
  learn_vip: `Aprender mais sobre ${process.env.REACT_APP_SITE} Sistema VIP`,
  become_a_host: `Se tornar um ${process.env.REACT_APP_SITE}`,
  host_member: `Se tornar um ${process.env.REACT_APP_SITE} membro`,
  how_much_has_host_given_out_in_bonuses: `Quanto tem ${process.env.REACT_APP_SITE} distribuído em bônus?`,
  why_is_HOST_VIP_program_the_best: `Por que é ${process.env.REACT_APP_SITE} Programa VIP o melhor?`,
  HOST_VIP_telegram_channel: `Onde posso encontrar o ${process.env.REACT_APP_SITE} Canal VIP do Telegram?`,
  tip_with_any_currency_in_HOST: `Gorjeta com qualquer moeda em ${process.env.REACT_APP_SITE}`,
  turn_your_friends_and_followers_into_weekly_commissions_HOST: `Transforme seus amigos e seguidores em comissões semanais com ${process.env.REACT_APP_SITE}'s Programa de Afiliados. É simples, lucrativo e totalmente transparente`,
  why_should_i_be_a_HOST_affiliate: `Por que eu deveria ser um ${process.env.REACT_APP_SITE} Afiliado?`,
  if_you_already_have_a_HOST_account: `Se você já tem um ${process.env.REACT_APP_SITE} conta, basta seguir estas etapas:
      1. Visite a página do Afiliado.
      2. Gere uma nova campanha usando seu código de referência exclusivo.
      3. Compartilhe seu link de indicação em seu site, mídia social ou qualquer plataforma de alto tráfego.
      4. Reivindique sua comissão semanal dos usuários que você indicou.`,
  HOST_took_it_all: `${process.env.REACT_APP_SITE} levou tudo!`,
  there_are_various_ways_to_get_HOST_gift_codes: `Existem várias maneiras de obter ${process.env.REACT_APP_SITE} códigos de presente, como participação em eventos exclusivos de mídia social, atividades comunitárias, bônus ou missões especiais.`,
  what_is_HOST_giftcode: `'O que é ${process.env.REACT_APP_SITE} Código de presente?`,
  mini_HOST_is_Allways_3x3_Slot_machine_game: `Mini ${process.env.REACT_APP_SITE} é um jogo de caça-níqueis 3x3 de 27 linhas.`,
  the_above_third_party_HOST: `Os serviços de terceiros acima podem ser usados para comprar criptografia que pode ser usada para jogar ${process.env.REACT_APP_SITE}. Ao se registrar em sua plataforma, você também aceita seus termos de serviço e será obrigado a passar no processo KYC, que é executado de forma independente do nosso.`,
  what_is_investing_in_HOST_gamebank: `Em que está investindo ${process.env.REACT_APP_SITE} banco de jogo`,

  you_can_still_enjoy_the_best_casino_experience_at_satoshity: `Não se preocupe, você ainda pode aproveitar a MELHOR experiência de cassino em satoshity.io`,
  you_can_still_enjoy_the_best_casino_experience_at_betgecko: `Não se preocupe, você ainda pode aproveitar a MELHOR experiência de cassino em betgecko.io`,
  you_can_still_enjoy_the_best_casino_experience_at_bitwin: `Não se preocupe, você ainda pode aproveitar a MELHOR experiência de cassino em bitwin.club`,
  satoshity_game_is_not_available_in_your_region:
    'Desculpe, satoshity.io não está disponível em sua região.',
  betgecko_game_is_not_available_in_your_region:
    'Desculpe, betgecko.io não está disponível em sua região.',
  bitwin_game_is_not_available_in_your_region:
    'Desculpe, bitwin.club não está disponível em sua região.',
  how_to_get_satoshity_gift_code:
    'Como obter o código de presente satoshity.io?',
  how_to_get_betgecko_gift_code: 'Como obter o código de presente betgecko.io?',
  how_to_get_bitwin_gift_code: 'Como obter o código de presente bitwin.club?',
  how_to_use_satoshity_gift_code:
    'Como usar o código de presente satoshity.io?',
  how_to_use_betgecko_gift_code: 'Como usar o código de presente betgecko.io?',
  how_to_use_bitwin_gift_code: 'Como usar o código de presente bitwin.club?',
  use_your_COD_gift_to_bet_and_win_at_satoshity:
    'Use seu presente Satoshity para apostar e ganhar em satoshity.io',
  use_your_COD_gift_to_bet_and_win_at_betgecko:
    'Use seu presente Betgecko para apostar e ganhar em betgecko.io',
  use_your_COD_gift_to_bet_and_win_at_bitwin:
    'Use seu presente Bitwin para apostar e ganhar em bitwin.club',
  CODG_is_the_reward_token_of_satoshity:
    'CODG é o token de recompensa de satoshity.io. Quanto mais você se envolve com a plataforma, mais CODG você ganha para enriquecer seus ativos.',
  CODG_is_the_reward_token_of_bitwin:
    'CODG é o token de recompensa do bitwin.club. Quanto mais você se envolve com a plataforma, mais CODG você ganha para enriquecer seus ativos.',
  CODG_is_the_reward_token_of_betgecko:
    'CODG é o token de recompensa do betgecko.io. Quanto mais você se envolve com a plataforma, mais CODG você ganha para enriquecer seus ativos.',
  if_you_have_a_website_with_substantial_traffic_satoshity:
    'Se você tem um site com tráfego substancial ou uma conta de mídia social com um grande público, entre em contato conosco pelo e-mail business@satoshity.io para ofertas exclusivas.',
  if_you_have_a_website_with_substantial_traffic_betgecko:
    'Se você tem um site com tráfego substancial ou uma conta de mídia social com um grande público, entre em contato conosco pelo e-mail business@betgecko.io para ofertas exclusivas.',
  if_you_have_a_website_with_substantial_traffic_bitwin:
    'Se você tem um site com tráfego substancial ou uma conta de mídia social com um grande público, entre em contato conosco pelo e-mail business@bitwin.club para ofertas exclusivas.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_betgecko:
    'Devido à nossa licença de jogo, não podemos aceitar jogadores do seu país. Contate-nos através de support@Betgecko.io se precisar de mais assistência.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_satoshity:
    'Devido à nossa licença de jogo, não podemos aceitar jogadores do seu país. Contate-nos através de support@Satoshity.io se precisar de mais assistência.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_bitwin:
    'Devido à nossa licença de jogo, não podemos aceitar jogadores do seu país. Contate-nos através de support@Bitwin.club se precisar de mais assistência.',
  claim_successful: 'Reivindicação bem-sucedida',

  refer_description_coco:
    'Indique amigos para o Programa de Afiliados do coco.game e ganhe comissões para cada usuário que se registrar e jogar através de seus links, independentemente de seus resultados',
  refer_description_pi:
    'Indique amigos para o Programa de Afiliados do pi.game e ganhe comissões para cada usuário que se registrar e jogar através de seus links, independentemente de seus resultados',
  refer_description_ohplay:
    'Indique amigos para o Programa de Afiliados do ohplay.club e ganhe comissões para cada usuário que se registrar e jogar através de seus links, independentemente de seus resultados',
  refer_description_satoshity:
    'Indique amigos para o Programa de Afiliados do satoshity.io e ganhe comissões para cada usuário que se registrar e jogar através de seus links, independentemente de seus resultados',
  refer_description_betgecko:
    'Indique amigos para o Programa de Afiliados do betgecko.io e ganhe comissões para cada usuário que se registrar e jogar através de seus links, independentemente de seus resultados',
  refer_description_bitwin:
    'Indique amigos para o Programa de Afiliados do bitwin.club e ganhe comissões para cada usuário que se registrar e jogar através de seus links, independentemente de seus resultados',
  your_email_verified_coco:
    'Seu e-mail foi verificado. Vamos explorar coco.game.',
  your_email_verified_pi: 'Seu e-mail foi verificado. Vamos explorar pi.game.',
  your_email_verified_ohplay:
    'Seu e-mail foi verificado. Vamos explorar ohplay.club.',
  your_email_verified_satoshity:
    'Seu e-mail foi verificado. Vamos explorar satoshity.io.',
  your_email_verified_betgecko:
    'Seu e-mail foi verificado. Vamos explorar betgecko.io.',
  your_email_verified_bitwin:
    'Seu e-mail foi verificado. Vamos explorar bitwin.club.',
  GAME: 'Jogo',
  AFFILIATE: 'Afiliado',
  ACCOUNT: 'Conta',
  BONUS: 'Bônus',
  INVEST: 'Investir',
  SWAP: 'Trocar',
  deposit_reward: 'RECOMPENSA DE DEPÓSITO',
  roll_dice_trio: 'ROLAR DADOS TRIO',
  hit_1000: 'Acertar $ 1000',
  full_jackpot: 'JACKPOT COMPLETO',
  play_game2: 'Jogar um jogo',
  up_to: 'Até',
  for: 'para',
  free_5_usdt: 'GRÁTIS $ 5 USDT',
  non_deposited: 'NÃO DEPOSITADO',
  hands: 'Mãos',
  total_bet: 'Aposta total',
  deal: 'Negócio',
  bet_and_win: 'APOSTE E GANHE',
  daily_wager_tournament: 'TORNEIO DE APOSTAS DIÁRIAS',
  prize_pool: 'CONJUNTO DE PRÊMIOS',
  race_now: 'Corra agora',
  fuel_your_experience: 'Alimente sua experiência',
};

export default pt;
