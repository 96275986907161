import { SvgIcon } from '@mui/material';

export function IconHeart(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.4134 4.76153C19.3781 3.62561 17.9575 3 16.413 3C15.2585 3 14.2012 3.36922 13.2704 4.09733C12.8008 4.46486 12.3752 4.9145 12 5.43932C11.6249 4.91466 11.1992 4.46486 10.7294 4.09733C9.79877 3.36922 8.74149 3 7.58701 3C6.04251 3 4.62177 3.62561 3.58646 4.76153C2.56351 5.88418 2 7.41788 2 9.08032C2 10.7914 2.63034 12.3576 3.98364 14.0096C5.19427 15.4873 6.93423 16.9873 8.94916 18.7243C9.63718 19.3175 10.4171 19.9899 11.2268 20.7061C11.4408 20.8957 11.7153 21 12 21C12.2846 21 12.5592 20.8957 12.7729 20.7064C13.5826 19.99 14.363 19.3173 15.0513 18.7238C17.0659 16.9872 18.8059 15.4873 20.0165 14.0094C21.3698 12.3576 22 10.7914 22 9.08016C22 7.41788 21.4365 5.88418 20.4134 4.76153Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
