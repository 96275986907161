import { SvgIcon } from '@mui/material';

export function IconSlotMachine(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_734_27285)">
        <path
          d="M22.3374 3.31641C21.4248 3.31641 20.6828 4.05848 20.6828 4.97103C20.6828 5.73957 21.212 6.38187 21.9237 6.56688V14.0715H20.6828V9.52126C20.6828 8.83694 20.1261 8.28029 19.4418 8.28029H2.06812C1.38401 8.28029 0.827148 8.83694 0.827148 9.52126V21.5173H5.20632L6.03363 20.69H15.4765L16.3038 21.5173H20.6828V19.0354H21.5101C22.1944 19.0354 22.7511 18.4785 22.7511 17.7944V6.56688C23.463 6.38187 23.992 5.73957 23.992 4.97103C23.992 4.05848 23.25 3.31641 22.3374 3.31641ZM19.8554 19.0354C19.8554 19.4916 19.4844 19.8627 19.0281 19.8627H1.65447V9.1076H19.0281C19.4844 9.1076 19.8554 9.47864 19.8554 9.93491V19.0354Z"
          fill="#9998D4"
        />
        <path
          d="M16.2235 7.45249L17.7871 1.24764L13.6505 3.72958L10.7516 0L7.85924 3.72958L3.72266 1.24764L5.2864 7.45249H16.2235ZM14.8914 5.79786C15.1199 5.79786 15.3051 5.98308 15.3051 6.21151C15.3051 6.43975 15.1199 6.62517 14.8914 6.62517C14.6632 6.62517 14.4778 6.43975 14.4778 6.21151C14.4778 5.98308 14.6632 5.79786 14.8914 5.79786ZM10.7549 4.14323C11.4392 4.14323 11.9958 4.69989 11.9958 5.3842C11.9958 6.06831 11.4392 6.62517 10.7549 6.62517C10.0707 6.62517 9.51388 6.06831 9.51388 5.3842C9.51388 4.69989 10.0707 4.14323 10.7549 4.14323ZM6.61827 5.79786C6.84671 5.79786 7.03193 5.98308 7.03193 6.21151C7.03193 6.43975 6.84671 6.62517 6.61827 6.62517C6.39003 6.62517 6.20461 6.43975 6.20461 6.21151C6.20461 5.98308 6.39003 5.79786 6.61827 5.79786Z"
          fill="#9998D4"
        />
        <path
          d="M8.27295 18.2075H13.2369V19.0348H8.27295V18.2075Z"
          fill="#9998D4"
        />
        <path
          d="M2.48193 9.93457H7.44584V10.7619H2.48193V9.93457Z"
          fill="#9998D4"
        />
        <path
          d="M8.27295 17.3804H13.2369V9.93457H8.27295V17.3804ZM9.10027 11.5892H12.4095V12.5236L10.2891 16.3404L9.56604 15.9382L11.5226 12.4165H9.10027V11.5892Z"
          fill="#9998D4"
        />
        <path
          d="M14.0645 11.5894V19.0352H19.0288L19.0284 11.5894H14.0645ZM18.201 14.1783L16.0806 17.9952L15.3575 17.593L17.3141 14.0713H14.8918V13.244H18.201V14.1783Z"
          fill="#9998D4"
        />
        <path
          d="M14.0645 9.93457H19.0284V10.7619H14.0645V9.93457Z"
          fill="#9998D4"
        />
        <path
          d="M11.1686 5.38436C11.1686 5.6128 10.9834 5.79802 10.755 5.79802C10.5265 5.79802 10.3413 5.6128 10.3413 5.38436C10.3413 5.15592 10.5265 4.9707 10.755 4.9707C10.9834 4.9707 11.1686 5.15592 11.1686 5.38436Z"
          fill="#9998D4"
        />
        <path
          d="M21.0966 22.3442H15.9616L15.1343 21.5168H6.37616L5.54884 22.3442H0.413659C0.185419 22.3442 0 22.5298 0 22.7578V23.9988H21.5103V22.7578C21.5103 22.5298 21.325 22.3442 21.0966 22.3442ZM8.27317 23.1715H7.44586V22.3442H8.27317V23.1715ZM11.1688 23.1715H10.3415V22.3442H11.1688V23.1715ZM14.0644 23.1715H13.2371V22.3442H14.0644V23.1715Z"
          fill="#9998D4"
        />
        <path
          d="M2.48193 19.0352H7.44584V11.5894H2.48193V19.0352ZM3.30925 13.244H6.61852V14.1783L4.49812 17.9952L3.77502 17.593L5.73162 14.0713H3.30925V13.244Z"
          fill="#9998D4"
        />
      </g>
      <defs>
        <clipPath id="clip0_734_27285">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
