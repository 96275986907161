import { Box, styled } from '@mui/material';

export const AssetPopoverWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)} 0`,
  width: 448,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2, 2, 3),
  },
}));

export const CloseBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: '1/1',
  height: 20,
  button: {
    padding: 0,
  },
  right: theme.spacing(4.5),
  svg: {
    color: theme.palette.text.primary,
    transition: 'all ease-in-out 0.3s',
  },
  '&:hover svg': {
    color: theme.palette.text.contrast,
    transform: 'rotate(180deg)',
  },
  '&&': {
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(1.5),
    },
    '.MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
}));
