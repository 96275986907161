import { useSettingsContext } from 'components/settings';
import { CurrencyInput } from 'components/_Games/CurrencyField/styles';
import { useLocales } from 'locales';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputAdornment, Stack } from '@mui/material';

import { OptionInput, TitleControlGame, TitlePayout } from './styles';
import { FieldsBetProps } from './types';

// -------------------------------------------------------------------

const OnWin = (props: Partial<FieldsBetProps>) => {
  const { disabled, name } = props;
  const { control } = useFormContext();
  const [onWin, setOnWin] = useState(true);
  const { themeMode } = useSettingsContext();
  const { translate } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Stack direction="column" sx={{ pt: 1 }}>
          <TitlePayout>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
            >
              <TitleControlGame>{translate('on_win')}</TitleControlGame>
            </Stack>
          </TitlePayout>

          <Stack
            spacing={1}
            direction="row"
            sx={{
              padding: 0.4,
              borderRadius: (theme) => `${theme.shape.borderRadius}px`,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <OptionInput
                sx={{ width: 120 }}
                $click={onWin === true}
                variant="soft"
                themeMode={themeMode}
                fullWidth
                disabled={disabled}
                onClick={() => {
                  setOnWin(true);
                  onChange(true);
                }}
              >
                {translate('reset')}
              </OptionInput>
              <OptionInput
                $click={onWin === false}
                variant="soft"
                themeMode={themeMode}
                fullWidth
                disabled={disabled}
                onClick={() => {
                  setOnWin(false);
                  // onChange(0);
                }}
              >
                {translate('increase_by')}
              </OptionInput>
            </Stack>
            <CurrencyInput
              fullWidth
              disabled={disabled || onWin === true}
              size="small"
              autoComplete="off"
              onFocus={(event) => {
                event.target.select();
              }}
              type="number"
              value={value === true ? '0' : value}
              {...props}
              onChange={onWin === false && onChange}
              style={{
                WebkitTextFillColor:
                  onWin === true && 'rgba(145, 158, 171, 0.24)',
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                style: {
                  backgroundColor:
                    onWin === true ? 'rgba(145, 158, 171, 0.24)' : '',
                },
              }}
            />
          </Stack>
        </Stack>
      )}
    />
  );
};
export default OnWin;
