import Winner from 'components/_Events/_CrashEvent/Winner';
import {
  CrashPredictorEvent,
  CrashPredictorResult,
  FunCrashPredictorEvent,
  useCrashPredictorLazyQuery,
} from 'graph';
import lodashHelper from 'lodash';
import { memo, SetStateAction, useEffect, useMemo } from 'react';

interface Props {
  setData: React.Dispatch<
    SetStateAction<
      Pick<
        CrashPredictorEvent | FunCrashPredictorEvent,
        'end' | 'start' | 'crashPredictorResults'
      >
    >
  >;
  selectedDate: Array<Date | number>;
  gameId: string;
}

function CryptoContest({ gameId, setData, selectedDate }: Props) {
  const [getCrashPredictor, { data: crashPredictorData, loading }] =
    useCrashPredictorLazyQuery({
      variables: {
        date: selectedDate[0],
        gameId,
      },
    });

  const sortedCrashPredictorList = useMemo(
    () =>
      lodashHelper.orderBy(
        crashPredictorData?.crashPredictor?.crashPredictorResults || [],
        ['rank'],
        'asc',
      ),
    [crashPredictorData],
  );

  useEffect(() => {
    getCrashPredictor({
      variables: {
        date: selectedDate[0],
        gameId,
      },
      fetchPolicy: 'network-only',
    });
  }, [selectedDate[0]]);

  useEffect(() => {
    if (crashPredictorData) {
      setData({
        end: crashPredictorData?.crashPredictor?.end,
        start: crashPredictorData?.crashPredictor?.start,
        crashPredictorResults: crashPredictorData?.crashPredictor
          ?.crashPredictorResults as CrashPredictorResult[],
      });
    }
  }, [crashPredictorData]);

  return (
    <Winner
      data={sortedCrashPredictorList as CrashPredictorResult[]}
      myPredictor={crashPredictorData?.crashPredictor?.myPredictor}
      loading={loading}
    />
  );
}

export default memo(CryptoContest);
