import { Box, styled, TableContainer } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
  },
}));

export const WagerContestTableContainer = styled(TableContainer)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.shape.borderRadius,
    '.MuiTable-root': {
      minWidth: 'max-content',
    },
    '&& .MuiTableCell-head': {
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      ...theme.typography.subtitle2,
      fontWeight: 600,
      color: theme.palette.text.contrast,
      borderBottom: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        '& p': {
          fontSize: 12,
        },
      },
    },
    '.MuiTableCell-root': {
      ...theme.typography.subtitle2,
      fontWeight: 400,
      padding: theme.spacing(1.5, 3),
      color: theme.palette.text.contrast,
      [theme.breakpoints.up('xs')]: {
        '&.rank': {
          textAlign: 'left',
          minWidth: 20,
          maxWidth: '15%',
        },
        '&.player': {
          width: '30%',
          textAlign: 'center',
        },
        '&.wagered': {
          width: '25%',
          textAlign: 'right',
        },
        '&.prize': {
          width: '30%',
          textAlign: 'right',
        },
      },
      img: {
        height: 24,
        width: 24,
        '&.currency_image': {
          marginLeft: theme.spacing(1),
        },
        '&.avatar': {
          marginRight: theme.spacing(1),
          borderRadius: '50%',
          '&[alt=hidden]': {
            height: 16,
            width: 20,
          },
        },
        [theme.breakpoints.down('sm')]: {
          height: 16,
          width: 16,
          '&.currency_image': {
            marginLeft: theme.spacing(0.5),
          },
          '&.avatar': {
            marginRight: theme.spacing(0.5),
          },
        },
      },
      '&.rank': {
        fontWeight: 700,
        img: {
          aspectRatio: '24/29',
          width: 24,
          height: 'unset',
        },
        [theme.breakpoints.down('sm')]: {
          fontWeight: 500,
        },
      },
      '&.prize:not(th)': {
        color: theme.palette.primary.main,
      },
      '&.prize, &.wagered, .prize': {
        fontWeight: 600,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.25, 1.5),
        fontSize: 12,
        maxWidth: 70,
        h6: {
          fontSize: 12,
        },
        '&.rank': {
          img: {
            width: 16,
          },
          maxWidth: 10,
        },
      },
      '.user_avatar': {
        aspectRatio: '1/1',
        width: 24,
        borderRadius: '50%',
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          width: 16,
        },
      },
      '.user_name': {
        '&:first-letter': {
          textTransform: 'unset',
        },
      },
    },
    '.MuiTableRow-root.my_rank': {
      background: `linear-gradient(180deg, rgba(181, 124, 255, 0.20) 0%, rgba(87, 6, 191, 0.20) 47.40%, rgba(174, 117, 248, 0.20) 100%), #181C2A`,
      '.MuiTableCell-root': {
        p: {
          ...theme.typography.subtitle2,
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            fontWeight: 500,
            fontSize: 12,
          },
        },
        '.user_name': {
          '&:first-letter': {
            textTransform: 'capitalize',
          },
        },
      },
    },
  }),
);
