import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { IconBlockUser } from 'assets/icons/IconBlockUser';
import { IconUnlockUser } from 'assets/icons/IconUnlockUser';
import { IconUserUnknow } from 'assets/icons/IconUserUnknow';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { useUserInfoQuery } from 'graph';
import { useState } from 'react';

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import {
  Box,
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { idMods } from './ItemMessageBase';
import { ChatField } from './styles';

// -----------------------------------------------------------------

interface ChatWithMentionsProps {
  userRecent: Array<any>;
  setMessage: (e) => void;
  sendMessage: () => void;
  message: string;
}
interface Command {
  id?: number;
  name?: string;
  value?: string;
  nickname?: string;
  avatar?: any;
  image?: any;
}
export default function ChatWithMentions(props: ChatWithMentionsProps) {
  const { userRecent, setMessage, sendMessage, message } = props;
  const [mentionOptions, setMentionOptions] = useState<Command[]>([]);
  const [showPicker, setShowPicker] = useState(false);

  const { data: userInfoData } = useUserInfoQuery({
    fetchPolicy: 'cache-only',
  });

  const onEmojiClick = (emojiData) => {
    setMessage((prevInput: string) => prevInput + emojiData.native);
  };

  const commands = [
    {
      id: 1,
      name: '/ignore id',
      value: '/ignore ',
      image: <IconBlockUser />,
    },
    {
      id: 2,
      name: '/unignore id',
      value: '/unignore ',
      image: <IconUnlockUser />,
    },
  ];

  const findByIdMod = idMods.filter((e) => e === userInfoData?.me?.user?.id);
  const handleInputChange = async (newInputValue: string) => {
    setMessage(newInputValue);
    const wordsArray = newInputValue.split(' ');
    const lastWord = wordsArray[wordsArray.length - 1];
    if (lastWord === '@') {
      return setMentionOptions(userRecent);
    }
    if (
      newInputValue.startsWith('/ignore') ||
      newInputValue.startsWith('/unignore')
    ) {
      setMentionOptions([]);
    } else if (newInputValue[0] === '/') {
      setMentionOptions(commands);
    } else {
      setMentionOptions([]);
    }
  };

  const handleSendMessage = async () => {
    const idUser = message.match(/\d+/)?.pop();
    const listIgnoreUser = JSON.parse(localStorage.getItem('ignoreUser'));

    if (message.trim().startsWith('/ignore')) {
      if (listIgnoreUser !== null) {
        const newListIgnoreUser = [...listIgnoreUser, idUser];
        localStorage.setItem('ignoreUser', JSON.stringify(newListIgnoreUser));
      } else {
        localStorage.setItem('ignoreUser', JSON.stringify([idUser]));
      }
      setMessage('');
      return;
    }
    if (message.trim().startsWith('/unignore')) {
      const newListIgnoreUser = listIgnoreUser.filter((obj) => obj !== idUser);
      localStorage.setItem('ignoreUser', JSON.stringify(newListIgnoreUser));
      setMessage('');
      return;
    }
    // if (message.trim().startsWith('/pinMessage')) {
    //   const modifiedString = message.replace('/pinMessage', '');
    //   localStorage.setItem('pinMessage', modifiedString);
    //   return;
    // }

    sendMessage();
    setMessage('');
  };
  const handleSelectRecentUser = (option) => {
    if (option.nickname) {
      setMessage((state) => {
        const lastAtIdx = state.lastIndexOf('@');
        const updatedMessage =
          lastAtIdx !== -1
            ? `${state.slice(0, lastAtIdx)}@${option.nickname} `
            : `@${option.nickname} `;

        return updatedMessage;
      });
    } else {
      setMessage(option.value);
    }
    setMentionOptions([]);
  };
  const handleKeyDown = async (event) => {
    if (event.key === '/') {
      const currentInputValue = event.target.value || '';
      await handleInputChange(`${currentInputValue}/`);
    }
    if (event.key === 'Enter') {
      if (mentionOptions.length > 0) {
        setMentionOptions([]);
        return;
      }
      handleSendMessage();
      event.preventDefault();
    }
  };
  return (
    <>
      {showPicker && (
        <ClickAwayListener onClickAway={() => setShowPicker(!showPicker)}>
          <Box
            style={{
              position: 'absolute',
              bottom: '450px',
              right: '40px',
              zIndex: 9999,
            }}
          >
            <Picker
              data={data}
              onEmojiSelect={onEmojiClick}
              theme="dark"
              previewPosition="top"
            />
          </Box>
        </ClickAwayListener>
      )}
      <ChatField width="100%" display="flex" flexDirection="row">
        <Autocomplete
          fullWidth
          popupIcon=""
          freeSolo
          options={mentionOptions}
          value={message}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.value) {
              return option.value;
            }
            if (option.nickname) {
              const lastAtIdx = message.lastIndexOf('@');
              const updatedMessage =
                lastAtIdx !== -1
                  ? `${message.slice(0, lastAtIdx)}@${option.nickname} `
                  : `@${option.nickname} `;

              return updatedMessage;
            }
          }}
          onInputChange={(event, value) => value && setMessage(value)}
          open={mentionOptions.length > 0}
          getOptionDisabled={(option) => !mentionOptions.includes(option)}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => handleInputChange(e.target.value)}
              onKeyDown={handleKeyDown}
              value={message}
              multiline
              InputProps={{
                ...params.InputProps,
                inputProps: {
                  ...params.inputProps,
                  maxLength: findByIdMod.length > 0 ? 9999 : 160,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box display="flex" flexDirection="row" gap={1}>
                      <SentimentSatisfiedAltIcon
                        sx={{
                          color: '#858997',
                          ':hover': {
                            cursor: 'pointer',
                          },
                        }}
                        onClick={() => {
                          setShowPicker(!showPicker);
                        }}
                      />
                    </Box>
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '12px !important',
                  color: 'white',
                  alignContent: 'center',
                  pr: '12px !important',
                  background: (theme) => theme.palette.background.main,
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                background: '#262936',
                maxHeight: 250,
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <MenuItem
                {...props}
                key={option.id}
                value={option.nickname ? `@${option.nickname} ` : option.value}
                onClick={() => handleSelectRecentUser(option)}
              >
                {option.nickname ? (
                  <Box display="flex" flexDirection="row" gap={2} py={1}>
                    {option.avatar?.square ? (
                      <Box
                        component="img"
                        src={option.avatar.square}
                        width={24}
                        borderRadius={19}
                      />
                    ) : (
                      <IconUserUnknow />
                    )}
                    <Typography color="white">
                      {`@${option.nickname}`}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="row" gap={2} py={1}>
                    {/* <ArrowCircleRightIcon /> */}
                    {option.image}
                    <Typography color="white">{option.name}</Typography>
                  </Box>
                )}
                {/* <Box display="flex" flexDirection="row" gap={2} py={1}>

                  <Typography color="white">@{option.nickname}</Typography>
                </Box> */}
              </MenuItem>
            </Box>
          )}
        />
        <Box
          id="chat-send-btn"
          onClick={() => {
            sendMessage();
            setMessage('');
          }}
        >
          <SendIcon />
        </Box>
      </ChatField>
    </>
  );
}
