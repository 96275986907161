import { SvgIcon } from '@mui/material';

export function IconQuestionMessage(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M11.3334 1.62H4.66671C2.66671 1.62 1.33337 2.95333 1.33337 4.95333V8.95333C1.33337 10.9533 2.66671 12.2867 4.66671 12.2867V13.7067C4.66671 14.24 5.26004 14.56 5.70004 14.26L8.66671 12.2867H11.3334C13.3334 12.2867 14.6667 10.9533 14.6667 8.95333V4.95333C14.6667 2.95333 13.3334 1.62 11.3334 1.62ZM8.00004 9.73333C7.72004 9.73333 7.50004 9.50666 7.50004 9.23333C7.50004 8.95999 7.72004 8.73333 8.00004 8.73333C8.28004 8.73333 8.50004 8.95999 8.50004 9.23333C8.50004 9.50666 8.28004 9.73333 8.00004 9.73333ZM8.84004 6.96666C8.58004 7.13999 8.50004 7.25333 8.50004 7.43999V7.57999C8.50004 7.85333 8.27337 8.07999 8.00004 8.07999C7.72671 8.07999 7.50004 7.85333 7.50004 7.57999V7.43999C7.50004 6.66666 8.06671 6.28666 8.28004 6.13999C8.52671 5.97333 8.60671 5.85999 8.60671 5.68666C8.60671 5.35333 8.33337 5.07999 8.00004 5.07999C7.66671 5.07999 7.39337 5.35333 7.39337 5.68666C7.39337 5.95999 7.16671 6.18666 6.89337 6.18666C6.62004 6.18666 6.39337 5.95999 6.39337 5.68666C6.39337 4.79999 7.11337 4.07999 8.00004 4.07999C8.88671 4.07999 9.60671 4.79999 9.60671 5.68666C9.60671 6.44666 9.04671 6.82666 8.84004 6.96666Z"
        fill="#53BA0C"
      />
    </SvgIcon>
  );
}
