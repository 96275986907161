import { SvgIcon, useTheme } from '@mui/material';

export function IconEyeSlash(props: any) {
  const theme = useTheme();
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.1796 6.12003C13.9863 5.81337 13.7796 5.5267 13.5663 5.26003C13.3196 4.9467 12.853 4.92003 12.573 5.20003L10.573 7.20003C10.7196 7.64003 10.7463 8.1467 10.613 8.67337C10.3796 9.61337 9.61964 10.3734 8.67964 10.6067C8.15297 10.74 7.64631 10.7134 7.20631 10.5667C7.20631 10.5667 6.25297 11.52 5.56631 12.2067C5.23297 12.54 5.33964 13.1267 5.78631 13.3C6.49964 13.5734 7.23964 13.7134 7.99964 13.7134C9.18631 13.7134 10.3396 13.3667 11.393 12.72C12.4663 12.0534 13.433 11.0734 14.213 9.8267C14.8463 8.82003 14.813 7.1267 14.1796 6.12003Z"
        fill={theme.palette.text.contrast}
      />
      <path
        d="M9.34708 6.65326L6.65375 9.3466C6.31375 8.99993 6.09375 8.51993 6.09375 7.99993C6.09375 6.95326 6.94708 6.09326 8.00042 6.09326C8.52042 6.09326 9.00042 6.31326 9.34708 6.65326Z"
        fill="white"
      />
      <path
        d="M12.1673 3.83329L9.90732 6.09329C9.42065 5.59995 8.74732 5.30662 8.00065 5.30662C6.50732 5.30662 5.30732 6.51329 5.30732 7.99995C5.30732 8.74662 5.60732 9.41995 6.09398 9.90662L3.84065 12.1666H3.83398C3.09398 11.5666 2.41398 10.8 1.83398 9.89329C1.16732 8.84662 1.16732 7.14662 1.83398 6.09995C2.60732 4.88662 3.55398 3.93329 4.60732 3.27995C5.66065 2.63995 6.81398 2.28662 8.00065 2.28662C9.48732 2.28662 10.9273 2.83329 12.1673 3.83329Z"
        fill={theme.palette.text.contrast}
      />
      <path
        d="M9.90672 8.00005C9.90672 9.04672 9.05339 9.90672 8.00005 9.90672C7.96005 9.90672 7.92672 9.90672 7.88672 9.89339L9.89339 7.88672C9.90672 7.92672 9.90672 7.96005 9.90672 8.00005Z"
        fill={theme.palette.text.contrast}
      />
      <path
        d="M14.5126 1.48643C14.3126 1.28643 13.9859 1.28643 13.7859 1.48643L1.48594 13.7931C1.28594 13.9931 1.28594 14.3198 1.48594 14.5198C1.58594 14.6131 1.7126 14.6664 1.84594 14.6664C1.97927 14.6664 2.10594 14.6131 2.20594 14.5131L14.5126 2.20643C14.7193 2.00643 14.7193 1.68643 14.5126 1.48643Z"
        fill={theme.palette.text.contrast}
      />
    </SvgIcon>
  );
}
