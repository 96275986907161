import { IconSearchFile } from 'assets/icons';
import currencyImages from 'assets/images/coins';
import LoadingAnimated from 'components/LoadingAnimated';
import { format } from 'date-fns';
import { DepositStatus, WithdrawalStatus } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { useLocales } from 'locales';
import { useState } from 'react';

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import DetailTransactionDialog from './DetailTransactionDialog';
import {
  StyledTableCell,
  StyledTableHeaderSticker,
  StyledTableRow,
  TransactionTableBox,
} from './styles';
import { TransactionTabKey } from './TransactionsDialog';

// =================================================================

interface PropsType {
  rows: any[];
  handleLoadMore: (event) => void;
  loading: boolean;
  selectedTab: {
    key: string;
    icon: JSX.Element;
  };
}

export const DepositNWithdrawTransactionTable = ({
  rows,
  handleLoadMore,
  loading,
  selectedTab,
}: PropsType) => {
  const { translate } = useLocales();
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const [transactionDetail, setTransactionDetail] = useState(null);
  const [isDetailTransactionOpen, setIsDetailTransactionOpen] = useState(false);

  const handleOpenTransactionDetail = (row) => {
    setIsDetailTransactionOpen(true);
    setTransactionDetail(row);
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case DepositStatus.Completed || WithdrawalStatus.Completed:
        return translate('success');
      case DepositStatus.New && selectedTab.key === TransactionTabKey.DEPOSIT:
        return translate('processing');
      case WithdrawalStatus.Credit:
        return translate('audit');
      case WithdrawalStatus.Insufficient:
        return translate('insufficient');

      // TODO: update when confirm from MR THANH
      case WithdrawalStatus.New &&
        selectedTab.key === TransactionTabKey.WITHDRAW:
        return translate('new');
      case WithdrawalStatus.Processing:
        return translate('processing');
      default:
        return '';
    }
  };

  return (
    <TransactionTableBox>
      <StyledTableHeaderSticker>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" width="30%">
                {translate('time')}
              </StyledTableCell>
              <StyledTableCell align="right" width="25%">
                {translate('amount')}
              </StyledTableCell>
              <StyledTableCell align="center" width="30%">
                {translate('status')}
              </StyledTableCell>
              <StyledTableCell align="center" width="15%">
                {translate('detail')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </StyledTableHeaderSticker>
      <TableContainer
        sx={{
          borderTopLeftRadius: '0px !important',
          borderTopRightRadius: '0px !important',
        }}
        onScroll={handleLoadMore}
      >
        <Table>
          <TableBody>
            {rows &&
              rows.map(({ equivalentUsdRate, ...row }) => {
                const isStatusSuccess =
                  row.status === WithdrawalStatus.Completed;
                return (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row" width="30%">
                      <div>
                        <Typography
                          style={{ color: 'white' }}
                          whiteSpace="pre-wrap"
                        >
                          {format(new Date(row.createdAt), 'MM/dd/yyyy')}
                          <Typography
                            component="span"
                            whiteSpace="pre-wrap"
                            color="text.primary"
                          >
                            {format(new Date(row.createdAt), ' h:mm:ss aa')}
                          </Typography>
                        </Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      width="25%"
                      style={{ color: 'white' }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography>
                          {getCurrencyAmount(row.amount, {
                            ...row.currency,
                            equivalentUsdRate,
                          })}
                        </Typography>
                        <img
                          className="coin_img"
                          src={currencyImages[row.currency.shortName]}
                          alt={row.currency.name}
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                      width="30%"
                      className={
                        !isStatusSuccess ? 'status_pending' : 'status_success'
                      }
                    >
                      {getStatusLabel(row.status)}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="15%">
                      <IconButton
                        onClick={() =>
                          handleOpenTransactionDetail({
                            ...row,
                            equivalentUsdRate,
                          })
                        }
                        className="view_detail"
                      >
                        <IconSearchFile />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            {loading && (
              <TableRow>
                <StyledTableCell colSpan={4}>
                  <LoadingAnimated />
                </StyledTableCell>
              </TableRow>
            )}
            {!rows?.length && !loading && (
              <TableRow>
                <StyledTableCell colSpan={4} align="center">
                  {translate('empty_data')}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {isDetailTransactionOpen && (
          <DetailTransactionDialog
            isOpen={isDetailTransactionOpen}
            handleClose={() => setIsDetailTransactionOpen(false)}
            transaction={transactionDetail}
            selectedTab={selectedTab}
          />
        )}
      </TableContainer>
    </TransactionTableBox>
  );
};
