import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CurrencyInput = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.main,

  border: 'none',
  '&:hover': {
    backgroundColor: theme.palette.background.overlay,
    border: 0,
  },

  '& .MuiOutlinedInput-root': {
    border: 'none !important',
    borderRadius: theme.shape.borderRadius,
    '&.Mui-focused fieldset': {
      borderColor: '#FFE500',
    },
    input: {
      color: theme.palette.text.contrast,
    },
  },
}));
