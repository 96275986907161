import isPropValid from '@emotion/is-prop-valid';

import { Box, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LatestBetBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.secondary,
  borderRadius: theme.shape.borderRadius,
}));

export const BetResultContainerWrapper = styled(TableContainer, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<any>(({ theme, $shadowLastRow }) => ({
  backgroundColor: 'transparent',
  borderRadius: '12px',
  zIndex: 0,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  minHeight: 270,
  [theme.breakpoints.down('sm')]: {
    minHeight: 170,
  },
  ...($shadowLastRow && {
    '.bet-table-wrapper::after': {
      content: `""`,
      position: 'absolute',
      left: 0,
      right: 0,
      height: '50px',
      bottom: '50px',
      backgroundImage:
        'linear-gradient(180deg, rgba(27, 25, 62, 0) 0%, #161432 100%)',
    },
  }),
  '.MuiTableRow-head .MuiTableCell-root': {
    color: theme.palette.text.main,
    backgroundImage: 'unset',
    backgroundColor: '#2F2B60',
    fontWeight: 600,
    fontSize: 14,
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '&:last-child': {
      borderBottomRightRadius: '12px',
      borderTopRightRadius: '12px',
    },
  },
  '.MuiTableBody-root .MuiTableRow-root': {
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.secondary,
      borderRadius: theme.shape.borderRadius,
    },
    transition: 'all ease 0.15s',
    '& td:first-of-type': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '& td:last-child': {
      borderBottomRightRadius: '12px',
      borderTopRightRadius: '12px',
    },
  },
  '.MuiTableBody-root': {
    '.MuiTableCell-root': {
      border: 'none',
      backgroundColor: theme.palette.background.secondary,
      p: {
        fontWeight: 600,
      },
      '& .multiplier': {
        color: '#fff',
        padding: theme.spacing(0, 1.5),
        backgroundColor: '#2F2B60',
        width: 'fit-content',
        borderRadius: theme.shape.borderRadius,
        margin: '0 auto',
      },
      '&.time p': {
        textTransform: 'uppercase',
      },
      '&.increase p': {
        color: '#53BA0C',
      },
      '&.decrease p': {
        color: '#FF7A00',
      },
      '& .user_name, & .profit, & .bet_amount, & .jackpot_amount': {
        fontWeight: 600,
      },
      '& .profit, & .profit p': {
        minWidth: 'fit-content',
      },
      img: {
        height: 24,
        width: 24,
        '&.currency_image': {
          marginLeft: theme.spacing(1),
        },
        '&.avatar': {
          marginRight: theme.spacing(1),
          borderRadius: '50%',
          '&[alt=hidden]': {
            height: 16,
            width: 20,
          },
        },
        [theme.breakpoints.down('sm')]: {
          height: 16,
          width: 16,
          '&.currency_image': {
            marginLeft: theme.spacing(0.5),
          },
          '&.avatar': {
            marginRight: theme.spacing(0.5),
          },
        },
      },
    },
  },
  '.MuiTableCell-root': {
    border: 'none',
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 0.5),
      p: {
        fontSize: 12,
      },
    },
  },
}));
