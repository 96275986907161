import { useReactiveVar } from '@apollo/client';
import { IconDollar } from 'assets/icons';
import coinImages from 'assets/images/coins';
import { CurrencyInput } from 'components/_Games/CurrencyField/styles';
import { useLocales } from 'locales';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { storageCurrencyVar, viewInFiatVar } from 'store';

import { InputAdornment, Stack } from '@mui/material';

import { formatAmount } from '../betControl';
import { SubTitleControlGame, TitleControlGame, TitlePayout } from './styles';
import { FieldsBetProps } from './types';

// ------------------------------------------------------------------

const StopOnLose = (props: Partial<FieldsBetProps>) => {
  const { disabled, subtitle, name, defaultValue } = props;
  const { translate } = useLocales();
  const { control } = useFormContext();
  const { viewInFiat } = useReactiveVar(viewInFiatVar);
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const [formattedAmount, setFormattedAmount] = useState('');

  useEffect(() => {
    setFormattedAmount(formatAmount(defaultValue));
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Stack direction="column" sx={{ pt: 1 }}>
          <TitlePayout>
            {/* title */}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
            >
              <TitleControlGame>{translate('stop_on_lose')}</TitleControlGame>
            </Stack>
            {/* subtitle */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}
            >
              <SubTitleControlGame>{subtitle}</SubTitleControlGame>
            </Stack>
          </TitlePayout>

          <Stack spacing={1} direction="row">
            <CurrencyInput
              fullWidth
              disabled={disabled}
              size="small"
              autoComplete="off"
              onFocus={(event) => {
                event.target.select();
              }}
              // type="number"
              value={formattedAmount}
              onChange={(event) => {
                if (!Number.isNaN(Number(event.target.value))) {
                  onChange(event.target.value.trim());
                  setFormattedAmount(event.target.value.trim());
                }
              }}
              onBlur={() => {
                setFormattedAmount(formatAmount(value));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {viewInFiat.status === true &&
                    storageCurrency?.equivalentUsdRate !== 0 ? (
                      <IconDollar />
                    ) : (
                      <img
                        src={coinImages[storageCurrency?.shortName]}
                        alt="coin"
                        width={24}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              {...props}
              // onChange={onChange}
            />
          </Stack>
        </Stack>
      )}
    />
  );
};
export default StopOnLose;
