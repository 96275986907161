import Iconify from 'components/iconify';
import { CurrencyInput } from 'components/_Games/CurrencyField/styles';
import { useLocales } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';

import { InputAdornment, Stack } from '@mui/material';

import {
  ButtonSuffix,
  SubTitleControlGame,
  TitleControlGame,
  TitlePayout,
} from './styles';
import { FieldsBetProps } from './types';

const CashoutAt = (props: Partial<FieldsBetProps>) => {
  const { disabled, subtitle, name, title, maxWinRate } = props;
  const { control } = useFormContext();
  const { translate } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Stack direction="column">
          <TitlePayout>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
            >
              <TitleControlGame>
                {title
                  ? translate('target_multiplier')
                  : translate('cashout_at')}
              </TitleControlGame>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}
            >
              <SubTitleControlGame>{subtitle}</SubTitleControlGame>
            </Stack>
          </TitlePayout>

          <Stack spacing={1} direction="row">
            <CurrencyInput
              fullWidth
              disabled={disabled}
              size="small"
              autoComplete="off"
              // type="number"
              value={value}
              onChange={(event) => {
                if (!Number.isNaN(Number(event.target.value))) {
                  onChange(event.target.value.trim());
                }
              }}
              onBlur={() => {
                if (+value < 1.01) {
                  return onChange(1.01);
                }
                if (maxWinRate && +value >= maxWinRate) {
                  return onChange(Number(maxWinRate).toFixed(2));
                }
                onChange(Number(value).toFixed(2));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction="column" spacing={0.3}>
                      <ButtonSuffix
                        onClick={() => {
                          const increase: number = +value + 1;
                          onChange(+increase.toFixed(2));
                        }}
                        disabled={disabled}
                      >
                        <Iconify icon="icon-park-outline:up" width={15} />
                      </ButtonSuffix>
                      <ButtonSuffix
                        onClick={() => {
                          if (+value - 1 < 1.01) return;
                          const decrease: number = +value - 1;
                          onChange(+decrease.toFixed(2));
                        }}
                        disabled={disabled}
                      >
                        <Iconify icon="icon-park-outline:down" width={15} />
                      </ButtonSuffix>
                    </Stack>
                  </InputAdornment>
                ),
                // defaultValue: defaultValue,
              }}
              {...props}
            />
          </Stack>
        </Stack>
      )}
    />
  );
};
export default CashoutAt;
