import { TabsStyled } from 'common/Tabs';

import { Tab } from '@mui/material';

interface IBetHistoriesTabsProps<TabValue> {
  selectedTab: TabValue;
  disabled: boolean;
  onChangeTab: (tab: TabValue) => void;
  tabs: TabsType<TabValue>;
}

export const BetHistoriesTabs = <TabValue extends string>({
  tabs,
  disabled,
  selectedTab,
  onChangeTab,
}: IBetHistoriesTabsProps<TabValue>) => (
  <TabsStyled
    disabled
    sx={{ width: { sm: 500 }, alignSelf: 'center' }}
    scrollButtons={false}
    value={selectedTab}
    onChange={(_e, selectedTab) => onChangeTab(selectedTab)}
  >
    {tabs.map((tab) => (
      <Tab
        key={tab.value}
        label={tab.label}
        value={tab.value}
        disabled={tab.value !== selectedTab && disabled}
      />
    ))}
  </TabsStyled>
);
