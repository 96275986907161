enum GAME_ENUM {
  COIN_FLIP = 'coin-flip',
  MINES = 'mines',
  CRASH = 'crash',
  DICE = 'dice',
  LIMBO = 'limbo',
  VIDEO_POKER = 'videopoker',
}

enum BET_CONTROL_MODE {
  MANUAL = 'manual',
  AUTO = 'auto',
}

enum GAME_CONTROL_LAYOUT {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

enum BET_CONTROL_ELEMENT {
  BET_AMOUNT = 'betControl.betAmount',
  BET_MODE_TAB = 'betControl.betModeTab',
  BET_STATE_BTN = 'betControl.betStateBtn',
  INC_ON_LOSS = 'betControl.increaseOnLoss',
  INC_ON_WIN = 'betControl.increaseOnWin',
  STOP_ON_LOSS = 'betControl.stopOnLoss',
  STOP_ON_WIN = 'betControl.stopOnWin',
  NUMBER_OF_BETS = 'betControl.numberOfBets',
  CASHOUT_AT = 'betControl.cashoutAt',
  COIN_FLIP_INSTANT_DESCRIPTION = 'coinFlipDescription',
  COIN_FLIP_PICK_SIDE = 'coinflipPickSide',
  DICE_WIN_AMOUNT = 'betControl.diceWinAmount',
  MINES_MANUAL_BET = 'minesManualBet',
  MINES_SELECT_MINES_COUNT = 'numberOfMines',
  MINES_PICK_RANDOM = 'minesPickRandom',
  VIDEO_POKER_FIELDS = 'videoPokerFields',
}

enum GAME_FOOTER_ELEMENT {
  FAIRNESS = 'footer.fairness',
  SOUND = 'footer.sound',
  HELP = 'footer.help',
  TURBO = 'footer.turbo',
  LIKE = 'footer.like',
  FAVORITE = 'footer.favorite',
  LIVE_STATS = 'footer.liveStats',
}

export {
  GAME_ENUM,
  BET_CONTROL_MODE,
  BET_CONTROL_ELEMENT,
  GAME_CONTROL_LAYOUT,
  GAME_FOOTER_ELEMENT,
};
