import Iconify from 'components/iconify';
import {
  commonBetStateBtn,
  commonStateBtnStyle,
} from 'components/_Games/game-control-v2/bet-control/components/bet-state-btn';
import {
  BET_CONTROL_ELEMENT,
  GAME_CONTROL_LAYOUT,
  GAME_FOOTER_ELEMENT,
} from 'components/_Games/game-control-v2/constant';
import { CoinFlipBetData } from 'graph';
import { coinFlipHeadSide } from 'overrides/images/coin-flip-head';
import { coinFlipTailSide } from 'overrides/images/coin-flip-tail';
import { CoinflipBetFormType, COIN_FLIP_MODE } from 'pages/Games/CoinFlip/type';
import { DeepPartial } from 'react-hook-form';

export const coinFlipAssets = {
  coinHead: coinFlipHeadSide,
  coinTail: coinFlipTailSide,
  popupWinBg: '/assets/coinFlip/popup_win_bg.png',
};

export const springAnim = {
  type: 'spring',
  stiffness: 300,
  damping: 20,
};

export const coinFlipSlugByEnv =
  process.env.REACT_APP_SITE === 'pi' ? 'pi-flip' : 'coinflip';

export const coinFlipAnimDuration = 7 * 0.15; // in seconds

export const coinFlipAnimConf = {
  head: {
    rotateY: [1080, 900, 720, 540, 360, 180, 0],
    zIndex: [0, 1, 0, 1, 0, 1, 0],
    scale: [1, 1.15, 1.25, 1.35, 1.25, 1.15, 1],
    times: [0, 0.1, 0.25, 0.35, 0.5, 0.65, 1],
    duration: coinFlipAnimDuration,
  },
  tail: {
    rotateY: [1080, 900, 720, 540, 360, 180, 0, -180],
    zIndex: [0, 1, 0, 1, 0, 1, 0, 1],
    scale: [1, 1.15, 1.25, 1.35, 1.25, 1.15, 1],
    duration: coinFlipAnimDuration,
    times: [0, 0.1, 0.25, 0.35, 0.5, 0.65, 1],
  },
};

export const defaultCoinFlipConfig: DeepPartial<
  GameControlFormType<CoinFlipBetData, CoinflipBetFormType>
> = {
  betControl: {
    manualElements: [
      BET_CONTROL_ELEMENT.BET_AMOUNT,
      BET_CONTROL_ELEMENT.BET_STATE_BTN,
    ],
    autoElements: [
      BET_CONTROL_ELEMENT.BET_AMOUNT,
      BET_CONTROL_ELEMENT.COIN_FLIP_PICK_SIDE,
      BET_CONTROL_ELEMENT.NUMBER_OF_BETS,
      BET_CONTROL_ELEMENT.INC_ON_WIN,
      BET_CONTROL_ELEMENT.STOP_ON_WIN,
      BET_CONTROL_ELEMENT.INC_ON_LOSS,
      BET_CONTROL_ELEMENT.STOP_ON_LOSS,
    ],
    betAmount: {
      hideSlider: true,
    },
  },
  header: { hide: true },
  footer: {
    left: [GAME_FOOTER_ELEMENT.LIKE],
    right: [
      GAME_FOOTER_ELEMENT.TURBO,
      GAME_FOOTER_ELEMENT.LIVE_STATS,
      GAME_FOOTER_ELEMENT.HELP,
    ],
  },
  layoutDirection: GAME_CONTROL_LAYOUT.VERTICAL,
  gameState: {
    series: 0,
    betMode: COIN_FLIP_MODE.MULTIPLY,
    currentMultiplier: 0,
    nextMultiplier: 0,
    isShowAutoBetSetup: false,
    autoBetCoinSide: null,
    flippedRounds: [],
    flipCoinFn: () => {},
  },
};

export const coinflipStatesBtn = {
  ...commonBetStateBtn,
  chooseCoinSide: {
    ...commonStateBtnStyle,
    disabled: true,
    title: 'Choose Head or Tail',
    backgroundColor: 'background.hovering',
  },
  autoBetSetup: {
    ...commonStateBtnStyle,
    title: 'Auto Bet Setup',
    backgroundColor: 'background.hovering',
    startIcon: (
      <Iconify
        icon="icon-park-outline:setting-config"
        sx={{
          color: 'background.main !important',
        }}
      />
    ),
  },
};
