import { SvgIcon } from '@mui/material';

export function IconFB(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_6403_139315)">
        <path
          d="M40 20C40 8.95407 31.0459 0 20 0C8.95407 0 0 8.95407 0 20C0 29.9822 7.31259 38.2578 16.8741 39.757V25.7807H11.7956V20H16.8741V15.5941C16.8741 10.5807 19.8607 7.81333 24.4296 7.81333C26.6193 7.81333 28.9067 8.20444 28.9067 8.20444V13.1259H26.3852C23.9022 13.1259 23.1259 14.6667 23.1259 16.2489V20H28.6726L27.7867 25.7807H23.1259V39.757C32.6874 38.2578 40 29.9822 40 20Z"
          fill="#858997"
        />
        <path
          d="M27.7836 25.7808L28.6695 20H23.1229V16.2489C23.1229 14.6667 23.8962 13.1259 26.3821 13.1259H28.9036V8.20447C28.9036 8.20447 26.6162 7.81335 24.4266 7.81335C19.8577 7.81335 16.871 10.5808 16.871 15.5941V20H11.7925V25.7808H16.871V39.7571C17.8903 39.9171 18.9332 40 19.9969 40C21.0606 40 22.1036 39.9171 23.1229 39.7571V25.7808H27.7836Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6403_139315">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
