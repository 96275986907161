import Box from '@mui/material/Box';

import { NavSectionProps } from '../types';
import NavigationItem from './NavItem';
import { NavigationWrapper } from './styles';

/* -------------------------------------------------------------------------- */

interface Props extends NavSectionProps {
  isNavOpen?: boolean;
  handleToggleNav: VoidFunction;
}

export default function NavSectionMini({ data }: Props) {
  // Language

  return (
    <NavigationWrapper id="nav-mini">
      <div>
        <Box className="nav__box">
          {data?.map((navItem) => (
            <NavigationItem
              navItem={navItem}
              key={navItem.key || navItem.type}
            />
          ))}
        </Box>
      </div>
    </NavigationWrapper>
  );
}
