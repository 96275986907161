import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

export default function NetworkStatus() {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const onOffline = () => {
    enqueueSnackbar(translate('lost_connect_to_network'), { variant: 'error' });
  };

  const onOnline = () => {
    enqueueSnackbar(translate('internet_connection_restored'), {
      variant: 'success',
    });
  };

  useEffect(() => {
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  return null;
}
