import {
  liveStatsVar,
  showInsufficientBalancePopoverVar,
  showOverGameBankPopoverVar,
} from 'store';
import addLiveStatsData from 'store/operations/games-opts/addLiveStatsData';
import showInsufficientBalancePop from 'store/operations/games-opts/showInsufficientBalancePop';
import showOverGameBankPop from 'store/operations/games-opts/showOverGameBankPop';

export const gamesLocalStateOperations = {
  addLiveStats: addLiveStatsData(liveStatsVar),
  showInsufficientBalancePopOver: showInsufficientBalancePop(
    showInsufficientBalancePopoverVar,
  ),
  showOverGameBankPopover: showOverGameBankPop(showOverGameBankPopoverVar),
};
