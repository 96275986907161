import { ThemedButton } from 'common/Button/style';

import { keyframes, styled } from '@mui/material';

export const BetStateButtonStyle = styled(ThemedButton)(({ theme }) => ({
  minHeight: 48,
  marginTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0.5),
  border: 0,
  width: '100%',
  '&:hover, &.Mui-disabled': {
    border: 0,
    'span, p': {
      color: '#2E2C5E !important',
    },
  },
}));

export const wobbleKeyFrames = keyframes`
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.3) rotate(-10deg);
  }
  75% {
    transform: scale(1.3) rotate(10deg);
  }
`;
