import { SvgIcon } from '@mui/material';

export function IconNext(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M8.47527 16.3604C8.72743 16.3612 8.96641 16.2479 9.12527 16.052L13.1503 11.052C13.403 10.7445 13.403 10.3012 13.1503 9.99369L8.9836 4.99369C8.68905 4.63931 8.16299 4.5908 7.8086 4.88536C7.45422 5.17991 7.40572 5.70597 7.70027 6.06036L11.4253 10.527L7.82527 14.9937C7.61719 15.2435 7.57336 15.5914 7.71298 15.885C7.85261 16.1786 8.15021 16.3642 8.47527 16.3604Z"
        fill="#798189"
      />
    </SvgIcon>
  );
}
