import { Box, Typography } from '@mui/material';
import { ReactComponent as Wallet } from 'assets/icons/wallet_ohplay2.svg';
import { ReactComponent as WithdrawIcon } from 'assets/icons/withdraw_ohplay2.svg';
import Button from 'common/Button';
import V2Notifications from 'components/Notifications';
import useResponsive from 'hooks/useResponsive';
import { MyWalletBox } from 'layouts/dashboard/header/AuthenticatedUser/styles';
import UserBalance from 'layouts/dashboard/header/AuthenticatedUser/UserBalance';
import UserInfo from 'layouts/dashboard/header/AuthenticatedUser/UserInfo';
import { useLocales } from 'locales';
import { memo, useState } from 'react';

import WalletDialog from 'components/WalletDialog';
import { AuthenticatedUserWrapper } from './styles';

/* -------------------------------------------------------------------------- */

interface AuthenticatedUserProps {
  isDrawerNotiOpen: boolean;
  setIsDrawerNoti: (e) => void;
  isDrawerChatOpen: boolean;
  setIsDrawerChat: (e) => void;
}

const AuthenticatedUser = (props: AuthenticatedUserProps) => {
  const {
    isDrawerNotiOpen,
    setIsDrawerNoti,
    isDrawerChatOpen,
    setIsDrawerChat,
  } = props;

  const { translate } = useLocales();
  const isTablet = useResponsive('up', 'md');
  const isDesktop = useResponsive('up', 'sm');

  const [tabIdDefault, setTabIdDefault] = useState(0);
  const [isWalletDialogOpen, setIsWalletDialogOpen] = useState(false);

  return (
    <AuthenticatedUserWrapper
      className="authenticated-box"
      open={isDrawerNotiOpen || isDrawerChatOpen}
    >
      <Box className="wrapper">
        <Box className="balance-wallet">
          <UserBalance
            setIsWalletDialogOpen={setIsWalletDialogOpen}
            setTabIdDefault={(e: number) => setTabIdDefault(e)}
          />

          <Box className="nav-wallet-btn">
            <Button
              className="nav-withdraw"
              onClick={() => {
                setIsWalletDialogOpen(true);
                setTabIdDefault(2);
              }}
            >
              {!isTablet && <WithdrawIcon />}
              {isTablet && translate('withdraw')}
            </Button>

            <MyWalletBox className="nav-deposit-btn">
              <Box className="nav-bonus">
                <Typography component="span">300%</Typography>
              </Box>

              <Button
                onClick={() => {
                  setIsWalletDialogOpen(true);
                  setTabIdDefault(0);
                }}
              >
                {!isTablet && <Wallet width="24px" height="24px" />}
                {isTablet && translate('deposit')}
              </Button>
            </MyWalletBox>

            {isWalletDialogOpen && (
              <WalletDialog
                isOpen
                tabIdDefault={tabIdDefault}
                handleClose={() => setIsWalletDialogOpen(false)}
              />
            )}
          </Box>
        </Box>
        <UserInfo />
      </Box>

      {isDesktop && (
        <V2Notifications
          drawer={isDrawerNotiOpen}
          setDrawer={(e) => {
            setIsDrawerChat(false);
            setIsDrawerNoti(e);
          }}
          isShow
        />
      )}
    </AuthenticatedUserWrapper>
  );
};

export default memo(AuthenticatedUser);
