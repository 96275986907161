import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatisticBox = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  width: 380,
  border: 0,
  boxShadow: theme.shadows[6],
  padding: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  cursor: 'grab',
}));
