import { Box, styled } from '@mui/material';

export const BetDetailInfoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',

  '.info-list': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    aliItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down(450)]: {
      gap: theme.spacing(1.5),
    },
  },

  '.info-item': {
    width: 'calc((100% - 16px) / 2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  h6: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    marginBottom: theme.spacing(1),
  },

  '.info-item-content': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alineItems: 'center',
    backgroundColor: theme.palette.background.main,
    borderRadius: theme.shape.borderRadius,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 'normal',
    padding: theme.spacing(1.75),

    span: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.primary,
      lineHeight: 'normal',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  '.eye': {
    fontSize: 20,
    '&:hover': {
      color: theme.palette.text.contrast,
    },
  },
}));
