import { TabsStyled } from 'common/Tabs';
import { DialogAnimate } from 'components/animate';
import { CloseBox, DialogWrapper } from 'components/Dialog/styles';
import Iconify from 'components/iconify/Iconify';
import { useJackpotWinnersQuery } from 'graph';
import { useLocales } from 'locales';
import { HighBetTable, JackpotWinnerTable } from 'pages/Games/BetResultTable';
import { useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Tab, Typography, useTheme } from '@mui/material';

import { Body, Header } from './styles';

// ---------------------------------------------------------------

interface Props {
  isOpen: boolean;
  handleClose: VoidFunction;
  gameId: string;
}

type TabValue = 'jackpot_winner' | 'big_win';

const MiniGameBigWinnerDialog = ({ isOpen, handleClose, gameId }: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const [selectedTab, setSelectedTab] = useState<TabValue>('jackpot_winner');

  const { data: jackpotWinnerData } = useJackpotWinnersQuery({
    variables: {
      page: 1,
      pageSize: 50,
      gameId,
    },
  });

  const tabs: TabsType<TabValue> = [
    {
      label: translate('jackpot_winners'),
      value: 'jackpot_winner',
      icon: <Iconify icon="solar:crown-bold" />,
    },
    {
      label: translate('big_winner'),
      value: 'big_win',
      icon: <Iconify icon="solar:cup-star-bold" />,
    },
  ];

  return (
    <DialogAnimate
      open={isOpen}
      onClose={handleClose}
      sx={{
        width: 460,
        height: 595,
        [theme.breakpoints.down('sm')]: {
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
          maxHeight: '100%',
        },
      }}
    >
      <DialogWrapper>
        <Header>
          <Typography className="title" variant="subtitle2">
            {translate('winner')}
          </Typography>
          <CloseBox onClick={() => handleClose()} sx={{ '&&': { right: 20 } }}>
            <IconButton className="close-btn">
              <CloseRoundedIcon />
            </IconButton>
          </CloseBox>
        </Header>

        <Body>
          <TabsStyled
            value={selectedTab}
            onChange={(e, selectedTab) => setSelectedTab(selectedTab)}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </TabsStyled>

          <Box flex={1} display="flex" overflow="auto">
            {selectedTab === 'jackpot_winner' && (
              <JackpotWinnerTable
                rows={jackpotWinnerData?.jackpotWinBets?.data || []}
                isBetsByGame
                isShowInMiniGameDialog
              />
            )}
            {selectedTab === 'big_win' && (
              <HighBetTable
                isBetsByGame
                isShowInMiniGameDialog
                gameId={gameId}
              />
            )}
          </Box>
        </Body>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default MiniGameBigWinnerDialog;
