export function rnn(num: number, n: number) {
  // num : số cần xử lý
  // n: số chữ số sau dấu phẩy cần lấy
  // const base = 10 ** n;
  // const result = Math.round(num * base) / base;
  const result = Number.parseFloat(num.toFixed(n));
  return result;
}

export function rnn2(num: number, n: number) {
  // num : số cần xử lý
  // n: số chữ số sau dấu phẩy cần lấy
  const base = 10 ** n;
  const result = Math.round(num * base) / base;
  return result;
}

export function toN(number: string | number) {
  if (typeof number === 'string') {
    return Number(number.replaceAll(',', ''));
  }
  return number;
}

export function toFixedWithoutRounding(n: number, decimalDigits: number) {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${decimalDigits || -1}})?`);
  if (!Number.isNaN(Number(n)))
    return Number(n)
      .toFixed(decimalDigits + 2)
      .match(re)[0];
}

export const toNWithoutRounding = (n: number, decimalDigits: number) =>
  toFixedWithoutRounding(n, decimalDigits);

export const localStringNumber: (
  number: number,
  opts?: {
    decimalDigits?: number;
    locale?: string;
  },
) => string = (number, opts = { decimalDigits: 2, locale: 'en' }) => {
  const { decimalDigits, locale } = opts;

  return Number(toNWithoutRounding(number, decimalDigits)).toLocaleString(
    locale,
    {
      minimumFractionDigits: decimalDigits,
      maximumFractionDigits: decimalDigits,
    },
  );
};

// 1e-7 => 0.0000001
export const exponentialToDecimal = (x) => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1], 8);
    if (e) {
      x *= 10 ** (e - 1);
      x = `0.${new Array(e).join('0')}${x.toString().substring(2)}`;
    }
  } else {
    let e = parseInt(x.toString().split('+')[1], 8);
    if (e > 20) {
      e -= 20;
      x /= 10 ** e;
      x += new Array(e + 1).join('0');
    }
  }
  return x;
};
