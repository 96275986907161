import { DialogAnimate } from 'components/animate';
import { CloseBox, DialogWrapper } from 'components/Dialog/styles';
import Iconify from 'components/iconify/Iconify';
import { useLocales } from 'locales';
import { AllBetTable, MyBetTable } from 'pages/Games/BetResultTable';
import { useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

import { Body, Header, TabItem } from '../MiniGameBigWinnerDialog/styles';

// =================================================================

interface Props {
  isOpen: boolean;
  handleClose: VoidFunction;
  gameId: string;
}

const tabs = [
  {
    key: 'my_bet',
    icon: 'mdi:clock',
  },
  {
    key: 'all_bet',
    icon: 'ri:coins-fill',
  },
];

const MiniGameBetHistoryDialog = ({ isOpen, handleClose, gameId }: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <DialogAnimate
      open={isOpen}
      onClose={handleClose}
      sx={{
        width: 460,
        height: 595,
        [theme.breakpoints.down('sm')]: {
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
          maxHeight: '100%',
        },
      }}
    >
      <DialogWrapper
        sx={{
          '&&': { backgroundColor: (theme) => theme.palette.background.table },
        }}
      >
        <Header>
          <Typography className="title" variant="subtitle2">
            {translate('history')}
          </Typography>
          <CloseBox onClick={() => handleClose()} sx={{ '&&': { right: 20 } }}>
            <IconButton className="close-btn">
              <CloseRoundedIcon />
            </IconButton>
          </CloseBox>
        </Header>

        <Body>
          <Stack direction="row" columnGap={1} mb={2}>
            {tabs.map((tab) => (
              <TabItem
                key={tab.key}
                onClick={() => setSelectedTab(tab)}
                className={selectedTab.key === tab.key && 'active'}
              >
                <Iconify icon={tab.icon} />
                <Typography variant="subtitle1">
                  {translate(tab.key)}
                </Typography>
              </TabItem>
            ))}
          </Stack>

          <Box flex={1} display="flex" sx={{ overflow: 'auto' }}>
            {selectedTab.key === 'my_bet' && gameId && (
              <MyBetTable showInMiniGame gameId={gameId} />
            )}
            {selectedTab.key === 'all_bet' && gameId && (
              <AllBetTable
                isBetsByGame
                isShowInMiniGameDialog
                gameId={gameId}
              />
            )}
          </Box>
        </Body>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default MiniGameBetHistoryDialog;
