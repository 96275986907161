import { cardfn } from './Assets';

export const payTable = [
  [
    cardfn(10, '♠'),
    cardfn(11, '♠'),
    cardfn(12, '♠'),
    cardfn(13, '♠'),
    cardfn(14, '♠'),
  ],
  [
    cardfn(6, '♥'),
    cardfn(7, '♥'),
    cardfn(8, '♥'),
    cardfn(9, '♥'),
    cardfn(10, '♥'),
  ],
  [
    cardfn(12, '♦'),
    cardfn(12, '♥'),
    cardfn(12, '♠'),
    cardfn(12, '♣'),
    cardfn(8, '♥'),
  ],
  [
    cardfn(14, '♦'),
    cardfn(14, '♥'),
    cardfn(14, '♠'),
    cardfn(7, '♣'),
    cardfn(7, '♠'),
  ],
  [
    cardfn(4, '♦'),
    cardfn(6, '♦'),
    cardfn(9, '♦'),
    cardfn(12, '♦'),
    cardfn(13, '♦'),
  ],
  [
    cardfn(5, '♠'),
    cardfn(6, '♠'),
    cardfn(7, '♦'),
    cardfn(8, '♣'),
    cardfn(9, '♠'),
  ],
  [
    cardfn(10, '♥'),
    cardfn(10, '♣'),
    cardfn(10, '♠'),
    cardfn(2, '♥'),
    cardfn(13, '♣'),
  ],
  [
    cardfn(11, '♥'),
    cardfn(11, '♣'),
    cardfn(9, '♠'),
    cardfn(9, '♥'),
    cardfn(14, '♣'),
  ],
  [
    cardfn(12, '♥'),
    cardfn(12, '♣'),
    cardfn(7, '♠'),
    cardfn(8, '♥'),
    cardfn(9, '♣'),
  ],
];

export const textTable = [
  ['jackpot', 'straight_flush_with_jack'],
  ['x1000', 'straight_flush'],
  ['x150', 'four_of_a_kind'],
  ['x50', 'full_house'],
  ['x25', 'flush'],
  ['x13', 'straight'],
  ['x8', 'three_of_a_kind'],
  ['x5', 'tow_pair'],
  ['x2.5', 'pair_of_jacks_or_better'],
];
