import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { MiniDiceTopProfitDialogHeaderStyled } from 'components/_Games/_GamesMiniDice/top-profit/components/top-profit-dialog-header/styles';
import { addDays, format, isToday } from 'date-fns';
import { memo, useRef, useState } from 'react';

import { Button, IconButton, Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface IMiniDiceTopProfitDialogHeaderProps {
  onClose: () => void;
  selectedDate: Date;
  onChangeDate: (date: Date) => void;
}

const MiniDiceTopProfitDialogHeader = ({
  onClose,
  onChangeDate,
  selectedDate,
}: IMiniDiceTopProfitDialogHeaderProps) => {
  const dateNavigationRef = useRef(null);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  return (
    <MiniDiceTopProfitDialogHeaderStyled
      sx={{
        padding: 0,
        height: 'unset',
      }}
    >
      <Stack
        ref={dateNavigationRef}
        direction="row"
        className="navigation navigation-date "
      >
        <IconButton
          className="arrow-left-btn"
          onClick={() => onChangeDate(addDays(selectedDate, -1))}
        >
          <ArrowLeft />
        </IconButton>
        <Stack alignItems="center">
          <Button
            className="select-date-btn"
            variant="text"
            onClick={() => {
              setIsOpenDatePicker(true);
            }}
          >
            {format(selectedDate, 'dd/MM/yyyy')}
          </Button>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              open={isOpenDatePicker}
              onClose={() => setIsOpenDatePicker(false)}
              onAccept={(date: Date) => {
                onChangeDate(date);
              }}
              format="dd/MM/yyyy"
              closeOnSelect
              disableFuture
              slotProps={{
                popper: {
                  anchorEl: dateNavigationRef.current,
                  placement: 'bottom',
                },
                textField: {
                  style: {
                    display: 'none',
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Stack>
        <IconButton
          className="arrow-right-btn"
          disabled={isToday(selectedDate)}
          onClick={() => onChangeDate(addDays(selectedDate, 1))}
        >
          <ArrowRight />
        </IconButton>
      </Stack>
      <IconButton className="close-btn" onClick={() => onClose()}>
        <CloseCircle width="24px" height="24px" />
      </IconButton>
    </MiniDiceTopProfitDialogHeaderStyled>
  );
};

export default memo(MiniDiceTopProfitDialogHeader);
