import { SvgIcon } from '@mui/material';

export function LogoCocoFull(props: any) {
  return (
    <SvgIcon
      width="200"
      height="90"
      viewBox="0 0 200 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6582_21706)">
        <path
          d="M40.5618 72.8992C41.0705 73.3955 41.3249 74.4087 41.3249 75.9527V76.125C41.3249 76.8212 41.3037 77.3382 41.2613 77.6759C41.2189 78.0137 41.0564 78.4135 40.7879 78.8753C40.3428 79.7162 39.2194 80.1367 37.4108 80.1367C35.6021 80.1367 34.4152 79.861 33.8358 79.3095C33.2565 78.7581 32.9668 77.7104 32.9668 76.1664V75.9941C32.9668 75.3255 32.988 74.8154 33.0374 74.4707C33.0798 74.1192 33.2211 73.7263 33.4614 73.2921C33.9065 72.4167 35.0298 71.9824 36.8385 71.9824C38.6472 71.9824 39.8836 72.2857 40.5689 72.8992H40.5618Z"
          fill="#606060"
        />
        <path
          d="M58.7124 77.2208C57.5819 78.6752 55.7733 79.3989 53.3005 79.3989C50.8277 79.3989 48.5103 78.2892 46.3484 76.0628C44.1864 73.8364 43.1055 71.2447 43.1055 68.2739C43.1055 65.303 44.1935 62.7044 46.3696 60.4642C48.5456 58.2241 50.9266 57.1074 53.5195 57.1074C54.5298 57.1074 55.4553 57.2866 56.289 57.652C57.1227 58.0173 57.695 58.3481 58.0129 58.6583C58.3238 58.9616 58.5711 59.2442 58.7477 59.5061C58.8395 58.6928 59.1433 58.0724 59.6662 57.652C60.189 57.2315 61.0297 57.0178 62.1955 57.0178C63.3612 57.0178 64.209 57.1074 64.746 57.2797C65.2829 57.4521 65.6645 57.7554 65.8835 58.1758C66.1096 58.5963 66.2438 58.9685 66.2862 59.2856C66.3286 59.6095 66.3568 60.0989 66.3568 60.7675V78.8268C66.3568 82.1698 65.1628 84.865 62.7819 86.919C60.3939 88.9662 57.7162 89.9932 54.7347 89.9932C52.4668 89.9932 50.3119 89.4556 48.2489 88.3803C46.1929 87.305 45.1614 86.3331 45.1614 85.4577C45.1614 84.3204 45.6065 83.321 46.5038 82.4456C47.069 81.8321 47.5071 81.4116 47.825 81.1773C48.1359 80.9429 48.5032 80.8257 48.9201 80.8257C49.3369 80.8257 49.7679 80.9981 50.213 81.3496C51.6472 82.4249 53.1945 82.9625 54.8619 82.9625C55.9923 82.9625 56.9178 82.5283 57.6314 81.6529C58.345 80.7775 58.7053 79.7367 58.7053 78.5097V77.2001L58.7124 77.2208ZM50.757 68.3014C50.757 69.2457 51.1385 70.0936 51.8945 70.8518C52.6575 71.61 53.576 71.9822 54.664 71.9822C55.7521 71.9822 56.6564 71.5962 57.37 70.8242C58.0836 70.0522 58.4439 69.2113 58.4439 68.2945C58.4439 67.3778 58.0977 66.5231 57.4124 65.7235C56.7271 64.9239 55.8015 64.5241 54.6428 64.5241C53.4842 64.5241 52.5445 64.917 51.8239 65.7028C51.1103 66.4886 50.75 67.3571 50.75 68.3014H50.757Z"
          fill="#606060"
        />
        <path
          d="M84.7753 59.5543C84.952 57.9276 86.1319 57.1143 88.3079 57.1143C89.4737 57.1143 90.3356 57.2039 90.9008 57.3762C91.466 57.5485 91.8617 57.8587 92.0878 58.2929C92.3139 58.7272 92.4481 59.1132 92.4905 59.4509C92.5329 59.7887 92.5611 60.285 92.5611 60.9536V76.2213C92.5611 76.8899 92.5399 77.393 92.4905 77.7239C92.4481 78.0616 92.3209 78.4408 92.109 78.8819C91.6921 79.6401 90.6677 80.0123 89.0498 80.0123C87.4318 80.0123 86.3367 79.8193 85.7857 79.4264C85.2346 79.0335 84.9167 78.4614 84.8248 77.7032C83.7226 79.2472 81.8786 80.0123 79.3069 80.0123C76.7352 80.0123 74.3401 78.8612 72.1287 76.5659C69.9244 74.2706 68.8223 71.603 68.8223 68.5633C68.8223 65.5235 69.9315 62.8629 72.1499 60.5814C74.3684 58.2998 76.8059 57.1556 79.4624 57.1556C80.4727 57.1556 81.3982 57.3279 82.2319 57.6795C83.0656 58.031 83.6449 58.355 83.977 58.6583C84.302 58.9615 84.5705 59.2648 84.7824 59.5543H84.7753ZM76.6363 68.6253C76.6363 69.5834 77.0249 70.4519 77.8021 71.2239C78.5792 71.9959 79.5189 72.3819 80.621 72.3819C81.7232 72.3819 82.6417 71.989 83.3694 71.2033C84.0971 70.4175 84.4645 69.5559 84.4645 68.6046C84.4645 67.6534 84.1112 66.778 83.4118 65.9647C82.7123 65.1513 81.7727 64.7446 80.5928 64.7446C79.4129 64.7446 78.4591 65.1513 77.7314 65.9647C77.0037 66.778 76.6363 67.6672 76.6363 68.6253Z"
          fill="#606060"
        />
        <path
          d="M103.371 60.1678C104.77 58.0999 106.458 57.0729 108.423 57.0729C111.461 57.0729 113.728 58.3412 115.219 60.8708C115.544 60.4366 115.94 59.9748 116.406 59.4992C116.865 59.0167 117.607 58.4928 118.617 57.9276C119.628 57.3624 120.673 57.0798 121.747 57.0798C124.135 57.0798 126.128 57.9758 127.739 59.7611C129.349 61.5463 130.155 64.5103 130.155 68.6391V76.2695C130.155 76.9381 130.134 77.4413 130.091 77.7721C130.049 78.1099 129.908 78.489 129.667 78.9301C129.279 79.7159 128.134 80.1088 126.227 80.1088C124.319 80.1088 123.203 79.7021 122.786 78.8888C122.574 78.4545 122.454 78.0685 122.404 77.7308C122.362 77.3999 122.334 76.8967 122.334 76.2281V68.5977C122.334 66.068 121.408 64.8067 119.564 64.8067C118.519 64.8067 117.812 65.1444 117.438 65.813C117.063 66.4816 116.879 67.4259 116.879 68.646V76.2764C116.879 76.9725 116.858 77.4826 116.809 77.7997C116.766 78.1237 116.625 78.4959 116.385 78.937C115.968 79.7228 114.802 80.1157 112.895 80.1157C110.987 80.1157 109.871 79.709 109.454 78.8957C109.242 78.4614 109.122 78.0754 109.072 77.7377C109.03 77.4068 109.002 76.9036 109.002 76.235V68.6046C109.002 66.0749 108.076 64.8135 106.232 64.8135C104.388 64.8135 103.548 66.0749 103.548 68.6046V76.3246C103.548 76.9932 103.526 77.4964 103.477 77.8273C103.435 78.165 103.293 78.5441 103.053 78.9853C102.608 79.7435 101.442 80.1226 99.5628 80.1226C97.6835 80.1226 96.5389 79.7297 96.1221 78.9439C95.9101 78.5097 95.783 78.1305 95.7406 77.8066C95.6982 77.4895 95.6699 76.9794 95.6699 76.2833V60.9329C95.6699 60.2643 95.6911 59.7611 95.7406 59.4302C95.79 59.0994 95.9243 58.7272 96.1645 58.3205C96.6096 57.5347 97.6694 57.1418 99.3367 57.1418C101.004 57.1418 102.092 57.4037 102.601 57.9276C103.11 58.4514 103.364 59.2097 103.364 60.1953L103.371 60.1678Z"
          fill="#606060"
        />
        <path
          d="M155.992 65.8546C155.992 67.6398 155.54 68.9633 154.628 69.8249C153.717 70.6796 152.714 71.1138 151.611 71.1138H141.014C141.014 71.9272 141.508 72.6096 142.49 73.161C143.472 73.7124 144.462 73.9882 145.444 73.9882C147.175 73.9882 148.531 73.8158 149.513 73.4643L150.008 73.292C150.721 72.9749 151.322 72.8095 151.795 72.8095C152.749 72.8095 153.568 73.4643 154.254 74.774C154.642 75.5597 154.833 76.2283 154.833 76.7798C154.833 79.3715 151.661 80.6604 145.309 80.6604C143.105 80.6604 141.141 80.2882 139.431 79.5507C137.714 78.8063 136.386 77.8275 135.432 76.6074C133.553 74.2501 132.613 71.6239 132.613 68.7151C132.613 65.0481 133.828 62.1187 136.259 59.9267C138.689 57.7279 141.72 56.632 145.359 56.632C149.499 56.632 152.544 58.0588 154.48 60.9055C155.49 62.4151 155.999 64.0693 155.999 65.8546H155.992ZM146.56 67.1436C147.69 67.1436 148.256 66.6748 148.256 65.7443C148.256 65.0757 147.994 64.5381 147.471 64.1314C146.948 63.7247 146.207 63.5179 145.239 63.5179C144.271 63.5179 143.31 63.9039 142.356 64.6759C141.402 65.4479 140.929 66.2682 140.929 67.1436H146.56Z"
          fill="#606060"
        />
        <path
          d="M25.6182 50.6767C11.495 50.6767 0 39.4621 0 25.6763C0 11.8906 11.495 0.682861 25.6182 0.682861C32.4573 0.682861 38.8936 3.28147 43.7332 8.00307C46.1707 10.3811 46.1707 14.2342 43.7332 16.6122C41.2957 18.9903 37.3534 18.9903 34.9089 16.6122C32.4219 14.186 29.1225 12.8556 25.6111 12.8556C18.3623 12.8556 12.4629 18.6112 12.4629 25.6832C12.4629 32.7553 18.3623 38.5108 25.6111 38.5108C29.4192 38.5108 33.0366 36.8979 35.5377 34.0925C37.7914 31.556 41.7338 31.2871 44.3338 33.486C46.9337 35.6917 47.2093 39.531 44.9555 42.0676C40.0876 47.5405 33.0366 50.6767 25.6111 50.6767H25.6182Z"
          fill="#8527FF"
        />
        <path
          d="M127.173 50.6767C113.05 50.6767 101.555 39.4621 101.555 25.6832C101.555 11.9044 113.043 0.682861 127.173 0.682861C134.019 0.682861 140.448 3.28147 145.288 8.00307C147.725 10.3811 147.725 14.2342 145.288 16.6054C142.857 18.9834 138.901 18.9834 136.471 16.6054C133.984 14.186 130.684 12.8487 127.173 12.8487C119.924 12.8487 114.025 18.6043 114.025 25.6763C114.025 32.7484 119.924 38.504 127.173 38.504C130.981 38.504 134.598 36.891 137.099 34.0856C139.36 31.5491 143.296 31.2802 145.896 33.4791C148.495 35.6848 148.771 39.5241 146.517 42.0607C141.649 47.5336 134.598 50.6698 127.173 50.6698V50.6767Z"
          fill="#8527FF"
        />
        <path
          d="M174.275 50.5523C160.088 50.5523 148.551 39.2894 148.551 25.4554C148.551 11.6214 160.088 0.358521 174.275 0.358521C181.149 0.358521 187.607 2.97091 192.468 7.7132C197.329 12.4555 200.006 18.7555 200.006 25.4623C200.006 31.5142 197.767 37.3594 193.704 41.9155C188.815 47.4091 181.729 50.5592 174.275 50.5592V50.5523ZM174.275 12.5244C166.97 12.5244 161.021 18.3213 161.021 25.4554C161.021 32.5895 166.963 38.3864 174.275 38.3864C178.118 38.3864 181.764 36.7597 184.286 33.9336C186.378 31.5831 187.529 28.5779 187.529 25.4623C187.529 22.009 186.151 18.7624 183.65 16.3224C181.149 13.8823 177.822 12.5382 174.282 12.5382L174.275 12.5244Z"
          fill="#8527FF"
        />
        <path
          d="M84.7968 25.0629C84.7968 31.1079 79.7876 36.0088 73.5986 36.0294C73.5844 36.0294 73.5632 36.0294 73.5491 36.0294C67.3389 36.0294 62.3014 31.1148 62.3014 25.056C62.2308 20.4171 64.9367 17.0052 69.0628 13.5312C69.2535 13.3726 69.4231 13.2141 69.5997 13.0624L69.5856 13.0487C69.5856 13.0487 71.8959 11.1118 72.9345 8.55452C72.9345 8.54763 72.9415 8.53384 72.9486 8.52695C73.429 7.32759 73.6198 5.99727 73.1676 4.66006C72.9274 3.95009 72.5035 3.24702 71.8394 2.55774C68.4975 -0.730154 63.1916 1.31702 61.3052 2.2062C60.8672 2.41299 60.6128 2.55774 60.6128 2.55774V2.54395C52.3749 6.84509 46.7793 15.3095 46.7793 25.056C46.7793 32.7553 50.2695 39.6551 55.7944 44.3353C63.6085 45.4726 69.3595 45.0935 73.5915 43.8253C87.9337 39.531 84.7898 25.056 84.7898 25.056L84.7968 25.0629Z"
          fill="#EFC516"
        />
        <path
          d="M85.9677 2.92295C85.6922 2.75752 85.3672 2.5783 84.9927 2.39909C84.9221 2.36462 84.8585 2.32327 84.7879 2.2888C84.5406 2.17163 84.2226 2.03377 83.8694 1.88902C83.8694 1.88902 83.8694 1.88902 83.8623 1.88902H83.8482C83.608 1.79252 83.3466 1.69602 83.071 1.60641C74.07 -1.72284 64.1647 1.18595 64.1647 1.18595C66.5386 0.56559 69.619 0.393269 71.8445 2.5783C72.5086 3.2607 72.9325 3.97066 73.1728 4.68062C73.4271 5.41816 73.4695 6.1488 73.3847 6.86566C73.4271 7.16205 73.4907 7.45844 73.5825 7.74105C73.6108 7.85134 73.6603 8.03055 73.7592 8.28559C73.7592 8.29248 73.7662 8.30627 73.7733 8.31316C74.1619 9.27816 75.2146 11.1737 78.0406 13.5448C82.1737 17.0188 84.8726 20.4377 84.802 25.0697C84.802 25.0697 87.9318 39.524 73.6037 43.8251C69.3717 45.0934 63.6278 45.4863 55.8066 44.349C60.399 48.2435 66.3973 50.6008 72.9608 50.6008C87.4161 50.6008 99.1301 39.1725 99.1301 25.0697C99.1301 15.592 93.8312 7.33437 85.9748 2.92984L85.9677 2.92295Z"
          fill="#8527FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_6582_21706">
          <rect width="200" height="90" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
