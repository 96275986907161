import { IP_REGISTRY } from 'constants/config';

import { IPRegistry } from './type';

export default function useIPRegistry() {
  const registryStorage =
    typeof window !== 'undefined' ? localStorage.getItem(IP_REGISTRY) : null;
  try {
    const dataRegistry: IPRegistry =
      registryStorage && JSON.parse(localStorage.getItem(IP_REGISTRY));
    return dataRegistry;
  } catch (error) {
    /* empty */
  }
  return undefined;
}
