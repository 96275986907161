import Tick from '@pqina/flip';
import { DialogAnimate } from 'components/animate';
import {
  CloseBox,
  DialogBody,
  DialogHeader,
  DialogWrapper,
} from 'components/Dialog/styles';
import Iconify from 'components/iconify/Iconify';
import TabItem from 'components/WalletDialog/WalletTabItem';
import Rule from 'components/_Events/_CrashEvent/Rule';
import CryptoContest from 'components/_Events/_CrashEvent/Winner/CryptoContest';
import FunContest from 'components/_Events/_CrashEvent/Winner/FunContest';
import { addDays, differenceInDays, format, isAfter, isToday } from 'date-fns';
import { CrashPredictorEvent, FunCrashPredictorEvent } from 'graph';
import { useLocales } from 'locales';
import { memo, useEffect, useRef, useState } from 'react';
import { hideScrollbarY } from 'utils/cssStyles';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

import { BodyWrapper } from './styles';

// =================================================================================================

interface CountDownTextProps {
  countDownTo: Date;
}

const CountDownText = memo(({ countDownTo }: CountDownTextProps) => {
  const { translate } = useLocales();
  const [countDownValue, setCountDownValue] = useState([]);

  useEffect(() => {
    const counter = Tick?.count?.down(new Date(countDownTo), {
      format: ['h', 'm', 's'],
      interval: 1000,
    });

    counter.onupdate = (value) => {
      if (!value.includes(NaN)) {
        setCountDownValue(value);
      }
    };
  }, [countDownTo]);

  return (
    <Typography variant="subtitle2">
      {translate('reset_after')}{' '}
      {`${countDownValue[0] || 0}:${countDownValue[1] || 0}:${
        countDownValue[2] || 0
      }`}
    </Typography>
  );
});

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  gameId: string;
}

const tabs = [
  {
    key: 'crypto_play',
    icon: null,
  },
];

const PredictorDialog = ({ isOpen, onClose, gameId }: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedDate, setSelectedDate] = useState([new Date(), 0]);
  const isFirstRender = useRef(true);

  const [crashPredictorData, setCrashPredictorData] =
    useState<
      Pick<
        CrashPredictorEvent | FunCrashPredictorEvent,
        'end' | 'start' | 'crashPredictorResults'
      >
    >(null);

  const handleClickPrevDate = () => {
    setSelectedDate((prevDate) => [addDays(prevDate[0], -1), 1]);
  };

  const handleClickNextDate = () => {
    setSelectedDate((prevDate) => [addDays(prevDate[0], 1), 1]);
  };

  const handleClose = () => {
    onClose();
    setSelectedTab(tabs[0]);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const difInDays = differenceInDays(new Date(), new Date(selectedDate[0]));

    if (
      crashPredictorData !== undefined &&
      !crashPredictorData?.crashPredictorResults?.length &&
      selectedDate[1] === 0 &&
      difInDays < 10
    ) {
      setSelectedDate((prev) => [addDays(prev[0], -1), 0]);
    }
  }, [crashPredictorData?.crashPredictorResults]);

  return (
    <DialogAnimate
      open={isOpen}
      onClose={() => {
        handleClose();
        setSelectedDate([new Date(), 0]);
      }}
      sx={{
        width: 850,
        height: 700,
        maxWidth: 'unset',
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'unset',
          '&&&': { margin: 0 },
          width: '100%',
          height: '100%',
          overflowY: 'hidden',
        },
      }}
    >
      <DialogWrapper>
        <DialogHeader
          hasCloseBtn
          sx={{
            backgroundColor: 'background.secondary',
            height: { xs: 52, sm: 60 },
            padding: theme.spacing(0, 3),
            margin: 0,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            className="dialog-title"
            height="fit-content"
          >
            <Typography variant="h6">{translate('crush_of_crash')}</Typography>
            <IconButton
              sx={{ p: 0, ml: 0.5 }}
              onClick={() => setSelectedTab({ key: 'rule', icon: null })}
            >
              <Iconify
                icon="fluent:info-24-filled"
                width={{ xs: 16, sm: 20 }}
              />
            </IconButton>
          </Box>
          <CloseBox
            key="close-button"
            onClick={() => {
              handleClose();
              setSelectedDate([new Date(), 0]);
            }}
          >
            <IconButton className="close-btn">
              <CloseRoundedIcon />
            </IconButton>
          </CloseBox>
        </DialogHeader>

        <Box
          sx={{
            overflowY: 'auto',
            height: 'calc(100% - 60px)',
            ...hideScrollbarY,
          }}
        >
          <DialogBody
            sx={{
              overflowY: 'unset',
              backgroundColor: 'background.main',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {selectedTab.key !== 'rule' && (
              <Stack direction={{ xs: 'column', sm: 'row' }} pb={2.5}>
                <Stack
                  flex={1}
                  direction="row"
                  columnGap={1.5}
                  maxWidth={{ sm: '50%' }}
                  justifyContent={{ sm: 'flex-start', xs: 'center' }}
                  sx={{
                    '.MuiTypography-root': {
                      width: { xs: 'fit-content' },
                    },
                  }}
                >
                  {tabs.map((tab) => (
                    <TabItem
                      key={tab.key}
                      tab={tab}
                      isActive={tab.key === selectedTab.key}
                      handleSelectTab={() => setSelectedTab(tab)}
                      sx={{
                        maxWidth: { sm: 194, xs: 'fit-content' },
                        px: { xs: 3 },
                      }}
                    />
                  ))}
                </Stack>
                <Stack
                  flex={1}
                  direction="row"
                  columnGap={2}
                  justifyContent={{ sm: 'flex-end', xs: 'center' }}
                  alignItems="center"
                  sx={{
                    mt: { xs: 1, sm: 0 },
                    '.MuiIconButton-root:not(.close-btn)': {
                      padding: 0,
                      backgroundColor: theme.palette.background.hovering,
                      borderRadius: '2px',
                      '&:not(.close-btn):hover': {
                        backgroundColor: theme.palette.background.hovering,
                        opacity: 0.8,
                        path: {
                          fill: theme.palette.text.contrast,
                        },
                      },
                    },
                  }}
                >
                  <IconButton onClick={() => handleClickPrevDate()}>
                    <Iconify icon="basil:caret-left-outline" />
                  </IconButton>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    width={133}
                    height={43}
                  >
                    <Typography variant="subtitle1" color="text.contrast">
                      {format(selectedDate[0], 'dd/MM/yyyy')}
                    </Typography>
                    {isAfter(new Date(crashPredictorData?.end), new Date()) && (
                      <CountDownText countDownTo={crashPredictorData?.end} />
                    )}
                  </Stack>
                  <IconButton
                    onClick={() => handleClickNextDate()}
                    disabled={isToday(selectedDate[0])}
                  >
                    <Iconify icon="basil:caret-right-outline" />
                  </IconButton>
                </Stack>
              </Stack>
            )}
            <BodyWrapper>
              {selectedTab.key === 'crypto_play' && (
                <CryptoContest
                  selectedDate={selectedDate}
                  gameId={gameId}
                  setData={setCrashPredictorData}
                />
              )}
              {selectedTab.key === 'fun_play' && (
                <FunContest
                  selectedDate={selectedDate}
                  gameId={gameId}
                  setData={setCrashPredictorData}
                />
              )}
              {selectedTab.key === 'rule' && (
                <Rule handleBack={() => setSelectedTab(tabs[0])} />
              )}
            </BodyWrapper>
          </DialogBody>
        </Box>
      </DialogWrapper>
    </DialogAnimate>
  );
};

export default PredictorDialog;
