import { ApexOptions } from 'apexcharts';

export const threeDiceSumChartConfig: ApexOptions = {
  dataLabels: {
    enabled: true,
    textAnchor: 'middle',
    style: {
      fontWeight: 500,
      fontSize: '10px',
      colors: [
        function chartColor(opts) {
          const value = opts.series[0][opts.dataPointIndex];
          if (value > 10) {
            return '#53BA0C';
          }
          return '#FF7A00';
        },
      ],
    },
    background: {
      opacity: 1,
      borderRadius: 12,
      borderWidth: 0,
    },
  },
  yaxis: {
    max: 18,
    min: 3,
    stepSize: 3,
    labels: {
      style: {
        colors: '#9998D4',
        fontSize: '10px',
      },
      offsetX: -12,
    },
    axisBorder: {
      show: false,
    },
  },
  xaxis: {
    min: 1,
    max: 20,
    stepSize: 3,
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  chart: {
    type: 'line',
    fontFamily: 'Nunito Sans',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    width: 2,
    curve: 'straight',
    colors: ['#ffffff'],
  },
  grid: {
    show: true,
    borderColor: '#545380',
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: ['#161432'],
      opacity: 0.5,
    },
  },
};

export const threeDicePerDiceChartConfig: ApexOptions = {
  markers: {
    size: [6, 6, 6],
    strokeWidth: 0,
    shape: 'circle',
    colors: ['#FFE500', '#63D8FE', '#7660FE'],
  },
  yaxis: {
    max: 6,
    min: 1,
    stepSize: 1,
    labels: {
      style: {
        colors: '#9998D4',
        fontSize: '10px',
      },
      minWidth: 12,
      offsetX: -10,
    },
    axisBorder: {
      show: false,
    },
  },
  xaxis: {
    min: 1,
    max: 20,
    stepSize: 3,
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  chart: {
    type: 'line',
    fontFamily: 'Nunito Sans',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    width: 2,
    curve: 'straight',
    colors: ['#FFE500', '#63D8FE', '#7660FE'],
  },
  grid: {
    show: true,
    borderColor: '#545380',
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: ['#161432'],
      opacity: 0.5,
    },
  },
  legend: {
    labels: {
      colors: '#fff',
      useSeriesColors: false,
    },
    markers: {
      fillColors: ['#FFE500', '#63D8FE', '#7660FE'],
    },
  },
};
