import { SvgIcon } from '@mui/material';

export function SearchDocument(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.42644 19.9044L11.3403 17.9906C11.9737 18.427 12.7405 18.684 13.568 18.684C15.7396 18.684 17.5001 16.9235 17.5001 14.7519C17.5001 12.5803 15.7396 10.8198 13.568 10.8198C11.3964 10.8198 9.63591 12.5803 9.63591 14.7519C9.63591 15.5795 9.89293 16.3462 10.3294 16.9796L8.41553 18.8935L9.42644 19.9044ZM11.0658 14.7519C11.0658 13.37 12.1861 12.2497 13.568 12.2497C14.95 12.2497 16.0703 13.37 16.0703 14.7519C16.0703 16.1339 14.95 17.2542 13.568 17.2542C12.1861 17.2542 11.0658 16.1339 11.0658 14.7519Z"
        fill="#858997"
      />
      <path
        d="M6.96007 18.3264L8.57617 16.7103C8.33202 16.0908 8.20548 15.4291 8.20548 14.7517C8.20548 11.7951 10.6109 9.38977 13.5674 9.38977C14.6191 9.38977 15.5986 9.69862 16.4272 10.2237V0.810632C16.4272 0.415634 16.1069 0.0957031 15.7122 0.0957031H1.41366C1.01902 0.0957031 0.69873 0.415634 0.69873 0.810632V17.6115C0.69873 18.0061 1.01902 18.3264 1.41366 18.3264H6.96007ZM13.9249 6.53006H3.20098V5.1002H13.9249V6.53006ZM13.9249 3.67035H3.20098V2.24049H13.9249V3.67035Z"
        fill="#858997"
      />
    </SvgIcon>
  );
}
