import { SvgIcon } from '@mui/material';

export function IconCup(props: any) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.4161 3.70503C21.0161 3.28003 20.4485 3.03501 19.8635 3.03501H18.0007C18.0083 2.7 18.0132 2.35503 18.0132 2H5.99927C5.99927 2.35498 6.00425 2.7 6.01177 3.03501H4.13654C3.55146 3.03501 2.98391 3.28003 2.58386 3.70503C2.17878 4.13256 1.9688 4.71752 2.00376 5.31005C2.33881 10.895 5.22168 14.4 9.79221 14.92L8.90709 18C7.85447 18 7.00188 18.8525 7.00188 19.905V22H17.0131V19.905C17.0131 18.8525 16.1605 18 15.1079 18L14.2228 14.92C18.7833 14.395 21.6637 10.8926 21.9962 5.31005C22.0312 4.72006 21.8212 4.13505 21.4161 3.70503ZM4.00152 5.19003C3.99902 5.13505 4.02154 5.09755 4.04151 5.07753C4.07901 5.03754 4.12404 5.03754 4.13654 5.03754H6.1068C6.40435 8.97005 7.27193 11.2 8.11457 12.46C4.90665 11.115 4.14152 7.52255 4.00152 5.19003ZM15.898 12.4525C16.7406 11.19 17.6057 8.96253 17.9032 5.03505H19.866C19.8809 5.03505 19.9235 5.03505 19.961 5.07504C19.981 5.09506 20.0035 5.13256 20.001 5.18754C19.8585 7.52005 19.0959 11.1025 15.898 12.4525Z"
        fill="white"
      />
    </SvgIcon>
  );
}
