import { Stack, styled } from '@mui/material';

export const OverAndUnderTabsWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.main,
  '.tab': {
    cursor: 'pointer',
    padding: theme.spacing(2, 1.5, 0.5),
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    gap: theme.spacing(1),
    flex: 1,
    alignItems: 'center',
    '.win-flag': {
      position: 'absolute',
      top: 0,
      left: '50%',
      display: 'inline-flex',
      backgroundColor: '#ED1D49',
      padding: theme.spacing(0.5, 1),
      transform: 'translate(-50%, -60%)',
      borderRadius: theme.shape.borderRadius / 2,
    },
    'svg.user-couple': {
      width: 12,
      height: 12,
    },
    '.currency-img': {
      display: 'inline-flex',
      marginRight: theme.spacing(0.5),
      svg: {
        width: 16,
        height: 16,
      },
    },
    '&.active': {
      background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
    },
    '.over-total-bet-amount, .under-total-bet-amount': {
      alignItems: 'center',
      p: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.text.contrast,
      },
    },
    '.user-count': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#24244A',
      padding: theme.spacing(0, '9px', 0, 1),
      gap: theme.spacing(0.25),
      fontSize: 12,
      fontWeight: 400,
      '&.over': {
        borderRadius: `14px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px`,
      },
      '&.under': {
        borderRadius: `${theme.shape.borderRadius}px 14px 0px ${theme.shape.borderRadius}px`,
      },
    },
  },
}));
