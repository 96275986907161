import { useReactiveVar } from '@apollo/client';
import { Box, InputBase, Typography } from '@mui/material';
import { ReactComponent as CodgIcon } from 'assets/icons/CODG.svg';
import coinImages from 'assets/images/coins';
import { LockCurrencyDialog } from 'components/AssetsPopover/LockCurrencyDialog';
import {
  useLoyalUserQuery,
  useUserWalletsQuery,
  useVipClaimInstantBonusMutation,
} from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import { currenciesVar } from 'store';
import { getVipRankByVipPoint } from 'utils/getVipRank';

import { useTranslation } from 'react-i18next';
import WalletDialogHeader from '../WalletDialogHeader';
import { ClaimButton, CodgSwapList, CODGWrapper } from './style';

// -------------------------------------------------------------------

interface PropsCODG {
  handleCloseWalletDialog: VoidFunction;
}

const CODG = ({ handleCloseWalletDialog }: PropsCODG) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const { currencies } = useReactiveVar(currenciesVar);

  const { data: loyalUser } = useLoyalUserQuery();
  const { data: userWalletsData, refetch: refetchUserWallets } =
    useUserWalletsQuery({
      fetchPolicy: 'cache-only',
    });
  const [vipClaimInstant, { loading: loadClaim }] =
    useVipClaimInstantBonusMutation();

  const CODGCurrency = useMemo(
    () => currencies.find((c) => c.id === '13'),
    [currencies],
  );

  const CODGBallance = useMemo(
    () =>
      userWalletsData?.me?.userWallets.find(
        (userWallet: { currencyId: string }) => userWallet.currencyId === '13',
      )?.amount || 0,
    [userWalletsData],
  );

  const totalCODGAvailable = useMemo(() => {
    const returnValue = loyalUser?.me?.vipUser?.vipUserCurrencies?.reduce(
      (total, c) =>
        total + c.instantBonusAvailableAmount * c.currency.equivalentUsdRate,
      0,
    );
    return returnValue;
  }, [loyalUser]);

  const isCheckRankBronze = useMemo(() => {
    const vipRank = getVipRankByVipPoint(loyalUser?.me?.vipUser?.vipPoint);
    return !vipRank?.value;
  }, [loyalUser]);

  const codg_total = [
    {
      id: 1,
      title: 'total_received_CODG',
      value: getCurrencyAmount(CODGBallance, CODGCurrency, {
        onlyCurrency: true,
      }),
      colorValue: '#53BA0C',
      icon: <CodgIcon />,
    },
    {
      id: 2,
      title: 'unlocked_amount',
      value: getCurrencyAmount(totalCODGAvailable, CODGCurrency, {
        onlyCurrency: true,
      }),
      colorValue: '#FFFFFF',
      icon: <CodgIcon />,
    },
  ];

  const handleClaim = async () => {
    if (isCheckRankBronze) return;

    const responseValue = await vipClaimInstant();
    if (responseValue?.errors) {
      enqueueSnackbar(t('something_is_wrong'), { variant: 'error' });
      return;
    }
    if (responseValue?.data?.vipClaimInstantBonus?.success === false) {
      enqueueSnackbar(
        responseValue?.data?.vipClaimInstantBonus?.error?.message,
        { variant: 'error' },
      );
      return;
    }
    await refetchUserWallets();
    enqueueSnackbar(t('claim_successful'), { variant: 'success' });
  };
  const [openLockCurrencyDialog, setOpenLockCurrencyDialog] = useState(false);

  return (
    <CODGWrapper className="codg-wrapper">
      {!isMobile && !isTablet ? (
        <WalletDialogHeader
          handleClose={handleCloseWalletDialog}
          title="COD Gift"
          hideCloseIcon={isMobile}
          helpLink={
            <Box
              className="codg-help"
              onClick={() => setOpenLockCurrencyDialog(true)}
            >
              <Link to="/">{t('how_to_unlock_CODG')}</Link>
            </Box>
          }
        />
      ) : (
        <Box
          className="codg-help"
          onClick={() => setOpenLockCurrencyDialog(true)}
        >
          <Link to="/">{t('how_to_unlock_CODG')}</Link>
        </Box>
      )}

      <Box className="codg-content">
        <Box>
          <Box className="codg-total">
            {codg_total.map((item) => (
              <Box key={item.id} className="codg-item">
                <Typography component="span">{t(item.title)}</Typography>
                <Box>
                  <Typography component="span" color={item.colorValue}>
                    {item.value}
                  </Typography>

                  {item.icon}
                </Box>
              </Box>
            ))}
          </Box>

          <Box className="codg-swap">
            <Box className="codg-swap-group">
              <Box className="codg-swap-header">
                <Typography variant="h6">{t('swap_and_play')}</Typography>
                <Typography component="span">{t('unlocked_CODG')}</Typography>

                {isCheckRankBronze && (
                  <Typography component="span">
                    {t('you_need_to_reach_the_rank_to_unlock_this_feature', {
                      val: t('bronze'),
                    })}{' '}
                    <Link
                      to={PATH_DASHBOARD.vipClub.root}
                      target="_self"
                      onClick={() => handleCloseWalletDialog()}
                    >
                      {t('vip_club')}
                    </Link>
                  </Typography>
                )}
              </Box>

              <Box className="codg-swap-info">
                <Box>
                  <CodgIcon />
                  <Typography component="span">CODG</Typography>
                </Box>

                <Box className="balance_box">
                  <Typography component="span">
                    {t('available_to_swap')}
                  </Typography>
                  <InputBase
                    value={getCurrencyAmount(totalCODGAvailable, CODGCurrency, {
                      onlyCurrency: true,
                    })}
                    inputProps={{ readOnly: true }}
                    sx={{
                      '& input': {
                        fontSize: '14px !important',
                        fontWeight: 600,
                        lineHeight: 'normal',
                      },
                    }}
                  />
                </Box>
              </Box>

              {!isCheckRankBronze && (
                <CodgSwapList>
                  {loyalUser?.me?.vipUser?.vipUserCurrencies?.filter(
                    (c) => c.instantBonusAvailableAmount > 0,
                  )?.length > 0 ? (
                    loyalUser?.me?.vipUser?.vipUserCurrencies
                      ?.filter((c) => c.instantBonusAvailableAmount > 0)
                      ?.map((c) => (
                        <Box key={c.id}>
                          <Typography component="span">
                            {getCurrencyAmount(
                              c.instantBonusAvailableAmount,
                              c.currency,
                              {
                                onlyCurrency: true,
                              },
                            )}
                          </Typography>

                          <img
                            width={20}
                            src={coinImages[c.currency.shortName]}
                            alt="image_coin"
                          />
                        </Box>
                      ))
                  ) : (
                    <Typography sx={{ py: 3 }}>
                      {t('there_are_no_redeemable_currencies')}
                    </Typography>
                  )}
                </CodgSwapList>
              )}
            </Box>
          </Box>
        </Box>

        <ClaimButton
          onClick={handleClaim}
          variant={isCheckRankBronze ? 'disable' : 'contained'}
          disabled={isCheckRankBronze}
          endIcon={
            loadClaim && (
              <img
                src="assets/icon-circle-loading.svg"
                alt="tranfilogo"
                style={{ height: 36 }}
              />
            )
          }
        >
          {!loadClaim && t('claim')}
        </ClaimButton>
      </Box>

      {openLockCurrencyDialog && (
        <LockCurrencyDialog
          open={openLockCurrencyDialog}
          handleClose={() => setOpenLockCurrencyDialog(false)}
          handleOpenDeposit={() => setOpenLockCurrencyDialog(false)}
        />
      )}
    </CODGWrapper>
  );
};

export default CODG;
