import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { ThemedButton } from 'common/Button/style';
import { DialogHeaderStyled } from 'common/Dialog';
import DialogAnimate, {
  Props as DialogAnimateProps,
} from 'components/animate/DialogAnimate';
import { DialogWrapper } from 'components/Dialog/styles';
import FormProvider, {
  FormContextProps,
} from 'components/hook-form/FormProvider';
import { uniqueId } from 'lodash';

import {
  Box,
  ButtonProps,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

interface ConfirmAction extends ButtonProps {
  label: string;
}

interface Props extends DialogAnimateProps {
  title: string;
  actions?: ConfirmAction[];
  titleAlign?: 'center' | 'left';
  formContextProps?: Omit<FormContextProps, 'children'>;
}

/**
 * @example
 * ```tsx
 * <ConfirmDialog
 *  open
    onClose={() => {}}
    title="Eddie"
    titleAlign="center"
    sx={{ height: 400 }}
    formContextProps={{
      methods: formMethods,
      onSubmit: formMethods.handleSubmit(handleApplyFilter),
    }}
    actions={[
      {
        label: 'cancel',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        label: 'Confirm',
        variant: 'contained',
        color: 'secondary',
      },
    ]}
  >
    <Box>
      <UploadIllustration />
    </Box>
  </ConfirmDialog>
  * ```
* */

export default function ConfirmDialog({
  open,
  title,
  onClose,
  children,
  actions,
  titleAlign,
  formContextProps,
  ...other
}: Props) {
  const theme = useTheme();

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      {...other}
      sx={{
        p: 3,
        width: 633,
        borderRadius: 1,
        minHeight: 'fit-content',
        backgroundColor: 'background.default',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '80%',
          p: 2,
        },
        ...other.sx,
      }}
    >
      <FormProvider {...formContextProps} height="fit-content" width="100%">
        <DialogHeaderStyled
          sx={{
            height: 'unset',
          }}
        >
          <Typography
            variant="h6"
            color="text.contrast"
            {...(titleAlign === 'center' && {
              sx: {
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                left: 0,
              },
            })}
          >
            {title}
          </Typography>
          <IconButton
            sx={{ ml: 'auto' }}
            className="close-btn"
            onClick={() => onClose()}
          >
            <CloseCircle width="24px" height="24px" />
          </IconButton>
        </DialogHeaderStyled>
        <DialogWrapper
          sx={{ p: { xs: 0 }, backgroundColor: 'background.main' }}
        >
          <Box flex={1} py={3}>
            {children}
          </Box>
          <Stack
            direction={{ sm: 'row', xs: 'column' }}
            rowGap={1.5}
            columnGap={3}
            width="100%"
          >
            {actions.map(({ label, ...buttonProps }) => (
              <ThemedButton
                fullWidth
                sx={{ minHeight: 40 }}
                variant="contained"
                color="secondary"
                key={uniqueId()}
                {...buttonProps}
              >
                <Typography variant="button">{label}</Typography>
              </ThemedButton>
            ))}
          </Stack>
        </DialogWrapper>
      </FormProvider>
    </DialogAnimate>
  );
}

ConfirmDialog.defaultProps = {
  actions: [],
  formContextProps: null,
};
