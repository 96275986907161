import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg';
import { ReactComponent as SquareArrow } from 'assets/icons/square-arrow.svg';
import { useAuthContext } from 'auth/useAuthContext';
import { setSession } from 'auth/utils';
import { TabsStyled } from 'common/Tabs';
import { WithLoadingOverlay } from 'components/LoadingAnimated/LoadingAnimated';
import { REFERRAL_CODE } from 'constants/index';
import {
  useLoginMutation,
  useRegisterMutation,
  useRequestRecoverPasswordMutation,
} from 'graph';
import useResponsive from 'hooks/useResponsive';
import { useLocales } from 'locales';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { Box, Tab, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import { BannerLogin } from 'overrides/components/BannerLogin';
import { WelcomeMessage } from 'overrides/components/text-host/TextHost';
import V2ForgotPassword, {
  ForgotPasswordForm,
} from './ForgotPassword/ForgotPassword_v2';
import V2SignIn, { SignInForm } from './SignIn/SignIn_v2';
import V2SignUp, { SignUpForm } from './SignUp/SignUp_v2';
import {
  AuthenticationContainer,
  AuthenticationWrapper,
  Body,
  Footer,
  Header,
  SSOItemBox,
} from './styles_v2';

interface AuthenticationProps {
  open: boolean;
  page: AuthScreen;
  loading: boolean;
  handleClose: () => void;
  handleOpenBonusDialog: VoidFunction;
}

export enum AuthScreen {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  FORGOT_PW = 'FORGOT_PW',
}

const Authentication = ({
  open,
  page,
  loading,
  handleClose,
  handleOpenBonusDialog,
}: AuthenticationProps) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 700);
  const isTablet = useResponsive('between', 'sm', 'lg');
  const [selectedScreen, setSelectedScreen] = React.useState<AuthScreen>(
    page || AuthScreen.SIGN_IN,
  );
  const { loginWithGoogle } = useAuthContext();
  const [login, loginMutationResult] = useLoginMutation();
  const [register, registerMutationResult] = useRegisterMutation();
  const [requestResetPassword, resetPasswordMutationResult] =
    useRequestRecoverPasswordMutation();

  const tabs: TabsType<AuthScreen> = [
    {
      label: translate('sign_in'),
      value: AuthScreen.SIGN_IN,
    },
    {
      label: translate('sign_up'),
      value: AuthScreen.SIGN_UP,
    },
  ];

  const handleSignIn = async (data: SignInForm) => {
    const response = await login({
      variables: {
        email: data.email,
        password: data.password,
      },
    });

    if (response?.data?.login.success) {
      handleClose();
      enqueueSnackbar(translate(WelcomeMessage()), {
        variant: 'success',
      });
      setSession(response.data.login.content.accessToken);
    }
  };

  const handleSignUp = async (data: SignUpForm) => {
    const response = await register({
      variables: {
        email: data.email,
        password: data.password,
        referralCode: localStorage.getItem(REFERRAL_CODE),
      },
    });

    if (response.data?.register.success) {
      handleClose();
      setSession(response.data.register.content?.accessToken);
      enqueueSnackbar(translate(WelcomeMessage()), {
        variant: 'success',
      });
      handleOpenBonusDialog();

      ReactGA.event({
        category: 'Button Click',
        action: 'User Register',
        label: 'User Register',
      });
    }
  };

  const handleForgotPassword = async (
    data: ForgotPasswordForm,
    countdown: VoidFunction,
  ) => {
    const response = await requestResetPassword({
      variables: {
        email: data.email,
      },
    });

    if (response.data?.requestRecoverPassword.success) {
      enqueueSnackbar(translate('request_reset_email'), { variant: 'success' });
      countdown();
    }
  };

  useEffect(
    () => () => {
      window.history.replaceState({}, undefined);
    },
    [],
  );

  return (
    <Dialog
      open={open}
      id="authentication-dialog"
      onClose={() => handleClose()}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          justifyContent: 'center',
          boxShadow: 'none',
          maxWidth: 'unset',
          maxHeight: '80%',
          ...(isTablet && {
            transform: 'none',
            width: 800,
          }),
          [theme.breakpoints.down(700)]: {
            transform: 'none',
            width: '100%',
          },
          [theme.breakpoints.down(300)]: {
            margin: 0,
          },
        },
      }}
    >
      <AuthenticationContainer>
        {!isMobile && <BannerLogin />}

        <WithLoadingOverlay
          loading={
            loading ||
            loginMutationResult.loading ||
            registerMutationResult.loading ||
            resetPasswordMutationResult.loading
          }
        >
          <AuthenticationWrapper>
            <Header>
              <Box display="flex" gap={1} alignItems="center">
                {selectedScreen === AuthScreen.FORGOT_PW && (
                  <Box
                    display="flex"
                    gap={1}
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => setSelectedScreen(AuthScreen.SIGN_IN)}
                  >
                    <SquareArrow width="24px" height="24px" />
                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontWeight: 700,
                        color: '#fff',
                        lineHeight: '24px',
                      }}
                    >
                      {translate('reset_password')}
                    </Typography>
                  </Box>
                )}
                {selectedScreen !== AuthScreen.FORGOT_PW && (
                  <TabsStyled
                    value={selectedScreen}
                    scrollButtons={false}
                    onChange={(_, tab) => setSelectedScreen(tab)}
                    sx={{ mb: 0, minHeight: { sm: 44 } }}
                  >
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </TabsStyled>
                )}
              </Box>
              <IconButton className="close-btn" onClick={() => handleClose()}>
                <CloseCircle width="24px" height="24px" />
              </IconButton>
            </Header>
            <Body>
              {selectedScreen === AuthScreen.SIGN_IN && (
                <V2SignIn
                  onSubmitSignIn={handleSignIn}
                  loginMutationResult={loginMutationResult}
                  openForgotPassword={() =>
                    setSelectedScreen(AuthScreen.FORGOT_PW)
                  }
                  openSignUp={() => setSelectedScreen(AuthScreen.SIGN_UP)}
                  handleCloseAuthDialog={() => handleClose()}
                />
              )}
              {selectedScreen === AuthScreen.SIGN_UP && (
                <V2SignUp
                  onSubmitSignUp={handleSignUp}
                  handleCloseAuthDialog={() => handleClose()}
                  registerMutationResult={registerMutationResult}
                  openSignIn={() => setSelectedScreen(AuthScreen.SIGN_IN)}
                />
              )}
              {selectedScreen === AuthScreen.FORGOT_PW && (
                <V2ForgotPassword
                  onSubmitForgotPassword={handleForgotPassword}
                  resetPasswordMutationResult={resetPasswordMutationResult}
                />
              )}
            </Body>

            <Footer>
              <Divider
                flexItem
                sx={{ alignItems: { xs: 'center', sm: 'initial' } }}
              >
                <Typography fontSize={{ sx: 12, lg: 16 }} variant="caption">
                  {translate('or_log_in_with')}
                </Typography>
              </Divider>
              <Box display="flex" justifyContent="center" alignItems="center">
                <SSOItemBox>
                  <IconButton onClick={() => loginWithGoogle()}>
                    <GoogleIcon width="24px" height="24px" />
                  </IconButton>
                </SSOItemBox>
                {/* <SSOItemBox>
                <IconButton>
                <TelegramIcon />
                </IconButton>
                </SSOItemBox>
                <SSOItemBox>
                <IconButton>
                <IconFacebook />
                </IconButton>
              </SSOItemBox> */}
              </Box>
            </Footer>
          </AuthenticationWrapper>
        </WithLoadingOverlay>
      </AuthenticationContainer>
    </Dialog>
  );
};

export default Authentication;
