import { Box, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import currencyImages from 'assets/images/coins';
import PlayerBox from 'components/PlayerBox';
import { Currency, User } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import useResponsive from 'hooks/useResponsive';
import numeral from 'numeral';

interface IBetInfoProps {
  id: string;
  betAmount: number;
  currency: Currency;
  payoutRate: number;
  payout: number;
  gameName: string;
  user: User;
  handleClick: () => void;
}

interface IRowTableProps {
  row: IBetInfoProps;
  isBetsByGame: boolean;
  isShowInMiniGameDialog: boolean;
  gameId: string;
  equivalentUsdRate: string;
}

const RowHighBetTableWrapper = (props: Partial<IRowTableProps>) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const { getCurrencyAmount } = useGetCurrencyAmount();

  const {
    isShowInMiniGameDialog,
    isBetsByGame,
    gameId,
    equivalentUsdRate,
    row,
  } = props;
  return (
    <>
      <TableRow key={row.id} onClick={() => row.handleClick()}>
        {!isBetsByGame && (
          <TableCell
            align="left"
            sx={{ maxWidth: { sm: 100, xs: 20 } }}
            width="15%"
          >
            <Typography ml={1}>{row.gameName}</Typography>
          </TableCell>
        )}

        <TableCell align="center" width="30%">
          <PlayerBox align={isBetsByGame ? 'left' : 'center'} user={row.user} />
        </TableCell>

        {(!isMobile || gameId) && (
          <TableCell align="right" width="25%">
            <Box display="flex" alignItems="center" justifyContent="right">
              <Typography color="white" className="bet_amount">
                {getCurrencyAmount(row.betAmount, {
                  ...row.currency,
                  equivalentUsdRate,
                })}
              </Typography>
              <img
                className="currency_image"
                alt={row.currency.shortName}
                src={currencyImages[row.currency.shortName]}
              />
            </Box>
          </TableCell>
        )}

        <TableCell
          align="center"
          component="th"
          scope="row"
          width="20%"
          sx={{
            [theme.breakpoints.down('sm')]: {
              width: '15%',
            },
          }}
        >
          <Typography color="white" className="multiplier">
            {!Number.isNaN(Number(row.payoutRate))
              ? numeral(Number(row.payoutRate)).format('0.00')
              : row.payoutRate}
            x
          </Typography>
        </TableCell>

        <TableCell
          className={row.payout > 0 ? 'increase' : 'decrease'}
          align="right"
          sx={{ width: { sm: '30%', xs: '20%' } }}
        >
          <Box display="flex" alignItems="center" justifyContent="right" mr={1}>
            <Typography
              className="profit"
              color={isShowInMiniGameDialog && 'white !important'}
            >
              {row.payout
                ? getCurrencyAmount(row.payout, {
                    ...row.currency,
                    equivalentUsdRate,
                  })
                : getCurrencyAmount(row.betAmount, {
                    ...row.currency,
                    equivalentUsdRate,
                  })}
            </Typography>
            <img
              className="currency_image"
              alt={row.currency.shortName}
              src={currencyImages[row.currency.shortName]}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
export { RowHighBetTableWrapper };
